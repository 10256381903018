import styled from "styled-components";
import { colors } from "../../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 10vh;
	padding-left: 6rem;
	padding-right: 6rem;
	background-color: white;
	border-bottom: 1px solid #e0e0e0;

	.body {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.a {
			display: flex;
			flex-direction: row;
			height: 100%;
			width: 100%;
			background-color: white;

			.left {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 100%;

				.setaButton {
					background-color: transparent;
					border: none;
					margin-right: 2rem;
				}

				.setaButton2 {
					background-color: transparent;
					border: none;
					margin-left: 4rem;
				}

				.leftExecucao {
					display: flex;
					align-items: center;

					.nomeProtocolo {
						font-weight: 500;
						font-size: 1.5rem;

						color: #5c5b5b;

						margin-left: 2rem;

						margin-bottom: 0.3rem;
					}

					.tipoExecucao {
						font-weight: 400;
						font-size: 1.25rem;

						color: #7f7f7f;

						margin-left: 2rem;

						margin-bottom: 0.2rem;
					}
				}

				.patch {
					display: flex;
					width: 100%;

					.texto {
						font-family: Roboto;
						font-style: normal;
						font-weight: 500;
						font-size: 24px;
						line-height: 28px;
						color: #5c5b5b;
						margin-right: 5px;
					}

					.textoAzul {
						font-size: 1.5rem;
						color: #001574;
						font-weight: 500;
					}
				}
			}
		}

		.grande {
			display: flex;
			flex-direction: row;
			width: 90%;
			height: 100%;
			background-color: white;

			.left {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 60rem;
				height: 100%;

				.setaButton {
					background-color: transparent;
					border: none;
					margin-right: 2rem;
				}

				.setaButton2 {
					background-color: transparent;
					border: none;
					margin-left: 4rem;
				}

				.leftExecucao {
					display: flex;
					align-items: center;

					.nomeProtocolo {
						font-weight: 500;
						font-size: 1.5rem;

						color: #5c5b5b;

						margin-left: 2rem;

						margin-bottom: 0.3rem;
					}

					.tipoExecucao {
						font-weight: 400;
						font-size: 1.25rem;

						color: #7f7f7f;

						margin-left: 2rem;

						margin-bottom: 0.2rem;
					}
				}

				.patch {
					display: flex;
					width: 100%;

					.texto {
						font-family: Roboto;
						font-style: normal;
						font-weight: 500;
						font-size: 24px;
						line-height: 28px;
						color: #5c5b5b;
						margin-right: 5px;
					}

					.textoAzul {
						font-size: 1.5rem;
						color: ${colors?.primary_blue_k_medium_1};
						font-weight: 500;
					}
				}
			}
		}

		.b {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			width: 50%;
			height: 100%;

			.right {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				width: 16rem;
				height: 100%;
			}
		}

		.b {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			height: 100%;

			form {
				width: 100%;
				.mid {
					display: flex;
					align-items: center;
					justify-content: space-around;
					width: 100%;
					height: 100%;

					.input {
						display: flex;
						align-items: center;
						width: 100%;
						height: 2.8rem;
						background-color: #f2f2f2;
						padding-left: 0.75rem;
						padding-right: 0.75rem;
						border-radius: 3.75rem;
					}
				}

				.inputPesquisa {
					height: 90%;
					width: 100%;
					margin-left: 1rem;
					margin-right: 1rem;
					background-color: transparent;
					border: none;
				}

				.reset {
					display: flex;
					align-items: center;
					background-color: transparent;
					border: none;
				}
			}

			.rigth {
				display: flex;
				flex-direction: row;
				height: 100%;

				.opcoes {
					margin-left: 2.1rem;
					background-color: transparent;
					border: none;
				}
			}
		}

		.barraCheckList {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			height: 100%;
			margin-right: 4rem;
		}

		.pagamentos {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			width: 10%;
			height: 100%;
		}
	}

	.opcoes {
		margin-left: 2.1rem;
		background-color: transparent;
		border: none;
	}
`;

import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Conteiner = styled.div`
	.switch {
		position: relative;
		display: inline-block;
		width: 2rem;
		height: 1.25rem;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 1rem;
		width: 1rem;
		margin-left: 0.1rem;

		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: ${colors.secondary_green_k_medium_1};
	}

	input:focus + .slider {
		box-shadow: 0 0 1px ${colors.secondary_green_k_medium_1};
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(11px);
		-ms-transform: translateX(11px);
		transform: translateX(11px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
		margin-top: 0.13rem;
	}
`;

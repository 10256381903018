import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	height: 100vh;
	display: flex;
	align-items: stretch;
	background-color: white;

	overflow: hidden;
`;

export const Margem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 33%;
	max-width: 938px;
	height: 100vh;
	z-index: 10;
	background-color: white;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80%;
	height: 100vh;
	max-width: 1000px;
	background-color: white;

	.form {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		margin-top: 7vh;
	}

	.buttonPerfil {
		background-color: white;
		border: none;
	}

	.perfil {
		width: 15%;
	}

	.global {
		width: 100%;
	}

	.globalInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.input {
		width: 100%;
		margin-left: 0.5vw;
		background-color: white;
		color: #8c8e86;
		font-weight: 500;
		border: none;
	}

	.icon {
		margin-bottom: 5px;
	}

	.divhr {
		width: 100%;
		margin-bottom: 3vh;
		margin-top: 0.5vh;
	}

	.hr {
		width: 100%;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${colors.tertiary_red_1_dark};
		color: ${colors.tertiary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${colors.tertiary_red_1_dark};
	}

	.concluir {
		margin-top: 4vh;
		width: 100%;
		height: 6vh;
		min-height: 6vh;
		background-color: ${colors.primary_blue_k_main};
		color: white;
		font-weight: 700;
		border: none;
		margin-bottom: 3vh;
		border-radius: 8px;
		text-transform: uppercase;

		transition: all 0.2s ease;
	}

	.concluir:hover {
		background-color: ${colors.primary_blue_k_dark_2};
	}

	.voltar {
		width: 100%;
		height: 6vh;
		min-height: 6vh;
		background-color: white;
		color: ${colors.primary_blue_k_main};
		font-weight: 700;
		border: none;
		margin-bottom: 25px;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.voltar:hover {
		color: ${colors.primary_blue_k_dark_2};
	}

	.select {
		width: 100%;
		height: 30px;
		margin-left: 0.3vw;
		font-weight: 500;
		border: none;
		font-family: "Roboto", serif;
		font-size: 18px;
		color: #8c8e86;
	}

	select:hover,
	select:focus,
	select:active,
	select:checked {
		color: #8c8e86;
	}

	.eyeButton {
		background-color: white;
		border: none;
	}
`;

export const Background = styled.div`
	flex: 1;
	width: 67%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	z-index: 0;
`;

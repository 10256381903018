import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../../database/txt.interface";

import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { TypesBtnRelatorio } from "../../../utils/types";
import NenhumRelatorio from "../NenhumRelatorio";
import { Content, Container } from "./stylesPreview";
import QualidadeDeVida from "../QualidadeDeVIda";

const PreviewFisioterapeutico: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const anamneses: any = useSelector((state: any) => state.dashboard.anamneses);

	const [dataAnamnese, setDataAnamnese] = useState<any>([]);
	const [content, setContent] = useState<any>(<></>);

	useEffect(() => {
		setDataAnamnese(
			anamneses.filter((item: any) => item.ID_USUARIO == paciente.ID_USUARIO)[0]
				?.relatorio_fisioterapeutico
		);
	}, [paciente, anamneses]);

	useEffect(() => {
		if (dataAnamnese?.POSSIVEL_GERAR === "S") {
			setContent(
				<>
					<h3>{current_language?.history_patient}</h3>
					<div className="contentData">
						<div className="dataRow">
							<span>
								<b>Histórico geral:</b>{" "}
								{dataAnamnese && dataAnamnese?.HISTORICO_GERAL}
							</span>
						</div>
						<div className="dataRow">
							<span>
								<b>Encaminhamento:</b>{" "}
								{dataAnamnese && dataAnamnese.ENCAMINHAMENTO?.[0]}
							</span>
						</div>
						<div className="dataRow">
							<span>
								<b>Queixa atual:</b>{" "}
								{dataAnamnese && dataAnamnese?.QUEIXA_ATUAL?.[0]}
							</span>
						</div>
						<div className="dataRow">
							<span>
								<b>Histórico Famíliar:</b>{" "}
								{dataAnamnese && dataAnamnese?.HISTORICO_FAMILIAR?.[0]}
							</span>
						</div>
						<div className="dataColumn">
							<span>
								<b>Histórico de Saúde:</b>
								<div className="dualListRow">
									<ul className="dataList">
										{dataAnamnese &&
											dataAnamnese?.HISTORICO_SAUDE?.map((item: any) => (
												<li key={item}>{item}</li>
											))}
									</ul>
									{/* <ul className="dataList">
									<li>Não usa medicamentes</li>
									<li>Não é fumante</li>
									<li>Sendentário</li>
									<li>Experiência com Treinamento de Força - Iniciante</li>
									<li>Dor Lombar - 8</li>
									<li>Dor Cervical - 5</li>
									<li>Sem lesão diagnosticada</li>
									<li>Nunca fez cirurgia</li>
								</ul> */}
								</div>
							</span>
						</div>
						<div className="dataRow">
							<span>
								<b>Qualidade de vida:</b>
							</span>
							<QualidadeDeVida />
						</div>
					</div>
				</>
			);
		} else {
			setContent(
				<NenhumRelatorio
					anamnese={true}
					tipo={TypesBtnRelatorio.BTN_FISIOTERAPEUTICO}
				/>
			);
		}
	}, [dataAnamnese]);

	return (
		<Container>
			<Content>{content}</Content>
		</Container>
	);
};

export default PreviewFisioterapeutico;

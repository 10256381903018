import { CircularProgress } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import background from "../../../assets/kinologyIcons/png/backgroundLogin.png";
import Celular from "../../../assets/icons/celular.svg";
import emailGray from "../../../assets/icons/emailGray.svg";
import KeyGray from "../../../assets/icons/keyGray.svg";
import Nacionalidade from "../../../assets/icons/nacionalidade.svg";
import user from "../../../assets/icons/user.svg";
import Cpf from "../../../assets/icons/user.svg";
import FotoPerfil from "../../../components/FotoPerfil";
import { TextsProps } from "../../../database/txt.interface";
import { RegisterActions } from "../../../store/ducks/register";
import { Background, Conteiner, Content, Margem } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import NotVisible from "../../../assets/icons/notvisible.svg";
import Visible from "../../../assets/icons/visible.svg";

interface Usuario {
	celular: string;
	cpf: string;
}

const Cadastro: React.FC = function () {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const language: any = useSelector((state: any) => state.configs.language);

	const loading_register = useSelector(
		(state: any) => state.register.loading_register
	);

	let photo = useSelector((state: any) => state.add_pac_aval.photo);

	const [passwordShown, setPasswordShown] = useState(false);
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

	const togglePasswordVisiblity = (type: string) => {
		if(type === 'password') setPasswordShown(passwordShown ? false : true);
		if(type === 'confirm_password') setConfirmPasswordShown(confirmPasswordShown ? false : true);
	};

	const handleKeyUpTel = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (language === "PT") {
			// (99)99999-9999
			e.currentTarget.maxLength = 15;
			const { value } = e.currentTarget;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			if (ajustado.length > 10) {
				ajustado = ajustado.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 5) {
				ajustado = ajustado.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 2) {
				ajustado = ajustado.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
			} else {
				ajustado = ajustado.replace(/^(\d*)/, "($1");
			}
			e.currentTarget.value = ajustado;
		} else {
			// + 456 544 344 454
			e.currentTarget.maxLength = 17;
			const { value } = e.currentTarget;
			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			ajustado = ajustado.replace(/^(\d{3})/, "+ $1");

			e.currentTarget.value = ajustado;
		}
	}, []);

	const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (language === "PT") {
			// 234.234.324-34
			e.currentTarget.maxLength = 14;
			const { value } = e.currentTarget;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/(\d{3})(\d)/, "$1.$2");
			ajustado = ajustado.replace(/(\d{3})(\d)/, "$1.$2");
			ajustado = ajustado.replace(/(\d{3})(\d{1,2})/, "$1-$2");
			ajustado = ajustado.replace(/(-\d{2})\d+?$/, "$1");
			e.currentTarget.value = ajustado;
		} else {
			e.currentTarget.maxLength = 14;
			const { value } = e.currentTarget;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			e.currentTarget.value = ajustado;
		}
	}, []);

	const [aprovado, setAprovado] = useState(false);

	// Login
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	const onSubmit = (data: any) => {
		setAprovado(true);
		if (photo === undefined) {
			photo = null;
		}
		const dat_request = {
			USUARIO_EMAIL: data.email,
			USUARIO_NOME: data.nome,
			USUARIO_FOTO: photo,
			USUARIO_CONTATO: data.celular,
			USUARIO_SENHA: data.password,
			USUARIO_CPF: data.cpf,
			USUARIO_NACIONALIDADE: data.nacionalidade,
		};
		dispatch(RegisterActions.register_request(dat_request));
	};
	// console.log(errors);

	// Estado Mask telefone
	const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
	const hendleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUsuario({
				...usuario,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[usuario]
	);

	// Estado Mask cpf
	const [us, setUs] = useState<Usuario>({} as Usuario);
	const hendleChange2 = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUs({
				...us,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[us]
	);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		if (is_open_toast == true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<Conteiner>
			<Background>
				<img src={background} />
			</Background>
			<Margem>
				<Content>
					<form onSubmit={handleSubmit(onSubmit)} className="form">
						<FotoPerfil />
						<div className="global">
							<div className="globalInput">
								<img
									src={user}
									alt="key"
									className="icon"
									onClick={() => {
										// //console.log(photo);
									}}
								/>
								<input
									type="text"
									id="nome"
									placeholder={current_language?.primeiro_acesso_nome}
									className="input"
									{...register("nome", {
										required: `${current_language?.login_input_senha}`,
										pattern: {
											value:
												/\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,}\b/gi,
											message: `${current_language?.pagamento_nome_invalido}`,
										},
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.nome ? "hr hrcor" : "hr"} />
								{errors.nome && (
									<div className="error">{errors.nome.message}</div>
								)}
							</div>
							<div className="globalInput">
								<img src={emailGray} alt="key" className="icon" />
								<input
									type="text"
									id="email"
									placeholder={current_language?.login_email}
									className="input"
									{...register("email", {
										required: `${current_language?.login_input_senha}`,
										pattern: {
											value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: `${current_language?.email_invalido}`,
										},
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.email ? "hr hrcor" : "hr"} />
								{errors.email && (
									<div className="error">{errors?.email.message}</div>
								)}
							</div>
							<div className="globalInput">
								<img src={Celular} alt="key" className="icon" />
								<input
									onKeyPress={handleKeyUpTel}
									type="text"
									id="celular"
									placeholder={current_language?.primeiro_acesso_celular}
									className="input"
									{...register("celular", {
										required: `${current_language?.login_input_senha}`,
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.celular ? "hr hrcor" : "hr"} />
								{errors.celular && (
									<div className="error">{errors?.celular.message}</div>
								)}
							</div>
							<div className="globalInput">
								<img src={KeyGray} alt="key" className="icon" />
								<input
									type={passwordShown ? "text" : "password"}
									id="senha"
									placeholder={current_language?.primeiro_acesso_senha_6}
									className="input"
									{...register("password", {
										required: `${current_language?.login_input_senha}`,
										minLength: {
											value: 8,
											message: current_language?.alterar_senha_minimo,
										},
										pattern: {
											value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
											message: current_language?.password_requiriments
										}
									})}
								/>
								<button
									type="button"
									id="eye"
									className="eyeButton"
									onClick={() => togglePasswordVisiblity('password')}
								>
									<img src={passwordShown ? NotVisible : Visible} alt="eye" />
								</button>
							</div>
							<div className="divhr">
								<hr className={errors.password ? "hr hrcor" : "hr"} />
								{errors.password && (
									<div className="error">{errors?.password.message}</div>
								)}
							</div>
							<div className="globalInput">
								<img src={KeyGray} alt="senha" className="icon" />
								<input
									type={confirmPasswordShown ? "text" : "password"}
									id="confirm"
									placeholder={current_language?.primeiro_acesso_confirm_pass}
									className="input"
									{...register("confirm", {
										required: `${current_language?.login_input_senha}`,
										minLength: {
											value: 8,
											message: current_language?.alterar_senha_minimo,
										},
										pattern: {
											value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
											message: current_language?.password_requiriments
										},
										validate: (value) => {
											if (value === getValues().password) {
												return true;
											}
											return `${current_language?.confirmar_senha}`;
										},
									})}
								/>
								<button
									type="button"
									id="eye"
									className="eyeButton"
									onClick={() => togglePasswordVisiblity('confirm_password')}
								>
									<img src={confirmPasswordShown ? NotVisible : Visible} alt="eye" />
								</button>
							</div>
							<div className="divhr">
								<hr className={errors.confirm ? "hr hrcor" : "hr"} />
								{errors.confirm && (
									<div className="error">{errors.confirm.message}</div>
								)}
							</div>
							<div className="globalInput">
								<img src={Cpf} alt="cpf" className="icon" />
								<input
									onKeyPress={handleKeyUpCpf}
									type="text"
									id="cpf"
									placeholder={current_language?.primeiro_acesso_CPF}
									className="input"
									{...register("cpf", {
										required: `${current_language?.login_input_senha}`,
										pattern: {
											value:
												/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i,
											message: `${current_language?.cpf_invalido}`,
										},
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.cpf ? "hr hrcor" : "hr"} />
								{errors.cpf && (
									<div className="error">{errors.cpf.message}</div>
								)}
							</div>
							<div className="globalInput">
								<img
									id="nacionalidade"
									src={Nacionalidade}
									alt="nascionalidade"
									className="icon"
								/>
								<select
									id="nacionalidade"
									className="select"
									{...register("nacionalidade", {
										required: `${current_language?.login_input_senha}`,
									})}
								>
									<optgroup>
										<option value="Nacionalidade" defaultValue="nacionalidade">
											{current_language?.primeiro_acesso_nacionalidade}
										</option>
										<option value="Brasil">Brasil</option>
										<option value="Afeganistão">Afeganistão</option>
										<option value="África do Sul">África do Sul</option>
										<option value="Albânia">Albânia</option>
										<option value="Alemanha">Alemanha</option>
										<option value="Andorra">Andorra</option>
										<option value="Angola">Angola</option>
										<option value="Anguilla">Anguilla</option>
										<option value="Antilhas Holandesas">
											Antilhas Holandesas
										</option>
										<option value="Antárctida">Antárctida</option>
										<option value="Antígua e Barbuda">Antígua e Barbuda</option>
										<option value="Argentina">Argentina</option>
										<option value="Argélia">Argélia</option>
										<option value="Armênia">Armênia</option>
										<option value="Aruba">Aruba</option>
										<option value="Arábia Saudita">Arábia Saudita</option>
										<option value="Austrália">Austrália</option>
										<option value="Áustria">Áustria</option>
										<option value="Azerbaijão">Azerbaijão</option>
										<option value="Bahamas">Bahamas</option>
										<option value="Bahrein">Bahrein</option>
										<option value="Bangladesh">Bangladesh</option>
										<option value="Barbados">Barbados</option>
										<option value="Belize">Belize</option>
										<option value="Benim">Benim</option>
										<option value="Bermudas">Bermudas</option>
										<option value="Bielorrússia">Bielorrússia</option>
										<option value="Bolívia">Bolívia</option>
										<option value="Botswana">Botswana</option>
										<option value="Brunei">Brunei</option>
										<option value="Bulgária">Bulgária</option>
										<option value="Burkina Faso">Burkina Faso</option>
										<option value="Burundi">Burundi</option>
										<option value="Butão">Butão</option>
										<option value="Bélgica">Bélgica</option>
										<option value="Bósnia e Herzegovina">
											Bósnia e Herzegovina
										</option>
										<option value="Cabo Verde">Cabo Verde</option>
										<option value="Camarões">Camarões</option>
										<option value="Camboja">Camboja</option>
										<option value="Canadá">Canadá</option>
										<option value="Catar">Catar</option>
										<option value="Cazaquistão">Cazaquistão</option>
										<option value="Chade">Chade</option>
										<option value="Chile">Chile</option>
										<option value="China">China</option>
										<option value="Chipre">Chipre</option>
										<option value="Colômbia">Colômbia</option>
										<option value="Comores">Comores</option>
										<option value="Coreia do Norte">Coreia do Norte</option>
										<option value="Coreia do Sul">Coreia do Sul</option>
										<option value="Costa do Marfim">Costa do Marfim</option>
										<option value="Costa Rica">Costa Rica</option>
										<option value="Croácia">Croácia</option>
										<option value="Cuba">Cuba</option>
										<option value="Dinamarca">Dinamarca</option>
										<option value="Djibouti">Djibouti</option>
										<option value="Dominica">Dominica</option>
										<option value="Egito">Egito</option>
										<option value="El Salvador">El Salvador</option>
										<option value="Emirados Árabes Unidos">
											Emirados Árabes Unidos
										</option>
										<option value="Equador">Equador</option>
										<option value="Eritreia">Eritreia</option>
										<option value="Escócia">Escócia</option>
										<option value="Eslováquia">Eslováquia</option>
										<option value="Eslovênia">Eslovênia</option>
										<option value="Espanha">Espanha</option>
										<option value="Estados Federados da Micronésia">
											Estados Federados da Micronésia
										</option>
										<option value="Estados Unidos">Estados Unidos</option>
										<option value="Estônia">Estônia</option>
										<option value="Etiópia">Etiópia</option>
										<option value="Fiji">Fiji</option>
										<option value="Filipinas">Filipinas</option>
										<option value="Finlândia">Finlândia</option>
										<option value="França">França</option>
										<option value="Gabão">Gabão</option>
										<option value="Gana">Gana</option>
										<option value="Geórgia">Geórgia</option>
										<option value="Gibraltar">Gibraltar</option>
										<option value="Granada">Granada</option>
										<option value="Gronelândia">Gronelândia</option>
										<option value="Grécia">Grécia</option>
										<option value="Guadalupe">Guadalupe</option>
										<option value="Guam">Guam</option>
										<option value="Guatemala">Guatemala</option>
										<option value="Guernesei">Guernesei</option>
										<option value="Guiana">Guiana</option>
										<option value="Guiana Francesa">Guiana Francesa</option>
										<option value="Guiné">Guiné</option>
										<option value="Guiné Equatorial">Guiné Equatorial</option>
										<option value="Guiné-Bissau">Guiné-Bissau</option>
										<option value="Gâmbia">Gâmbia</option>
										<option value="Haiti">Haiti</option>
										<option value="Honduras">Honduras</option>
										<option value="Hong Kong">Hong Kong</option>
										<option value="Hungria">Hungria</option>
										<option value="Ilha Bouvet">Ilha Bouvet</option>
										<option value="Ilha de Man">Ilha de Man</option>
										<option value="Ilha do Natal">Ilha do Natal</option>
										<option value="Ilha Heard e Ilhas McDonald">
											Ilha Heard e Ilhas McDonald
										</option>
										<option value="Ilha Norfolk">Ilha Norfolk</option>
										<option value="Ilhas Cayman">Ilhas Cayman</option>
										<option value="Ilhas Cocos (Keeling)">
											Ilhas Cocos (Keeling)
										</option>
										<option value="Ilhas Cook">Ilhas Cook</option>
										<option value="Ilhas Feroé">Ilhas Feroé</option>
										<option value="Ilhas Geórgia do Sul e Sandwich do Sul">
											Ilhas Geórgia do Sul e Sandwich do Sul
										</option>
										<option value="Ilhas Malvinas">Ilhas Malvinas</option>
										<option value="Ilhas Marshall">Ilhas Marshall</option>
										<option value="Ilhas Menores Distantes dos Estados Unidos">
											Ilhas Menores Distantes dos Estados Unidos
										</option>
										<option value="Ilhas Salomão">Ilhas Salomão</option>
										<option value="Ilhas Virgens Americanas">
											Ilhas Virgens Americanas
										</option>
										<option value="Ilhas Virgens Britânicas">
											Ilhas Virgens Britânicas
										</option>
										<option value="Ilhas Åland">Ilhas Åland</option>
										<option value="Indonésia">Indonésia</option>
										<option value="Inglaterra">Inglaterra</option>
										<option value="Índia">Índia</option>
										<option value="Iraque">Iraque</option>
										<option value="Irlanda do Norte">Irlanda do Norte</option>
										<option value="Irlanda">Irlanda</option>
										<option value="Irã">Irã</option>
										<option value="Islândia">Islândia</option>
										<option value="Israel">Israel</option>
										<option value="Itália">Itália</option>
										<option value="Iêmen">Iêmen</option>
										<option value="Jamaica">Jamaica</option>
										<option value="Japão">Japão</option>
										<option value="Jersey">Jersey</option>
										<option value="Jordânia">Jordânia</option>
										<option value="Kiribati">Kiribati</option>
										<option value="Kuwait">Kuwait</option>
										<option value="Laos">Laos</option>
										<option value="Lesoto">Lesoto</option>
										<option value="Letônia">Letônia</option>
										<option value="Libéria">Libéria</option>
										<option value="Liechtenstein">Liechtenstein</option>
										<option value="Lituânia">Lituânia</option>
										<option value="Luxemburgo">Luxemburgo</option>
										<option value="Líbano">Líbano</option>
										<option value="Líbia">Líbia</option>
										<option value="Macau">Macau</option>
										<option value="Macedônia">Macedônia</option>
										<option value="Madagáscar">Madagáscar</option>
										<option value="Malawi">Malawi</option>
										<option value="Maldivas">Maldivas</option>
										<option value="Mali">Mali</option>
										<option value="Malta">Malta</option>
										<option value="Malásia">Malásia</option>
										<option value="Marianas Setentrionais">
											Marianas Setentrionais
										</option>
										<option value="Marrocos">Marrocos</option>
										<option value="Martinica">Martinica</option>
										<option value="Mauritânia">Mauritânia</option>
										<option value="Maurícia">Maurícia</option>
										<option value="Mayotte">Mayotte</option>
										<option value="Moldávia">Moldávia</option>
										<option value="Mongólia">Mongólia</option>
										<option value="Montenegro">Montenegro</option>
										<option value="Montserrat">Montserrat</option>
										<option value="Moçambique">Moçambique</option>
										<option value="Myanmar">Myanmar</option>
										<option value="México">México</option>
										<option value="Mônaco">Mônaco</option>
										<option value="Namíbia">Namíbia</option>
										<option value="Nauru">Nauru</option>
										<option value="Nepal">Nepal</option>
										<option value="Nicarágua">Nicarágua</option>
										<option value="Nigéria">Nigéria</option>
										<option value="Niue">Niue</option>
										<option value="Noruega">Noruega</option>
										<option value="Nova Caledônia">Nova Caledônia</option>
										<option value="Nova Zelândia">Nova Zelândia</option>
										<option value="Níger">Níger</option>
										<option value="Omã">Omã</option>
										<option value="Palau">Palau</option>
										<option value="Palestina">Palestina</option>
										<option value="Panamá">Panamá</option>
										<option value="Papua-Nova Guiné">Papua-Nova Guiné</option>
										<option value="Paquistão">Paquistão</option>
										<option value="Paraguai">Paraguai</option>
										<option value="País de Gales">País de Gales</option>
										<option value="Países Baixos">Países Baixos</option>
										<option value="Peru">Peru</option>
										<option value="Pitcairn">Pitcairn</option>
										<option value="Polinésia Francesa">
											Polinésia Francesa
										</option>
										<option value="Polônia">Polônia</option>
										<option value="Porto Rico">Porto Rico</option>
										<option value="Portugal">Portugal</option>
										<option value="Quirguistão">Quirguistão</option>
										<option value="Quênia">Quênia</option>
										<option value="Reino Unido">Reino Unido</option>
										<option value="República Centro-Africana">
											República Centro-Africana
										</option>
										<option value="República Checa">República Checa</option>
										<option value="República Democrática do Congo">
											República Democrática do Congo
										</option>
										<option value="República do Congo">
											República do Congo
										</option>
										<option value="República Dominicana">
											República Dominicana
										</option>
										<option value="Reunião">Reunião</option>
										<option value="Romênia">Romênia</option>
										<option value="Ruanda">Ruanda</option>
										<option value="Rússia">Rússia</option>
										<option value="Saara Ocidental">Saara Ocidental</option>
										<option value="Saint Martin">Saint Martin</option>
										<option value="Saint-Barthélemy">Saint-Barthélemy</option>
										<option value="Saint-Pierre e Miquelon">
											Saint-Pierre e Miquelon
										</option>
										<option value="Samoa Americana">Samoa Americana</option>
										<option value="Samoa">Samoa</option>
										<option value="Santa Helena, Ascensão e Tristão da Cunha">
											Santa Helena, Ascensão e Tristão da Cunha
										</option>
										<option value="Santa Lúcia">Santa Lúcia</option>
										<option value="Senegal">Senegal</option>
										<option value="Serra Leoa">Serra Leoa</option>
										<option value="Seychelles">Seychelles</option>
										<option value="Singapura">Singapura</option>
										<option value="Somália">Somália</option>
										<option value="Sri Lanka">Sri Lanka</option>
										<option value="Suazilândia">Suazilândia</option>
										<option value="Sudão">Sudão</option>
										<option value="Suriname">Suriname</option>
										<option value="Suécia">Suécia</option>
										<option value="Suíça">Suíça</option>
										<option value="Svalbard e Jan Mayen">
											Svalbard e Jan Mayen
										</option>
										<option value="São Cristóvão e Nevis">
											São Cristóvão e Nevis
										</option>
										<option value="São Marino">São Marino</option>
										<option value="São Tomé e Príncipe">
											São Tomé e Príncipe
										</option>
										<option value="São Vicente e Granadinas">
											São Vicente e Granadinas
										</option>
										<option value="Sérvia">Sérvia</option>
										<option value="Síria">Síria</option>
										<option value="Tadjiquistão">Tadjiquistão</option>
										<option value="Tailândia">Tailândia</option>
										<option value="Taiwan">Taiwan</option>
										<option value="Tanzânia">Tanzânia</option>
										<option value="Terras Austrais e Antárticas Francesas">
											Terras Austrais e Antárticas Francesas
										</option>
										<option value="Território Britânico do Oceano Índico">
											Território Britânico do Oceano Índico
										</option>
										<option value="Timor-Leste">Timor-Leste</option>
										<option value="Togo">Togo</option>
										<option value="Tonga">Tonga</option>
										<option value="Toquelau">Toquelau</option>
										<option value="Trinidad e Tobago">Trinidad e Tobago</option>
										<option value="Tunísia">Tunísia</option>
										<option value="Turcas e Caicos">Turcas e Caicos</option>
										<option value="Turquemenistão">Turquemenistão</option>
										<option value="Turquia">Turquia</option>
										<option value="Tuvalu">Tuvalu</option>
										<option value="Ucrânia">Ucrânia</option>
										<option value="Uganda">Uganda</option>
										<option value="Uruguai">Uruguai</option>
										<option value="Uzbequistão">Uzbequistão</option>
										<option value="Vanuatu">Vanuatu</option>
										<option value="Vaticano">Vaticano</option>
										<option value="Venezuela">Venezuela</option>
										<option value="Vietname">Vietname</option>
										<option value="Wallis e Futuna">Wallis e Futuna</option>
										<option value="Zimbabwe">Zimbabwe</option>
										<option value="Zâmbia">Zâmbia</option>
									</optgroup>
								</select>
							</div>
							<div className="divhr">
								<hr className={errors.nacionalidade ? "hr hrcor" : "hr"} />
								{errors.nacionalidade && (
									<div className="error">{errors.nacionalidade.message}</div>
								)}
							</div>
						</div>
						{aprovado == true ? (
							<button id="concluir" className="concluir">
								<CircularProgress color="inherit" />
							</button>
						) : (
							<button 
								id="concluir" 
								className="concluir"
							>
								{current_language?.primeiro_acesso_concluir}
							</button>
						)}

						<Link to="/login">
							<button id="voltar" className="voltar"
								onClick={()=> AmplitudeLog('voltar_criar_conta')}
							>
								{current_language?.voltar}
							</button>
						</Link>
					</form>
				</Content>
			</Margem>
		</Conteiner>
	);
};

export default memo(Cadastro);

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	padding: 3rem 2.8rem 3rem 4.8rem;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 80vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 85vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 85vh;
		width: 42vw;
	}

	.close {
		width: 1.738rem;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}
`;

export const Content = styled.div`
	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
		margin: 2rem 0;
	}
	width: 100%;
	padding-right: 2rem;

	form {
		width: 100%;
		margin-bottom: 2.75rem;
	}

	.body {
		width: 100%;
		height: 85%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.mid {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 2.8rem;
		background-color: #f2f2f2;
		padding: 0 0.75rem;
		border-radius: 3.75rem;
	}

	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}

	.inputPesquisa {
		width: 100%;
		height: 90%;
		margin: 0 1rem;
		background-color: transparent;
		border: none;
	}

	.divText {
		color: #5c5b5b;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: left;
	}

	.text {
		color: #5c5b5b;
		font-weight: 500;
		font-family: "Roboto";
		font-size: 26px;
		margin-bottom: 2rem;
	}

	.selecionados {
		width: 100%;
		min-height: 8rem;
		display: flex;
		justify-content: left;

		@media only screen and (min-width: 1280px) {
			min-height: 4rem;
		}

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.paciente {
		font-size: 1.25rem;
		font-weight: 500;
		color: #8c8e86;

		height: 3rem;

		margin-right: 1.5rem;

		display: flex;
		flex-direction: row;
		align-items: baseline;

		svg {
			margin-right: 0.6rem;
		}
	}

	.item {
		width: 100%;
		height: 5rem;

		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.divTags {
		width: 100%;
		height: 40%;
		margin-bottom: 4rem;
		align-items: baseline;
		word-wrap: break-word;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}

		@media only screen and (max-width: 1280px) {
			margin-bottom: 2rem;
			height: 20%;
		}
	}

	.tag {
		background-color: #8c8e86;
		font-size: 20px;
		font-weight: 500;
		padding: 0.25rem 0.75rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
		display: inline-block;
		margin-bottom: 1.6rem;
		cursor: pointer;

		@media only screen and (min-width: 1280px) {
			margin-bottom: 0.6rem;
		}
	}

	.tagVermelha {
		background-color: ${colors.primary_blue_k_main};
		font-size: 20px;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
		display: inline-block;
		margin-bottom: 1.6rem;
		cursor: pointer;
	}

	.divButton {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.button {
		width: 50%;

		text-align: center;

		font-family: "Roboto";
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 0.7rem 3.3rem;

		border: 4px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}

	.divPesquisarTag {
		display: flex;
		flex-direction: column;
		width: 34rem;
		height: 23rem;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
		border-radius: 12px;
		border: 1px solid #e5e5e5;
		box-sizing: border-box;
		padding: 2.1rem;
		justify-content: space-between;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}

		@media only screen and (max-width: 1280px) {
			width: 34rem;
			height: 23rem;
		}

		@media only screen and (max-width: 1440px) {
			width: 100%;
			height: 15rem;
		}
	}

	.divAdicionarEtiqueta {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
	}

	.p {
		margin-left: 0.8rem;
		margin-right: 0.3rem;
		color: ${colors.primary_blue_k_main};
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
	}

	.value {
		color: ${colors.primary_blue_k_main};
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
	}
`;

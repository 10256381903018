import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import { checkLanguage } from "../../../../utils/texts";
import ContentDash from "../../../Dashboard/ContentDash";

const AdicionarProtocoloController: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	useEffect(() => {
		checkLanguage(language, dispatch);
	}, [language]);
	return (
		<ViewPort>
			<Menu setMenu="protocolos"></Menu>
			<ContentDash listagem={"adicionarProtocolo"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default AdicionarProtocoloController;

export const Types = {
	EDIT_ASSINATURA_PERFIL: "EDIT_ASSINATURA_PERFIL",
	EDIT_ASSINATURA_PERFIL_FINALLY: "EDIT_ASSINATURA_PERFIL_FINALLY",
	SET_ASSINATURA: "SET_ASSINATURA",
};

const INITIAL_STATE = {
	loading_assinatura: false,
	assinatura: null,
};

export default function assinatura(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.EDIT_ASSINATURA_PERFIL:
			return {
				...state,
				loading_assinatura: true,
			};
		case Types.EDIT_ASSINATURA_PERFIL_FINALLY:
			return {
				...state,
				loading_assinatura: false,
			};
		case Types.SET_ASSINATURA:
			return {
				...state,
				assinatura: null,
			};

		default:
			return state;
	}
}

export const AssinaturaActions = {
	set_assinatura: (data: any) => ({
		type: Types.SET_ASSINATURA,
		payload: data,
	}),
	edit_assinatura: (data: any) => ({
		type: Types.EDIT_ASSINATURA_PERFIL,
		payload: data,
	}),
	edit_assinatura_finally: () => ({
		type: Types.EDIT_ASSINATURA_PERFIL_FINALLY,
	}),
};


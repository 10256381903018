import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { EtiquetaProps } from "../../../interfaces/etiqueta.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { Container, Content } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalFiltroEtiquetas: React.FC = () => {
	const etiquetas: EtiquetaProps[] = useSelector(
		(state: any) => state.dashboard.etiquetas
	);
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_filtro_etiquetas = 
	useSelector(
		(state: any) => state.app.is_open_filtro_etiquetas
	);

	const { register, setValue } = useForm();

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_filtro_etiquetas(false));
	};

	const ModalCadastroCloseSave = () => {
		dispatch(AppActions.set_lista_filtro(listaTags));
		dispatch(AppActions.set_modal_filtro_etiquetas(false));
		const filtros_pacintes_request = {
			inicio: 0,
			fim: 200,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
			],
		};
		if (listaTags.length > 0) {
			filtros_pacintes_request.filtros.push({
				tipo: "ID_ETIQUETA",
				valor: listaTags,
			});
		}
		dispatch(
			DashBoardActions.set_filtros_pacintes_request(filtros_pacintes_request)
		);
		dispatch(DashBoardActions.paciente_request());
	};

	const lista_filtro = useSelector((state: any) => state.app.lista_filtro);

	const listaTags: any = lista_filtro;
	const AdicionarTag = (id: any) => {
		if (listaTags.indexOf(id) === -1) {
			listaTags.push(id);
		} else if (listaTags.indexOf(id) >= 0) {
			listaTags.splice(listaTags.indexOf(id), 1);
		}
	};

	useEffect(() => {
		etiquetas.map((etiqueta: EtiquetaProps) => {
			setValue(
				`${etiqueta.ID_ETIQUETA}`,
				listaTags.indexOf(etiqueta.ID_ETIQUETA) >= 0 ? true : false
			);
		});
	}, [listaTags]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_filtro_etiquetas}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_filtro_etiquetas}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<Content>
							<div className="divText">
								<p className="text">
									{current_language?.modal_filtro_etiqueta}
								</p>
							</div>
							<div className="etiquetas">
								<h4>{current_language?.modal_filtro_etiqueta2}</h4>
								{etiquetas.map((etiqueta: EtiquetaProps) => {
									return (
										<form key={etiqueta.ID_ETIQUETA} className="item">
											<span className="etiqueta">{etiqueta.ETIQUETA_NOME}</span>
											<div className="custom-checkbox">
												<input
													id={`class${etiqueta.ID_ETIQUETA}`}
													type="checkbox"
													onClick={() => AdicionarTag(etiqueta.ID_ETIQUETA)}
													{...register(`${etiqueta.ID_ETIQUETA}`, {})}
												/>
												<label htmlFor={`class${etiqueta.ID_ETIQUETA}`}></label>
											</div>
										</form>
									);
								})}
							</div>
						</Content>
						<button className="button" onClick={ModalCadastroCloseSave}>
							{current_language?.filtrar}
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalFiltroEtiquetas);

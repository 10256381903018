import styled from "styled-components";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	padding-right: 4.8rem;
	padding-left: 4.8rem;
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 80vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 85vh;
		width: 42vw;
	}

	.close {
		width: 1.738rem;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.title {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 30px;
		text-align: center;
		color: #5C5B5B;
		margin-bottom: 2rem;
	}

	.mid {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 2.8rem;
		background-color: #f2f2f2;
		padding: 0 0.75rem;
		border-radius: 3.75rem;
	}

	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}

	.inputPesquisa {
		width: 100%;
		height: 90%;
		margin: 0 1rem;
		background-color: transparent;
		border: none;
	}


	.div_pacientes { 
		width: 100%;
		height: 100%;
		margin-top: 2rem;
		overflow-x: hidden;
		overflow-y: scroll;
		cursor: pointer ;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
			margin: 2rem 0;
		}

		.item_paciente {
			width: 100%; 
			height: 20%; 

			display: flex ;
			flex-direction: row; 
			align-items: center;
			border-bottom: 1px solid #E5E5E5;

			.paciente_foto{
				height: 70%;
				width: 20%;
			}

			.paciente_nome {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				color: #001574;
			}
			
			

		}
	}
`;

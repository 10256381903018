import { CardProps } from "material-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import cartaoCredito from "../../../assets/icons/cartaoCredito.svg";
import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import mastercard from "../../../assets/icons/mastercard.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import { TextsProps } from "../../../database/txt.interface";
import { CartaoProps, FaturaProps } from "../../../interfaces/cartao.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { ConfigsActions } from "../../../store/ducks/configs";
import { FinancialActions } from "../../../store/ducks/financial";
import { Conteiner, BodyEdite, Footer } from "./styles";

enum TypeFatura {
	BOLETO = 'Boleto',
	PENDING = 'pending',
};

const Pagamentos: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	useEffect(() => {
		dispatch(FinancialActions.financial_request(
			config_servicos?.SERVICO_IUGU,
			config_servicos?.SERVICO_SUPERLOGICA
		));
	}, []);
	const faturas = useSelector((state: any) => state.financial.faturas);
	const cartao = useSelector((state: any) => state.financial.cartao);

	const fatura = faturas?.filter(
		(fatura: FaturaProps) => fatura?.FATURA_TIPO === TypeFatura?.BOLETO || fatura?.FATURA_TIPO === TypeFatura?.PENDING
	);

	const numberFaturas = fatura?.length;

	const handleVisualizarFatura = () => {
		dispatch(AppActions.set_open_visualizar_fatura(true));
	};
	const handleAdicionarMetodoPagamento = () => {
		dispatch(AppActions.set_open_adicionar_metodo_pagamento(true));
	};

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("pagamentos"));
	}, []);

	//UI
	return (
		<Conteiner>
			<BodyEdite>
				<div className="lineItens">
					{cartao?.map((card: CartaoProps) => {
						return (
							<div className="item" key={card.CC_NUMERO}>
								<div className="left">
									<div>
										{card?.CC_NUMERO?.toString().slice(0, 2) >= "51" &&
										card?.CC_NUMERO?.toString().slice(0, 2) <= "55" ? (
											<img src={mastercard} alt="Mastercard" />
										) : (
											<img src={cartaoCredito} alt="Cartão" />
										)}
									</div>
									<div className="info">
										<p className="tituloCard">
											{card?.CC_NUMERO?.toString().slice(0, 2) >= "51" &&
											card?.CC_NUMERO?.toString().slice(0, 2) <= "55"
												? `Mastercard ● ${current_language?.credito}`
												: card?.CC_NUMERO?.toString().slice(0, 1) >= "4"
												? `Visa ● ${current_language?.credito}`
												: `Cartão ● ${current_language?.credito}`}
										</p>
										<p>
											● ● ● ● ● ● ● ●{" "}
											{`${card?.CC_NUMERO?.toString().slice(-4)}`}
										</p>
									</div>
								</div>
								<div className="botaoSelecao">
									<div className="dropdown">
										<img className="pontos" src={Pontos} alt="" />
										<div className="dropdown-content">
											<Link to="metodo_pagamento">
												<button
													className="op"
													onClick={handleAdicionarMetodoPagamento}
												>
													<img src={Editar} className="img" alt="" />
													{current_language?.editar}
												</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						);
					})}
					{cartao?.lenght > 0 || cartao == undefined ? (
						<Link
							to="metodo_pagamento"
							className="Adicionar"
							onClick={handleAdicionarMetodoPagamento}
						>
							<svg
								width="25"
								height="25"
								viewBox="0 0 25 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.5002 23.3002C18.4649 23.3002 23.3002 18.4649 23.3002 12.5002C23.3002 6.53552 18.4649 1.7002 12.5002 1.7002C6.53552 1.7002 1.7002 6.53552 1.7002 12.5002C1.7002 18.4649 6.53552 23.3002 12.5002 23.3002Z"
									stroke="#001574"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M12.5 8.18018V16.8202"
									stroke="#001574"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.18066 12.5H16.8207"
									stroke="#001574"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<p>{current_language?.pagamento_adicionar_metodo}</p>
						</Link>
					) : (
						<Link
							to="metodo_pagamento"
							className="Adicionar"
							onClick={handleAdicionarMetodoPagamento}
						>
							<svg
								width="23"
								height="23"
								viewBox="0 0 23 23"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 3.12109H3C2.46957 3.12109 1.96086 3.33181 1.58579 3.70688C1.21071 4.08195 1 4.59066 1 5.12109V19.1211C1 19.6515 1.21071 20.1602 1.58579 20.5353C1.96086 20.9104 2.46957 21.1211 3 21.1211H17C17.5304 21.1211 18.0391 20.9104 18.4142 20.5353C18.7893 20.1602 19 19.6515 19 19.1211V12.1211"
									stroke="#001574"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z"
									stroke="#001574"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>

							<p>{current_language?.pagamento_editar_pagamento}</p>
						</Link>
					)}
				</div>
			</BodyEdite>
			<Footer>
			{numberFaturas > 0 && (
				<>
					<Link to="/faturas" className="item" onClick={handleVisualizarFatura}>
					<div className="left">
						<svg
							width="22"
							height="24"
							viewBox="0 0 22 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19.5556 2.66667V21.3333H2.44444V2.66667H19.5556ZM20.9 0H1.1C0.488889 0 0 0.533333 0 1.2V22.8C0 23.3333 0.488889 24 1.1 24H20.9C21.3889 24 22 23.3333 22 22.8V1.2C22 0.533333 21.3889 0 20.9 0ZM9.77778 5.33333H17.1111V8H9.77778V5.33333ZM9.77778 10.6667H17.1111V13.3333H9.77778V10.6667ZM9.77778 16H17.1111V18.6667H9.77778V16ZM4.88889 5.33333H7.33333V8H4.88889V5.33333ZM4.88889 10.6667H7.33333V13.3333H4.88889V10.6667ZM4.88889 16H7.33333V18.6667H4.88889V16Z"
								fill="#5C5B5B"
							/>
						</svg>

						<p>{current_language?.pagamento_faturas}</p>
					</div>
					<svg
						width="8"
							height="17"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</Link>
					<span
						className={`${
							numberFaturas > 0 ? "faturasPendentes" : "faturasNaoPendentes"
						}`}
					>{`${
						numberFaturas > 0
							? current_language?.pagamento_fatura_pendente
							: current_language?.menu_lateral_faturas_pendentes
					}`}</span>
				</>
			)}
			</Footer>
		</Conteiner>
	);
};

export default Pagamentos;

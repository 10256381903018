import styled from "styled-components";

export const InputText = styled.input`
	width: 22.875rem;

	display: flex;
	align-items: center;
	padding: 0.4rem 0;

	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 120%;

	border: 0;

	border-bottom: 1px solid #404040;

	color: #606060;

	::placeholder,
	::-webkit-input-placeholder {
		color: #d0d0d0;
	}
	:-ms-input-placeholder {
		color: #d0d0d0;
	}
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
`;

export const Question = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 120%;

	color: #404040;

	margin-bottom: 1.8rem;
`;

export const Body = styled.div`
	display: flex;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;

	margin-right: 1rem;
`;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnInformacao from "../../components/BtnInformacao";

import ToastAlert from "../../components/toast";
import { TextsProps } from "../../database/txt.interface";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import { AvaliationActions } from "../../store/ducks/avaliation";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import { AppActions } from "../../store/ducks/app";
import {
	TypesBtnInfo,
	TypesMenuInfoProps,
	TypesQuestionnaire,
	TypesQuestionnaireAnamneseTipo,
} from "../../utils/types";
import ExercicioSup from "./exercicioSup";
import Informacoes from "./Informacoes";
import Protocolos from "./protocolos";
import Questionario_progress from "./Questionario_progress";
import { Conteiner, Content, ContentType, Title } from "./styles";
import EditUser from "../../assets/icons/editUser.svg";
import DeletUser from "../../assets/icons/deletUser.svg";
import { ManageProtocolActions } from "../../store/ducks/manage_protocols";
import { AmplitudeLog } from "../../utils/amplitude";
import { colors } from "../../styles/colors";

enum typeSelected {
	information = "informacoes",
	superiors = "superiores",
	protocols = "protocolos",
}

const Paciente: React.FC = () => {
	const dispatch = useDispatch();
	const botaoRef = useRef<HTMLButtonElement>(null);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const OptionSelected = useSelector((state: any)=> state.app.set_selected_options_page_pattient);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const patients_most_accessed = useSelector(
		(state: any) => state.dashboard.pacientes_mais_visitados.LISTA
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	const [current_patient, set_current_patient]: any = useState(null);

	/**
	 *
	 * @returns Retorna os dados atualizados do paciente esteja ele na lista principal ou lista dos mais acessados
	 */
	function GetPatientInOrigin() {
		const patient_is_on_the_main_list = pacientes.filter(
			(item: PacientesProps) => item?.ID_USUARIO == paciente?.ID_USUARIO
		);
		let patient_is_on_the_most_accessed_list = undefined;
		if(patients_most_accessed){
			patient_is_on_the_most_accessed_list = patients_most_accessed.filter(
				(item: PacientesProps) => item?.ID_USUARIO == paciente?.ID_USUARIO
			);
		}
		return patient_is_on_the_main_list[0] != undefined
			? patient_is_on_the_main_list[0]
			: patient_is_on_the_most_accessed_list[0];
	}

	useEffect(() => {
		set_current_patient(GetPatientInOrigin);
	}, [pacientes_loading, pacientes, patients_most_accessed, current_patient]);

	useEffect(() => {
		const dataForAmplitude: Partial<PacientesProps> = {
			ID_USUARIO: current_patient?.ID_USUARIO,
			USUARIO_ATUALIZADO: current_patient?.USUARIO_ATUALIZADO,
			USUARIO_AVALIADOR: current_patient?.USUARIO_AVALIADOR,
			USUARIO_CONDICIONAMENTO: current_patient?.USUARIO_CONDICIONAMENTO,
			USUARIO_CRIADO: current_patient?.USUARIO_CRIADO,
			USUARIO_NOME: current_patient?.USUARIO_NOME,
			USUARIO_SEXO: current_patient?.USUARIO_SEXO,
			USUARIO_RESPONSAVEL: current_patient?.USUARIO_RESPONSAVEL,
		};
		if (OptionSelected === typeSelected.information) {
			setTimeout(() => {
				AmplitudeLog("info_pacientes");
			}, 200);
		}
		if (OptionSelected === typeSelected.protocols) {
			AmplitudeLog("executar_protocolo", {
				body: dataForAmplitude,
			});
		}
		if (OptionSelected === typeSelected.superiors) {
			AmplitudeLog("executar_exercicio", {
				body: dataForAmplitude,
			});
		}
	}, [OptionSelected]);

	const handleActiveClick = (active: string) => {
		dispatch(AppActions.set_selected_options_page_patients(active))
		dispatch(DashBoardActions.set_select_info(TypesMenuInfoProps.BTN_MENU));
		dispatch(ManageProtocolActions.set_button_goback(false));
	};

	const questionario = useSelector((state: any) => state.pac_aval.questionario);

	const handlePacienteEditar = (current_patient: any) => {
		dispatch(AppActions.set_modal_editar_paciente(true));
		dispatch(AppActions.set_open_paciente(current_patient));
	};

	const handleOpenExcluir = (current_patient: any) => {
		const obj = {
			mode: "paciente",
			id: current_patient,
		};

		dispatch(AppActions.set_modal_excluir({ status: true, parameter: obj }));
	};

	useEffect(() => {
		dispatch(DashBoardActions.execucoes_request(false));
		dispatch(DashBoardActions.assimetria_request());
		dispatch(DashBoardActions.anamneses_request());

		dispatch(AvaliationActions.set_force_option_max(3.6));
		dispatch(AvaliationActions.set_force_option_max_str("3 . 60"));

		dispatch(AvaliationActions.set_force_option_min(3));
		dispatch(AvaliationActions.set_force_option_min_str("3 . 00"));
	}, []);

	const [pacient, setPacient]: any = useState([]);

	//Faz calulo da idade
	const dataAtual = new Date();
	const anoAtual = dataAtual.getFullYear();
	const anoNascParts = current_patient?.USUARIO_NASCIMENTO?.split("-");

	let diaNasc = null;
	let mesNasc = null;
	let anoNasc = null;
	let idade = null;

	if (anoNascParts) {
		diaNasc = anoNascParts[2];
		mesNasc = anoNascParts[1];
		anoNasc = anoNascParts[0];
		idade = anoAtual - anoNasc;
		const mesAtual = dataAtual.getMonth() + 1;
		if (mesAtual < mesNasc) {
			idade--;
		} else {
			if (mesAtual === mesNasc) {
				if (new Date().getDate() < diaNasc) {
					idade--;
				}
			}
		}
	}

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		dispatch(DashBoardActions.exercices_request());
		dispatch(DashBoardActions.protocolos_request());
	}, []);

	useEffect(() => {
		AmplitudeLog("perfil_paciente", {
			body: {
				current_patient: current_patient?.ID_USUARIO,
			},
		});
	}, []);

	useEffect(() => {
		dispatch(PacAvalActions.set_questionario(""));
	}, []);

	const select_questionnaire = (quest: string) => {
		if (questionario === TypesQuestionnaire.ANAMNESE) {
			dispatch(PacAvalActions.set_tipo_anamnese(quest));
			dispatch(PacAvalActions.set_questionario_request(true));
		}
		if (questionario === TypesQuestionnaire.ANAMNESE_FISICA) {
			dispatch(PacAvalActions.set_tipo_anamnese(quest));
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_AVALIACAO)
			);
		}
	};

	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);

	const formatIMC = (weigth: string, heigth: string) => {
		return (
			parseFloat(weigth.replace(",", ".")) /
			(parseFloat(heigth.replace(",", ".")) *
				parseFloat(heigth.replace(",", ".")))
		).toFixed(2);
	};

	const assimetria = useSelector((state: any) => state.dashboard.assimetria);

	const CALCULO_IQ = assimetria[0]?.CALCULO_IQ || [];

	function openModalShereAnamnese(typeAnamnese: string) {
		if (questionario != "ANAMNESE_FISICA") {
			dispatch(AppActions.set_modal_compartilhamento(true, 10));
			dispatch(AppActions.set_continue_compartilhamento("ShereAnamnese"));
			select_questionnaire(typeAnamnese);
		} else {
			select_questionnaire(typeAnamnese);
		}
	}

	useEffect(() => {
		botaoRef?.current?.focus();
	}, []);

	function GetLastAvaliation() {
		if (execucoes.length > 0) {
			return execucoes[0].EXECUCAO_EXECUTADA.slice(0, 10)
				.replace(/-/g, "/")
				.split("/")
				.reverse()
				.join("/");
		} else {
			return "-";
		}
	}

	//UI
	return (
		<Conteiner id="DivPrincipal">
			{is_open_toast && <ToastAlert />}
			{questionario === TypesQuestionnaire.ANAMNESE ||
			questionario === TypesQuestionnaire.ANAMNESE_FISICA ? (
				<>
					<ContentType>
						<Title>{current_language?.escolha_anamnese}</Title>
						<div className="btns_type">
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_SAUDE}
								Texto={current_language?.saude}
								funcao={() => {
									openModalShereAnamnese(TypesQuestionnaireAnamneseTipo.SAUDE);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_saude"
											: "anamnese_saude"
									);
								}}
							/>
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_REABILITACAO}
								Texto={current_language?.reabilitacao}
								funcao={() => {
									openModalShereAnamnese(
										TypesQuestionnaireAnamneseTipo.REABILITACAO
									);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_reabilitacao"
											: "anamnese_reabilitacao"
									);
								}}
							/>
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_ESPORTIVA}
								Texto={current_language?.esportiva}
								funcao={() => {
									openModalShereAnamnese(
										TypesQuestionnaireAnamneseTipo.ESPORTIVO
									);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_esportiva"
											: "anamnese_esportiva"
									);
								}}
							/>
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_ESTETICA}
								Texto={current_language?.estetica}
								funcao={() => {
									openModalShereAnamnese(
										TypesQuestionnaireAnamneseTipo.ESTETICO
									);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_estetica"
											: "anamnese_estetica"
									);
								}}
							/>
						</div>
					</ContentType>
				</>
			) : questionario === TypesQuestionnaire.ANAMNESE_TIPO ? (
				<Questionario_progress
					key={TypesQuestionnaire.ANAMNESE}
					Type={TypesQuestionnaire.ANAMNESE}
				/>
			) : questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO ? (
				<Questionario_progress
					key={TypesQuestionnaire.ANAMNESE_AVALIACAO}
					Type={TypesQuestionnaire.ANAMNESE_AVALIACAO}
				/>
			) : questionario === TypesQuestionnaire.SF_36 ? (
				<Questionario_progress
					key={TypesQuestionnaire.SF_36}
					Type={TypesQuestionnaire.SF_36}
				/>
			) : (
				<Content>
					<div className="head">
						<div className="header">
							<div className="divFoto">
								<div className="conteinerImg">
									{current_patient?.USUARIO_FOTO != "" &&
									current_patient?.USUARIO_FOTO != null &&
									current_patient?.USUARIO_FOTO != "img/photo.png" ? (
										<img
											src={current_patient?.USUARIO_FOTO}
											alt=""
											className="imgForInput"
										/>
									) : (
										<svg
											width="100"
											height="100"
											viewBox="0 0 100 100"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="50"
												cy="50"
												r="48.5"
												fill="#EBEBEB"
												stroke="#E5E5E5"
												strokeWidth="3"
											/>
											<path
												d="M49.9984 48.3597C57.4707 48.3597 63.523 42.3075 63.523 34.8351C63.523 27.3628 57.4707 21.3105 49.9984 21.3105C42.5261 21.3105 36.4738 27.3628 36.4738 34.8351C36.4738 42.3075 42.5261 48.3597 49.9984 48.3597ZM49.9984 55.122C40.9707 55.122 22.9492 59.6528 22.9492 68.6466V75.4089H77.0476V68.6466C77.0476 59.6528 59.0261 55.122 49.9984 55.122Z"
												fill="#B3B3B3"
											/>
										</svg>
									)}
								</div>
							</div>
							<div className="dados">
								<div className="divNome">
									<p className="nome">{current_patient?.USUARIO_NOME}</p>
								</div>
								<div className="caracteristicas">
									<div className="headItem">
										<p className="crt">{current_language?.dash_idade}</p>
										<p className="value">{idade ? idade : "-"} anos</p>
									</div>
									<div className="headItem">
										<p className="crt">{current_language?.dash_peso} </p>
										<p className="value">
											{current_patient?.USUARIO_MASSA
												? current_patient?.USUARIO_MASSA
												: "-"}
										</p>
									</div>
									<div className="headItem">
										<p className="crt">
											{current_language?.menu_lateral_altura}
										</p>
										<p className="value">
											{current_patient?.USUARIO_ESTATURA
												? `${current_patient?.USUARIO_ESTATURA} m`
												: "-"}
										</p>
									</div>
									<div className="headItem">
										<p className="crt">{current_language?.imc}</p>
										<p className="value">
											{current_patient?.USUARIO_MASSA &&
											current_patient?.USUARIO_ESTATURA
												? `${formatIMC(
														current_patient?.USUARIO_MASSA,
														current_patient?.USUARIO_ESTATURA
												  )} kg/m²`
												: "-"}
										</p>
									</div>
									<div className="headItem">
										<p className="crt">{current_language?.ultimo_i_q}</p>

										<p
											className="value"
											style={{
												color:
													CALCULO_IQ.length > 0 &&
													parseInt(CALCULO_IQ[0]?.VALOR_DIREITO) > 50
														? colors.primary_blue_k_dark_2
														: colors.tertiary_red_1_dark,
											}}
										>
											{current_language?.iq_direito}:{" "}
											{CALCULO_IQ.length > 0
												? `${CALCULO_IQ[0]?.VALOR_DIREITO}`
												: "-"}{" "}
										</p>
										<p
											className="value"
											style={{
												color:
													CALCULO_IQ.length > 0 &&
													parseInt(CALCULO_IQ[0]?.VALOR_ESQUERDO) > 50
														? "green"
														: "red",
											}}
										>
											{current_language?.iq_esquerdo}:{" "}
											{CALCULO_IQ.length > 0
												? `${CALCULO_IQ[0]?.VALOR_ESQUERDO}`
												: "-"}{" "}
										</p>
									</div>
									<div className="headItem">
										<p className="crt">{current_language?.ultima_avaliacao}</p>
										<p className="value">Data: {GetLastAvaliation()}</p>
									</div>
									<div className="containerEdit">
										<button
											className="containerIconEditUser"
											onClick={() => {
												handlePacienteEditar(current_patient);
												AmplitudeLog("editar_paciente");
											}}
										>
											<img src={EditUser} />
										</button>
										<button
											className="containerIconDeleteUser"
											onClick={() => {
												handleOpenExcluir(current_patient);
												AmplitudeLog("excluir_paciente");
											}}
										>
											<img src={DeletUser} />
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="nav">
							<button
								className={`${
									OptionSelected == "informacoes" ? "selecao" : "selecao2"
								}`}
								autoFocus
								onClick={() => {
									handleActiveClick(typeSelected.information);
								}}
							>
								{current_language?.informacoes}
							</button>
							<button
								className={`${
									OptionSelected == "protocolos" ? "selecao" : "selecao2"
								}`}
								onClick={() => {
									handleActiveClick(typeSelected.protocols);
								}}
							>
								{current_language?.menu_lateral_protocolos}
							</button>
							<button
								className={`${
									OptionSelected == "superiores" ? "selecao" : "selecao2"
								}`}
								onClick={() => {
									handleActiveClick(typeSelected.superiors);
								}}
							>
								{current_language?.exercicios}
							</button>
						</div>
						<hr className="hr" />
					</div>

					{ OptionSelected == "informacoes" ? (
						<Informacoes autoFocus />
					) : OptionSelected == "protocolos" ? (
						<Protocolos />
					) : (
						OptionSelected == "superiores" && <ExercicioSup />
					)}
				</Content>
			)}
		</Conteiner>
	);
};

export default Paciente;

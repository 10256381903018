import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Excluir from "../../../assets/icons/excluir.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { AgrupamentosProps } from "../../../interfaces/agrupamento.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";
import { getPermission } from "../../../utils/funcs";
import { TypesLanguage, TypesPermission } from "../../../utils/types";
import BotaoAdicionar from "../../Dashboard/BotãoAdicionar";
import NenhumProtocolo from "../NenhumProtocolo";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import Lupa from "../../../assets/icons/lupa.svg";
import Perfil from "../../../assets/icons/perfilCompleto.svg";
import logoElastic from "../../../assets/kinologyIcons/svg/logo.svg";

import Limpar from "../../../assets/icons/limpar.svg";
import LupaFilterProtocol from "../../../assets/icons/lupaFilterProtocol.svg";

const ListagemProtocolos: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	//Use
	const protocolos: ProtocolosProps[] = useSelector(
		(state: any) => state.manage_protocol.protocolos
	);

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const exerciciosCurrent: Exercicios2Props[] = [];

	
	const exerciciosElastic = exercicios.filter((e: any) => e.ID_USUARIO == 0);
	exerciciosCurrent.push(...exerciciosElastic);
	
	const exerciciosCriados = exercicios.filter((e: any) => e.ID_USUARIO != 0);
	exerciciosCurrent.push(...exerciciosCriados.reverse());

	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);

	const [inputValueProtocol, setInputValueProtocol] = useState("");
	const [inputValueExercise, setInputValueExercise] = useState("");

	const atualizacao_proto: any = [];
	execucoes.map((e: any) => {
		atualizacao_proto.push({
			ID_PROTOCOLO: e.ID_PROTOCOLO,
			EXECUCAO_ATUALIZADA: e.EXECUCAO_ATUALIZADA,
			EXECUCAO_EXECUTADA: e.EXECUCAO_EXECUTADA,
		});
	});
	const data_protocolo = (number: any) => {
		const data: any = atualizacao_proto?.find(
			(e: any) => e?.ID_PROTOCOLO == number
		);
		return data?.EXECUCAO_ATUALIZADA;
	};

	const agrupamentos: AgrupamentosProps[] = useSelector(
		(state: any) => state.dashboard.agrupamentos
	);

	// Essa funcão dispara a troca a classe css dos botões e dispara funcão-
	// para a troca de listagem.
	const [selected, setSelected] = useState<string[]>(["Protocolos"]);
	const handleActiveClick = (active: string, listagem: boolean) => {
		const alreadySelected = selected.findIndex((item) => item === active);

		if (alreadySelected < 0) {
			setSelected([active]);
		}
	};

	function getGrupamento(exer: Exercicios2Props): AgrupamentosProps {
		const find: any = agrupamentos.find(
			(agru) => agru.ID_AGRUPAMENTO == exer.ID_AGRUPAMENTO
		);
		return find;
	}

	const prtBranco = false;

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("protocolos"));
	}, []);

	const handleOpenExcluirExercicio = (id: any) => {
		const obj = {
			mode: "exercicio",
			id: id,
		};
		dispatch(AppActions.set_modal_excluir({ status: true, parameter: obj }));
	};

	//Abre a modal para editar protocolos e exericios

	const permissionAddExercicio = () => {
		const accessAddExercicio = getPermission(
			config_servicos,
			TypesPermission.SERVICO_EXERCICIOS_PERSONALIZADOS
		);
		if (accessAddExercicio) {
			history.push("/adicionar_exercicio");
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		}
	};
	const permissionAddProtocol = (
		config_servicos: ServicosProps[],
		dispatch: any
	) => {
		const accessAddProtocol: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_PROTOCOLOS_PERSONALIZADOS
		);
		if (accessAddProtocol) {
			history.push("/adicionar_protocolo");
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		}
	};

	const handleProtocoloList = (protocolo: ProtocolosProps) => {
		dispatch(AppActions.set_protocolo_list(protocolo));
		history.push("/detalhes_protocolo");
	};

	const [protocolos_pesquisa, setProtocolosPesquisa] = useState<
		ProtocolosProps[]
	>([]);

	const [exercicios_pesquisa, setExerciciosPesquisa] = useState<
		Exercicios2Props[]
	>([]);

	const handleInputChangeProtocol = (event: any) => {
		setInputValueProtocol(event.target.value);
		if (inputValueProtocol != "") {
			const matchedProtocolos = protocolos.filter(
				(protocolo: ProtocolosProps) => {
					return (
						protocolo.PROTOCOLO_TITULO_PT.toLowerCase().indexOf(
							event.target.value.toLowerCase()
						) !== -1
					);
				}
			);
			setProtocolosPesquisa(matchedProtocolos);
		}
	};

	const handleInputChangeExercise = (event: any) => {
		setInputValueExercise(event.target.value);
		if (inputValueExercise != "") {
			const matchedExercises = exerciciosCurrent.filter(
				(exercicio: Exercicios2Props) => {
					return (
						exercicio.EXERCICIO_TITULO_PT.toLowerCase().indexOf(
							event.target.value.toLowerCase()
						) !== -1
					);
				}
			);
			setExerciciosPesquisa(matchedExercises);
		}
	};

	const resetInput = (type: string) => {
		type == "protocol" ? setInputValueProtocol("") : setInputValueExercise("");
	};
	const categorias_protocolo  = useSelector((state:any)=> state.dashboard.categorias_protocolos)

	const handleCategoriaTitulo = (protocolo:any)=> {

		const categoria = categorias_protocolo?.categorias_protocolo?.filter((item: any)=> item?.ID_CATEGORIA_PROTOCOLO == protocolo?.ID_CATEGORIA_PROTOCOLO)

		return language == TypesLanguage.PT 
			? categoria[0]?.CATEGORIA_TITULO_PT
			: language == TypesLanguage.EN
				?  categoria[0]?.CATEGORIA_TITULO_EN
				:  categoria[0]?.CATEGORIA_TITULO_ES
	};

	function _RenderProtocolList(protocol_list: Array<ProtocolosProps>) {
		return protocol_list.map((row) => (
			<div
				className="fichaExercicio"
				key={row.ID_PROTOCOLO}
				onClick={() => {
					handleProtocoloList(row);
					AmplitudeLog("visualizar_protocolo");
				}}
			>
				<div className="a">	
					<div className="handleLogoTitle">	
						<img  
							src={row.ID_USUARIO === 0 
								? logoElastic
								: Perfil
						}/>
							
						<p className="p">
							{language === TypesLanguage.PT
								? row.PROTOCOLO_TITULO_PT
								: language === TypesLanguage.EN
								? row.PROTOCOLO_TITULO_EN
								: row.PROTOCOLO_TITULO_EN}
						</p>

					</div>
					<div style={{flexDirection: 'row', display: 'flex'}}>

						<div className="divProtoSugerido">
								{row?.ID_USUARIO === 0 ? (
										<p className="pa" >{handleCategoriaTitulo(row)}</p>
								) : (
										<p className="pa">{current_language?.Criado_por_voce}</p>
								)}
						</div>
						{  row?.ID_USUARIO === 0 &&
							<p className="pipe">|</p>

						}
						{  row?.ID_USUARIO === 0 &&
							<div className="tempoEstimado">
								<p className="Time">{current_language?.tempo_estimado}: {row?.PROTOCOLO_TEMPO_ESTIMADO}</p>
							</div>
						}
					</div>
				</div>
			</div>
		));
	}

	function _renderExerciseList(exers: Exercicios2Props[]) {
		return exers.map((row: Exercicios2Props) => (
			<div className="fichaExercicio" key={row.ID_EXERCICIO}>
				<div className="ab">
					<div className="superior">
						<div className="Divtitle">
							<p className="pExer">
								{language === TypesLanguage.PT
									? row.EXERCICIO_TITULO_PT
									: language === TypesLanguage.EN
									? row.EXERCICIO_TITULO_EN
									: row.EXERCICIO_TITULO_ES}
							</p>
							<span className="span">
								{language === TypesLanguage.PT
									? getGrupamento(row)?.AGRUPAMENTO_DESCRICAO_PT
									: language === TypesLanguage.EN
									? getGrupamento(row)?.AGRUPAMENTO_DESCRICAO_EN
									: getGrupamento(row)?.AGRUPAMENTO_DESCRICAO_ES}
							</span>
						</div>
						<div className="dropdown">
							{row.ID_USUARIO !== 0 && (
								<>
									<img className="pontos" src={Pontos} alt="" />
									<div className="dropdown-content">
										<button
											className="op2"
											onClick={() => {
												handleOpenExcluirExercicio(row.ID_EXERCICIO);
												AmplitudeLog("excluir_exercicio");
											}}
										>
											<img src={Excluir} className="img" alt="" />
											{current_language?.excluir}
										</button>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="infosExercicios">
						<div className="rigth">
							{row.ID_USUARIO == 0 ? (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<img src={logoElastic}/>
									<p className="criado">{current_language?.original_elastic}</p>
								</div>
							) : (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<svg
										width="19"
										height="14"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12 12C15.315 12 18 9.315 18 6C18 2.685 15.315 0 12 0C8.685 0 6 2.685 6 6C6 9.315 8.685 12 12 12ZM12 15C7.995 15 0 17.01 0 21V24H24V21C24 17.01 16.005 15 12 15Z"
											fill="#8C8E86"
										/>
									</svg>
									<p className="criado">
										{current_language?.exercicio_criado_em}{" "}
										{language == TypesLanguage.PT
											? `${row?.EXERCICIO_ATUALIZADO.substr(0, 10)
													.split("-")
													.reverse()
													.join("/")}`
											: language == TypesLanguage.EN
											? `${row?.EXERCICIO_ATUALIZADO.substr(5, 5)
													.split("-")
													.join("/")}/${row?.EXERCICIO_ATUALIZADO?.substr(
													0,
													4
											  )}`
											: `${row?.EXERCICIO_ATUALIZADO.substr(0, 10)
													.split("-")
													.reverse()
													.join("-")}`}
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		));
	}

	//UI
	return (
		<Conteiner id="DivPrincipal">
			{is_open_toast && <ToastAlert />}
			<div className="divCabecalho">
				<div className="divNav">
					<button
						className={`${
							selected.includes("Protocolos") ? "selecao" : "selecao2"
						}`}
						onClick={() => {
							handleActiveClick("Protocolos", true);
							AmplitudeLog("aba_protocolo");
						}}
					>
						{current_language?.pag_inicial_menu_inferior_proto}
					</button>
					<button
						className={`${
							selected.includes("exercicios") ? "selecao" : "selecao2"
						}`}
						onClick={() => {
							handleActiveClick("exercicios", false);
							AmplitudeLog("aba_exericio");
						}}
					>
						{current_language?.exercicios}{" "}
					</button>
				</div>
			</div>

			{selected.includes("Protocolos") && (
				<>
					<div className="boxSearch">
						<div className="input">
							<button type="button" className={"reset"}>
								<img className="lupa" src={LupaFilterProtocol} alt="" />
							</button>
							<input
								// onKeyDown={Submit}
								value={inputValueProtocol}
								onChange={handleInputChangeProtocol}
								className="inputPesquisa"
								type="text"
								placeholder="Pesquisar protocolos"
							/>
							{inputValueProtocol != "" && (
								<button type="reset" className="reset">
									<img
										className="x"
										src={Limpar}
										alt=""
										onClick={()=> resetInput("protocol")}
									/>
								</button>
							)}
						</div>
					</div>

					<div className="divExercicio">
						{prtBranco ? (
							<NenhumProtocolo />
						) : (
							<div className="divExercicio">
								{_RenderProtocolList(
									inputValueProtocol != "" ? protocolos_pesquisa : protocolos
								)}
							</div>
						)}
					</div>
				</>
			)}

			{selected.includes("exercicios") && (
				<>
					<div className="boxSearch">
						<div className="input">
							<button type="button" className={"reset"}>
								<img className="lupa" src={LupaFilterProtocol} alt="" />
							</button>
							<input
								// onKeyDown={Submit}
								value={inputValueExercise}
								onChange={handleInputChangeExercise}
								className="inputPesquisa"
								type="text"
								placeholder="Pesquisar exercícios"
							/>
							{inputValueExercise != "" && (
								<button type="reset" className="reset">
									<img
										className="x"
										src={Limpar}
										alt=""
										onClick={()=> resetInput("exercise")}
									/>
								</button>
							)}
						</div>
					</div>

					<div className="divExercicio">
						{prtBranco ? (
							<NenhumProtocolo />
						) : (
							<div className="divExercicio">
								{_renderExerciseList(
									inputValueExercise != ""
										? exercicios_pesquisa
										: exerciciosCurrent
								)}
							</div>
						)}
					</div>
				</>
			)}

			{selected.includes("Protocolos") && (
				<div
					className="botaoAdd"
					onClick={() => (
						permissionAddProtocol(config_servicos, dispatch),
						AmplitudeLog("adicionar_protocolo")
					)}
					style={{ textDecoration: "none" }}
				>
					{!prtBranco && (
						<BotaoAdicionar patch="addProto">
							{current_language?.gerenciar_proto_criar}
						</BotaoAdicionar>
					)}
				</div>
			)}

			{selected.includes("exercicios") && (
				<div className="botaoAdd">
					<div
						onClick={() => {
							permissionAddExercicio(), AmplitudeLog("adicionar_exercicio");
						}}
						style={{ textDecoration: "none" }}
					>
						<BotaoAdicionar patch="addExe">
							{current_language?.novo_exercicio}
						</BotaoAdicionar>
					</div>
				</div>
			)}
		</Conteiner>
	);
};

export default ListagemProtocolos;

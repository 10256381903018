import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding-bottom: 5vh;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	border-radius: 1.25rem;

	.centralizar {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		height: 100%;
		width: 100%;
	}

	.nfunciona {
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		svg {
			margin-bottom: 1rem;
		}

		div {
			width: 50%;
		}

		.text {
			font-size: 1.1rem;
			font-weight: 400;
			color: #5c5b5b;
			text-align: center;
		}
	}

	.conteudo {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		height: 6.5rem;

		.divp1 {
			display: flex;
			flex-direction: row;
			width: 100%;

			.left {
				display: flex;
			}

			.mid {
				display: flex;
				width: 100%;
				flex-direction: column;
				margin-left: 2.4%;
				cursor: pointer;
				text-decoration: none;

				.nome {
					font-size: 1.25rem;
					margin-bottom: 5px;
					cursor: pointer;
				}

				.tags {
					display: flex;
					flex-direction: row;
					width: 100%;
					margin-top: 5px;

					.tag {
						background-color: #8c8e86;
						font-size: 0.75rem;
						font-weight: 500;
						border-radius: 2rem;
						color: white;
						margin-right: 2%;
						padding-left: 0.75rem;
						padding-right: 0.75rem;
						padding-top: 0.25rem;
						padding-bottom: 0.25rem;
						display: flow-root;
						flex-wrap: wrap;
						white-space: nowrap;
					}

					.tagSelected {
						background-color: ${colors.primary_blue_k_main};
					}
				}
			}
		}

		.right {
			display: flex;
			flex-direction: row;

			.PacienteEdata {
				display: flex;
				width: 100%;
				flex-direction: column;

				.Divpaciente {
					width: 120px;
					display: flex;
					flex-direction: row;
					margin-right: 1.9rem;

					.paciente {
						width: 100%;
						font-size: 1rem;
						color: #5c5b5b;
						font-weight: 400;
					}

					.dia {
						width: 100%;
					}
				}
			}

			.divOpcoes {
				width: 20%;
				height: 100%;
				display: flex;
				flex-direction: column;
				float: inline-end;
				justify-content: space-between;
			}
		}
	}

	.contador {
		width: 100%;
		height: 2.6rem;

		margin-top: 2rem;

		display: flex;
		align-items: center;
		justify-content: space-between;

		font-size: 1.25rem;
		font-weight: 500;

		color: #5c5b5b;

		span {
			margin-left: 0.3rem;
			color: #fa8c0b;
		}

		.selecionarTodos {
			font-size: 1rem;

			color: #001574;

			cursor: pointer;
		}
	}

	.b {
		width: 100%;
		height: 95%;

		.button {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;

			height: 15%;
		}
	}

	.body {
		width: 100%;
		height: 85%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.usuario {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		padding: 1.1rem;
		border-bottom: 1px solid #e5e5e5;
	}
	.usuario2 {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-bottom: 1px solid #e5e5e5;
	}

	.usuarioAzul {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}

	.avaliador {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: #b3b3b3;
	}

	.hr {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		border: none;
		height: 0.063rem;
		background-color: #e5e5e5;
	}

	.dropdown-content {
		display: none;
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		min-height: 7.6rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		z-index: 999999999;
		border-radius: 1.12rem;
		right: 0;
	}

	.dropdown-content-active {
		display: block;
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		min-height: 7.6rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
	}

	.op {
		font-size: 1.25rem;
		width: 100%;
		margin-bottom: 1.4rem;
		font-weight: 500;
		color: #5c5b5b;
		background-color: transparent;
		border: none;
	}

	.op2 {
		font-size: 1.25rem;
		width: 100%;
		color: ${colors.tertiary_red_1_dark};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}

	.botaoPontos {
		margin-top: 2rem;
		background-color: transparent;
		border: none;
	}

	.pontos {
		width: 1.25rem;
		height: 1.25rem;
		float: right;
	}

	.img {
		width: 1.18rem;
		height: 1.18rem;
		margin-right: 1rem;
	}

	.user {
		width: 1rem;
		height: 1rem;
		margin-right: 5%;
	}

	.dia {
		margin-top: 5%;
	}

	.btnAdicionar {
		display: flex;
		flex-direction: row;
		width: 13.75rem;
		height: 4.125rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
		@media (max-height: 900px) {
			margin-top: 1.5rem;
		}
	}

	.textoBotao {
		margin-left: 1rem;
	}

	.paginacao {
		display: flex;
		align-items: center;

		svg {
			cursor: pointer;
		}

		button {
			background: transparent;
			border: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.numerosPaginacao {
			display: flex;
			padding: 0 1rem;

			.marcado {
				background-color: #e5e5e5;

				border-radius: 50%;

				transition: background-color 0.2s ease-in;
			}

			p {
				width: 2rem;
				height: 2rem;

				color: #000;

				font-size: 16px;
				font-weight: 400;

				display: flex;
				justify-content: center;
				align-items: center;

				cursor: pointer;
			}
		}
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.dropdown {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.circulo {
		display: flex;
		width: 7rem;
		height: 7rem;
		display: flex;
		background-color:${colors.neutral_grey_k_10};
		justify-content: center;
		align-items: center;
		border-radius: 50% !important;
	}

	.divLoading {
		display: flex;
		flex-direction: column;
		align-items: center;

		.loader-container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 7rem;
			width: 7rem;
			position: absolute;
			z-index: 1;
		}

		.loader {
			border: 8px solid${colors.neutral_grey_k_10}; 
			border-top: 8px solid ${colors.secondary_green_k_main};
			border-radius: 100%;
			height: 7rem;
			width: 7rem;
			animation: spin 1s linear infinite; 
		}

		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}

		.texto {
			margin-top: 25px;
			color: #8d8d8d;
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
			text-align: center;
		}
	}
`;

	


import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Perfil from "../../../assets/icons/perfil.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps, UserProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { getEtiquetas } from "../../../utils/etiquetas";
import BotaoAdicionar from "../BotãoAdicionar";
import BotoesInferiores from "../BotõesInferiores";
import Loading from "../Loading";
import { Conteiner } from "./styles";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import moment from "moment";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { MudarPagina, getPermission } from "../../../utils/funcs";
import { TypesLanguage, TypesPermission } from "../../../utils/types";
import { ConfigsActions } from "../../../store/ducks/configs";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ServicosProps } from "../../../interfaces/config.interface";
import DinoHappy from "../../../assets/dinoHappyAsync.svg";
import ArrowRight from "../../../assets/icons/arrowRight.svg";
import ArrowLeft from "../../../assets/icons/arrowLeft.svg";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core";
import { AuthActions } from "../../../store/ducks/auth";
import { loadStorage, persistStorage } from "../../../utils/base_async_storage";
import { ServiceTypePatient } from "../../../interfaces/planos.interface";
import BotaoAdicionarCartao from "../BotaoAdicionarCartao/index";
import { colors } from "../../../styles/colors";

const Listagem: React.FC = () => {
	const dispatch = useDispatch();

	dispatch(AppActions.set_open_barraSuperior(false));

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const language = useSelector((state: any) => state.configs.language);

	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);
	const pacientes_listagem = useSelector((state: any) => state.dashboard.pacientes_listagem);

	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);

	const etis = useSelector((state: any) => state.dashboard.etiquetas);

	const lista_pacientes = useSelector(
		(state: any) => state.app.lista_pacientes
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const is_list = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	const is_open_atribuir_avaliador = useSelector(
		(state: any) => state.app.is_open_atribuir_avaliador
	);

	const is_open_atribuir_etiqueta = useSelector(
		(state: any) => state.app.is_open_atribuir_etiqueta
	);

	const is_open_atribuir_fisica = useSelector(
		(state: any) => state.app.is_open_atribuir_fisica
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const user_editation: any = useSelector((state: any)=> state?.auth?.data_user);

	const listaPacientes = lista_pacientes;

	const search = useSelector((state: any) => state.dashboard.search);

	const button_search = useSelector(
		(state: any) => state.dashboard.button_search
	);

	const lista_filtro = useSelector((state: any) => state.app.lista_filtro);

	const { register, setValue } = useForm();

	const [thumbIndex, setThumbIndex] = useState(0);

	const [thumbAnimation, setThumbAnimation] = useState(false);

	const [thumbs, setThumbs]: any = useState([]);

	const config_lista_usuarios = useSelector(
		(state: any) => state.dashboard.pacientes_mais_visitados
	);

	const mais_visitadosTexto = language == TypesLanguage.ES && config_lista_usuarios?.LISTA_TITULO_ES 
	|| language == TypesLanguage.EN && config_lista_usuarios?.LISTA_TITULO_EN || config_lista_usuarios?.LISTA_TITULO_PT;

	const pagina_atual = useSelector(
		(state: any) => state.dashboard.current_page_patient
	);
	const amount_executions: number = useSelector(
		(state: any) => state.dashboard.set_quantidade_execucoes,
    );
	//Logica paginacao
	const numero_de_paginas = Math.ceil(total_pacientes / 6);

	const categorias_protocolo  = useSelector((state:any)=> state.dashboard.categorias_protocolos)

	const modal_search_hardware_resposta: boolean = useSelector((state: any)=> state.app.modal_search_hardware_resposta )

	const HandleClassUsuario = (val: PacientesProps) => {
		dispatch(PacAvalActions.set_add_selected_patient(val));
		if (listaPacientes.indexOf(val.ID_USUARIO) === -1) {
			listaPacientes.push(val.ID_USUARIO);
		} else if (listaPacientes.indexOf(val.ID_USUARIO) >= 0) {
			listaPacientes.splice(listaPacientes.indexOf(val.ID_USUARIO), 1);
		}
		dispatch(AppActions.set_lista_pacientes(listaPacientes));
	};

	const selecionarTodos = () => {
		pacientes?.map((val: PacientesProps) => {
			dispatch(PacAvalActions.set_add_selected_patient(val));
			if (listaPacientes.indexOf(val.ID_USUARIO) === -1) {
				listaPacientes.push(val.ID_USUARIO);
			} else if (listaPacientes.indexOf(val.ID_USUARIO) >= 0) {
				listaPacientes.splice(listaPacientes.indexOf(val.ID_USUARIO), 1);
			}
			dispatch(AppActions.set_lista_pacientes(listaPacientes));
		});
	};

	const limparSelecao = () => {
		pacientes?.map((val: PacientesProps) => {
			dispatch(PacAvalActions.reset_patient());
			if (listaPacientes.indexOf(val.ID_USUARIO) >= 0) {
				listaPacientes.splice(listaPacientes.indexOf(val.ID_USUARIO), 1);
			}
			dispatch(AppActions.set_lista_pacientes(listaPacientes));
		});
	};

	function isPresentInListSelecteds(paciente: PacientesProps) {
		const is_present = selected_patients.find(
			(p: PacientesProps) => p.ID_USUARIO === paciente.ID_USUARIO
		);
		if (is_present) {
			return true;
		}
		return false;
	};

	const getPatientCarrossel = (paciente: PacientesProps)=> {
		const filtros_paginacao = {
			inicio: 0,
			fim: 1,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
				{tipo: "ID_USUARIO", valor: [paciente?.ID_USUARIO]}
			],
		};

		dispatch(
			DashBoardActions.set_filtros_pacintes_request(filtros_paginacao)
		);
		dispatch(DashBoardActions.paciente_request());
		dispatch(AppActions.set_open_paciente(paciente));
	};

	const handlePaciente = (paciente: any) => {

		const obj = {
			name: paciente?.USUARIO_NOME,
			value: "paciente",
		};
		const payload = {
			ID_USUARIO: paciente?.ID_USUARIO,
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
		};
		dispatch(AppActions.set_open_paciente(paciente));
		dispatch(PacAvalActions.set_perfil_anamnese_patient(paciente?.ID_USUARIO));
		dispatch(AppActions.set_open_barraSuperior(obj));
		dispatch(AppActions.set_confirm_close_modal_edit(true));
		dispatch(AddPacAvalActions.set_recorrente(payload));
		AmplitudeLog("paci_mais_visitados", {
			body: {
				paciente: payload,
			}
		});
		const permissionAssessmentPhysical: any = getPermission(
			config_servicos as ServicosProps[],
			TypesPermission.SERVICO_ANAMNESE_FISICA
		);
		if (!permissionAssessmentPhysical) {
			dispatch(ConfigsActions.set_close_modal_upgrade_and_goback(true));
			dispatch(
				ConfigsActions.set_physical_anamnesis_verification_by_the_most_visited_patients(
					permissionAssessmentPhysical
				)
			);
		}
	};


	useEffect(()=> {
		if(categorias_protocolo?.length == 0){
			dispatch(DashBoardActions.call_categorias_protocolos())
		}
	},[])

	useEffect(()=> {
		dispatch(DashBoardActions.set_questionario_satisfacao_verificar_tipos_modal_satisfacao_exame(['EXAME_1','EXAME_2']))
	},[amount_executions])
	
	useEffect(()=>{
		if(user_editation.length === 0){
			dispatch(AuthActions.edit_user_profile(user_logado))
		}
	},[user_logado, user_editation])

	useEffect(()=>{
		dispatch(AppActions.set_selected_options_page_patients('informacoes'))
	},[])

	useEffect(() => {
		selected_patients.length === 0
			? pacientes?.map((pac: PacientesProps) => {
					setValue(
						`${pac.ID_USUARIO}`,
						lista_pacientes.indexOf(pac.ID_USUARIO) >= 0 ? true : false
					);
			  })
			: selected_patients.map((pac: PacientesProps) => {
					setValue(
						`${pac.ID_USUARIO}`,
						lista_pacientes.indexOf(pac.ID_USUARIO) >= 0 ? true : false
					);
			  });
	}, [lista_pacientes, selected_patients, listaPacientes]);

	useEffect(() => {
		if (
			is_open_atribuir_avaliador == false &&
			is_open_atribuir_etiqueta == false &&
			is_open_atribuir_fisica == false
		) {
			limparSelecao();
		}
	}, [
		is_open_atribuir_avaliador,
		is_open_atribuir_etiqueta,
		is_open_atribuir_fisica,
	]);

	const thumbItems = (it: any, [setThumbIndex, setThumbAnimation]: any) => {
		return config_lista_usuarios?.LISTA?.map((e: any, i: any) => (
			<div
				key={i}
				data-value={i}
				className="carrosselPacientes"
				onClick={() => (setThumbIndex(i), setThumbAnimation(true))}
			>
				<Link
					to="/paciente"
					onClick={() => {handlePaciente(e), getPatientCarrossel(e)}}
					className="carrosselFoto link"
					draggable="false"
				>
					{e?.USUARIO_FOTO != "" &&
					e?.USUARIO_FOTO != null &&
					e?.USUARIO_FOTO != "img/photo.png" ? (
						<img
							draggable="false"
							src={e?.USUARIO_FOTO}
							alt=""
							className="carrosselFoto"
						/>
					) : (
						<img
							draggable="false"
							src={Perfil}
							alt=""
							className="carrosselFoto"
						/>
					)}
				</Link>
				<Link
					draggable="false"
					to="/paciente"
					onClick={() => {handlePaciente(e), getPatientCarrossel(e)}}
					className="carrosselNome link"
				>
					{e?.USUARIO_NOME.substring(0, 15)}
					{e?.USUARIO_NOME.length > 15 && "..."}
				</Link>
			</div>
		));
	};

	const slideNext = () => {
		if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
			setThumbAnimation(true);
			setThumbIndex(thumbIndex + 1);
		}
	};

	const slidePrev = () => {
		if (!thumbAnimation && thumbIndex > 0) {
			setThumbAnimation(true);
			setThumbIndex(thumbIndex - 1);
		}
	};

	const syncThumbs = (e: any) => {
		setThumbIndex(e.item);
		setThumbAnimation(false);
	};

	const useStyles = makeStyles(() => ({
		root: {
			"& .MuiPagination-ul": {
				justifyContent: "center",
			},
			"& .MuiPaginationItem-root": {
				color: "black",
				"&:hover": {
					backgroundColor: colors?.primary_blue_k_medium_1,
					color: "white",
				},
			},
			"& .MuiPagination-outlined": {
				backgroundColor: colors?.primary_blue_k_medium_1,
			},
			"& .Mui-selected": {
				backgroundColor: colors?.primary_blue_k_medium_1,
				color: colors?.primary_blue_k_medium_1,
				"&:hover": {
					color: "black",
				},
			},
		},
	}));

	const classes = useStyles();


	const PesquisarPaciente = () => {
		const filtros_paginacao = {
			inicio: 0,
			fim: 6,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
			],
		};

		if (search != "") {
			filtros_paginacao.filtros.push({ tipo: "USUARIO_NOME", valor: search });
		}

		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));
		dispatch(DashBoardActions.paciente_request());
	};

	useEffect(() => {
		!is_list &&
			setThumbs(thumbItems(config_lista_usuarios?.LISTA, [setThumbIndex, setThumbAnimation]));
	}, [config_lista_usuarios?.LISTA, is_list]);

	useEffect(() => {
		if (button_search) {
			PesquisarPaciente();
			dispatch(DashBoardActions.set_button_search(false));
		}
	}, [button_search]);

	function _renderPacientes(lista: any) {
		return lista.map(
			(row: PacientesProps) =>
				row !== undefined && (
					<div className="conteudo" id="conteudo" key={row.ID_USUARIO}>
						<div
							className={isPresentInListSelecteds(row) ? "usuario2" : "usuario"}
							id={row.USUARIO_NOME}
						>
							<div className="divp1">
								<div className="left">
									{row.USUARIO_FOTO != "" &&
									row.USUARIO_FOTO != null &&
									row.USUARIO_FOTO != "img/photo.png" ? (
										<div className="conteinerImg">
											<img
												src={row.USUARIO_FOTO}
												alt=""
												className="imgForInput"
											/>
										</div>
									) : (
										<div className="conteinerImg">
											<img src={Perfil} alt="" className="imgForInput" />
										</div>
									)}
								</div>
								<Link
									to="/paciente"
									className="mid"
									onClick={() => {
										handlePaciente(row), AmplitudeLog("pacientes", {
											body:{
												paciente: row?.ID_USUARIO,
											}
										});
									}}
								>
									<h1 className="nome">{row.USUARIO_NOME}</h1>
									<p className="avaliador"></p>
									<div className="tags">
										{getEtiquetas(row, etis, lista_filtro).map((e: any) => {
											return (
												<span
													key={e.ID_ETIQUETA}
													className={`tag ${
														lista_filtro.indexOf(e.ID_ETIQUETA) >= 0 &&
														"tagSelected"
													}`}
												>
													{e.ETIQUETA_NOME}
												</span>
											);
										})}
									</div>
								</Link>
							</div>
							<div className="right">
								<div className="divOpcoes">
									<div className="custom-checkbox">
										<input
											id={row.ID_USUARIO.toString()}
											type="checkbox"
											onClick={() => HandleClassUsuario(row)}
											{...register(`${row.ID_USUARIO}`, {})}
										/>
										<label htmlFor={row.ID_USUARIO.toString()}></label>
									</div>
								</div>
							</div>
						</div>
						<div className="divisor">
							<hr className="hr" />
						</div>
					</div>
				)
		);
	}
	return (
		<Conteiner id="DivPrincipal">
			{is_list && <Loading />}
			{!is_list && (
				<>
					{config_lista_usuarios?.LISTA?.length > 0 && (
						<>
							<div className="maisVisitados">{mais_visitadosTexto}</div>

							<div className="pacientesRecorrentes thumbs">
								<button className="setasCarrosselEsq" onClick={slidePrev}>
									<img src={ArrowRight} alt="" />
								</button>
								<AliceCarousel
									activeIndex={thumbIndex}
									autoWidth
									disableDotsControls
									disableButtonsControls
									items={thumbs}
									mouseTracking={true}
									onSlideChanged={syncThumbs}
								/>
								<button className="setasCarrosselDir" onClick={slideNext}>
									<img src={ArrowLeft} alt="" />
								</button>
							</div>
						</>
					)}

					<div className="contador">
						<p>
							{current_language?.lista_pacientes}{" "}
							<span>
								{total_pacientes}/
								{config_servicos?.SERVICO_PACIENTES > 1000000 || config_servicos?.SERVICO_PACIENTES_TIPO == ServiceTypePatient?.MENSAL 
									? current_language?.ilimitado
									: config_servicos?.SERVICO_PACIENTES}
							</span>
						</p>
						{lista_pacientes.length > 0 ? (
							<p className="selecionarTodos" onClick={limparSelecao}>
								{current_language?.limpar_selecao}
							</p>
						) : (
							<p className="selecionarTodos" onClick={selecionarTodos}>
								{current_language?.selecionar_todos}
							</p>
						)}
					</div>

					<div className="b">
						<div className="body">
							{pacientes?.length <= 0 && (
								<div className="nfunciona" key={pacientes?.length}>
									<img src={DinoHappy} alt="" />
									<div className="text">{current_language?.no_paciente}</div>
								</div>
							)}
							{_renderPacientes(pacientes)}
						</div>
						<div className="button">
							<div className="paginacao">
								<Pagination
									onChange={(event, pagina_desejada)=>	{
										MudarPagina(
											pagina_desejada,
											numero_de_paginas,
											dispatch,
											[3],
											search
										)
										dispatch(DashBoardActions.set_number_page(pagina_desejada))
									}}
									page={pagina_atual}
									className={classes.root}
									size="large"
									count={numero_de_paginas}
								/>
							</div>

							{selected_patients.length > 0 ? (
								<BotoesInferiores />
							) : (
								<>
									<div 
										className="ContainerButons"
										style={{
											justifyContent: config_servicos?.SERVICO_IUGU || config_servicos?.SERVICO_SUPERLOGICA 
											? 'space-between'
											: 'flex-end'
										}}
									>
										{ 
											(config_servicos?.SERVICO_IUGU || config_servicos?.SERVICO_SUPERLOGICA) &&
												<BotaoAdicionarCartao patch="addPc">
													{current_language?.cadastrar_cartao}
												</BotaoAdicionarCartao>
										}
										<BotaoAdicionar patch="addPc">
											{current_language?.adicionar_paciente}
										</BotaoAdicionar>
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</Conteiner>
	);
};

export default Listagem;

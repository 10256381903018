import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}

body{
    background-color: white;
    color: #B3B3B3;
    -webkit-font-smoothing: antialiased;
}

//Regular quando tiver o design
body, input, button{
    font-family: 'Roboto', serif;
    font-size: 18px;
}

h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500;
    color: #000E4B;
}

.CalendarDay__selected_span {
    background:#5FDFAC; 
    color: white; 
    border: 1px solid #00D084; 
}

.CalendarDay__selected {
    background: #00A377;
    color: white;
}

button{
  cursor: pointer;
}

`;

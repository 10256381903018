import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	padding-left: 3.1rem;
	padding-right: 3.1rem;
	position: relative;
	z-index: 1;
`;

export const Title = styled.div`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1.375rem;
	line-height: 120%;

	margin-bottom: 1.367rem;

	color: ${colors.primary_blue_k_main};
`;

export const ContentType = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	justify-content: center;
	align-items: center;

	.btns_type {
		display: flex;
		justify-content: center;
		button {
			margin: 4rem 1rem;
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.head {
		display: flex;
		flex-direction: column;
	}

	.header {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 1rem;
	}

	.divFoto {
		width: 6.25rem;
		background-color: white;
	}

	.imgForInput {
		width: 6.25rem;
		height: 6.25rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.dados {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: white;
	}

	.divNome {
		display: flex;
		padding-left: 2.1rem;
		width: 100%;
		height: 50%;
		background-color: white;
		align-items: center;
	}

	.nome {
		color: #5c5b5b;
		font-size: 1.37rem;
		font-weight: 700;
	}

	.caracteristicas {
		display: flex;
		flex-direction: row;
		padding-left: 2.1rem;
		width: 100%;
		height: 50%;
		align-items: flex-start;
	}

	.containerEdit{
		display:flex;
		width: 190px;
		height:30px;
		flex-direction: row;
		justify-content: flex-end;
	}

	.containerIconEditUser{
		margin-right:70px;
		border: none;
		background-color: transparent;
	}

	.containerIconDeleteUser{
		margin-right:20px;
		border: none;
		background-color: transparent;
	}

	.headItem {
		margin-right: 3.688rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.crt {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 120%;

		color: #404040;
	}

	.value {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: .75rem;
		line-height: 120%;

		text-align: center;

		color: #404040;
	}

	.nav {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 4.5rem;
		background-color: white;
		margin-bottom: 1.3rem;
		z-index: 20;
	}

	.selecao {
		height: 100%;
		font-size: 1.25rem;
		margin-right: 2.6rem;
		color: #001574;
		font-weight: 500;
		background-color: transparent;
		border-bottom: 3px solid #001574;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		height: 100%;
		font-size: 1.25rem;
		margin-right: 2.6rem;
		color: #5c5b5b;
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.hr {
		margin-top: -1.3rem;
		width: 100%;
		border: none;
		height: 1px;
		background-color: #e0e0e0;
		z-index: 20;
	}
`;

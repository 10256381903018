import { EtiquetaProps } from "../interfaces/etiqueta.interface";
import { PacientesProps } from "../interfaces/pacientes.interface";

export function getEtiquetas(
	paciente: PacientesProps,
	all_etiquetas: EtiquetaProps[],
	lista_filtro: any[]
) {
	const aux_filter: any = [];
	all_etiquetas.forEach((et: EtiquetaProps) => {
		et?.ID_USUARIOS.filter((n: number) => {
			if (n === paciente.ID_USUARIO) {
				if (lista_filtro.indexOf(et.ID_ETIQUETA) >= 0) {
					aux_filter.unshift(et);
				} else if (aux_filter.length <= 3) {
					aux_filter.push(et);
					//console.log(et, 'et')
				}
			}
		});
	});

	if (aux_filter.length >= 4) {
		aux_filter[3] = { ETIQUETA_NOME: "..." };
		while (aux_filter.length > 4) {
			aux_filter.pop();
		}
	}

	return aux_filter;
}

import styled from "styled-components";
import { colors } from "../../../../../styles/colors";

export const Body = styled.div`
	.semAviso {
		color: #7D7D7D;
	}

	.aviso {
		color: #D40000;
		-webkit-animation: shake 1s linear 2;
		animation: shake 1s linear 2;
	}
		
	@-webkit-keyframes shake {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		5% {
			-webkit-transform: rotate(5deg);
			transform: rotate(5deg);
		}

		15% {
			-webkit-transform: rotate(-4deg);
			transform: rotate(-4deg);
		}

		25% {
			-webkit-transform: rotate(4deg);
			transform: rotate(4deg);
		}

		35% {
			-webkit-transform: rotate(-3deg);
			transform: rotate(-3deg);
		}

		45% {
			-webkit-transform: rotate(2deg);
			transform: rotate(2deg);
		}

		55% {
			-webkit-transform: rotate(-1deg);
			transform: rotate(-1deg);
		}

		60% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
	}

	@keyframes shake {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		5% {
			-webkit-transform: rotate(5deg);
			transform: rotate(5deg);
		}

		15% {
			-webkit-transform: rotate(-4deg);
			transform: rotate(-4deg);
		}

		25% {
			-webkit-transform: rotate(4deg);
			transform: rotate(4deg);
		}

		35% {
			-webkit-transform: rotate(-3deg);
			transform: rotate(-3deg);
		}

		45% {
			-webkit-transform: rotate(2deg);
			transform: rotate(2deg);
		}

		55% {
			-webkit-transform: rotate(-1deg);
			transform: rotate(-1deg);
		}

		60% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
	}
`;

export const InsetPhotos = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 23.268rem;
	height: 4.45rem;

	background: #FCFCFC;

	border: 0.508592px solid #EDEDED;
	box-shadow: 0px 2.03437px 2.03437px rgba(0, 0, 0, 0.25);
	border-radius: 16.275px;

	cursor: pointer;

	input {
		display: none;
	}
	label {
		width: 100%;
		height: 100%;

		padding: 1rem;

		border-radius: 16.275px;

		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		div {
			display: flex;
			align-items: center;
			svg {
				margin-right: 1rem;
			}
			p {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 400;
				font-size: 1.399rem;
				line-height: 120%;

				color: ${colors.primary_blue_k_main};
			}
		}
	}
`;

export const Getphotos = styled.div`
	width: 23.268rem;
	min-height: 3.813rem;
	padding: .5rem;

	background: #FCFCFC;

	border: 0.508592px solid #EDEDED;
	box-shadow: 0px 2.03437px 2.03437px rgba(0, 0, 0, 0.25);
	border-radius: 16.275px;

	margin-top: 1.375rem;

  display: grid;
	justify-content: center;
  gap: .5rem .5rem;
	grid-template-columns: repeat(5, 3.813rem);

	.excluir {
		background-color: #FFF;

		width: 1.15rem;
		height: 1.15rem;

		border: 0;

		display: flex;
		justify-content: center;
		align-items: center;

		border-radius: 50%;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;

		text-align: right;

		position: absolute;
		top: -.3rem;
		right: -.3rem;

		cursor: pointer;
	}

	div {
		position: relative;
	}

	.foto {
		overflow: hidden;
		width: 3.813rem;
		height: 3.813rem;
		border-radius: 8px;

		background-color: #EDEDED;

		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	img {
		border-radius: 8px;
		max-height: 100%;
		height: auto;
		width: auto;
	}
`;

export const Aviso = styled.p`
	width: 23.268rem;

	margin-top: .55rem;

	display: flex;
	justify-content: flex-end;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 120%;
	/* or 14px */

	text-align: right;
	`;
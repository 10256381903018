import { EtiquetaProps } from "../../interfaces/etiqueta.interface";

export const Types = {
	OPEN_MODAL_TAG: "OPEN_MODAL_TAG",
	CLOSE_MODAL_TAG: "CLOSE_MODAL_TAG",

	SET_USER_RECIPIENT_TAG: "SET_USER_RECIPIENT_TAG",

	SET_REDS: "SET_REDS",
	RESET_REDS: "RESET_REDS",

	SET_ADD_SELECT_TAGS: "SET_ADD_SELECT_TAGS",
	RESET_SELECT_TAGS: "RESET_SELECT_TAGS",

	SET_CURRENT_TAG_EDITION: "SET_CURRENT_TAG_EDITION",
	SET_USER_DELETE: "SET_USER_DELETE",

	SUCCES_DELETE_TIMER: "SUCCES_DELETE_TIMER",
	SUCCES_DELETE_TIMER_TAG: "SUCCES_DELETE_TIMER_TAG",

	SET_TAG_FILTERED: "SET_TAG_FILTERED",
	OPEN_MODAL_TAG_FILTERED: "OPEN_MODAL_TAG_FILTERED",

	ADD_TAG_REQUEST: "ADD_TAG_REQUEST",
	ADD_TAG_CANCEL: "ADD_TAG_CANCEL",

	SET_TAGS: "SET_TAGS",

	SET_DELETE_TAGS: "SET_DELETE_TAGS",
	SET_DELETE_TAGS_CANCEL: "SET_DELETE_TAGS_CANCEL",

	SET_EDITAR_TAGS: "SET_EDITAR_TAGS",
	SET_EDITAR_TAGS_CANCEL: "SET_EDITAR_TAGS_CANCEL",

	SET_ATRIBUIR_TAG: "SET_ATRIBUIR_TAG",
	SET_ATRIBUIR_TAG_CANCEL: "SET_ATRIBUIR_TAG_CANCEL",
};

const INITIAL_STATE = {
	modal_tag_open: false,
	user_recipient_tags: null,
	selecteds_tags: [],
	reds: [],
	is_tag_edit: false,
	current_tag_edition: null,
	user_delete: null,

	success_delete_timer: false,
	success_delete_timer_tag: false,

	tags_filtered: [],
	modal_tag_filtered: false,

	loading_add_tag: false,

	loading_atribuir_tag: false,
};

export default function tags(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.OPEN_MODAL_TAG:
			return {
				...state,
				modal_tag_open: true,
				is_tag_edit: action.payload,
			};

		case Types.CLOSE_MODAL_TAG:
			return {
				...state,
				modal_tag_open: false,
			};

		case Types.SET_USER_RECIPIENT_TAG:
			return {
				...state,
				user_recipient_tags: action.payload,
			};

		case Types.RESET_REDS:
			return {
				...state,
				reds: [],
			};
		case Types.RESET_SELECT_TAGS:
			return {
				...state,
				selecteds_tags: [],
			};

		case Types.SET_ADD_SELECT_TAGS:
			let auxListTags: any = [];
			const find_add_tag: any = state.selecteds_tags.find(
				(tag: EtiquetaProps) => tag.ID_ETIQUETA == action.payload.ID_ETIQUETA
			);
			if (!!find_add_tag) {
				auxListTags = state.selecteds_tags.filter(
					(tag: EtiquetaProps) => tag.ID_ETIQUETA != action.payload.ID_ETIQUETA
				);
			} else {
				auxListTags = [...state.selecteds_tags, action.payload];
			}
			return {
				...state,
				selecteds_tags: [...auxListTags],
			};

		case Types.SET_REDS:
			let newLisReds: any = [];
			newLisReds = [...state.reds, action.payload];
			return {
				...state,
				reds: [...newLisReds],
			};

		case Types.SET_CURRENT_TAG_EDITION:
			return {
				...state,
				current_tag_edition: action.payload,
			};
		case Types.SET_USER_DELETE:
			return {
				...state,
				user_delete: action.payload,
			};
		case Types.SUCCES_DELETE_TIMER:
			return {
				...state,
				success_delete_timer: action.payload,
			};
		case Types.SUCCES_DELETE_TIMER_TAG:
			return {
				...state,
				success_delete_timer_tag: action.payload,
			};
		case Types.SET_TAG_FILTERED:
			let auxListFilterTags: any = [];
			const find_filter_tag: any = state.tags_filtered.find(
				(tag: EtiquetaProps) => tag.ID_ETIQUETA == action.payload.ID_ETIQUETA
			);
			if (!!find_filter_tag) {
				auxListFilterTags = state.tags_filtered.filter(
					(tag: EtiquetaProps) => tag.ID_ETIQUETA != action.payload.ID_ETIQUETA
				);
			} else {
				auxListFilterTags = [...state.tags_filtered, action.payload];
			}
			return {
				...state,
				tags_filtered: [...auxListFilterTags],
			};
		case Types.OPEN_MODAL_TAG_FILTERED:
			return {
				...state,
				modal_tag_filtered: action.payload,
			};
		case Types.ADD_TAG_REQUEST:
			return {
				...state,
				loading_add_tag: true,
			};
		case Types.ADD_TAG_CANCEL:
			return {
				...state,
				loading_add_tag: false,
			};

		case Types.SET_TAGS:
			return {
				...state,
				selecteds_tags: action.payload,
			};

		case Types.SET_DELETE_TAGS:
			return {
				...state,
				loading_add_tag: true,
			};

		case Types.SET_DELETE_TAGS_CANCEL:
			return {
				...state,
				loading_add_tag: false,
			};

		case Types.SET_EDITAR_TAGS:
			return {
				...state,
				loading_add_tag: true,
			};

		case Types.SET_EDITAR_TAGS_CANCEL:
			return {
				...state,
				loading_add_tag: false,
			};

		case Types.SET_ATRIBUIR_TAG:
			return {
				...state,
				loading_atribuir_tag: true,
			};

		case Types.SET_ATRIBUIR_TAG_CANCEL:
			return {
				...state,
				loading_atribuir_tag: false,
			};

		default:
			return state;
	}
}

export const TagActions = {
	openModalTag: (is_editing = false) => ({
		type: Types.OPEN_MODAL_TAG,
		payload: is_editing,
	}),
	closeModalTag: () => ({
		type: Types.CLOSE_MODAL_TAG,
	}),
	set_user_recipient: (user: any) => ({
		type: Types.SET_USER_RECIPIENT_TAG,
		payload: user,
	}),
	set_reds: (tag: any) => ({
		type: Types.SET_REDS,
		payload: tag,
	}),
	reset_reds: () => ({
		type: Types.RESET_REDS,
	}),
	reset_select_tag: () => ({
		type: Types.RESET_SELECT_TAGS,
	}),
	set_add_tags: (tag: any) => ({
		type: Types.SET_ADD_SELECT_TAGS,
		payload: tag,
	}),
	set_current_tag_edition: (tag: any) => ({
		type: Types.SET_CURRENT_TAG_EDITION,
		payload: tag,
	}),
	set_user_delete: (user: any) => ({
		type: Types.SET_USER_DELETE,
		payload: user,
	}),
	set_delete_timer: (status: any) => ({
		type: Types.SUCCES_DELETE_TIMER,
		payload: status,
	}),
	set_delete_timer_tag: (status: any) => ({
		type: Types.SUCCES_DELETE_TIMER_TAG,
		payload: status,
	}),
	set_tag_filtered: (tag: any) => ({
		type: Types.SET_TAG_FILTERED,
		payload: tag,
	}),
	openModalTagFiltered: (status: any) => ({
		type: Types.OPEN_MODAL_TAG_FILTERED,
		payload: status,
	}),

	add_tag_request: (tag: any) => ({
		type: Types.ADD_TAG_REQUEST,
		payload: tag,
	}),
	add_tag_cancel: () => ({
		type: Types.ADD_TAG_CANCEL,
	}),

	set_tags: (data: any) => ({
		type: Types.SET_TAGS,
		payload: data,
	}),

	set_delete_tags: (data: any) => ({
		type: Types.SET_DELETE_TAGS,
		payload: data,
	}),
	set_delete_tags_canceL: () => ({
		type: Types.SET_DELETE_TAGS_CANCEL,
	}),

	set_editar_tags: (data: any) => ({
		type: Types.SET_EDITAR_TAGS,
		payload: data,
	}),
	set_editar_tags_canceL: () => ({
		type: Types.SET_EDITAR_TAGS_CANCEL,
	}),

	set_atribuir_tags: (data: any) => ({
		type: Types.SET_ATRIBUIR_TAG,
		payload: data,
	}),
	set_atribuir_tags_cancel: () => ({
		type: Types.SET_ATRIBUIR_TAG_CANCEL,
	}),
};

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import YouTube from "react-youtube";

import DinoFeliz from "../../../assets/icons/dinoFeliz.svg";
import { AppActions } from "../../../store/ducks/app";
import BotaoAdicionar from "../../Dashboard/BotãoAdicionar";
import { Conteiner } from "./styles";

const Tutorial: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("youtube"));
	}, []);

	const videoId = "Vem do exercicio";

	const opts = {
		height: "600",
		width: "950",
	};

	const OnReady = (event: any) => {
		event.target.pauseVideo();
	};

	//UI
	return (
		<Conteiner>
			<YouTube videoId="pGbIOC83-So" opts={opts} onReady={OnReady} />
		</Conteiner>
	);
};

export default Tutorial;

import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 2rem;

	.labelForInput {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 100%;
		height: 100%;
	}

	.imgForInput {
		width: 100px;
		object-fit: cover;
	}

	.divFoto {
		padding: 1rem;

		position: relative;

		border: 1px dashed #8c8e86;
		box-sizing: border-box;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.xisFoto {
		position: absolute;

		top: 0.5rem;
		right: 0.5rem;

		width: 1.8rem;
		height: 1.8rem;

		display: flex;
		justify-content: center;
		align-items: center;

		border: 1px solid #8c8e86;
		border-radius: 50%;

		padding: 0.5rem;

		background: rgba(255, 255, 255, 0.85);

		cursor: pointer;
	}

	.Adicionar {
		cursor: pointer;

		margin-top: 1rem;

		display: flex;
		align-items: center;
		justify-content: center;

		p {
			color: ${colors.primary_blue_k_main};

			font-weight: 500;
			font-size: 1.125rem;

			svg {
				margin: 0 0.5rem 0 1rem;
			}
		}

		.p {
			display: flex;
			justify-content: center;
			align-items: center;

			color: ${colors.primary_blue_k_medium_1};

			cursor: pointer;

			font-weight: 500;
			font-size: 1.125rem;

			svg {
				margin: 0 0.5rem 0 0.5rem;
			}
		}

		.addLogo {
			color: #5c5b5b;

			font-weight: 400;
			font-size: 1.125rem;
		}
	}
`;

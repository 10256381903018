import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Back from "../../../assets/icons/back.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TypesMenuInfoProps } from "../../../utils/types";
import QualidadeDeVida from "../../Relatorios/QualidadeDeVIda";

const QualidadeDeVidaProfile: React.FC = () => {
	const dispatch = useDispatch();

	const setSelectMenu = (info: string) => {
		dispatch(DashBoardActions.set_select_info(info));
	};

	return (
		<Container>
			<div>
				<img
					style={{ cursor: "pointer" }}
					src={Back}
					onClick={() => setSelectMenu(TypesMenuInfoProps.BTN_MENU)}
					alt=""
				/>
				<Title className="p">Dados de qualidade de vida</Title>
				<QualidadeDeVida />
			</div>
		</Container>
	);
};

export default QualidadeDeVidaProfile;

const Container = styled.div`
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 1rem;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

export const Title = styled.h2`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 120%;

	color: #404040;

	width: 100%;
	margin: 2rem 0;
`;

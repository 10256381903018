import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import {
	Container,
	Content,
	ContainerNotification,
	ContainerTextNotification,
	TitleNotification,
	SubTitleNotification,
	ContainerIconNotification,
} from "./styles";
import { persistStorage } from "../../../utils/base_async_storage";
import { TypesErrorRequest } from "../../../utils/types";
import { AmplitudeLog } from "../../../utils/amplitude";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import history from "../../../routes/history";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);

export interface NotificationProps {
	ID_USUARIO: number;
	NOTIFICACAO_DESCRICAO: string;
	NOTIFICACAO_ONCLICK: string;
	NOTIFICACAO_TIPO: string;
	NOTIFICACAO_TITULO: string;
	NOTIFICACAO_CRIADO: string;
	IS_NEW: boolean;
}

const ModalNotificacoes: React.FC = () => {
	const notificacoes:Array<any> = useSelector(
		(state: any) => state.dashboard.notificacoes
	);

	const dispatch = useDispatch();

	const [count, setCount] = useState<number>(0);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	
	const pacientes: Array<PacientesProps> = useSelector((state: any) => state.dashboard.pacientes_notification);
	const classes = useStyles();

	const is_open_notificacoes = useSelector(
		(state: any) => state.app.is_open_notificacoes
	);

	const notification_view: Array<NotificationProps> = useSelector(
		(state: any) => state.app.notification_view,
	)

	const ModalClose = () => {
		dispatch(AppActions.set_modal_notificacoes(false));
	};

	const handleGetPatients = (ids: any) => {
		const filtros_paginacao = {
			inicio: 0,
			fim: ids?.length,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
				{ tipo: "ID_USUARIO", valor: ids },
			],
		};

		dispatch(
			DashBoardActions.set_filtros_pacintes_notification_request(filtros_paginacao)
		);
		dispatch(DashBoardActions.paciente_notification_request());
	};

	const handleAdicionarMetodoPagamento = () => {
		dispatch(AppActions.set_open_adicionar_metodo_pagamento(true));
		history.push('metodo_pagamento');
		ModalClose();
	};

	const handleNotificacoes = (notifi: any) => {
		if(notifi?.NOTIFICACAO_ONCLICK?.indexOf('http') >= 0) window.open(notifi?.NOTIFICACAO_ONCLICK, '_blank');
		if(notifi?.NOTIFICACAO_ONCLICK === 'cadastro_cartao') handleAdicionarMetodoPagamento();
		if (notifi?.NOTIFICACAO_ONCLICK === "perfil_paciente") {
			const payload = {
				ID_USUARIO: notifi?.ID_USUARIO,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			};

			const paciente = pacientes.filter(
				(item: PacientesProps) => item?.ID_USUARIO == notifi?.ID_USUARIO
			);
	
			if(paciente.length > 0 ){
				const obj = {
					name: paciente[0].USUARIO_NOME,
					value: "paciente",
				};
				dispatch(AppActions.set_open_barraSuperior(obj));
				dispatch(AppActions.set_open_paciente(paciente[0]));
				dispatch(PacAvalActions.set_perfil_anamnese_patient(notifi?.ID_USUARIO));
				dispatch(AddPacAvalActions.set_recorrente(payload));
				dispatch(push("/paciente"));
				ModalClose();
			}else{
				dispatch(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast:current_language?.notifi_paci_excluido,
					})
				)
			}
		}
	};

	useEffect(() => {
		const ids: any = [];
		notificacoes.filter((notificacao) => {
			if(notificacao?.ID_USUARIO) ids.push(notificacao.ID_USUARIO)
		})

		if(ids.length > 0) handleGetPatients(ids);
	}, [notificacoes]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_notificacoes}
				onClose={ModalClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_notificacoes}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={()=> {
								ModalClose() 
								AmplitudeLog('fechar_modal_notiificacao')
							}}
							alt="Close"
						/>
						<Content>
							<div className="divText">
								<p className="text">
									{current_language?.notificacoes}
								</p>
							</div>
							
								<div className="notificacoes">
									{notificacoes.map((notif: NotificationProps, index: number) => (
										<ContainerNotification key={index}
											onClick={()=> {
												async function storage(item: NotificationProps) {
													await persistStorage({
														tag: 'notification_view',
														res: [...notification_view, item]
													});

												}
												if(
													!!!notification_view.find(
														(x: NotificationProps) => x === notificacoes[index].NOTIFICACAO_CRIADO
													)
												){
													dispatch(
														AppActions.set_notification_view(notificacoes[index].NOTIFICACAO_CRIADO),
													);
													storage(notificacoes[index].NOTIFICACAO_CRIADO);
												}
												AmplitudeLog('notificacao_acessada')
											}}
											
										>
												<ContainerIconNotification>
													{
														notification_view.find(
															(x: NotificationProps) => x === notificacoes[index].NOTIFICACAO_CRIADO,
														) ? (
															<svg
																width="28"
																height="30"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path d="M7 18H17V16H7V18Z" fill="#8f8f8f" />
																<path d="M17 14H7V12H17V14Z" fill="#8f8f8f" />
																<path d="M7 10H11V8H7V10Z" fill="#8f8f8f" />
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
																	fill="#8f8f8f"
																/>
															</svg>
														): (
															<svg
															width="28"
															height="30"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path d="M7 18H17V16H7V18Z" fill="#001574" />
															<path d="M17 14H7V12H17V14Z" fill="#001574" />
															<path d="M7 10H11V8H7V10Z" fill="#001574" />
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
																fill="#001574"
															/>
														</svg>
														)
													}
											</ContainerIconNotification>
											
											
											<ContainerTextNotification
												onClick={() => handleNotificacoes(notif)}
											>
												<TitleNotification
													style={{
														color: notif.IS_NEW ? "#001574" : "#8f8f8f",
													}}
												>
													{notif.NOTIFICACAO_TITULO}
												</TitleNotification>
												<SubTitleNotification>
													{notif.NOTIFICACAO_DESCRICAO}
												</SubTitleNotification>
											</ContainerTextNotification>
										</ContainerNotification>
									))}
								</div>
							
							
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalNotificacoes;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import FotoPerfilEditar from "../../../components/FotoPerfilEditar";
import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { Conteiner, Header, BodyEdite } from "./styles";
import ToastAlert from "../../../components/toast";
import { AmplitudeLog } from "../../../utils/amplitude";

const Perfil: React.FC = () => {
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const perfil = user_logado;

	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const user_editation: UserProps = useSelector((state: any)=> state?.auth?.data_user)

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("perfil"));
	}, []);

	const handleConta = () => {
		dispatch(AppActions.set_open_editar_perfil(true));
		dispatch(AppActions.set_open_barraSuperior("perfilEditar"));
	};

	const nivel_usuario = perfil.USUARIO_NIVEL;
	let nivel = "";
	nivel_usuario == 1 || nivel_usuario == 0
		? (nivel = `${current_language?.add_avaliador}`)
		: nivel_usuario == 2
		? (nivel = `${current_language?.pag_inicial_avaliador}`)
		: (nivel = `${current_language?.paciente}`);


	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	//UI
	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			<Header>
				<div className="bodyHeader">
					<FotoPerfilEditar />
					<div className="identificacao">
						<p className="nome">{user_editation ? user_editation?.USUARIO_NOME : perfil.USUARIO_NOME}</p>
						<span>{nivel}</span>
					</div>
				</div>
				<Link to="editar_perfil" onClick={()=> AmplitudeLog('editar')}>
					<button onClick={handleConta}>
						{current_language?.editar_caixa_alta}
					</button>
				</Link>
			</Header>
			<BodyEdite>
				<div className="lineItens">
					<div className="item">
						<svg
							width="24"
							height="19"
							viewBox="0 0 24 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21.6 0H2.4C1.08 0 0.012 1.06875 0.012 2.375L0 16.625C0 17.9312 1.08 19 2.4 19H21.6C22.92 19 24 17.9312 24 16.625V2.375C24 1.06875 22.92 0 21.6 0ZM21.6 4.75L12 10.6875L2.4 4.75V2.375L12 8.3125L21.6 2.375V4.75Z"
								fill="#8C8E86"
							/>
						</svg>
						<p>{user_editation ? user_editation?.USUARIO_EMAIL : perfil.USUARIO_EMAIL}</p>
					</div>

					<div className="item">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M8.25 10.5C11.145 10.5 13.5 12.855 13.5 15.75C13.5 18.645 11.145 21 8.25 21C5.355 21 3 18.645 3 15.75C3 12.855 5.355 10.5 8.25 10.5ZM8.25 7.5C3.69 7.5 0 11.19 0 15.75C0 20.31 3.69 24 8.25 24C12.81 24 16.5 20.31 16.5 15.75C16.5 14.01 15.96 12.405 15.045 11.07L21 5.13V9H24V0H15V3H18.87L12.915 8.955C11.595 8.04 9.99 7.5 8.25 7.5Z"
								fill="#8D8D8D"
							/>
						</svg>

						<p>
							{
								user_editation 
								? user_editation?.USUARIO_SEXO === "M"
									? `${current_language?.masculino}`
									: `${current_language?.feminino}`
								: perfil.USUARIO_SEXO === "M"
									? `${current_language?.masculino}`
									: `${current_language?.feminino}`
							}
						</p>
					</div>

					<div className="item">
						<svg
							width="22"
							height="28"
							viewBox="0 0 22 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.6666 0.666504H2.99992C1.53325 0.666504 0.333252 1.8665 0.333252 3.33317V24.6665C0.333252 26.1332 1.53325 27.3332 2.99992 27.3332H18.9999C20.4666 27.3332 21.6666 26.1332 21.6666 24.6665V8.6665L13.6666 0.666504ZM10.9999 11.3332C12.4666 11.3332 13.6666 12.5332 13.6666 13.9998C13.6666 15.4665 12.4666 16.6665 10.9999 16.6665C9.53325 16.6665 8.33325 15.4665 8.33325 13.9998C8.33325 12.5332 9.53325 11.3332 10.9999 11.3332ZM16.3333 21.9998H5.66658V21.2398C5.66658 20.1598 6.30659 19.1998 7.29325 18.7732C8.42659 18.2798 9.67992 17.9998 10.9999 17.9998C12.3199 17.9998 13.5733 18.2798 14.7066 18.7732C15.6933 19.1998 16.3333 20.1598 16.3333 21.2398V21.9998Z"
								fill="#8D8D8D"
							/>
						</svg>
						<p>
							{user_editation 
								? user_editation?.USUARIO_CPF?.replace(
								  /(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")  
								: perfil.USUARIO_CPF?.replace(
									/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")
							}
						</p>
					</div>

					<div className="item">
						<svg
							width="17"
							height="28"
							viewBox="0 0 17 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.5697 0.0127273L2.41816 0C1.08149 0 0 1.14545 0 2.54545V25.4545C0 26.8545 1.08149 28 2.41816 28H14.5697C15.9064 28 17 26.8545 17 25.4545V2.54545C17 1.14545 15.9064 0.0127273 14.5697 0.0127273ZM14.5697 22.9091H2.41816V5.09091H14.5697V22.9091Z"
								fill="#8C8E86"
							/>
						</svg>

						<p>
							{user_editation 
								? user_editation?.USUARIO_CONTATO?.replace(
									/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
								: perfil.USUARIO_CONTATO?.replace(
									/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
							}
						</p>
					</div>

					<div className="item">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM12 12C9.78667 12 8 10.2133 8 8C8 5.78667 9.78667 4 12 4C14.2133 4 16 5.78667 16 8C16 10.2133 14.2133 12 12 12Z"
								fill="#8D8D8D"
							/>
							<path
								d="M10.6666 7.3335H9.33325V8.66683H10.6666V7.3335Z"
								fill="#8D8D8D"
							/>
							<path
								d="M12.6666 7.3335H11.3333V8.66683H12.6666V7.3335Z"
								fill="#8D8D8D"
							/>
							<path
								d="M14.6666 7.3335H13.3333V8.66683H14.6666V7.3335Z"
								fill="#8D8D8D"
							/>
						</svg>

						<p>
							{ user_editation 
								? user_editation?.USUARIO_CONDICIONAMENTO  === "1"
									? current_language?.menu_lateral_atleta
									: perfil.USUARIO_CONDICIONAMENTO === "2"
									? current_language?.menu_lateral_ativo
									: perfil.USUARIO_CONDICIONAMENTO === "3"
									? current_language?.menu_lateral_idoso
									: perfil.USUARIO_CONDICIONAMENTO === "4"
									? current_language?.menu_lateral_pos_operatorio
									: current_language?.menu_lateral_sedentario

								: perfil.USUARIO_CONDICIONAMENTO === "1"
									? current_language?.menu_lateral_atleta
									: perfil.USUARIO_CONDICIONAMENTO === "2"
									? current_language?.menu_lateral_ativo
									: perfil.USUARIO_CONDICIONAMENTO === "3"
									? current_language?.menu_lateral_idoso
									: perfil.USUARIO_CONDICIONAMENTO === "4"
									? current_language?.menu_lateral_pos_operatorio
									: current_language?.menu_lateral_sedentario
							}
						</p>
					</div>

					<div className="item">
						<svg
							width="24"
							height="28"
							viewBox="0 0 24 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M8 12.6665H5.33333V15.3332H8V12.6665ZM13.3333 12.6665H10.6667V15.3332H13.3333V12.6665ZM18.6667 12.6665H16V15.3332H18.6667V12.6665ZM21.3333 3.33317H20V0.666504H17.3333V3.33317H6.66667V0.666504H4V3.33317H2.66667C1.18667 3.33317 0.0133333 4.53317 0.0133333 5.99984L0 24.6665C0 26.1332 1.18667 27.3332 2.66667 27.3332H21.3333C22.8 27.3332 24 26.1332 24 24.6665V5.99984C24 4.53317 22.8 3.33317 21.3333 3.33317ZM21.3333 24.6665H2.66667V9.99984H21.3333V24.6665Z"
								fill="#8D8D8D"
							/>
						</svg>

						{current_language?.idioma === "Português" ? (
							<p>{`${user_editation?.USUARIO_NASCIMENTO?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`}</p>
						) : current_language?.idioma === "English" ? (
							<p>{`${user_editation?.USUARIO_NASCIMENTO?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${user_editation?.USUARIO_NASCIMENTO?.substr(6, 10)}`}</p>
						) : (
							<p>{`${user_editation?.USUARIO_NASCIMENTO?.substr(0, 10)
								.split("/")
								.join("-")}`}</p>
						)}
					</div>

					<div className="item">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM12 12C9.78667 12 8 10.2133 8 8C8 5.78667 9.78667 4 12 4C14.2133 4 16 5.78667 16 8C16 10.2133 14.2133 12 12 12Z"
								fill="#8D8D8D"
							/>
							<path
								d="M10.6666 7.3335H9.33325V8.66683H10.6666V7.3335Z"
								fill="#8D8D8D"
							/>
							<path
								d="M12.6666 7.3335H11.3333V8.66683H12.6666V7.3335Z"
								fill="#8D8D8D"
							/>
							<path
								d="M14.6666 7.3335H13.3333V8.66683H14.6666V7.3335Z"
								fill="#8D8D8D"
							/>
						</svg>

						<p>{ user_editation ? user_editation?.USUARIO_MASSA + 'kg' : `${perfil.USUARIO_MASSA} kg`}</p>
					</div>

					<div className="item">
						<svg
							width="30"
							height="16"
							viewBox="0 0 30 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M26.9999 0H2.99992C1.53325 0 0.333252 1.2 0.333252 2.66667V13.3333C0.333252 14.8 1.53325 16 2.99992 16H26.9999C28.4666 16 29.6666 14.8 29.6666 13.3333V2.66667C29.6666 1.2 28.4666 0 26.9999 0ZM26.9999 13.3333H2.99992V2.66667H5.66658V8H8.33325V2.66667H10.9999V8H13.6666V2.66667H16.3333V8H18.9999V2.66667H21.6666V8H24.3333V2.66667H26.9999V13.3333Z"
								fill="#8D8D8D"
							/>
						</svg>

						<p>{user_editation ? user_editation?.USUARIO_ESTATURA + 'm' : `${perfil.USUARIO_ESTATURA}m`}</p>
					</div>
				</div>
			</BodyEdite>
		</Conteiner>
	);
};

export default Perfil;

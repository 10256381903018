import moment from "moment";
import { push } from "connected-react-router";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AddPacAvalActions } from "../ducks/add_pac_aval";
import { AppActions } from "../ducks/app";
import { DashBoardActions } from "../ducks/dashboard";
import { PerfilActions, Types } from "../ducks/perfil";
import { AuthActions } from "../ducks/auth";
import * as Sentry from "@sentry/react";

function* editUser({ payload }: any): any {
	const novoEmail = payload.NOVO_EMAIL;
	delete payload.NOVO_EMAIL;
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const language: any = yield select((state) => state.configs.language);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const user_eval_avatar_64 = yield select(
		(state: any) => state.add_pac_aval.user_eval_avatar_64
	);
	async function apiCall() {
		try {
			const dataRequest: any = {};
			const dat = {
						...payload,
						USUARIO: !!user_eval_avatar_64 ? user_eval_avatar_64 : "",
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txIdioma: language?.toLowerCase(),
				  };
			dataRequest["0"] = { TOKEN: user_logado.token };
			dataRequest["1"] = dat;
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}usuarios/usuarios_editar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: usuarios_editar.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == 1) {
				message = current_language?.edicao_erro_1;
			}
			if (response.code == 2) {
				message = current_language?.edicao_erro_2;
			}
			if (response.code == 3) {
				message = current_language?.edicao_erro_3;
			}
			if (response.code == 4) {
				message = current_language?.edicao_erro_4;
			}
			if (response.code == 5) {
				message = current_language?.edicao_erro_5;
			}
			if (response.code == 6) {
				message = current_language?.edicao_erro_6;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			if (response.code == 0) {
				yield put(AuthActions.edit_user_profile(payload));

				yield put(DashBoardActions.paciente_request());
				message = current_language?.edicao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
			if (novoEmail) {
				yield put(push("/login"));
			}
		}
	} catch (e) {
	} finally {
		yield put(PerfilActions.edit_perfil_finally());
		yield put(DashBoardActions.paciente_request());
		yield put(AddPacAvalActions.set_avatar_paci_64(null));
		yield put(AddPacAvalActions.set_avatar_eval_64(null));
	}
}

export function* PerfilSaga(): any {
	return yield all([takeLatest(Types.EDIT_USUARIO_PERFIL, editUser)]);
}

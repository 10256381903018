import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Filtro from "../../../../assets/icons/filtro.svg";
import Limpar from "../../../../assets/icons/limpar.svg";
import Lupa from "../../../../assets/icons/lupa.svg";
import { TextsProps } from "../../../../database/txt.interface";
import { AppActions } from "../../../../store/ducks/app";
import { DashBoardActions } from "../../../../store/ducks/dashboard";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../../utils/amplitude";
import LupaFilterProtocol from "../../../../assets/icons/lupaFilterProtocol.svg";

const BarraSuperiorPadrao: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const notificacoes: any = useSelector(
		(state: any) => state.dashboard.notificacoes
	);

	const lista_filtro = useSelector((state: any) => state.app.lista_filtro);


	const { reset, register } = useForm();

	const [search, setSearch] = useState(true);

	const [pulse, setPulse] = useState(false);

	const [inputValue, setInputValue] = useState("");

	// Abre a modal de filtro
	const handleOpenFiltroEtiquetas = () => {
		dispatch(AppActions.set_modal_filtro_etiquetas(true));
	};
	// Abre a modal de notificações
	const handleOpenNotificacoes = () => {
		dispatch(AppActions.set_modal_notificacoes(true));
	};

	/*Manda o valor digitado no input para o redux em tempo real, e tbm mostra o
	botão X ao digitar algo */
	const [isWriting, SetIsWriting] = useState(false);
	const handleOpenSearch = (e: any) => {
		dispatch(DashBoardActions.set_search(e.target.value));
		if (e.target.value !== "") {
			SetIsWriting(true);
			setPulse(true);
		} else {
			SetIsWriting(false);
			setPulse(false);
		}
	};

	//reseta o campo de input
	const resetInput = () => {
		setInputValue("");
		dispatch(DashBoardActions.set_search(""));
		dispatch(DashBoardActions.set_button_search(true));
		SetIsWriting(false);
		setPulse(false);
		reset();
	};

	useEffect(() => {
		dispatch(DashBoardActions.set_search(""));
		SetIsWriting(false);
		setPulse(false);
		reset();
	}, []);


	const Submit = (event: any) => {
		setPulse(false);
		if (event.key == "Enter" || event.type == "click") {
			setPulse(false);
			dispatch(DashBoardActions.set_button_search(true));
		}
	};

	const handleInputChange = (event: any) => {
		setInputValue(event.target.value);
	};

	return (
		<Conteiner>
			<div className="b">
				<div className="mid">
					{search === true && (
						<div className="input">
							<button
								type="button"
								onClick={Submit}
								className={pulse ? "pulse" : "reset"}
							>
								<img className="lupa" src={LupaFilterProtocol} alt="" />
							</button>
							<input
								onKeyUp={(e) => {
									if (e.key != "Enter") handleOpenSearch(e);
								}}
								onKeyDown={Submit}
								value={inputValue}
								onChange={handleInputChange}
								className="inputPesquisa"
								type="text"
								placeholder={current_language?.dash_pesquisar_paciente}
							/>
							{isWriting === true && (
								<button type="reset" className="reset">
									<img className="x" src={Limpar} alt="" onClick={resetInput} />
								</button>
							)}
						</div>
					)}
					{search === false && (
						<div className="input">
							<button type="button" className="reset">
							<img className="lupa" src={Lupa} alt="" />
							</button>
							<input
								className="inputPesquisa"
								type="text"
								placeholder={current_language?.dash_pesquisar_evalu}
								onKeyUp={(e) => {
									if (e.key != "Enter") handleOpenSearch(e);
								}}
								{...register("pesquisa", {})}
							/>

							{isWriting === true && (
								<button type="reset" className="reset">
									<img className="x" src={Limpar} alt="" onClick={resetInput} />
								</button>
							)}
						</div>
					)}
				</div>
				<div className="rigth">
					<button className="opcoes" onClick={handleOpenFiltroEtiquetas}>
						{lista_filtro.length > 0 ? (
							<svg
								width="28"
								height="29"
								viewBox="0 0 28 29"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M26.4444 0H1.55556C1.143 0 0.747335 0.160809 0.455612 0.447051C0.163889 0.733293 0 1.12152 0 1.52633V5.47952C0 6.27779 0.331334 7.06232 0.906889 7.62706L9.33333 15.8952V27.4739C9.33364 27.7339 9.40158 27.9896 9.53073 28.2167C9.65988 28.4438 9.84596 28.6347 10.0714 28.7715C10.2967 28.9083 10.554 28.9864 10.8187 28.9984C11.0835 29.0104 11.347 28.9558 11.5842 28.84L17.8064 25.7873C18.3338 25.5278 18.6667 24.9997 18.6667 24.4212V15.8952L27.0931 7.62706C27.6687 7.06232 28 6.27779 28 5.47952V1.52633C28 1.12152 27.8361 0.733293 27.5444 0.447051C27.2527 0.160809 26.857 0 26.4444 0ZM16.0113 14.1842C15.8666 14.3257 15.7518 14.4939 15.6736 14.6791C15.5954 14.8643 15.5553 15.0628 15.5556 15.2633V23.478L12.4444 25.0043V15.2633C12.4447 15.0628 12.4046 14.8643 12.3264 14.6791C12.2482 14.4939 12.1334 14.3257 11.9887 14.1842L3.11111 5.47952V3.05266H24.8904L24.8936 5.46883L16.0113 14.1842Z"
									fill="#001574"
								/>
							</svg>
						) : (
							<img src={Filtro} alt="" />
						)}
					</button>
					<button className="opcoes" onClick={() => {
							handleOpenNotificacoes() 
							AmplitudeLog('notificacoes')
						}}
					>
						<div className="boxBell">
							{notificacoes.filter((x: any) => x.IS_NEW).length != 0 && (
								<div className="boxNewBell"> </div>
							)}
							<svg
								width="32px"
								height="32px"
								fill="#B3B3B3"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									d="M12 1C8.318 1 5 3.565 5 7v4.539a3.25 3.25 0 01-.546 1.803l-2.2 3.299A1.518 1.518 0 003.519 19H8.5a3.5 3.5 0 107 0h4.982a1.518 1.518 0 001.263-2.36l-2.2-3.298A3.25 3.25 0 0119 11.539V7c0-3.435-3.319-6-7-6zM6.5 7c0-2.364 2.383-4.5 5.5-4.5s5.5 2.136 5.5 4.5v4.539c0 .938.278 1.854.798 2.635l2.199 3.299a.017.017 0 01.003.01l-.001.006-.004.006-.006.004-.007.001H3.518l-.007-.001-.006-.004-.004-.006-.001-.007.003-.01 2.2-3.298a4.75 4.75 0 00.797-2.635V7zM14 19h-4a2 2 0 104 0z"
								/>
							</svg>
						</div>
					</button>
				</div>
			</div>
		</Conteiner>
	);
};

export default BarraSuperiorPadrao;

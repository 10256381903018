import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { TagActions } from "../../../store/ducks/tags";
import { TypesErrorRequest } from "../../../utils/types";
import ConfiguracoesController from "../Configuracoes/Controller";
import { Conteiner, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

interface TagsProps {
	ID_ETIQUETA: number;
	ETIQUETA_NOME: string;
	ID_USUARIOS: [];
}

const Etiquetas: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const tags = useSelector((state: any) => state.tags.selecteds_tags);

	const etiquetas = useSelector((state: any) => state.dashboard.etiquetas);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("etiquetas"));
	}, []);

	const [identificador, setIdentificador] = useState<number[]>([]);

	const handleActiveTag = (id: number) => () => {
		let newIdentificador: any = [];
		if (identificador?.includes(id)) {
			const filter = identificador.filter((e: any) => e !== id);
			newIdentificador = filter;
		} else {
			newIdentificador = [...identificador, id];
		}
		setIdentificador(newIdentificador);
		const etiq = etiquetas.filter((e: TagsProps) =>
			newIdentificador?.includes(e.ID_ETIQUETA)
		);
		dispatch(TagActions.set_tags(etiq));
	};

	const handleActiveTagAll = () => {
		const newIdentificador: any = [];
		if (identificador.length > 0) {
			setIdentificador([]);
			dispatch(TagActions.reset_select_tag());
		} else {
			etiquetas.map((e: TagsProps) => {
				if (newIdentificador.indexOf(e.ID_ETIQUETA) === -1) {
					newIdentificador.push(e.ID_ETIQUETA);
				} else if (newIdentificador.indexOf(e.ID_ETIQUETA) >= 0) {
					newIdentificador.splice(newIdentificador.indexOf(e.ID_ETIQUETA), 1);
				}
				setIdentificador(newIdentificador);
			});
			const etiq = etiquetas.filter((e: TagsProps) =>
				newIdentificador?.includes(e.ID_ETIQUETA)
			);
			dispatch(TagActions.set_tags(etiq));
		}
	};

	const handleOpenAdicionarEtiqueta = () => {
		let message = "";
		if (etiquetas?.length >= 10) {
			message = current_language?.limite_maximo_etiquetas;
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			dispatch(AppActions.set_modal_adicionar_etiqueta(true));
		}
	};

	const ExcluirMarcados = () => {
		AmplitudeLog('excluir_etiquetas_selecionadas')
		setIdentificador([]);
		dispatch(AppActions.set_modal_excluir_etiqueta(true));
	};

	const ExcluirUm = (e: any) => {
		AmplitudeLog('excluir_etiqueta')
		dispatch(TagActions.set_tags([e]));
		dispatch(AppActions.set_modal_excluir_etiqueta(true));
	};

	const editar = (e: any) => {
		dispatch(AppActions.set_modal_editar_etiqueta(e));
		// dispatch(TagActions.set_tags(e));
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const { register, setValue } = useForm();

	useEffect(() => {
		identificador.length === 0
			? etiquetas.map((row: any) => {
					setValue(
						`${row.ID_ETIQUETA}`,
						identificador.indexOf(row.ID_ETIQUETA) >= 0 ? true : false
					);
			  })
			: etiquetas.map((row: any) => {
					setValue(
						`${row.ID_ETIQUETA}`,
						identificador.indexOf(row.ID_ETIQUETA) >= 0 ? true : false
					);
			  });
	}, [identificador]);
	//UI

	return (
		<Conteiner id="DivPrincipal">
			{is_open_toast && <ToastAlert />}

			<Content>
				<div className="divTitle">
					<div className="divGroupTitle">
						<p className="title">{current_language?.tags_minha_etiquetas}</p>
						<p className="count">{etiquetas?.length}/10</p>
					</div>
					<button 
						className="button" 
						onClick={()=> { handleOpenAdicionarEtiqueta(), AmplitudeLog('adicionar_etiqueta')}}
					>
						<svg
							width="27"
							height="27"
							viewBox="0 0 27 27"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.5002 24.3002C19.4649 24.3002 24.3002 19.4649 24.3002 13.5002C24.3002 7.53552 19.4649 2.7002 13.5002 2.7002C7.53552 2.7002 2.7002 7.53552 2.7002 13.5002C2.7002 19.4649 7.53552 24.3002 13.5002 24.3002Z"
								stroke="#00A377"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M13.5 9.17969V17.8197"
								stroke="#00A377"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M9.18066 13.5H17.8207"
								stroke="#00A377"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<p className="ButtonText">
							{current_language?.tags_criar_nova_etiqueta}
						</p>
					</button>
				</div>

				<div className="cabecalho">
					<div className="colunas">
						<p className="title">{current_language?.tags_nome_etiqueta}</p>
						<p className="title">{current_language?.tags_n_usos}</p>
					</div>
					{identificador.length > 0 ? (
						<p className="selecionar" onClick={handleActiveTagAll}>
							{current_language?.limpar_selecao}
						</p>
					) : (
						<p 
							className="selecionar" onClick={()=> {
								handleActiveTagAll()
								AmplitudeLog('selecionar_todas_etiquetas')
							}}
						>
							{current_language?.tag_selecionar_todos}
						</p>
					)}
				</div>
				<div className="tabela">
					{etiquetas.map((row: TagsProps) => (
						<div className="etiqueta" key={row.ID_ETIQUETA}>
							<div className="group">
								<div className="custom-checkbox">
									<input
										id={row.ID_ETIQUETA.toString()}
										type="checkbox"
										onClick={handleActiveTag(row.ID_ETIQUETA)}
										{...register(`${row.ID_ETIQUETA}`, {})}
									/>
									<label htmlFor={row.ID_ETIQUETA.toString()}></label>
								</div>
								<div className="collumn">
									<p className="text">{row.ETIQUETA_NOME}</p>
									<p className="text">
										{row?.ID_USUARIOS.length}{" "}
										{current_language?.pag_inicial_pacientes}
									</p>
								</div>
							</div>
							<div className="dropdown">
								<svg
									width="16"
									height="4"
									viewBox="0 0 16 4"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z"
										fill="#8C8E86"
									/>
								</svg>
								<div className="dropdown-content">
									<button 
										className="op" 
										onClick={() => {
											editar(row) 
											AmplitudeLog('editar_etiqueta')
										}}
									>
										<img src={Editar} className="img" alt="" />
										{current_language?.editar}
									</button>
									<br />
									<button 
										className="op2" 
										onClick={() => ExcluirUm(row)}
									>
										<img src={Excluir} className="img" alt="" />
										{current_language?.excluir}
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="divBotao">
					{identificador.length > 0 && (
						<div>
							<button
								type="button"
								className="btnRecuperar"
								onClick={()=> ExcluirMarcados()}
							>
								<svg
									className="svg"
									width="22"
									height="23"
									viewBox="0 0 22 23"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M20.75 1.43751H15.125L14.6844 0.597469C14.591 0.417877 14.4473 0.266809 14.2692 0.161259C14.0912 0.0557084 13.8859 -0.000136022 13.6766 7.87602e-06H8.31875C8.10987 -0.00076166 7.90498 0.0548745 7.72756 0.160542C7.55015 0.26621 7.40739 0.417635 7.31562 0.597469L6.875 1.43751H1.25C1.05109 1.43751 0.860322 1.51323 0.71967 1.64802C0.579018 1.78282 0.5 1.96563 0.5 2.15626L0.5 3.59376C0.5 3.78438 0.579018 3.9672 0.71967 4.10199C0.860322 4.23678 1.05109 4.31251 1.25 4.31251H20.75C20.9489 4.31251 21.1397 4.23678 21.2803 4.10199C21.421 3.9672 21.5 3.78438 21.5 3.59376V2.15626C21.5 1.96563 21.421 1.78282 21.2803 1.64802C21.1397 1.51323 20.9489 1.43751 20.75 1.43751ZM2.99375 20.9785C3.02952 21.5259 3.28164 22.0397 3.69878 22.4153C4.11591 22.7908 4.66672 22.9999 5.23906 23H16.7609C17.3333 22.9999 17.8841 22.7908 18.3012 22.4153C18.7184 22.0397 18.9705 21.5259 19.0062 20.9785L20 5.75001H2L2.99375 20.9785Z"
										fill="white"
									/>
								</svg>
								{current_language?.atribuir_tags_excluir}
							</button>
						</div>
					)}
				</div>
			</Content>
		</Conteiner>
	);
};

export default memo(Etiquetas);

import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container, Content } from "./styles";
import IconProfile from "../../../assets/icons/icon_profile.svg";
import Lupa from "../../../assets/icons/lupa.svg";
import { PacientesProps, UserProps } from "../../../interfaces/pacientes.interface";
import moment from "moment";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { TypesPermission } from "../../../utils/types";
import { getPermission } from "../../../utils/funcs";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AmplitudeLog } from "../../../utils/amplitude";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalMigracao: React.FC = () => {

	const dispatch = useDispatch();
	const classes = useStyles();
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [value, setvalue] = useState<any>();
	const [palavra, setPalavra] = useState<any>();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const title_export_data: boolean = useSelector(
		(status: any)=> status.app.title_export_data
	);

	const is_open_migracao = useSelector(
		(state: any) => state.app.is_open_migracao.status
	);

	const execucao = useSelector(
		(state: any) => state.app.is_open_migracao.execucao
	);

	const pacientes = useSelector(
		(state: any) => state.dashboard.pacientes
	);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const language: any = useSelector((state: any) => state.configs.language);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const ModalMigracaoClose = () => {
		dispatch(AppActions.set_modal_migracao(false, null));
	};

	const IsWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalue(e.target.value);
	};

	const MigrateOrExportData= (id_paciente: number) => {
		if(title_export_data){
			if (getPermission(config_servicos, TypesPermission.SERVICO_CSV, dispatch)) {
				window.open(
					`${process.env.REACT_APP_BASE_URL}relatorios/exportar_csv.php?l=${language?.toLowerCase()}&t=${user_logado.token}&i=${id_paciente}`,"_blank");
				}
		}else{

			const request = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				ID_EXECUCAO: [execucao],
				ID_USUARIO: id_paciente,
			};
			dispatch(ExerciciosActions.editar_execucao_request(request));
			dispatch(AppActions.set_modal_migracao(false, null));
		}
	}

	useEffect(() => {
		setPalavra(
			pacientes?.filter((paciente: PacientesProps) =>
				paciente?.USUARIO_NOME?.toLowerCase()?.includes(value?.toLowerCase())
			)
		);
	}, [value]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={!!is_open_migracao}
				onClose={ModalMigracaoClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_migracao}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={()=> {(ModalMigracaoClose(), AmplitudeLog(title_export_data ? 'fechar_modal_exportar_dados' : 'fechar_modal_migracao'))}}
							alt="Close"
						/>
						<Content>
							<p className="title">
								{ title_export_data ? current_language?.escolha_paciente_exportar_dados : current_language?.escolha_paci_migrar}
							</p>

							<form action="">
								<div className="mid">
									<div className="input">
										<button type="button" className="reset">
											<img className="lupa" src={Lupa} alt="" />
										</button>
										<input
											className="inputPesquisa"
											type="text"
											placeholder={current_language?.pag_inicial_busca}
											onKeyUp={(e) => IsWriting(e)}
										/>
									</div>
								</div>
							</form>
							{isSearch ?
								<div className="div_pacientes">
									{palavra.map((item: PacientesProps) => (
										<div 
											key={item.ID_USUARIO} 
											onClick={() => {
												MigrateOrExportData(item?.ID_USUARIO), 
												AmplitudeLog('download_dados')
											}} 
											className="item_paciente"
										>
											<img className="paciente_foto" src={IconProfile} />
											<p className="paciente_nome">{item.USUARIO_NOME}</p>
										</div>
									))}
								</div>
								:
								<div className="div_pacientes">
									{pacientes.map((item: PacientesProps) => (
										<div 
											key={item.ID_USUARIO} 
											onClick={() => {
												MigrateOrExportData(item?.ID_USUARIO), 
												AmplitudeLog('download_dados')
											}} 
											className="item_paciente"
										>
											<img className="paciente_foto" src={IconProfile} />
											<p className="paciente_nome">{item.USUARIO_NOME}</p>
										</div>
									))}
								</div>
							}
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalMigracao);

import React from "react";
import styled from "styled-components";

import Menu from "../../../../../components/Menu";
import ContentDash from "../../../../Dashboard/ContentDash";

const ConfirmacaoController: React.FC = () => {
	return (
		<ViewPort>
			<Menu setMenu="conta"></Menu>
			<ContentDash listagem={"confirmacao"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default ConfirmacaoController;

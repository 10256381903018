import { getDatabase, push, ref, set } from "firebase/database";
import moment from "moment";
import { TextsProps } from "../database/txt.interface";
import { ServicosProps } from "../interfaces/config.interface";
import { Exercicios2Props } from "../interfaces/exercicio.interface";
import { PacientesProps, UserProps } from "../interfaces/pacientes.interface";
import { AppActions } from "../store/ducks/app";
import { AuthActions } from "../store/ducks/auth";
import { TypesPermission, TypesDivision, TypesLanguage } from "./types";
import { useSelector } from "react-redux";
import { DashBoardActions } from "../store/ducks/dashboard";
import { Dispatch } from "redux";
import { loadStorage, persistStorage } from "./base_async_storage";


export function getGroupingSide(side: any, current_language: TextsProps) {
	if (side == "D") {
		return current_language?.hemisferio_direito;
	}
	if (side == "E") {
		return current_language?.hemisferio_esquerdo;
	}
	if (side == "B") {
		return current_language?.hemisferio_bilateral;
	}
	if (side == "S") {
		return current_language?.sem_hemisferio;
	}
}

export function getElementsExers(
	exer: Exercicios2Props,
	type: any,
	T: any,
	current_language: TextsProps,
	language: any
) {
	const DEB = type == TypesDivision.DEB ? ["D", "E", "B"] : ["S"];
	const aux_tipo = T.split("");

	const arr: any = [];

	aux_tipo.forEach((e: any) => {
		if (DEB.includes(e)) {
			arr.push({
				title:
					e == "D"
						? current_language?.hemisferio_direito
						: e == "E"
						? current_language?.hemisferio_esquerdo
						: e == "B"
						? current_language?.hemisferio_bilateral
						: current_language?.sem_hemisferio,
				value: e,
				exercice_id: exer?.ID_EXERCICIO,
				grupo: exer?.ID_AGRUPAMENTO,
				name:
					language == TypesLanguage.PT
						? exer?.EXERCICIO_TITULO_PT
						: language == TypesLanguage.EN
						? exer?.EXERCICIO_TITULO_EN
						: exer?.EXERCICIO_TITULO_ES,
			});
		}
	});
	return arr;
}

export function getPermission(
	config_servicos: ServicosProps[],
	type: any,
	dispatch?: any,
) {
	return config_servicos[type] || false;
}

export function getTimeExecFunction(tempInitial: number, tempFinal: number, description: string, user?: UserProps ) {
	const dataAtual = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
	const result = tempFinal - tempInitial
	const data = {
		user: user,
		moment: dataAtual,
		resultTime: result,
	}

	try{
		const firebaseDataBase = getDatabase();
			push(ref(firebaseDataBase, description), {
				id_user: data?.user?.ID_USUARIO,
				current_date: data.moment,
				ResultTime: data.resultTime,

			})
	}catch(error){
		console.log( 'Seus dados não foram enviados', error)
	}
};

export function getTimeout(tempInitial: number, tempFinal: number, description: string, user?: UserProps ){
	const dataAtual = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
	const result = tempFinal - tempInitial
	const timeout = {
		user: user,
		moment: dataAtual,
		timeout: result,
	};

	try{
		const firebaseDataBase = getDatabase();
		if(result >= 60000){
			push(ref(firebaseDataBase, description), {
				id_user: timeout?.user?.ID_USUARIO,
				current_date: timeout.moment,
				timeout: timeout.timeout,

			})
		}
	}catch(error){
		console.log( 'Seus dados não foram enviados', error)
	}
};

/**
 * 
 * @param pagina_desejada Number of the page you want to load the patient list
 * @param numero_de_paginas Represents the total number of existing pages (total number of patients divided by 6 - Number of patients per page)
 * @param dispatch redux dispatch (used to trigger an action)
 */
export function MudarPagina  (
	pagina_desejada: number,
	numero_de_paginas: number,
	dispatch: Dispatch,
	value?: Array<number>,
	SearchName?: any
 ) {
	//Caso queira alterar o numero de pacientes por pagina
	//Alterar também no ducks dashboard para primeira requisição
	const usuarios_por_pagina = 6;
	const usuarios_na_pagina = [];

	dispatch(DashBoardActions.set_current_page_patient(pagina_desejada));

	if (pagina_desejada != null && pagina_desejada <= numero_de_paginas) {
		const indiceInicial = (pagina_desejada - 1) * usuarios_por_pagina;
		const indiceFinal = indiceInicial + usuarios_por_pagina;
		for (let i = indiceInicial; i <= indiceFinal; i++) {
			usuarios_na_pagina.push(i);
		}

		const filtros_paginacao = {
			inicio: usuarios_na_pagina[0],
			fim: usuarios_na_pagina[usuarios_por_pagina],
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: value },
			],
		};
		if (SearchName != "") {
			filtros_paginacao.filtros.push({ tipo: "USUARIO_NOME", valor: SearchName });
		}

		dispatch(
			DashBoardActions.set_filtros_pacintes_request(filtros_paginacao)
		);
		dispatch(DashBoardActions.paciente_request());
		
		if(value?.includes(1) && value?.includes(2)){

			dispatch(DashBoardActions.set_current_page_evaluator(pagina_desejada));
			dispatch(
				DashBoardActions.set_filtros_avaliadores_request(filtros_paginacao)
			);
			dispatch(DashBoardActions.avaliadores_request());
		}
	} else {
		console.log(`A página ${pagina_desejada} não existe.`);
	}
};

export async function getEvaluatedPatients(paciente: PacientesProps) {
		
	let evaluatedPatients: number[] | null = []

	evaluatedPatients = await loadStorage('evaluated_patients')

	if(!evaluatedPatients?.includes(paciente?.ID_USUARIO)){
		
		evaluatedPatients?.push(paciente?.ID_USUARIO)

		persistStorage({
			res: evaluatedPatients,
			tag: 'evaluated_patients',
		});

	};
};


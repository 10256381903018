import React, { memo } from "react";
import { Dot, Spectrum } from "./styles";
import { QualityLifeProps } from "../../../interfaces/anamnese.interface";

export interface SpectrumLightProps {
	factor: QualityLifeProps;
}

const SpectrumLight: React.FC<SpectrumLightProps> = ({ factor }) => {
	const SpectrumColors = [
		"#F3281E",
		"#F94A1A",
		"#FB6713",
		"#FC8108",
		"#FE9900",
		"#FFB200",
		"#FFCC01",
		"#BFCB06",
		"#7EC90D",
		"#66C722",
	];
	const colorEmpty = "#E5E5E5";
	const sizeEmpty = 10 - factor.INDICATIVO_VALOR;
	const listColorsEmpty = Array(sizeEmpty).fill(colorEmpty);

	const filteredSpectrumColors =
		factor.INDICATIVO_TIPO == "POSITIVO"
			? SpectrumColors.slice(0, factor.INDICATIVO_VALOR)
			: SpectrumColors.reverse().slice(0, factor.INDICATIVO_VALOR);

	return (
		<Spectrum>
			{filteredSpectrumColors.map((color: string, index) => (
				<Dot key={index} style={{ backgroundColor: color }}></Dot>
			))}
			{listColorsEmpty.map((color: string, index) => (
				<Dot key={index} style={{ backgroundColor: color }}></Dot>
			))}
		</Spectrum>
	);
};

export default memo(SpectrumLight);

import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Perfil from "../../../assets/icons/perfil.svg";
import UserAzul from "../../../assets/icons/userAzul.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { getEtiquetas } from "../../../utils/etiquetas";
import { getPermission } from "../../../utils/funcs";
import { TypesErrorRequest, TypesPermission } from "../../../utils/types";
import { Conteiner } from "./styles";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { AmplitudeLog } from "../../../utils/amplitude";
import Loading from "../../Dashboard/Loading";

enum excluidos {
	account = "conta",
	exercise = "exercicio",
	exercises = "exercicios",
	accounts = "contas",
}

const GerenciadorExcluidos: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("excluidos"));
	}, []);
	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);
	const lista_excluidos = useSelector(
		(state: any) => state.app.lista_excluidos
	);

	const carregando_execucoes = useSelector(
		(state: any) => state.dashboard.execucoes_loading
	);

	const exerciciosExcluidos = useSelector(
		(state: any) => state.exercicios.exerciciosExcluidos
	);
	const exerciciosDeletados = useSelector(
		(state: any) => state.exercicios.exerciciosDeletados
	);

	const execucoes = useSelector((state: any) => state.dashboard.execucoes);

	const execucoesExcluidas = execucoes.filter(
		(e: any) => e.EXECUCAO_EXCLUIDA == true
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const listaExcluidos = lista_excluidos;

	const HandleClassUsuario = (val: PacientesProps) => (event: any) => {
		dispatch(PacAvalActions.set_add_selected_patient(val));
		if (listaExcluidos.indexOf(val.ID_USUARIO) === -1) {
			listaExcluidos.push(val.ID_USUARIO);
		} else if (listaExcluidos.indexOf(val.ID_USUARIO) >= 0) {
			listaExcluidos.splice(listaExcluidos.indexOf(val.ID_USUARIO), 1);
		}
		dispatch(AppActions.set_lista_excluidos(listaExcluidos));
	};

	const [exerciciosrecuperacao, setExerciciosrecuperacao] =
		useState(exerciciosExcluidos);
	const HandleClassExcluidos = (val: any) => (event: any) => {
		dispatch(ExerciciosActions.set_add_selected_exercices(val));
		if (exerciciosrecuperacao.indexOf(val) === -1) {
			setExerciciosrecuperacao([...exerciciosrecuperacao, val]);
		} else if (exerciciosrecuperacao.indexOf(val) >= 0) {
			const newExer = exerciciosrecuperacao.filter((e: any) => e !== val);
			setExerciciosrecuperacao(newExer);
		}
		dispatch(ExerciciosActions.set_exercicios_excluidos(exerciciosrecuperacao));
	};

	const execucoesRecuperar = useSelector(
		(state: any) => state.exercicios.execucoesRecuperar
	);

	const execucoesExc: any = execucoesRecuperar;
	const HandleClassExecucoes = (val: any) => (event: any) => {
		dispatch(ExerciciosActions.set_execucoes_recuperar(val));
		if (execucoesExc.indexOf(val) === -1) {
			execucoesExc.push(val);
		} else if (execucoesExc.indexOf(val) >= 0) {
			execucoesExc.splice(execucoesExc.indexOf(val), 1);
		}
		dispatch(ExerciciosActions.set_execucoes_excluidas(execucoesExc));
	};

	const { register, setValue, reset } = useForm();

	function isPresentInListSelecteds(paciente: PacientesProps) {
		const is_present = selected_patients.find(
			(p: PacientesProps) => p.ID_USUARIO === paciente.ID_USUARIO
		);
		if (is_present) {
			return true;
		}
		return false;
	}

	const handlePaciente = () => {
		dispatch(AppActions.set_open_paciente(true));
	};

	const is_list = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	// Essa funcão dispara a troca a classe css dos botões e dispara funcão-
	// para a troca de listagem.
	const [selected, setSelected] = useState<string[]>(["pacientes"]);
	const handleActiveClick = (active: string) => {
		const alreadySelected = selected.findIndex((item) => item === active);

		if (alreadySelected < 0) {
			setSelected([active]);
		}
		if (active == "execucoes") {
			dispatch(DashBoardActions.execucoes_request(true));
		}
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		selected_patients.map((pac: PacientesProps) => {
			setValue(
				`${pac.ID_USUARIO}`,
				lista_excluidos.indexOf(pac.ID_USUARIO) >= 0 ? true : false
			);
		});
	}, [lista_excluidos]);

	const handleModalRecuperarConta = (item: any) => () => {
		if (item?.mode === excluidos.account) {
			AmplitudeLog("recuperar_paci_avaliador");
		}
		if (item?.mode === excluidos.exercise) {
			AmplitudeLog("recuperar_exercicio");
		}
		if (item?.mode === excluidos.accounts) {
			AmplitudeLog("recuperar_pacientes_selecionados");
		}
		if (item?.mode === excluidos.exercises) {
			AmplitudeLog("recuperar_exercicios_selecionados");
		}
		if (
			getPermission(config_servicos, TypesPermission.SERVICO_RECYCLE, dispatch)
		) {
			dispatch(AppActions.set_modal_recuperar(item));
			dispatch(ExerciciosActions.set_exercicios_excluidos([]));
		}
	};

	const etis = useSelector((state: any) => state.dashboard.etiquetas);
	const lista_filtro = useSelector((state: any) => state.app.lista_filtro);

	const calcular_diferenca_data = (data: any) => {
		const now = moment(new Date()); // Data de hoje
		const past = moment(data); // Outra data no passado
		const duration = moment.duration(now.diff(past));

		// Mostra a diferença em dias
		const days = duration.asDays();

		return days;
	};

	const desabilitadosSessenta = pacientes.filter(
		(row: any) =>
			parseInt(calcular_diferenca_data(row.USUARIO_ATUALIZADO).toFixed(0)) <= 60
	);

	const exerciciosDeletadosSessenta = exerciciosDeletados.filter(
		(row: any) =>
			parseInt(calcular_diferenca_data(row.EXERCICIO_ATUALIZADO).toFixed(0)) <=
			60
	);

	const execucoesExcluidasSessenta = execucoesExcluidas.filter(
		(row: any) =>
			parseInt(calcular_diferenca_data(row.EXECUCAO_ATUALIZADA).toFixed(0)) <=
			60
	);

	useEffect(() => {
		const filtros_paginacao = {
			inicio: 0,
			fim: 10000,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "S" },
				{ tipo: "USUARIO_NIVEL", valor: [1, 2, 3] },
			],
		};

		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));
		dispatch(DashBoardActions.paciente_request());
	}, []);
	return (
		<Conteiner id="DivPrincipal">
			{is_open_toast && type_toast == TypesErrorRequest.SUCCESS ? (
				<ToastAlert />
			) : (
				""
			)}

			{is_list && <div className="loading">{current_language?.loading}</div>}
			<div className="divCabecalho">
				<div className="divTitle">
					<p className="title">{current_language?.menu_lateral_recuperar}</p>
				</div>
				<p className="itensExcluidos">
					{current_language?.itens_disponiveis_excluidos}
				</p>
				<div className="divNav">
					<button
						className={`${
							selected.includes("pacientes") ? "selecao" : "selecao2"
						}`}
						onClick={() => {
							handleActiveClick("pacientes");
							setExerciciosrecuperacao([]);
							AmplitudeLog("pacientes_avaliadores_excluidos");
						}}
					>
						{current_language?.usuario}
					</button>
					<button
						className={`${
							selected.includes("exercicios") ? "selecao" : "selecao2"
						}`}
						onClick={() => {
							handleActiveClick("exercicios");
							reset();
							dispatch(AppActions.set_lista_excluidos([]));
							dispatch(PacAvalActions.set_add_selected_patient([]));
							AmplitudeLog("exercicios_excluidos");
						}}
					>
						{current_language?.exercicios}{" "}
					</button>
					<button
						className={`${
							selected.includes("execucoes") ? "selecao" : "selecao2"
						}`}
						onClick={() => {
							handleActiveClick("execucoes");
							AmplitudeLog("exercicios_excluidos");
						}}
					>
						Execuções
					</button>
				</div>
			</div>
			{selected.includes("pacientes") &&
				(desabilitadosSessenta.length > 0 ? (
					<div className="body">
						{desabilitadosSessenta.map((row: PacientesProps, index: any) => (
							<div key={index}>
								{
									//row.USUARIO_EXCLUIDO === true &&
									<div className="conteudo" id="conteudo" key={row.ID_USUARIO}>
										<div
											className={
												isPresentInListSelecteds(row) ? "usuario2" : "usuario"
											}
											id={row.USUARIO_NOME}
										>
											<div className="left">
												<div className="conteinerImg">
													{row.USUARIO_FOTO != "" &&
													row.USUARIO_FOTO != null &&
													row.USUARIO_FOTO != "img/photo.png" ? (
														<div className="conteinerImg">
															<img
																src={row.USUARIO_FOTO}
																alt=""
																className="imgForInput"
															/>
														</div>
													) : (
														<div className="conteinerImg">
															<img
																src={Perfil}
																alt=""
																className="imgForInput"
															/>
														</div>
													)}
												</div>
											</div>
											<div className="mid" onClick={handlePaciente}>
												<h1 className="nome">{row.USUARIO_NOME}</h1>
												<div className="PacienteEdata">
													<div className="Divpaciente">
														<img className="user" src={UserAzul} alt="" />
														<p className="paciente">
															{(row.USUARIO_NIVEL == 2 &&
																current_language?.pag_inicial_avaliador) ||
																current_language?.dash_paciente}
														</p>
													</div>
												</div>
												<div>
													<p className="avaliador">
														{current_language?.idioma === "Português" ? (
															parseInt(
																calcular_diferenca_data(
																	row.USUARIO_ATUALIZADO
																).toFixed(0)
															) > 1 ? (
																<span>
																	Excluído há{" "}
																	<span className="vermelho">
																		{calcular_diferenca_data(
																			row.USUARIO_ATUALIZADO
																		).toFixed(0)}{" "}
																		dias
																	</span>
																</span>
															) : (
																"Excluído recentemente"
															)
														) : current_language?.idioma === "English" ? (
															parseInt(
																calcular_diferenca_data(
																	row.USUARIO_ATUALIZADO
																).toFixed(0)
															) > 1 ? (
																<span>
																	Deleted{" "}
																	<span className="vermelho">
																		{calcular_diferenca_data(
																			row.USUARIO_ATUALIZADO
																		).toFixed(0)}{" "}
																		days
																	</span>{" "}
																	ago
																</span>
															) : (
																"Recently deleted"
															)
														) : parseInt(
																calcular_diferenca_data(
																	row.USUARIO_ATUALIZADO
																).toFixed(0)
														  ) > 1 ? (
															<span>
																Eliminado hace{" "}
																<span className="vermelho">
																	{calcular_diferenca_data(
																		row.USUARIO_ATUALIZADO
																	).toFixed(0)}{" "}
																	días
																</span>
															</span>
														) : (
															"Recientemente eliminado"
														)}
													</p>
												</div>
												<div className="tags">
													{getEtiquetas(row, etis, lista_filtro).map(
														(e: any) => {
															return (
																<span
																	key={e.ID_ETIQUETA}
																	className={`tag ${
																		lista_filtro.indexOf(e.ID_ETIQUETA) >= 0 &&
																		"tagSelected"
																	}`}
																>
																	{e.ETIQUETA_NOME}
																</span>
															);
														}
													)}
												</div>
											</div>
											<div className="right">
												<div className="r">
													<div className="divOpcoes">
														<div className="custom-checkbox">
															<input
																// name={row.USUARIO_NOME}
																id={row.ID_USUARIO.toString()}
																type="checkbox"
																onClick={HandleClassUsuario(row)}
																{...register(`${row.ID_USUARIO}`, {})}
															/>
															<label
																htmlFor={row.ID_USUARIO.toString()}
															></label>
														</div>
													</div>
												</div>
												<div className="divRecuperarConta">
													<p
														className="recuperar"
														onClick={handleModalRecuperarConta({
															id: row.ID_USUARIO,
															mode: "conta",
														})}
													>
														{current_language?.recuperar_conta}
													</p>
												</div>
											</div>
										</div>
										<div className="divisor">
											<hr className="hr" />
										</div>
									</div>
								}
							</div>
						))}
					</div>
				) : (
					<div className="body">
						<div className="semExcluido">
							<svg
								width="161"
								height="161"
								viewBox="0 0 161 161"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="80.5" cy="80.5" r="80.5" fill="#EBEBEB" />
								<rect x="50" y="64" width="60" height="60" fill="white" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M104.777 65.1219H55.5683C53.3036 65.1219 51.4676 66.9579 51.4676 69.2226V118.431C51.4676 120.696 53.3036 122.532 55.5683 122.532H104.777C107.042 122.532 108.878 120.696 108.878 118.431V69.2226C108.878 66.9579 107.042 65.1219 104.777 65.1219ZM55.5683 59.6543C50.2839 59.6543 46 63.9382 46 69.2226V118.431C46 123.716 50.2839 128 55.5683 128H104.777C110.061 128 114.345 123.716 114.345 118.431V69.2226C114.345 63.9382 110.061 59.6543 104.777 59.6543H55.5683Z"
									fill="#5C5B5B"
								/>
								<path
									d="M64.1113 85.9672C64.1113 84.4574 65.3352 83.2334 66.8451 83.2334C68.3549 83.2334 69.5789 84.4574 69.5789 85.9672V92.8017C69.5789 94.3116 68.3549 95.5356 66.8451 95.5356C65.3352 95.5356 64.1113 94.3116 64.1113 92.8017V85.9672Z"
									fill="#5C5B5B"
								/>
								<path
									d="M91.7916 85.9672C91.7916 84.4574 93.0156 83.2334 94.5254 83.2334C96.0353 83.2334 97.2593 84.4574 97.2593 85.9672V92.8017C97.2593 94.3116 96.0353 95.5356 94.5254 95.5356C93.0156 95.5356 91.7916 94.3116 91.7916 92.8017V85.9672Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M77.238 91.8659C80.1049 92.9476 83.2976 92.3099 84.4743 91.8659C85.1807 91.5994 85.9693 91.9559 86.2358 92.6623C86.5023 93.3686 86.1457 94.1572 85.4394 94.4237C83.8823 95.0112 79.9671 95.8176 76.2729 94.4237C75.5666 94.1572 75.21 93.3686 75.4765 92.6623C75.743 91.9559 76.5317 91.5994 77.238 91.8659Z"
									fill="#5C5B5B"
								/>
								<path
									d="M75.3885 53.1623C75.3885 51.0863 77.0714 49.4033 79.1475 49.4033C81.2235 49.4033 82.9065 51.0863 82.9065 53.1623V61.3638C82.9065 63.4398 81.2235 65.1227 79.1475 65.1227C77.0714 65.1227 75.3885 63.4398 75.3885 61.3638V53.1623Z"
									fill="#5C5B5B"
								/>
								<path
									d="M85.6403 54.8701C86.3952 54.8701 87.0072 55.4821 87.0072 56.237C87.0072 56.9919 86.3952 57.6039 85.6403 57.6039L72.6547 57.6039C71.8998 57.6039 71.2878 56.9919 71.2878 56.237C71.2878 55.4821 71.8998 54.8701 72.6547 54.8701L85.6403 54.8701Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8057 38.4676C75.786 38.4676 73.3381 40.9156 73.3381 43.9353V45.6409C73.3381 47.5434 74.0939 49.368 75.4391 50.7132L77.6633 52.9374L73.7971 56.8036L71.5729 54.5794C69.2023 52.2088 67.8705 48.9935 67.8705 45.6409V43.9353C67.8705 37.8959 72.7664 33 78.8057 33V38.4676Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8058 38.4676C81.8255 38.4676 84.2734 40.9156 84.2734 43.9353V45.6409C84.2734 47.5434 83.5176 49.368 82.1724 50.7132L79.9482 52.9374L83.8144 56.8036L86.0386 54.5794C88.4092 52.2088 89.741 48.9935 89.741 45.6409V43.9353C89.741 37.8959 84.8451 33 78.8058 33V38.4676Z"
									fill="#5C5B5B"
								/>
								<path
									d="M107.169 116.381V102.028C90.3268 106.295 72.7087 106.475 55.7828 102.553L53.518 102.028V116.381L55.8488 116.011C72.8597 113.314 90.1988 113.439 107.169 116.381Z"
									fill="#000E4B"
								/>
							</svg>
							<p>{current_language?.nenhum_paciente_excluido}</p>
						</div>
					</div>
				))}
			{selected.includes("exercicios") &&
				(exerciciosDeletadosSessenta.length > 0 ? (
					<div className="divExercicio">
						{exerciciosDeletadosSessenta.map((row: Exercicios2Props) => (
							<div className="fichaExercicio" key={row.ID_EXERCICIO}>
								<div className="a">
									<p className="p">
										{current_language?.idioma === "Português"
											? row.EXERCICIO_TITULO_PT
											: current_language?.idioma === "English"
											? row.EXERCICIO_TITULO_EN
											: row.EXERCICIO_TITULO_ES}
									</p>
									<p>
										{current_language?.idioma === "Português" ? (
											parseInt(
												calcular_diferenca_data(
													row.EXERCICIO_ATUALIZADO
												).toFixed(0)
											) > 1 ? (
												<span>
													Excluído há{" "}
													<span className="vermelho">
														{calcular_diferenca_data(
															row.EXERCICIO_ATUALIZADO
														).toFixed(0)}{" "}
														dias
													</span>
												</span>
											) : (
												"Excluído recentemente"
											)
										) : current_language?.idioma === "English" ? (
											parseInt(
												calcular_diferenca_data(
													row.EXERCICIO_ATUALIZADO
												).toFixed(0)
											) > 1 ? (
												<span>
													Deleted{" "}
													<span className="vermelho">
														{calcular_diferenca_data(
															row.EXERCICIO_ATUALIZADO
														).toFixed(0)}{" "}
														days
													</span>{" "}
													ago
												</span>
											) : (
												"Recently deleted"
											)
										) : parseInt(
												calcular_diferenca_data(
													row.EXERCICIO_ATUALIZADO
												).toFixed(0)
										  ) > 1 ? (
											<span>
												Eliminado hace{" "}
												<span className="vermelho">
													{calcular_diferenca_data(
														row.EXERCICIO_ATUALIZADO
													).toFixed(0)}{" "}
													días
												</span>
											</span>
										) : (
											"Recientemente eliminado"
										)}
									</p>
								</div>
								<div className="opo">
									<div className="custom-checkbox">
										<input
											id={`${row.ID_EXERCICIO}`}
											type="checkbox"
											checked={
												exerciciosrecuperacao.indexOf(row.ID_EXERCICIO) >= 0
													? true
													: false
											}
											onChange={HandleClassExcluidos(row.ID_EXERCICIO)}
										/>
										<label htmlFor={`${row.ID_EXERCICIO}`}></label>
									</div>
									<div className="divRecuperarConta">
										<p
											className="recuperar"
											onClick={handleModalRecuperarConta({
												id: row.ID_EXERCICIO,
												mode: "exercicio",
											})}
										>
											{current_language?.recuperar}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<div className="body">
						<div className="semExcluido">
							<svg
								width="161"
								height="161"
								viewBox="0 0 161 161"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="80.5" cy="80.5" r="80.5" fill="#EBEBEB" />
								<rect x="50" y="64" width="60" height="60" fill="white" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M104.777 65.1219H55.5683C53.3036 65.1219 51.4676 66.9579 51.4676 69.2226V118.431C51.4676 120.696 53.3036 122.532 55.5683 122.532H104.777C107.042 122.532 108.878 120.696 108.878 118.431V69.2226C108.878 66.9579 107.042 65.1219 104.777 65.1219ZM55.5683 59.6543C50.2839 59.6543 46 63.9382 46 69.2226V118.431C46 123.716 50.2839 128 55.5683 128H104.777C110.061 128 114.345 123.716 114.345 118.431V69.2226C114.345 63.9382 110.061 59.6543 104.777 59.6543H55.5683Z"
									fill="#000E4B"
								/>
								<path
									d="M64.1113 85.9672C64.1113 84.4574 65.3352 83.2334 66.8451 83.2334C68.3549 83.2334 69.5789 84.4574 69.5789 85.9672V92.8017C69.5789 94.3116 68.3549 95.5356 66.8451 95.5356C65.3352 95.5356 64.1113 94.3116 64.1113 92.8017V85.9672Z"
									fill="#000E4B"
								/>
								<path
									d="M91.7916 85.9672C91.7916 84.4574 93.0156 83.2334 94.5254 83.2334C96.0353 83.2334 97.2593 84.4574 97.2593 85.9672V92.8017C97.2593 94.3116 96.0353 95.5356 94.5254 95.5356C93.0156 95.5356 91.7916 94.3116 91.7916 92.8017V85.9672Z"
									fill="#000E4B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M77.238 91.8659C80.1049 92.9476 83.2976 92.3099 84.4743 91.8659C85.1807 91.5994 85.9693 91.9559 86.2358 92.6623C86.5023 93.3686 86.1457 94.1572 85.4394 94.4237C83.8823 95.0112 79.9671 95.8176 76.2729 94.4237C75.5666 94.1572 75.21 93.3686 75.4765 92.6623C75.743 91.9559 76.5317 91.5994 77.238 91.8659Z"
									fill="#000E4B"
								/>
								<path
									d="M75.3885 53.1623C75.3885 51.0863 77.0714 49.4033 79.1475 49.4033C81.2235 49.4033 82.9065 51.0863 82.9065 53.1623V61.3638C82.9065 63.4398 81.2235 65.1227 79.1475 65.1227C77.0714 65.1227 75.3885 63.4398 75.3885 61.3638V53.1623Z"
									fill="#000E4B"
								/>
								<path
									d="M85.6403 54.8701C86.3952 54.8701 87.0072 55.4821 87.0072 56.237C87.0072 56.9919 86.3952 57.6039 85.6403 57.6039L72.6547 57.6039C71.8998 57.6039 71.2878 56.9919 71.2878 56.237C71.2878 55.4821 71.8998 54.8701 72.6547 54.8701L85.6403 54.8701Z"
									fill="#000E4B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8057 38.4676C75.786 38.4676 73.3381 40.9156 73.3381 43.9353V45.6409C73.3381 47.5434 74.0939 49.368 75.4391 50.7132L77.6633 52.9374L73.7971 56.8036L71.5729 54.5794C69.2023 52.2088 67.8705 48.9935 67.8705 45.6409V43.9353C67.8705 37.8959 72.7664 33 78.8057 33V38.4676Z"
									fill="#000E4B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8058 38.4676C81.8255 38.4676 84.2734 40.9156 84.2734 43.9353V45.6409C84.2734 47.5434 83.5176 49.368 82.1724 50.7132L79.9482 52.9374L83.8144 56.8036L86.0386 54.5794C88.4092 52.2088 89.741 48.9935 89.741 45.6409V43.9353C89.741 37.8959 84.8451 33 78.8058 33V38.4676Z"
									fill="#000E4B"
								/>
								<path
									d="M107.169 116.381V102.028C90.3268 106.295 72.7087 106.475 55.7828 102.553L53.518 102.028V116.381L55.8488 116.011C72.8597 113.314 90.1988 113.439 107.169 116.381Z"
									fill="#00D084"
								/>
							</svg>
							<p>{current_language?.nenhum_exercicio_excluido}</p>
						</div>
					</div>
				))}
			{selected.includes("execucoes") &&
				execucoesExcluidasSessenta.length > 0 && (
					<div className="divExercicio">
						{execucoesExcluidasSessenta.map((row: any) => (
							<div className="fichaExercicio" key={row.ID_EXECUCAO}>
								<div className="a">
									<p className="p">
										{current_language?.idioma === "Português"
											? row?.EXERCICIO_TITULO_PT
											: current_language?.idioma === "English"
											? row?.EXERCICIO_TITULO_PT
											: row?.EXERCICIO_TITULO_PT}
									</p>
									<p>
										{current_language?.idioma === "Português" ? (
											parseInt(
												calcular_diferenca_data(
													row.EXECUCAO_ATUALIZADA
												).toFixed(0)
											) > 1 ? (
												<span>
													Excluído há{" "}
													<span className="vermelho">
														{calcular_diferenca_data(
															row.EXECUCAO_ATUALIZADA
														).toFixed(0)}{" "}
														dias
													</span>
												</span>
											) : (
												"Excluído recentemente"
											)
										) : current_language?.idioma === "English" ? (
											parseInt(
												calcular_diferenca_data(
													row.EXECUCAO_ATUALIZADA
												).toFixed(0)
											) > 1 ? (
												<span>
													Deleted{" "}
													<span className="vermelho">
														{calcular_diferenca_data(
															row.EXECUCAO_ATUALIZADA
														).toFixed(0)}{" "}
														days
													</span>{" "}
													ago
												</span>
											) : (
												"Recently deleted"
											)
										) : parseInt(
												calcular_diferenca_data(
													row.EXECUCAO_ATUALIZADA
												).toFixed(0)
										  ) > 1 ? (
											<span>
												Eliminado hace{" "}
												<span className="vermelho">
													{calcular_diferenca_data(
														row.EXECUCAO_ATUALIZADA
													).toFixed(0)}{" "}
													días
												</span>
											</span>
										) : (
											"Recientemente eliminado"
										)}
									</p>
								</div>
								<div className="opo">
									<div className="custom-checkbox">
										<input
											id={`${row.ID_EXECUCAO}`}
											type="checkbox"
											checked={
												execucoesExc.indexOf(row.ID_EXECUCAO) >= 0
													? true
													: false
											}
											onChange={HandleClassExecucoes(row.ID_EXECUCAO)}
										/>
										<label htmlFor={`${row.ID_EXECUCAO}`}></label>
									</div>
									<div className="divRecuperarConta">
										<p
											className="recuperar"
											onClick={handleModalRecuperarConta({
												id: row.ID_EXECUCAO,
												mode: "exec",
											})}
										>
											{current_language?.recuperar}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			{carregando_execucoes && selected.includes("execucoes") && <Loading />}
			{carregando_execucoes == false &&
				execucoesExcluidasSessenta.length == 0 &&
				selected.includes("execucoes") && (
					<div className="body">
						<div className="semExcluido">
							<svg
								width="161"
								height="161"
								viewBox="0 0 161 161"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="80.5" cy="80.5" r="80.5" fill="#EBEBEB" />
								<rect x="50" y="64" width="60" height="60" fill="white" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M104.777 65.1219H55.5683C53.3036 65.1219 51.4676 66.9579 51.4676 69.2226V118.431C51.4676 120.696 53.3036 122.532 55.5683 122.532H104.777C107.042 122.532 108.878 120.696 108.878 118.431V69.2226C108.878 66.9579 107.042 65.1219 104.777 65.1219ZM55.5683 59.6543C50.2839 59.6543 46 63.9382 46 69.2226V118.431C46 123.716 50.2839 128 55.5683 128H104.777C110.061 128 114.345 123.716 114.345 118.431V69.2226C114.345 63.9382 110.061 59.6543 104.777 59.6543H55.5683Z"
									fill="#5C5B5B"
								/>
								<path
									d="M64.1113 85.9672C64.1113 84.4574 65.3352 83.2334 66.8451 83.2334C68.3549 83.2334 69.5789 84.4574 69.5789 85.9672V92.8017C69.5789 94.3116 68.3549 95.5356 66.8451 95.5356C65.3352 95.5356 64.1113 94.3116 64.1113 92.8017V85.9672Z"
									fill="#5C5B5B"
								/>
								<path
									d="M91.7916 85.9672C91.7916 84.4574 93.0156 83.2334 94.5254 83.2334C96.0353 83.2334 97.2593 84.4574 97.2593 85.9672V92.8017C97.2593 94.3116 96.0353 95.5356 94.5254 95.5356C93.0156 95.5356 91.7916 94.3116 91.7916 92.8017V85.9672Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M77.238 91.8659C80.1049 92.9476 83.2976 92.3099 84.4743 91.8659C85.1807 91.5994 85.9693 91.9559 86.2358 92.6623C86.5023 93.3686 86.1457 94.1572 85.4394 94.4237C83.8823 95.0112 79.9671 95.8176 76.2729 94.4237C75.5666 94.1572 75.21 93.3686 75.4765 92.6623C75.743 91.9559 76.5317 91.5994 77.238 91.8659Z"
									fill="#5C5B5B"
								/>
								<path
									d="M75.3885 53.1623C75.3885 51.0863 77.0714 49.4033 79.1475 49.4033C81.2235 49.4033 82.9065 51.0863 82.9065 53.1623V61.3638C82.9065 63.4398 81.2235 65.1227 79.1475 65.1227C77.0714 65.1227 75.3885 63.4398 75.3885 61.3638V53.1623Z"
									fill="#5C5B5B"
								/>
								<path
									d="M85.6403 54.8701C86.3952 54.8701 87.0072 55.4821 87.0072 56.237C87.0072 56.9919 86.3952 57.6039 85.6403 57.6039L72.6547 57.6039C71.8998 57.6039 71.2878 56.9919 71.2878 56.237C71.2878 55.4821 71.8998 54.8701 72.6547 54.8701L85.6403 54.8701Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8057 38.4676C75.786 38.4676 73.3381 40.9156 73.3381 43.9353V45.6409C73.3381 47.5434 74.0939 49.368 75.4391 50.7132L77.6633 52.9374L73.7971 56.8036L71.5729 54.5794C69.2023 52.2088 67.8705 48.9935 67.8705 45.6409V43.9353C67.8705 37.8959 72.7664 33 78.8057 33V38.4676Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8058 38.4676C81.8255 38.4676 84.2734 40.9156 84.2734 43.9353V45.6409C84.2734 47.5434 83.5176 49.368 82.1724 50.7132L79.9482 52.9374L83.8144 56.8036L86.0386 54.5794C88.4092 52.2088 89.741 48.9935 89.741 45.6409V43.9353C89.741 37.8959 84.8451 33 78.8058 33V38.4676Z"
									fill="#5C5B5B"
								/>
								<path
									d="M107.169 116.381V102.028C90.3268 106.295 72.7087 106.475 55.7828 102.553L53.518 102.028V116.381L55.8488 116.011C72.8597 113.314 90.1988 113.439 107.169 116.381Z"
									fill="#000E4B"
								/>
							</svg>
							<p>{current_language?.nenhuma_execucao_excluida}</p>
						</div>
					</div>
				)}
			<div className="divBotao">
				{listaExcluidos.length > 0 && (
					<div>
						<button
							className="btnRecuperar"
							onClick={handleModalRecuperarConta({ id: "id", mode: "contas" })}
						>
							{current_language?.recuperar_todas_selecionadas}
						</button>
					</div>
				)}

				{exerciciosrecuperacao.length > 0 && (
					<div>
						<button
							className="btnRecuperar"
							onClick={handleModalRecuperarConta({
								id: "id",
								mode: "exercicios",
							})}
						>
							{current_language?.recuperar_todas_selecionadas}
						</button>
					</div>
				)}

				{execucoesExc.length > 0 && (
					<div>
						<button
							className="btnRecuperar"
							onClick={handleModalRecuperarConta({
								id: "id",
								mode: "execs",
							})}
						>
							{current_language?.recuperar_todas_selecionadas}
						</button>
					</div>
				)}
			</div>
		</Conteiner>
	);
};

export default memo(GerenciadorExcluidos);

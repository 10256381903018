import React from "react";
import styled from "styled-components";

import Tutorial from "..";

import Menu from "../../../../components/Menu";
import BarraSuperiorControladora from "../../../Dashboard/BarrasSuperiores/BarraSuperiorControladora";
import {
	Cards,
	Container,
	Group,
	ListagemEcards,
} from "../../../Dashboard/ContentDash/styles";

const tutorialController: React.FC = () => {
	return (
		<ViewPort>
			<Menu></Menu>
			<Container>
				<BarraSuperiorControladora />
				<ListagemEcards>
					<Group>
						<Tutorial />
					</Group>
				</ListagemEcards>
			</Container>
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	height: 100vh;
	min-height: 797px;
	width: 100vw;
	display: flex;
	color: rgba(140, 142, 134, 0, 8);
	min-height: 48.125rem;
`;

export default tutorialController;

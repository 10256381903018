import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { Container, Content } from './styles';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCofiguracoesExecucaoForcaMin: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_modal_config_forc_min = useSelector(
		(state: any) => state.app.is_modal_config_forc_min
	);

	const max_force = useSelector((state: any) => state.avaliation.max_force);
	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const force_option_min_str = useSelector(
		(state: any) => state.avaliation.force_option_min_str
	);

	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);

	const atualizacao_proto: any = [];
	execucoes.map((e: any) => {
		atualizacao_proto.push({
			ID_EXERCICIO: e.ID_EXERCICIO,
			EXECUCAO_ATUALIZADA: e.EXECUCAO_ATUALIZADA,
			EXECUCAO_EXECUTADA: e.EXECUCAO_EXECUTADA,
		});
	});

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_config_forc_min(false));
	};

	const aumentar = () => {
		let aumentei = force_option_min + 0.2;
		dispatch(AvaliationActions.set_force_option_min(aumentei));
		if (aumentei >= 199.8) {
			dispatch(AvaliationActions.set_force_option_min(0.2));
			aumentei = 0.2;
		}
		if (max_force) {
			const max = (aumentei * 20) / 100;
			const calc = aumentei >= 199.6 ? 200 : aumentei + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
		let newValueMin = aumentei?.toFixed(2)?.toString();
		newValueMin = newValueMin?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_min_str(newValueMin));
	};

	const diminuir = () => {
		let diminui = (force_option_min - 0.2);
		dispatch(AvaliationActions.set_force_option_min(diminui));
		if (diminui <= 0.2) {
			dispatch(AvaliationActions.set_force_option_min(199.8));
			diminui = 199.8;
		}
		if (max_force) {
			const max = (diminui * 20) / 100;
			const calc = diminui === 199.8 ? 200 : diminui + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
		let newValueMin = diminui?.toFixed(2)?.toString();
		newValueMin = newValueMin?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_min_str(newValueMin));
	};

	const [valorMinimo, setValorMinimo] = useState(force_option_min);
	const [valorMinimoStr, setValorMinimoStr] = useState(force_option_min_str);

	useEffect(() => {
		setValorMinimo(force_option_min);
		setValorMinimoStr(force_option_min_str);

		if (max_force) {
			const max = (force_option_min * 20) / 100;
			const calc = force_option_min >= 199.6 ? 200 : force_option_min + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
	}, [force_option_min, force_option_min_str]);

	useEffect(()=>{
		if (max_force === false) {
			dispatch(AvaliationActions.set_force_option_max(force_option_min * 2));
		}
	},[max_force])

	// Segurar botão Mínimo Diminui rápido
	const [pressionadoMinDim, setPressionadoMinDim] = useState(false);

	const segurarBotãoMinDim = () => {
		setPressionadoMinDim(true);
	};

	const soltarBotãoMinDim = () => {
		setPressionadoMinDim(false);
	};

	useEffect(() => {
		if (pressionadoMinDim === true) {
			setTimeout(() => {
				diminuir();
			}, 50);
		} else if (pressionadoMinDim === false) {
			return;
		}
	}, [pressionadoMinDim, force_option_min]);

	// Segurar botão Mínimo Aumenta rápido
	const [pressionadoMinAum, setPressionadoMinAum] = useState(false);

	const segurarBotãoMinAum = () => {
		setPressionadoMinAum(true);
	};

	const soltarBotãoMinAum = () => {
		setPressionadoMinAum(false);
	};

	useEffect(() => {
		if (pressionadoMinAum === true) {
			setTimeout(() => {
				aumentar();
			}, 50);
		} else if (pressionadoMinAum === false) {
			return;
		}
	}, [pressionadoMinAum, force_option_min]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_modal_config_forc_min}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_modal_config_forc_min}>
					<Container>
						<img src={buttonClose} onClick={() => ModalCadastroClose()} className="close" />
						<Content>
							<p className="text title">
								{current_language?.modal_configurar_execucao}
							</p>

							<div className="divText">
								<div>
									<svg
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0 -5.24537e-07L6 8.5L12 0L0 -5.24537e-07ZM12 10L-4.37114e-07 10L-5.24537e-07 12L12 12L12 10Z"
											fill="#5C5B5B"
										/>
									</svg>
									<span>{current_language?.execucao_force_min}. (kg)</span>
								</div>
								<div className="numberSelect">
									<button
										className="buttons"
										onMouseUp={soltarBotãoMinDim}
										onMouseDown={segurarBotãoMinDim}
										onClick={diminuir}
									>
										<svg
											width="16"
											height="2"
											viewBox="0 0 16 2"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M15 1H1"
												stroke="#000E4B"
												strokeWidth="2"
												strokeLinecap="round"
											/>
										</svg>
									</button>

									<span>
										{" "}
										{valorMinimo < 10
											? `00${valorMinimoStr}`
											: valorMinimo < 100
												? `0${valorMinimoStr}`
												: valorMinimoStr}
									</span>
									<button
										className="buttons"
										onMouseUp={soltarBotãoMinAum}
										onMouseDown={segurarBotãoMinAum}
										onClick={aumentar}
									>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8 0C8.26522 0 8.51957 0.105357 8.70711 0.292893C8.89464 0.48043 9 0.734784 9 1V7H15C15.2652 7 15.5196 7.10536 15.7071 7.29289C15.8946 7.48043 16 7.73478 16 8C16 8.26522 15.8946 8.51957 15.7071 8.70711C15.5196 8.89464 15.2652 9 15 9H9V15C9 15.2652 8.89464 15.5196 8.70711 15.7071C8.51957 15.8946 8.26522 16 8 16C7.73478 16 7.48043 15.8946 7.29289 15.7071C7.10536 15.5196 7 15.2652 7 15V9H1C0.734784 9 0.48043 8.89464 0.292893 8.70711C0.105357 8.51957 0 8.26522 0 8C0 7.73478 0.105357 7.48043 0.292893 7.29289C0.48043 7.10536 0.734784 7 1 7H7V1C7 0.734784 7.10536 0.48043 7.29289 0.292893C7.48043 0.105357 7.73478 0 8 0V0Z"
												fill="#000E4B"
											/>
										</svg>
									</button>
								</div>
							</div>
							<div className="footer">
								<button className="button" onClick={ModalCadastroClose}>
									{current_language?.pagamento_salvar}
								</button>
							</div>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalCofiguracoesExecucaoForcaMin;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	margin-right: 2.6%;
	padding-top: 3.6rem;
	padding-bottom: 5vh;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	background-color: white;
	border-radius: 1.25rem;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-left: 2.9rem;

	.nome {
		font-weight: 500;
		font-size: 1.5rem;

		color: #5c5b5b;
		width: 20rem;
	}

	.identificacao {
		width: 100%;

		margin-left: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.bodyHeader {
		width: 50%;
		display: flex;
	}

	button {
		margin-right: 3rem;
		padding: 0.75rem 3.5rem;

		height: 3.625rem;
		width: 17.625rem;

		border-radius: 0.5rem;
		border: 0;

		border: 1.5px solid #ffffff;

		background: ${colors.primary_blue_k_main};
		color: #ffffff;

		font-weight: 500;
		font-size: 1.125rem;

		transition: all 0.2s linear;
	}

	button:hover {
		color: ${colors.primary_blue_k_main};
		border: 1.5px solid ${colors.primary_blue_k_main};
		background: #ffffff;
	}
`;

export const BodyEdite = styled.div`
	margin-left: 2.9rem;
	margin-top: 3rem;

	.lineItens {
		display: grid;
		grid-template-columns: 40% 33% 30%;
		grid-auto-rows: minmax(2rem, auto);
		grid-row-gap: 3rem;
	}

	.item {
		display: flex;
		align-items: center;
		p {
			margin-left: 0.5rem;

			font-size: 1rem;

			color: #5c5b5b;
		}
	}
`;

export const Content = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.labelForInput {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}

	.conteinerImg {
		background-color: #e5e5e5;
		width: 5.3rem;
		height: 5.3rem;
		border-radius: 50%;
		padding: 4.5px;
	}

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.camera {
		display: flex;
		margin-left: 75px;
		width: 45px;
		height: 45px;
		margin-top: -40px;
	}

	button {
		margin-right: 3rem;
		padding: 0.75rem 3.5rem;

		height: 3.625rem;
		width: 17.625rem;

		border-radius: 0.5rem;
		border: 0;

		border: 1.5px solid #ffffff;

		background: ${colors.primary_blue_k_main};
		color: #ffffff;

		font-weight: 500;
		font-size: 1.125rem;

		transition: all 0.2s linear;
	}

	button:hover {
		color: ${colors.primary_blue_k_main};
		border: 1.5px solid ${colors.primary_blue_k_main};
		background: #ffffff;
	}
`;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-top: 3.6rem;
	padding-bottom: 2.5rem;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	border-radius: 1.25rem;

	.nfunciona {
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		img {
			width: 7rem;
			height: 7rem;
			margin-bottom: 1rem;
		}

		div {
			width: 50%;
		}

		.text {
			font-size: 1.1rem;
			font-weight: 400;
			color: #5c5b5b;
			text-align: center;
		}
	}

	.pulse {
		display: block;
		width: 30px;
		height: 25px;
		border-radius: 50%;
		background: transparent;
		cursor: pointer;
		box-shadow: 0 0 0 ${colors.primary_blue_k_dark_2};
		animation: pulse 2s infinite;
		border: none;
	}
	.pulse:hover {
		animation: none;
	}

	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
		}
		70% {
			-webkit-box-shadow: 0 0 0 10px ${colors.primary_blue_k_dark_2};
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
			box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
		}
		70% {
			-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		}
	}
	
	.conteudo {
		display:flex;
		width: 100%;
	}
	.card{
		display: flex;
		align-items: center;
		height: 120%;
		margin-top:5px;
		margin-bottom:5px;
		margin-left: 2px;
		width: 99%;
		border-radius: 8px;
		box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
	}
	.maisVisitados {
		font-size: 1.4rem;
		font-weight: 400;
		color: #404040;
		margin-top: 1.8rem;
		margin-bottom: 0.5rem;
		width: 100%;
	}

	.body {
		width: 100%;
		height: 90%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	

	.left {
		display: flex;
		margin-left:0.5%;
	}

	.mid {
		margin-left: 2.4%;
		width: 77%;
		cursor: pointer;
	}

	.usuario {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6.5rem;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}
	.usuario2 {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}

	.usuarioAzul {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}

	.avaliador {
		font-weight: 400;
	}

	.nome {
		font-size: 1.25rem;
		margin-top: 1%;
		margin-bottom: 4%;
		cursor: pointer;
	}

	.tags {
		display:flex;
		width: 100%;
		margin-top: 2%;
	}

	.tag {
		display:flex;
		background-color:${colors.neutral_grey_k_50};
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
		margin-bottom: 2%;
	}

	.tagVermelha {
		background-color: ${colors.secondary_green_k_medium_1};
	}

	.right {
		display: flex;
		width: 23%;
		flex-direction: row;
		justify-content: flex-end;
		margin-right: 15px;
	}

	.hr {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		border: none;
		height: 0.063rem;
		background-color: #e5e5e5;
	}

	.PacienteEdata {
		width: 80%;
	}

	.Divpaciente {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.paciente {
		font-size: 1rem;
		color: #5c5b5b;
		font-weight: 400;
	}

	.divOpcoes {
		width: 20%;
		height: 100%;
		display: flex;
		flex-direction: column;
		float: inline-end;
	}

	.botaoPontos {
		margin-top: 2rem;
		background-color: transparent;
		border: none;
	}

	.pontos {
		width: 1.25rem;
		height: 1.25rem;
		margin-top: 5%;
		float: right;
	}

	.user {
		width: 1rem;
		height: 1rem;
		margin-right: 5%;
	}

	.dia {
		margin-top: 5%;
	}

	.btnAdicionar {
		display: flex;
		flex-direction: row;
		width: 13.75rem;
		height: 4.125rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
		@media (max-height: 900px) {
			margin-top: 1.5rem;
		}
	}

	.textoBotao {
		margin-left: 1rem;
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
	}

	.dropdown {
		position: relative;
		display: inline-block;
		margin-top: 4rem;
		cursor: pointer;
	}

	.dropdown-content {
		display: none;
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		min-height: 7.6rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
	}

	.dropdown-content2 {
		display: none;
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		min-height: 4.6rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	.dropdown:hover .dropdown-content2 {
		display: block;
	}

	.img {
		width: 1.18rem;
		height: 1.18rem;
		margin-right: 1rem;
	}

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.op {
		font-size: 1.25rem;
		width: 100%;
		margin-bottom: 1.4rem;
		font-weight: 500;
		color: #5c5b5b;
		background-color: transparent;
		border: none;
	}

	.op2 {
		font-size: 1.25rem;
		width: 100%;
		color: ${colors.tertiary_red_1_dark};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}

	.nfunciona {
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		svg {
			margin-bottom: 1rem;
		}

		div {
			width: 50%;
		}

		.text {
			font-size: 1.1rem;
			font-weight: 400;
			color: #5c5b5b;
			text-align: center;
		}
	}
`;
export const Header = styled.div`
	display: flex;
	height: 10%;
	

	.boxTitle{
		height:100%;
		display: flex;
		width:40%;
		align-items: center;
	}
	.title{
		margin-left: 5px;
		font-weight: 500;
		color:${colors.neutral_grey_k_80};
		font-size: 20;
	}
	.boxSearch{
		display: flex;
		height:100%;
		align-items: center;
		width:60%;
	}
	.input {
		display: flex;
		align-items: center;
		width: 60%;
		height: 2.3rem;
		background-color: #f2f2f2;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		border-radius: 10px;
	}
	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}
	.inputPesquisa {
		height: 90%;
		width: 100%;
		margin-left: 1rem;
		margin-right: 1rem;
		background-color: transparent;
		border: none;
	}

	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}

`;
export const NewEvalutor = styled.div`
	display: flex;
	height: 10%;
	justify-content: space-between;
	align-items: center;
`;

export const colors = {
    primary_blue_k_main:'#000E4B',
	primary_blue_k_dark_2: '#001574',
	primary_blue_k_medium_1: '#0023BD',
	primary_blue_k_medium_2: '#0937FF',
	primary_blue_k_light_1: '#4585FF',
	primary_blue_k_light_2: '#bad2ff',
	blue_k_light_2: '#6B9DFF',
	blue_k_light_3: '#BAD2FF',
	blue_k_light_4: '#D1E0FF',
	secondary_green_k_dark_1: '#005760',
	secondary_green_k_dark_2: '#007C6B',
	secondary_green_k_medium_1: '#00A377',
	secondary_green_k_main: '#00D084',
	secondary_green_k_light_1: '#5FDFAC',
	secondary_green_k_light_2: '#CEF6E7',
	tertiary_blue_1_dark: '#0094D1',
	tertiary_blue_1_light: '#00D1CB',
	tertiary_green_1_dark: '#0CD100',
	tertiary_green_1_light: '#80F279',
	tertiary_yellow_1_dark: '#FFC530',
	tertiary_yellow_1_light: '#FFD580',
	tertiary_orange_1_dark: '#E57E00',
	tertiary_orange_1_light: '#E59D45',
	tertiary_red_1_dark: '#D12600',
	tertirary_red_1_light: '#E58873',
	default_gold_dark: '#99731F',
	default_gold_medium: '#D19400',
	default_gold_light: '#FFB000',
	neutral_grey_k_90: '#141622',
	neutral_grey_k_80: '#2A3048',
	neutral_grey_k_70: '#3D4259',
	neutral_grey_k_60: '#63677A',
	neutral_grey_k_50: '#797D8D',
	neutral_grey_k_40: '#9497A4',
	neutral_grey_k_30: '#ABADB7',
	neutral_grey_k_20: '#C4C5CC',
	neutral_grey_k_10: '#DEDFE3',
	neutral_grey_k_5: '#FAFAF5',
};
import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding-top: 1rem;
	padding-bottom: 1vh;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	border-radius: 1.25rem;

	.body {
		width: 100%;
		height: 80%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	.divRecuperarConta {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 50%;
	}

	.recuperar {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: ${colors.primary_blue_k_main};
	}

	.divCabecalho {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.divNav {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 5rem;
		align-items: flex-start;
		background-color: white;
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: 1.25rem;
	}

	.selecao {
		display: block;
		flex-direction: row;
		height: 100%;
		margin-right: 4.1rem;
		color: #001574;
		font-weight: 500;
		background-color: transparent;
		border-bottom: 3px solid #001574;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		display: block;
		flex-direction: row;
		height: 100%;
		margin-right: 4.1rem;
		color: #4d4d4d;
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		
	}

	.fichaExercicio {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 99%;
		height: 5rem;
		padding: 1.7rem;
		margin-top: 1rem;
		padding-right: 1rem;
		background-color: white;
		border: none;
		cursor:pointer;
		border-radius:8px;
		margin-right:10px;
		box-shadow: 1px 1px 4px #5c5b5b50;
	}

	.c {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;
		height: 100%;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;
	}

	.ca {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		width: 100%;
		height: 50%;
	}

	.cb {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50%;
		background-color: white;
	}

	.cc {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
	}

	.d {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		background-color: snow;
	}

	.condicao {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 1.3rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 90%;
		height: 100%;
	}
	.handleLogoTitle {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 100%;
	}

	.ab {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.b {
		display: flex;
		width: 10%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
	}
	.divProtoSugerido {
		display: flex;
		background-color: #E5E5E5;
		justify-content: center;
		min-width: 70px;
		padding-left:7px;
		padding-right:7px;
		border-radius: 10px;
		margin-top: 8px;
		align-items: center;

		.pa {
			color: #394452;
			font-size: 13px;
		}
	}
	.pipe {
		margin-left: 7px;
		margin-right:7px;
		margin-top: 8px;
	}
	.tempoEstimado {
		margin-top: 11px;
		margin-right: 8px;
		align-items: center;
		justify-content: center;


		.Time {
			font-weight: 400;
			color: #B3B3B3;
			font-size: 13px;
		}
	}
	.p {
		margin-right: 0.8rem;
		margin-left: 0.8rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #5c5b5b;
	}
	.pExer {
		margin-right: 0.8rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #5c5b5b;
	}

	.opo {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 10%;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.botao {
		color: ${colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		font-weight: 500;
		margin-left: 2.1rem;
	}

	.divExercicio {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 38rem;
		align-items: flex-end;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.avaliador {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #b3b3b3;
		margin-right: 2.6rem;
	}

	.sagital {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		text-align: right;
		color: #8c8e86;
		margin-right: 2.6rem;
	}

	.criado {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #b3b3b3;
	}

	.infos {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
	}

	.infosExercicios {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}

	.svg {
		margin-right: 0.6rem;
	}

	.button {
		background-color: transparent;
		border: none;
	}

	.dropdown {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.dropdown-content {
		display: none;
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		/* min-height: 7.6rem; */
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
	}

	.dropdown-content-active {
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		min-height: 7.6rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
		display: block;
	}

	.img {
		width: 1.18rem;
		height: 1.18rem;
		margin-right: 1rem;
	}

	.op {
		font-size: 1.25rem;
		width: 100%;
		margin-bottom: 1.4rem;
		font-weight: 500;
		color: #5c5b5b;
		background-color: transparent;
		border: none;
	}

	.op2 {
		font-size: 1.25rem;
		width: 100%;
		color: ${colors.tertiary_red_1_dark};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}

	.botaoAdd {
		margin-bottom: 1.3rem;
		margin-right: 2.6rem;
	}

	.superior {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	}

	.Divtitle {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: flex-start;
	}

	.span {
		background-color: ${colors.neutral_grey_k_50};
		border-radius: 32px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		text-align: center;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		color: #ffffff;
	}

	.rigth {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.menu-container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.menu-trigger {
		background: #ffffff;
		border-radius: 90px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 4px 6px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		border: none;
		vertical-align: middle;
		transition: box-shadow 0.4s ease;
		margin-left: auto; /* Strictly for positioning */
	}

	.menu-trigger:hover {
		box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	}

	.menu-trigger span {
		font-weight: 700;
		vertical-align: middle;
		font-size: 14px;
		margin: 0 10px;
	}

	.menu-trigger img {
		border-radius: 90px;
	}

	.menu {
		background: #ffffff;
		border-radius: 8px;
		position: absolute;
		top: 60px;
		right: 0;
		width: 300px;
		box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
		opacity: 0;
		visibility: hidden;
		transform: translateY(-20px);
		transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	}

	.menu.active {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}

	.menu ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.menu li {
		border-bottom: 1px solid #dddddd;
	}

	.menu li a {
		text-decoration: none;
		color: #333333;
		padding: 15px 20px;
		display: block;
	}

	.boxSearch {
		width: 100%;
	}


	.input {
		display: flex;
		align-items: center;
		width: 35%;
		height: 2.3rem;
		background-color: #f2f2f2;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		border-radius: 10px;
		margin-bottom: 1rem;
	}
	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}
	.inputPesquisa {
		height: 90%;
		width: 100%;
		margin-left: 1rem;
		margin-right: 1rem;
		background-color: transparent;
		border: none;
	}

	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}
`;
export const ContainerSearchBar = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	

`;


export const Content = styled.div`
	width: 98.5%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom:0.8rem;

	.title {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 30px;
		text-align: center;
		color: #5C5B5B;
		margin-bottom: 2rem;
	}

	.mid {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 2.8rem;
		background-color: #f2f2f2;
		padding: 0 0.75rem;
		border-radius: 3.75rem;
	}

	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}

	.inputPesquisa {
		width: 100%;
		height: 90%;
		margin: 0 1rem;
		background-color: transparent;
		border: none;
	}
`;


import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	margin-right: 2.6%;
	height: 100%;
	padding-top: 2rem;
	padding-bottom: 5vh;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	background-color: white;
	border-radius: 1.25rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	a {
		text-decoration: none;
	}
`;

export const BodyEdite = styled.div`
	margin-top: 2rem;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.lineItens {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(auto, 7rem);
		grid-row-gap: 1rem;
	}

	.number {
		width: 4rem;
		display: flex;
		justify-content: flex-end;

		font-weight: 400;
	}

	.capacidade {
		color: #b3b3b3;
	}

	.left {
		display: flex;
	}

	.item {
		border: 1px solid #e0e0e0;
		border-radius: 1rem;

		padding: 2rem;

		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			margin-left: 1rem;
		}
	}

	.info {
		p {
			font-weight: 500;
			font-size: 1.25rem;
			color: #5c5b5b;
		}
		padding: 0.5rem 0;

		.tituloCard {
			margin-bottom: 1rem;
		}
	}

	.dropdown {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.dropdown-content {
		display: none;
		flex-direction: column;
		position: absolute;
		background-color: #ffffff;
		min-width: 9.75rem;
		min-height: 4.6rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	.img {
		width: 1.18rem;
		height: 1.18rem;
		margin-right: 1rem;
	}

	.op {
		font-size: 1.25rem;
		width: 100%;
		margin-bottom: 1.4rem;
		font-weight: 500;
		color: #5c5b5b;
		background-color: transparent;
		border: none;
	}

	.op2 {
		font-size: 1.25rem;
		width: 100%;
		color: ${colors.tertiary_red_1_dark};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}

	.pontos {
		width: 1.25rem;
		height: 1.25rem;
		float: right;
	}

	.botaoSelecao {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.Adicionar {
		cursor: pointer;

		display: flex;
		align-items: center;

		margin-left: 2.5rem;
		margin-top: 2rem;

		p {
			margin-left: 0.5rem;
			color: ${colors.primary_blue_k_dark_2};
		}
	}
`;

export const Footer = styled.div`
	border-top: 1px solid #e0e0e0;

	.item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.5rem;
		margin-bottom: 1rem;
		p {
			cursor: pointer;
			margin-left: 1rem;
			margin-top: 0.2rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			cursor: pointer;
			margin-left: 1rem;
		}
	}

	.left {
		display: flex;
		align-items: center;
	}

	.faturasPendentes {
		font-weight: 500;
		font-size: 0.875rem;

		margin-left: 1rem;

		color: ${colors.tertiary_red_1_dark};
	}

	.faturasNaoPendentes {
		font-weight: 500;
		font-size: 0.875rem;

		margin-left: 1rem;

		color: ${colors.secondary_green_k_medium_1};
	}
`;

import React, { memo } from "react";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../Dashboard/ContentDash";

const AlterarSenhaController: React.FC = () => {
	return (
		<ViewPort>
			<Menu></Menu>
			<ContentDash listagem={"alterarSenha"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
	color: rgba(140, 142, 134, 0, 8);
`;

export default memo(AlterarSenhaController);

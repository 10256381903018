import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import Limpar from "../../../assets/icons/limpar.svg";
import Lupa from "../../../assets/icons/lupa.svg";
import LupaFilterProtocol from "../../../assets/icons/lupaFilterProtocol.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TypesErrorRequest } from "../../../utils/types";
import BotaoAdicionar from "../BotãoAdicionar";
import { Conteiner, Header, NewEvalutor } from "./styles";
import Loading from "../Loading";
import DinoBad from "../../../assets/dinoBadAsync.svg";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core";
import { AmplitudeLog } from "../../../utils/amplitude";
import { MudarPagina } from "../../../utils/funcs";

const ListagemAval: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);

	const total_avaliadores = useSelector(
		(state: any) => state.dashboard.total_avaliadores
	);

	const numero_de_paginas = Math.ceil(total_avaliadores / 6);

	const pagina_atual = useSelector(
		(state: any) => state.dashboard.current_page_evaluator
	);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const type_toast = useSelector((state: any) => state.app.type_toast);

	const [pulse, setPulse] = useState(false);

	const [inputValue, setInputValue] = useState("");

	const avaliadores_loading = useSelector(
		(state: any) => state.dashboard.avaliadores_loading
	);

	const resetInput = () => {
		dispatch(DashBoardActions.set_search(""));
		setInputValue("");
		setPulse(false);
		Submit("reset");
	};

	const Submit = (event: any) => {
		if (event.key == "Enter" || event.type == "click" || event == "reset") {
			setPulse(false);
			const filtros_paginacao = {
				inicio: 0,
				fim: 6,
				filtros: [
					{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
					{ tipo: "USUARIO_NIVEL", valor: [1, 2] },
				],
			};
			if (inputValue != "" && event != "reset") {
				filtros_paginacao.filtros.push({
					tipo: "USUARIO_NOME",
					valor: inputValue,
				});
			}

			dispatch(
				DashBoardActions.set_filtros_avaliadores_request(filtros_paginacao)
			);
			dispatch(DashBoardActions.avaliadores_request());
		}
	};

	const handleInputChange = (event: any) => {
		setInputValue(event.target.value);
		setPulse(true);
	};

	const handleAvaliadorEditar = (avaliador: any) => {
		dispatch(AppActions.set_open_avaliador(avaliador));
		dispatch(AppActions.set_open_paciente(avaliador));
		dispatch(AppActions.set_modal_editar_avaliador(true));
	};

	const handleAvaliadorExcluir = (parameter: any) => {
		dispatch(
			AppActions.set_modal_excluir({ status: true, parameter: parameter })
		);
	};

	function _renderAvaliadores(lista: any) {
		return lista.map((row: PacientesProps) => (
			<div className="conteudo" id="conteudo" key={row.ID_USUARIO}>
				<div className="card" id={row.USUARIO_NOME}>
					<div className="left">
						{row.USUARIO_FOTO != "" &&
						row.USUARIO_FOTO != null &&
						row.USUARIO_FOTO != "img/photo.png" ? (
							<div className="conteinerImg">
								<img src={row.USUARIO_FOTO} alt="" className="imgForInput" />
							</div>
						) : (
							<div className="conteinerImg">
								<img src={Perfil} alt="" className="imgForInput" />
							</div>
						)}
					</div>
					<div
						/*to="/paciente"*/ className="mid" /*onClick={handlePaciente(row)}*/
					>
						<h1 className="nome">{row.USUARIO_NOME}</h1>
						<div className="tags">
							<span
								className={`tag ${
									row.ID_USUARIO == user_logado?.ID_USUARIO && "tagVermelha"
								}`}
							>
								{row.ID_USUARIO == user_logado?.ID_USUARIO
									? current_language?.dash_voce
									: row.USUARIO_NIVEL == 1
									? "Administrador"
									: row.USUARIO_NIVEL == 2 && "Avaliador"}
							</span>
						</div>
					</div>
					<div className="right">
						<div className="divOpcoes">
							<div className="dropdown">
								<img className="pontos" src={Pontos} alt="" />
								<div
									className={
										row.USUARIO_RESPONSAVEL != 0
											? "dropdown-content"
											: "dropdown-content2"
									}
								>
									<button 
										className="op" 
										onClick={()=> {
											handleAvaliadorEditar(row), 
											AmplitudeLog('editar_avaliador')
										}}
									>
										<img src={Editar} className="img" alt="" />
										{current_language?.editar}
									</button>
									<br />
									{row.USUARIO_RESPONSAVEL != 0 ? (
										<button
											className="op2"
											onClick={() =>{
												handleAvaliadorExcluir({ id: row, mode: "avaliador" })
												AmplitudeLog('excluir_avaliador')
											}}
										>
											<img src={Excluir} className="img" alt="" />
											{current_language?.excluir}
										</button>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		));
	}

	const useStyles = makeStyles(() => ({
		root: {
			"& .MuiPagination-ul": {
				justifyContent: "center",
			},
			"& .MuiPaginationItem-root": {
				color: "black",
				"&:hover": {
					backgroundColor: "#0023BD",
					color: "white",
				},
			},
			"& .MuiPagination-outlined": {
				backgroundColor: "#0023BD",
			},
			"& .Mui-selected": {
				backgroundColor: "#0023BD",
				color: "#0023BD",
				"&:hover": {
					color: "black",
				},
			},
		},
	}));

	const classes = useStyles();


	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("avaliador"));
	}, []);

	//UI
	return (
		<Conteiner id="DivPrincipal">
			<Header>
				<div className="boxTitle">
					<p className="title">{current_language?.menu_lateral_avaliadores}</p>
				</div>
				<div className="boxSearch">
					<div className="input">
						<button
							type="button"
							onClick={Submit}
							className={pulse ? "pulse" : "reset"}
						>
							<img className="lupa" src={LupaFilterProtocol} alt="" />
						</button>
						<input
							//	onKeyUp={(e) => handleOpenSearch(e)}
							onKeyDown={Submit}
							value={inputValue}
							onChange={handleInputChange}
							className="inputPesquisa"
							type="text"
							placeholder={current_language?.dash_pesquisar_evalu}
						/>
						{inputValue != "" && (
							<button type="reset" className="reset">
								<img className="x" src={Limpar} alt="" onClick={resetInput} />
							</button>
						)}
					</div>
				</div>
			</Header>
			{is_open_toast && type_toast == TypesErrorRequest.SUCCESS ? (
				<ToastAlert />
			) : (
				""
			)}
			{avaliadores_loading ? (
				<Loading />
			) : (
				<div className="body">
					{avaliadores.length > 0 ? (
						_renderAvaliadores(avaliadores)
					) : (
						<div className="nfunciona">
							<img src={DinoBad} alt="" />
							<div className="text">
								Nenhum avaliador encontrado, clique no botão para adicionar!
							</div>
						</div>
					)}
				</div>
			)}

			<NewEvalutor>
				<Pagination
					onChange={(event, pagina_desejada)=> 
						MudarPagina(
							pagina_desejada,
							numero_de_paginas,
							dispatch,
							[1, 2],
							inputValue
						)
					}
					page={pagina_atual}
					className={classes.root}
					size="large"
					count={numero_de_paginas}
				/>
				<BotaoAdicionar patch="addAval">
					{current_language?.adicionar_avaliador}
				</BotaoAdicionar>
			</NewEvalutor>
		</Conteiner>
	);
};

export default ListagemAval;

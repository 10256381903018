import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	@media only screen and (max-width: 1280px) {
		height: 70vh;
		width: 622px;
	}

	@media only screen and (max-width: 1440px) {
		height: 70vh;
		width: 622px;
	}

	.close {
		width: 21px;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	h1 {
		font-size: 7.5rem;
		font-weight: 500;
		color: ${colors.secondary_green_k_medium_1};
	}

	.preparar {
		display: flex;
		justify-content: center;
		align-items: center;

		margin-top: 2rem;
		p {
			font-size: 2rem;
			font-weight: 500;
			margin-left: 1rem;
			color: #8c8e86;
		}
	}

	.vai {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		p {
			font-size: 5rem;
			font-weight: 500;
			margin-top: 3rem;
			color: #8c8e86;
		}
	}
`;

import React from "react";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../Dashboard/ContentDash";

const ListagemProtocolosController: React.FC = () => {
	return (
		<ViewPort>
			<Menu setMenu="protocolos"></Menu>
			<ContentDash listagem={"listagemProtocolo"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default ListagemProtocolosController;

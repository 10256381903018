import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { AtribuirActions } from "../../../store/ducks/atribuir";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import Checkbox from "../../checkbox";
import { Container, Content } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAtribuirFisica: React.FC = () => {
	const dispatch = useDispatch();
	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);
	const selected_aval: PacientesProps = useSelector(
		(state: any) => state.pac_aval.selected_aval
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_atribuir_fisica = useSelector(
		(state: any) => state.app.is_open_atribuir_fisica
	);

	const paci: PacientesProps = selected_patients[0];
	const [identificador, setIdentificador] = useState(
		paci?.USUARIO_CONDICIONAMENTO
	);

	const ModalCadastroClose = () => {
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_atribuir_fisica(false));
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [aprovado, setAprovado] = useState(false);
	const atribuir = () => {
		setAprovado(true);
		selected_patients.map((e: PacientesProps) => {
			const request = {
				ID_USUARIO: e.ID_USUARIO,
				USUARIO_NOME: e.USUARIO_NOME,
				USUARIO_CONDICIONAMENTO: identificador,
			};
			dispatch(AtribuirActions.edit_atribuir(request));
		});
		dispatch(PacAvalActions.reset_patient());
		dispatch(DashBoardActions.paciente_request());
	};

	const ModalCadastroCloseSave = () => {
		atribuir();
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_atribuir_fisica(false));
	};

	useEffect(() => {
		setIdentificador(paci?.USUARIO_CONDICIONAMENTO);
	}, [selected_patients]);

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	const handleActivecheck = (id: number) => () => {
		setIdentificador(id);
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_atribuir_fisica}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_atribuir_fisica}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<Content>
							<div className="divText">
								<p className="text">{current_language?.tag_selecionados}:</p>
							</div>
							<div className="selecionados">
								{selected_patients.map((paciente: PacientesProps) => {
									return (
										<span className="paciente" key={paciente?.ID_USUARIO}>
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
													fill="#001574"
												/>
											</svg>
											<p>{paciente?.USUARIO_NOME}</p>
										</span>
									);
								})}
							</div>
							<div className="divText">
								<p className="text">
									{current_language?.atribuir_condicionamento}:
								</p>
							</div>
							<div className="etiquetas">
								<div className="item">
									<span className="etiqueta">
										{current_language?.menu_lateral_atleta}
									</span>
									<div className="custom-checkbox">
										<input
											checked={identificador == 1 && true}
											type="checkbox"
											id="atleta"
											style={{ display: "none" }}
											onClick={handleActivecheck(1)}
										/>
										<label className="label2" htmlFor={`atleta`}></label>
									</div>
								</div>
								<div className="item">
									<span className="etiqueta">
										{current_language?.menu_lateral_ativo}
									</span>
									<div className="custom-checkbox">
										<input
											checked={identificador == 2 && true}
											type="checkbox"
											id="ativo"
											style={{ display: "none" }}
											onClick={handleActivecheck(2)}
										/>
										<label className="label2" htmlFor={`ativo`}></label>
									</div>
								</div>
								<div className="item">
									<span className="etiqueta">
										{current_language?.menu_lateral_pos_operatorio}
									</span>
									<div className="custom-checkbox">
										<input
											checked={identificador == 3 && true}
											type="checkbox"
											id="operatorio"
											style={{ display: "none" }}
											onClick={handleActivecheck(3)}
										/>
										<label className="label2" htmlFor={`operatorio`}></label>
									</div>
								</div>
								<div className="item">
									<span className="etiqueta">
										{current_language?.menu_lateral_sedentario}
									</span>
									<div className="custom-checkbox">
										<input
											checked={identificador == 4 && true}
											type="checkbox"
											id="sedentario"
											style={{ display: "none" }}
											onClick={handleActivecheck(4)}
										/>
										<label className="label2" htmlFor={`sedentario`}></label>
									</div>
								</div>
								<div className="item">
									<span className="etiqueta">
										{current_language?.menu_lateral_idoso}
									</span>
									<div className="custom-checkbox">
										<input
											checked={identificador == 5 && true}
											type="checkbox"
											id="idoso"
											style={{ display: "none" }}
											onClick={handleActivecheck(5)}
										/>
										<label className="label2" htmlFor={`idoso`}></label>
									</div>
								</div>
							</div>
						</Content>
						<button className="button" onClick={ModalCadastroCloseSave}>
							{aprovado == true ? (
								<CircularProgress color="inherit" />
							) : (
								current_language?.btn_atribuir
							)}
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalAtribuirFisica;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 80vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 40vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 85vh;
		width: 35vw;
	}

	.close {
		width: 1.738rem;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.divText {
		width: 100%;
		margin-bottom: 3rem;
	}

	.text {
		color: #5c5b5b;
		font-weight: 500;
		font-family: "Roboto";
		font-size: 26px;
		line-height: 30.47px;
		text-align: left;
	}

	.textButton {
		color: #b3b3b3;
		font-weight: 500;
		font-family: "Roboto";
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
		cursor: pointer;
	}

	.button {
		width: 50%;

		text-align: center;

		font-family: "Roboto";
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 0.7rem 3.3rem;

		border: 4px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}
`;

export const Content = styled.div`
	width: 90%;
	height: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.notificacoes {
		height: 100%;
		width: 100%;
		font-weight: 500;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}


`;

export const ContainerNotification = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 10px 0;
	border-bottom: 0.1rem solid #c4c4c4;
	cursor: pointer;

	&:hover {
		background-color: #f0f0f0;
	}
`;

export const ContainerIconNotification = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	margin-right: 10px;
`;
export const ContainerTextNotification = styled.div`
	flex-direction: column;
	justify-content: center;
	width: 80%;
`;
export const TitleNotification = styled.h4`
	color: #001574;
`;
export const SubTitleNotification = styled.span`
	color: #8f8f8f;
	font-size: 16px;
	font-weight: normal;
	padding-top: 10px;
`;

export const ContainerNoNotification = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90%;
	height: 90%;
`;

export const ContainerTextNoNotification = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const TitleNoNotification = styled.h4`
	color: #5c5b5b;
	font-size: 1.4rem;
	font-weight: bold;
	color: #404040;
	padding-top: 1rem;
`;
export const SubTitleNoNotification = styled.span``;

export const DinoImg = styled.img`
	width: 20%;
	min-width: 6rem;
	margin-right: 15px;
`;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../../store/ducks/app";
import { Conteiner } from "./styles";
import { Link } from "react-router-dom";
import CreditCard from '../../../assets/icons/Credit_card.svg'
import history from "../../../routes/history";
interface iBotao {
	patch: string;
}

const BotaoAdicionarCartao: React.FC<iBotao> = (props: any) => {
	const dispatch = useDispatch();

	const handleAdicionarMetodoPagamento = () => {
		dispatch(AppActions.set_open_adicionar_metodo_pagamento(true));
		history.push('metodo_pagamento')
	};

	return (
		<Conteiner>
			<div>
				<div
					className="btnAdicionar"
					onClick={()=>{handleAdicionarMetodoPagamento()}}
				>
					<img src={CreditCard} alt="" />
					<p className="textoBotao">{props.children}</p>
				</div>
			</div>
		</Conteiner>
	);
};

export default BotaoAdicionarCartao;
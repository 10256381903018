import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;

	@media only screen and (min-width: 1280px) {
		width: calc(100vw - 7rem);
	}

	@media only screen and (max-width: 1280px) {
		width: 100%;
	}

	@media only screen and (min-width: 1440px) {
		width: calc(100vw - 7rem);
	}

	@media only screen and (min-width: 1680px) {
		width: calc(100vw - 15.93rem);
	}
`;

export const ListagemEcards = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 90vh;
	padding-left: 4rem;
	padding-right: 4rem;
	padding-top: 4rem;
	padding-bottom: 4rem;
	background-color: #f5fbfc;

	@media only screen and (min-width: 1280px) {
		padding: 2rem;
	}

	@media only screen and (max-width: 1300px) {
		padding: 2rem;
	}

	@media only screen and (min-width: 1440px) {
		padding: 3rem;
	}

	@media only screen and (max-width: 1280px) {
		padding: 1rem;
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 1.25rem;
`;

export const Group = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`;

export const Cards = styled.div`
	display: flex;
	flex-direction: column;
	background-color: red;
	width: 100%;
	height: 15%;
	margin-top: 2rem;
`;

import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-top: 1rem;
	padding-bottom: 5vh;
	padding-left: 4rem;
	padding-right: 4rem;
	border-radius: 1.25rem;

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5rem;
		margin-top: 2rem;

		.protocol_name {
			font-family: Roboto;
			font-size: 24px;
			font-weight: 500;
			line-height: 28px;
			letter-spacing: 0em;
			text-align: center;
			color: #5c5b5b;
		}

		.editar_protocolo {
			margin-right: 3rem;
		}

		img {
			cursor: pointer;
		}
	}

	.listagem {
		.exercise_name {
			font-family: Roboto;
			font-size: 20px;
			font-weight: 500;
			line-height: 19px;
			letter-spacing: 0em;
			text-align: left;
			margin-bottom: 1.2rem;
			margin-top: 1.2rem;
			color: #5c5b5b;
		}
	}
`;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Text } from "./styles";

import AssimetriaIcon from "../../assets/icons/reportAss.svg";
import EvolucaoIcon from "../../assets/icons/reportEv.svg";
import ForcaTempoIcon from "../../assets/icons/reportFt.svg";
import fisioterapeutico from "../../assets/icons/fisioterapeutico.svg";
import multifatorial from "../../assets/icons/multifatorial.svg";

import { TypesBtnRelatorio } from "../../utils/types";
import { RelatorioActions } from "../../store/ducks/relatorios";
import { AmplitudeLog } from "../../utils/amplitude";

interface BtnInfoProps {
	Icon: string;
	Texto: string;
	set_age?: any;
	style?: any;
}

const BtnRelatorio: React.FC<BtnInfoProps> = ({
	Icon,
	Texto,
	set_age,
	style,
}) => {
	const dispatch = useDispatch();

	const age: number = useSelector((state: any) => state.relatorios.age);

	useEffect(()=>{
		if(age === 1 && style.borderBottom === '3px solid #00A377'){
			AmplitudeLog('relat_evolucao')
		}
		if(age === 2 && style.borderBottom === '3px solid #00A377' ){
			AmplitudeLog('relat_forca_tempo')
		}
		if(age === 3 && style.borderBottom === '3px solid #00A377'){
			AmplitudeLog('relat_assimetria')
		}
		if(age === 5 && style.borderBottom === '3px solid #00A377'){
			AmplitudeLog('relat_multifatorial')
		}
		if(age === 6 && style.borderBottom === '3px solid #00A377'){
			AmplitudeLog('relat_fisioterapeutico')
		}
	},[age])
	
	const setAge = (age: number) => {
		
		dispatch(RelatorioActions.set_age(age));
	};

	if (Texto.length > 40) {
		Texto = `${Texto.substring(0, 40)}...`;
	}

	const imagem = (icon: string) => {
		if (icon === TypesBtnRelatorio.BTN_ASSIMETRIA) {
			return AssimetriaIcon;
		}
		if (icon === TypesBtnRelatorio.BTN_EVOLUCAO) {
			return EvolucaoIcon;
		}
		if (icon === TypesBtnRelatorio.BTN_FORCA_TEMPO) {
			return ForcaTempoIcon;
		}
		if (icon === TypesBtnRelatorio.BTN_FISIOTERAPEUTICO) {
			return fisioterapeutico;
		}
		if (icon === TypesBtnRelatorio.BTN_MULTIFATORIAL) {
			return multifatorial;
		}
	};

	return (
		<Container onClick={() => setAge(set_age)} style={style}>
			<img src={imagem(Icon)}></img>
			<Text>{Texto}</Text>
		</Container>
	);
};

export default BtnRelatorio;

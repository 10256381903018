import { all } from "redux-saga/effects";

import { AddPaciAvalSaga } from "./add_pac_aval";
import { AnotationSaga } from "./anotacoes";
import { AtribuirSaga } from "./atribuir";
import { AuthSaga } from "./auth";
import { AvaliationSaga } from "./avaliation";
import { CentralSaga } from "./central";
import { ConfigSaga } from "./config";
import { DashBoardSaga } from "./dashboard";
import { ExercicioSaga } from "./exer";
import { FianncialSaga } from "./financial";
import { QuestionarioSaga } from "./manage_pac_aval";
import { PerfilSaga } from "./perfil";
import { ProtocoloSaga } from "./protocolos";
import { RegisterSaga } from "./register";
import { PersonalizarRelatorioSaga } from "./relatorios";
import { SenhaSaga } from "./senha";
import { TagsSaga } from "./tags";
import { AssinaturaSaga } from "./assinatura";

export default function* rootSaga() {
	yield all([
		AuthSaga(),
		DashBoardSaga(),
		RegisterSaga(),
		FianncialSaga(),
		ConfigSaga(),
		AssinaturaSaga(),
		QuestionarioSaga(),
		AddPaciAvalSaga(),
		ProtocoloSaga(),
		AvaliationSaga(),
		ExercicioSaga(),
		TagsSaga(),
		PersonalizarRelatorioSaga(),
		AnotationSaga(),
		PerfilSaga(),
		SenhaSaga(),
		AtribuirSaga(),
		CentralSaga(),
	]);
}

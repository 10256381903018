import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VictoryArea, VictoryChart, VictoryLine, VictoryLabel } from "victory";

import { TextsProps } from "../../database/txt.interface";
import { AvaliationActions } from "../../store/ducks/avaliation";
import { Chart2 } from "./styles";
import { init } from "rollbar";
import { colors } from "../../styles/colors";

interface PropsValuesExecucao {
	valor: number;
	time:number;
	po: number;
	pico: number;
	init: number;
	ate: number;
};

const Grafic: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const arrayData = useSelector((state: any) => state.avaliation.values);

	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);

	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);

	const force_max: number = useSelector((state: any) => state.avaliation.force_max);

	const resultado_execucao: boolean = useSelector(
		(state: any) => state.app.resultado_execucao
	);

	const in_execution_force_max: boolean = useSelector(
		(state: any) => state.avaliation.in_execution_force_max
	);

	const [arrMaior, setarrMaior] = useState<any>([]);
	const [valuePicos, setValuePicos] = useState<any>([]);
	const picos = useSelector((state: any) => state.avaliation.picos);
	useEffect(() => {
		if (resultado_execucao) {
			dispatch(AvaliationActions.set_valor(0, 0));

			const arrayMaior: Partial<PropsValuesExecucao>[] = [];


			arrayData.map((item:{valor: number, time: number}) => {
				if (item?.valor < force_option_min) {

					const ultimo = arrayMaior.slice(-1)[0];
					
					if (ultimo !== undefined) {
						arrayMaior.push({ time: ultimo.time, valor: 0 });
					}

					arrayMaior.push({ time: item?.time, valor: 0 });

				} else {
					const ultimo = arrayMaior.slice(-1)[0];
					if (ultimo === undefined) {
						arrayMaior.push({ time: item?.time, valor: item?.valor });
					} else if (ultimo.valor === 0) {
						arrayMaior.push({ time: item?.time, valor: 0 });
					}
					arrayMaior.push(item);
				}
			});
			
			setarrMaior(arrayMaior);
		}
	}, [resultado_execucao]);
	useEffect(() => {
		if (resultado_execucao) {
			if (picos.length > 0) {

				const indices: number[] = [];

				let isFirstGreater = true;
				
				for (let i = 0; i < arrMaior.length; i++) {
					if (arrMaior[i].valor > force_option_min && isFirstGreater) {
						indices.push(i);
						isFirstGreater = false;
					}
					
					if (arrMaior[i].valor <= force_option_min && !isFirstGreater) {
						isFirstGreater = true;
					}
				}

				const minimumForceIntervalExceeded: Omit<PropsValuesExecucao,"valor"|"time"|"po">[] = [];
			
				const arrMaiorDividedBetweenIntervals: Omit<PropsValuesExecucao,"pico"|"init"|"ate"|"po" >[] = [];
				let startPosition = 0
				let idx = 1

				indices.forEach((element: number, index: number, array: number[]) => {
					
				    const positions = {
						init: startPosition,
						ate: element,
						pico: index + 1,
					}

					minimumForceIntervalExceeded.push(positions)
					arrMaiorDividedBetweenIntervals.push(arrMaior.slice(element, array[idx]))
					startPosition = element + 1
					idx = idx + 1
					
				});
				

				const peakForceExerted: number[] = [];

				arrMaiorDividedBetweenIntervals.forEach((arrayElements: any) => {

					const valuePowerElements: number[] = [];

					arrayElements.forEach((element: PropsValuesExecucao)=> {
						valuePowerElements.push(element.valor)
					})
					
					peakForceExerted.push(Math.max(...valuePowerElements))
				})
				
				const heigherPeaksMinimumForce = [...peakForceExerted].filter((e: number) => e >= force_option_min);
				
			
				const getPico = (index: number) => {
					let valuePico: any
					minimumForceIntervalExceeded.forEach((e: Omit<PropsValuesExecucao,"valor"|"time"|"po">) => {
						
						if (index >= e.init && index <= e.ate) {
							valuePico = e.pico;
							return;
						}
					});
					return valuePico;
				};

				const dados: PropsValuesExecucao[] = [];
				
			
				arrMaior.forEach((element: PropsValuesExecucao, index: number) => {
					if (heigherPeaksMinimumForce.includes(element.valor)) {
						dados.push({
							...element,
							po: index,
							pico: getPico(index),
							valor: element.valor,
						});
					}
				});

				const arr_final: PropsValuesExecucao[] = [];
			
				dados.forEach((e: PropsValuesExecucao, index: number) => {
					if (index == 0) {
						arr_final.push(e);
					} else {
						const exist = arr_final.find((v: PropsValuesExecucao) => v.pico == e.pico);
						if (exist) {
							if (e.valor > exist.valor) {
								arr_final.pop();
								arr_final.push(e);
							}
						} else {
							arr_final.push(e);
						}
					}
				});
				dispatch(AvaliationActions.set_pico_result(arr_final));

				setValuePicos(arr_final);
			}
		}
	}, [picos, resultado_execucao, arrMaior]);

	const time_final = useSelector((state: any) => state.avaliation.time_final);

	return (
		<Chart2>
			<div className="legendaEsquerda">
				<small>{current_language?.forca}(kg)</small>
			</div>

			<VictoryChart height={400} width={1000}>
				<VictoryArea
					interpolation="basis"
					labels={({ datum }) => `${datum.valor} kg`}
					domain={{
						x: [
							arrayData.length > 0 ? arrayData[0]?.time : 0,
							time_final != 0 ? time_final : 10,
						],
						y: [0, arrayData.length <= 0 ? 10 : force_max + force_max / 1.5],
					}}
					style={{
						data: {
							stroke: colors.primary_blue_k_light_1+25,
							fill: colors.primary_blue_k_light_1+25,
							strokeLinecap: "round",
						},
						labels: {
							fill: "transparent",
						},
					}}
					x="time"
					y="valor"
					// data={[...values]}
					data={[...arrayData]}
				/>

				<VictoryArea
					interpolation="basis"
					labels={({ datum }) => `${datum.valor} kg`}
					domain={{
						x: [
							arrayData.length > 0 ? arrayData[0]?.time : 0,
							time_final != 0 ? time_final : 10,
						],
						y: [0, arrayData.length <= 0 ? 10 : force_max + force_max / 1.5],
					}}
					style={{
						data: {
							stroke: colors.primary_blue_k_light_1+80,
							fill: colors.primary_blue_k_light_1+80,
							strokeLinecap: "round",
						},
						labels: {
							fill: ({ datum, index }: any) => {
								if (
									valuePicos.find(
										(e: any) => e.po == index
									)
								) {
									return colors.primary_blue_k_main;
								} else {
									return "transparent";
								}
							},
							fontSize: 22,
							fontWeight: 700,
						},
					}}
					x="time"
					y="valor"
					data={[...arrMaior]}
				/>

				{in_execution_force_max && (
					<VictoryLine
						style={{
							data: { stroke: colors.primary_blue_k_main, strokeWidth: 3 },
							labels: {
								fontSize: 25,
								fontWeight: 700,
								fontFamily: "roboto",
								fill: ({ datum }: any) =>
									datum.x === 0 ? "#5C5B5B" : "transparent",
							},
						}}
						data={[
							{ x: 0, y: parseFloat(force_option_max) },
							{
								x: time_final != 0 ? time_final : 10,
								y: parseFloat(force_option_max),
							},
						]}
						labelComponent={
							<VictoryLabel
								renderInPortal
								dx={-130}
								dy={force_option_max - force_option_min < 15 ? -35 : -10}
							/>
						}
						labels={() => [`${parseFloat(force_option_max).toFixed(2)} kg`]}
					/>
				)}
				{in_execution_force_max && (
					<VictoryLine
						style={{
							data: { stroke: colors.primary_blue_k_main, strokeWidth: 0 },
							labels: {
								fontSize: 18,
								fontWeight: 500,
								fontFamily: "roboto",
								fill: ({ datum }: any) =>
									datum.x === 0 ? "#8C8E86" : "transparent",
							},
						}}
						data={[
							{ x: 0, y: parseFloat(force_option_max) },
							{
								x: time_final != 0 ? time_final : 10,
								y: parseFloat(force_option_max),
							},
						]}
						labelComponent={
							<VictoryLabel
								renderInPortal
								dx={-146}
								dy={force_option_max - force_option_min < 15 ? -5 : 25}
							/>
						}
						labels={() => [`${current_language?.execucao_force_max}`]}
					/>
				)}

				<VictoryLine
					style={{
						data: { stroke: colors.primary_blue_k_main, strokeWidth: 0 },
						labels: {
							fontSize: 25,
							fontWeight: 700,
							fontFamily: "roboto",
							fill: ({ datum }: any) =>
								datum.x === 0 ? "#5C5B5B" : "transparent",
						},
					}}
					data={[
						{ x: 0, y: parseFloat(force_option_min) },
						{
							x: time_final != 0 ? time_final : 10,
							y: parseFloat(force_option_min),
						},
					]}
					labelComponent={
						<VictoryLabel
							renderInPortal
							dx={-130}
							dy={force_option_max - force_option_min < 15 ? 20 : -10}
						/>
					}
					labels={() => [`${parseFloat(force_option_min).toFixed(2)} kg`]}
				/>
				<VictoryLine
					style={{
						data: { stroke: colors.primary_blue_k_main, strokeWidth: 3 },
						labels: {
							fontSize: 18,
							fontWeight: 500,
							fontFamily: "roboto",
							fill: ({ datum }: any) =>
								datum.x === 0 ? "#8C8E86" : "transparent",
						},
					}}
					data={[
						{ x: 0, y: parseFloat(force_option_min) },
						{
							x: time_final != 0 ? time_final : 10,
							y: parseFloat(force_option_min),
						},
					]}
					labelComponent={
						<VictoryLabel
							renderInPortal
							dx={-145}
							dy={force_option_max - force_option_min < 15 ? 50 : 25}
						/>
					}
					labels={() => [`${current_language?.execucao_force_min}`]}
				/>
			</VictoryChart>
			<div className="legendaInferior">
				<small>{current_language?.tempo} (s)</small>
			</div>
		</Chart2>
	);
};

export default memo(Grafic)

import React, { useCallback, useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import "react-circular-progressbar/dist/styles.css";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { TypesErrorModal } from "../../../utils/types";
import { beep_curto } from "../beep";
import { beep_long_contracao } from "../beep_long_contracao";
import { ConexaoBluetooth } from "../ConexaoBluetooth";
import Configuracoes from "../Configuracoes";
import Header from "../Header";
import Progress from "../progress";
import ResultadoExecucao from "../ResultadoExecucao";
import { Body, Conteiner, Content, Scroll } from "../styles";
import TimerRelogio from "../timer";

import moment from "moment";
import { browserName } from "react-device-detect";
import { AmplitudeLog } from "../../../utils/amplitude";
import { colors } from "../../../styles/colors";

export const Preenchimento = styled.div`
	height: 100%;

	transition: all 0.1s ease-out;

	background-color: #000E4B;
`;

export const Barra = styled.div`
	height: 5rem;
	width: 55rem;
	background-color: #e5e5e5;

	position: relative;

	overflow: hidden;

	border-radius: 1rem;

	.lineMin {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid #000;
	}

	.lineMax {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid #000;
	}

	@media (max-width: 1300px) {
		height: 4rem;
		width: 50rem;
	}

	@media (max-width: 1130px) {
		height: 3.8rem;
		width: 45rem;
	}
`;

const Avaliacao: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const language = useSelector((state: any) => state.configs.language);
	

	const connect_flag = useSelector(
		(state: any) => state.avaliation.connect_flag
	);
	const result_bluetooth = useSelector(
		(state: any) => state.configs.result_bluetooth
	);
	const alreadyConnected = useSelector(
		(state: any) => state.avaliation.already_connected
	);
	const modo_demo: any = useSelector((state: any) => state.configs.modo_demo);

	useEffect(()=>{
		dispatch(AppActions.set_open_barraSuperior("execucaoExercicio"));
	},[])
	
	if (connect_flag) {
		dispatch(AppActions.set_modal_conecta(false));
		if (alreadyConnected === false) {
			dispatch(AvaliationActions.set_already_connected(true));
			dispatch(
				AppActions.set_modal_visible({
					type: TypesErrorModal.SUCCESS,
					message: "conectado",
				})
			);
		}
	} else {
		if (!modo_demo) {
			if (
				window.navigator.userAgent.indexOf("Windows NT 10.0") != -1 ||
				window.navigator.userAgent.indexOf("Windows NT 6.2") != -1 ||
				window.navigator.userAgent.indexOf("Windows NT 6.1") != -1 ||
				window.navigator.userAgent.indexOf("Windows NT 6.0") != -1 ||
				window.navigator.userAgent.indexOf("Windows NT 5.1") != -1 ||
				window.navigator.userAgent.indexOf("Windows NT 5.0") != -1 ||
				window.navigator.userAgent.indexOf("Mac") != -1
			) {
				if (browserName == "Chrome") {
					ConexaoBluetooth();
					if (result_bluetooth) {
						dispatch(AppActions.set_modal_conecta(true));
					} else {
						dispatch(AppActions.set_modal_conecta3(true));
					}
				} else {
					dispatch(AppActions.set_modal_conecta2(true));
				}
			} else {
				dispatch(AppActions.set_modal_conecta4(true));
			}
		}
	}

	const [timeInitiTimeAval, setTimeInitAvaliation] = useState<any>();

	const resultado_execucao = useSelector(
		(state: any) => state.app.resultado_execucao
	);

	const is_open_configuracoes = useSelector(
		(state: any) => state.app.is_open_configuracoes_exercicios
	);

	const current_repeticoes = useSelector(
		(state: any) => state.avaliation.current_repeticoes
	);
	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const start = useSelector((state: any) => state.avaliation.start);

	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);
	const valor_ble = useSelector((state: any) => state.avaliation.valor_ble);
	const valor_obtido = useSelector((state: any) => state.avaliation.valor);

	const is_play = useSelector((state: any) => state.app.is_play_exercicios);

	const exercicio = useSelector((state: any) => state.app.exercicio);

	const is_valid: any = useSelector((state: any) => state.avaliation.is_valid);

	const calc_percent_values = useCallback((valKG: any, time: any, repetitions?: any) => {
		if (parseFloat(valKG) > parseFloat(force_option_min) && is_valid === null) {
			dispatch(AvaliationActions.set_is_valid(true));
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
		}
		if (
			parseFloat(valKG) < parseFloat(force_option_min) &&
			is_valid === false
		) {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
			dispatch(AvaliationActions.set_is_valid(null));
		} else {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
		}
	},[force_option_min, is_valid, valor_ble.peso])

	function calc_percent_values_demo(valKG: any, time: any, repetitions?: any ){
		if (valKG > force_option_min) {
			dispatch(AvaliationActions.set_is_valid(true));
		}
		if (valKG < force_option_min) {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
			dispatch(AvaliationActions.set_is_valid(false));
		} else {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
		}
	}

	useEffect(() => {
		if (modo_demo) {
			if (is_valid && is_valid != null) {
				beep_curto();
				dispatch(AvaliationActions.set_current_repe(current_repeticoes + 1));
			}
		} else {
			if (is_valid) {
				beep_curto();
				dispatch(AvaliationActions.set_current_repe(current_repeticoes + 1));
				dispatch(AvaliationActions.set_is_valid(false));
			}
		}

		if (!is_valid && is_valid != null && start && start != null) {
			const fim = moment(new Date()).valueOf();
			const time_screen: any = (fim - timeInitiTimeAval) / 1000;
			
			if (!!time_screen) {
				dispatch(AvaliationActions.set_picos(time_screen.toFixed(1)));
			}
		}
	}, [is_valid, start]);

	useEffect(() => {
		if(connect_flag) {
			dispatch(AppActions.set_modal_conecta(false));
			dispatch(AppActions.set_modal_conecta3(false));
			dispatch(AppActions.set_modal_conecta2(false));
			dispatch(AppActions.set_modal_conecta4(false));
		}
	}, [connect_flag])

	useEffect(() => {
		if (start && start != null) {
			dispatch(AppActions.set_play_exercicios(true));
			setTimeInitAvaliation(moment(new Date()).valueOf());
		}
	}, [start]);

	useEffect(() => {
		let demoInterval: any = null;
		let demoCounter = 0;
		if (start && start != null) {
			let time = 0;
			demoInterval = setInterval(function () {
				if (modo_demo) {
					time += 0.1;
					calc_percent_values_demo(
						20 * Math.sin((5 * demoCounter * Math.PI) / 180) + 20,
						time.toFixed(1)
					);
					demoCounter++;
				}
			}, 100);

			if (!modo_demo) {
				const fim = moment(new Date()).valueOf();
				const time_screen: any = (fim - timeInitiTimeAval) / 1000;
				if (!!time_screen) {
					calc_percent_values(valor_ble.peso, time_screen.toFixed(1));
				}
			}
		}
		if (!start && start != null) {
			clearInterval(demoInterval);
			demoCounter = 0;
		}
		return () => {
			clearInterval(demoInterval);
		};
	}, [start, valor_ble.ping]);

	const contagem_regressiva = useSelector(
		(state: any) => state.configs.contagem_regressiva
	);
	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);

	const pico_result = useSelector((state: any) => state.avaliation.pico_result);

	const handleIsNotPlay = () => {
		dispatch(AvaliationActions.set_start(false));
		dispatch(AppActions.set_play_exercicios(false));
		dispatch(AppActions.set_resultado_execucao(true));
	};

	const handleOpenContagemExercicio = () => {
		if (!result_bluetooth && !modo_demo) {
			!modo_demo && dispatch(AppActions.set_modal_conecta3(true));
		} else {
			contagem_regressiva
				? dispatch(AppActions.set_modal_contagem_exercicio(true))
				: dispatch(AvaliationActions.set_start(true));

			contagem_regressiva === false &&
				dispatch(AppActions.set_timer_reset(true));
		}
	};

	const valorContador = tempo_contracao;
	const tempoMaximo = tempo_contracao;
	const [contador, setContador] = React.useState<any>(valorContador);
	const [beepContagem, setBeepContagem]: any = useState(tempoMaximo);

	const timer_reset = useSelector((state: any) => state.app.timer_reset);

	useEffect(() => {
		if (is_play) {
			if (timer_reset) {
				setTimeout(() => {
					contador !== 0 && setContador(contador - 1);
					setBeepContagem(contador);
					if (contador === 0) {
						setContador(0);
						beepContagem !== contador && beep_long_contracao();
					}
				}, 1000);
			}
		} else if (is_play === false) {
			setContador(valorContador);
			return;
		}
		if (timer_reset === false) {
			setContador(valorContador);
			return;
		}
	}, [is_play, contador, timer_reset]);

	const timer = contador;
	const valor = tempoMaximo - timer;

	const [picoResult, setPicoResult] = React.useState<any>(0);

	let resultMediaPico = 0;
	pico_result.map((i: any) => {
		if (i.valor > picoResult) {
			setPicoResult(i.valor);
		}
		resultMediaPico += i.valor;
	});
	resultMediaPico = resultMediaPico / pico_result.length;

	return (
		<Conteiner>
			<Scroll>
				<Content>
					{resultado_execucao ? (
						<ResultadoExecucao />
					) : (
						<>
							{is_open_configuracoes ? (
								<Configuracoes />
							) : (
								<>
									<Header />
									<Body>
										<h2 className="titleH2">
											{language === "PT"
												? exercicio.EXERCICIO_TITULO_PT
												: language === "EN"
												? exercicio.EXERCICIO_TITULO_EN
												: exercicio.EXERCICIO_TITULO_ES}
										</h2>
										<div className="marginTopoBarra">
											<Progress
												step={valor_obtido}
												max={force_option_max * 1.2}
											/>
										</div>
										<div className="timers">
											<div className="contador">
												<div className="circularProgress">
													<CircularProgressbar
														value={valor}
														minValue={0}
														maxValue={tempoMaximo}
														strokeWidth={10}
														styles={buildStyles({
															strokeLinecap: "butt",
															pathColor: colors.neutral_grey_k_10,
															textSize: "40px",
															textColor:  colors.neutral_grey_k_10,
															trailColor: colors.primary_blue_k_light_1,
														})}
													></CircularProgressbar>
													<div className="timer">{timer}</div>
												</div>
												<p>{current_language?.contracao_tempo}</p>
											</div>
											<div className="repeticoes">
												<p>{current_repeticoes}</p>
												<span>{current_language?.config_aval_repeticoes}</span>
											</div>
											<div className="cronometro">
												<svg
													width="36"
													height="36"
													viewBox="0 0 36 36"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M17.985 3C9.705 3 3 9.72 3 18C3 26.28 9.705 33 17.985 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 17.985 3ZM18 30C11.37 30 6 24.63 6 18C6 11.37 11.37 6 18 6C24.63 6 30 11.37 30 18C30 24.63 24.63 30 18 30Z"
														fill="#4585FF"
													/>
													<path
														d="M18.75 10.5H16.5V19.5L24.375 24.225L25.5 22.38L18.75 18.375V10.5Z"
														fill="#4585FF"
													/>
												</svg>
												<TimerRelogio />
											</div>
										</div>
										{is_play ? (
											<button
												className={` pause ${"marginTopoBarra"}`}
												onClick={()=> {
													handleIsNotPlay()
													AmplitudeLog('parar_exercicio')
												}}
											>
												<svg
													width="14"
													height="16"
													viewBox="0 0 14 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<rect width="5" height="16" fill="#000E4B" />
													<rect x="9" width="5" height="16" fill="#000E4B" />
												</svg>
												{current_language?.parar}
											</button>
										) : (
											<button
												className={` ${ "marginTopoBarra"}`}
												onClick={()=> {handleOpenContagemExercicio(), AmplitudeLog('iniciar_exercicio')}}
											>
												<svg
													width="17"
													height="16"
													viewBox="0 0 17 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M15.555 6.90775L2.34542 0.302956C2.16722 0.220392 1.9712 0.183711 1.77519 0.196251C1.57919 0.208791 1.38944 0.270154 1.22321 0.374754C1.05698 0.479355 0.919553 0.62387 0.823436 0.795146C0.72732 0.966421 0.675568 1.15902 0.672892 1.3554V14.5593C0.671227 14.7609 0.721594 14.9595 0.819124 15.1359C0.916653 15.3124 1.05805 15.4607 1.22964 15.5665C1.40123 15.6723 1.59722 15.7321 1.79866 15.74C2.0001 15.7479 2.20018 15.7037 2.37956 15.6118L15.555 9.00696C15.7502 8.90898 15.9143 8.75867 16.0289 8.57282C16.1436 8.38697 16.2043 8.17289 16.2043 7.95451C16.2043 7.73613 16.1436 7.52206 16.0289 7.3362C15.9143 7.15035 15.7502 7.00004 15.555 6.90207V6.90775Z"
														fill="white"
													/>
												</svg>
												{current_language?.iniciar}
											</button>
										)}
									</Body>
								</>
							)}
						</>
					)}
				</Content>
			</Scroll>
		</Conteiner>
	);
};

export default Avaliacao;

import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps, UserProps } from "../../../interfaces/pacientes.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from '../../../store/ducks/financial';
import { Conteiner, Header, BodyEdite, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ServiceTypePatient } from "../../../interfaces/planos.interface";

const MeuPlano: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const planos_upsell = useSelector(
		(state: any) => state.financial.planos_upsell
	);
	const config_servicos: ServicosProps = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const avaliadores: PacientesProps[] = useSelector(
		(state: any) => state.dashboard.avaliadores
	);
	const pacientes: PacientesProps[] = useSelector(
		(state: any) => state.dashboard.pacientes
	);
	

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("meuPlano"));
		dispatch(FinancialActions.set_select_item(""));
	}, []);

	const filterPlanos = planos_upsell?.planos?.filter((item: any)=> item?.PLANO_PESO > config_servicos?.SERVICO_PLANO_PESO)
	const date = new Date().toLocaleDateString('pt-BR');
	let plano = "";
	if (
		!!config_servicos?.SERVICO_PLANO_TRIAL_FIM
		&& new Date(config_servicos?.SERVICO_PLANO_TRIAL_FIM).toLocaleDateString('pt-BR') >= date
	) {
		plano = current_language?.idioma === "Português" 
			&& `${current_language?.menu_lateral_plano} Trial ${config_servicos?.SERVICO_PLANO_NOME_TRIAL} ${new Date(config_servicos?.SERVICO_PLANO_TRIAL_FIM).toLocaleDateString('pt-BR')}`
			|| `${config_servicos?.SERVICO_PLANO_NOME} ${current_language?.menu_lateral_plano}`;
	} else if (new Date(config_servicos?.SERVICO_PLANO_INICIO).toLocaleDateString('pt-BR') <= date) {
		plano = current_language?.idioma === "Português" 
			&& `${current_language?.menu_lateral_plano} ${config_servicos?.SERVICO_PLANO_NOME}`
			|| `${config_servicos?.SERVICO_PLANO_NOME} ${current_language?.menu_lateral_plano}`;
	}

	const selectContrato = () => {
		AmplitudeLog('upgrade_plano')
		window.open(`https://wa.me/556133479291?text=Ol%C3%A1%2C%20gostaria%20de%20mudar%20o%20meu%20plano%20`, '_blank');
	}

	//UI
	return (
		<Conteiner>
			<Content>
				<Header>
					<p className="nome">{plano}</p>
				</Header>
				<BodyEdite>
					<div className="lineItens">
						<div className="item">
							<p>{current_language?.perfil_paciente_treino}</p>

							{config_servicos.SERVICO_FREE_TRAINING ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.menu_lateral_personalizar_relatorio}</p>

							{config_servicos.SERVICO_CSV ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.perfil_logomarca_relatorio}</p>

							{config_servicos.SERVICO_LOGOMARCA ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.menu_lateral_percentual_rm}</p>

							{config_servicos.SERVICO_PERCENTUALRM ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.menu_lateral_modo_web}</p>

							{config_servicos.SERVICO_WEB ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.perfil_avaliacao_iq}</p>

							{config_servicos.SERVICO_AVALIACAO_IQ ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.pag_inicial_avaliador}</p>

							<h5
								className={`textHeader number ${avaliadores.length == config_servicos.SERVICO_AVALIADORES
									? "maximo"
									: avaliadores.length <
										config_servicos.SERVICO_AVALIADORES / 2
										? "minimo"
										: "medio"
									} `}
							>{`${avaliadores.length}/${config_servicos.SERVICO_AVALIADORES >= 999999999 ? current_language?.ilimitado : config_servicos.SERVICO_AVALIADORES}`}</h5>
						</div>

						<div className="item">
							<p>{current_language?.pag_inicial_pacientes}</p>

							<h5
								className={`textHeader number ${pacientes.length == config_servicos?.SERVICO_PACIENTES
									? "maximo"
									: pacientes.length < config_servicos?.SERVICO_PACIENTES / 2
										? "minimo"
										: "medio"
									}`}
							>
								{
								`${pacientes.length}/${config_servicos?.SERVICO_PACIENTES >= 999999999 || config_servicos?.SERVICO_PACIENTES_TIPO == ServiceTypePatient?.MENSAL 
									? current_language?.ilimitado 
									: config_servicos?.SERVICO_PACIENTES}`
								}
							</h5>
						</div>
						{ config_servicos?.SERVICO_PACIENTES_TIPO == ServiceTypePatient?.MENSAL &&
							<div className="item">
								<p>{current_language?.cadastros_mensais}</p>

								<h5
									className={`textHeader number ${pacientes.length == config_servicos?.SERVICO_PACIENTES
										? "maximo"
										: pacientes.length < config_servicos?.SERVICO_PACIENTES / 2
											? "minimo"
											: "medio"
										}`}
								>
									{`${config_servicos?.SERVICO_PACIENTES_CADASTRADOS}/${config_servicos?.SERVICO_PACIENTES}`}
								</h5>
							</div>
						}

						<div className="item">
							<p>{current_language?.meu_plano_capacidade}</p>

							<h5
								className={`textHeader number capacidade`}
							>{`${config_servicos.SERVICO_FORCA} kg`}</h5>
						</div>

						<div className="item">
							<p>{current_language?.menu_lateral_relatorio_assimetria}</p>

							{config_servicos.SERVICO_REL_ASSIMETRIA ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.menu_lateral_relatorio_avaliacao}</p>

							{config_servicos.SERVICO_REL_AVALIACAO ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.menu_lateral_relatorio_forca_tempo}</p>

							{config_servicos.SERVICO_REL_FORCA ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>

						<div className="item">
							<p>{current_language?.anamnese}</p>

							{config_servicos.SERVICO_ANAMNESE ? (
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z"
										fill="#00A377"
									/>
								</svg>
							) : (
								<svg
									width="13"
									height="13"
									viewBox="0 0 13 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3751 0L6.49943 4.875L1.62486 0L0 1.625L4.87457 6.5L0 11.375L1.62486 13L6.49943 8.125L11.3751 13L13 11.375L8.12543 6.5L13 1.625L11.3751 0Z"
										fill="#D12600"
									/>
								</svg>
							)}
						</div>
						{!!planos_upsell?.planos?.length && user_logado?.USUARIO_NIVEL == 1 && (
							<div className="footer">
								{filterPlanos.length > 0 && (
									<Link to="/upgrade">
										<button 
											className="botao" 
											onClick={()=> AmplitudeLog('upgrade_plano')}>
											UPGRADE
										</button>
									</Link>
								)}
								{filterPlanos.length === 0 && (
									<button 
										className="botao" 
										onClick={()=> selectContrato()}>
										UPGRADE
									</button>
								)}
							</div>
						)}
					</div>
				</BodyEdite>
			</Content>
		</Conteiner>
	);
};

export default memo(MeuPlano);

import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Filtro from "../../../../assets/icons/filtro.svg";
import Limpar from "../../../../assets/icons/limpar.svg";
import Lupa from "../../../../assets/icons/lupa.svg";
import Seta from "../../../../assets/icons/setaDireitaBlack.svg";
import Status from "../../../../assets/icons/status.svg";
import { TextsProps } from "../../../../database/txt.interface";
import { ProtocolosProps } from "../../../../interfaces/protocolo.interface";
import history from "../../../../routes/history";
import { AppActions } from "../../../../store/ducks/app";
import { AvaliationActions } from "../../../../store/ducks/avaliation";
import { DashBoardActions } from "../../../../store/ducks/dashboard";
import { dataHelpers } from "../../../../utils/dataHelpers";
import { MudarPagina, getPermission } from "../../../../utils/funcs";
import { TypesAvalOrFunci, TypesLanguage, TypesPermission } from "../../../../utils/types";
import { Conteiner } from "./styles";
import { ServicosProps } from "../../../../interfaces/config.interface";
import { RelatorioActions } from "../../../../store/ducks/relatorios";

const BarraSuperiorPatch: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const goDashboard: boolean = useSelector(
		(state: any )=> state.configs.close_modal_and_goback
	);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const removeButtonClose: boolean = useSelector(
		(state: any) => state.configs.remove_button_close,
	);

	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);
	const pagina_desejada = useSelector(
		(state: any) => state.dashboard.number_pages_listagem
	);
	
	const numero_de_paginas = Math.ceil(total_pacientes / 6);
	
	//Botão de voltar
	const handleBack = () => {

		MudarPagina(
			pagina_desejada,
			numero_de_paginas,
			dispatch,
			[3]
		)
		dispatch(RelatorioActions.set_age(3))
		dispatch(AppActions.set_open_barraSuperior(false));
		dispatch(DashBoardActions.mais_visitados_request())
		if(removeButtonClose){
			const modeWeb = getPermission(
				config_servicos,
				TypesPermission.SERVICO_WEB
			)
			if(!modeWeb){
				dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
			}
		}
		if(goDashboard){
			history.push("/dashboard");
		}else{
			window.history.back();
		}
	};

	const handleBackCentralAjuda = () => {
		dispatch(AppActions.set_open_barraSuperior(false));
		history.push("/central_ajuda");
	};

	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);

	const handleBackExecucao = () => {
		dispatch(AppActions.set_checklist_execucao(true));
		if (is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO) {
			dispatch(AvaliationActions.set_in_execution_force_max_status(false));
		}
		dispatch(AppActions.set_resultado_execucao(false));
		dispatch(AvaliationActions.reset2());
		dispatch(AvaliationActions.reset());
	};

	const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const open_barra_superior = useSelector(
		(state: any) => state.app.is_open_barra_superior
	);

	const slider = useSelector((state: any) => state.app.slider_execucao);

	const categoria = useSelector((state: any) => state.central?.categoria);
	const { reset, register } = useForm();

	const [isWriting, SetIsWriting] = useState(false);
	const handleOpenSearch = (e: any) => {
		dispatch(DashBoardActions.set_search(e.target.value));
		if (e.target.value !== "") {
			SetIsWriting(true);
		} else {
			SetIsWriting(false);
		}
	};
	const is_inadimplente = useSelector(
		(state: any) => state.app.is_inadimplente
	);

	//reseta o campo de input
	const resetInput = () => {
		dispatch(DashBoardActions.set_search(""));
		SetIsWriting(false);
		reset();
	};

	useEffect(() => {
		dispatch(DashBoardActions.set_search(""));
		SetIsWriting(false);
		reset();
	}, []);

	return (
		<Conteiner>
			<div className="body">
				<div
					className={
						open_barra_superior === "metodoPagameto" || "Confirmacao"
							? `grande`
							: `a`
					}
				>
					<div className="left">
						{open_barra_superior !== "execucaoProtocolo" &&
							open_barra_superior !== "execucaoCheckList" &&
							open_barra_superior !== "execucaoExercicio" && (
								<button className="setaButton">
									{ removeButtonClose || is_inadimplente
										? <div></div>
										:
										<img
											src={Seta}
											alt=""
											className="seta"
											onClick={
												open_barra_superior === "centralAjudaItem"
												? handleBackCentralAjuda	
												: handleBack 
											}
										/>
									}
								</button>
							)}

						{/*Paciente*/}
						{open_barra_superior.value === "paciente" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_inicio} /
								</p>
								<p className="texto" onClick={handleBack}>
									{" "}
									{current_language?.pag_inicial_pacientes} /
								</p>
								<p className="textoAzul">{open_barra_superior.name}</p>
							</div>
						)}

						{open_barra_superior === "etiquetas" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_etiquetas}
								</p>
							</div>
						)}

						{open_barra_superior === "minhaConta" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_minha_conta}
								</p>
							</div>
						)}

						{open_barra_superior === "personalizarRelatorio" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_minha_conta} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.menu_lateral_personalizar_relatorio}
								</p>
							</div>
						)}

						{open_barra_superior === "faturas" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_minha_conta} /
								</p>
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_pagamento} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.menu_lateral_faturas}
								</p>
							</div>
						)}

						{open_barra_superior === "metodoPagameto" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.confirm_dado} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.pagamento_adicionar_metodo}
								</p>
							</div>
						)}

						{open_barra_superior === "meuPlano" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_minha_conta} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.menu_lateral_meu_plano}
								</p>
							</div>
						)}

						{open_barra_superior === "avaliador" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_minha_conta} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.pag_inicial_avaliador}
								</p>
							</div>
						)}

						{open_barra_superior === "Upgrade" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_meu_plano} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.menu_lateral_upgrade}
								</p>
							</div>
						)}

						{open_barra_superior === "Confirmacao" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_upgrade} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.confirm_dado}
								</p>
							</div>
						)}

						{open_barra_superior === "pagamentos" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_minha_conta} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.menu_lateral_pagament}
								</p>
							</div>
						)}

						{open_barra_superior === "perfil" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_perfil}
								</p>
							</div>
						)}

						{open_barra_superior === "perfilEditar" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_perfil} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.editar}
								</p>
							</div>
						)}

						{open_barra_superior === "alterarSenha" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_perfil} /
								</p>
								<p className="texto" onClick={handleBack}>
									{current_language?.editar} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.menu_lateral_alterar_senha}
								</p>
							</div>
						)}

						{open_barra_superior === "assinatura" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_perfil} /
								</p>
								<p className="texto" onClick={handleBack}>
									{current_language?.editar} /
								</p>
								<p className="textoAzul" onClick={handleBack}>
									{current_language?.assinatura}
								</p>
							</div>
						)}

						{open_barra_superior === "configurar" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_configurar}
								</p>
							</div>
						)}

						{open_barra_superior === "excluidos" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.menu_lateral_lixeira}
								</p>
							</div>
						)}

						{open_barra_superior === "protocolos" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.protocolos_e_exercicios}
								</p>
							</div>
						)}

						{open_barra_superior === "edexercicio" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.protocolos_e_exercicios} /
								</p>
								<p className="textoAzul">
									{current_language?.editar_exercicio}
								</p>
							</div>
						)}

						{open_barra_superior === "edProtocolo" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.protocolos_e_exercicios} /
								</p>
								<p className="textoAzul">
									{current_language?.editar_protocolo}
								</p>
							</div>
						)}

						{open_barra_superior === "centralAjuda" && (
							<div className="patch">
								<p className="texto" onClick={handleBack}>
									{current_language?.central_ajuda}
								</p>
							</div>
						)}

						{open_barra_superior === "centralAjudaItem" && (
							<>
								<div className="patch">
									<p className="texto" onClick={handleBackCentralAjuda}>
										{current_language?.central_ajuda} /
									</p>
									<p className="textoAzul">
										{language == TypesLanguage.PT
											? dataHelpers[categoria]?.category_pt
											: language == TypesLanguage.EN
											? dataHelpers[categoria]?.category_en
											: dataHelpers[categoria]?.category_es}
									</p>
								</div>
							</>
						)}

						{open_barra_superior === "youtube" && <div></div>}

						{open_barra_superior === "execucaoExercicio" && (
							<button className="setaButton2">
								<img src={Seta} alt="" className="seta" onClick={handleBack} />
							</button>
						)}

						{open_barra_superior === "execucaoCheckList" && (
							<div className="leftExecucao">
								<button className="setaButton2">
									<img
										src={Seta}
										alt=""
										className="seta"
										onClick={handleBackExecucao}
									/>
								</button>
								<p className="nomeProtocolo">
									{current_language?.idioma === "Português"
										? protocolo_list.PROTOCOLO_TITULO_PT
										: current_language?.idioma === "English"
										? protocolo_list.PROTOCOLO_TITULO_EN
										: protocolo_list.PROTOCOLO_TITULO_ES}
								</p>
								<p className="tipoExecucao">
									{is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO
										? `${current_language?.config_avaliacao}`
										: `${current_language?.config_funcional} • ${slider}% ${
												current_language?.idioma === "English" ? "MR" : "RM"
										  }`}
								</p>
							</div>
						)}
					</div>
				</div>

				<div
					className={
						open_barra_superior === "execucaoProtocolo" ||
						open_barra_superior === "execucaoCheckList" ||
						open_barra_superior === "execucaoExercicio"
							? `barraCheckList`
							: open_barra_superior === "metodoPagameto"
							? `pagamentos`
							: `b`
					}
				>
					{(open_barra_superior === "centralAjudaItem" ||
						open_barra_superior === "centralAjuda") && (
						<div className="right">
							<div className="b">
								<form action="">
									<div className="mid">
										<div className="input">
											<button type="button" className="reset">
												<img className="lupa" src={Lupa} alt="" />
											</button>
											<input
												onKeyUp={(e) => handleOpenSearch(e)}
												className="inputPesquisa"
												type="text"
												placeholder={current_language?.tela_inicial_busca}
												{...register("pesquisa", {})}
											/>
											{isWriting === true && (
												<button type="reset" className="reset">
													<img
														className="x"
														src={Limpar}
														alt=""
														onClick={resetInput}
													/>
												</button>
											)}
										</div>
									</div>
								</form>
							</div>
						</div>
					)}
				</div>
			</div>
		</Conteiner>
	);
};

export default memo(BarraSuperiorPatch);

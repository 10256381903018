import React, { memo } from "react";
import { Spinner } from "react-activity";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import background from "../../../assets/kinologyIcons/png/backgroundLogin.png";
import email from "../../../assets/icons/email.svg";
import lockPassword from "../../../assets/icons/lockPassword.svg";
import logoNegativo from "../../../assets/logo_negativo.png";
import { TextsProps } from "../../../database/txt.interface";
import { AuthActions } from "../../../store/ducks/auth";
import { i18n } from "../../../translate/i18n";
import { TypesNotifications } from "../../../utils/types";
import { Conteiner, Content } from "./styles";
import { Background } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const RecuperarSenha: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const loading_notification = useSelector(
		(state: any) => state.auth.loading_notification
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const onSubmit = (data: any) => {
		dispatch(
			AuthActions.notification_request(TypesNotifications.FORGOT, data.email)
		);
	};
	//console.log(errors);

	//UI
	return (
		<Conteiner>
			<Content>
				<img src={lockPassword} alt="logo" className="logo" />
				<h1>{current_language?.login_esqueci_senha_subtitulo}</h1>
				<form action="" onSubmit={handleSubmit(onSubmit)}>
					<div className="globalInput">
						<img src={email} alt="email" className="icon" />
						<input
							className="input"
							type="text"
							id="email"
							placeholder={i18n.t("recuperar_senha.exemplo")}
							{...register("email", {
								required: `${current_language?.login_input_senha}`,
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: `${current_language?.register_email_valido}`,
								},
							})}
						/>
					</div>
					<div className="divhr">
						<hr className={errors.email ? "hr hrcor" : "hr"} />
						{errors.email && (
							<div className="error">{errors.email.message}</div>
						)}
					</div>
					<div className="LoginCadastro">
						{loading_notification ? (
							<Spinner />
						) : (
							<button
							onClick={()=> AmplitudeLog('senha_alterada')}
								type="submit"
								id="enviar"
								value="ENVIAR"
								className="enviar"
							>
								{current_language?.primeiro_acesso_concluir}
							</button>
						)}

						<Link to="/login">
							<button
								onClick={()=> AmplitudeLog('voltar_esqu_senha')}
								type="button"
								id="cadastro"
								value="Voltar"
								className="cadastro"
							>
								{current_language?.voltar}
							</button>
						</Link>
					</div>
				</form>
			</Content>
			<Background>
				<img src={background} alt="" />
			</Background>
		</Conteiner>
	);
};

export default memo(RecuperarSenha);

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 438px;
	width: 565px;
	border-radius: 30px;
	display: flex;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 1.738rem;
		position: absolute;
		right: 2rem;
		top: 2rem;
		cursor: pointer;
	}
	.ContainerDino {
		display: flex;
		width: 100%;
		height: 150px;
		justify-content: center;
		align-items: center;
	}

	.divText {
		display: flex;
		flex-direction: column;
		align-items: center; 
		padding: 2rem;
		text-align: center;
	}
	
	.title {
		color: #09101D;
		font-size: 20px;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.text {
		font-size: 20px;
		color: #09101D;
		line-height: 1.5;
	}
	.ContainerButtons {
		display: flex;
		width: 100%;
		height: 100px;
		margin-top: 1.5rem;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}
	.buttonCadastrar {
		display: flex;
		width: 206px;
		height: 55px;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		background-color: ${colors.primary_blue_k_main};
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
		cursor: pointer;
		transition: all 0.2s linear;
	}
	.buttonCadastrar:hover {
		background-color: ${colors.primary_blue_k_dark_2};
	}
	.buttonCancelar {
		display: flex;
		width: 206px;
		height: 55px;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		background-color: #B3B3B3;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
		cursor: pointer;
		transition: all 0.2s linear;
	}
	.buttonCancelar:hover {
		background-color: #999999;
	}
	.txtCadastrar {
		color: #ffffff;
		font-weight: bold;
	}
	.txtCancel {
		color: #ffffff;
		font-weight: bold;
	}
`;
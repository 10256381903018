import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import BarraSuperiorPatch from "../BarraGerenciadorPatch";
import BarraSuperiorPadrao from "../BarraSuperiorPadrão";
import Seta from "../../../../assets/icons/setaDireitaBlack.svg";
import { Conteiner } from "./styles";
import history from "../../../../routes/history";
import { ManageProtocolActions } from "../../../../store/ducks/manage_protocols";

const BarraSuperiorControladora: React.FC = () => {

	const dispatch = useDispatch();
	const open_barra_superior = useSelector(
		(state: any) => state.app.is_open_barra_superior
	);
	const buttonGoback: boolean = useSelector(
		(state: any)=> state. manage_protocol.set_button_goback
	);

	function goback () {
		dispatch(ManageProtocolActions.set_button_goback(false))
		history.goBack()
	};
	const progress_exers_proto = useSelector(
		(state: any) => state.avaliation.progress_exers_proto
	);

	return (
		<Conteiner>
			{buttonGoback
				?<div className="buttonGoback">
					{  progress_exers_proto.length == 0 
						?
							<button className="setaButton" onClick={goback}>
								<img src={Seta}/>
							</button>
						: <div></div>
					}
				 </div>
				: null
			}
			{!open_barra_superior && <BarraSuperiorPadrao />}
			{open_barra_superior && <BarraSuperiorPatch />}
		</Conteiner>
	);
};

export default memo(BarraSuperiorControladora) ;

import { Fade } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Celular from "../../../assets/icons/celular.svg";
import Datanasc from "../../../assets/icons/datanasc.svg";
import emailGray from "../../../assets/icons/emailGray.svg";
import Fechar from "../../../assets/icons/fechar.svg";
import user from "../../../assets/icons/user.svg";
import Cpf from "../../../assets/icons/user.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorRequest } from "../../../utils/types";
import FotoPerfilMenor from "../../FotoPerfilMenor";

import ToastAlert from "../../toast";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import handleMaskHeight from "../../MascaraAltura";
import handleMaskDate from "../../MascaraData";
import handleMaskWeight from "../../MascaraPeso";
import handleMaskCPF from "../../MascaraCPF";
import handleMaskPhone from "../../MascaraTel";

interface Usuario {
	celular: string;
	cpf: string;
}

const EditarPaciente = () => {
	//ESTILO DA MODAL
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			modal: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				backgroundColor: theme.palette.background.paper,
				border: "0",
				boxShadow: theme.shadows[5],
			},
		})
	);

	const classes = useStyles();
	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	//Traducão
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	//ESTADO CPF MASK
	const [us, setUs] = useState<Usuario>({} as Usuario);
	const hendleChange2 = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUs({
				...us,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[us]
	);

	// Estado Mask telefone
	const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
	const hendleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUsuario({
				...usuario,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[usuario]
	);

	//Abrir modal
	const is_open = useSelector(
		(state: any) => state.app.is_open_editar_paciente
	);
	const confirm_close_modal: boolean = useSelector(
		(state: any) => state.app.confirm_close_modal_edit
	);
	const ModalEditarPacienteClose = () => {
		dispatch(AppActions.set_modal_editar_paciente(false));
		setAprovado(false);
	};

	//Avaliadores
	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);

	//Submit do formulario
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const [aprovado, setAprovado] = useState(false);

	const user_eval_avatar_64 =  useSelector((state: any) => state.add_pac_aval.user_eval_avatar_64);

	const onSubmit = (data: any) => {
		setAprovado(true);
		
		const dat_request = {
			USUARIO_NIVEL: "3",
			USUARIO_NOME: data.nome,
			USUARIO_EMAIL: data.email,
			USUARIO_CONTATO: data.celular,
			USUARIO_CPF: data.cpf,
			USUARIO_ESTATURA: data.altura.replace("m", ""),
			USUARIO_MASSA: data.massa.replace("kg", "").replace("k", ""),
			USUARIO_NASCIMENTO:
				current_language?.idioma === "Português"
					? data?.data?.split("/").reverse().join("-")
					: current_language?.idioma === "English"
					? `${data?.data?.substr(6, 10)}-${data?.data
							?.substr(0, 5)
							.split("/")
							.reverse()
							.join("-")}`
					: data?.data.split("-").reverse().join("-"),
			USUARIO_SEXO: data?.sexo != "M" && data?.sexo != "F" ? "" : data?.sexo,
			USUARIO_CONDICIONAMENTO: data.condicionamento,
		};
		dispatch(AddPacAvalActions.set_avatar_eval_64(user_eval_avatar_64 != null ? user_eval_avatar_64 : paciente?.USUARIO_FOTO));
		AmplitudeLog('edit_perfil_paciente', {body: dat_request})
		dispatch(AddPacAvalActions.set_edit_paci(paciente));
		dispatch(AddPacAvalActions.request_add_evaluetor(dat_request));
		if(confirm_close_modal){
			dispatch(AppActions.set_modal_editar_paciente(false));
		}
	};
	//console.log(errors);

	//Valores dos inputs
	useEffect(() => {
		setValue("nome", paciente?.USUARIO_NOME);
		setValue("email", paciente?.USUARIO_EMAIL);
		
		setValue(
			"celular",
			paciente?.USUARIO_CONTATO
		);
		setValue(
			"cpf",
			paciente?.USUARIO_CPF?.replace(
				/(\d{3})(\d{3})(\d{3})(\d{2})/,
				"$1.$2.$3-$4"
			)
		);
		setValue(
			"data",
			paciente?.USUARIO_NASCIMENTO == "0000-00-00"
				? ""
				: current_language?.idioma === "Português"
				? `${paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("/")}`
				: current_language?.idioma === "English"
				? `${paciente?.USUARIO_NASCIMENTO?.substr(5, 10)
						.split("-")
						.join("/")}/${paciente?.USUARIO_NASCIMENTO?.substr(0, 4)}`
				: `${paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("-")}`
		);
		setValue(
			"altura",
			`${
				paciente?.USUARIO_ESTATURA != null && paciente?.USUARIO_ESTATURA != ""
					? paciente?.USUARIO_ESTATURA?.split(".").join(",")
					: ""
			}`
		);
		setValue(
			"massa",
			`${
				paciente?.USUARIO_MASSA != null && paciente?.USUARIO_MASSA != ""
					? paciente?.USUARIO_MASSA?.split(".").join(",")
					: ""
			}`
		);
	}, [paciente]);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		if (type_toast == TypesErrorRequest.SUCCESS) {
			ModalEditarPacienteClose();
		}
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				onClose={ModalEditarPacienteClose}
				open={is_open}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open}>
					<Conteiner>
						{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
							<ToastAlert />
						) : (
							""
						)}

						<form onSubmit={handleSubmit(onSubmit)} className="form">
							<div className="sup">
								<div className="NomeEperfil">
									<h1 className="NovoPc">
										{current_language?.editar_paciente}
									</h1>
									<FotoPerfilMenor />
								</div>
								<div className="fechar">
									<button type="button" className="botaofechar">
										<img
											src={Fechar}
											alt=""
											onClick={()=> {
												ModalEditarPacienteClose() 
											    AmplitudeLog('cancelar_edit_perfil_paciente')
											}}
										/>
									</button>
								</div>
							</div>
							<div className="mid">
								<div className="formP1">
									<div className="globalInput">
										<img src={user} alt="key" className="icon" />
										<input
											type="text"
											id="nome"
											placeholder={current_language?.primeiro_acesso_nome}
											className="input"
											{...register("nome", {
												required: `${current_language?.login_input_senha}`,
												max: 35,
												min: 3,
												pattern: {
													value:
														/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,35}/i,
													message: `${current_language?.pagamento_nome_invalido}`,
												},
											})}
										/>
									</div>
									<div className="divhr">
										<hr className={errors.nome ? "hr hrcor" : "hr"} />
										{errors.nome && (
											<div className="error">{errors.nome.message}</div>
										)}
									</div>
									<div className="globalInput">
										<img src={emailGray} alt="key" className="icon" />
										<input
											type="text"
											id="email"
											placeholder={current_language?.login_email}
											className="input"
											{...register("email", {
												pattern: {
													value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: `${current_language?.forgot_request_error_email}`,
												},
											})}
										/>
									</div>
									<div className="divhr">
										<hr className={errors.email ? "hr hrcor" : "hr"} />
										{errors.email && (
											<div className="error">{errors.email.message}</div>
										)}
									</div>
									<div className="globalInput">
										<img src={Celular} alt="key" className="icon" />
										<input
											type="text"
											id="celular"
											onKeyDown={handleMaskPhone}
											placeholder={current_language?.menu_lateral_person_numero}
											className="input"
											{...register("celular")}
											{...hendleChange}
										/>
									</div>
									<div className="divhr">
										<hr className={errors.celular ? "hr hrcor" : "hr"} />
										{errors.celular && (
											<div className="error">{errors.celular.message}</div>
										)}
									</div>
									<div className="globalInput">
										<img src={Cpf} alt="cpf" className="icon" />
										<input
											type="text"
											id="cpf"
											onKeyDown={handleMaskCPF}
											placeholder={current_language?.primeiro_acesso_CPF}
											className="input"
											{...register("cpf", {
												pattern: {
													value:
													/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i,
													message: `${current_language?.cpf_invalido}`,
												},
											})}
											{...hendleChange2}
										/>
									</div>
									<div className="divhrCPF">
										<hr className={errors.cpf ? "hr hrcor" : "hr"} />
										{errors.cpf && (
											<div className="error">{errors.cpf.message}</div>
										)}
									</div>
								</div>
								<div className="formP2">
									<div className="formP21">
										<div className="grupo">
											<div className="agrupar">
												<div className="globalInput">
													<img src={user} alt="key" className="icon" />
													<input
														type="text"
														id="massa"
														onKeyDown={handleMaskWeight}
														placeholder={current_language?.menu_lateral_massa}
														className="input"
														{...register("massa", {
															pattern: {
																value: /^[0-9]/g,
																message: `${current_language?.apenas_numeros}`,
															},
														})}
													/>
												</div>
												<div className="divhrN">
													<hr className={errors.massa ? "hr hrcor" : "hr"} />
													{errors.massa && (
														<div className="error">{errors.massa.message}</div>
													)}
												</div>
											</div>
											<div className="agrupar">
												<div className="globalInput">
													<img src={user} alt="key" className="icon" />
													<input
														type="text"
														id="altura"
														onKeyDown={handleMaskHeight}
														placeholder={current_language?.menu_lateral_altura}
														className="input"
														{...register("altura", {
															pattern: {
																value: /^[0-9]/g,
																message: `${current_language?.apenas_numeros}`,
															},
														})}
													/>
												</div>
												<div className="divhrN">
													<hr className={errors.altura ? "hr hrcor" : "hr"} />
													{errors.altura && (
														<div className="error">{errors.altura.message}</div>
													)}
												</div>
											</div>
										</div>
										<div className="globalInput">
											<select
												id="condicionamento"
												className="select"
												{...register("condicionamento", {
													required: `${current_language?.login_input_senha}`,
												})}
											>
												<optgroup>
													<option value="1">
														{current_language?.menu_lateral_atleta}
													</option>
													<option value="2">
														{current_language?.menu_lateral_ativo}
													</option>
													<option value="3">
														{current_language?.menu_lateral_pos_operatorio}
													</option>
													<option value="4">
														{current_language?.menu_lateral_sedentario}
													</option>
													<option value="5">
														{current_language?.menu_lateral_idoso}
													</option>
												</optgroup>
											</select>
										</div>
										<div className="divhr">
											<hr
												className={errors.condicionamento ? "hr hrcor" : "hr"}
											/>
											{errors.nacionalidade && (
												<div className="error">
													{errors?.condicionamento?.message}
												</div>
											)}
										</div>
										<div className="grupo">
											<div className="agrupar">
												<div className="globalInput">
													<img src={Datanasc} alt="key" className="icon" />
													<input
														type="text"
														id="data"
														onKeyDown={handleMaskDate}
														placeholder={
															current_language?.menu_lateral_data_nascimento
														}
														className="input"
														{...register("data", {
															required: `${current_language?.login_input_senha}`,
															pattern: {
																value:
																	/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i,
																message: `${current_language?.data_invalida}`,
															},
														})}
													/>
												</div>
												<div className="divhrN">
													<hr className={errors.data ? "hr hrcor" : "hr"} />
													{errors.data && (
														<div className="error">{errors.data.message}</div>
													)}
												</div>
											</div>
											<div className="agrupar">
												<div className="globalInput">
													<select
														id="sexo"
														className="select"
														{...register("sexo", {
															required: `${current_language?.login_input_senha}`,
														})}
													>
														<optgroup>
															<option value="M">
																{current_language?.options_sexo_mas}
															</option>
															<option value="F">
																{current_language?.options_sexo_fem}
															</option>
														</optgroup>
													</select>
												</div>
												<div className="divhr">
													<hr
														className={
															errors.condicionamento ? "hr hrcor" : "hr"
														}
													/>
													{errors.nacionalidade && (
														<div className="error">
															{errors?.condicionamento?.message}
														</div>
													)}
												</div>
											</div>
										</div>

										<div className="grupo">
											<div className="agrupar">
												<div className="globalInput">
													<select
														id="avaliador"
														className="select"
														{...register("avaliador", {})}
													>
														<optgroup>
															<option value="avaliador" disabled selected>
																{current_language?.pag_inicial_avaliador}
															</option>
															{avaliadores.map((item: PacientesProps) => (
																<option
																	value={item.ID_USUARIO}
																	key={item.ID_USUARIO}
																>
																	{item.USUARIO_NOME}
																</option>
															))}
														</optgroup>
													</select>
												</div>
												<div className="divhr">
													<hr
														className={
															errors.condicionamento ? "hr hrcor" : "hr"
														}
													/>
													{errors.nacionalidade && (
														<div className="error">
															{errors?.condicionamento?.message}
														</div>
													)}
												</div>
											</div>
											<div className="agrupar"></div>
										</div>
									</div>
								</div>
							</div>
							<div className="inf">
								<button id="concluir" className="concluir">
									{aprovado == true ? (
										<CircularProgress size="2rem" color="inherit" />
									) : (
										current_language?.pagamento_salvar
									)}
								</button>
							</div>
						</form>
					</Conteiner>
				</Fade>
			</Modal>
		</div>
	);
};

export default EditarPaciente;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 2rem;
	padding-bottom: 5vh;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	border-radius: 1.25rem;

	overflow: hidden;
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;

	overflow-x: hidden;

	overflow-y: scroll;

	padding-right: 1rem;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	.nome {
		font-weight: 500;
		font-size: 1.5rem;

		color: #5c5b5b;
		width: 20rem;
	}

	.bodyHeader {
		width: 100%;

		.hr {
			border-bottom: 1px solid #e0e0e0;
			margin: 0.3rem 0;
		}

		.footerBody {
			display: flex;
			justify-content: flex-end;
		}

		.textHeader {
			font-weight: 400;
			font-size: 1rem;

			color: #8d8d8d;
		}

		.text {
			width: 5rem;
		}
	}

	button {
		margin-right: 3rem;
		padding: 0.75rem 3.5rem;

		height: 3.625rem;
		width: 17.625rem;

		border-radius: 0.5rem;
		border: 0;

		border: 1.5px solid transparent;

		background: ${colors.primary_blue_k_main};
		color: transparent;

		font-weight: 500;
		font-size: 1.125rem;

		transition: all 0.2s linear;
	}

	button:hover {
		color: ${colors.primary_blue_k_main};
		border: 1.5px solid ${colors.primary_blue_k_main};

		background: transparent;
	}
`;

export const BodyEdite = styled.div`
	margin-top: 2rem;

	.lineItens {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(13, 2.8rem);
	}

	.number {
		width: 4rem;
		display: flex;
		justify-content: flex-end;

		font-weight: 400;
	}

	.capacidade {
		color: #b3b3b3;
	}

	.left {
		display: flex;
	}

	.item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		justify-content: space-between;
		p {
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			margin-left: 1rem;
		}
	}

	.minimo {
		color: ${colors?.secondary_green_k_medium_1};
	}

	.medio {
		color: ${colors?.tertiary_yellow_1_dark};
	}

	.maximo {
		color: ${colors.tertiary_red_1_dark};
	}

	.footer {
		display: flex;
		justify-content: center;
	}

	.botao {
		margin-top: 3rem;
		width: 23rem;
		height: 3.625rem;
		background-color: transparent;
		border: 0.2rem solid ${colors.primary_blue_k_main};
		color: ${colors.primary_blue_k_main};
		border-radius: 0.5rem;
		font-weight: 900;
	}

	.botao:hover {
		border: 0.2rem solid ${colors.primary_blue_k_dark_2};
		color: ${colors.primary_blue_k_dark_2};
	}
`;

import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BtnInformacao from "../../../components/BtnInformacao";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { getPermission } from "../../../utils/funcs";
import {
	TypesBtnInfo,
	TypesMenuInfoProps,
	TypesPermission,
} from "../../../utils/types";
import Relatorios from "../../Relatorios";
import Anotacoes from "../Anotacoes";
import Questionarios from "../Questionario_progress/Components/Questionarios";
import {
	BodyQuest,
	BtnDiv,
	BtnEmitir,
	Content,
	FichaTech,
	InformacoesContent,
	Item,
	ListItens,
	Title,
} from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import QualidadeDeVidaProfile from "../QualidadeDeVidaProfile";

interface InformacoesProps {
	autoFocus?: boolean;
};

const Informacoes: React.FC<InformacoesProps> = ({autoFocus}) => {
	const dispatch = useDispatch();
	const contentRef = useRef<HTMLDivElement>(null);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const pacientes = useSelector((state: any) => state.dashboard.pacientes);
	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	const p = pacientes.filter(
		(item: PacientesProps) => item?.ID_USUARIO == paciente?.ID_USUARIO
	);
	const [pacient, setPacient]: any = useState(p[0]);

	const etis = useSelector((state: any) => state.dashboard.etiquetas);

	const perfil_anamnese_array = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array
	);

	const anamneses: any = useSelector(
		(state: any) => state.dashboard.anamneses
	);

	//Abre a ficha tecnica
	const [ficha, setFicha] = useState(false);
	const OpenFicha = () => {
		if (ficha === false) {
			setFicha(true);
		} else {
			setFicha(false);
		}
	};
	
	useEffect(()=>{
		dispatch(PacAvalActions.set_perfil_anamnese_patient(paciente?.ID_USUARIO))
	},[pacientes_loading])

	//Abre modal atribuir avaliador
	const handleOpenAtribuirAvaliador = () => {
		dispatch(PacAvalActions.set_add_selected_patient(pacient));
		dispatch(AppActions.set_modal_atribuir_avaliador(true));
	};

	//Abre modal de condicão fisica
	const handleOpenAtribuirCondicao = () => {
		dispatch(PacAvalActions.set_add_selected_patient(pacient));
		dispatch(AppActions.set_modal_atribuir_fisica(true));
	};

	//Abre modal atribuir etiqueta
	const handleOpenAtribuirEtiqueta = () => {
		dispatch(PacAvalActions.set_add_selected_patient(pacient));
		dispatch(AppActions.set_modal_atribuir_etiqueta(true));
	};


	useEffect(() => {
	  if (autoFocus && contentRef.current) {
		contentRef.current.focus();
	  }
	}, [autoFocus]);

	//Condicionamento
	let cond = null;

	if (pacient?.USUARIO_CONDICIONAMENTO == 1) {
		cond = current_language?.menu_lateral_atleta;
	} else if (pacient?.USUARIO_CONDICIONAMENTO == 2) {
		cond = current_language?.menu_lateral_ativo;
	} else if (pacient?.USUARIO_CONDICIONAMENTO == 3) {
		cond = current_language?.menu_lateral_pos_operatorio;
	} else if (pacient?.USUARIO_CONDICIONAMENTO == 4) {
		cond = current_language?.menu_lateral_sedentario;
	} else if (pacient?.USUARIO_CONDICIONAMENTO == 5) {
		cond = current_language?.menu_lateral_idoso;
	} else {
		cond = "-";
	}

	let nasci = "";

	if (paciente?.USUARIO_NASCIMENTO == "0000-00-00") nasci = "-";
	else if (current_language?.idioma === "Português") {
		nasci = paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
			.split("-")
			.reverse()
			.join("/");
	} else if (current_language?.idioma === "English") {
		nasci =
			pacient?.USUARIO_NASCIMENTO?.substr(5, 10).split("-").join("/") +
			"/" +
			pacient?.USUARIO_NASCIMENTO?.substr(0, 4);
	} else if (current_language?.idioma === "Español") {
		nasci = pacient?.USUARIO_NASCIMENTO?.substr(0, 10)
			.split("-")
			.reverse()
			.join("-");
	}

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	// dispatch(DashBoardActions.set_select_info(""));

	useEffect(() => {
		const p = pacientes.filter(
			(item: PacientesProps) => item?.ID_USUARIO == paciente?.ID_USUARIO
		);
		setPacient(p[0]);
	}, [paciente, is_open_toast, pacientes_loading]);

	useEffect(() => {
		setSelectMenu(TypesMenuInfoProps.BTN_MENU);
	}, []);

	const avaliador = avaliadores.filter(
		(item: PacientesProps) => item?.ID_USUARIO === pacient?.USUARIO_AVALIADOR
	)[0];

	const select_info = useSelector((state: any) => state.dashboard.select_info);
	// config_servicos, dispatch
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	function accessReports(
		config_servicos: ServicosProps[],
		dispatch: any,
		info: string
	) {
		const access_assimetria: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_ASSIMETRIA
		);
		const access_evolution: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_EVOLUCAO
		);
		const access_force_time: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_FORCA_TEMPO
		);
		const multifactorial_access: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_MULTIFATORIAL
		);
		const pysiotherapeutic_access: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_FISIOTERAPEUTICO
		);
		if (
			!access_assimetria &&
			!access_evolution &&
			!access_force_time &&
			!multifactorial_access &&
			!pysiotherapeutic_access
		) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		} else {
			dispatch(DashBoardActions.set_select_info(info));
		}
	}
	function permissionAnamnese(
		config_servicos: ServicosProps[],
		dispatch: any,
		info: string
	) {
		const access_anamnese: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_ANAMNESE
		);
		if (!access_anamnese) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		} else {
			dispatch(DashBoardActions.set_select_info(info));
		}
	}
	function permissionSF36(
		config_servicos: ServicosProps[],
		dispatch: any,
		info: string
	) {
		// const access_SF36: any =getPermission(
		// 	config_servicos,
		// 	TypesPermission.SERVICO_QUALIDADE_VIDA,
		// )
		const access_SF36: any = true;
		if (!access_SF36) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		} else {
			dispatch(DashBoardActions.set_select_info(info));
		}
	}
	function permissionNotes(
		config_servicos: ServicosProps[],
		dispatch: any,
		info: string
	) {
		const access_Notes: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_ANOTACOES
		);
		if (!access_Notes) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		} else {
			dispatch(DashBoardActions.set_select_info(info));
		}
	}

	const setSelectMenu = (info: string) => {
		dispatch(DashBoardActions.set_select_info(info));
	};

	function render_menu_info() {
		return (
			<BtnDiv>

				{anamneses && anamneses[0] && anamneses[0].qualidades_vida.length > 0 && (
					<BtnInformacao
						Icon={TypesBtnInfo.BTN_QUALIDADE_DE_VIDA}
						Texto={"Qualidade de vida"}
						funcao={() => {
							setSelectMenu(TypesMenuInfoProps.BTN_QUALIDADE_VIDA);
							AmplitudeLog("qualidade_vida");
						}}
					/>
				)}

				<BtnInformacao
					Icon={TypesBtnInfo.BTN_FICHA_TECNICA}
					Texto={current_language?.dash_ficha_tec}
					funcao={() => {
						setSelectMenu(TypesMenuInfoProps.BTN_FICHA_TECNICA);
						AmplitudeLog("ficha_paciente");
					}}
				/>

				<BtnInformacao
					Icon={TypesBtnInfo.BTN_RELATORIO}
					Texto={current_language?.relatorios}
					funcao={() => {
						accessReports(
							config_servicos,
							dispatch,
							TypesMenuInfoProps.BTN_RELATORIO
						);
						AmplitudeLog("relatorios_paciente");
					}}
				/>

				<BtnInformacao
					Icon={TypesBtnInfo.BTN_ANAMNESE}
					Texto={current_language?.anamnese}
					funcao={() => {
						permissionAnamnese(
							config_servicos,
							dispatch,
							TypesMenuInfoProps.BTN_ANAMNESE
						);
						AmplitudeLog("anamnese");
					}}
				/>

				<BtnInformacao
					Icon={TypesBtnInfo.BTN_SF}
					Texto={current_language?.sf_36_question}
					funcao={() => {
						permissionSF36(
							config_servicos,
							dispatch,
							TypesMenuInfoProps.BTN_SF
						);
						AmplitudeLog("sf_36");
					}}
				/>

				<BtnInformacao
					Icon={TypesBtnInfo.BTN_ANOTACOES}
					Texto={current_language?.anotacoes}
					funcao={() => {
						permissionNotes(
							config_servicos,
							dispatch,
							TypesMenuInfoProps.BTN_ANOTACOES
						);
						AmplitudeLog("anotacoes_paciente");
					}}
				/>

				<BtnInformacao
					Icon={TypesBtnInfo.BTN_AVALIADOR}
					Texto={`${current_language?.pag_inicial_avaliador}: ${
						!!avaliador?.USUARIO_NOME ? avaliador?.USUARIO_NOME : "-"
					}`}
					funcao={() => {
						handleOpenAtribuirAvaliador();
						AmplitudeLog("avaliador_atual");
					}}
				/>
			</BtnDiv>
		);
	}

	function render_ficha_tecnica() {
		perfil_anamnese_array?.ficha_tecnica?.map((ficha: any) => {
			if(ficha.TITULO_FICHA === "IMC" && !!ficha.VALOR_FICHA) ficha.VALOR_FICHA = ficha.VALOR_FICHA.replace(/exponential/g, "sup");
		})

		return (
			<FichaTech>
				<div className="divBtn">
					<svg
						onClick={() => setSelectMenu(TypesMenuInfoProps.BTN_MENU)}
						className="btnVoltar"
						width="28"
						height="28"
						viewBox="0 0 28 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M27.3594 12.3594V15.6406H7.04688L16.3438 25.0156L14 27.3594L0.640625 14L14 0.640625L16.3438 2.98438L7.04688 12.3594H27.3594Z"
							fill="#000E4B"
						/>
					</svg>
					<BtnEmitir
						onClick={() => {
							setSelectMenu(TypesMenuInfoProps.BTN_RELATORIO);
							dispatch(RelatorioActions.set_age(5));
							AmplitudeLog("emitir_anamnese_paciente");
						}}
					>
						{current_language?.emitir_anamnese}
					</BtnEmitir>
				</div>
				<Title>{current_language?.dash_ficha_tec}</Title>
				<InformacoesContent>
					<ListItens>
						{perfil_anamnese_array?.ficha_tecnica?.map(
							(e: any, i: any) =>
								i < Math.ceil(perfil_anamnese_array.ficha_tecnica.length / 2) &&
								e.VALOR_FICHA !== "" &&
								e.VALOR_FICHA !== null &&
								e.VALOR_FICHA !== undefined &&
								e.VALOR_FICHA?.length > 0 && (
									<Item key={i}>
										<p>{e.TITULO_FICHA}:</p>
											{e.TITULO_FICHA === "Nível condicionamento" && (
												<>
													<span>
														{
															e.VALOR_FICHA === "Atleta"
															? current_language?.menu_lateral_atleta
															: e.VALOR_FICHA === "Ativo"
															? current_language?.menu_lateral_ativo
															: e.VALOR_FICHA === "Pós-operatório"
															? current_language?.menu_lateral_pos_operatorio
															: e.VALOR_FICHA === "Sedentário"
															? current_language?.menu_lateral_sedentario
															: current_language?.menu_lateral_idoso
														}
													</span>
												</>
											) || e.TITULO_FICHA === "IMC" && (
												<>
													<span style={{lineHeight: '0.5rem'}} dangerouslySetInnerHTML={{__html: e.VALOR_FICHA}}></span>
												</>
											) || (
													<span>{e.VALOR_FICHA}</span>
											)}
									</Item>
								)
						)}
					</ListItens>
					<ListItens>
						{perfil_anamnese_array?.ficha_tecnica?.map(
							(e: any, i: any) =>
								i >=
									Math.ceil(perfil_anamnese_array?.ficha_tecnica?.length / 2) &&
								e.VALOR_FICHA !== "" &&
								e.VALOR_FICHA !== null &&
								e.VALOR_FICHA !== undefined &&
								e.VALOR_FICHA?.length > 0 && (
									<Item key={i}>
										<p>{e.TITULO_FICHA}:</p>
										<span>
											{e.TITULO_FICHA != "Imagens"
												? e.VALOR_FICHA
												: e.VALOR_FICHA.map((f: any) => {
														return (
															<img key={f} src={f} style={{ width: "100px" }} />
														);
												  })}
										</span>
									</Item>
								)
						)}
					</ListItens>
				</InformacoesContent>
			</FichaTech>
		);
	}

	function render_questionario(questionario: string) {
		return (
			<BodyQuest>
				<svg
					onClick={() => setSelectMenu(TypesMenuInfoProps.BTN_MENU)}
					className="btnVoltar"
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M27.3594 12.3594V15.6406H7.04688L16.3438 25.0156L14 27.3594L0.640625 14L14 0.640625L16.3438 2.98438L7.04688 12.3594H27.3594Z"
						fill="#000E4B"
					/>
				</svg>
				<Questionarios Questionario={questionario} />
			</BodyQuest>
		);
	}

	return (
		<Content ref={contentRef}>
			{select_info === TypesMenuInfoProps.BTN_MENU ? (
				render_menu_info()
			) : select_info === TypesMenuInfoProps.BTN_FICHA_TECNICA ? (
				render_ficha_tecnica()
			) : select_info === TypesMenuInfoProps.BTN_AVALIACAO_FÍSICA ? (
				render_questionario(TypesMenuInfoProps.BTN_AVALIACAO_FÍSICA)
			) : select_info === TypesMenuInfoProps.BTN_ANAMNESE ? (
				render_questionario(TypesMenuInfoProps.BTN_ANAMNESE)
			) : select_info === TypesMenuInfoProps.BTN_SF ? (
				render_questionario(TypesMenuInfoProps.BTN_SF)
			) : select_info === TypesMenuInfoProps.BTN_RELATORIO ? (
				<Relatorios />
			)  : select_info === TypesMenuInfoProps.BTN_QUALIDADE_VIDA ? (
				<QualidadeDeVidaProfile />
			)
			 : (
				select_info === TypesMenuInfoProps.BTN_ANOTACOES && <Anotacoes />
			)}
		</Content>
	);
};

export default Informacoes;

import styled from "styled-components";

export const Conteiner = styled.div`

	.btnAdicionar {
		z-index: 999;
		display: flex;
		flex-direction: row;
		width: 12.438rem;
		height: 3.731rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		font-weight: 500;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
		transition: all 0.3s linear;
	}

	.textoBotao {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		color: #7f7f7f;
		margin-left: 0.9rem;
	}

	.btnAdicionar:hover {
		transform: translateY(-3px);
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
		/* transition: all 0.2s linear; */
	}

	.btnAdicionar:active {
		transform: translateY(-1px);
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btn-white {
		background-color: white;
		color: #777;
	}

	.btnAdicionar::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 100px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}

	.btn-white::after {
		background-color: white;
		transition: all 0.2s linear;
	}

	.btnAdicionar-animated {
		animation: moveInBottom 5s ease-out;
		animation-fill-mode: backwards;
	}

	@keyframes moveInBottom {
		0% {
			opacity: 0;
			transform: translateY(30px);
		}

		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}
`;

import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Toast = styled.div`
	z-index: 9999999;

	height: 54px;
	width: 356px;
	border-radius: 8px;
	color: #ffffff;
	position: absolute;

	top: 15px;
	right: 15px;

	transition: all 0.2s linear;

	overflow: hidden;

	svg {
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.success {
		background-color: #2db54e;
	}

	.fail {
		background-color: ${colors.tertiary_red_1_dark};
	}

	.enter {
		animation: go2-in 1s;
		opacity: 1;
		transition: all 0.6s linear;

		@keyframes go2-in {
			from {
				transform: translateX(380px);
			}
			to {
				transform: translateX(0);
			}
		}
	}

	.down {
		animation: go2-back 1s;
		opacity: 0;
		transition: all 0.6s linear;

		@keyframes go2-back {
			from {
				transform: translateX(0);
			}
			to {
				transform: translateX(380px);
			}
		}
	}

	div {
		height: 100%;
		width: 100%;
		border-radius: 8px;
		display: flex;
		justify-content: left;
		align-items: center;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	}
`;

import styled from "styled-components";

export const Container = styled.div`
	width: 10.568rem;
	min-width: 10.568rem;
	height: 5.696rem;
	min-height: 5.696rem;

	background: #FCFCFC;

	border: 1.37083px solid #EDEDED;
	box-shadow: 0px 1.37083px 2.74166px rgba(0, 0, 0, 0.16);
	border-radius: 0px;

	padding: .5rem 1rem;

	display: flex;
	justify-content: center;
	align-items: center;

	flex-direction: column;

	cursor: pointer;

	transition: all linear .3s;
`;

export const Text = styled.span`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 120%;

	margin-top: 0.842rem;

	color: #404040;

	text-align: center;
`;

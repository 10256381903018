import React from "react";
import styled from "styled-components";

import Menu from "../../../components/Menu";
import ContentDash from "../../Dashboard/ContentDash";

export const ViewPort = styled.div`
	display: flex;
	color: rgba(140, 142, 134, 0, 8);
`;
const PacienteController: React.FC = () => {
	return (
		<ViewPort>
			<Menu setMenu="home"></Menu>
			<ContentDash listagem={"paciente"} />
		</ViewPort>
	);
};

export default PacienteController;

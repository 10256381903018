import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding: 3.6rem;
	border-radius: 1.25rem;

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.r {
		display: flex;
		flex-direction: row;
	}

	.divCabecalho {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: #5c5b5b;
	}

	.divTitle {
		display: flex;
		flex-direction: row;
		height: 2rem;
		background-color: white;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 28px;
		color: #5c5b5b;
	}

	.divNav {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 6rem;
		align-items: center;
		background-color: white;
	}

	.selecao {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #001574;
		font-weight: 500;
		background-color: transparent;
		border-bottom: 3px solid #001574;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #4d4d4d;
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.fichaExercicio {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		margin-top: 1.3rem;
		margin-bottom: 1rem;
		padding-right: 1rem;
	}

	.c {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;
		height: 100%;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;
	}

	.ca {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		width: 100%;
		height: 50%;
	}

	.cb {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50%;
		background-color: white;
	}

	.cc {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
	}

	.d {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		background-color: snow;
	}

	.condicao {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 1.3rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 60%;
		height: 100%;
	}

	.b {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
	}

	.p {
		margin-bottom: 0.5rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #5c5b5b;
	}

	.opo {
		display: flex;
		flex-direction: row;
		width: 40%;
		align-items: center;
		justify-content: flex-end;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.botao {
		color: ${colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		font-weight: 500;
		margin-left: 2.1rem;
	}

	.divExercicio {
		display: flex;
		flex-direction: column;
		width: 100%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.DivIcone {
		display: flex;
		margin-right: 1.5rem;
		align-items: center;
		margin-bottom: 1.8rem;
	}

	.divInput {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 2.5rem;
		margin-bottom: 1rem;
	}

	.tempo {
		font-family: Roboto;
		font-style: italic;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #5c5b5b;
		margin-right: 1rem;
	}

	.input {
		display: flex;
		width: 5rem;
		height: 2rem;
		border: 0.5px solid #b3b3b3;
		box-sizing: border-box;
		border-radius: 4px;
		font-family: Roboto;
		font-style: italic;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: ${colors.primary_blue_k_main};
		text-align: center;
		::placeholder {
			color: ${colors.primary_blue_k_dark_2};
		}
	}

	.groupRadio {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.checkmark {
		height: 1.25rem;
		width: 1.25rem;
		background-color: #c4c4c4;
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}

	.checkmarkSeleted {
		height: 1.25rem;
		width: 1.25rem;
		background-color: ${colors.secondary_green_k_medium_1};
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}

	.select {
		display: none;
	}

	.lenguage {
		display: flex;
		flex-direction: row;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: flex-end;
	}

	.idioma {
		margin-left: 0.6rem;
	}

	.ope {
		display: flex;
		flex-direction: row;
		width: 40%;
		justify-content: flex-end;
		align-items: flex-end;
	}
`;

import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";


import DeletUser from "../../../assets/icons/deletUser.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Conteiner } from "./styles";
import moment from "moment";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { persistStorage } from "../../../utils/base_async_storage";

const BotoesInferiores: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);

	const dispatch = useDispatch();

	const handleOpenAtribuirAvaliador = () => {
		dispatch(AppActions.set_modal_atribuir_avaliador(true));
	};

	const handleOpenAtribuirEtiqueta = () => {
		dispatch(AppActions.set_modal_atribuir_etiqueta(true));
	};

	const handleOpenAtribuirFisica = () => {
		dispatch(AppActions.set_modal_atribuir_fisica(true));
	};

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const handleDeleteUsers =  ()=> {
		
		const formatObjet: any = {}
		formatObjet["0"] = {
			TOKEN: user_logado?.token,
		};
		selected_patients?.forEach((
			objeto: { ID_USUARIO: number; txDataHora: string }, 
			indice:  number
		) => {
				formatObjet[indice + 1] = {
					txDataHora: moment(new Date())
						.format('YYYY-MM-DD HH:mm:ss')
						.toString(),
					ID_USUARIO: objeto?.ID_USUARIO,
				};
		});

		const obj = {
			mode: 'excluir_pacientes',
			data: formatObjet,
		};

		persistStorage({
			res: formatObjet,
			tag:'undo_action_patient_delete',
		});

		dispatch(AppActions.set_modal_excluir({ status: true, parameter: obj  }));
	}

	return (
		<Conteiner>
			<div className="btns">
				<button
					className="btnAvaliador btn-white btn-animate transicaoAva"
					onClick={()=>handleDeleteUsers()}
				>
					<img src={DeletUser} />
					<p className="texto">{current_language?.excluir}</p>
				</button>
				<button
					className="btnAvaliador btn-white btn-animate transicaoAva"
					onClick={handleOpenAtribuirAvaliador}
				>
					<svg
						width="20"
						height="22"
						viewBox="0 0 20 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M17.7778 2.2H13.1333C12.6667 0.924 11.4444 0 10 0C8.55556 0 7.33333 0.924 6.86667 2.2H2.22222C1 2.2 0 3.19 0 4.4V19.8C0 21.01 1 22 2.22222 22H17.7778C19 22 20 21.01 20 19.8V4.4C20 3.19 19 2.2 17.7778 2.2ZM10 2.2C10.6111 2.2 11.1111 2.695 11.1111 3.3C11.1111 3.905 10.6111 4.4 10 4.4C9.38889 4.4 8.88889 3.905 8.88889 3.3C8.88889 2.695 9.38889 2.2 10 2.2ZM10 6.6C11.8444 6.6 13.3333 8.074 13.3333 9.9C13.3333 11.726 11.8444 13.2 10 13.2C8.15556 13.2 6.66667 11.726 6.66667 9.9C6.66667 8.074 8.15556 6.6 10 6.6ZM16.6667 19.8H3.33333V18.26C3.33333 16.06 7.77778 14.85 10 14.85C12.2222 14.85 16.6667 16.06 16.6667 18.26V19.8Z"
							fill="#001574"
						/>
					</svg>
					<p className="texto">{current_language?.pag_inicial_avaliador}</p>
				</button>
				<button
					className="btnfísica btn-white btn-animate transicaoCond"
					onClick={handleOpenAtribuirFisica}
				>
					<svg
						width="24"
						height="27"
						viewBox="0 0 24 27"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12 0C13.4667 0 14.6667 1.215 14.6667 2.7C14.6667 4.185 13.4667 5.4 12 5.4C10.5333 5.4 9.33333 4.185 9.33333 2.7C9.33333 1.215 10.5333 0 12 0ZM24 8.45C24 9.00228 23.5523 9.45 23 9.45H16V26C16 26.5523 15.5523 27 15 27H14.3333C13.781 27 13.3333 26.5523 13.3333 26V18.9H10.6667V26C10.6667 26.5523 10.219 27 9.66667 27H9C8.44772 27 8 26.5523 8 26V9.45H1C0.447715 9.45 0 9.00228 0 8.45V7.75C0 7.19772 0.447715 6.75 1 6.75H23C23.5523 6.75 24 7.19771 24 7.75V8.45Z"
							fill="#001574"
						/>
					</svg>{" "}
					<p className="texto">{current_language?.condicao_fisica}</p>
				</button>
				<button
					className="btnEtiqueta btn-white btn-animate"
					onClick={handleOpenAtribuirEtiqueta}
				>
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4.86 15.42C4.76391 16.1982 5.04947 17.0495 5.68421 17.6842L16.1138 28.0967C17.1559 29.1388 18.7714 29.2286 19.7143 28.2857L28.2857 19.7143C29.2286 18.7714 29.1388 17.1559 28.0967 16.1138L17.6842 5.68421C17.0495 5.04948 16.1982 4.76391 15.42 4.86L6 6L4.86 15.42Z"
							fill="#001574"
						/>
						<circle cx="11.3518" cy="11.352" r="2.12132" fill="white" />
					</svg>
					<p className="texto">{current_language?.tag}</p>
				</button>
			</div>
		</Conteiner>
	);
};

export default memo(BotoesInferiores);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import history from "../../../routes/history";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { Content, Exercice, Update } from "./styles";
import PreviewFisioterapeutico from "./_renderPreview";
import DinoTriste from "../../../assets/icons/dinoTriste.svg";

const Fisioterapeutico: React.FC = () => {
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const [permission, setPermission] = useState(true);

	const navigationUpgrade = () => {
		history.push("/upgrade");
	};

	useEffect(() => {
		const permissionPhysiotherapeutic: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_FISIOTERAPEUTICO
		);
		if (!permissionPhysiotherapeutic) {
			setPermission(false);
		}
	}, []);

	return (
		<Content>
			<Exercice>
				{permission ? (
					<PreviewFisioterapeutico />
				) : (
					<Update>
						<img src={DinoTriste} alt="" />
						<p>{current_language?.modal_upgrade_funcionalidade_indisponivel}</p>
						<p>
							{current_language?.modal_upgrade_funcionalidade_indisponivel2}
						</p>
						<button className="buttonUpgrade" onClick={navigationUpgrade}>
							{current_language?.menu_lateral_upgrade}
						</button>
					</Update>
				)}
			</Exercice>
		</Content>
	);
};

export default Fisioterapeutico;

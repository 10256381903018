import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Conteiner = styled.div`
	label {
		cursor: pointer;
	}

	.custom-checkbox input + .label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}

	.custom-checkbox input:checked + .label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${colors.secondary_green_k_medium_1};
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.custom-checkbox input + .label2:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}

	.custom-checkbox input:checked + .label2:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${colors.secondary_green_k_medium_1};
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}
`;

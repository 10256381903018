import React from "react";
import { Container } from "./styles";
import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import {
	ProtoExercicioProps,
	ProtocolosProps,
} from "../../../interfaces/protocolo.interface";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";
import { AmplitudeLog } from "../../../utils/amplitude";

const DetailProtocolo: React.FC = () => {
	const dispatch = useDispatch();

	const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);

	const EditarProtocolo = (protocolo: ProtocolosProps) => {
		AmplitudeLog('editar_protocolo')
		dispatch(AppActions.set_open_protocolo(protocolo, true));
		history.push("/adicionar_protocolo");
	};

	const ExcluirProtocolo = (protocolo: ProtocolosProps) => {
		AmplitudeLog('excluir_protocolo')
		const type = {
			mode: "protocolo",
			id: protocolo.ID_PROTOCOLO,
		};
		dispatch(AppActions.set_modal_excluir({ status: true, parameter: type }));
	};

	return (
		<Container>
			<div className="header">
				<p></p>
				<p className="protocol_name">{protocolo_list.PROTOCOLO_TITULO_PT}</p>
				<div>
					{protocolo_list.ID_USUARIO != 0 && (
						<>
							<img
								src={Editar}
								onClick={() => EditarProtocolo(protocolo_list)}
								className="editar_protocolo"
								alt="editar protocolo"
							/>
							<img
								src={Excluir}
								onClick={() => ExcluirProtocolo(protocolo_list)}
								alt="excluir protocolo"
							/>
						</>
					)}
				</div>
			</div>
			<div className="listagem">
				{protocolo_list?.PROTOCOLO_EXERCICIO.map(
					(exercicio: ProtoExercicioProps, index) => (
						<div key={index} className="exercicio">
							<p className="exercise_name">{exercicio.EXERCICIO_TITULO_PT}</p>
							<hr />
						</div>
					)
				)}
			</div>
		</Container>
	);
};

export default DetailProtocolo;

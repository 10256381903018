import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100vw;
	height: 90vh;

	background: #f6fbfc;

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.tutorial {
		display: flex;
		width: 20%;
		height: 100%;
		margin-right: 10px;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		cursor: pointer;
	}
	.TitleTutorial {
		font-weight: 500;
		color: ${colors.primary_blue_k_dark_2};
	}

	.pause {
		background: transparent;
		border: 0.2rem solid ${colors.primary_blue_k_main};
		color: ${colors.primary_blue_k_main};
	}

	.pause:hover {
		background: transparent;
		border: 0.2rem solid ${colors.primary_blue_k_dark_2};
		color: ${colors.primary_blue_k_dark_2};
	}

	.r {
		display: flex;
		flex-direction: row;
	}

	.divCabecalho {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: #5c5b5b;
	}

	.divTitle {
		display: flex;
		flex-direction: row;
		height: 2rem;
		background-color: white;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 28px;
		color: #5c5b5b;
	}

	.divNav {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 6rem;
		align-items: center;
		background-color: white;
	}

	.selecao {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #001574;
		font-weight: 500;
		background-color: transparent;
		border-bottom: 3px solid #001574;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #4d4d4d;
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.fichaExercicio {
		display: flex;
		flex-direction: row;
		width: 90%;
		height: 5rem;
		margin-bottom: 0.5rem;
		padding-right: 1rem;
	}

	.c {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;
		height: 100%;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;
	}

	.ca {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		width: 100%;
		height: 50%;
	}

	.cb {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50%;
		background-color: white;
	}

	.cc {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
	}

	.d {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		background-color: snow;
	}

	.condicao {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 1.3rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 60%;
		height: 100%;
	}

	.b {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
	}

	.p {
		margin-bottom: 0.5rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #5c5b5b;
	}

	.opo {
		display: flex;
		flex-direction: row;
		width: 40%;
		align-items: center;
		justify-content: flex-end;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.botao {
		color: ${colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		font-weight: 500;
		margin-left: 2.1rem;
	}

	.divExercicio {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.DivIcone {
		display: flex;
		margin-right: 1.5rem;
		align-items: center;
		margin-bottom: 1.8rem;
	}

	.divInput {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 2.5rem;
		margin-bottom: 1rem;
	}

	.tempo {
		font-family: Roboto;
		font-style: italic;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #5c5b5b;
		margin-right: 1rem;
	}

	.input {
		display: flex;
		width: 5rem;
		height: 2rem;
		border: 0.5px solid #b3b3b3;
		box-sizing: border-box;
		border-radius: 4px;
		font-family: Roboto;
		font-style: italic;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: ${colors.primary_blue_k_main};
		text-align: center;
		::placeholder {
			color: ${colors.primary_blue_k_dark_2};
		}
	}

	.groupRadio {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.checkmark {
		height: 1.25rem;
		width: 1.25rem;
		background-color: #c4c4c4;
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}

	.checkmarkSeleted {
		height: 1.25rem;
		width: 1.25rem;
		background-color: #001574;
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}

	.select {
		display: none;
	}

	.lenguage {
		display: flex;
		flex-direction: row;
		height: 100%;
		width: 25%;
		align-items: center;
		justify-content: flex-end;
	}

	.idioma {
		margin-left: 0.6rem;
	}

	.ope {
		display: flex;
		flex-direction: row;
		width: 40%;
		justify-content: flex-end;
		align-items: flex-end;
	}

	overflow: hidden;
`;

export const Scroll = styled.div`
	width: 85%;
	height: 85%;
	border-radius: 1.2rem;

	@media (max-height: 1800px) {
		overflow-x: hidden;
		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 0px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: transparent;
			border-radius: 10px;
			margin: 1rem 0;
		}
	}

	overflow: hidden;

	background: white;

	box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);

	@media (max-height: 900px) {
		height: 90%;

		padding: 1rem;
	}
`;

export const Content = styled.div`
	width: 100%;
	padding: 3rem 0;

	.forc_min {
		position: relative;

		.edit {
			position: absolute;
			right: -2rem;

			cursor: pointer;
		}
	}

	.botaoClick {
		cursor: pointer;

		path {
			transition: all 0.2s ease-in;
			fill: #8c8e86;
		}

		path:hover {
			fill: ${colors.primary_blue_k_main};
		}
	}

	.botaoClick2 {
		path {
			transition: all 0.2s ease-in;
			fill: #e0e0e0;
		}
	}

	.botaoClick3 {
		cursor: pointer;

		path {
			transition: all 0.2s ease-in;
			fill: ${colors.primary_blue_k_main};
		}
	}

	.header {
		padding: 0 3rem;
		width: 100%;
		height: 20%;

		display: flex;
		justify-content: space-between;

		.perfil {
			color: #5c5b5b;
			display: flex;
			justify-content: center;
			align-items: center;

			.fotoPerfil {
				width: 7rem;
				height: 7rem;
			}

			.info {
				margin-left: 2rem;

				p {
					margin-bottom: 1rem;
					font-weight: 700;
					font-size: 1.375rem;
				}

				small {
					font-size: 1rem;
					font-weight: 500;
				}

				svg {
					width: 0.8rem;
					height: 0.8rem;
					margin-right: 0.5rem;
				}
			}
		}

		.config {
			height: 100%;
			margin: 1.5rem 0;

			.configArea {
				display: flex;
				flex-direction: row;
			}

			.bodyConfig {
				color: #001574;
				margin-left: 1.2rem;

				display: flex;
				align-items: center;
				svg {
					margin-left: 0.4rem;
				}

				small {
					margin-top: 0.2rem;
					font-size: 0.8rem;
				}

				.dentroBateria {
					width: 100%;
					height: 100%;
				}
			}

			.headConfig {
				display: flex;
				justify-content: center;
				align-items: center;
				svg {
					margin-right: 1.2rem;
				}
			}

			.footerConfig {
				color: ${colors.primary_blue_k_dark_2};

				cursor: pointer;

				display: flex;
				justify-content: flex-end;

				margin-right: 0.2rem;
				margin-top: 2rem;

				text-decoration: none;

				svg {
					margin-left: 0.5rem;
				}
			}

			.footerConfig2 {
				color: #e0e0e0;

				display: flex;
				justify-content: flex-end;

				margin-right: 0.2rem;
				margin-top: 2rem;

				text-decoration: none;

				svg {
					margin-left: 0.5rem;
				}
			}
		}
	}

	.configuracoes {
		height: 15%;
	}
`;

export const Body = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;

	flex-direction: column;

	.descanso {
		margin-top: 1rem;
	}

	.titleH2 {
		font-weight: 700;
		font-size: 1.375rem;
		color: #5c5b5b;
	}

	.marginTopoBarra {
		margin-top: 3rem;
		margin-bottom: 2rem;
		

		@media (max-width: 1300px) {
			margin-top: 2rem;
			margin-bottom: 0;
		}
	}

	.linhasBarra {
		height: 4.5rem;
		width: 55rem;

		display: flex;
		justify-content: space-around;

		.legenda {
			font-weight: 500;
			font-size: 1.5rem;

			h2 {
				color: ${colors.primary_blue_k_light_1};
			}
		}

		.legendaPonta {
			width: 6rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			p {
				font-weight: 500;
				font-size: 1.15rem;
				color: ${colors.primary_blue_k_light_1};
			}

			small {
				font-weight: 500;
				font-size: 0.8rem;
				color: ${colors.primary_blue_k_light_1};
			}
		}

		@media (max-width: 1300px) {
			height: 4rem;
			width: 50rem;
		}

		@media (max-width: 1130px) {
			height: 3.8rem;
			width: 45rem;
		}
	}

	.timers {
		height: 8rem;
		width: 42rem;

		display: flex;

		justify-content: space-between;

		.contador {
			height: 100%;

			display: flex;
			align-items: center;

			.circularProgress {
				background-color: #fff;
				width: 4.375rem;

				display: flex;
				justify-content: center;
				align-items: center;

				position: relative;

				.timer {
					width: 2.5rem;
					height: 2.5rem;

					border-radius: 50%;

					background: #fff;

					position: absolute;

					display: flex;
					justify-content: center;
					align-items: center;

					font-size: 1.8rem;
					font-weight: 500;

					color: #7f7f7f;
				}
			}
			p {
				color: #7f7f7f;
				font-weight: 500;
				font-size: 1rem;

				width: 5rem;

				margin-left: 1rem;
			}
		}

		.repeticoes {
			height: 100%;

			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			color: #7f7f7f;
			font-weight: 500;
			p {
				font-size: 2.625rem;
			}

			span {
				font-size: 1rem;
			}
		}

		.cronometro {
			height: 100%;

			display: flex;
			align-items: center;
			justify-content: center;

			p {
				margin-left: 1rem;

				font-size: 2.25rem;
				font-weight: 500;

				color: #5c5b5b;
			}
		}

		@media (max-width: 1300px) {
			height: 5rem;
			width: 39rem;
			margin-top: 2rem;
		}
	}

	.botaoRefazer {
		background: transparent;
		color: ${colors.primary_blue_k_main};
		border: 0;
		margin-top: 1.8rem;
	}

	.botaoRefazer:hover {
		background: transparent;
		color: ${colors.primary_blue_k_dark_2};
		border: 0;
	}

	.botaoConcluir {
		margin-top: 2rem;
	}

	.botaoInferior {
		@media (max-height: 900px) {
			height: 5rem;
		}
	}

	.duvida {
		cursor: pointer;
	}

	button {
		width: 19rem;
		height: 3.3rem;

		font-weight: 700;

		color: #fff;

		background-color: ${colors.primary_blue_k_main};

		border: 0;
		border-radius: 1rem;

		display: flex;
		justify-content: center;
		align-items: center;

		transition: all 0.2s linear;

		svg {
			margin-right: 0.8rem;
		}
	}

	button:hover {
		background-color: ${colors.primary_blue_k_dark_2};
	}

	.legendaResultado {
		height: 3.5rem;
		width: 50%;

		margin-top: 1rem;

		display: flex;
		align-items: center;
		justify-content: space-between;

		.legendaInfo {
			display: flex;

			svg {
				margin-right: 0.5rem;
			}

			p {
				font-weight: 400;
				font-size: 1.125rem;
				color: #5c5b5b;
			}
		}

		@media (max-width: 1300px) {
			width: 65%;
		}

		@media (max-width: 1130px) {
		}
	}
`;

export const Checklist = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1rem 15%;

	.HeaderLista {
		display: flex;
		width: 100%;
		height: 30%;
		display: flex;
		flex-direction: column;
		align-items: center;

		h2 {
			margin-bottom: 1rem;
			font-weight: 500;
			font-size: 1.5rem;
			color: #5c5b5b;
			width: 100%;
			display:flex;

			.container{
				display:flex;
				width: 100%;
				height: 30px;
				flex-direction: row;
			}
			
			.containerLeft{
				height: 100%;
				width:33.3%;
			}
			.containerTitle{
				display:flex;
				min-width: 33.3%;
				align-items: center;
				justify-content: space-around;
				height: 30px;
			}
			.containerIcons{
				display: flex;
				justify-content: flex-end;
				flex-direction: row;
				height: 100%;
				width:33.3%;
			}
			.iconEditProtocol{
				display:flex;
				width:8%;
				height:30px;
				align-items: center;
				justify-content: center;
				margin-left: 50px;
			}
			.iconDeleteProtocol{
				display:flex;
				width:6%;
				height:30px;
				align-items: center;
				justify-content: center;
				margin-left: 30px;
				margin-right: 20px;
			}
		}
		.containerTitleSugeri{
			display:flex;
			width: 372px;
			align-items: center;
			
			flex-direction: row;
			height: 33px;
			padding-left: 24px;
			padding-right:24px;
			margin-top: 20px;
			border-radius: 100px;
			background-color: ${colors.primary_blue_k_light_2};

			.TempoEstimado {
				font-size: 15px;
				font-weight: 400;
				color: ${colors.primary_blue_k_dark_2};
			}
			.valueTime {
				font-size: 15px;
				font-weight: 700;
				color: ${colors.primary_blue_k_dark_2};
				margin-left: 4px;
			}
		}
		span {
			display:flex;
			font-weight: 400;
			margin-top: 10px;
			font-size: 1.25rem;
			color: #7f7f7f;
			justify-content: center;
		}
	}

	.finalizar {
		background: ${colors.primary_blue_k_main};
		color: #fff;
		margin: 0;

		transition: all 0.2s linear;
	}

	.finalizar:hover {
		background-color: ${colors.primary_blue_k_dark_2};
		border-color: ${colors.primary_blue_k_dark_2};
		color: #fff;
	}

	.lista {
		width: 100%;
		height: 80%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.selecaoLado {
			padding: 0.5rem 1rem;

			border-radius: 1.5rem;

			background: #8c8e86;
			color: #fff;

			margin-left: 1rem;

			font-size: 0.9rem;
		}
		.hori {
			display: flex;
			flex-direction: row;

			width: 100%;
		}

		hr {
			border: 1px solid #e0e0e0;
			color: #e0e0e0;
			background: #e0e0e0;
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;

		height: 40%;
	}

	.footer2 {
		display: flex;
		flex-direction: column;
		align-items: center;

		height: 20%;
	}

	.exercicios {
		height: 60%;
		overflow-y: scroll;

		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.cardInfo {
		width: 100%;
		height: 6rem;

		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

		display: flex;
		align-items: center;

		padding: 1.5rem;
		border-radius: 1rem;

		hr {
			margin: 0.5rem 0;
		}

		.infoSec {
			width: 40%;
		}

		.info {
			width: 70%;
		}

		.duvida {
			width: 30%;
			display: flex;
			justify-content: flex-end;

			svg {
				cursor: pointer;
			}
		}

		.infoBody {
			display: flex;
			justify-content: space-between;
		}

		.infoFooter {
			display: flex;
			justify-content: space-between;
		}
	}

	button {
		width: 23rem;
		height: 3.5rem;

		margin-bottom: 3rem;
		margin-top: 2rem;

		color: ${colors.primary_blue_k_main};

		font-weight: 700;
		font-size: 1.125rem;

		background: transparent;
		border-radius: 0.5rem;
		border: 3px solid ${colors.primary_blue_k_main};

		transition: all 0.2s linear;
	}

	button:hover {
		border-color: ${colors.primary_blue_k_dark_2};
		color: ${colors.primary_blue_k_dark_2};
	}

	@media (max-width: 1300px) {
		.cardInfo {
			.info {
				width: 80%;
			}

			.duvida {
				width: 20%;
			}
		}

		button {
			margin-bottom: 1rem;
		}
	}

	@media (max-width: 1130px) {
		button {
			margin-bottom: 0rem;
		}
	}
`;

export const Item = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin: 1.5rem 0 0.7rem;
	margin-right: 1rem;

	color: #5c5b5b;

	font-weight: 500;
	font-size: 1rem;

	h2 {
		width: 70%;
		font-weight: 500;
		font-size: 1.2rem;
		color: #5c5b5b;
	}

	.hori {
		display: flex;
		justify-content: flex-end;

		.borderVerde {
			border: 1px solid #32b753;
		}

		.borderCinza {
			border: 1px solid #8c8e86;
		}

		.item {
			margin-right: 1rem;
			padding: 0.3rem 0.7rem;

			display: flex;
			align-items: center;

			cursor: pointer;

			border-radius: 32px;

			.toPlay {
				font-weight: 500;
				font-weight: 0.875rem;
				margin-right: 0.4rem;
			}

			.verde {
				color: #32b753;
			}

			.cinza {
				color: #8c8e86;
			}
		}
	}
`;

export const Chart = styled.div`
	width: 40%;
	height: 35%;
	position: relative;

	margin-bottom: 0.5rem;

	.legendaInferior {
		width: 100%;

		display: flex;
		justify-content: flex-start;

		font-weight: 700;
		font-size: 0.875rem;

		color: #5c5b5b;

		position: absolute;
		bottom: -0.3rem;
		left: 2rem;
	}

	.legendaEsquerda {
		display: flex;
		justify-content: center;

		font-weight: 700;
		font-size: 0.875rem;

		color: #5c5b5b;

		position: absolute;
		bottom: 3rem;
		left: -2rem;

		transform: rotate(270deg);
	}
`;

export const Chart2 = styled.div`
	width: 40%;
	height: 35%;
	position: relative;

	margin-bottom: 0.5rem;

	.legendaInferior {
		width: 100%;

		display: flex;
		justify-content: flex-start;

		font-weight: 700;
		font-size: 0.875rem;

		color: #5c5b5b;

		position: absolute;
		bottom: -0.3rem;
		left: 2rem;
	}

	.legendaEsquerda {
		display: flex;
		justify-content: center;

		font-weight: 700;
		font-size: 0.875rem;

		color: #5c5b5b;

		position: absolute;
		bottom: 3rem;
		left: -2rem;

		transform: rotate(270deg);
	}
`;

export const Preenchimento = styled.div`
	height: 100%;

	transition: all 0.1s ease-out;

	background-color: ${colors.primary_blue_k_light_1+80};
`;

export const Barra = styled.div`
	height: 5rem;
	width: 55rem;
	background-color: ${colors.neutral_grey_k_10};

	position: relative;

	overflow: hidden;

	border-radius: 1rem;

	.lineMin {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid ${colors.primary_blue_k_main};
		transition: all 0.1s ease-out;
	}

	.lineMax {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid #000;
		transition: all 0.1s ease-out;
	}

	@media (max-width: 1300px) {
		height: 4rem;
		width: 50rem;
	}

	@media (max-width: 1130px) {
		height: 3.8rem;
		width: 45rem;
	}
`;

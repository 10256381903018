import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { ConfigsActions } from "../ducks/configs";
import { RelatorioActions, Types } from "../ducks/relatorios";
import * as Sentry from "@sentry/react";

function* PersonalizarRelatorio({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dat = {
				...payload,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}configs/configs_relatorios_salvar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);

		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == 1) {
				message = current_language?.falha_personalizacao;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			if (response.code == 0) {
				message = current_language?.personalizacao_concluida;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: configs_relatorios_salvar.php", "error");
	} finally {
		yield put(RelatorioActions.personalizar_relatorio_request_cancel());
		yield put(ConfigsActions.config_request());
	}
}

function* Exportar(): any {

	const language: any = yield select((state) => state.configs.language);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			// const dat = {
			//   TOKEN: user_logado.token
			// };
			const requestOptions: any = {
				method: "GET",
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}relatorios/exportar_csv.php?l=${language?.toLowerCase()}&t=${
					user_logado.token
				}`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
	} catch (e) {
	} finally {
	}
}

function* Compartilhar({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dat = {
				...payload,
				TOKEN: user_logado?.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}relatorios/relatorios_pdf.php`,
				requestOptions
			);
			const response: any = await res.json();

			if (payload.txOutput === "download" && response.status === TypesErrorRequest.SUCCESS)
				window.open(response.message);



			return {...response, ...payload};
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: relatorios_pdf.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == -1) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${current_language?.sem_dados_exportar}`;
			}
			if (response.code == 1) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${current_language?.falha_smtp}`;
			}

			if (response.code === 2) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${response?.message}`;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			if (response.code == 0) {
				if (response.message == "Email enviado.") {
					message = current_language?.email_enviado;
				} else {
					message = response.message;
				}
				yield put ( AppActions.set_modal_compartilhamento(false, 0))
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
	} finally {
		yield put(RelatorioActions.set_atribuir_tags_cancel());
	}
}

export function* PersonalizarRelatorioSaga(): any {
	return yield all([
		takeLatest(Types.REQUEST_PERSONALIZAR_RELATORIO, PersonalizarRelatorio),
		takeLatest(Types.SET_COMPARTILHAR, Compartilhar),
		takeLatest(Types.EXPORTAR, Exportar),
	]);
}

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 45vh;
	background-color: white;
	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.protocoloInfo {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.divProtoSugerido {
		display: flex;
		background-color: #E5E5E5;
		justify-content: center;
		min-width: 70px;
		padding-left:7px;
		padding-right:7px;
		border-radius: 10px;
		margin-top: 8px;
		align-items: center;

		.pa {
			color: #394452;
			font-size: 13px;
		}
	}
	.pipe {
		margin-left: 7px;
		margin-right:7px;
		margin-top: 8px;
	}
	.tempoEstimado {
		margin-top: 11px;
		margin-right: 8px;
		align-items: center;
		justify-content: center;


		.Time {
			font-weight: 400;
			color: #B3B3B3;
			font-size: 13px;
		}
	}

	.d {
		display: flex;
		width: 30%;
		height: 40px;
		justify-content: flex-end;
		align-items: center;
	}

	.play {
		cursor: pointer;
		margin-top: 1rem;
		margin-right: 0.5rem;
		width: 1.5rem;
		height: 1.5rem;

		transition: all 0.2s linear;
	}

	.play:hover {
		transform: translateX(5px);
	}

	.protocolo {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 99%;
		height: 5rem;
		padding: 1.7rem;
		margin-top: 1rem;
		padding-left: 1.7rem;
		margin-left:2px;
		border: 1px;
		border-radius:8px;
		box-shadow: 1px 1px 4px #5c5b5b50;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 0.56rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 50%;
		height: 100%;
		border-bottom: 1px solid #e0e0e0;
	}

	.b {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
	}

	.p {
		color: #5c5b5b;
		margin-left: 8px;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.botao {
		color: ${colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		font-size: 0.87rem;
	}

	.p {
		color: #5c5b5b;
	}

	.pa {
		color: #394452;
	}

`;

export const BoxSearch = styled.div`
	display: flex;
	flex-direction: row;
	.input {
		display: flex;
		align-items: center;
		width: 90%;
		height: 2.3rem;
		background-color: #f2f2f2;
		padding-left: 0.75rem;
		padding-right: 28px;
		border-radius: 10px;
		margin-bottom: 1rem;
		margin-top: 1rem;
	}
	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}
	.inputPesquisa {
		height: 90%;
		width: 100%;
		margin-left: 1rem;
		margin-right: 1rem;
		background-color: transparent;
		border: none;
	}

	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}
	.ContentIconFilterList {
		display: flex;
		width: 10%;
		height: 100%;
		align-items: center;
		justify-content: center;
		
		.ContainerIcons {
			width:45; 
			flex-direction: row; 
			display: flex;
		}
		
	}
`;

export const LoadingDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 4rem;
`;
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../Dashboard/ContentDash";



const FaturasController: React.FC = () => {

	const is_open_inadimplente = useSelector(
		(state: any) => state.app.is_open_modal_inadimplente
	);

	return (
		<ViewPort>
			{!!is_open_inadimplente &&
				<Menu/>
			}
			<ContentDash listagem={"fatura"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default FaturasController;

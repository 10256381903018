import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	margin-right: 2.6%;
	padding-top: 3.6rem;
	padding-bottom: 3.6rem;
	padding-left: 3.6rem;
	padding-right: 2rem;
	border-radius: 1.25rem;
	overflow: hidden;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 1.6rem;
	margin-bottom: 1rem;

	.nome {
		font-weight: 500;
		font-size: 1.5rem;

		color: #5c5b5b;
		width: 20rem;
	}

	.bodyHeader {
		width: 100%;

		.hr {
			border-bottom: 1px solid #e0e0e0;
			margin: 0.3rem 0;
		}

		.footerBody {
			display: flex;
			justify-content: flex-end;
		}

		.textHeader {
			font-weight: 400;
			font-size: 1rem;

			color: #8d8d8d;
		}

		.text {
			margin-right: 1rem;
			min-width: 4rem;
		}

		.number {
			width: auto;
			min-width: 5rem;
			display: flex;
			justify-content: flex-end;
		}
	}

	button {
		margin-right: 3rem;
		padding: 0.75rem 3.5rem;

		height: 3.625rem;
		width: 17.625rem;

		border-radius: 0.5rem;
		border: 0;

		border: 1.5px solid #ffffff;

		background: ${colors.primary_blue_k_main};
		color: #ffffff;

		font-weight: 500;
		font-size: 1.125rem;

		transition: all 0.2s linear;
	}

	button:hover {
		color: ${colors.primary_blue_k_main};
		border: 1.5px solid ${colors.primary_blue_k_main};

		background: #ffffff;
	}
`;

export const BodyEdite = styled.div`
	height: 80%;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
	.lineItens {
		display: flex;
		flex-direction: column;

		padding-right: 1.6rem;
	}

	.left {
		display: flex;
	}

	.item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		justify-content: space-between;
		text-decoration: none;
		height: 6rem;
		cursor: pointer;
		p {
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			cursor: pointer;
			margin-left: 1rem;
		}
	}

	.item2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;
		height: 6rem;
		cursor: pointer;
		p {
			cursor: pointer;
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			cursor: pointer;
			margin-left: 1rem;
		}
	}
`;

export const Content = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.labelForInput {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}

	.conteinerImg {
		background-color: #e5e5e5;
		width: 5.3rem;
		height: 5.3rem;
		border-radius: 50%;
		padding: 4.5px;
	}

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.camera {
		display: flex;
		margin-left: 75px;
		width: 45px;
		height: 45px;
		margin-top: -40px;
	}
`;

import styled from "styled-components";
import { colors } from "../../styles/colors";


interface MenuListProps {
	hasDinoBAttery: boolean;
}

export const Container = styled.div`
	width: 15.93rem;
	min-width: 255px;
	height: 100vh;
	min-height: 600px;
	display: flex;
	color: #5c5b5b;
	font-family: "Roboto";
	border-right: 1px solid #e0e0e0;

	@media only screen and (max-width: 1566px) {
		width: 7rem;
		min-width: 110px;
	}
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	min-height: 600px;
	background-color: white;
`;

export const Usuario = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4.5px;
	position: relative;

	.imgForInput {
		border: 5px solid #e5e5e5;
		border-radius: 50%;
		height: 7rem;
		width: 7rem;
		object-fit: cover;

		@media only screen and (max-width: 1566px) {
			height: 5rem;
			width: 5rem;
		}
	}
`;

export const Perfil = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	height: 12%;

	margin-top: 3rem;

	p {
		color: #4d4d4d;
		font-size: 1.125rem;
		font-weight: 500;

		margin-top: 0.8rem;

		text-align: center;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.ContentEvaluatedPatient{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100px;
	}
	
	.ContentDinoBattery{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100px;
	}
	
	.ContentNameAndValue {
		display: flex;
		width: 100%;
		margin-top: 12px;
		flex-direction: row;
	}

	.ContentDinoValue {
		display: flex;
		width: 100%;
		margin-top: 12px;
		flex-direction: row;
	}

	.ServicoPaciente {
		width: 10%;
		font-size: 12px;
		color: #2C3A4B;
		margin-left: 10px;
	}
	.evaluatedPatient {
		width: 90%;
		font-size: 12px;
		color: #2C3A4B;
	}

	.phrase {
		display: flex;
		width: 60%;  
		font-size:12px; 
		height:20px; 	
		color: #2C3A4B;
		margin-left: 15px;
		justify-content: center;
		align-items: center;
		transition: color 0.3s;
		cursor: pointer;

		@media only screen and (max-width: 1567px) {
			display: none;
		}
	};
	.ContentNameAndValue:hover {
    	color: ${colors.primary_blue_k_main}; 
	}
	
	.phraseDino {
		display: flex;
		width: 100%;  
		font-size:12px; 
		height:20px; 	
		color: #2C3A4B;
		padding-left: 0.5rem;
		justify-content: center;
		align-items: center;
		transition: color 0.3s;
		cursor: pointer;

		@media only screen and (max-width: 1567px) {
			display: none;
		}
	};
	.ContentDinoValue:hover {
    	color: ${colors.primary_blue_k_main}; 
	}

	.ContentOnlyBattery{
		display: none;

		@media only screen and (max-width: 1567px) {
			cursor: pointer;
			display: flex;
			width: 100%;
			height: 20px;
			align-items: center;
			flex-direction: column;
		}
		
	}

	
	.tooltip {
		display: none; 
		position: absolute;
		background-color: ${colors.primary_blue_k_dark_2};
		color: #fff;
		padding: 5px;
		border-radius: 5px;
		margin-left: 250px;
		text-align: center;
		width: 200px;
		margin-top: -25px;
		
	}

	.value {
		display: flex;
		width: 30%;
		font-size: 12px;
		color: #2C3A4B;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		transition: color 0.3s;
		cursor: pointer;

		@media only screen and (max-width: 1567px) {
			width: 100%;
		}
	}
	.phrase:hover,
	.value:hover,
	.ContentOnlyBattery:hover,
	.phraseDino:hover,
	.phraseDino.hovered,
	.phrase.hovered,
	.value.hovered,
	.ContentOnlyBattery.hovered {
		color: ${colors?.primary_blue_k_dark_2};
	}
	
	.value:hover .tooltip {
		@media only screen and (max-width: 1567px) {
			display: block;
		}
	}
	
	.ContentOnlyBattery:hover .tooltip {
		@media only screen and (max-width: 1567px) {
			display: block;
			z-index: 99;
			font-size: 12px;
			font-weight: bold;
			cursor: pointer;
			margin-top: 0.1rem;
		}
	}
	
	.ContentProgressBar{
		display: flex;
		width: 100%;
		height: 20px;
		align-items: center;
		flex-direction: column;

		@media only screen and (max-width: 1567px) {
			width: 100%;
		}
		
	}

	.progressBar {
		width: 90%;
        height: 5px;
		margin-top: 5px;
		border-radius: 20px;
        background-color: #ccc; 
	}

	.background{
        height: 5px;
		border-radius: 20px;
        background-color: ${colors.secondary_green_k_main}; 
	}

	a {
		text-decoration: none;
		color: ${colors.primary_blue_k_main};
		font-weight: 500;
	}

	a:hover {
		text-decoration: none;
	}

	@media only screen and (max-height: 885px) {
		margin-top: 2rem;
	}

	@media only screen and (max-width: 1566px) {
		.p {
			display: none;
		}
	}

	@media only screen and (max-width: 1370px) {
		margin-top: 1rem;
	}
`;

export const MenuList = styled.div<MenuListProps>`
	width: 100%;
	height: 40%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	${(props) =>
		props.hasDinoBAttery && `margin-top: 6.8rem;`}

	p {
		font-size: 1rem;
		font-weight: 500;

		display: flex;
		align-items: center;

		color: #5c5b5b;

		padding-left: 1.188rem;
		transition: all 0.2s linear;
	}

	p:hover {
		color: ${colors.primary_blue_k_dark_2};
	}

	a {
		text-decoration: none;

		height: 3rem;

		width: 100%;

		transition: all 0.2s linear;

		display: flex;
		align-items: center;

		cursor: pointer;

		padding-left: 2rem;

		div {
			width: 2rem;

			display: flex;
			justify-content: center;
		}

		:hover {
			text-decoration: none;

			border-left: 0.2rem solid ${colors.primary_blue_k_dark_2};
			color: ${colors.primary_blue_k_dark_2};
		}

		:hover p {
			color: ${colors.primary_blue_k_dark_2};
		}

		:hover svg path {
			fill: ${colors.primary_blue_k_dark_2};
		}

		svg path {
			transition: all 0.2s linear;
		}

		.protocolos {
			display: flex;
			justify-content: flex-end;
		}
	}

	.active {
		p {
			color: ${colors.primary_blue_k_dark_2};
		}

		svg path {
			fill: ${colors.primary_blue_k_dark_2};
		}

		border-left: 0.2rem solid ${colors.primary_blue_k_dark_2};
	}

	@media only screen and (max-height: 885px) {
	${(props) =>
		props.hasDinoBAttery && `margin-top: 6.8rem;`|| `margin-top: 2rem;`}
	}

	@media only screen and (max-height: 750px) {
		${(props) =>
			props.hasDinoBAttery && `margin-top: 6.8rem;`|| `margin-top: 2rem;`}
	}

	@media only screen and (max-width: 1566px) {
		.p {
			display: none;
		}

		a {
			justify-content: center;

			padding-left: 0rem;
		}
	}

	@media only screen and (max-width: 1290px) {
		${(props) =>
			props.hasDinoBAttery && `margin-top: 4.8rem;`|| `margin-top: 2rem;`}
	}

	@media only screen and (max-width: 1370px) and (min-width:1291px) {
		padding-top: 1rem;
		${(props) =>
			props.hasDinoBAttery && `margin-top: 3.8rem;`|| `margin-top: 2rem;`}

		height: 50%;

		a {
			justify-content: center;

			padding-left: 0rem;
		}
	}
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70%;

	height: 23%;

	.bandeira {
		display: flex;
		align-items: center;

		width: 100%;

		margin-bottom: 1rem;

		p {
			margin-left: 0.5rem;

			font-weight: 500;
			font-size: 1.063rem;
		}
	}

	.plano {
		width: 100%;

		font-size: 0.875rem;

		color: #b3b3b3;

		margin-bottom: 1.688rem;
	}

	button {
		padding: 0.75rem 3.5rem;

		border-radius: 0.5rem;
		border: 0;

		border: 1.5px solid #ffffff;

		background: ${colors.primary_blue_k_main};
		color: #ffffff;

		transition: all 0.2s linear;
	}

	button:hover {
		color: ${colors.primary_blue_k_dark_2};
		border: 1.5px solid ${colors.primary_blue_k_dark_2};

		background: #ffffff;
	}

	.sair {
		font-size: 1rem;
		font-weight: 500;
		cursor: pointer;
		color: ${colors.primary_blue_k_main};
		margin-top: 1.7rem;
	}

	.versao {
		margin-top: 0.5rem;

		color: #b3b3b3;

		font-weight: 500;
		font-size: 0.938rem;
	}

	@media only screen and (max-height: 885px) {
		.bandeira {
			margin-bottom: 0.2rem;
		}

		.plano {
			margin-bottom: 1rem;
		}

		.sair {
			margin-top: 1.3rem;
		}

		.versao {
			margin-top: 0.4rem;
		}
	}

	@media only screen and (max-width: 1566px) {
		.p {
			display: none;
		}

		.sair {
			font-size: 12.5px;
		}
	}

	@media only screen and (max-width: 1370px) {
		.plano {
			font-size: 0.75rem;

			margin-bottom: 1rem;
		}

		.sair {
			font-size: 0.8rem;
			font-weight: 700;
			color: ${colors.primary_blue_k_main};
			margin-top: 0rem;
		}
	}
`;

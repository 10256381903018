import React, { useEffect, useState } from "react";

import { Conteiner, Content } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../../../database/txt.interface";
import { CartaoProps } from "../../../../interfaces/cartao.interface";
import { TypesLanguage } from "../../../../utils/types";
import { Link } from "react-router-dom";

import mastercard from "../../../../assets/icons/mastercard.svg";
import cartaoCredito from "../../../../assets/icons/cartaoCredito.svg";
import Editar from "../../../../assets/icons/editar.svg";
import Checkbox from "../../../../assets/icons/check_box.svg";
import CheckboxBlank from "../../../../assets/icons/check_box_blank.svg";
import { CircularProgress } from "@material-ui/core";
import { AppActions } from "../../../../store/ducks/app";
import ToastAlert from "../../../../components/toast";
import { TypesSelectButton } from '..';
import { FinancialActions } from '../../../../store/ducks/financial';
import { UserProps } from '../../../../interfaces/pacientes.interface';


const Confirmacao: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [checkbox, setCheckbox] = useState(false);
	const selecionado = useSelector(
		(state: any) => state.financial.plano
	);
	const tipo = useSelector((state: any) => state.financial.tipo);
	const contrato = useSelector((state: any) => state.financial.contrato);
	const cartao = useSelector((state: any) => state.financial.cartao);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const [aprovado, setAprovado] = useState(false);

	useEffect(() => {
		const texto = language == TypesLanguage.PT
		? "Gostaria%20de%20saber%20mais%20sobre%20o%20Anamnese"
		: language == TypesLanguage.EN
			? "I%20would%20like%20to%20know%20more%20about%20the%20Anamnesis"
			: language == TypesLanguage.ES &&
			"Me%20gustaría%20saber%20más%20sobre%20la%20Anamnesis"

		dispatch(AppActions.set_open_barraSuperior("Confirmacao"));
		if (
			config_servicos.SERVICO_PLANO_NOME === "Free" ||
			config_servicos.SERVICO_PLANO_NOME === "Básico"
		) {
			setTimeout(() => {
				window.open(`https://wa.me/556135423199?text=${texto}`, '_blank');
			}, 3000)
		}
	}, []);

	//Requisição para assinar plano
	async function handleAssinarPlano() {
		if (checkbox) {
			setAprovado(true);
			tipo === TypesSelectButton.PLANOS ?
				dispatch(FinancialActions.set_assinar_plano({
					PLANO_IUGU: selecionado.PLANO_IUGU,
					txEmail: user_logado.USUARIO_EMAIL
				}))
				:
				tipo === TypesSelectButton.PACOTES &&
				dispatch(FinancialActions.set_assinar_item({
					ITEM_ASSINATURA_VALOR: selecionado.ITEM_ASSINATURA_VALOR,
					ITEM_ASSINATURA_TITULO: selecionado.ITEM_ASSINATURA_TITULO_PT,
					ITEM_ASSINATURA_QUANTIDADE: selecionado.ITEM_ASSINATURA_QUANTIDADE,
					ITEM_ASSINATURA_RECORRENCIA: selecionado.ITEM_ASSINATURA_RECORRENCIA
				}))
		}
	}

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	//Muda o estado no checkbox
	const handleCheckbox = () => {
		if (checkbox) {
			setCheckbox(false);
		} else {
			setCheckbox(true);
		}
	};

	//Abre o contrato
	const openContrato = () => {
		const link = document.createElement("a");
		link.href = contrato;
		link.target = "_blank";
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	//Toast
	useEffect(() => {
		if (is_open_toast == true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	//UI
	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			{(config_servicos.SERVICO_PLANO_NOME !== "Free" &&
				config_servicos.SERVICO_PLANO_NOME !== "Básico") ?
				<>
					<h1 className="title">{current_language?.confirm_dado}</h1>

					<Content>
						{tipo === TypesSelectButton.PLANOS ?
							<div key={selecionado.PLANO_DESCRICAO_PT} className="divPlano">
								<div className="divPlanoDetail">
									<div className="titleAndPrice">
										<h3 style={{ marginRight: "6px" }} className="titlePlan">
											{current_language?.plano}
										</h3>
										<h3 className="titlePlan">
											{
												language == TypesLanguage.PT
													? selecionado.PLANO_TITULO_PT
													: language == TypesLanguage.EN
														? selecionado.PLANO_TITULO_EN
														: language == TypesLanguage.ES &&
														selecionado.PLANO_TITULO_ES
											}
										</h3>
										<p className="rs">R$</p>
										<h3 className="price">{selecionado.PLANO_VALOR}0</h3>
										<p className="rs">/{current_language?.mes_palavra}</p>
									</div>
									<p className="detail">
										{
											language == TypesLanguage.PT
												? selecionado.PLANO_DESCRICAO_PT
												: language == TypesLanguage.EN
													? selecionado.PLANO_DESCRICAO_EN
													: language == TypesLanguage.ES &&
													selecionado.PLANO_DESCRICAO_ES
										}
									</p>
								</div>
							</div>
							:
							tipo === TypesSelectButton.PACOTES &&
							<div key={selecionado.ITEM_ASSINATURA_DESCRICAO_PT} className="divPlano">
								<div className="divPlanoDetail">
									<div className="titleAndPrice">
										<h3 className="titlePlan">
											{current_language?.pacote}{' '}
											{
												language == TypesLanguage.PT
													? selecionado.ITEM_ASSINATURA_TITULO_PT
													: language == TypesLanguage.EN
														? selecionado.ITEM_ASSINATURA_TITULO_EN
														: language == TypesLanguage.ES && selecionado.ITEM_ASSINATURA_TITULO_ES
											}
										</h3>
										<p className="rs">R$</p>
										<h3 className="price">{selecionado.ITEM_ASSINATURA_VALOR && selecionado.ITEM_ASSINATURA_VALOR / 100}0</h3>
										<p className="rs">/{current_language?.mes_palavra}</p>
									</div>
									<p className="detail">
										{
											language == TypesLanguage.PT
												? selecionado.ITEM_ASSINATURA_DESCRICAO_PT
												: language == TypesLanguage.EN
													? selecionado.ITEM_ASSINATURA_DESCRICAO_EN
													: language == TypesLanguage.ES && selecionado.ITEM_ASSINATURA_DESCRICAO_ES
										}
									</p>
								</div>
							</div>
						}

						{cartao?.map((card: CartaoProps) => {
							console.log(cartao, 'cartao')
							console.log(card, 'card')
							return (
								<div className="item" key={card.CC_NUMERO}>
									<div className="divSub">
										<h3 className="metodo_pagamento">{current_language?.pagamento_primeiro_cartao}</h3>
										<div className="left">
											<div>
												{card?.CC_NUMERO?.toString().slice(0, 2) >= "51" &&
													card?.CC_NUMERO?.toString().slice(0, 2) <= "55" ? (
													<img src={mastercard} alt="Mastercard" />
												) : (
													<img src={cartaoCredito} alt="Cartão" />
												)}
											</div>
											<div className="info">
												<p className="tituloCard">
													{card?.CC_NUMERO?.toString().slice(0, 2) >= "51" &&
														card?.CC_NUMERO?.toString().slice(0, 2) <= "55"
														? `Mastercard ● ${current_language?.credito}`
														: card?.CC_NUMERO?.toString().slice(0, 1) >= "4"
															? `Visa ● ${current_language?.credito}`
															: `Cartão ● ${current_language?.credito}`}
												</p>
												<p>
													● ● ● ● ● ● ● ●{" "}
													{`${card?.CC_NUMERO?.toString().slice(-4)}`}
												</p>
											</div>
										</div>
									</div>
									<Link className="botaoSelecao" to="metodo_pagamento">
										<img src={Editar} alt="editar" />
										<h2 className="editar">{current_language?.perfil_paciente_editar}</h2>
									</Link>
								</div>
							);
						})}

						<div className="divContrato">
							<img
								className="checkbox"
								onClick={handleCheckbox}
								src={checkbox ? Checkbox : CheckboxBlank}
								alt=""
							></img>
							<p className="declaracao">
								{current_language?.declaro_que_li}{" "}
								{
									<button className="contrato" onClick={openContrato}>
										{current_language?.contrato_servico_elastic}
									</button>
								}{" "}
								{current_language?.concordo_termos}.*
							</p>
						</div>

						<button
							onClick={handleAssinarPlano}
							style={
								checkbox
									? { backgroundColor: "#A21C53", color: "#FCFCFC" }
									: { backgroundColor: "#f2f2f2", color: "gray", cursor: "not-allowed" }
							}
							className="enviar"
						>
							{aprovado == true ? (
								<CircularProgress color="inherit" />
							) : (
								current_language?.enviar
							)}
						</button>
					</Content>
				</>
				:
				<>
					<Content>
						<div className='encaminhar'>
							<svg width="104" height="145" viewBox="0 0 104 145" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M89.2014 48.7495H14.5212C11.0841 48.7495 8.2978 51.5358 8.2978 54.9728V129.653C8.2978 133.09 11.0841 135.876 14.5212 135.876H89.2014C92.6384 135.876 95.4247 133.09 95.4247 129.653V54.9728C95.4247 51.5358 92.6384 48.7495 89.2014 48.7495ZM14.5212 40.4517C6.50134 40.4517 0 46.953 0 54.9728V129.653C0 137.673 6.50134 144.174 14.5212 144.174H89.2014C97.2212 144.174 103.723 137.673 103.723 129.653V54.9728C103.723 46.953 97.2212 40.4517 89.2014 40.4517H14.5212Z" fill="#5C5B5B" />
								<path d="M27.4863 80.3852C27.4863 78.0939 29.3439 76.2363 31.6352 76.2363C33.9266 76.2363 35.7841 78.0939 35.7841 80.3852V90.7575C35.7841 93.0489 33.9266 94.9064 31.6352 94.9064C29.3439 94.9064 27.4863 93.0489 27.4863 90.7575V80.3852Z" fill="#5C5B5B" />
								<path d="M69.4961 80.3852C69.4961 78.0939 71.3536 76.2363 73.645 76.2363C75.9364 76.2363 77.7939 78.0939 77.7939 80.3852V90.7575C77.7939 93.0489 75.9364 94.9064 73.645 94.9064C71.3536 94.9064 69.4961 93.0489 69.4961 90.7575V80.3852Z" fill="#5C5B5B" />
								<path fillRule="evenodd" clipRule="evenodd" d="M58.3871 89.3359C54.0361 90.9775 49.1908 90.0097 47.405 89.3359C46.3331 88.9314 45.1362 89.4725 44.7318 90.5444C44.3273 91.6164 44.8684 92.8132 45.9403 93.2176C48.3034 94.1093 54.2453 95.333 59.8517 93.2176C60.9236 92.8132 61.4647 91.6164 61.0603 90.5444C60.6558 89.4725 59.459 88.9314 58.3871 89.3359Z" fill="#5C5B5B" />
								<path d="M44.6016 30.5983C44.6016 27.4477 47.1557 24.8936 50.3063 24.8936C53.4569 24.8936 56.011 27.4477 56.011 30.5983V43.045C56.011 46.1956 53.4569 48.7497 50.3063 48.7497C47.1557 48.7497 44.6016 46.1956 44.6016 43.045V30.5983Z" fill="#5C5B5B" />
								<path d="M60.1619 33.1914C61.3076 33.1914 62.2363 34.1202 62.2363 35.2659C62.2363 36.4115 61.3076 37.3403 60.1619 37.3403L40.4546 37.3403C39.3089 37.3403 38.3801 36.4115 38.3801 35.2659C38.3801 34.1202 39.3089 33.1914 40.4546 33.1914L60.1619 33.1914Z" fill="#5C5B5B" />
								<path fillRule="evenodd" clipRule="evenodd" d="M49.789 8.2978C45.2062 8.2978 41.4912 12.0129 41.4912 16.5956V19.1842C41.4912 22.0714 42.6381 24.8404 44.6797 26.882L48.0552 30.2575L42.1877 36.1249L38.8123 32.7495C35.2145 29.1517 33.1934 24.2722 33.1934 19.1842V16.5956C33.1934 7.43011 40.6235 0 49.789 0V8.2978Z" fill="#5C5B5B" />
								<path fillRule="evenodd" clipRule="evenodd" d="M49.7853 8.2978C54.368 8.2978 58.0831 12.0129 58.0831 16.5956V19.1842C58.0831 22.0714 56.9361 24.8404 54.8945 26.882L51.5191 30.2575L57.3865 36.1249L60.7619 32.7495C64.3597 29.1517 66.3809 24.2722 66.3809 19.1842V16.5956C66.3809 7.43011 58.9508 0 49.7853 0V8.2978Z" fill="#5C5B5B" />
								<path d="M92.8304 126.541V104.76C67.2701 111.235 40.5325 111.508 14.8454 105.556L11.4082 104.76V126.541L14.9454 125.981C40.7616 121.888 67.0758 122.077 92.8304 126.541Z" fill="#000E4B" />
							</svg>

							{current_language?.encaminhar_cunsultor}
						</div>
					</Content>
				</>
			}
		</Conteiner>
	);
};

export default Confirmacao;

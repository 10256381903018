import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { PacientesProps } from "../../interfaces/pacientes.interface";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import { Conteiner } from "./styles";

interface IcheckBox {
	classe: string;
	avaliador: PacientesProps;
	isChecked: boolean;
	isAround: boolean;
}

const Checkbox: React.FC<IcheckBox> = ({
	classe,
	avaliador,
	isChecked = false,
	isAround = false,
}: any) => {
	const dispatch = useDispatch();
	const HandleClassAvaliador = (val: any) => (event: any) => {
		dispatch(PacAvalActions.set_add_selected_aval(val));
	};

	return (
		<Conteiner>
			<div className="custom-checkbox">
				<input
					id={`checkbox${classe}`}
					defaultChecked={isChecked}
					type="checkbox"
					onClick={HandleClassAvaliador(avaliador)}
					style={{ display: "none" }}
				/>
				<label
					className={isAround ? "label2" : "label"}
					htmlFor={`checkbox${classe}`}
				></label>
			</div>
		</Conteiner>
	);
};

export default memo(Checkbox);

/* eslint-disable eqeqeq */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DinoTriste from "../../../assets/icons/dinoTriste.svg";

import SuperiorFrontal from "../../../assets/assimetria/superiores_frontal.png";
import InferiorFrontal from "../../../assets/assimetria/inferiores_frontal.png";
import InferiorDorsal from "../../../assets/assimetria/inferiores_dorsal.png";
import SuperiorDorsal from "../../../assets/assimetria/superiores_dorsal.png";

/*POSITIVOS FRONTAL SUPERIOR*/
import BICEPS_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/BICEPS_DIREITO.svg";
import BICEPS_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/BICEPS_ESQUERDO.svg";
import ABDOMEN_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/elastic_assimetria-59.svg";
import ABDOMEN_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/elastic_assimetria-73.svg";
import LATERAL_ABDOMEN_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/LATERAL_ABDOMEN_ESQUERDO.svg";
import LATERAL_ABDOMEN_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/LATERAL_ABDOMEN_DIREITO.svg";
import PEITO_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PEITO_DIREITO.svg";
import PEITO_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PEITO_ESQUERDO.svg";
import OMBRO_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/OMBRO_DIREITO.svg";
import OMBRO_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/OMBRO_ESQUERDO.svg";
import MAO_DIREITA_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/MAO_DIREITA.svg";
import MAO_ESQUERDA_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/MAO_ESQUERDA.svg";
import PESCOCO_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PESCOCO_DIREITO.svg";
import PESCOCO_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PESCOCO_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/ANTEBRACO_ANTERIOR_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/ANTEBRACO_ANTERIOR_DIREITO.svg";

/*INTERMEDIARIOS FRONTAL SUPERIOR*/
import BICEPS_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_BICEPS_DIREITO.svg";
import BICEPS_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_BICEPS_ESQUERDO.svg";
import ABDOMEN_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_ELASTIC_ASSIMETRIA_20.svg";
import ABDOMEN_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_ELASTIC_ASSIMETRIA_34.svg";
import LATERAL_ABDOMEN_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_LATERAL_ABDOMEN_ESQUERDO.svg";
import LATERAL_ABDOMEN_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_LATERAL_ABDOMEN_DIREITO.svg";
import PEITO_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_PEITO_DIREITO.svg";
import PEITO_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_PEITO_ESQUERDO.svg";
import OMBRO_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_OMBRO_DIREITO.svg";
import OMBRO_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_OMBRO_ESQUERDO.svg";
import MAO_DIREITA_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/MAO_DIREITA.svg";
import MAO_ESQUERDA_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/MAO_ESQUERDA.svg";
import PESCOCO_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/PESCOCO_DIREITO.svg";
import PESCOCO_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/PESCOCO_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/ANTEBRACO_ANTERIOR_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/ANTEBRACO_ANTERIOR_DIREITO.svg";

/*NEGATIVOS FRONTAL SUPERIOR*/
import BICEPS_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/BICEPS_DIREITO.svg";
import BICEPS_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/BICEPS_ESQUERDO.svg";
import ABDOMEN_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/elastic_assimetria-20.svg";
import ABDOMEN_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/elastic_assimetria-34.svg";
import LATERAL_ABDOMEN_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/LATERAL_ABDOMEN_ESQUERDO.svg";
import LATERAL_ABDOMEN_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/LATERAL_ABDOMEN_DIREITO.svg";
import PEITO_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PEITO_DIREITO.svg";
import PEITO_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PEITO_ESQUERDO.svg";
import OMBRO_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/OMBRO_DIREITO.svg";
import OMBRO_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/OMBRO_ESQUERDO.svg";
import MAO_DIREITA_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/MAO_DIREITA.svg";
import MAO_ESQUERDA_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/MAO_ESQUERDA.svg";
import PESCOCO_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PESCOCO_DIREITO.svg";
import PESCOCO_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PESCOCO_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/ANTEBRACO_ANTERIOR_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/ANTEBRACO_ANTERIOR_DIREITO.svg";

/*NEUTROS FRONTAL SUPERIOR*/
import BICEPS_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/BICEPS_DIREITO.svg";
import BICEPS_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/BICEPS_ESQUERDO.svg";
import ABDOMEN_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/elastic_assimetria-93.svg";
import ABDOMEN_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/elastic_assimetria-107.svg";
import LATERAL_ABDOMEN_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/LATERAL_ABDOMEN_ESQUERDO.svg";
import LATERAL_ABDOMEN_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/LATERAL_ABDOMEN_DIREITO.svg";
import PEITO_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PEITO_ESQUERDO.svg";
import PEITO_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PEITO_DIREITO.svg";
import OMBRO_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/OMBRO_DIREITO.svg";
import OMBRO_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/OMBRO_ESQUERDO.svg";
import MAO_DIREITA_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/MAO ESQUERDA.svg";
import MAO_ESQUERDA_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/MAO ESQUERDA.svg";
import PESCOCO_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PESCOCO_DIREITO.svg";
import PESCOCO_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PESCOCO_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/ANTEBRACO_ANTERIOR_ESQUERDO.svg";
import ANTEBRACO_ANTERIOR_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/ANTEBRACO_ANTERIOR_DIREITO.svg";

/*POSITIVO FRONTAL INFERIOR */
import COXA_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/COXA_DIREITO.svg";
import COXA_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/COXA_ESQUERDO.svg";
import CANELA_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/CANELA_DIREITO.svg";
import CANELA_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/CANELA_ESQUERDO.svg";
import QUADRIL_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/QUADRIL_DIREITO.svg";
import QUADRIL_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/QUADRIL_ESQUERDO.svg";
import COXA_INTERNA_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/COXA_INTERNA_DIREITO.svg";
import COXA_INTERNA_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/COXA_INTERNA_ESQUERDO.svg";
import ILIOPSOAS_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/ILIOPSOAS_DIREITO.svg";
import ILIOPSOAS_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/ILIOPSOAS_ESQUERDO.svg";
import PE_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PE_ESQUERDO.svg";
import PE_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PE_DIREITO.svg";

/*INTERMEDIARIO FRONTAL INFERIOR */
import COXA_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_COXA_DIREITO.svg";
import COXA_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_COXA_ESQUERDO.svg";
import CANELA_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_CANELA_DIREITO.svg";
import CANELA_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_CANELA_ESQUERDO.svg";
import QUADRIL_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_QUADRIL_DIREITO.svg";
import QUADRIL_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_QUADRIL_ESQUERDO.svg";
import COXA_INTERNA_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_COXA_INTERNA_DIREITO.svg";
import COXA_INTERNA_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_COXA_INTERNA_ESQUERDO.svg";
import ILIOPSOAS_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_ILIOPSOAS_DIREITO.svg";
import ILIOPSOAS_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_ILIOPSOAS_ESQUERDO.svg";
import PE_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/PE_ESQUERDO.svg";
import PE_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/PE_DIREITO.svg";

/*NEGATIVOS FRONTAL INFERIOR */
import COXA_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/COXA_DIREITO.svg";
import COXA_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/COXA_ESQUERDO.svg";
import CANELA_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/CANELA_DIREITO.svg";
import CANELA_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/CANELA_ESQUERDO.svg";
import QUADRIL_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/QUADRIL_DIREITO.svg";
import QUADRIL_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/QUADRIL_ESQUERDO.svg";
import COXA_INTERNA_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/COXA_INTERNA_DIREITO.svg";
import COXA_INTERNA_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/COXA_INTERNA_ESQUERDO.svg";
import ILIOPSOAS_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/ILIOPSOAS_DIREITO.svg";
import ILIOPSOAS_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/ILIOPSOAS_ESQUERDO.svg";
import PE_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PE_ESQUERDO.svg";
import PE_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PE_DIREITO.svg";

/*NEUTROS FRONTAL INFERIOR */
import COXA_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/COXA_DIREITO.svg";
import COXA_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/COXA_ESQUERDO.svg";
import CANELA_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/CANELA_DIREITA.svg";
import CANELA_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/CANELA_ESQUERDO.svg";
import QUADRIL_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/QUADRIL_DIREITO.svg";
import QUADRIL_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/QUADRIL_ESQUERDO.svg";
import COXA_INTERNA_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/COXA_INTERNA_DIREITO.svg";
import COXA_INTERNA_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/COXA_INTERNA_ESQUERDO.svg";
import ILIOPSOAS_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/ILIOPSOAS_DIREITO.svg";
import ILIOPSOAS_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/ILIOPSOAS_ESQUERDO.svg";
import PE_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PE_ESQUERDO.svg";
import PE_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PE_DIREITO.svg";

/* POSITIVO DORSAL INFERIOR */
import GLUTEO_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/GLUTEO_ESQUERDO.svg";
import COXA_TRAZ_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/COXA_TRAZ_DIREITO.svg";
import COXA_TRAZ_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/COXA_TRAZ_ESQUERDO.svg";
import PANTURRILHA_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PANTURRILHA_DIREITO.svg";
import PANTURRILHA_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/PANTURRILHA_ESQUERDO.svg";

/* INTERMEDIARIO DORSAL INFERIOR */
import GLUTEO_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_GLUTEO_DIREITO.svg";
import GLUTEO_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_GLUTEO_ESQUERDO.svg";
import COXA_TRAZ_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_COXA_TRAZ_DIREITO.svg";
import COXA_TRAZ_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_COXA_TRAZ_ESQUERDO.svg";
import PANTURRILHA_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_PANTURRILHA_DIREITO.svg";
import PANTURRILHA_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_PANTURRILHA_ESQUERDO.svg";

/* NEGATIVO DORSAL INFERIOR */
import GLUTEO_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/GLUTEO_DIREITO.svg";
import GLUTEO_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/GLUTEO_ESQUERDO.svg";
import COXA_TRAZ_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/COXA_TRAZ_DIREITO.svg";
import COXA_TRAZ_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/COXA_TRAZ_ESQUERDO.svg";
import PANTURRILHA_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PANTURRILHA_DIREITO.svg";
import PANTURRILHA_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/PANTURRILHA_ESQUERDO.svg";

/* NEUTRO DORSAL INFERIOR */
import GLUTEO_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/GLUTEO_DIREITO.svg";
import GLUTEO_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/GLUTEO_ESQUERDO.svg";
import COXA_TRAZ_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/COXA_TRAZ_DIREITO.svg";
import COXA_TRAZ_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/COXA_TRAZ_ESQUERDO.svg";
import PANTURRILHA_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PANTURRILHA_DIREITO.svg";
import PANTURRILHA_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/PANTURRILHA_ESQUERDO.svg";

/*POSITIVOS DORSAL SUPERIOR*/
import TRICEPS_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/TRICEPS_DIREITO.svg";
import TRICEPS_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/TRICEPS_ESQUERDO.svg";
import DORSAL_TRAPEZIO_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/DORSAL_TRAPEZIO_DIREITO.svg";
import DORSAL_TRAPEZIO_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/DORSAL_TRAPEZIO_ESQUERDO.svg";
import TRAPEZIO_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/TRAPEZIO_DIREITO.svg";
import TRAPEZIO_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/TRAPEZIO_ESQUERDO.svg";
import DEULTOIDE_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/DELTOIDE_ESQUERDO.svg";
import DEULTOIDE_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/DELTOIDE_DIREITO.svg";
import ANTEBRACO_POSTERIOR_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/ANTEBRACO_POSTERIOR_ESQUERDO.svg";
import ANTEBRACO_POSTERIOR_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/ANTEBRACO_POSTERIOR_DIREITO.svg";
import OMBRO_DORSAL_ESQUERDO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/OMBRO_DORSAL_ESQUERDO.svg";
import OMBRO_DORSAL_DIREITO_POSITIVO from "../../../assets/assimetria/agrupamentos/positivo/OMBRO_DORSAL_DIREITO.svg";

/*NEGATIVOS DORSAL SUPERIOR*/
import TRICEPS_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/TRICEPS_DIREITO.svg";
import TRICEPS_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/TRICEPS_ESQUERDO.svg";
import DORSAL_TRAPEZIO_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/DORSAL_TRAPEZIO_DIREITO.svg";
import DORSAL_TRAPEZIO_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/DORSAL_TRAPEZIO_ESQUERDO.svg";
import TRAPEZIO_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/TRAPEZIO_DIREITO.svg";
import TRAPEZIO_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/TRAPEZIO_ESQUERDO.svg";
import DEULTOIDE_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/DELTOIDE_ESQUERDO.svg";
import DEULTOIDE_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/DELTOIDE_DIREITO.svg";
import ANTEBRACO_POSTERIOR_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/ANTEBRACO_POSTERIOR_ESQUERDO.svg";
import ANTEBRACO_POSTERIOR_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/ANTEBRACO_POSTERIOR_DIREITO.svg";
import OMBRO_DORSAL_ESQUERDO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/OMBRO_DORSAL_ESQUERDO.svg";
import OMBRO_DORSAL_DIREITO_NEGATIVO from "../../../assets/assimetria/agrupamentos/negativo/OMBRO_DORSAL_DIREITO.svg";

/*NEUTRO DORSAL SUPERIOR*/
import TRICEPS_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/TRICEPS_DIREITO.svg";
import TRICEPS_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/TRICEPS_ESQUERDO.svg";
import DORSAL_TRAPEZIO_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/DORSAL_TRAPEZIO_DIREITO.svg";
import DORSAL_TRAPEZIO_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/DORSAL_TRAPEZIO_ESQUERDO.svg";
import TRAPEZIO_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/TRAPEZIO_DIREITO.svg";
import TRAPEZIO_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/TRAPEZIO_ESQUERDO.svg";
import DEULTOIDE_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/DELTOIDE_ESQUERDO.svg";
import DEULTOIDE_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/DELTOIDE_DIREITO.svg";
import ANTEBRACO_POSTERIOR_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/ANTEBRACO_POSTERIOR_ESQUERDO.svg";
import ANTEBRACO_POSTERIOR_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/ANTEBRACO_POSTERIOR_DIREITO.svg";
import OMBRO_DORSAL_ESQUERDO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/OMBRO_DORSAL_ESQUERDO.svg";
import OMBRO_DORSAL_DIREITO_NEUTRO from "../../../assets/assimetria/agrupamentos/neutro/OMBRO_DORSAL_DIREITO.svg";

/*INTERMEDIARIO DORSAL SUPERIOR*/
import TRICEPS_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_TRICEPS_DIREITO.svg";
import TRICEPS_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_TRICEPS_ESQUERDO.svg";
import DORSAL_TRAPEZIO_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_DORSAL_TRAPEZIO_DIREITO.svg";
import DORSAL_TRAPEZIO_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_DORSAL_TRAPEZIO_ESQUERDO.svg";
import TRAPEZIO_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_TRAPEZIO_DIREITO.svg";
import TRAPEZIO_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_TRAPEZIO_ESQUERDO.svg";
import DEULTOIDE_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_DELTOIDE_ESQUERDO.svg";
import DEULTOIDE_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/INTERMEDIARIO_DELTOIDE_DIREITO.svg";
import ANTEBRACO_POSTERIOR_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/ANTEBRACO_POSTERIOR_ESQUERDO.svg";
import ANTEBRACO_POSTERIOR_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/ANTEBRACO_POSTERIOR_DIREITO.svg";
import OMBRO_DORSAL_ESQUERDO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/OMBRO_DORSAL_ESQUERDO.svg";
import OMBRO_DORSAL_DIREITO_INTERMEDIARIO from "../../../assets/assimetria/agrupamentos/intermediario/OMBRO_DORSAL_DIREITO.svg";

import { TextsProps } from "../../../database/txt.interface";
import {
	AssimetriaPros,
	ExerCucaoProp,
	LegendasTypeProps,
} from "../../../interfaces/assimetria.interface";
import Alert from "../../../assets/icons/alert.svg";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { getPermission } from "../../../utils/funcs";
import {
	TypeAgrupamentoLado,
	TypesCores,
	TypesErrorRequest,
	TypesLanguage,
	TypesPermission,
	TypesSInferiorDORSAL,
	TypesSInferiorFrontal,
	TypesSuperiorDorsal,
	TypesSuperiorFrontal,
} from "../../../utils/types";
import DropDown from "./card";
import { Content } from "./styles";
import { ConfigsActions } from "../../../store/ducks/configs";
import history from "../../../routes/history";
import { AmplitudeLog } from "../../../utils/amplitude";

const Assimetria: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const dispatch = useDispatch();
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);

	const type_current_assimetria: any = useSelector(
		(state: any) => state.relatorios.type_current_assimetria
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const language = useSelector((state: any) => state.configs.language);

	const assimetria: any = assimetrias.filter(
		(ass) => ass.ID_USUARIO == patient.ID_USUARIO
	);

	const [listIQ, setListIQ] = useState<any[]>([]);
	const [currentVisibleId, setCurrentVId] = useState<any>(false);
	const [currentElement, setCurrentElement] = useState<ExerCucaoProp>();
	const [legendas, setLegendas] = useState<LegendasTypeProps>();
	const [handlePermission, setHandlePermission]= useState<boolean>()
	const [permission, setPermission ] = useState(true)

	const arr_options = [
		{
			id: 1,
			value: TypeAgrupamentoLado.SUPERIOR_FRONTAL,
			Agrupamento: current_language?.grupameto_superior,
			imgAgrupamento: SuperiorFrontal,
		},
		{
			id: 2,
			value: TypeAgrupamentoLado.SUPERIOR_DORSAL,
			Agrupamento: current_language?.grupameto_superior,
			imgAgrupamento: SuperiorDorsal,
		},
		{
			id: 3,
			value: TypeAgrupamentoLado.INFERIOR_FRONTAL,
			Agrupamento: current_language?.grupameto_inferior,
			imgAgrupamento: InferiorFrontal,
		},
		{
			id: 4,
			value: TypeAgrupamentoLado.INFERIOR_DORSAL,
			Agrupamento: current_language?.grupameto_inferior,
			imgAgrupamento: InferiorDorsal
		},
	];

	const [current, setCurrent] = useState(arr_options[0]);


	function nivigate_options(id: any) {
		const find = arr_options.find((e: any) => e.id == id);
		if (find) {
			setCurrent(find);
		}
	}

	useEffect(() => {
		dispatch(RelatorioActions.set_current_assimetria(current.value));
	}, [current]);

	useEffect(() => {
		const filter_paci: AssimetriaPros[] = assimetrias.filter(
			(ass) => ass.ID_USUARIO === patient.ID_USUARIO
		);
		if (filter_paci) {
			setLegendas(filter_paci[0]?.LEGENDAS);
		}
	}, []);

	useEffect(()=>{
		const isPermission = () => {
			const permission_access_iq: any = getPermission(
				config_servicos,
				TypesPermission.SERVICO_AVALIACAO_IQ
			)
			return permission_access_iq ;
		}
		setHandlePermission(isPermission)
	},[])

	function _renderSupeFrontal() {
		return (
			<div className="contentBody">
				<img src={SuperiorFrontal} alt="" className="imgBody" />

				<div className="numerosSuperiorFrontal">
					<div className="row1">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_D
										? 1
										: 0,
								}}
								className="OmbroDireito"
							>
								<small>{legendas?.SUPERIOR_FRONTAL.OMBRO?.LABEL_MUSCULO}</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorFrontal.OMBRO && (
										<DropDown
											currentElement={currentElement}
											top={-5}
											left={-42}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_D
										? 1
										: 0,
								}}
								className="PeitoDireito"
							>
								<small>{legendas?.SUPERIOR_FRONTAL.PEITO?.LABEL_MUSCULO}</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorFrontal.PEITO && (
										<DropDown
											currentElement={currentElement}
											top={0.5}
											left={-121}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_E
										? 1
										: 0,
								}}
								className="PeitoEsquerdo"
							>
								<small>{legendas?.SUPERIOR_FRONTAL.PEITO?.LABEL_MUSCULO}</small>
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_E
										? 1
										: 0,
								}}
								className="OmbroEsquerdo"
							>
								<small>{legendas?.SUPERIOR_FRONTAL.OMBRO?.LABEL_MUSCULO}</small>
							</section>
						</main>
					</div>

					<div className="row2">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_D
										? 1
										: 0,
								}}
								className="bicepsDireito"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.BICEPS?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorFrontal.BICEPS && (
										<DropDown
											currentElement={currentElement}
											top={-5}
											left={-36}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>

						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_E
										? 1
										: 0,
								}}
								className="bicepsEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.BICEPS?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row3">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN
										?.LEGENDA_D
										? 1
										: 0,
								}}
								className="abdomenDireito"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorFrontal.LATERAL_ABDOMEN && (
										<DropDown
											currentElement={currentElement}
											top={-3}
											left={-88}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN
										?.LEGENDA_E
										? 1
										: 0,
								}}
								className="abdomenEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row4">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_D ? 1 : 0,
								}}
								className="maoDireita"
							>
								<small>{legendas?.SUPERIOR_FRONTAL.MAO?.LABEL_MUSCULO}</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorFrontal.MAO && (
										<DropDown
											currentElement={currentElement}
											top={-5.5}
											left={100}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_E ? 1 : 0,
								}}
								className="maoEsquerda"
							>
								<small>{legendas?.SUPERIOR_FRONTAL.MAO?.LABEL_MUSCULO}</small>
							</section>
						</main>
					</div>

					<div className="row5">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_D
										? 1
										: 0,
								}}
								className="pescocoDireita"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.PESCOCO?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorFrontal.PESCOCO && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={30}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_E
										? 1
										: 0,
								}}
								className="pescocoEsquerda"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.PESCOCO?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>
					<div className="row6">
					<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_D
										? 1
										: 0,
								}}
								className="anteBracoDireito"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorFrontal.ANTEBRACO && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-2}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_E
										? 1
										: 0,
								}}
								className="anteBracoEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>
				</div>

				{legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={BICEPS_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={BICEPS_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={BICEPS_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={BICEPS_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={BICEPS_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={BICEPS_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={BICEPS_ESQUERDO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.BICEPS?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={BICEPS_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={ABDOMEN_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={ABDOMEN_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={ABDOMEN_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={ABDOMEN_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={ABDOMEN_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={ABDOMEN_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={ABDOMEN_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.ABDOMEN?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={ABDOMEN_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_DIREITO_NEGATIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_DIREITO_POSITIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_DIREITO_NEUTRO}
						alt=""
					/>
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_ESQUERDO_NEGATIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_ESQUERDO_POSITIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.LATERAL_ABDOMEN?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img
						className="musculo"
						src={LATERAL_ABDOMEN_ESQUERDO_NEUTRO}
						alt=""
					/>
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={PEITO_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={PEITO_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={PEITO_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_D == TypesCores.NEUTRO ? (
					<img className="musculo" src={PEITO_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={PEITO_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={PEITO_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={PEITO_ESQUERDO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PEITO?.LEGENDA_E == TypesCores.NEUTRO ? (
					<img className="musculo" src={PEITO_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}
				
				{legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_D == TypesCores.NEUTRO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_ESQUERDO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.ANTEBRACO_ANTERIOR?.LEGENDA_E == TypesCores.NEUTRO ? (
					<img className="musculo" src={ANTEBRACO_ANTERIOR_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={OMBRO_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={OMBRO_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={OMBRO_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_D == TypesCores.NEUTRO ? (
					<img className="musculo" src={OMBRO_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={OMBRO_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={OMBRO_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={OMBRO_ESQUERDO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.OMBRO?.LEGENDA_E == TypesCores.NEUTRO ? (
					<img className="musculo" src={OMBRO_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={MAO_DIREITA_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_D == TypesCores.POSITIVO ? (
					<img className="musculo" src={MAO_DIREITA_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={MAO_DIREITA_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_D == TypesCores.NEUTRO ? (
					<img className="musculo" src={MAO_DIREITA_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={MAO_ESQUERDA_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_E == TypesCores.POSITIVO ? (
					<img className="musculo" src={MAO_ESQUERDA_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={MAO_ESQUERDA_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.MAO?.LEGENDA_E == TypesCores.NEUTRO ? (
					<img className="musculo" src={MAO_ESQUERDA_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={PESCOCO_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={PESCOCO_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={PESCOCO_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={PESCOCO_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={PESCOCO_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={PESCOCO_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={PESCOCO_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_FRONTAL.PESCOCO?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={PESCOCO_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}
			</div>
		);
	}

	function _renderSuperiorDorsal() {
		return (
			<div className="contentBody">
				<img src={SuperiorDorsal} alt="" className="imgBody" />

				<div className="numerosSuperiorDorsal">
					<div className="row1">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_D
										? 1
										: 0,
								}}
								className="trapezioDireito"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorDorsal.TRAPEZIO && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={10}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_E
										? 1
										: 0,
								}}
								className="trapezioEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>
					<div className="row2">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_E
										? 1
										: 0,
								}}
								className="deltoideEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.DELTOIDE?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorDorsal.DELTOIDE && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-42}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_D
										? 1
										: 0,
								}}
								className="deltoideDireito"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.DELTOIDE?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row3">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO
										?.LEGENDA_E
										? 1
										: 0,
								}}
								className="trapezioDorsalEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorDorsal.DORSAL_TRAPEZIO && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-120}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO
										?.LEGENDA_D
										? 1
										: 0,
								}}
								className="trapezioDorsalDireito"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row4">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_D
										? 1
										: 0,
								}}
								className="tricepsDireito"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.TRICEPS?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorDorsal.TRICEPS && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-31}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_E
										? 1
										: 0,
								}}
								className="tricepsEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.TRICEPS?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row5">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_D
										? 1
										: 0,
								}}
								className="anteBracoDireito"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorDorsal.ANTEBRACO && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-2}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_E
										? 1
										: 0,
								}}
								className="anteBracoEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>
					<div className="row6">
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_D
										? 1
										: 0,
								}}
								className="ombroDorsalDireito"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSuperiorDorsal.OMBRO_DORSAL && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-48}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_E
										? 1
										: 0,
								}}
								className="ombroDorsalEsquerdo"
							>
								<small>
									{legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>
				</div>

				{legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={OMBRO_DORSAL_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={OMBRO_DORSAL_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={OMBRO_DORSAL_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={OMBRO_DORSAL_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={OMBRO_DORSAL_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={OMBRO_DORSAL_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={OMBRO_DORSAL_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.OMBRO_DORSAL?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={OMBRO_DORSAL_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={TRICEPS_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={TRICEPS_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={TRICEPS_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={TRICEPS_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={TRICEPS_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={TRICEPS_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={TRICEPS_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={TRICEPS_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}
				
				{legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculo" src={ANTEBRACO_POSTERIOR_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={ANTEBRACO_POSTERIOR_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculo" src={ANTEBRACO_POSTERIOR_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={ANTEBRACO_POSTERIOR_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculo" src={ANTEBRACO_POSTERIOR_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={ANTEBRACO_POSTERIOR_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.ANTEBRACO_POSTERIOR?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={ANTEBRACO_POSTERIOR_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.TRICEPS?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={ANTEBRACO_POSTERIOR_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_DIREITO_NEGATIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_DIREITO_POSITIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_DIREITO_NEUTRO}
						alt=""
					/>
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_ESQUERDO_NEGATIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_ESQUERDO_POSITIVO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DORSAL_TRAPEZIO?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img
						className="musculo"
						src={DORSAL_TRAPEZIO_ESQUERDO_NEUTRO}
						alt=""
					/>
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={TRAPEZIO_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={TRAPEZIO_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={TRAPEZIO_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={TRAPEZIO_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={TRAPEZIO_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={TRAPEZIO_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={TRAPEZIO_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.TRAPEZIO?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={TRAPEZIO_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={DEULTOIDE_DIREITO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={DEULTOIDE_DIREITO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={DEULTOIDE_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={DEULTOIDE_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img className="musculo" src={DEULTOIDE_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculo" src={DEULTOIDE_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculo"
						src={DEULTOIDE_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.SUPERIOR_DORSAL.DELTOIDE?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculo" src={DEULTOIDE_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}
			</div>
		);
	}

	function _renderInferiorFrontal() {
		return (
			<div className="contentBody" style={{paddingTop: 20}}>

				<img src={InferiorFrontal} alt="" className="imgBodyInf"/>

				<div className="numerosInferiorFrontal">
					<div className="row1">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_D
										? 1
										: 0,
								}}
								className="quadrilDireito"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.QUADRIL?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorFrontal.QUADRIL && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-40}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_D
										? 1
										: 0,
								}}
								className="iliopsoasDireito"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorFrontal.ILIOPSOAS && (
										<DropDown
											currentElement={currentElement}
											top={-29}
											left={-134}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_E
										? 1
										: 0,
								}}
								className="iliopsoasEsquerdo"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
						<main>
							<section style={{ opacity: 0 }} className="adutorDireito" />
						</main>
						<main>
							<section style={{ opacity: 0 }} className="adutorEsquerdo" />
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_E
										? 1
										: 0,
								}}
								className="quadrilEsquerdo"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.QUADRIL?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row2">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_D
										? 1
										: 0,
								}}
								className="coxaInternaDireito"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorFrontal.COXA_INTERNA && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-137}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_E
										? 1
										: 0,
								}}
								className="coxaInternaEsquerdo"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row3">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_D ? 1 : 0,
								}}
								className="coxaDireito"
							>
								<small>{legendas?.INFERIOR_FRONTAL.COXA?.LABEL_MUSCULO}</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorFrontal.COXA && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-103}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_E ? 1 : 0,
								}}
								className="coxaEsquerdo"
							>
								<small>{legendas?.INFERIOR_FRONTAL.COXA?.LABEL_MUSCULO}</small>
							</section>
						</main>
					</div>

					<div className="row4">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_D
										? 1
										: 0,
								}}
								className="canelaDireito"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.CANELA?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorFrontal.CANELA && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-88}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_E
										? 1
										: 0,
								}}
								className="canelaEsquerdo"
							>
								<small>
									{legendas?.INFERIOR_FRONTAL.CANELA?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row5">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_D ? 1 : 0,
								}}
								className="peDireito"
							>
								<small>{legendas?.INFERIOR_FRONTAL.PE?.LABEL_MUSCULO}</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorFrontal.PE && (
										<DropDown
											currentElement={currentElement}
											top={-7}
											left={37}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_E ? 1 : 0,
								}}
								className="peEsquerdo"
							>
								<small>{legendas?.INFERIOR_FRONTAL.PE?.LABEL_MUSCULO}</small>
							</section>
						</main>
					</div>
				</div>

				{legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={COXA_DIREITO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={COXA_DIREITO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculoInf" src={COXA_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_D == TypesCores.NEUTRO ? (
					<img className="musculoInf" src={COXA_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={COXA_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={COXA_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={COXA_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.COXA?.LEGENDA_E == TypesCores.NEUTRO ? (
					<img className="musculoInf" src={COXA_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={CANELA_DIREITO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={CANELA_DIREITO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={CANELA_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={CANELA_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={CANELA_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={CANELA_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={CANELA_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.CANELA?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={CANELA_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={QUADRIL_DIREITO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={QUADRIL_DIREITO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={QUADRIL_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={QUADRIL_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={QUADRIL_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={QUADRIL_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={QUADRIL_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.QUADRIL?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={QUADRIL_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_DIREITO_NEGATIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_DIREITO_POSITIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_DIREITO_NEUTRO}
						alt=""
					/>
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_ESQUERDO_NEGATIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_ESQUERDO_POSITIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.COXA_INTERNA?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img
						className="musculoInf"
						src={COXA_INTERNA_ESQUERDO_NEUTRO}
						alt=""
					/>
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={ILIOPSOAS_DIREITO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={ILIOPSOAS_DIREITO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={ILIOPSOAS_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={ILIOPSOAS_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculoInf"
						src={ILIOPSOAS_ESQUERDO_NEGATIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculoInf"
						src={ILIOPSOAS_ESQUERDO_POSITIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={ILIOPSOAS_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_FRONTAL.ILIOPSOAS?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={ILIOPSOAS_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={PE_DIREITO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_D == TypesCores.POSITIVO ? (
					<img className="musculoInf" src={PE_DIREITO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculoInf" src={PE_DIREITO_INTERMEDIARIO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_D == TypesCores.NEUTRO ? (
					<img className="musculoInf" src={PE_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={PE_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_E == TypesCores.POSITIVO ? (
					<img className="musculoInf" src={PE_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img className="musculoInf" src={PE_ESQUERDO_INTERMEDIARIO} alt="" />
				) : legendas?.INFERIOR_FRONTAL.PE?.LEGENDA_E == TypesCores.NEUTRO ? (
					<img className="musculoInf" src={PE_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}
			</div>
		);
	}

	function _renderInferiorDorsal() {
		return (
			<div className="contentBody">
				<img src={InferiorDorsal} alt="" className="imgBodyInf" />

				<div className="numerosInferiorDorsal">
					<div className="row1">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_E
										? 1
										: 0,
								}}
								className="gluteoEsquerdo"
							>
								<small>{legendas?.INFERIOR_DORSAL.GLUTEO?.LABEL_MUSCULO}</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorDORSAL.GLUTEO && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-104}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_D
										? 1
										: 0,
								}}
								className="gluteoDireito"
							>
								<small>{legendas?.INFERIOR_DORSAL.GLUTEO?.LABEL_MUSCULO}</small>
							</section>
						</main>
					</div>

					<div className="row2">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_E
										? 1
										: 0,
								}}
								className="coxaEsquerdo"
							>
								<small>
									{legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorDORSAL.COXA_TRAZ && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-104}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>

						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_D
										? 1
										: 0,
								}}
								className="coxaDireito"
							>
								<small>
									{legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>

					<div className="row3">
						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_E
										? 1
										: 0,
								}}
								className="panturrilhaEsquerdo"
							>
								<small>
									{legendas?.INFERIOR_DORSAL.PANTURRILHA?.LABEL_MUSCULO}
								</small>
								{currentVisibleId &&
									currentVisibleId === TypesSInferiorDORSAL.PANTURRILHA && (
										<DropDown
											currentElement={currentElement}
											top={-8}
											left={-114}
											side="rigth"
											maior={
												currentElement &&
												currentElement?.EXECUCOES[0]?.MAXEXEC_D >=
													currentElement?.EXECUCOES[0]?.MAXEXEC_E
													? currentElement?.EXECUCOES[0].MAXEXEC_D
													: currentElement?.EXECUCOES[0].MAXEXEC_E
											}
										/>
									)}
							</section>
						</main>

						<main>
							<section
								style={{
									opacity: !!legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_D
										? 1
										: 0,
								}}
								className="panturrilhaDireito"
							>
								<small>
									{legendas?.INFERIOR_DORSAL.PANTURRILHA?.LABEL_MUSCULO}
								</small>
							</section>
						</main>
					</div>
				</div>

				{legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_D == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={GLUTEO_DIREITO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={GLUTEO_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={GLUTEO_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_D == TypesCores.NEUTRO ? (
					<img className="musculoInf" src={GLUTEO_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_E == TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={GLUTEO_ESQUERDO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={GLUTEO_ESQUERDO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={GLUTEO_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.GLUTEO?.LEGENDA_E == TypesCores.NEUTRO ? (
					<img className="musculoInf" src={GLUTEO_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img className="musculoInf" src={COXA_TRAZ_DIREITO_NEGATIVO} alt="" />
				) : legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img className="musculoInf" src={COXA_TRAZ_DIREITO_POSITIVO} alt="" />
				) : legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={COXA_TRAZ_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={COXA_TRAZ_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculoInf"
						src={COXA_TRAZ_ESQUERDO_NEGATIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculoInf"
						src={COXA_TRAZ_ESQUERDO_POSITIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={COXA_TRAZ_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.COXA_TRAZ?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={COXA_TRAZ_ESQUERDO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_D ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculoInf"
						src={PANTURRILHA_DIREITO_NEGATIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_D ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculoInf"
						src={PANTURRILHA_DIREITO_POSITIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_D ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={PANTURRILHA_DIREITO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_D ==
				  TypesCores.NEUTRO ? (
					<img className="musculoInf" src={PANTURRILHA_DIREITO_NEUTRO} alt="" />
				) : (
					<></>
				)}

				{legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_E ==
				TypesCores.NEGATIVO ? (
					<img
						className="musculoInf"
						src={PANTURRILHA_ESQUERDO_NEGATIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_E ==
				  TypesCores.POSITIVO ? (
					<img
						className="musculoInf"
						src={PANTURRILHA_ESQUERDO_POSITIVO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_E ==
				  TypesCores.INTERMEDIARIO ? (
					<img
						className="musculoInf"
						src={PANTURRILHA_ESQUERDO_INTERMEDIARIO}
						alt=""
					/>
				) : legendas?.INFERIOR_DORSAL.PANTURRILHA?.LEGENDA_E ==
				  TypesCores.NEUTRO ? (
					<img
						className="musculoInf"
						src={PANTURRILHA_ESQUERDO_NEUTRO}
						alt=""
					/>
				) : (
					<></>
				)}
			</div>
		);
	}
	

	useEffect(()=> {
		
		const CALCULO_IQ = assimetria[0]?.CALCULO_IQ;
		setListIQ(CALCULO_IQ);
	},[assimetrias])

	const handleInformationAgrupamento = (
		assimetria: any, 
		typeAgrupamento: string, 
		index?: number
	) => {

		dispatch(AppActions.set_number_grafics(false));
		
		const filter_agrupamento = assimetria[0]?.LISTAS[typeAgrupamento]
			? assimetria[0]?.LISTAS[typeAgrupamento]
			: [];

		const array_history: any = [];

		if (filter_agrupamento.length > 0) {
			filter_agrupamento.forEach((el: any, index: any) => {
				const arr_d: any = [];
				const arr_e: any = [];
				el.EXECUCOES.slice(0, 3)?.forEach((e: any) => {
					const datD = {
						x: moment(e.DATA).format("DD/MM/YY"),
						y: e.MAXEXEC_D,
						position: "D",
						color: e.LEGENDA_ASSIMETRIA,
						maior: e.MAXEXEC_E > e.MAXEXEC_D ? e.MAXEXEC_E : e.MAXEXEC_D,
						valor_e: e.MAXEXEC_E,
					};
					const datE = {
						x: moment(e.DATA).format("DD/MM/YY"),
						y: e.MAXEXEC_E,
						position: "E",
						color: e.LEGENDA_ASSIMETRIA,
						maior: e.MAXEXEC_E > e.MAXEXEC_D ? e.MAXEXEC_E : e.MAXEXEC_D,
						valor_d: e.MAXEXEC_D,
					};
					arr_d.push(datD);
					arr_e.push(datE);
				});
				array_history.push({
					name_exer:
						language == TypesLanguage.PT
							? el?.EXERCICIO_TITULO_PT
							: language == TypesLanguage.EN
							? el?.EXERCICIO_TITULO_EN
							: el?.EXERCICIO_TITULO_ES,
					arr_d: arr_d,
					arr_e: arr_e,
					filter_agrupamento: filter_agrupamento[index]?.EXECUCOES,
				});

			});
			
			if(index){
				!getPermission(
					config_servicos_array,
					TypesPermission.SERVICO_REL_ASSIMETRIA,
					dispatch
				)
					? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
					: (
						dispatch(RelatorioActions.set_exercicios_historicos(filter_agrupamento))
						&& 
						dispatch(RelatorioActions.set_age(4)) 
					  )
			}

		}else if(index && filter_agrupamento.length == 0 ){
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: current_language?.sem_historico_assimetria_grupamento,
				})
			)
		}

		return filter_agrupamento
	};

	

	function setInfoCard(ID_EXERCICIO: number) {

		const typeAgrupamentos = [
			TypeAgrupamentoLado.SUPERIOR_FRONTAL,
			TypeAgrupamentoLado.SUPERIOR_DORSAL,
			TypeAgrupamentoLado.INFERIOR_FRONTAL,
			TypeAgrupamentoLado.INFERIOR_DORSAL
		];
		
		const itensFiltrados: any[] = [];

		typeAgrupamentos.forEach(type => {

		  const itemFiltrado = assimetria[0]?.LISTAS[type].find((item: any) => item.ID_EXERCICIO === ID_EXERCICIO);

		  if (itemFiltrado) {
			itensFiltrados.push(itemFiltrado);
		  }
		  
		});
		
		if (itensFiltrados[0]) {
			setCurrentVId(null);
			setTimeout(() => {
				setCurrentVId(itensFiltrados[0].MUSCULO);
				setCurrentElement(itensFiltrados[0]);
			}, 50);
		}
	}

	const navigationUpgrade = ()=> {
		history.push("/upgrade")
	}

	useEffect(()=>{
		const permissionAsymmetry: any =  getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_ASSIMETRIA
		)
		if(!permissionAsymmetry){
			setPermission(false)
			dispatch(ConfigsActions.set_title_modal_upgrade(true))
		}else{
			dispatch(ConfigsActions.set_title_modal_upgrade(false))
		}
	
	},[])

	const config_servicos_array: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);


	return (
		<Content>
			{
				permission ? 
					<>
						{arr_options?.map((item, index)=>(
							<div 
								key={index}
								className="bodyAndTable" 
							>
								<div className="body">
									<div 
										className="btnShare" 
										onClick={()=>{
											handleInformationAgrupamento(
												assimetria, 
												index == 0 
													? TypeAgrupamentoLado.SUPERIOR_FRONTAL
													: index == 1
														? TypeAgrupamentoLado.SUPERIOR_DORSAL
														: index == 2
															? TypeAgrupamentoLado.INFERIOR_FRONTAL
															: TypeAgrupamentoLado.INFERIOR_DORSAL,
												index + 1, 
											)
											AmplitudeLog('historico_assimetria')
										}}
									>
										<svg
											className="iconHistory"
											width="19"
											height="16"
											viewBox="0 0 19 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M10.8571 0C6.36048 0 2.71429 3.58222 2.71429 8H0L3.51952 11.4578L3.58286 11.5822L7.2381 8H4.52381C4.52381 4.56 7.35571 1.77778 10.8571 1.77778C14.3586 1.77778 17.1905 4.56 17.1905 8C17.1905 11.44 14.3586 14.2222 10.8571 14.2222C9.11095 14.2222 7.52762 13.52 6.38762 12.3911L5.10286 13.6533C6.57762 15.1022 8.60429 16 10.8571 16C15.3538 16 19 12.4178 19 8C19 3.58222 15.3538 0 10.8571 0ZM9.95238 4.44444V8.88889L13.8248 11.1467L14.4762 10.0711L11.3095 8.22222V4.44444H9.95238Z"
												fill="#00A377"
											/>
										</svg>
										{current_language?.ver_histórico}
									</div>
									
									{
										item?.value === TypeAgrupamentoLado.SUPERIOR_DORSAL ? (
											_renderSuperiorDorsal()
										) : item?.value ===
										TypeAgrupamentoLado.SUPERIOR_FRONTAL ? (
											_renderSupeFrontal()
										) : item?.value ===
										TypeAgrupamentoLado.INFERIOR_DORSAL ? (
											_renderInferiorDorsal()
										) : item?.value ===
										TypeAgrupamentoLado.INFERIOR_FRONTAL ? (
											_renderInferiorFrontal()
										) : (
											<></>
										)
									}

								
								</div>
								<div className="divTable">
									<div className="contentTable">
										{item?.value === TypeAgrupamentoLado.SUPERIOR_DORSAL ? (
											<p className="title">
												{" "}
												{current_language?.agrupamentos_superiores_dorsal}{" "}
											</p>
										) : item?.value ===
										TypeAgrupamentoLado.SUPERIOR_FRONTAL ? (
											<p className="title">
												{" "}
												{current_language?.agrupamentos_superiores_frontal}{" "}
											</p>
										) : item?.value ===
										TypeAgrupamentoLado.INFERIOR_DORSAL ? (
											<p className="title">
												{" "}
												{current_language?.agrupamentos_inferiores_dorsal}{" "}
											</p>
										) : (
											<p className="title">
												{" "}
												{current_language?.agrupamentos_inferiores_frontal}{" "}
											</p>
										)}

										{handleInformationAgrupamento(assimetria, item?.value)?.length == 0 && (
											<div className="avisoDiv">
												<p className="avisoDados">
													{current_language?.sem_dados_suficientes}
												</p>
												<p className="avisoFuncional">
													Exercícios realizados em modo funcional não são contabilizados
													no relatório de assimetria
												</p>
												<img src={Alert} alt="key" className="icon" />
											</div>
										)}
										<div className="ContentTabel">
											{handleInformationAgrupamento(assimetria, item?.value)?.map((item:any) => (
												<div
													key={item.ID_EXERCICIO}
													onClick={() => {
														!getPermission(
															config_servicos,
															TypesPermission.SERVICO_REL_ASSIMETRIA,
															dispatch
														)
															? dispatch(
																	AppActions.set_modal_upgrade_funcionalidade(true)
															)
															: setInfoCard(item.ID_EXERCICIO);
													}}
												>
													<table className="table" >
														<tbody>
															<tr className="tr">
																<td>
																	<div className="number">{item.LABEL_EXERCICIO}</div>
																</td>
																<td className="name">
																	{" "}
																	{language == TypesLanguage.PT
																		? item?.EXERCICIO_TITULO_PT
																		: language == TypesLanguage.EN
																		? item?.EXERCICIO_TITULO_EN
																		: item?.EXERCICIO_TITULO_ES}
																</td>
																<td
																	className="percentage"
																	style={{
																		color:
																			item?.EXECUCOES[0]?.LEGENDA_ASSIMETRIA ==
																			TypesCores.POSITIVO
																				? "#39E664"
																				: item?.EXECUCOES[0]?.LEGENDA_ASSIMETRIA ==
																				TypesCores.INTERMEDIARIO
																				? "#EBB909"
																				: "#E50000",
																	}}
																>
																	{item.EXECUCOES[0].ASSIMETRIA_VALOR}
																</td>
															</tr>
														</tbody>
													</table>
													<hr className="hr" />
												</div>
											))}

										</div>
									</div>
									<div className="containerLegenda">
										<div className="ContentInfo">
											<div className="danger"/>
											<p className="TextLegenda">{current_language?.alta_chance_lesao}</p>

										</div>
										<div className="ContentInfo">
											<div className="alert"/>
											<p className="TextLegenda">{current_language?.media_chance_lesao}</p>

										</div>
										<div className="ContentInfo">
											<div className="good"/>
											<p className="TextLegenda">{current_language?.baixa_chance_lesao}</p>

										</div>
										<div className="ContentInfo">
											<div className="dominantMuscle"/>
											<p className="TextLegenda">{current_language?.musculo_dominante}</p>

										</div>
									</div>
									{ item?.value == TypeAgrupamentoLado?.INFERIOR_FRONTAL &&
										<div className="divIq">
											<div className="divTitle">
												<p className="titleIndiceIQ">{current_language?.execucao_indice_iq}</p>
											</div>
										
											{handlePermission 
												?
													<>	
														{listIQ?.length > 0
															?
																<div className="ContainerInfos">
																	{
																		listIQ?.map((item, index)=>{
																			return (
																				<>
																					<div key={index}>
																						<div className="table">
																							{index == 0 
																								? 
																									<>	
																										<div className="ContentTitles"
																											style={{
																												borderBottomColor: '#001574',
																												borderBottomWidth: 0.1
																											}}
																										>
																											<div className="indiceiq">
																												<p className="title">
																													{ current_language?.execucao_indice_iq }
																												</p>
																											</div>
																											<div className="valorDireito">
																												<p className="title">
																													{current_language?.iq_direito}:
																												</p>
																												<p	
																													className="values"
																													style={{
																														fontWeight: 'bold',
																														color:
																														item?.VALOR_DIREITO &&
																														parseInt(item?.VALOR_DIREITO) > 50
																															? "green"
																															: "red",
																													}}
																												>
																													{item?.VALOR_DIREITO}
																												</p>
																											</div>
																											<div className="ultimaAvaliacao">
																												<p className="title">
																													{current_language?.ultima_avaliacao}
																												</p>
																											</div>
																											<div 
																												className="valorEsquerdo"
																											>	
																												<p className="title">
																													{current_language?.iq_esquerdo}:
																												</p>
																												<p 
																													className="values"
																													style={{
																														fontWeight: 'bold',
																														color:
																														item?.VALOR_ESQUERDO &&
																														parseInt(item?.VALOR_ESQUERDO) > 50
																															? "green"
																															: "red",
																													}}
																												>
																													{item?.VALOR_ESQUERDO}
																												</p>
																											</div>
																										</div>
																									</>
																								:	
																									<>
																										<div className="indiceiq"/>

																										<div 
																											className="valorDireito"
																										>	
																											{current_language?.iq_direito}:
																											<p
																												style={{
																													fontWeight: 'bold',
																													color:
																													item?.VALOR_DIREITO &&
																													parseInt(item?.VALOR_DIREITO) > 50
																														? "green"
																														: "red",
																												}}
																											>
																												{item?.VALOR_DIREITO}
																											</p>
																										</div>
																										<div className="data"
																											style={{
																												width: '25%'
																											}}
																										>
																											{item?.DATA}
																										</div>
																										<div 
																											className="valorEsquerdo"
																										>	
																											{current_language?.iq_esquerdo}:
																											<p
																												style={{
																													fontWeight: 'bold',
																													color:
																													item?.VALOR_ESQUERDO &&
																													parseInt(item?.VALOR_ESQUERDO) > 50
																														? "green"
																														: "red",
																												}}
																											>
																												{item?.VALOR_ESQUERDO}
																											</p>
																										</div>
																									</>
																							}
																						</div>
																					</div>
																				</>
																			)
																		})
																	}
																</div>
															:<p className="nenhumIQ">{current_language?.nenhuma_info_iq}</p>
														}
													</>
												: <p className="modalUpgrade">{current_language?.modal_upgrade_funcionalidade_indisponivel}</p>
											}
											<div className="divInfoIQ">
												<p className="infoIQ">{current_language?.alta_chance_de_lesao}</p>
												<p className="infoIQ">{current_language?.media_chance_de_lesao}</p>
												<p className="infoIQ">{current_language?.baixa_chance_de_lesao}</p>
											</div>
										</div>

									}
								</div>
						
							</div>
 
						 ))

						}
					</>
				: 
				<div className="upgrade" >
					<img src={DinoTriste} alt="" />
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel}</p>
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel2}</p>
					<button className="buttonUpgrade" onClick={navigationUpgrade}>{current_language?.menu_lateral_upgrade}</button> 	
				</div> 
			}
		</Content>
	);
};

export default Assimetria;

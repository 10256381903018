import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Perfil from "../../assets/icons/perfil.svg";
import { UserProps } from "../../interfaces/pacientes.interface";
import { Content } from "./styles";

const FotoPerfilEditar: React.FC = () => {
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const user_editation: UserProps = useSelector((state: any)=> state?.auth?.data_user)

	const [img, setImg] = useState<any>(user_editation?.USUARIO_FOTO);
	
	useEffect(()=>{
		setImg(user_editation?.USUARIO_FOTO)
	},[user_editation])

	return (
		<Content className="DivInput">
			<label htmlFor="img" className="labelForInput">
				<div className="conteinerImg">
					<img src={img ? img : Perfil} alt="" className="imgForInput" />
				</div>
			</label>
		</Content>
	);
};

export default FotoPerfilEditar;

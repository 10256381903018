import {
	createStyles,
	FormControl,
	makeStyles,
	MenuItem,
	Select,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as V from "victory";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { EvolucaoProps, ExecucaoProps } from "../../../interfaces/execucao.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { ConfigsActions } from "../../../store/ducks/configs";
import { getGroupingSide, getPermission } from "../../../utils/funcs";
import {
	EXECUCAO_TIPO,
	TypesLanguage,
	TypesPermission,
} from "../../../utils/types";
import { Item } from "../../Paciente/Informacoes/styles";
import NenhumRelatorio from "../NenhumRelatorio";
import { Charts, Content, Exercice, ForcaMaxima, ForcaMed } from "./styles";
import { colors } from "../../../styles/colors";

export interface ExerAfterListProps {
	EXERCICIO_NOME: string;
	ID_EXERCICIO: number;
	MODO: any;
}

const Evolucao: React.FC = () => {
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const [ permission, setPermission ] = useState(true)

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			button: {
				display: "block",
				marginTop: theme.spacing(2),
				border: "none",
				fontSize: 25,
			},
			formControl: {
				margin: theme.spacing(1),
				border: "none",
				fontSize: 25,
			},
			select: {
				border: 0,
				fontSize: 20,
				fontWeight: "bold",
				fontStyle: "normal",
				color: "#5C5B5B",
			},
		})
	);

	const sharedAxisStyles = {
		axis: {
			stroke: "transparent",
		},
		axisLabel: {
			fontSize: 11,
			padding: 35,
			fontFamily: "roboto",
		},
		tickLabels: {
			fill: "#5C5B5B",
			fontSize: 11,
			fontFamily: "roboto",
		},
	};

	const classes = useStyles();
	const [age, setAge] = React.useState<string>();
	const [open, setOpen] = React.useState(false);

	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const [finalExers, setFinalExers] = useState<Exercicios2Props[]>([]);
	const [finalExersRelatorios, setFinalExersRelatorios] = useState<
		ExerAfterListProps[]
	>([]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setAge(event.target.value as string);
		setCurrentIndex(0);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		!getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_EVOLUCAO,
			dispatch
		)
			? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
			: setOpen(true);
	};

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);

	const opAvalFunci = useSelector((state: any) => state.relatorios.opAvalFunci);

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const [currentIndex, setCurrentIndex] = useState(0);

	function execucoesUsuario(execucoes: ExecucaoProps) {
		return execucoes.ID_USUARIO === paciente.ID_USUARIO;
	}
	let execfiltered = execucoes.filter(execucoesUsuario);

	useEffect(()=>{
		const permissionEvolution: any =  getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_EVOLUCAO
		)
		if(!permissionEvolution){
			setPermission(false)
			dispatch(ConfigsActions.set_title_modal_upgrade(true))
		}else{
			dispatch(ConfigsActions.set_title_modal_upgrade(false))
		}
	
	},[])

	function execucoesType(execucoes: ExecucaoProps) {
		if (opAvalFunci == EXECUCAO_TIPO.AVALIACAO) {
			return execucoes.EXECUCAO_TIPO === "a";
		} else {
			return execucoes.EXECUCAO_TIPO === "f";
		}
	}
	execfiltered = execfiltered.filter(execucoesType);

	function execucoesExercicio(execucoes: ExecucaoProps) {
		return execucoes.ID_EXERCICIO + execucoes.EXECUCAO_MODO == age;
	}
	execfiltered = execfiltered.filter(execucoesExercicio);

	const maximas: any = [];
	let E_maximas = execfiltered.length + 1;

	function ExtraiMaximas(execucoes: ExecucaoProps, index: any) {
		E_maximas = E_maximas - 1;
		maximas.push({
			y: execucoes.EXECUCAO_EXECMAX,
			x: `E${E_maximas}: ${moment(execucoes.EXECUCAO_EXECUTADA).format(
				"DD/MM/YY"
			)}`,
			i: execucoes,
		});
	}
	execfiltered.filter(ExtraiMaximas);
	maximas.reverse();

	const medias: any = [];
	let E_medias = execfiltered.length + 1;
	function ExtraiMedias(execucoes: ExecucaoProps, index: any) {
		E_medias = E_medias - 1;
		medias.push({
			y: execucoes.EXECUCAO_EXECMED,
			x: `E${E_medias}: ${moment(execucoes.EXECUCAO_EXECUTADA).format(
				"DD/MM/YY"
			)}`,
			i: execucoes,
		});
	}
	execfiltered.filter(ExtraiMedias);
	medias.reverse();

	const datas: any = [];
	function ExtraiDatas(execucoes: ExecucaoProps, index: number) {
		datas.push(`${moment(execucoes.EXECUCAO_EXECUTADA).format("DD/MM/YY")}`);
	}
	execfiltered.filter(ExtraiDatas);

	const dadosMaxima = {
		y: maximas,
	};

	const dadosMedia = {
		y: medias,
	};

	useEffect(() => {
		dispatch(AppActions.set_number_grafics(execfiltered.length));
		const ids: any = [];
		const modosForExer: any = {};
		execucoes.forEach((e) => {
			if (e.ID_USUARIO == paciente.ID_USUARIO) {
				ids.push(e.ID_EXERCICIO);
				modosForExer[`${e.ID_EXERCICIO}-${e.EXECUCAO_MODO}`] = e.EXECUCAO_MODO;
			}
		});

		const filter = exercicios.filter((e) => {
			return ids.includes(e.ID_EXERCICIO);
		});

		let exers: any = [];
		filter.forEach((exer) => {
			let pro: any;
			for (pro in modosForExer) {
				if (exer.ID_EXERCICIO == pro.split("-")[0]) {
					const filt: any = exers.find(
						(ex: any) => ex.ID_EXERCICIO == exer.ID_EXERCICIO
					);
					if (filt) {
						const add = {
							...exer,
							modo: [...filt.modo, modosForExer[pro]],
						};
						exers = exers.filter(
							(ex: any) => ex.ID_EXERCICIO != exer.ID_EXERCICIO
						);
						exers.push(add);
					} else {
						exers.push({
							...exer,
							modo: [modosForExer[pro]],
						});
					}
				}
			}
		});
		setFinalExers(exers);
	}, []);

	useEffect(() => {
		const finalExers2: any = [];		

		function ChangeType() {
			if(opAvalFunci == "AVALIACAO"){
				return "a"
			}else{
				return "f"
			}
		}
		
		finalExers?.forEach((exer: any, index) => {
			if (exer.EXERCICIO_EXCLUIDO == false) {
				if (
					execucoes.find(
						(item) => 
							item.ID_EXERCICIO == exer.ID_EXERCICIO &&
							item.EXECUCAO_EXCLUIDA == false &&
							item.EXECUCAO_TIPO == ChangeType()
					)
				) {
					exer?.modo?.forEach((e: string) => {
						finalExers2.push({
							EXERCICIO_NOME:
								language == TypesLanguage.PT
									? finalExers[index]?.EXERCICIO_TITULO_PT
									: language == TypesLanguage.EN
									? finalExers[index]?.EXERCICIO_TITULO_EN
									: finalExers[index]?.EXERCICIO_TITULO_ES,
							ID_EXERCICIO: exer?.ID_EXERCICIO,
							MODO: e[0] !== null ? e[0] : "",
						});
					});
				}
			}
		});
		setAge(finalExers2[0]?.ID_EXERCICIO + finalExers2[0]?.MODO);
		setFinalExersRelatorios(finalExers2);
	}, [finalExers, opAvalFunci]);

	//Faz a divisão das execucoes em partes de até 5 execucoes

	const chunksize = 4;
	const max_force_chunks: any = [];
	const medium_force_chunks: any = [];

	dadosMaxima.y.forEach((item: any) => {
		if (
			!max_force_chunks.length ||
			max_force_chunks[max_force_chunks.length - 1].length == chunksize
		)
			max_force_chunks.push([]);

		max_force_chunks[max_force_chunks.length - 1].push(item);
	});

	dadosMedia.y.forEach((item: any) => {
		if (
			!medium_force_chunks.length ||
			medium_force_chunks[medium_force_chunks.length - 1].length == chunksize
		)
			medium_force_chunks.push([]);
		medium_force_chunks[medium_force_chunks.length - 1].push(item);
	});

	//Faz a troca de indices das execucoes
	const all_index = max_force_chunks.length;

	useEffect(()=>{
		if(opAvalFunci == EXECUCAO_TIPO.FUNCIONAL || EXECUCAO_TIPO.AVALIACAO ){
			setCurrentIndex(0)
		}
	},[opAvalFunci])

	const SpendReport = (increasingORdecreasing: string) => {
		if (increasingORdecreasing === "increasing") {
			if (all_index != currentIndex + 1) {
				setCurrentIndex(currentIndex + 1);
			}
		} else {
			if (currentIndex == 0) {
			} else {
				setCurrentIndex(currentIndex - 1);
			}
		}
	};

	function _renderGrafic(dados: any) {
		return (
			<div className="Chart">
				<V.VictoryChart height={230}>
					<V.VictoryLine
						style={{
							data: { stroke: colors.blue_k_light_2 },
							parent: { border: "1px solid #ccc" },
						}}
						data={dados}
					/>
					<V.VictoryScatter
						data={dados}
						size={7}
						style={{
							data: {
								fill: colors.blue_k_light_2,
							},
							labels: {
								fontFamily: "roboto",
							},
						}}
						labels={({ datum }) => {
							return datum.y;
						}}
						labelComponent={
							<V.VictoryLabel
								dy={-20}
								backgroundStyle={{ fill: "white" }}
								backgroundPadding={3}
								className="label"
								style={{ fontFamily: "roboto", fontSize: 11 }}
							/>
						}
					/>
					<V.VictoryAxis
						domain={{
							y: [
								0,
								Math.max(
									...execfiltered.map(
										(exe: ExecucaoProps) => exe.EXECUCAO_EXECMAX
									)
								) + 10,
							],
						}}
						label={current_language?.forca_kg}
						style={{
							...sharedAxisStyles,
							grid: {
								stroke: "#C4C4C4",
								strokeWidth: 0.5,
							},
						}}
						dependentAxis
					/>
					<V.VictoryAxis
						standalone={true}
						width={2}
						padding={4}
						domainPadding={{ x: [1, -7] }}
						style={{
							...sharedAxisStyles,
						}}
					/>
				</V.VictoryChart>
			</div>
		);
	}

	function handleData(execucoes: EvolucaoProps){
		return (
			<>
				{execucoes?.map((execucao: EvolucaoProps ) => {
					return (
						<tr key={execucao?.x.split(":", 1)[0]}>
							<th className="value">{execucao?.x.split(":", 1)[0]}</th>
							<th className="value">
								{moment(execucao?.i.EXECUCAO_EXECUTADA).format("DD/MM/YY")}
							</th>
							<th className="value">{execucao?.i.EXECUCAO_EXECMED} (Kg)</th>
							<th className="value">
								<span
									className={
										getPermission(
											config_servicos,
											TypesPermission.SERVICO_VALOR_REFERENCIA,
											dispatch
										)
											? execucao?.i.EVOLUCAO.STATUS !== "fail" &&
											execucao?.i.EVOLUCAO.DADOS["%5"] <
													execucao?.i.EXECUCAO_EXECMED
												? "colorSuccess"
												: execucao?.i.EVOLUCAO.STATUS !== "fail" &&
												execucao?.i.EVOLUCAO.DADOS["%5"] >
														execucao?.i.EXECUCAO_EXECMED
												? "colorFail"
												: ""
											: ""
									}
								>
									{execucao.i.EXECUCAO_EXECMAX} (Kg)
								</span>
								{getPermission(
									config_servicos,
									TypesPermission.SERVICO_VALOR_REFERENCIA,
									dispatch
								) && (
									<>
										{execucao.i.EVOLUCAO.STATUS !== "fail" &&
										 execucao.i.EVOLUCAO.DADOS["%5"] <
											execucao.i.EXECUCAO_EXECMED ? (
											<svg
												width="13"
												height="12"
												viewBox="0 0 13 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M6.57559 2.89109L6.69902 1.70666C6.74785 1.23068 7.22491 0.9077 7.7068 1.02369C8.43178 1.19868 8.94104 1.82615 8.94104 2.54561V4.06302C8.94104 4.40051 8.94104 4.5695 9.01938 4.69299C9.06392 4.76348 9.1251 4.82298 9.19808 4.86548C9.32687 4.94097 9.50234 4.94097 9.85276 4.94097H10.0653C10.9791 4.94097 11.4358 4.94098 11.717 5.13596C11.9279 5.28246 12.0765 5.49694 12.1356 5.73993C12.2139 6.06491 12.0384 6.47139 11.6869 7.28334L11.512 7.68832C11.4105 7.92287 11.369 8.1762 11.3907 8.42828C11.5152 9.8652 10.2923 11.0756 8.79722 10.9961L3.20344 10.6967C2.59222 10.6642 2.28688 10.6477 2.01106 10.4202C1.7347 10.1927 1.68264 9.95819 1.57908 9.48972C1.35865 8.49308 1.36871 7.46412 1.60859 6.47139C1.76046 5.84742 2.40011 5.51144 3.0607 5.59144C4.81224 5.80143 6.40012 4.582 6.57559 2.89159V2.89109Z"
													stroke="#39E664"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										) : (
											execucao.i.EVOLUCAO.STATUS !== "fail" &&
											execucao.i.EVOLUCAO.DADOS["%5"] >
												execucao.i.EXECUCAO_EXECMED && (
												<svg
													width="12"
													height="10"
													viewBox="0 0 12 10"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M6.10466 7.48704L5.99367 8.43464C5.94976 8.81544 5.52075 9.07384 5.0874 8.98104C4.43544 8.84104 3.97748 8.33904 3.97748 7.76344V6.54943C3.97748 6.27943 3.97748 6.14423 3.90702 6.04543C3.86747 5.98948 3.81238 5.94217 3.74632 5.90743C3.6305 5.84703 3.4727 5.84703 3.15758 5.84703H2.96648C2.14466 5.84703 1.73399 5.84703 1.48112 5.69103C1.29012 5.57234 1.15661 5.40096 1.10471 5.20782C1.03426 4.94782 1.19206 4.62262 1.50814 3.97302L1.66595 3.64902C1.75667 3.46181 1.79383 3.25901 1.77453 3.05701C1.66257 1.90741 2.76235 0.939001 4.10681 1.003L9.13716 1.2422C9.68681 1.2682 9.96139 1.2814 10.2094 1.4634C10.458 1.6454 10.5048 1.83301 10.5979 2.20781C10.7962 3.00516 10.7872 3.8284 10.5714 4.62262C10.4348 5.12182 9.85957 5.39063 9.26552 5.32662C7.6904 5.15862 6.26246 6.13423 6.10466 7.48664V7.48704Z"
														stroke="#D12600"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											)
										)}
									</>
								)}
							</th>
							{getPermission(
								config_servicos,
								TypesPermission.SERVICO_VALOR_REFERENCIA,
								dispatch
							) && (
								<th className="value">
									{execucao.i.EVOLUCAO.STATUS === "fail"
										? "-"
										: `${execucao.i.EVOLUCAO.DADOS["%5"]} Kg`}
								</th>
							)}
							<th className="value">{execucao.i.EXECUCAO_TEMPO} (s)</th>
							<th className="value">{execucao.i.EXECUCAO_REPS}</th>
						</tr>
					);
				})}
			</>
		)
	}

	function _renderTable(execucoes: EvolucaoProps) {
		return (
			<div className="divTable">
				<table className="table">
					<thead>
						<tr className="tr">
							<th className="prop">
								<svg
									width="13"
									height="11"
									viewBox="0 0 13 11"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.8946 1.1H8.13037C7.85263 0.462 7.12522 0 6.26554 0C5.40587 0 4.67846 0.462 4.40072 1.1H1.63654C0.909123 1.1 0.313965 1.595 0.313965 2.2V9.9C0.313965 10.505 0.909123 11 1.63654 11H10.8946C11.622 11 12.2171 10.505 12.2171 9.9V2.2C12.2171 1.595 11.622 1.1 10.8946 1.1ZM6.26554 1.1C6.62925 1.1 6.92683 1.3475 6.92683 1.65C6.92683 1.9525 6.62925 2.2 6.26554 2.2C5.90184 2.2 5.60426 1.9525 5.60426 1.65C5.60426 1.3475 5.90184 1.1 6.26554 1.1ZM7.58812 8.8H2.95911V7.7H7.58812V8.8ZM9.57198 6.6H2.95911V5.5H9.57198V6.6ZM9.57198 4.4H2.95911V3.3H9.57198V4.4Z"
										fill="#C4C4C4"
									/>
								</svg>
							</th>
							<th className="prop">{current_language?.data}</th>
							<th className="prop">{current_language?.media}</th>
							<th className="prop">{current_language?.maximo}</th>
							{getPermission(
								config_servicos,
								TypesPermission.SERVICO_VALOR_REFERENCIA,
								dispatch
							) && <th className="prop">{current_language?.referencia}*</th>}
							<th className="prop">{current_language?.Tempo_total}</th>
							<th className="prop">{current_language?.config_aval_repeticoes}</th>
						</tr>
					</thead>
					<tbody>
						{handleData(execucoes)}
					</tbody>
				</table>
				<p className="legenda_descricao">
					* {current_language?.legenda_descricao_evolucao} (MELDRUM, 2007)
				</p>
			</div>
		);
	}

	return (
		<Content>
			<Exercice>
				<div className="Divtitle">
					<FormControl>
						{finalExersRelatorios.length > 0 ?

							<Select
								className={'selectTitle'}
								labelId="demo-controlled-open-select-label"
								id="demo-controlled-open-select"
								open={open}
								value={age ? age : ""}
								onClose={handleClose}
								onOpen={handleOpen}
								onChange={handleChange}
							>
								{finalExersRelatorios.map((item) => (
									<MenuItem
										key={item.ID_EXERCICIO}
										value={item.ID_EXERCICIO + item.MODO}
									>
										{`${item.EXERCICIO_NOME} ${getGroupingSide(
											item.MODO,
											current_language
										)?.toLowerCase()}`}
									</MenuItem>
								))}
							</Select>
							: <p></p>
						}
					</FormControl>
				</div>

				{execfiltered.length > 0 && permission ? ( 
					<> 
						<div className="DivSubTitle">
							<p className="subTitleFirst">
								{current_language?.execucao_force_media}
							</p>
							<p className="subTitle">{current_language?.execucao_force_max}</p>
						</div>
						<Charts>
							<ForcaMaxima>
								{currentIndex != 0 && (
									<button
										className="btn"
										onClick={() => {
											!getPermission(
												config_servicos,
												TypesPermission.SERVICO_REL_EVOLUCAO,
												dispatch
											)
												? dispatch(
														AppActions.set_modal_upgrade_funcionalidade(true)
												  )
												: SpendReport("decreasing");
										}}
									>
										<svg
											width="8"
											height="14"
											viewBox="0 0 8 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M7 1L1 7L7 13"
												stroke="#000E4B"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								)}
								{_renderGrafic(medium_force_chunks[currentIndex])}
							</ForcaMaxima>

							<ForcaMed>
								{_renderGrafic(max_force_chunks[currentIndex])}
								{all_index != 0 && currentIndex + 1 != all_index && (
									<button
										className="btn"
										onClick={() => {
											!getPermission(
												config_servicos,
												TypesPermission.SERVICO_REL_EVOLUCAO,
												dispatch
											)
												? dispatch(
														AppActions.set_modal_upgrade_funcionalidade(true)
												  )
												: SpendReport("increasing");
										}}
									>
										<svg
											width="8"
											height="14"
											viewBox="0 0 8 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0.999999 13L7 7L1 0.999999"
												stroke="#000E4B"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								)}
							</ForcaMed>
						</Charts>
						{_renderTable(max_force_chunks[currentIndex])}
					</>
				) : (
					<NenhumRelatorio />
				)}
			</Exercice>
		</Content>
	);
};

export default Evolucao;

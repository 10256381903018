import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 21px;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 2rem;
		width: 12%;
	}

	.divText {
		width: 65%;
	}

	.text {
		color: #b3b3b3;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}

	.button {
		width: 50%;
		margin-top: 3.25rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 1.3rem 3.3rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}
`;

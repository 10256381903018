import moment from "moment";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TextsProps } from "../../../../database/txt.interface";
import { Exercicios2Props } from "../../../../interfaces/exercicio.interface";
import { AppActions } from "../../../../store/ducks/app";
import { ManageProtocolActions } from "../../../../store/ducks/manage_protocols";
import { TypesLanguage } from "../../../../utils/types";
import { Conteiner, Content } from "../styles";
import dragIcon from "../../../../assets/icons/dragicon.svg";
import { AmplitudeLog } from "../../../../utils/amplitude";
import { exercicesWithNoTutorial } from "../../../../database/exercicicesNoTutorial";

interface protocoloStep {
	EXERCICIO_TITULO_EN: string;
	EXERCICIO_TITULO_ES: string;
	EXERCICIO_TITULO_PT: string;
	ID_EXERCICIO: number;
	PROTOCOLO_EXERCICIO_TIPO: string;
}

const DragProtocols: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const is_open_protocolo = useSelector(
		(state: any) => state.app.is_open_protocolo
	);

	const berofe_add_protocol: any = useSelector(
		(state: any) => state.manage_protocol.berofe_add_protocol
	);

	const language = useSelector((state: any) => state.configs.language);
	const [protocoloFinal, updateprotocoloFinal] = useState(
		berofe_add_protocol?.PROTOCOLO_EXERCICIO
	);

	function handleOnDragEnd(result: any) {
		if (!result.destination) return;

		const items = Array.from(protocoloFinal);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		updateprotocoloFinal(items);
	}

	function GenereteExerProtocoloFinalArray() {
		const exerProtocoloFinalArray: any = [];
		protocoloFinal.map((e: any) =>
			exerProtocoloFinalArray.push({
				ID_EXERCICIO: e.ID_EXERCICIO,
				PROTOCOLO_EXERCICIO_TIPO:
					e.PROTOCOLO_EXERCICIO_TIPO == "" ? "S" : e.PROTOCOLO_EXERCICIO_TIPO,
			})
		);

		return exerProtocoloFinalArray;
	}

	const onSaveProtocol = () => {
		const dat = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			PROTOCOLO_TITULO: berofe_add_protocol.PROTOCOLO_TITULO,
			PROTOCOLO_EXERCICIO: GenereteExerProtocoloFinalArray(),
		};
		const data_request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ID_PROTOCOLO: is_open_protocolo?.status?.ID_PROTOCOLO,
			PROTOCOLO_TITULO: berofe_add_protocol.PROTOCOLO_TITULO,
			PROTOCOLO_EXERCICIO: GenereteExerProtocoloFinalArray(),
		};
		is_open_protocolo.edit
			? dispatch(ManageProtocolActions.edit_protocolo_request(data_request))
			: dispatch(ManageProtocolActions.add_protocolo_request(dat));

		is_open_protocolo.edit
			?AmplitudeLog( 'protocolo_editado_sucesso', {body: data_request})
			:AmplitudeLog( 'protocolo_adicionado',{body: dat})
	};

	const tutorial = (e: any) => {
		dispatch(AppActions.set_exercicio_tutorial(e));
		dispatch(AppActions.set_modal_tutorial(true));
	};

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);
	const exerProtocolo: any = [];
	protocoloFinal.map((i: any) => exerProtocolo.push(i.ID_EXERCICIO));
	const exerFinal = exercicios.filter((e: any) =>
		exerProtocolo.includes(e.ID_EXERCICIO)
	);

	return (
		<Conteiner>
			<div className="cabecalho">
				<p className="title">{current_language?.drag_protocolo}</p>
			</div>

			<Content>
				<div>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="protocoloFinal">
							{(provided) => (
								<ul
									className="protocoloFinal"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{protocoloFinal.map((item: protocoloStep, index: any) => {
										return (
											<Draggable
												key={item.ID_EXERCICIO + index}
												draggableId={item.ID_EXERCICIO.toString()}
												index={index}
											>
												{(provided) => (
													<div
														className="ficha"
														{...provided?.draggableProps}
														{...provided?.dragHandleProps}
														ref={provided.innerRef}
													>
														<div className="left">
															<img src={dragIcon} />
															<p style={{ marginLeft: "30px" }}>
																{language == TypesLanguage.PT
																	? item.EXERCICIO_TITULO_PT
																	: language == TypesLanguage.EN
																	? item.EXERCICIO_TITULO_EN
																	: item.EXERCICIO_TITULO_ES}
															</p>
														</div>

														{exerFinal.find(
															(e: any) => e.ID_EXERCICIO == item.ID_EXERCICIO
														)?.ID_USUARIO === 0 && !exercicesWithNoTutorial.includes(item.EXERCICIO_TITULO_PT) && (
															<button
																type="button"
																className="right botao"
																onClick={() => tutorial(item.ID_EXERCICIO)}
															>
																<p className="tutorial">
																	{current_language?.tutorial}
																</p>
																<svg
																	width="28"
																	height="23"
																	viewBox="0 0 28 23"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M24.9087 0H2.49087C1.10844 0 0 1.13722 0 2.55556V17.8889C0 19.2944 1.10844 20.4444 2.49087 20.4444H8.71805V23H18.6815V20.4444H24.9087C26.2787 20.4444 27.3871 19.2944 27.3871 17.8889L27.3996 2.55556C27.3996 1.13722 26.2787 0 24.9087 0ZM24.9087 17.8889H2.49087V2.55556H24.9087V17.8889Z"
																		fill="#001574"
																	/>
																	<path
																		d="M8.11841 14.3737C8.11841 14.3737 8.7948 7.49924 10.824 5L14.2059 5.61106L13.5295 7.49924H12.1767V11.8744H12.8531C14.2059 9.99848 17.0062 9.31409 18.6971 9.99848C20.9292 10.9334 20.7263 13.7504 18.6971 14.997C17.0738 15.9991 12.1767 16.8729 8.11841 14.3737Z"
																		fill="#001574"
																	/>
																</svg>
															</button>
														)}
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</ul>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</Content>
			<button 
				type="button" 
				onClick={() => onSaveProtocol()} 
				className="salve"
			>
				{current_language?.pagamento_salvar}
			</button>
			<Link to="/protocolos" style={{ textDecoration: "none" }}>
				<button
					id="cancel"
					type="button"
					className="cancel"
					onClick={() =>{
						dispatch(ManageProtocolActions.set_selected_exercises_reset())
						AmplitudeLog(is_open_protocolo?.edit ? 'cancelar_editar_protocolo' : 'cancelar_add_protocolo')
					}}
				>
					{current_language?.botao_cancelar}
				</button>
			</Link>
		</Conteiner>
	);
};

export default DragProtocols;

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { persistStorage } from "../../../utils/base_async_storage";

import ToastAlert from "../../../components/toast";
import Toogle from "../../../components/Toogle";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { ConfigsActions } from "../../../store/ducks/configs";
import { TypesLanguage } from "../../../utils/types";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import Ampulheta from '../../../assets/icons/ampulheta.svg';
import RefresFuncional from '../../../assets/icons/refresh_funcional.svg';
import Descanso from '../../../assets/icons/time_pause.svg';
import Contracao from '../../../assets/icons/clock.svg';
import { colors } from "../../../styles/colors";

const Configuracoes: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("configurar"));
	}, []);

	const tempo_contracao_flag = useSelector(
		(state: any) => state.configs.tempo_contracao_flag
	);
	const contagem_regressiva = useSelector(
		(state: any) => state.configs.contagem_regressiva
	);
	const intervalo_descanso_flag = useSelector(
		(state: any) => state.configs.intervalo_descanso_flag
	);
	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);
	const intervalo_descanso = useSelector(
		(state: any) => state.configs.intervalo_descanso
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const modo_demo = useSelector((state: any) => state.configs.modo_demo);

	const language = useSelector((state: any) => state.configs.language);
	const config_funcional = useSelector(
		(state: any) => state.configs.config_funcional,
	);

	const [selected, setSelected] = useState<string[]>(["gerais"]);
	const handleActiveClick = (active: string, listagem: boolean) => {
		const alreadySelected = selected.findIndex((item) => item === active);

		if (alreadySelected < 0) {
			setSelected([active]);
		}
	};

	const handleCheckLanguage = async (event: { target: { value: string } }) => {
		if (event.target.value === "pt-BR") {
			dispatch(ConfigsActions.set_language(TypesLanguage.PT));
			await persistStorage({
				res: TypesLanguage.PT,
				tag: "config_language",
			});
		} else if (event.target.value === "en-US") {
			dispatch(ConfigsActions.set_language(TypesLanguage.EN));
			await persistStorage({
				res: TypesLanguage.EN,
				tag: "config_language",
			});
		} else {
			dispatch(ConfigsActions.set_language(TypesLanguage.ES));
			await persistStorage({
				res: TypesLanguage.ES,
				tag: "config_language",
			});
		}
	};

	const isWritingContracao = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value !== "" && parseInt(e.target.value) > 0) {
			dispatch(ConfigsActions.set_tempo_contracao_timer(e.target.value));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_CONTRACAO_FLAG: tempo_contracao_flag,
					CONFIG_EXER_CONTRACAO: e.target.value,
				})
			);
		} else {
			dispatch(ConfigsActions.set_tempo_contracao_timer(20));
		}
	};

	const isWritingDescanso = (e: any) => {
		if (e.target.value !== "" && parseInt(e.target.value) > 0) {
			dispatch(ConfigsActions.set_intervalo_descanso_timer(e.target.value));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_DESCANSO_FLAG: intervalo_descanso_flag,
					CONFIG_EXER_DESCANSO: e.target.value,
				})
			);
		} else {
			dispatch(ConfigsActions.set_intervalo_descanso_timer(60));
		}
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	return (
		<Conteiner id="DivPrincipal">
			{is_open_toast && <ToastAlert />}
			<div className="divCabecalho">
				<div className="divTitle">
					<p className="title">{current_language?.menu_lateral_config}</p>
				</div>
				<div className="divNav">
					<button
						className={`${
							selected.includes("gerais") ? "selecao" : "selecao2"
						}`}
						onClick={() => {
							handleActiveClick("gerais", false) 
							AmplitudeLog('configs_gerais')
						}}
					>
						{current_language?.menu_lateral_geral}
					</button>
					<button
						className={`${
							selected.includes("exercicios") ? "selecao" : "selecao2"
						}`}
						onClick={() => {
							handleActiveClick("exercicios", true)
							AmplitudeLog('configs_exercicios')
						}}
					>
						{current_language?.exercicios}
					</button>
				</div>
			</div>
			{selected.includes("exercicios") && (
				<div className="divExercicio">
				
					<div className="fichaExercicio">
						<div className="DivIcone">
							<img src={Ampulheta} alt="" />
						</div>
						<div className="a">
							<p className="p">
								{current_language?.menu_lateral_exercicio_contagem}
							</p>
							<p>{current_language?.menu_lateral_exercicio_contagem_legenda}</p>
						</div>
						<div className="opo">
							<Toogle action={"ativarContagem"} isCheck={contagem_regressiva} />
						</div>
					</div>
					<div className="fichaExercicio">
						<div className="DivIcone">
							<img src={RefresFuncional} 	width={25} height={25}  alt="" />
						</div>
						<div className="a">
							<p className="p">
								{current_language?.config_exercise_functional}
							</p>
							<p>
								{current_language?.description_config_exercise_functional}
							</p>
						</div>
						<div className="opo">
							<Toogle action={"ativarFuncional"} isCheck={config_funcional} />
						</div>
					</div>
					<div className="fichaExercicio">
						<div className="DivIcone">
							<img src={Descanso}/>
						</div>
						<div className="a">
							<p className="p">
								Intervalo de descanso
							</p>
							<p>
								{current_language?.menu_lateral_exercicios_intervalo_legenda}
							</p>
						</div>
						<div className="opo">
							<Toogle
								action={"ativarDescanco"}
								isCheck={intervalo_descanso_flag}
							/>
						</div>
					</div>
					<div className="divInput">
						<p className="tempo">{current_language?.menu_tempo_segundos}</p>
						<input
							className="input"
							id="inputDescanco"
							placeholder={intervalo_descanso.toString() + " s"}
							type="text"
							onBlur={(e) => isWritingDescanso(e)}
						/>
					</div>
					<div className="fichaExercicio">
						<div className="DivIcone">
							<img src={Contracao} alt="" />
						</div>
						<div className="a">
							<p className="p">{current_language?.contracao_tempo}</p>
							<p>{current_language?.menu_tempo_contracao_legenda}</p>
						</div>
						<div className="opo">
							<Toogle
								action={"ativarContracao"}
								isCheck={tempo_contracao_flag}
							/>
						</div>
					</div>
					<div className="divInput">
						<p className="tempo">{current_language?.menu_tempo_segundos}</p>
						<input
							className="input"
							id="inputContracao"
							placeholder={tempo_contracao.toString() + " s"}
							type="text"
							onBlur={(e) => isWritingContracao(e)}
						/>
					</div>
				</div>
			)}
			{selected.includes("gerais") && (
				<div className="divExercicio">
					<div className="fichaExercicio">
						<div className="DivIcone">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM20.304 7.2H16.764C16.3884 5.71375 15.8323 4.27908 15.108 2.928C17.2969 3.6816 19.1414 5.19807 20.304 7.2ZM12 2.448C12.996 3.888 13.776 5.484 14.292 7.2H9.708C10.224 5.484 11.004 3.888 12 2.448ZM2.712 14.4C2.52 13.632 2.4 12.828 2.4 12C2.4 11.172 2.52 10.368 2.712 9.6H6.768C6.672 10.392 6.6 11.184 6.6 12C6.6 12.816 6.672 13.608 6.768 14.4H2.712ZM3.696 16.8H7.236C7.62 18.3 8.172 19.74 8.892 21.072C6.70076 20.3225 4.855 18.805 3.696 16.8ZM7.236 7.2H3.696C4.855 5.19501 6.70076 3.67748 8.892 2.928C8.16771 4.27908 7.61157 5.71375 7.236 7.2ZM12 21.552C11.004 20.112 10.224 18.516 9.708 16.8H14.292C13.776 18.516 12.996 20.112 12 21.552ZM14.808 14.4H9.192C9.084 13.608 9 12.816 9 12C9 11.184 9.084 10.38 9.192 9.6H14.808C14.916 10.38 15 11.184 15 12C15 12.816 14.916 13.608 14.808 14.4ZM15.108 21.072C15.828 19.74 16.38 18.3 16.764 16.8H20.304C19.1414 18.8019 17.2969 20.3184 15.108 21.072ZM17.232 14.4C17.328 13.608 17.4 12.816 17.4 12C17.4 11.184 17.328 10.392 17.232 9.6H21.288C21.48 10.368 21.6 11.172 21.6 12C21.6 12.828 21.48 13.632 21.288 14.4H17.232Z"
									fill="#001574"
								/>
							</svg>
						</div>
						<div className="a">
							<p className="p">
								{current_language?.menu_lateral_gerais_linguagem}
							</p>
							<p>{current_language?.menu_linguagem_legenda}</p>
						</div>
						<div className="ope">
							<div className="lenguage">
								<div className="groupRadio">
									<input
										className="select"
										type="radio"
										name="radio"
										id="portugues"
										value="pt-BR"
										onChange={handleCheckLanguage}
									/>
									<label
										className={
											language == TypesLanguage.PT
												? "checkmarkSeleted"
												: "checkmark"
										}
										htmlFor="portugues"
									></label>
								</div>
								<label className="idioma" htmlFor="portugues">
									Pt-Br
								</label>
							</div>
							<div className="lenguage">
								<div className="groupRadio">
									<input
										className="select"
										type="radio"
										name="radio"
										id="english"
										value="en-US"
										onChange={handleCheckLanguage}
									/>
									<label
										className={
											language == TypesLanguage.EN
												? "checkmarkSeleted"
												: "checkmark"
										}
										htmlFor="english"
									></label>
								</div>
								<label className="idioma" htmlFor="english">
									En
								</label>
							</div>
							<div className="lenguage">
								<div className="groupRadio">
									<input
										className="select"
										type="radio"
										name="radio"
										id="español"
										value="es"
										onChange={handleCheckLanguage}
									/>
									<label
										className={
											language == TypesLanguage.ES
												? "checkmarkSeleted"
												: "checkmark"
										}
										htmlFor="español"
									></label>
								</div>
								<label className="idioma" htmlFor="español">
									Es
								</label>
							</div>
						</div>
					</div>
					<div className="fichaExercicio">
						<div className="DivIcone">
							<svg
								width="19"
								height="21"
								viewBox="0 0 19 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M0 0V21L19 10.5L0 0Z" fill="#001574" />
							</svg>
						</div>
						<div className="a">
							<p className="p">{current_language?.menu_lateral_demonstracao}</p>
							<p>{current_language?.menu_lateral_calibre_dino_txt}</p>
						</div>
						<div className="opo">
							<Toogle action="demo" isCheck={modo_demo} />
						</div>
					</div>
				</div>
			)}
		</Conteiner>
	);
};

export default memo(Configuracoes);

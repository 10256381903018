import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TypesAvalOrFunci, TypesProLivre } from "../../../utils/types";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalModoExercicio: React.FC = () => {
	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	// comunica com redux
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	
	const id_current_exercise: number = useSelector((state:any)=> state.exercicios.id_exercise)

	const classes = useStyles();
	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const is_open_modo_exercicio = useSelector(
		(state: any) => state.app.is_open_modo_exercicio
	);
	const config_funcional_habilitado = useSelector(
		(state: any) => state.configs.config_funcional,
	);
	const lado_exercicio = useSelector((state: any) => state.app.lado_exercicio);
	const is_selected_type_pro_livre = useSelector(
		(state: any) => state.app.is_selected_type_pro_livre
	);
	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);


	// função para fechar modal
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_modo_exercicio(false));
	};

	const Marcar = (lado: any) => {
		dispatch(AppActions.set_lado_exercicio(lado));
	};


	const nextPage = () => {
		dispatch(AppActions.set_modal_select_avaliacao(true));
		ModalCadastroClose();
	};

	const handlerWithExerciseExecutionEvaluationMode = ()=> {

		dispatch(AppActions.set_modal_modo_exercicio(false));
		dispatch(AppActions.set_selected_avaliacao(TypesAvalOrFunci.AVALIACAO)); 

		if (is_selected_type_pro_livre === TypesProLivre.LIVRE) {
			dispatch(push("/exercicio"));
		}
	}
	

	return (
		<div>
			{/* librarie para abrir o modal */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_modo_exercicio}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_modo_exercicio}>
					{/* modal com as infos, feito html e css */}
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="divText">
							<p className="text">
								<b>{current_language?.modo_exercicio}</b>
							</p>
							<div className="botoes">
								<div
									onClick={() => {
										Marcar("Esquerdo")
										AmplitudeLog('exercicio_esquerdo')
									}}
									className={`botao ${
										lado_exercicio === "Esquerdo" && "botaoMarcado"
									}`}
								>
									<p>{current_language?.esquerda}</p>
								</div>
								<div
									onClick={() => {
										Marcar("Bilateral")
										AmplitudeLog('exercicio_bilateral')
									}}
									className={`botao ${
										lado_exercicio === "Bilateral" && "botaoMarcado"
									}`}
								>
									<p>{current_language?.hemisferio_bilateral}</p>
								</div>
								<div
									onClick={() => {
										Marcar("Direita")
										AmplitudeLog('exercicio_direito')
									}}
									className={`botao ${
										lado_exercicio === "Direita" && "botaoMarcado"
									}`}
								>
									<p>{current_language?.direita}</p>
								</div>
							</div>
						</div>
						<button
							onClick={() => {
								!!config_funcional_habilitado
								? nextPage()
								: handlerWithExerciseExecutionEvaluationMode() 
								AmplitudeLog('tipo_exercicio_selecionado')
							}}
							className={`button ${lado_exercicio !== "" && "upgrade"}`}
						>
							{current_language?.proximo}
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalModoExercicio;

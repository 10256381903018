export const Types = {
	SET_EXERCICIOS: "SET_EXERCICIOS",
	RECUPERAR_EXERCICIO_REQUEST: "RECUPERAR_EXERCICIO_REQUEST",
	RECUPERAR_EXERCICIO_REQUEST_CANCEL: "RECUPERAR_EXERCICIO_REQUEST_CANCEL",
	SET_EXERCICIOS_EXCLUIDOS: "SET_EXERCICIOS_EXCLUIDOS",
	SET_EXERCICIOS_RECUPERAR: "SET_EXERCICIOS_RECUPERAR",
	RESET_EXERCICES: "RESET_EXERCICES",
	EDITAR_EXERCICES: "EDITAR_EXERCICES",
	EDITAR_EXERCICES_CANCEL: "EDITAR_EXERCICES_CANCEL",
	EXERCICIOS_DELETADOS: "EXERCICIOS_DELETADOS",
	RECUPERAR_EXECUCAO_REQUEST: "RECUPERAR_EXECUCAO_REQUEST",
	RECUPERAR_EXECUCAO_REQUEST_CANCEL: "RECUPERAR_EXECUCAO_REQUEST_CANCEL",
	SET_EXECUCOES_RECUPERAR: "SET_EXECUCOES_RECUPERAR",
	SET_EXECUCOES_EXCLUIDAS: "SET_EXECUCOES_EXCLUIDAS",
	SET_ID_PROTO: "SET_ID_PROTO",
	EDITAR_EXECUCAO_REQUEST: "EDITAR_EXECUCAO_REQUEST",
	EDITAR_EXECUCAO_REQUEST_CANCEL: "EDITAR_EXECUCAO_REQUEST_CANCEL",
	GET_ID_EXERCISE: 'GET_ID_EXERCISE',
};

const INITIAL_STATE = {
	exercicios: [],
	exerciciosDeletados: [],
	exerciciosExcluidos: [],
	exerciciosRecuperar: [],
	execucoesExcluidas: [],
	execucoesRecuperar: [],
	execucoesEditar: [],
	loading: false,
	loading_execucao: false,
	id_proto: false,
	id_exercise: null,
};

export default function exercicios(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_EXERCICIOS:
			return {
				...state,
				exercicios: action.payload,
			};
		case Types.EXERCICIOS_DELETADOS:
			return {
				...state,
				exerciciosDeletados: action.payload,
			};
		case Types.SET_EXERCICIOS_EXCLUIDOS:
			return {
				...state,
				exerciciosExcluidos: action.payload,
			};
		case Types.SET_EXERCICIOS_RECUPERAR:
			let newLis: any = [];
			const exist: any = state.exerciciosRecuperar.find(
				(pac: any) => pac == action.payload
			);
			if (!!exist) {
				newLis = state.exerciciosRecuperar.filter(
					(pac: any) => pac != action.payload
				);
			} else {
				newLis = [...state.exerciciosRecuperar, action.payload];
			}
			return {
				...state,
				exerciciosRecuperar: [...newLis],
			};
		case Types.RECUPERAR_EXERCICIO_REQUEST:
			return {
				...state,
				loading: true,
			};
		case Types.RECUPERAR_EXERCICIO_REQUEST_CANCEL:
			return {
				...state,
				loading: false,
			};
		case Types.EDITAR_EXERCICES:
			return {
				...state,
				loading: true,
			};
		case Types.EDITAR_EXERCICES_CANCEL:
			return {
				...state,
				loading: false,
			};
		case Types.RESET_EXERCICES:
			return {
				...state,
				exerciciosRecuperar: [],
				execucoesRecuperar: [],
				execucoesExcluidas: [],
			};
		case Types.RECUPERAR_EXECUCAO_REQUEST:
			return {
				...state,
				loading_execucao: true,
			};
		case Types.RECUPERAR_EXECUCAO_REQUEST_CANCEL:
			return {
				...state,
				loading_execucao: false,
			};
		case Types.EDITAR_EXECUCAO_REQUEST:
			return {
				...state,
				loading_execucao: true,
			};
		case Types.EDITAR_EXECUCAO_REQUEST_CANCEL:
			return {
				...state,
				loading_execucao: false,
			};
		case Types.SET_EXECUCOES_EXCLUIDAS:
			return {
				...state,
				execucoesExcluidas: action.payload,
			};
		case Types.SET_EXECUCOES_RECUPERAR:
			let newLisExec: any = [];
			const existExec: any = state.execucoesRecuperar.find(
				(pac: any) => pac == action.payload
			);
			if (!!existExec) {
				newLisExec = state.execucoesRecuperar.filter(
					(pac: any) => pac != action.payload
				);
			} else {
				newLisExec = [...state.execucoesRecuperar, action.payload];
			}
			return {
				...state,
				execucoesRecuperar: [...newLisExec],
			};
		case Types.GET_ID_EXERCISE:
			return {
				...state,
				id_exercise: action.payload,
			}
		case Types.SET_ID_PROTO:
			return {
				...state,
				id_proto: action.payload,
			};

		default:
			return state;
	}
}

export const ExerciciosActions = {
	set_exercicios: (exercicios: any) => ({
		type: Types.SET_EXERCICIOS,
		payload: exercicios,
	}),
	set_exercicios_deletado: (exerciciosDeletados: any) => ({
		type: Types.EXERCICIOS_DELETADOS,
		payload: exerciciosDeletados,
	}),
	set_exercicios_excluidos: (exerciciosExcluidos: any) => ({
		type: Types.SET_EXERCICIOS_EXCLUIDOS,
		payload: exerciciosExcluidos,
	}),
	set_add_selected_exercices: (exer: any) => ({
		type: Types.SET_EXERCICIOS_RECUPERAR,
		payload: exer,
	}),
	set_reset_exercices: () => ({
		type: Types.RESET_EXERCICES,
	}),
	recuperar_exercicio_request: (data: any) => ({
		type: Types.RECUPERAR_EXERCICIO_REQUEST,
		payload: data,
	}),
	recuperar_exercicio_request_cancel: () => ({
		type: Types.RECUPERAR_EXERCICIO_REQUEST_CANCEL,
	}),
	editar_exercicio_request: (data: any) => ({
		type: Types.EDITAR_EXERCICES,
		payload: data,
	}),
	editar_exercicio_request_cancel: () => ({
		type: Types.EDITAR_EXERCICES_CANCEL,
	}),
	recuperar_execucao_request: (data: any) => ({
		type: Types.RECUPERAR_EXECUCAO_REQUEST,
		payload: data,
	}),
	recuperar_execucao_request_cancel: () => ({
		type: Types.RECUPERAR_EXECUCAO_REQUEST_CANCEL,
	}),
	set_execucoes_recuperar: (data: any) => ({
		type: Types.SET_EXECUCOES_RECUPERAR,
		payload: data,
	}),
	editar_execucao_request: (data: any) => ({
		type: Types.EDITAR_EXECUCAO_REQUEST,
		payload: data,
	}),
	editar_execucao_request_cancel: () => ({
		type: Types.EDITAR_EXECUCAO_REQUEST_CANCEL,
	}),
	set_execucoes_excluidas: (data: any) => ({
		type: Types.SET_EXECUCOES_EXCLUIDAS,
		payload: data,
	}),
	set_id_proto: (data: any) => ({
		type: Types.SET_ID_PROTO,
		payload: data,
	}),
	set_get_id_exercise: (id_exercise:number)=> ({
		type: Types.GET_ID_EXERCISE,
		payload: id_exercise
	}),
};

import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import calendar from "../../../assets/icons/calendar.svg";
import cartao from "../../../assets/icons/cartaoPagamento.svg";
import user from "../../../assets/icons/peopleCartao.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from "../../../store/ducks/financial";
import { RegisterActions } from "../../../store/ducks/register";
import { Conteiner, BodyEdite, Footer } from "./styles";

const MetodoPagamento: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	useEffect(() => {
		dispatch(FinancialActions.financial_request(
			config_servicos.SERVICO_IUGU,
			config_servicos?.SERVICO_SUPERLOGICA
		));
	}, []);

	const onSubmit = (data: any) => {
		const dat_request = {
			SERVICO_IUGU: config_servicos?.SERVICO_IUGU,
			SERVICO_SUPERLOGICA: config_servicos?.SERVICO_SUPERLOGICA,
			CC_NOME: data.nome,
			CC_NUMERO: data.numeroCartao.split(" ").join(""),
			CC_VALIDADE: `${data.mesCartao}/${data.anoCartao}`,
			CC_CVV: data.cvv,
		};
		dispatch(FinancialActions.add_card_request(dat_request));
	};

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("metodoPagameto"));
	}, []);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);


	//UI
	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			<BodyEdite>
				<h2 className="titulo">
					{current_language?.pagamento_insira_informacoes}
				</h2>
				<form onSubmit={handleSubmit(onSubmit)} className="form">
					<div className="global">
						<div className="tamanhoInput">
							<div className="globalInput">
								<img src={cartao} alt="key" className="icon" />
								<input
									type="text"
									id="numeroCartao"
									placeholder={current_language?.pagamento_numero_cartao}
									className="input"
									{...register("numeroCartao", {
										required: `${current_language?.login_input_senha}`,
										pattern: {
											value: /^[0-9]+$/i,
											message: `${current_language?.pagamento_somente_numero}`,
										},
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.numeroCartao ? "hr hrcor" : "hr"} />
								{errors.numeroCartao && (
									<div className="error">{errors.numeroCartao.message}</div>
								)}
							</div>
						</div>

						<div className="tamanhoInput">
							<div className="globalInput">
								<img src={user} alt="key" className="icon" />
								<input
									type="text"
									id="nome"
									placeholder={current_language?.pagamento_nome_cartao}
									className="input"
									{...register("nome", {
										required: `${current_language?.login_input_senha}`,
										pattern: {
											value:
												/\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,}\b/gi,
											message: `${current_language?.pagamento_nome_invalido}`,
										},
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.nome ? "hr hrcor" : "hr"} />
								{errors.nome && (
									<div className="error">{errors.nome.message}</div>
								)}
							</div>
						</div>

						<div className="mesAno">
							<div className="dadosCartao tamanhoInput">
								<div className="globalInput">
									<img src={calendar} alt="key" className="icon" />
									<input
										type="text"
										id="mesCartao"
										placeholder={current_language?.pagamento_mes_expiracao}
										className="input"
										maxLength={2}
										{...register("mesCartao", {
											required: `${current_language?.login_input_senha}`,
											pattern: {
												value: /^(0[1-9]|1[012])$/,
												message: `${current_language?.pagamento_dois_numeros}`,
											},
										})}
									/>
								</div>
								<div className="divhr">
									<hr className={errors.mesCartao ? "hr hrcor" : "hr"} />
									{errors.mesCartao && (
										<div className="error">{errors.mesCartao.message}</div>
									)}
								</div>
							</div>

							<div className="dadosCartao tamanhoInput">
								<div className="globalInput">
									<img src={calendar} alt="key" className="icon" />
									<input
										type="text"
										id="anoCartao"
										placeholder={current_language?.pagamento_ano_expiracao}
										className="input"
										maxLength={4}
										{...register("anoCartao", {
											required: `${current_language?.login_input_senha}`,
											pattern: {
												value: /^(20[2-9]\d{1})$/,
												message: `${current_language?.pagamento_quatro_numeros}`,
											},
										})}
									/>
								</div>
								<div className="divhr">
									<hr className={errors.anoCartao ? "hr hrcor" : "hr"} />
									{errors.anoCartao && (
										<div className="error">{errors.anoCartao.message}</div>
									)}
								</div>
							</div>
						</div>

						<div className="mesAno">
							<div className="dadosCartao tamanhoInput">
								<div className="globalInput">
									<img src={cartao} alt="key" className="icon" />
									<input
										type="text"
										id="cvv"
										placeholder={`CVV`}
										className="input"
										maxLength={4}
										{...register("cvv", {
											required: `${current_language?.login_input_senha}`,
											maxLength: 4,
											pattern: {
												value: /^([0-9]\d{2,3})$/,
												message: `${current_language?.pagamento_tres_numeros}`,
											},
										})}
									/>
									<div className="duvida">
										<svg
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 15.3H8.1V13.5H9.9V15.3ZM11.763 8.325L10.953 9.153C10.305 9.81 9.9 10.35 9.9 11.7H8.1V11.25C8.1 10.26 8.505 9.36 9.153 8.703L10.269 7.569C10.602 7.245 10.8 6.795 10.8 6.3C10.8 5.31 9.99 4.5 9 4.5C8.01 4.5 7.2 5.31 7.2 6.3H5.4C5.4 4.311 7.011 2.7 9 2.7C10.989 2.7 12.6 4.311 12.6 6.3C12.6 7.092 12.276 7.812 11.763 8.325Z"
												fill="#0023BD"
											/>
										</svg>
										<h5>{current_language?.pagamento_info_cvv}</h5>
									</div>
								</div>
								<div className="divhr">
									<hr className={errors.cvv ? "hr hrcor" : "hr"} />
									{errors.cvv && (
										<div className="error">{errors.cvv.message}</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<Footer>
						<button type="submit" id="botao" className="botao">
							{current_language?.pagamento_salvar}
						</button>
					</Footer>
				</form>
			</BodyEdite>
		</Conteiner>
	);
};

export default memo(MetodoPagamento);

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	padding-right: 4.8rem;
	padding-left: 4.8rem;
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 80vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 85vh;
		width: 42vw;
	}

	.close {
		width: 1.738rem;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.cabecalho {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 30px;
		color: #5c5b5b;
		margin-bottom: 5.3rem;
	}

	.divInput {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
	}

	.input {
		width: 100%;
		margin-left: 0.8rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: rgba(140, 142, 134, 0.8);
		border: none;
	}

	.hr {
		border: none;
		background-color: #e0e0e0;
		height: 2px;
	}

	.hrcor {
		background-color: ${colors.tertiary_red_1_dark};
		color: ${colors.tertiary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${colors.tertiary_red_1_dark};
	}

	.divButtons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.salvar {
		display: flex;
		justify-content: center;
		width: 23rem;
		height: 3.6rem;
		background: ${colors.primary_blue_k_main};
		border-radius: 8px;
		border: none;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 23px;
		align-items: center;
		text-align: center;
		color: #ffffff;
	}

	.cancelar {
		display: flex;
		justify-content: center;
		width: 23rem;
		height: 3.6rem;
		background: white;
		border-radius: 8px;
		border: none;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 23px;
		align-items: center;
		text-align: center;
		color: ${colors.primary_blue_k_main};
	}

	.tag {
		background-color: ${colors.secondary_green_k_medium_1};
		font-size: 20px;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
		display: inline-block;
		margin-bottom: 1.6rem;
		cursor: pointer;
	}
`;

import styled from "styled-components";
import { colors } from "../../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	height: 10vh;
	padding-left: 5.5rem;
	padding-right: 5.5%;
	background-color: white;
	border-bottom: 1px solid #e0e0e0;

	.a {
		display: flex;
		flex-direction: row;
		width: 30%;
		height: 100%;
	}

	.b {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		width: 50%;
		height: 100%;

		.mid {
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 100%;
			height: 100%;

			.input {
				display: flex;
				align-items: center;
				width: 100%;
				height: 2.8rem;
				background-color: #f2f2f2;
				padding-left: 0.75rem;
				padding-right: 0.75rem;
				border-radius: 12px;
			}
		}

		.inputPesquisa {
			height: 90%;
			width: 100%;
			margin-left: 1rem;
			margin-right: 1rem;
			background-color: transparent;
			border: none;
		}

		.reset {
			display: block;
			align-items: center;
			background-color: transparent;
			border: none;
			width: 30px;
			height: 25px;
		}

		.rigth {
			display: flex;
			flex-direction: row;
			height: 100%;

			.opcoes {
				margin-left: 2.1rem;
				background-color: transparent;
				border: none;
			}
		}
	}
	.boxBell {
		position: relative;
	}
	.boxNewBell {
		position: absolute;
		top: -0.2rem;
		right: -0.1rem;
		color: white;
		font-size: 0.8rem;
		font-weight: bold;
		padding: 0.3rem 0.4rem;
		border-radius: 15rem;
		background-color: ${colors.secondary_green_k_main};
		width: 1rem;
		height: 1rem;
	}

	.left {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		width: 70%;
		height: 100%;

		@media only screen and (max-width: 1360px) {
			width: 100%;
		}
	}

	.labelsDiv {
		display: flex;
		align-items: flex-end;
	}

	.selecao {
		display: block;
		height: 100%;
		width: 6rem;
		color: ${colors.primary_blue_k_main};
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		display: block;
		height: 100%;
		width: 6rem;
		color: ${colors.primary_blue_k_dark_2};
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.pulse {
		display: block;
		width: 30px;
		height: 25px;
		border-radius: 50%;
		background: transparent;
		cursor: pointer;
		box-shadow: 0 0 0 ${colors.primary_blue_k_dark_2};
		animation: pulse 2s infinite;
		border: none;
	}
	.pulse:hover {
		animation: none;
	}

	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
		}
		70% {
			-webkit-box-shadow: 0 0 0 10px ${colors.primary_blue_k_dark_2};
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
			box-shadow: 0 0 0 0 ${colors.primary_blue_k_dark_2};
		}
		70% {
			-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		}
	}
`;

export const exercicesWithNoTutorial = [
	'Flexão plantar',	
	'Extensão do pescoço',
	'Rotação do pescoço',
	'Pronação do antebraço',
	'Desvio Ulnar do punho',
	'Desvio Radial do punho',
	'Supinação do antebraço',
	'lantiflexã',
	'Extensão de punho',
	'Inversão resistida',
	'Eversão resistida',
];
import styled from "styled-components";

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	.labelForInput {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}

	.conteinerImg {
		background-color: #e5e5e5;
		width: 5.3rem;
		height: 5.3rem;
		border-radius: 50%;
		padding: 4.5px;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.camera {
		display: flex;
		margin-left: 75px;
		width: 45px;
		height: 45px;
		margin-top: -40px;
	}
`;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Camera from "../../assets/icons/camera.svg";
import Perfil from "../../assets/icons/perfil.svg";
import { AddPacAvalActions } from "../../store/ducks/add_pac_aval";
import { Container } from "./styles";

const FotoPerfil: React.FC = () => {
	const dispatch = useDispatch();

	const [img, setImg] = useState<any>();
	function getBase64(event: any) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setImg(reader.result);
		};
		reader.onerror = function (error) {
			console.error("Error: ", error);
		};
	}

	useEffect(() => {
		dispatch(AddPacAvalActions.set_photo(img));
	}, [img]);

	return (
		<Container className="DivInput">
			<input
				id="img"
				type="file"
				onChange={getBase64}
				style={{ display: "none" }}
				accept="image/*"
			/>
			<label htmlFor="img" className="labelForInput">
				<div className="conteinerImg">
					<img src={img ? img : Perfil} alt="" className="imgForInput" />
				</div>
				<img src={Camera} alt="" className="camera" />
			</label>
		</Container>
	);
};

export default FotoPerfil;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 90vh;
	width: 90vw;
	

	@media only screen and (min-width: 1280px) {
		height: 90vh;
		width: 90vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 90vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 80vh;
		width: 80vw;
	}

	.selecionarTodos {
		font-size: 1rem;

		color: #001574;

		cursor: pointer;

		width: 98%;

		display: flex;
		justify-content: end;
	}

	.close {
		width: 21px;

		position: absolute;
		right: 2.5rem;
		top: 2.5rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 2rem;
		width: 12%;
	}

	.divText {
		width: 65%;
	}

	.text {
		color: #b3b3b3;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}

	.textSucesso {
		width: 50%;
		color: #000;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}

	.button {
		width: 50%;
		margin-top: 3.25rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 1.3rem 3.3rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}

	.todoPeriodo {
		margin-left: 2rem;
		height: 3rem;
		padding: 0 1rem;

		border: 1px solid rgba(0, 0, 0, 0.18);
		border-radius: 1px;

		background: transparent;
	}

	.bordaAzul {
		border: 1px solid #001574;
		transition: all 0.2s ease-in;
	}
`;

export const ContainerSuccess = styled.div`
	height: 100%;
	width: 50%;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.alert {
		margin-bottom: 2rem;
		width: 20%;
	}

	.divText {
		width: 100%;
	}

	.textSucesso {
		width: 100%;
		color: #000;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
		margin-bottom: 5rem;
	}
	.subTextSucesso {
		width: 100%;
		color: #666666;
		font-weight: 500;
		font-family: Roboto;
		font-size: 18px;
		line-height: 30.47px;
		text-align: center;
		margin-bottom: 5rem;
	}

	.divAgosto {
		p {
			width: 100%;
			color: #000;
			font-weight: 500;
			font-family: Roboto;
			font-size: 26px;
			line-height: 30.47px;
			text-align: center;
			margin-bottom: 0.5rem;
		}
		.subtitle {
			color: ${colors.primary_blue_k_main};
			font-weight: 900;
		}
		.description {
			color: #666666;
			font-size: 20px;
		}

		.divButton {
			display: flex;
			justify-content: space-between;

			a {
				width: 40%;
				margin-top: 3.25rem;

				font-family: Roboto;
				font-weight: 700;
				font-size: 1.5rem;

				color: ${colors.primary_blue_k_main};
				background: transparent;

				line-height: 1.758rem;
				padding: 1.3rem 3.3rem;

				border: 2px solid ${colors.primary_blue_k_main};
				border-radius: 0.5rem;

				transition: all 0.3s ease;
			}

			button {
				width: 40%;
				margin-top: 3.25rem;

				font-family: Roboto;
				font-weight: 700;
				font-size: 1.5rem;

				color: ${colors.primary_blue_k_main};
				background: transparent;

				line-height: 1.758rem;
				padding: 1.3rem 3.3rem;

				border: 2px solid ${colors.primary_blue_k_main};
				border-radius: 0.5rem;

				transition: all 0.3s ease;
			}

			.saibaMais {
				background-color: ${colors.primary_blue_k_main};
				color: white;
			}
		}
	}

	.button {
		width: 50%;
		margin-top: 3.25rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 1.3rem 3.3rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}
`;

export const ContainerList = styled.div`
	height: 100%;
	width: 65%;
	color: black;

	padding: 4rem 6rem;

	.alert {
		margin-bottom: 2rem;
		width: 20%;
	}

	.divText {
		width: 65%;
	}

	.text {
		width: 100%;
		color: #000;
		font-weight: 500;
		font-family: Roboto;
		font-size: 2rem;
		line-height: 30.47px;
		display: flex;
	}

	.button {
		width: 50%;
		margin-top: 3.25rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 1.3rem 3.3rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}

	.lista {
		margin-top: 3rem;
		width: 100%;
		height: 70%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.ficha {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 6.5rem;
		width: 100%;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;

		.left {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 5rem;
			height: 100%;
			margin-right: 1.25rem;
		}

		.mid {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			cursor: pointer;

			.midSup {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				height: 50%;

				.exercicio {
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
					color: #5c5b5b;
					margin-left: 0.375rem;
				}
			}

			.midInf {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				width: 100%;
				height: 50%;

				.sagitalSelected {
					background-color: ${colors.secondary_green_k_medium_1};
					padding-top: 0.313rem;
					padding-bottom: 0.3rem;
					padding-left: 0.75rem;
					padding-right: 0.75rem;
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #ffffff;
					border-radius: 32px;
					margin-right: 0.87rem;
					border: solid 2px ${colors.secondary_green_k_medium_1};
				}

				.sagital {
					background-color: transparent;
					padding-top: 0.313rem;
					padding-bottom: 0.3rem;
					padding-left: 0.75rem;
					padding-right: 0.75rem;
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #8d8d8d;
					border-radius: 32px;
					margin-right: 0.87rem;
					border: solid 2px #8d8d8d;
				}
			}
		}

		@media only screen and (max-height: 960px) {
			.lista {
				height: 50%;
			}
		}
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;

		background-color: red;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		margin-right: 2rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.botoes {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 25%;
	}

	.continuar {
		margin-top: 0.5rem;

		width: 31.25rem;
		height: 3.625rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.25rem;

		color: #ffffff;
		background: ${colors.primary_blue_k_main};

		line-height: 1.758rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.voltar {
		width: 31.25rem;
		height: 3.625rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.25rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;

		border: 0;
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}
`;

export const ContainerData = styled.div`
	height: 85%;
	width: 85%;
	color: black;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.texto {
		color: #000;
		font-weight: 500;
		font-family: Roboto;
		font-size: 32px;
		margin-bottom: 2rem;
	}

	.button {
		width: 50%;
		margin-top: 3.25rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 1.3rem 3.3rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}

	.tipo {
		width: 40%;

		.choice {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.buttons {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		.select {
			width: 1.5rem;
			margin-bottom: 2rem;

			fill: ${colors.secondary_green_k_medium_1};
		}

		.titulo {
			font-weight: 700;
			font-size: 18px;
			color: #5c5b5b;
			margin-bottom: 0.6rem;
		}

		.bordaNormal {
			border: 1px solid #fff;
			transition: all 0.2s ease-in;
		}

		.bordaAzul {
			border: 1px solid #001574;
			transition: all 0.2s ease-in;
		}

		.card {
			width: 23.063rem;
			height: 6.75rem;

			margin-top: 2rem;

			border-radius: 1rem;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);

			display: flex;
			align-items: center;
			justify-content: space-around;

			.bodyCard {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 2rem;
			}

			.textos {
				width: 13rem;
				margin-bottom: 2.5rem;
				padding-right: 1rem;
			}

			label {
				cursor: pointer;
				font-size: 1.5rem;
				font-weight: 500;
				margin-top: 1rem;
				color: #7f7f7f;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				svg {
					margin-top: 1.2rem;
					margin-bottom: 0.5rem;
				}

				.svg {
					margin-top: 1.2rem;
					margin-bottom: 1.5rem;
				}

				span {
					font-size: 0.7rem;
					font-weight: 400;
					color: #7f7f7f;
				}
			}
		}

		.corNormal {
			margin-top: 1rem;
			color: #7f7f7f;
			transition: all 0.2s ease-in;
		}

		.corSelected {
			margin-top: 1rem;
			color: ${colors.primary_blue_k_main};
			transition: all 0.2s ease-in;
		}

		.select {
			width: 1.5rem;
			margin-bottom: 2rem;

			fill: #001574;
			transition: all 0.2s ease-in;
		}

		.groupRadio {
			margin-right: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			transition: all 0.2s ease-in;
		}

		.groupRadio input {
			opacity: 0;
			cursor: pointer;
			z-index: 999;
			height: 25px;
			transition: all 0.2s ease-in;
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
			border-radius: 50%;
			transition: all 0.2s ease-in;
			background-color: #eaebeb;
		}

		.checkmarkSeleted {
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
			border-radius: 50%;
			transition: all 0.2s ease-in;
			background-color: #001574;
			border: 0.3rem solid #eaebeb;
		}

		.textAvFunc {
			font-size: 0.8rem;
			font-weight: 400;
			width: 80%;

			text-align: center;
		}
	}

	.calendario {
		width: 60%;

		.botoesCalendarios {
			display: flex;
		}

		p {
			margin-bottom: 3rem;
		}
	}

	.Body {
		display: flex;
		width: 100%;
	}

	.footer {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		margin-top: 3rem;

		button {
			font-family: Roboto;
			font-weight: 700;
			font-size: 1.125em;

			color: #fff;
			background: ${colors.primary_blue_k_main};

			line-height: 1.758rem;

			border: 2px solid ${colors.primary_blue_k_main};
			border-radius: 0.5rem;

			transition: all 0.3s ease;

			height: 3.625rem;
			width: 31.25rem;
		}
		button:disabled {
			background: #a9a9a9;
			border: 2px solid #a9a9a9;
			pointer-events: none;
		}
	}
`;

export const ContainerEmail = styled.div`
	height: 95%;
	width: 50%;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	
	overflow-y: auto;
	
	.alert {
		margin-bottom: 2rem;
		width: 20%;
	}
	
	.divText {
		width: 80%;
		margin-bottom: 2rem;
	}
	
	.text {
	    width: 100%;
		color: #000;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}
	
	.subText {
		font-weight: 400;
		font-size: 1.5rem;
		color: #8c8e86;
		margin-top: 1.875rem;
	}

	.button {
		width: 50%;
		margin-top: 3.25rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 1.3rem 3.3rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 3.75rem;

		p {
			font-weight: 400;
			font-size: 1.25rem;

			margin-bottom: 1.75rem;

			color: #5c5b5b;
		}

		button {
			font-family: Roboto;
			font-weight: 700;
			font-size: 1.125rem;

			height: 3.625rem;

			margin-top: 2rem;
			margin-bottom:0.5rem;

			color: #fff;
			background: ${colors.primary_blue_k_main};

			line-height: 1.758rem;

			border: 2px solid ${colors.primary_blue_k_main};
			border-radius: 0.5rem;

			transition: all 0.3s ease;
		}

		button:hover {
			transform: translateY(-2px);
		}
	}

	.buttons {
		width: 100%;
		justify-content: space-between;
	}

	.fPaciente {
		height: 3.625rem;
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.125rem;

		color: white;
		background: ${colors.primary_blue_k_main};

		line-height: 1.758rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;

		svg {
			margin-right: 0.5rem;
		}
	}

	.fPaciente:hover {
		transform: translateY(-2px);
		background-color: transparent;
		color: ${colors.primary_blue_k_main};
	}

	.baixar {
		height: 3.625rem;
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.125rem;

		color: ${colors.primary_blue_k_light_1};
		background: transparent;

		line-height: 1.758rem;

		border: 2px solid ${colors.primary_blue_k_light_1};
		border-radius: 0.5rem;

		transition: all 0.3s ease;

		svg {
			margin-right: 0.5rem;
		}
	}

	.baixar:hover {
		transform: translateY(-2px);
	}

	.voltar {
		height: 3.625rem;
		width: 100%;

		display: flex;
		align-items: flex-end;
		justify-content: center;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.125rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		border: 0;

		transition: all 0.3s ease;

		svg {
			margin-right: 0.5rem;
		}
	}

	.globalInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.input {
		width: 100%;
		margin-left: 0.5vw;
		background-color: white;
		color: #4d4d4d;
		font-weight: 500;
		border: none;
	}

	.icon {
		margin-bottom: 5px;
	}

	.eyeButton {
		background-color: white;
		border: none;
	}

	.divhr {
		width: 100%;
		margin-top: 0.5vh;
	}

	.hr {
		width: 100%;
		margin-top: 0.5vh;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${colors.tertiary_red_1_dark};
		color: ${colors.tertiary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${colors.tertiary_red_1_dark};
	}
`;
export const ContainerAnamnese = styled.div`
	height: 100%;
	width: 65%;
	color: black;

	padding: 4rem 6rem;

	.alert {
		margin-bottom: 2rem;
		width: 20%;
	}

	.divText {
		width: 65%;
	}

	.text {
		width: 100%;
		color: #000;
		font-weight: 500;
		font-family: Roboto;
		font-size: 2rem;
		line-height: 30.47px;
		display: flex;
	}

	.button {
		width: 50%;
		margin-top: 3.25rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 1.3rem 3.3rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}

	.lista {
		margin-top: 3rem;
		width: 100%;
		height: 70%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.ficha {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 6.5rem;
		width: 100%;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;

		.left {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 5rem;
			height: 100%;
			margin-right: 1.25rem;
		}

		.mid {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			cursor: pointer;

			.midSup {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				height: 50%;

				.exercicio {
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
					color: #5c5b5b;
					margin-left: 0.375rem;
				}
			}

			.midInf {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				width: 100%;
				height: 50%;

				.sagitalSelected {
					background-color: #001574;
					padding-top: 0.313rem;
					padding-bottom: 0.3rem;
					padding-left: 0.75rem;
					padding-right: 0.75rem;
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #ffffff;
					border-radius: 32px;
					margin-right: 0.87rem;
					border: solid 2px #001574;
				}

				.sagital {
					background-color: transparent;
					padding-top: 0.313rem;
					padding-bottom: 0.3rem;
					padding-left: 0.75rem;
					padding-right: 0.75rem;
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #8d8d8d;
					border-radius: 32px;
					margin-right: 0.87rem;
					border: solid 2px #8d8d8d;
				}
			}
		}

		@media only screen and (max-height: 960px) {
			.lista {
				height: 50%;
			}
		}
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;

		background-color: red;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		margin-right: 2rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.botoes {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 25%;
	}

	.continuar {
		margin-top: 0.5rem;

		width: 31.25rem;
		height: 3.625rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.25rem;

		color: #ffffff;
		background: ${colors.primary_blue_k_main};

		line-height: 1.758rem;

		border: 2px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.voltar {
		width: 31.25rem;
		height: 3.625rem;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.25rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;

		border: 0;
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}
`;

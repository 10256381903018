import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { DashBoardActions } from "../../../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../../../store/ducks/manage_pac_aval";
import { QuestionarioSaga } from "../../../../../store/sagas/manage_pac_aval";
import {
	TypeButtonsQuestionnaire,
	TypesMenuInfoProps,
	TypesQuestionnaire,
} from "../../../../../utils/types";
import { Button } from "./styles";

interface Props {
	btns: any;
	back: any;
	questionAsync: boolean | undefined;
}
const BotoesTypes: React.FC<Props> = ({ btns, back, questionAsync }) => {
	const dispatch = useDispatch();

	const botoes = btns.filter(
		(e: any) => e.TELA_CONTEUDO_TIPO.substr(0, 2) === "BT"
	);

	const finalizar = (tipo: string) => {
		dispatch(
			PacAvalActions.set_questionario_respostas_concluido({
				CONCLUIDO: "S",
				TIPO: tipo,
			})
		);
		dispatch(DashBoardActions.set_select_info(TypesMenuInfoProps.BTN_MENU));
		dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.MENU));
		if (!questionAsync) {
			dispatch(PacAvalActions.set_reset_questionario());
		}
		QuestionarioSaga();
	};

	function render_button(item: any) {
		const classe =
			item.TELA_CONTEUDO_TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_AVALIACAO ||
			item.TELA_CONTEUDO_TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_TIPO ||
			item.TELA_CONTEUDO_TIPO === TypeButtonsQuestionnaire.BT_FINALIZAR_SF_36
				? "final"
				: (item.TELA_CONTEUDO_TIPO ===
						TypeButtonsQuestionnaire.BT_VOLTAR_ANAMNESE_AVALIACAO ||
						item.TELA_CONTEUDO_TIPO ===
							TypeButtonsQuestionnaire.BT_VOLTAR_ANAMNESE_TIPO ||
						item.TELA_CONTEUDO_TIPO ===
							TypeButtonsQuestionnaire.BT_VOLTAR_SF_36) &&
				  "voltar";

		return (
			<button
				key={item.TELA_ORDEM}
				className={`${classe}`}
				onClick={() =>
					classe === "final"
						? finalizar(item.TELA_CONTEUDO_TIPO)
						: classe === "voltar" && back()
				}
			>
				{item.TELA_CONTEUDO}
			</button>
		);
	}

	return (
		<Button questionAsync={questionAsync}>
			{botoes.map((e: any) => {
				return render_button(e);
			})}
		</Button>
	);
};

export default memo(BotoesTypes);

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 1.738rem;

		position: absolute;
		right: 2rem;
		top: 2rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 3rem;
		width: 12%;
	}

	.divText {
		width: 65%;
	}

	.text {
		color: #001574;
		font-weight: bold;
		font-family: Roboto;
		font-size: 20px;
		line-height: 30.47px;
		text-align: center;
		margin-bottom: 2rem;
		margin-top: 2rem;

		b {
			color: #5c5b5b;
			font-weight: 500;
		}
	}

	.buttons {
		width: 70%;
		display: flex;
		justify-content: space-between;
		margin-top: 2rem;
	}

	.button {
		font-family: Roboto;
		font-size: 1.3rem;
		line-height: 1.758rem;

		color: #ffffff;

		width: 15rem;
		height: 5rem;

		border: 0;
		border-radius: 0.5rem;

		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
	}

	.excluir {
		border: 3px solid ${colors.tertiary_red_1_dark};
		background: ${colors.tertiary_red_1_dark};
		transition: all 0.3s ease;
	}

	.excluir:hover {
		background: transparent;
		color: ${colors.tertiary_red_1_dark};
	}

	.cancelar {
		border: 3px solid #7F7F7F;
		background: #7F7F7F;
		transition: all 0.3s ease;
	}

	.cancelar:hover {
		background: transparent;
		color: #7F7F7F;
	}
	.containerFrases {
		display: flex;
		flex-direction: column;
	}
	.DesejaRemover {
		font-size: 25px;
		font-weight: bold;
		color: #666666;
	}
	.QtdPacientes {
		font-size: 25px;
		font-weight: bold;
		color: #E50000;
	}
`;

import { push } from "connected-react-router";
import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { TextsProps } from "../../database/txt.interface";
import { EndPoint } from "../../interfaces/config.interface";
import {
	PacientesProps,
	UserProps,
} from "../../interfaces/pacientes.interface";
import { TypesErrorRequest, TypesModalAviso } from "../../utils/types";
import { AddPacAvalActions, Types } from "../ducks/add_pac_aval";
import { AppActions } from "../ducks/app";
import { DashBoardActions } from "../ducks/dashboard";
import { AmplitudeLog } from "../../utils/amplitude";
import * as Sentry from "@sentry/react";

function* registerPaci({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const user_paci_avatar_64: any = yield select(
		(state) => state.add_pac_aval.user_paci_avatar_64
	);

	const paci_edition: PacientesProps = yield select(
		(state: any) => state.add_pac_aval.paci_edition
	);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const language: any = yield select((state) => state.configs.language);

	async function apiCall() {
		try {
			const dat = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				txIdioma: language?.toLowerCase(),
				...payload,
				USUARIO_COMENTARIO: "",
				USUARIO_NACIONALIDADE: "Brazil",
				TOKEN: user_logado.token,
			};

			const dat_edition = {
				"0": {
					TOKEN: user_logado.token,
				},
				"1": {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					txIdioma: language?.toLowerCase(),
					...payload,
					ID_USUARIO: paci_edition?.ID_USUARIO,
					USUARIO_FOTO:
						paci_edition?.USUARIO_FOTO && !user_paci_avatar_64
							? paci_edition?.USUARIO_FOTO
							: !!user_paci_avatar_64
							? `${user_paci_avatar_64}`
							: "",
					USUARIO_COMENTARIO: "Recuperação de LCA.",
					USUARIO_NACIONALIDADE: "Brazil",
				},
			};

			const url = paci_edition
				? `${process.env.REACT_APP_BASE_URL}usuarios/usuarios_editar.php`
				: `${process.env.REACT_APP_BASE_URL}usuarios/usuarios_adicionar.php`;

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(paci_edition ? dat_edition : dat),
			};
			const res = await fetch(url, requestOptions);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: usuarios_adicionar.php", "error");
			throw e;
		}
	}

	if (!paci_edition) {
		try {
			const data = yield call(apiCall);
			let message = "";
			if (data.status == TypesErrorRequest.FAIL) {
				if (data.code == -1) {
					message = current_language?.cadastro_falhou;
				}
				if (data.code == 1) {
					message = current_language?.erro_usuario_desta_conta;
				}
				if (data.code == 2) {
					message = current_language?.erro_usuario_outra_conta;
				}
				if (data.code == 3) {
					message = current_language?.erro_avaliador_desta_conta;
				}
				if (data.code == 4) {
					message = current_language?.erro_avaliador_outra_conta;
				}
				if (data.code == 5) {
					message = current_language?.erro_paciente_desta_conta;
				}
				if (data.code == 6) {
					message = data.message
				}
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			} else if (data.status == TypesErrorRequest.SUCCESS) {
				AmplitudeLog("paciente_criado", {
					body: {
						paciente: payload,
					},
				});
				if (data.code == 0) {
					message = current_language?.cadastro_concluido;
					yield put(
						AppActions.set_toast_visible({
							status_toast: true,
							type_toast: TypesErrorRequest.SUCCESS,
							message_toast: message,
						})
					);
				}
				yield put(DashBoardActions.paciente_request());
				yield put(AddPacAvalActions.set_avatar_paci_64(null));
				yield put(AddPacAvalActions.set_avatar_eval_64(null));
				yield put(AddPacAvalActions.request_cancel());
				yield put(AddPacAvalActions.add_first_infos(null));
			}
		} catch (e) {
			yield put(AddPacAvalActions.request_cancel());
		} finally {
			yield put(AddPacAvalActions.request_cancel());
		}
	} else {
		try {
			const data = yield call(apiCall);
			let message = "";
			if (data.status == TypesErrorRequest.SUCCESS) {
				if (data.code == 0) {
					message = current_language?.edicao_concluida;
				}
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(DashBoardActions.paciente_request());
				yield put(AddPacAvalActions.set_edit_paci(null));
			} else {
				if (data.status == TypesErrorRequest.FAIL) {
					if (data.code == 1) {
						message = current_language?.erro_usuario_desta_conta;
					}
					if (data.code == 2) {
						message = current_language?.erro_usuario_outra_conta;
					}
					if (data.code == 3) {
						message = current_language?.erro_avaliador_desta_conta;
					}
					if (data.code == 4) {
						message = current_language?.erro_avaliador_outra_conta;
					}
					if (data.code == 5) {
						message = current_language?.erro_paciente_desta_conta;
					}
					yield put(
						AppActions.set_toast_visible({
							status_toast: true,
							type_toast: TypesErrorRequest.FAIL,
							message_toast: message,
						})
					);
				}
			}
		} catch (e) {
			yield put(AddPacAvalActions.request_cancel());
		} finally {
			yield put(AddPacAvalActions.request_cancel());
		}
	}
}

function* registerEval({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const user_eval_avatar_64: any = yield select(
		(state) => state.add_pac_aval.user_eval_avatar_64
	);

	const paci_edition: PacientesProps = yield select(
		(state: any) => state.add_pac_aval.paci_edition
	);
	const is_open_paciente = yield select(
		(state: any) => state.add_pac_aval.paci_edition
	);

	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const language: any = yield select((state) => state.configs.language);

	async function apiCall() {
		try {
			const dat = {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txIdioma: language?.toLowerCase(),
						...payload,
						USUARIO_FOTO: !!user_eval_avatar_64 ? user_eval_avatar_64 : "",
						USUARIO_COMENTARIO: "Recuperação de LCA.",
						USUARIO_NACIONALIDADE: "Brazil",
				  };

			const dat_edition = {
						"0": {
							TOKEN: user_logado.token,
						},
						"1": {
							txDataHora: moment(new Date())
								.format("YYYY-MM-DD HH:mm:ss")
								.toString(),
							txIdioma: language?.toLowerCase(),
							...payload,
							ID_USUARIO: paci_edition?.ID_USUARIO,
							USUARIO_FOTO: !!user_eval_avatar_64 ? user_eval_avatar_64 : "",
							USUARIO_COMENTARIO: "Recuperação de LCA.",
							USUARIO_NACIONALIDADE: "Brazil",
							USUARIO_AVALIADOR: paci_edition?.USUARIO_AVALIADOR,
						},
				  };

			const url = paci_edition
				? `${process.env.REACT_APP_BASE_URL}usuarios/usuarios_editar.php`
				: `${process.env.REACT_APP_BASE_URL}usuarios/usuarios_adicionar.php`;

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(paci_edition ? dat_edition : dat),
			};
			const res = await fetch(url, requestOptions);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	if (!paci_edition) {
		try {
			const data = yield call(apiCall);
			let message = "";
			if (data.status == TypesErrorRequest.FAIL) {
				if (data.code == -1) {
					message = current_language?.cadastro_falhou;
				}
				if (data.code == 1) {
					message = current_language?.erro_usuario_desta_conta;
				}
				if (data.code == 2) {
					message = current_language?.erro_usuario_outra_conta;
				}
				if (data.code == 3) {
					message = current_language?.erro_avaliador_desta_conta;
				}
				if (data.code == 4) {
					message = current_language?.erro_avaliador_outra_conta;
				}
				if (data.code == 5) {
					message = current_language?.erro_paciente_desta_conta;
				}
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			} else {
				if (data.code == 0) {
					message = current_language?.cadastro_concluido;
					yield put(
						AppActions.set_toast_visible({
							status_toast: true,
							type_toast: TypesErrorRequest.SUCCESS,
							message_toast: message,
						})
					);
				}
				yield put(AddPacAvalActions.set_avatar_eval_64(null));
				yield put(AddPacAvalActions.set_avatar_paci_64(null));
				yield put(AddPacAvalActions.add_first_infos(null));
				yield put(DashBoardActions.avaliadores_request())
			}
		} catch (e) {
			yield put(AddPacAvalActions.request_cancel_evaluetor());
		} finally {
			yield put(AddPacAvalActions.request_cancel_evaluetor());
		}
	} else {
		try {
			const data = yield call(apiCall);
			let message = "";
			if (data.status == TypesErrorRequest.FAIL) {
				if (data.code == 1) {
					message = current_language?.erro_usuario_desta_conta;
				}
				if (data.code == 2) {
					message = current_language?.erro_usuario_outra_conta;
				}
				if (data.code == 3) {
					message = current_language?.erro_avaliador_desta_conta;
				}
				if (data.code == 4) {
					message = current_language?.erro_avaliador_outra_conta;
				}
				if (data.code == 5) {
					message = current_language?.erro_paciente_desta_conta;
				}
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			} else {
				if (data.code == 0) {
					message = current_language?.edicao_concluida;
				}
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(AddPacAvalActions.set_avatar_eval_64(null));
				yield put(AddPacAvalActions.set_avatar_paci_64(null));
				yield put(AddPacAvalActions.add_first_infos(null));
				yield put(DashBoardActions.avaliadores_request());
				yield put(AddPacAvalActions.set_edit_paci(null));

				const filtros_paginacao = {
					inicio: 0,
					fim: 6,
					filtros: [
						{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
						{ tipo: "USUARIO_NIVEL", valor: [3] },
						{tipo: "ID_USUARIO", valor:[paci_edition?.ID_USUARIO] }
					],
				};
				yield put(
					DashBoardActions.set_filtros_pacintes_request(filtros_paginacao)
				);
				yield put(DashBoardActions.paciente_request());
				yield put(DashBoardActions.mais_visitados_request());
				yield put(DashBoardActions.set_current_page_patient(1));
			}
		} catch (e) {
			console.error(e);
			yield put(AddPacAvalActions.request_cancel_evaluetor());
		} finally {
			yield put(AddPacAvalActions.request_cancel_evaluetor());
		}
	}
}

function* desativarPaciente( {payload} : any): any {

	const pacientes: PacientesProps[] = yield select(
		(state: any) => state.dashboard.pacientes
	);

	const avaliadores: PacientesProps[] = yield select(
		(state: any) => state.dashboard.avaliadores
	);
	const desativados: PacientesProps[] = yield select(
		(state: any) => state.dashboard.desativados
	);

	const current_language: TextsProps = yield select(
		(state) => state.configs.current_language
	);
	
	
	
	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify({...payload}),
			};

			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}usuarios/usuarios_desativar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: usuarios_desativar.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);

		if (data.status == TypesErrorRequest.SUCCESS) {
			yield put(DashBoardActions.paciente_request());
			const new_pacis = pacientes.filter(
				(paci) => paci.ID_USUARIO != payload.ID_USUARIO
			);
			yield put(DashBoardActions.paciente_success(new_pacis));

			const new_avals = avaliadores.filter(
				(aval) => aval.ID_USUARIO != payload.ID_USUARIO
			);
			yield put(DashBoardActions.set_avaliadores(new_avals));

			const findInAvals = avaliadores.find(
				(aval) => aval.ID_USUARIO == payload.ID_USUARIO
			);
			if (findInAvals) {
				yield put(
					DashBoardActions.set_desativados([...desativados, findInAvals])
				);
			}

			const findInPacis = pacientes.find(
				(aval) => aval.ID_USUARIO == payload.ID_USUARIO
			);
			if (findInPacis) {
				yield put(
					DashBoardActions.set_desativados([...desativados, findInPacis])
				);
			}

			yield put(DashBoardActions.avaliadores_request());
			yield put(DashBoardActions.mais_visitados_request())

			let message = "";
			if (data.code == 0) {
				message = current_language?.desativacao_concluida_desfazer;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				const is_open_toast = yield select(
					(state: any) => state.app.is_open_toast
				);
			}
		}
	} catch (e) {
		yield put(AddPacAvalActions.request_cancel_paci());
	} finally {
		yield put(AddPacAvalActions.request_cancel_paci());
	}
}

function* recuperarPaciente({ payload }: any): any {

	const current_language: TextsProps = yield select(
		(state) => state.configs.current_language
	);
	const call_pagination_filter: boolean = yield select(
		(state) => state.add_pac_aval.call_filtro
	);

	async function apiCall() {
		try {
			
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify({...payload}),
			};

			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}usuarios/usuarios_recuperar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: usuarios_recuperar.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);

		if (data.status == TypesErrorRequest.SUCCESS) {
			if( call_pagination_filter){

				const filtros_paginacao = {
					inicio: 0,
					fim: 200,
					filtros: [
						{ tipo: "USUARIO_EXCLUIDO", valor: "S" },
						{ tipo: "USUARIO_NIVEL", valor: [1, 2, 3] },
					],
				};
	
				yield put(
					DashBoardActions.set_filtros_pacintes_request(filtros_paginacao)
				);
			}
			yield put(DashBoardActions.paciente_request());

			let message = "";
			if (data.code == "0") {
				message = current_language?.recuperacao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		yield put(AddPacAvalActions.request_recuperar_cancel_paci());
	} finally {
		yield put(AddPacAvalActions.request_recuperar_cancel_paci());
	}
}

function* recorrente({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dat: any = {};
			dat["0"] = { TOKEN: user_logado.token };
			dat["1"] = { ...payload };

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}logs/log_acessos.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: log_acessos.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
	} catch (e) {
		console.error(e);
	} finally {
	}
}

export function* AddPaciAvalSaga(): any {
	return yield all([
		takeLatest(Types.SET_RECORRENTE, recorrente),
		takeLatest(Types.REGISTER_REQUEST_PACI, registerPaci),
		takeLatest(Types.REGISTER_REQUEST_EVALUETOR, registerEval),
		takeLatest(Types.DESATIVAR_PACI_REQUEST, desativarPaciente),
		takeLatest(Types.RECUPERAR_PACI_REQUEST, recuperarPaciente),
	]);
}

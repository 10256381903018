import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../routes/history"

import buttonClose from "../../../assets/icons/buttonClose.svg";
import fail from "../../../assets/icons/fail.svg";
import success from "../../../assets/icons/success.svg";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorModal } from "../../../utils/types";
import { Container } from "./styles";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAlert: React.FC = () => {
	// comunica com redux
	const dispatch = useDispatch();

	const classes = useStyles();
	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const is_open =  useSelector((state: any) => state.app.is_open);
	const message = useSelector((state: any) => state.app.message);
	const redirect = useSelector((state: any) => state.app.redirect);
	const type = useSelector((state: any) => state.app.type);
	console.log("🚀 ~ type:", type)

	const handleClose = () => {
		dispatch(AppActions.clear_modal_visible());
		if(redirect?.length) history.push(redirect);
	};

	if (message == "conectado") {
		setTimeout(() => {
			dispatch(AppActions.clear_modal_visible());
		}, 1000);
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open}>
					<Container className={classes.paper}>
						<img
							className="close"
							src={buttonClose}
							onClick={handleClose}
							alt="Close"
						/>
						{type == 'success' ? (
							<img className="alert" src={success} alt="Alerta" />
						) : (
							type == 'fail' && (
								<img className="alert" src={fail} alt="Alerta" />
							)
						)}
						<div className="divText">
							<p id="transition-modal-description" className="text">
								{message}
							</p>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalAlert);

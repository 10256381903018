import React, { memo } from "react";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../Dashboard/ContentDash";

const MeuPlanoController: React.FC = () => {
	return (
		<ViewPort>
			<Menu setMenu="conta"></Menu>
			<ContentDash listagem={"meuPlano"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default MeuPlanoController;

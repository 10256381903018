import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	@media only screen and (max-width: 1440px) {
		height: 70vh;
		width: 722px;
	}

	.close {
		width: 1.738rem;

		position: absolute;
		right: 2rem;
		top: 2rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 3rem;
		width: 12%;
	}

	.divText {
		width: 80%;
		margin-top: 4rem;
	}

	.text {
		color: #b3b3b3;
		font-weight: 400;
		font-family: Roboto;
		font-size: 1.625rem;
		line-height: 30.47px;
		text-align: center;
		margin-bottom: 2rem;

		b {
			color: #5c5b5b;
			font-weight: 500;
		}
	}

	.numeroPacientes {
		color: ${colors.tertiary_red_1_dark};
	}

	.botoes {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 5rem;

		.botao {
			cursor: pointer;

			display: flex;
			justify-content: center;
			align-items: center;

			width: 10.625rem;
			height: 2.813rem;

			border-radius: 2rem;

			border: 1px solid ${colors.neutral_grey_k_50};

			transition: all 0.2s linear;

			p {
				font-weight: 700;
				font-size: 1.15rem;

				color: #8c8e86;
			}
		}

		.botao:hover {
			background: ${colors.secondary_green_k_medium_1};

			border: 1px solid ${colors.secondary_green_k_medium_1};

			p {
				font-weight: 700;
				font-size: 1.15rem;

				color: #fff;
			}
		}

		.botaoMarcado {
			background: ${colors.secondary_green_k_medium_1};

			border: 1px solid ${colors.secondary_green_k_medium_1};

			p {
				font-weight: 700;
				font-size: 1.15rem;

				color: #fff;
			}
		}
	}

	.button {
		font-family: Roboto;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 1.758rem;

		color: #ffffff;

		background: #e5e5e5;

		width: 23rem;
		height: 3.3rem;

		border: 0;
		border-radius: 0.5rem;

		margin-bottom: 3rem;

		cursor: auto;
	}

	.upgrade {
		border: 3px solid ${colors.primary_blue_k_main};
		background: ${colors.primary_blue_k_main};
		transition: all 0.3s ease;
		color: ${colors.primary_blue_k_main};

		color: #ffffff;

		cursor: pointer;
	}
`;

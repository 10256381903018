import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 70vh;
	width: 40vw;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	z-index: 10000;

	.close {
		width: 1.738rem;

		position: absolute;
		right: 2rem;
		top: 2rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 3rem;
		width: 12%;
	}

	.divText {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		width: 80%;
		height: 60%;
	}

	.titulo {
		color: #404040;
		font-weight: 400;
		font-family: Roboto;
		font-size: 1.5rem;
		text-align: center;
		width: 70%;

		b {
			color: #404040;
			font-weight: 700;
		}
	}

	.text {
		color: #404040;
		font-weight: 300;
		font-family: Roboto;
		font-size: 1.2rem;
		text-align: center;
	}

	.chamada {
		color: #404040;
		font-weight: 500;
		font-family: Roboto;
		font-size: 1.2rem;
		text-align: center;
	}

	.numeroPacientes {
		color: ${colors.tertiary_red_1_dark};
	}

	.button {
		font-family: Roboto;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 1.758rem;

		color: #ffffff;

		width: 30%;
		height: 3.3rem;

		border: 0;
		border-radius: 0.8rem;

		margin-top: 3rem;

		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	.upgrade {
		background: ${colors.primary_blue_k_main};
		transition: all 0.3s ease;
		color: #ffffff;
	}

	.upgrade:hover {
		background: ${colors.primary_blue_k_dark_2};
		color: #ffffff;
	}

	@media only screen and (max-width: 1440px) {
		.titulo {
			width: 80%;
			margin: 0.5rem;
		}

		.text {
			margin: 0.5rem 0;
		}

		.chamada {
			margin: 0.5rem 0;
		}

		.button {
			margin-top: 1rem;
		}
	}
`;

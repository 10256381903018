import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 2.5rem;
	border-radius: 1.25rem;

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		color: #5c5b5b;
		margin-bottom: 4.3rem;
	}

	.titleMid {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		color: #5c5b5b;
		margin-bottom: 1.8rem;
	}

	.form {
		width: 100%;
		height: 100%;
	}

	.header {
		width: 100%;
		margin-bottom: 3.6rem;
	}

	.divInput {
		display: flex;
		align-items: flex-end;
		width: 100%;
		margin-bottom: 0.56rem;
	}

	.input {
		width: 100%;
		margin-left: 1rem;
		border: none;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #5c5b5b;
	}

	.hr {
		background-color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.divButtons {
		display: flex;

		button {
			padding: 1rem;
		}
	}

	.btn {
		width: 27.75rem;
		height: 11.62rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		border: none;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
		border-radius: 12px;
	}

	.btnSelected {
		width: 27.75rem;
		height: 11.62rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		border: none;
		border-radius: 12px;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
		border: 2px solid ${colors.secondary_green_k_medium_1};
		box-sizing: border-box;
		transition: all 0.1s linear;
	}

	.divTitleBtn {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
	}

	.titleBtn {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #5c5b5b;
	}

	.label {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 126.69%;
		text-align: center;
		color: #5c5b5b;
	}

	.content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: space-between;
		height: 100%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.inf {
		margin-top: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}

	.salve {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;

		background-color: ${colors.primary_blue_k_main};
		color: white;

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.salve:hover {
		background-color: ${colors.primary_blue_k_dark_2};
	}

	.cancel {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;

		background-color: transparent;
		color: ${colors.primary_blue_k_main};

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.cancel:hover {
		color: ${colors.primary_blue_k_dark_2};
	}

	.span {
		border: 2px solid #8c8e86;
		padding-bottom: 0.31rem;
		padding-top: 0.31rem;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		border-radius: 32px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: #8c8e86;
		margin-right: 1.12rem;
		cursor: pointer;
	}

	.spanSelected {
		border: 2px solid #001574;
		background-color: #001574;
		padding-bottom: 0.31rem;
		padding-top: 0.31rem;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		border-radius: 32px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: white;
		margin-right: 1.12rem;
		cursor: pointer;
	}

	.divTags {
		display: flex;
		flex-direction: row;
		margin-bottom: 1rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;

		.divSelecao {
			margin-right: 1rem;

			.buttonGrouping {
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				padding: 0.5rem;
				padding-bottom: 0.2rem;
				padding-top: 0.2rem;
				border-radius: 5px;
				border-width: 1px;
				border-color: #8c8e86;
				background-color: transparent;
			}

			.select {
				display: flex;
				flex-direction: column;
				align-items: center;
				border-color: #8c8e86;
				border: 1px solid gray;

				.option {
					margin-top: 5px;
					margin-bottom: 5px;
					cursor: pointer;
				}
			}
		}
	}

	.hrcor {
		background-color: ${colors.tertiary_red_1_dark};
		color: ${colors.tertiary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${colors.tertiary_red_1_dark};
	}
`;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	justify-content: space-between;
`;

export const Content = styled.div`
	display: flex;
`;

export const ContentBody = styled.div`
	display: flex;
	flex-direction: column;

	margin-right: 1rem;
`;

export const Cards = styled.div`
	width: 50%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	border-right: 1px solid #b3b3b3;

	padding-bottom: 3rem;

	.cardsList {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		div {
			margin: 0.5rem;
		}
	}
`;

export const Header = styled.div`
	width: 100%;

	h3 {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 28px;

		text-align: center;

		color: ${colors.primary_blue_k_main};
	}
`;

export const Body = styled.div`
	width: 100%;

	margin: 2rem 0;
	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 4px;
	}
`;

export const InicioFim = styled.div`
	width: 100%;

	.inicioParagrafo {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 300;
		font-size: 1.125rem;
		line-height: 119%;
		color: #252525;
	}

	.inicioItalico {
		font-style: italic;
	}
`;

export const Title = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 1.375rem;
	line-height: 120%;

	margin-bottom: 1.367rem;

	color: ${colors.primary_blue_k_dark_2};
`;

export const Question = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 120%;

	color: #404040;

	margin-bottom: 1.8rem;
`;

export const Description = styled.div`
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	font-size: 1rem;
	line-height: 119%;

	color: #000000;

	padding-bottom: 1.313rem;
	margin-bottom: 1.313rem;

	border-bottom: 1px solid #cbcbcb;
`;

export const List = styled.div`
	display: flex;
	flex-direction: column;

	border-top: 1px solid #b3b3b3;
`;

export const ItemList = styled.text`
	padding: 1.438rem 0;

	border-bottom: 1px solid #b3b3b3;
`;

export const Footer = styled.div`
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const Botoes = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	.btnSemPreenchimento {
		width: 9.063rem;
		height: 3.125rem;
		background-color: transparent;
		color: ${colors.primary_blue_k_main};

		border: 4px solid ${colors.primary_blue_k_main};
		border-radius: 8px;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 1.375rem;
		line-height: 120%;

		text-align: center;

		margin-right: 1rem;
	}

	.btnComPreenchimento {
		width: 9.063rem;
		height: 3.125rem;
		background-color: ${colors.primary_blue_k_main};
		color: #fcfcfc;

		border: 4px solid ${colors.primary_blue_k_main};
		border-radius: 8px;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 1.375rem;
		line-height: 120%;

		text-align: center;

		margin-left: 1rem;
	}

	.btnFinal {
		width: 15rem;
		height: 4.75rem;
		background-color: ${colors.primary_blue_k_main};
		color: #fcfcfc;

		border: 4px solid ${colors.primary_blue_k_main};
		border-radius: 8px;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 1.375rem;
		line-height: 120%;

		text-align: center;
	}
`;

export const ErroObrigatoriedade = styled.div`
	color: #d40000;
	-webkit-animation: shake 1s linear 2;
	animation: shake 1s linear 2;

	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;

	@-webkit-keyframes shake {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		5% {
			-webkit-transform: rotate(5deg);
			transform: rotate(5deg);
		}

		15% {
			-webkit-transform: rotate(-4deg);
			transform: rotate(-4deg);
		}

		25% {
			-webkit-transform: rotate(4deg);
			transform: rotate(4deg);
		}

		35% {
			-webkit-transform: rotate(-3deg);
			transform: rotate(-3deg);
		}

		45% {
			-webkit-transform: rotate(2deg);
			transform: rotate(2deg);
		}

		55% {
			-webkit-transform: rotate(-1deg);
			transform: rotate(-1deg);
		}

		60% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
	}

	@keyframes shake {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		5% {
			-webkit-transform: rotate(5deg);
			transform: rotate(5deg);
		}

		15% {
			-webkit-transform: rotate(-4deg);
			transform: rotate(-4deg);
		}

		25% {
			-webkit-transform: rotate(4deg);
			transform: rotate(4deg);
		}

		35% {
			-webkit-transform: rotate(-3deg);
			transform: rotate(-3deg);
		}

		45% {
			-webkit-transform: rotate(2deg);
			transform: rotate(2deg);
		}

		55% {
			-webkit-transform: rotate(-1deg);
			transform: rotate(-1deg);
		}

		60% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
	}
`;

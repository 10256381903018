import React, { useEffect } from "react";
import styled from "styled-components";

import Menu from "../../../../../components/Menu";
import BarraSuperiorControladora from "../../../../Dashboard/BarrasSuperiores/BarraSuperiorControladora";
import {
	Container,
	ListagemEcards,
} from "../../../../Dashboard/ContentDash/styles";
import { checkLanguage } from "../../../../../utils/texts";

import { useDispatch, useSelector } from "react-redux";

import DragProtocols from "..";

const DragProtoExerController: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	useEffect(() => {
		checkLanguage(language, dispatch);
	}, [language]);
	return (
		<ViewPort>
			<Menu setMenu="protocolos"></Menu>
			<Container>
				<BarraSuperiorControladora />
				<ListagemEcards>
					<Group>
						<DragProtocols />
					</Group>
				</ListagemEcards>
			</Container>
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
	color: rgba(140, 142, 134, 0, 8);
	background-color: #f5fbfc;
	min-width: 1247px;
`;

export const Group = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
`;

export default DragProtoExerController;

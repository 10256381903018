import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	background-color: white;
	padding: 0rem 4rem 3rem 5rem;
	border-radius: 1.12rem;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	height: 90vh;
	width: 90vw;
	position: relative;

	@media only screen and (max-width: 1280px) {
		height: 90vh;
		width: 90vw;
	}

	@media only screen and (max-width: 1440px) {
		height: 80vh;
		width: 90vw;
	}

	@media only screen and (max-width: 1680px) {
		height: 85vh;
		width: 80vw;
	}

	.sup {
		display: flex;
		flex-direction: row;
		height: 14.5rem;
		width: 100%;
	}
	.infoAvaliablePatient {
		display: flex;
		height: 20px;
		width: 100%;
		margin-top: 10px;
	}
	.info {
		color: #001574;
	}

	.NomeEperfil {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: flex-end;
		width: 50%;
	}

	.fechar {
		position: absolute;
		top: 0;
		right: 2rem;
		display: flex;
		width: 50%;
		height: 15%;
		justify-content: flex-end;
	}

	.botaofechar {
		background-color: transparent;
		border: none;
	}

	.NovoPc {
		width: 100%;
		font-size: 2rem;
		color: #000000;
	}

	.mid {
		display: flex;
		flex-direction: row;
		height: 28rem;
		width: 100%;
		background-color: white;
	}

	.form {
		width: 100%;
		height: 90%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding-right: 1rem;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.formP1 {
		display: flex;
		flex-direction: column;
		width: 50%;
		padding-right: 4rem;
		padding-top: 2rem;
		height: 100%;
		background-color: white;
	}

	.formP2 {
		display: flex;
		flex-direction: column;
		width: 50%;
		padding-left: 4rem;
		padding-top: 2rem;
		height: 100%;
		background-color: white;
	}

	.formP21 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	}

	.buttonEtiqueta {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		color: ${colors.primary_blue_k_main};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}

	.inf {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		width: 100%;
		background-color: white;
	}

	.buttonPerfil {
		background-color: white;
		border: none;
	}

	.perfil {
		width: 15%;
	}

	.global {
		width: 100%;
	}

	.globalInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.grupo {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.agrupar {
		display: flex;
		flex-direction: column;
		width: 47%;
	}

	.input {
		width: 100%;
		margin-left: 0.5vw;
		background-color: white;
		color: #8c8e86;
		font-weight: 500;
		border: none;
	}

	.icon {
		margin-bottom: 5px;
	}

	.divhr {
		width: 100%;
		margin-bottom: 2.75rem;
		margin-top: 0.5vh;
	}

	.divhrN {
		width: 100%;
		margin-bottom: 2.4rem;
		margin-top: 0.5vh;
	}

	.divhrCPF {
		width: 100%;
		margin-top: 0.5vh;
	}

	.hr {
		width: 100%;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${colors.tertiary_red_1_dark};
		color: ${colors.tertiary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${colors.tertiary_red_1_dark};
	}

	.concluir {
		width: 31.25rem;
		height: 3rem;
		background-color: ${colors.primary_blue_k_main};
		color: white;
		font-weight: 700;
		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.concluir:hover {
		background-color: ${colors.primary_blue_k_dark_2};
	}

	.voltar {
		width: 100%;
		height: 6vh;
		min-height: 6vh;
		background-color: white;
		color: ${colors.primary_blue_k_main};
		font-weight: 700;
		border: none;
		margin-bottom: 25px;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.voltar:hover {
		color: ${colors.primary_blue_k_dark_2};
	}

	.select {
		width: 100%;
		height: 30px;
		margin-left: 0.3vw;
		font-weight: 500;
		border: none;
		font-family: "Roboto", serif;
		font-size: 18px;
		color: #8c8e86;
	}

	select:hover,
	select:focus,
	select:active,
	select:checked {
		color: #8c8e86;
	}
`;

import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AnotacaoProps } from "../../../interfaces/anotacoes.interface";
import {
	PacientesProps,
} from "../../../interfaces/pacientes.interface";
import { AnotationActions } from "../../../store/ducks/anotacoes";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorRequest, TypesLanguage } from "../../../utils/types";
import ToastAlert from "../../toast";
import { Container, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalEditarAnotacao: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const language = useSelector((state: any) => state.configs.language);

	const classes = useStyles();

	const is_open_editar_anotacao: AnotacaoProps = useSelector(
		(state: any) => state.app.is_open_editar_anotacao
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_editar_anotacao(false));
	};

	//Pega o valor do input em tempo real
	const [value, setvalue]: any = useState<any>(0);

	const [isSearch, setIsSearch] = useState<boolean>(false);

	const isWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalue(e.target.value);
	};

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	let dataHora = moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString();

	if (language == TypesLanguage.PT) {
		dataHora = moment(new Date()).format("DD/MM/YYYY HH:mm:ss").toString();
	}

	//Form
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();


	useEffect(() => {
		if(is_open_editar_anotacao.ANOTACAO_TXT){
			setValue("text", is_open_editar_anotacao.ANOTACAO_TXT.replace(/\\n/gi,`${'\n'}`));
		}
	}, [paciente, is_open_editar_anotacao]);

	//Array enviar api
	const [aprovado, setAprovado] = useState(false);

	const onSubmit = async (data: any) => {
		setAprovado(true);
		const edit_etiqueta = {
			ID_ANOTACAO: is_open_editar_anotacao.ID_ANOTACAO,
			ANOTACAO_TXT: data.text,
		};
		AmplitudeLog('anotacao_editada', {body:edit_etiqueta})
		dispatch(AnotationActions.edit_anotation_request(edit_etiqueta));
		ModalCadastroClose();
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		if (type_toast == TypesErrorRequest.SUCCESS) {
			ModalCadastroClose();
		}
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	const windowWidth = window.innerWidth;

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={!!is_open_editar_anotacao}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={!!is_open_editar_anotacao}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<Content>
							{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
								<ToastAlert />
							) : (
								""
							)}
							<form action="" onSubmit={handleSubmit(onSubmit)}>
								<div className="sup">
									<p className="title">
										{current_language?.adicionar_anotacao_modal}
									</p>

									<div className="divAvaliador">
										<svg
											style={{ marginRight: 11 }}
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
												fill="#001574"
											/>
										</svg>
										<p className="avaliador">{paciente?.USUARIO_NOME}</p>
									</div>

									<p className="anotacao">
										{current_language?.anotacao} {dataHora}
									</p>

									<textarea
										className="textArea"
										id="text"
										cols={30}
										rows={windowWidth < 1440 ? 5 : 10}
										onKeyUp={(e) => isWriting(e)}
										{...register("text", {
											required: `${current_language?.login_input_senha}`,
										})}
									></textarea>
									<p className="limite">
										{value.length === undefined ? "0" : value.length}/1000
									</p>
									<div className="divhr">
										{errors.text && (
											<div className="error">{errors.text.message}</div>
										)}
									</div>
								</div>

								<div className="inf">
									<button
										type="submit"
										id="enviar"
										value="ENVIAR"
										className="salve"
									>
										{current_language?.pagamento_salvar}
									</button>
									<button
										id="cancel"
										type="button"
										className="cancel"
										onClick={()=> {
											ModalCadastroClose() 
											AmplitudeLog('cancelar_edicao_anotacao')
										}}
									>
										{current_language?.voltar}
									</button>
								</div>
							</form>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalEditarAnotacao);

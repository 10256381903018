import React from "react";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../Dashboard/ContentDash";

const ConfiguracoesController: React.FC = () => {
	return (
		<ViewPort>
			<Menu setMenu="configurar"></Menu>
			<ContentDash listagem={"configuracoes"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default ConfiguracoesController;

const messages = {
	pt: {
		translations: {
			login: {
				email: "Email",
				senha: "Senha",
				forget: "Esqueceu a senha?",
				enviar: "ENVIAR",
				cadastrar: "CADASTRAR",
				idioma: "Idioma",
				ajuda: "Ajuda",
			},
			cadastro: {
				nome: "Nome",
				email: "Email",
				celular: "Celular",
				senha: "Senha",
				confirmarsenha: "Confirme a senha",
				cpf: "CPF",
				nacionalidade: "Nacionalidade",
				concluir: "CONCLUIR",
				voltar: "Voltar",
			},
			recuperar_senha: {
				inserir: "Inserir o email do cadastro para redefinir a senha",
				exemplo: "exemplo@gmail.com",
				enviar: "ENVIAR",
				voltar: "Voltar",
			},
		},
	},
};

export { messages };

import { AvaliationActions } from "../../store/ducks/avaliation";
import { ConfigsActions } from "../../store/ducks/configs";
import store from "../../store/index";

const tara = 1;
let nomeDevice = "";
var services = '';
var characterist = '';
let previousBatteryLevel = null;

export function ConexaoBluetooth() {
	store.dispatch(ConfigsActions.set_bluetooth_result(false));
	store.dispatch(ConfigsActions.set_dino_battery(null));

	navigator.bluetooth
		.requestDevice({
			filters: [
				{ services: [0xffe0] },
				{ services: ["6e400001-b5a3-f393-e0a9-e50e24dcca9e"] },
				{ services: ["0000180f-0000-1000-8000-00805f9b34fb"] },
			],
		})
		.then((device) => {
			nomeDevice = device;
			if (
				device.name.substring(0, 8) === "E-lastic" ||
				device.name.substring(0, 6) === "HMSoft" ||
				device.name.substring(0, 11) === "E-lastic_v3"
			) {
				device.addEventListener("gattserverdisconnected", () => {
					store.dispatch(AvaliationActions.set_connect_flag(false));
					ConexaoBluetooth();
				});
				return device.gatt.connect();
			}
		})
		.then((server) => {
			if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
				monitorBatteryLevelWithNotifications(server);
				return server.getPrimaryService("6e400001-b5a3-f393-e0a9-e50e24dcca9e");
			}
			return server.getPrimaryService(0xffe0);
		})
		.then((service) => {
			services = service
			if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
				return service.getCharacteristic(
					"6e400003-b5a3-f393-e0a9-e50e24dcca9e"
				);
			}
			return service.getCharacteristic(0xffe1);
		})
		.then((characteristic) => {
			characterist = characteristic
			const dinamo_uuid = characteristic.service.uuid
			store.dispatch(AvaliationActions.set_connect_flag(true));

			function send_data_bluetooth (data) {
				let encoder = new TextEncoder('utf-8');

				if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
					try {
						services.getCharacteristic("6e400002-b5a3-f393-e0a9-e50e24dcca9e")
							.then(function (characte) {
								characte.writeValueWithoutResponse(encoder.encode(data))
						})
					} catch (e) {
						console.error(e, 'catch')
					}
				} else {
					characterist.writeValueWithoutResponse(encoder.encode(data))
				}
			}
			
			if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
					send_data_bluetooth("EL_MAC[]")
			}

			return characteristic.startNotifications().then(() => {
				characteristic.addEventListener(
					"characteristicvaluechanged",
					(event) => {
						const data_received = new Uint8Array(event.target.value.buffer);
						if(nomeDevice.name.substring(0, 11) === "E-lastic_v3"){
							if(data_received.length == 19){
								var DINAMO_MAC_ADDRESS = ''
								for (var i = 0; i < data_received.length; i++) {
									DINAMO_MAC_ADDRESS += '' + String.fromCharCode(data_received[i]);
								}
								store.dispatch(ConfigsActions.set_identification_dinamo(DINAMO_MAC_ADDRESS.slice(1, -1),dinamo_uuid, nomeDevice.name ));
							}
						}
						var peso = 0
						if (data_received.length == 1) {
							peso = data_received[0] * 0.2 - tara;
						}
						if (data_received.length == 2) {
							peso =
								data_received[0] * 0.2 * 256 + data_received[1] * 0.2 - tara;
						}
						if (data_received.length == 4) {
							peso =
								data_received[2] * 0.2 * 256 + data_received[1] * 0.2 - tara;
						}
						peso = peso.toFixed(1);
						store.dispatch(AvaliationActions.set_valor_ble(peso));
						store.dispatch(ConfigsActions.set_bluetooth_result(true));
					}
				);
			});
		})
		.catch((error) => {
			if (error == "DOMException: Bluetooth adapter not available.") {
				store.dispatch(ConfigsActions.set_bluetooth_result(true));
			}
			return error;
		});
}

// Function to monitor battery level
const monitorBatteryLevelWithNotifications = async (server) => {
	const batteryServiceUUID = '0000180f-0000-1000-8000-00805f9b34fb';
	const batteryLevelUUID = '00002a19-0000-1000-8000-00805f9b34fb';
	
	try {
		const service = await server.getPrimaryService(batteryServiceUUID);
		const characteristic = await service.getCharacteristic(batteryLevelUUID);
		await characteristic.startNotifications();
		
		characteristic.addEventListener(
			"characteristicvaluechanged",
			(event) => {
				const data_received = new Uint8Array(event.target.value.buffer);
				const batteryLevel = data_received[0];
				
				if (!previousBatteryLevel || !!batteryLevel && batteryLevel < previousBatteryLevel) {
					previousBatteryLevel = batteryLevel;
					store.dispatch(ConfigsActions.set_dino_battery(batteryLevel));
				}
			}
		);
	} catch (error) {
		console.error('Error monitoring battery level:', error);
	}
};


import React from "react";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../Dashboard/ContentDash";

const CentralAjudaController: React.FC = () => {
	return (
		<ViewPort>
			<Menu setMenu="centralAjuda"></Menu>
			<ContentDash listagem={"centralAjudaItem"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default  CentralAjudaController;

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import {
	CentralAjudaProps,
	HelpersProps,
} from "../../../interfaces/central_ajuda";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";
import { CentralAjuda } from "../../../store/ducks/central";
import { dataHelpers } from "../../../utils/dataHelpers";
import { TypesLanguage } from "../../../utils/types";
import { Conteiner, Header, BodyList, Footer } from "./styles";

const CentralDeAjudaItem: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("centralAjudaItem"));
	}, []);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const language: any = useSelector((state: any) => state.configs.language);
	const categoria = useSelector((state: any) => state.central.categoria);
	const help = useSelector((state: any) => state.central.help);
	const search: any = useSelector((state: any) => state.dashboard.search);

	const text = (
		language == TypesLanguage.PT
			? dataHelpers[categoria].helpers[help]?.description_pt
			: language == TypesLanguage.EN
			? dataHelpers[categoria].helpers[help]?.description_en
			: dataHelpers[categoria].helpers[help]?.description_es
	)?.split("\n");

	const [selected, setSelected] = useState<string[]>([]);

	const handleActiveClick = (active: string) => {
		const alreadySelected = selected.findIndex((item) => item === active);

		if (alreadySelected < 0) {
			setSelected([active]);
		} else {
			setSelected([]);
		}
	};

	const changeHelpers = (categoria: any, idHelp: any) => {
		dispatch(CentralAjuda.set_categoria(categoria));
		dispatch(CentralAjuda.set_help(idHelp - 1));
		history.push("/central_ajuda_item");
	};

	const pesquisa = () => {
		let pesquisa: any = [];
		if (search !== "") {
			pesquisa = [];
			dataHelpers.map((message: CentralAjudaProps, index: number) => {
				if (language == TypesLanguage.PT) {
					const item = message.helpers.filter((help: HelpersProps) =>
						help.description_pt.includes(search)
					);
					if (item.length !== 0) {
						pesquisa.push([index, item]);
					}
				}
			});
		}
		return pesquisa;
	};
	const pesquisar = pesquisa();

	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}

			<Header>
				<div className="bodyHeader">
					<p className="nome">{current_language?.me_ajuda}</p>
					<div className="hr" />
				</div>
			</Header>
			<BodyList>
				{search !== "" && pesquisar.length === 0 ? (
					<nav className="lineItens">Nenhum item encontrado</nav>
				) : pesquisar.length === 0 ? (
					<>
						<div className="helperTitle">
							{language == TypesLanguage.PT
								? dataHelpers[categoria].helpers[help]?.title_pt
								: language == TypesLanguage.EN
								? dataHelpers[categoria].helpers[help]?.title_en
								: dataHelpers[categoria].helpers[help]?.title_es}
						</div>
						<div className="helperDescription">
							{text?.map((e: any) => {
								return (
									<p key={e}>
										{e}
										<br></br>
									</p>
								);
							})}
						</div>
					</>
				) : (
					<nav className="pesquisa">
						{pesquisar.map((help: any) => {
							return help[1].map((item: HelpersProps) => {
								return (
									<div
										key={item?.id}
										className="helper"
										onClick={() => changeHelpers(help[0], item.id)}
									>
										<div className="helperTitle">
											{language == TypesLanguage.PT
												? item?.title_pt
												: language == TypesLanguage.EN
												? item?.title_en
												: item?.title_es}
										</div>
										<div className="helperDescription">
											{(language == TypesLanguage.PT
												? item?.description_pt
												: language == TypesLanguage.EN
												? item?.description_en
												: item?.description_es
											).slice(0, 100)}
											...
										</div>
									</div>
								);
							});
						})}
					</nav>
				)}
			</BodyList>
			{/* <Footer>
				<div>
					<button
						className="btnAdicionar btn-white btn-animate"
						onClick={() => dispatch(AppActions.set_modal_central_ajuda(true))}
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16 6.01562V4H4V6.01562H16ZM12.0156 12.0156V10H4V12.0156H12.0156ZM4 7V9.01562H16V7H4ZM18.0156 0.015625C18.5469 0.015625 19 0.21875 19.375 0.625C19.7812 1 19.9844 1.45313 19.9844 1.98438V13.9844C19.9844 14.5156 19.7812 14.9844 19.375 15.3906C19 15.7969 18.5469 16 18.0156 16H4L0.015625 19.9844V1.98438C0.015625 1.45313 0.203125 1 0.578125 0.625C0.984375 0.21875 1.45312 0.015625 1.98438 0.015625H18.0156Z"
								fill="#000E4B"
							/>
						</svg>

						<p className="textoBotao">
							{current_language?.problemas_sugestoes}
						</p>
					</button>
				</div>
			</Footer> */}
		</Conteiner>
	);
};

export default memo(CentralDeAjudaItem);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../database/txt.interface";
import { ExerGrupoHemiPros } from "../../interfaces/exercicio_protocolo";
import { Exercicios2Props } from "../../interfaces/exercicio.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { AppActions } from "../../store/ducks/app";
import { AmplitudeLog } from "../../utils/amplitude";

const Header: React.FC = function () {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const paciente = useSelector((state: any) => state.app.is_open_paciente);


	const is_play = useSelector((state: any) => state.app.is_play_exercicios);

	const current_exercise: ExerGrupoHemiPros = useSelector(
		(state: any) => state.avaliation.current_exercise
	);

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);
	const id_usuario = exercicios.find(
		(e: Exercicios2Props) => current_exercise?.exercice_id === e.ID_EXERCICIO
	);

	const valorBateria = 80;
	const bateria = (100 - valorBateria) / 6.66 + 3;


	const handleOpenConfiguracoes = () => {
		dispatch(AppActions.set_open_configuracoes_exercicios(true));
	};

	const tutorial = (e: any) => {
		dispatch(AppActions.set_exercicio_tutorial(e.name));
		dispatch(AppActions.set_modal_tutorial(true));
	};

	return (
		<div className="header">
			<div className="perfil">
				<svg
					className="fotoPerfil"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="10" cy="10" r="9.5" fill="#EBEBEB" stroke="#E5E5E5" />
					<path
						d="M10.2747 10.2864C11.7319 10.2864 12.9121 9.10355 12.9121 7.64319C12.9121 6.18283 11.7319 5 10.2747 5C8.81758 5 7.63736 6.18283 7.63736 7.64319C7.63736 9.10355 8.81758 10.2864 10.2747 10.2864ZM10.2747 11.608C8.51429 11.608 5 12.4934 5 14.2512V15.5728H15.5495V14.2512C15.5495 12.4934 12.0352 11.608 10.2747 11.608Z"
						fill="#B3B3B3"
					/>
				</svg>
				<div className="info">
					<p>{user_logado.USUARIO_NOME}</p>
					<div>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12 12C15.315 12 18 9.315 18 6C18 2.685 15.315 0 12 0C8.685 0 6 2.685 6 6C6 9.315 8.685 12 12 12ZM12 15C7.995 15 0 17.01 0 21V24H24V21C24 17.01 16.005 15 12 15Z"
								fill="#001574"
							/>
						</svg>
						<small>{paciente?.USUARIO_NOME}</small>
					</div>
				</div>
			</div>
			<div className="config">
				<div className="configArea">
					<div className="headConfig">
						{is_play ? (
							<svg
								className="botaoClick2"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M17.6502 10.975C17.6888 10.6625 17.7145 10.3375 17.7145 10C17.7145 9.6625 17.6888 9.3375 17.6373 9.025L19.8102 7.375C20.0031 7.225 20.0545 6.95 19.9388 6.7375L17.8816 3.275C17.753 3.05 17.483 2.975 17.2516 3.05L14.693 4.05C14.153 3.65 13.5872 3.325 12.9572 3.075L12.5715 0.425C12.5329 0.175 12.3143 0 12.0572 0H7.94281C7.68566 0 7.47994 0.175 7.44137 0.425L7.05565 3.075C6.42563 3.325 5.84705 3.6625 5.31989 4.05L2.76126 3.05C2.52982 2.9625 2.25982 3.05 2.13124 3.275L0.0740529 6.7375C-0.0545215 6.9625 -0.00309162 7.225 0.202628 7.375L2.37554 9.025C2.32411 9.3375 2.28553 9.675 2.28553 10C2.28553 10.325 2.31125 10.6625 2.36268 10.975L0.18977 12.625C-0.00309189 12.775 -0.0545215 13.05 0.0611955 13.2625L2.11839 16.725C2.24696 16.95 2.51697 17.025 2.7484 16.95L5.30703 15.95C5.84705 16.35 6.41277 16.675 7.04279 16.925L7.42851 19.575C7.47994 19.825 7.68566 20 7.94281 20H12.0572C12.3143 20 12.5329 19.825 12.5586 19.575L12.9444 16.925C13.5744 16.675 14.153 16.3375 14.6801 15.95L17.2387 16.95C17.4702 17.0375 17.7402 16.95 17.8688 16.725L19.9259 13.2625C20.0545 13.0375 20.0031 12.775 19.7974 12.625L17.6502 10.975ZM10 13.75C7.87852 13.75 6.14277 12.0625 6.14277 10C6.14277 7.9375 7.87852 6.25 10 6.25C12.1215 6.25 13.8572 7.9375 13.8572 10C13.8572 12.0625 12.1215 13.75 10 13.75Z" />
							</svg>
						) : (
							<svg
								onClick={()=> {
									handleOpenConfiguracoes() 
									AmplitudeLog('configuracoes_execucao')
								}}
								className="botaoClick"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M17.6502 10.975C17.6888 10.6625 17.7145 10.3375 17.7145 10C17.7145 9.6625 17.6888 9.3375 17.6373 9.025L19.8102 7.375C20.0031 7.225 20.0545 6.95 19.9388 6.7375L17.8816 3.275C17.753 3.05 17.483 2.975 17.2516 3.05L14.693 4.05C14.153 3.65 13.5872 3.325 12.9572 3.075L12.5715 0.425C12.5329 0.175 12.3143 0 12.0572 0H7.94281C7.68566 0 7.47994 0.175 7.44137 0.425L7.05565 3.075C6.42563 3.325 5.84705 3.6625 5.31989 4.05L2.76126 3.05C2.52982 2.9625 2.25982 3.05 2.13124 3.275L0.0740529 6.7375C-0.0545215 6.9625 -0.00309162 7.225 0.202628 7.375L2.37554 9.025C2.32411 9.3375 2.28553 9.675 2.28553 10C2.28553 10.325 2.31125 10.6625 2.36268 10.975L0.18977 12.625C-0.00309189 12.775 -0.0545215 13.05 0.0611955 13.2625L2.11839 16.725C2.24696 16.95 2.51697 17.025 2.7484 16.95L5.30703 15.95C5.84705 16.35 6.41277 16.675 7.04279 16.925L7.42851 19.575C7.47994 19.825 7.68566 20 7.94281 20H12.0572C12.3143 20 12.5329 19.825 12.5586 19.575L12.9444 16.925C13.5744 16.675 14.153 16.3375 14.6801 15.95L17.2387 16.95C17.4702 17.0375 17.7402 16.95 17.8688 16.725L19.9259 13.2625C20.0545 13.0375 20.0031 12.775 19.7974 12.625L17.6502 10.975ZM10 13.75C7.87852 13.75 6.14277 12.0625 6.14277 10C6.14277 7.9375 7.87852 6.25 10 6.25C12.1215 6.25 13.8572 7.9375 13.8572 10C13.8572 12.0625 12.1215 13.75 10 13.75Z" />
							</svg>
						)}
					</div>
				</div>
				{id_usuario?.ID_USUARIO === 0 ? (
					is_play ? (
						<span
							className="footerConfig2"
							onClick={() => {
								tutorial(current_exercise)
								AmplitudeLog('tutorial_protocolo')
							}}
						>
							<p>{current_language?.ver_tutorial}</p>
							<svg
								width="28"
								height="23"
								viewBox="0 0 28 23"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M24.9087 0H2.49087C1.10844 0 0 1.13722 0 2.55556V17.8889C0 19.2944 1.10844 20.4444 2.49087 20.4444H8.71805V23H18.6815V20.4444H24.9087C26.2787 20.4444 27.3871 19.2944 27.3871 17.8889L27.3996 2.55556C27.3996 1.13722 26.2787 0 24.9087 0ZM24.9087 17.8889H2.49087V2.55556H24.9087V17.8889Z"
									fill="#e0e0e0"
								/>
								<path
									d="M8.11841 14.3737C8.11841 14.3737 8.7948 7.49924 10.824 5L14.2059 5.61106L13.5295 7.49924H12.1767V11.8744H12.8531C14.2059 9.99848 17.0062 9.31409 18.6971 9.99848C20.9292 10.9334 20.7263 13.7504 18.6971 14.997C17.0738 15.9991 12.1767 16.8729 8.11841 14.3737Z"
									fill="#e0e0e0"
								/>
							</svg>
						</span>
					) : (
						<span
							className="footerConfig"
							onClick={() => {
								tutorial(current_exercise)
								AmplitudeLog('tutorial_protocolo')
							}}
						>
							<p>{current_language?.ver_tutorial}</p>
							<svg
								width="28"
								height="23"
								viewBox="0 0 28 23"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M24.9087 0H2.49087C1.10844 0 0 1.13722 0 2.55556V17.8889C0 19.2944 1.10844 20.4444 2.49087 20.4444H8.71805V23H18.6815V20.4444H24.9087C26.2787 20.4444 27.3871 19.2944 27.3871 17.8889L27.3996 2.55556C27.3996 1.13722 26.2787 0 24.9087 0ZM24.9087 17.8889H2.49087V2.55556H24.9087V17.8889Z"
									fill="#001574"
								/>
								<path
									d="M8.11841 14.3737C8.11841 14.3737 8.7948 7.49924 10.824 5L14.2059 5.61106L13.5295 7.49924H12.1767V11.8744H12.8531C14.2059 9.99848 17.0062 9.31409 18.6971 9.99848C20.9292 10.9334 20.7263 13.7504 18.6971 14.997C17.0738 15.9991 12.1767 16.8729 8.11841 14.3737Z"
									fill="#001574"
								/>
							</svg>
						</span>
					)
				) : (
					<div className="space" />
				)}
			</div>
		</div>
	);
};

export default Header;

import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { push } from "connected-react-router";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { getPermission } from "../../../utils/funcs";
import { TypesAvalOrFunci, TypesPermission, TypesProLivre } from "../../../utils/types";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { Container } from "./styles";

import { AmplitudeLog } from "../../../utils/amplitude";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalSelectAvaliation: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_select_avaliacao = useSelector(
		(state: any) => state.app.is_open_select_avaliacao
	);

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_select_avaliacao(false));
	};

	const [selected, setSelected] = useState(false);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const permissionAndEvaluationChoice = (type_avaliacao: string) => {
		const permissionServicoRM: any =  getPermission(
			config_servicos,
			TypesPermission.SERVICO_PERCENTUAL_RM
		)
		if(permissionServicoRM){
			setSelected(true)
			dispatch(AppActions.set_selected_avaliacao(type_avaliacao));
		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
			dispatch(AppActions.set_modal_select_avaliacao(false));
		}
	};

	const SelectedChoiceAvaliacao = (type_avaliacao: string) => {
		setSelected(true)
		dispatch(AppActions.set_selected_avaliacao(type_avaliacao)); 
	};


	const is_selected_type_pro_livre = useSelector(
		(state: any) => state.app.is_selected_type_pro_livre
	);

	const Navegar = () => {
		if (is_selected_type_pro_livre === TypesProLivre.LIVRE) {
			if (is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO && selected) {
				dispatch(push("/exercicio"));
			}
			if (is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL) {
				dispatch(AppActions.set_open_configuracoes_execucao(true));
			}
			dispatch(AppActions.set_modal_select_avaliacao(false));
		}
		if (is_selected_type_pro_livre === TypesProLivre.PROTOCOLO && selected) {
			dispatch(AppActions.set_checklist_execucao(true));
			dispatch(push("/protocolo"));
			dispatch(AppActions.set_modal_select_avaliacao(false));
			dispatch(ManageProtocolActions.set_remove_play(false))
			dispatch(ManageProtocolActions.set_button_goback(true));
		}
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_select_avaliacao}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_select_avaliacao}>
					<Container>
						<div className="divText">
							{current_language?.idioma === "Português" ? (
								<p className="text">
									Iniciar {is_selected_type_pro_livre === TypesProLivre.LIVRE ? "exercício" : "protocolo"} no modo:
								</p>
							) : current_language?.idioma === "English" ? (
								<p className="text">
									Start {is_selected_type_pro_livre === TypesProLivre.LIVRE ? "exercise " : "protocol"} in mode:
								</p>
							) : (
								<p className="text">
									Iniciar {is_selected_type_pro_livre === TypesProLivre.LIVRE ? "ejercicio" : "protocolo"} en modo:
								</p>
							)}
						</div>
						<div className="choice">
							<div className="buttons">
								<div
									className={`card ${is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO
											? "bordaAzul"
											: "bordaNormal"
										}`}
								>
									<label htmlFor="avaliacao">
										<p
											className={
												is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO
													? "corSelected"
													: "corNormal"
											}
										>
											{current_language?.config_avaliacao}
										</p>
										{is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO ? (
											<svg
												className="svg"
												width="43"
												height="73"
												viewBox="0 0 43 73"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M0 6.92653H18.9656H20.1509M0 12.8533H13.0388"
													stroke="#797D8D"
												/>
												<path d="M0 1H18.9656H20.1509" stroke="#797D8D" />
												<rect
													y="51.3924"
													width="7.1121"
													height="21.3363"
													fill="#00D084"
												/>
												<rect
													x="11.8535"
													y="56.1338"
													width="7.1121"
													height="16.5949"
													fill="#C4C4C4"
												/>
												<rect
													x="23.7075"
													y="49.0217"
													width="7.1121"
													height="23.707"
													fill="#00D084"
												/>
												<rect
													x="35.3076"
													y="21.7692"
													width="7.61538"
													height="51.2308"
													fill="#001574"
												/>
												<path
													d="M0 34.9231H40.3982H42.9231"
													stroke="#797D8D"
													strokeMiterlimit="16"
													strokeDasharray="2 2"
												/>
												<path
													d="M3.79695 25.0861L2.24534 21.9099H0.249549L2.73821 26.303L0.182617 30.7692H2.20275L3.82129 27.5443L5.44591 30.7692H7.44171L4.88612 26.303L7.38086 21.9099H5.3729L3.79695 25.0861Z"
													fill="#001574"
												/>
											</svg>
										) : (
											<svg
												className="svg"
												width="43"
												height="73"
												viewBox="0 0 43 73"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M0 6.92676H18.9656H20.1509M0 12.8535H13.0388"
													stroke="#B3B3B3"
												/>
												<path d="M0 1H18.9656H20.1509" stroke="#B3B3B3" />
												<rect
													y="51.3926"
													width="7.1121"
													height="21.3363"
													fill="#B3B3B3"
												/>
												<rect
													x="11.8535"
													y="56.1338"
													width="7.1121"
													height="16.5949"
													fill="#C4C4C4"
												/>
												<rect
													x="23.7075"
													y="49.0215"
													width="7.1121"
													height="23.707"
													fill="#B3B3B3"
												/>
												<rect
													x="35.3076"
													y="21.7695"
													width="7.61538"
													height="51.2308"
													fill="#B3B3B3"
												/>
												<path
													d="M0 34.9229H40.3982H42.9231"
													stroke="#B3B3B3"
													strokeMiterlimit="16"
													strokeDasharray="2 2"
												/>
												<path
													d="M3.79695 25.0864L2.24534 21.9102H0.249549L2.73821 26.3033L0.182617 30.7695H2.20275L3.82129 27.5446L5.44591 30.7695H7.44171L4.88612 26.3033L7.38086 21.9102H5.3729L3.79695 25.0864Z"
													fill="#B3B3B3"
												/>
											</svg>
										)}
										<p className="textAvFunc">
											{current_language?.avaliacao_explicacao}
										</p>
									</label>

									<div className="groupRadio">
										<label
											htmlFor="avaliacao"
											className={
												is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO
													? "checkmarkSeleted"
													: "checkmark"
											}
										></label>
										<input
											className="select"
											type="radio"
											name="radio"
											id="avaliacao"
											onChange={() => { 
												SelectedChoiceAvaliacao(TypesAvalOrFunci.AVALIACAO)
												AmplitudeLog(
													is_selected_type_pro_livre === TypesProLivre.LIVRE 
													? 'exercicio_modo_avaliacao'
													:'proto_modo_avaliacao'
												)
											}}
										/>
									</div>
								</div>

								<div
									className={`card ${is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL
											? "bordaAzul"
											: "bordaNormal"
										}`}
								>
									<label htmlFor="funcional">
										<p
											className={
												is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL
													? "corSelected"
													: "corNormal"
											}
										>
											{current_language?.config_funcional}
										</p>
										{is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL ? (
											<svg
												width="66"
												height="74"
												viewBox="0 0 66 74"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g opacity="0.72">
													<path
														d="M10.8955 7.50684H31.7197H33.0212M10.8955 14.0144H25.2121"
														stroke="#797D8D"
													/>
													<path
														d="M10.8955 1H31.7197H33.0212"
														stroke="#797D8D"
													/>
													<path
														d="M1 72.9993C17.0379 56.9613 20.4502 12.9424 32.7346 12.9424C45.019 12.9424 47.7488 54.9139 65.4929 72.9993"
														stroke="#797D8D"
														strokeDasharray="2 2"
													/>
													<line
														x1="33.2344"
														y1="7.82422"
														x2="33.2344"
														y2="72.6583"
														stroke="#797D8D"
														strokeDasharray="2 2"
													/>
													<path
														d="M32.9479 39.2548L30.9085 35.0801H28.2852L31.5563 40.8544L28.1973 46.7246H30.8525L32.9798 42.4859L35.1152 46.7246H37.7384L34.3794 40.8544L37.6585 35.0801H35.0192L32.9479 39.2548Z"
														fill="#001574"
													/>
													<path
														d="M21.5423 29.2787H43.9271V35.927L52.8811 27.0626L43.9271 18.1982V24.8465H17.0653V38.1431H21.5423V29.2787ZM43.9271 51.4397H21.5423V44.7914L12.5884 53.6558L21.5423 62.5202V55.8719H48.4041V42.5753H43.9271V51.4397Z"
														fill="#00D084"
													/>
												</g>
											</svg>
										) : (
											<svg
												width="66"
												height="74"
												viewBox="0 0 66 74"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M10.8955 7.50706H31.7197H33.0212M10.8955 14.0146H25.2121"
													stroke="#B3B3B3"
												/>
												<path d="M10.8955 1H31.7197H33.0212" stroke="#B3B3B3" />
												<path
													d="M1 72.9999C17.0379 56.962 20.4502 12.9431 32.7346 12.9431C45.019 12.9431 47.7488 54.9146 65.4929 72.9999"
													stroke="#C4C4C4"
													strokeDasharray="2 2"
												/>
												<line
													x1="33.2344"
													y1="7.82458"
													x2="33.2344"
													y2="72.6587"
													stroke="#C4C4C4"
													strokeDasharray="2 2"
												/>
												<path
													d="M32.9479 39.2553L30.9085 35.0805H28.2852L31.5563 40.8548L28.1973 46.7251H30.8525L32.9798 42.4863L35.1152 46.7251H37.7384L34.3794 40.8548L37.6585 35.0805H35.0192L32.9479 39.2553Z"
													fill="#7F7F7F"
												/>
												<path
													d="M21.5423 29.2787H43.9271V35.927L52.8811 27.0626L43.9271 18.1982V24.8465H17.0653V38.1431H21.5423V29.2787ZM43.9271 51.4397H21.5423V44.7914L12.5884 53.6558L21.5423 62.5202V55.8719H48.4041V42.5753H43.9271V51.4397Z"
													fill="#8D8D8D"
												/>
											</svg>
										)}
										<p className="textAvFunc">
											{current_language?.modal_funcional_explicacao}
										</p>
									</label>

									<div className="groupRadio">
										<label
											htmlFor="funcional"
											className={
												is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL
													? "checkmarkSeleted"
													: "checkmark"
											}
										></label>
										<input
											className="select"
											type="radio"
											name="radio"
											id="funcional"
											onChange={() => { 
												permissionAndEvaluationChoice(TypesAvalOrFunci.FUNCIONAL)
												AmplitudeLog(
													is_selected_type_pro_livre === TypesProLivre.LIVRE 
													? 'exercicio_modo_funcional'
													: 'proto_modo_funcional'
												) 
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="botao">
							<button 
								className={selected ? "button" : "button_desligado"} 
								onClick={()=> {
									Navegar()
									AmplitudeLog('modo_selecionado')
								}}
							>
								{current_language?.comecar}
							</button>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalSelectAvaliation);

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 636px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.divText {
		width: 100%;
		margin-top: 7rem;
	}

	@media only screen and (max-width: 1280px) {
		height: 85vh;
		width: 45vw;
	}

	@media only screen and (max-width: 1440px) {
		height: 80vh;
		width: 45vw;
		.divText {
			margin-top: 3rem;
		}
	}
	.text {
		color: #5c5b5b;
		font-weight: 500;
		font-family: Roboto;
		font-size: 1.625rem;
		line-height: 30.47px;
		text-align: center;
	}

	.choice {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.buttons {
		width: 60%;
		display: flex;
		justify-content: space-around;
		margin-top: 2rem;
	}

	.botao {
		width: 100%;

		margin-top: 4rem;

		display: flex;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: 1440px) {
			margin-top: 3rem;
		}
	}

	.button {
		font-family: Roboto;
		font-size: 1.3rem;
		line-height: 1.758rem;

		color: #ffffff;
		background-color: ${colors.primary_blue_k_main};

		width: 23rem;
		height: 3.625rem;

		border: 0;
		border-radius: 0.5rem;

		transition: all 0.2s ease-in;
	}

	.button_desligado {
		font-family: Roboto;
		font-size: 1.3rem;
		line-height: 1.758rem;

		color: #ffffff;
		background-color: #B3B3B3;

		width: 23rem;
		height: 3.625rem;

		border: 0;
		border-radius: 0.5rem;

		transition: all 0.2s ease-in;
	}

	.button:hover {
		background-color: ${colors.primary_blue_k_dark_2};
	}

	.select {
		width: 1.5rem;
		margin-bottom: 2rem;

		fill: #001574;
	}

	.bordaNormal {
		border: 1px solid #fff;
		transition: all 0.2s ease-in;
	}

	.bordaAzul {
		border: 1px solid ${colors.secondary_green_k_light_1};
		transition: all 0.2s ease-in;
	}

	.card {
		width: 10rem;
		height: 17rem;

		border-radius: 1rem;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		label {
			cursor: pointer;
			font-size: 1.5rem;
			font-weight: 500;
			margin-top: 1rem;
			color: #7f7f7f;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			svg {
				margin-top: 1.2rem;
				margin-bottom: 0.5rem;
			}

			.svg {
				margin-top: 1.2rem;
				margin-bottom: 1.5rem;
			}
		}

		@media only screen and (max-width: 1280px) {
			width: 10rem;
			height: 17rem;
		}

		@media only screen and (max-width: 1440px) {
			width: 10rem;
			height: 15rem;
		}
	}

	.corNormal {
		margin-top: 1rem;
		color: #7f7f7f;
		transition: all 0.2s ease-in;
	}

	.corSelected {
		margin-top: 1rem;
		color: ${colors.primary_blue_k_main};
		transition: all 0.2s ease-in;
	}

	.select {
		width: 1.5rem;
		margin-bottom: 2rem;

		fill: #001574;
		transition: all 0.2s ease-in;
	}

	.groupRadio {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		transition: all 0.2s ease-in;

		margin-bottom: 1rem;
	}

	.groupRadio input {
		opacity: 0;
		cursor: pointer;
		z-index: 999;
		height: 25px;
		transition: all 0.2s ease-in;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eaebeb;
		border-radius: 50%;
		transition: all 0.2s ease-in;
	}

	.checkmarkSeleted {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		border: 0.3rem solid #eaebeb;
		background-color: ${colors.secondary_green_k_medium_1};
		border-radius: 50%;
		transition: all 0.2s ease-in;
	}

	.textAvFunc {
		font-size: 0.8rem;
		font-weight: 400;
		width: 80%;

		text-align: center;
	}
`;

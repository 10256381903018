import styled from "styled-components";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: 72%;
	margin-right: 2.6%;
	height: 100%;
	padding-top: 5vh;
	padding-bottom: 5vh;
	padding-left: 2vw;
	padding-right: 2vw;
	background-color: white;
	border-radius: 1.25rem;
	justify-content: center;
	align-items: center;

	.circulo {
		width: 10rem;
		height: 10rem;
		display: flex;
		background-color: #ebebeb;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	.texto {
		display: flex;
		font-size: 1.5rem;
		color: #5c5b5b;
		margin-top: 1.6rem;
		text-align: center;
		margin-bottom: 2.8rem;
	}

	.botão {
		margin-top: 2.5rem;
		display: flex;
		flex-direction: row;
		width: 18.5rem;
		height: 5.6rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
	}

	.textoBotao {
		margin-left: 1rem;
	}
`;

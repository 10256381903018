const messages = {
	en: {
		translations: {
			login: {
				email: "Email",
				senha: "Password",
				forget: "Forget password?",
				enviar: "SUBMIT",
				cadastrar: "REGISTER",
				idioma: "Language",
				ajuda: "Help",
			},
			cadastro: {
				nome: "Name",
				email: "Email",
				celular: "Cell",
				senha: "Password",
				confirmarsenha: "Confirm the Password",
				cpf: "CPF",
				nacionalidade: "Nationality",
				concluir: "CONCLUDE",
				voltar: "Back",
			},
			recuperar_senha: {
				inserir: "Enter registration email to reset password",
				exemplo: "example@gmail.com",
				enviar: "SUBMIT",
				voltar: "Back",
			},
		},
	},
};

export { messages };

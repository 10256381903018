export const linksForVideo = [
    {
        id: 2,
        name: 'Inversão',
        link: 'https://www.youtube.com/embed/qFPHbxTfY5M?si=bbiTooRxEOdn-QWG&rel=0',
    },
    {
        id: 3,
        name: 'Extensão de cotovelo testa',
        link: 'https://www.youtube.com/embed/zp-6b5xtxgY?si=EDzAcByfBdQT-MRS&rel=0'
    },
    {
        id: 4,
        name: 'Adução de quadril',
        link: 'https://www.youtube.com/embed/4T_ijBI-l_Q?si=-PN84ANhONkXzXfh&rel=0'
    },
    {
        id: 5,
        name: 'Extensão de quadril',
        link: 'https://www.youtube.com/embed/EZt4qkWHiXI?si=qrfn2iEeQoqOkcqR&rel=0'
    },
    {
        id: 6,
        name: 'Flexão do pescoço',
        link: 'https://www.youtube.com/embed/efHceZYychs?si=wpM1G47RydvQEczu&rel=0'
    },
    {
        id: 8,
        name: 'Remada aberta',
        link: 'https://www.youtube.com/embed/PEfLeLyF-cI?si=_u9l9-k9FPU5sTDj&rel=0'
    },
    {
        id: 9,
        name: 'Flexão de cotovelo',
        link: 'https://www.youtube.com/embed/TNeoZupzx9U?si=madmfQIbaZcx72Cu&rel=0'
    },
    {
        id: 11,
        name: 'Abdução de ombro',
        link: 'https://www.youtube.com/embed/PEfLeLyF-cI?si=0BD3jIiANaUwB2F3&rel=0'
    },
    {
        id: 12,
        name: 'Rotação interna',
        link: 'https://www.youtube.com/embed/vhOJxrJjXd0?si=SwOm9jB1TVLuOzKG&rel=0'
    },
    {
        id: 15,
        name: 'Extensão de cotovelo francês',
        link: 'https://www.youtube.com/embed/z2ei4xO3_zs?si=_VUAXSRukWbJ1ACT&rel=0'
    },
    {
        id: 16,
        name: 'Flexão de ombro',
        link: 'https://www.youtube.com/embed/v1Jm-55-Zn4?si=b5XWAv_tnbk54hog&rel=0'
    }, 
    {
        id: 17,
        name: 'Eversão',
        link: 'https://www.youtube.com/embed/8JEI7xY-nng?si=C_uSWqLpsblyU0na&rel=0'
    },
    {
        id: 19,
        name: 'Crucifixo',
        link: 'https://www.youtube.com/embed/Akjg7_0zO4A?si=6Ly0vrx_y0Zw6z0U&rel=0'
    },
    {
        id: 20,
        name: 'Flexão de punho',
        link: 'https://www.youtube.com/embed/iCtwXaMNfbY?si=vwFKQeG25d5tZ22n&rel=0'
    },
    {
        id: 22,
        name: 'Afundo',
        link: 'https://www.youtube.com/embed/n7Gl1bBLISA?si=RhvKU-JBGG3FPQ_g&rel=0'
    },
    {
        id: 23,
        name: 'Agachamento',
        link: 'https://www.youtube.com/embed/32KWCIJ0zDA?si=uvolfj4VAyxQ_feE&rel=0'
    },
    {
        id: 26,
        name: 'Flexão de quadril',
        link: 'https://www.youtube.com/embed/PNWGbRbEm2Q?si=HpmN0Yk_u4LEfqoO&rel=0'
    },
    {
        id: 27,
        name: 'Flexão lateral do pescoço',
        link: 'https://www.youtube.com/embed/qzJhuO7_gMs?si=FL0cM4cB6SA0ROpM&rel=0'
    },
    {
        id: 29,
        name: 'Abdominal',
        link: 'https://www.youtube.com/embed/Jjqzi3zi2Q4?si=h6kSwc6RFmQzK0Vm&rel=0'
    },
    {
        id: 30,
        name: 'Extensão de cotovelo',
        link: 'https://www.youtube.com/embed/wVrfF3pfFbY?si=NhmUHXm4WYrygQsC&rel=0',
    },
    {
        id: 31,
        name: 'Flexão de joelho',
        link: 'https://www.youtube.com/embed/yUsFurGLwsQ?si=MHBY4gJ9_SAFPFst&rel=0'
    },
    {
        id: 32,
        name: 'Remada alta',
        link: 'https://www.youtube.com/embed/iZKMh34I8IM?si=6un9wr2AXVbOvE17&rel=0'
    },
    {
        id: 33,
        name: 'Rotação externa',
        link: 'https://www.youtube.com/embed/YSisY0afL_I?si=fePqowcWrmyfKKxu&rel=0'
    },
    {
        id: 36,
        name: 'Supino reto',
        link: 'https://www.youtube.com/embed/2WytNqrMWAU?si=vxb2FS6uxPQx314M&rel=0'
    },
    {
        id: 37,
        name: 'Desenvolvimento de ombro',
        link: 'https://www.youtube.com/embed/OaF8cNsXzbI?si=Jh2Bra-lsCH-CMYm&rel=0'
    },
    {
        id: 38,
        name: 'Remada',
        link: 'https://www.youtube.com/embed/6iXxphC3UcQ?si=jtUBdilDARWtcFpD&rel=0'
    },
    {
        id: 40,
        name: 'Extensão de ombro',
        link: 'https://www.youtube.com/embed/5P7NCrLm2M0?si=k8azYCKpfBWtTO0s&rel=0'
    },
    {
        id: 41,
        name: 'Flexão lateral de tronco',
        link: 'https://www.youtube.com/embed/4jmDEEOjlWE?si=DCPkADFOh_PtBtKM&rel=0'
    },
    {
        id: 42,
        name: 'Extensão de joelho',
        link: 'https://www.youtube.com/embed/xLU3bwD-JJM?si=YWI-Tqf5jzhvrwu-&rel=0'
    },
    {
        id: 43,
        name: 'Abdução de quadril',
        link: 'https://www.youtube.com/embed/xbjghAbfqwU?si=l2JwXnj8IxJmuVh3&rel=0'
    },
    {
        id: 44,
        name: 'Adução de ombro',
        link: 'https://www.youtube.com/embed/2btpN8uTuoI?si=rS6-8t4_j1fQdlGK&rel=0'
    },
    {
        id: 45,
        name: 'Dorsiflexão',
        link: 'https://www.youtube.com/embed/_v9dY5DkxvI?si=f-yF1y8trBtkF2UA&rel=0'
    },
    {
        id: 47,
        name: 'Abdução de ombro',
        link: 'https://www.youtube.com/embed/8RMeZFdZq8o?si=EwFnnCsbcoZg0XGW&rel=0'
    }, 
];
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";

interface RadioButtonProps {
	questionAsync: boolean | undefined;
}

export const Radio = styled.button<RadioButtonProps>`
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.6rem;
	margin-right: 3rem;
	background-color: transparent;
	border: 0;
	cursor: auto;
	${({ questionAsync }) => questionAsync && `text-align: left;`}

	label {
		cursor: pointer;
		color: #404040;
	}

	.custom-checkbox {
		margin-right: 0.5rem;
	}

	.custom-checkbox input + .label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: #ededed;
		border: 2px solid #e4e3e3;
		/* display: inline-block;
		vertical-align: middle; */
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.custom-checkbox input:checked + .label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${colors.secondary_green_k_medium_1};
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.custom-checkbox input + .label2:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: #ededed;
		border: 2px solid #e4e3e3;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.custom-checkbox input:checked + .label2:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${colors.secondary_green_k_medium_1};
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
`;

export const RadioCheck = styled.div`
	display: flex;
`;

export const Question = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 120%;

	color: #404040;

	margin-bottom: 1.8rem;
`;

export const Content = styled.div`
	display: flex;
`;

export const ContentBody = styled.div`
	display: flex;
	flex-direction: column;

	margin-right: 1rem;
`;

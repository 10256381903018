import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../database/txt.interface";
import { AppActions } from "../../store/ducks/app";
import { AvaliationActions } from '../../store/ducks/avaliation';
import { Barra, Preenchimento } from "./styles";

interface ProgressProps {
	step: any;
	max: any;
}

const Progress: React.FC<ProgressProps> = ({ step, max }: any) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const [porcent, setPorcent] = useState((step * 100) / max);

	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);
	const force_max = useSelector((state: any) => state.avaliation.force_max);
	const start = useSelector((state: any) => state.avaliation.start);

	const [porcentMin, setPorcenMin] = useState((force_option_min * 100) / max);
	const [porcentMax, setPorcenMax] = useState((force_option_max * 100) / max);

	const timer_reset = useSelector((state: any) => state.app.timer_reset);

	useEffect(() => {
		let max2: any = max;
		if (force_max > max2) {
			max2 = force_max * 1.2;
		}
		setPorcent((step * 100) / max2);
		setPorcenMin((force_option_min * 100) / max2);
		setPorcenMax((force_option_min * 100) / max2 * 1.2);
		if (porcent <= (force_option_min * 100) / max2) {
			dispatch(AppActions.set_timer_reset(false));
		}
		if (porcent >= (force_option_min * 100) / max2) {
			dispatch(AppActions.set_timer_reset(true));
		}
	}, [step, timer_reset, force_option_min]);


	const connect_flag = useSelector(
		(state: any) => state.avaliation.connect_flag
	);

	const modo_demo: any = useSelector((state: any) => state.configs.modo_demo);



	const [ valorMaior, setValorMaior] = useState<number>(0)
	
	useEffect(()=>{
		if(porcent > valorMaior){
			setValorMaior(porcent)
		}
	},[porcent])	

	return (
		<div className="secaoBarra">
			<div className="linhasBarra">
				<div className="legendaPonta">
					<p title={(!start && (connect_flag || modo_demo)) ? "Editar" : "Edição bloqueada, verifique o bluetooth ou pare a execução"} className="forc_min">{parseFloat(force_option_min).toFixed(2)} Kg
						<svg className="edit" onClick={() => (!start && (connect_flag || modo_demo)) && dispatch(AppActions.set_modal_config_forc_min(true))} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.7188 4.03125L15.8906 5.85938L12.1406 2.10938L13.9688 0.28125C14.1562 0.09375 14.3906 0 14.6719 0C14.9531 0 15.1875 0.09375 15.375 0.28125L17.7188 2.625C17.9062 2.8125 18 3.04688 18 3.32812C18 3.60938 17.9062 3.84375 17.7188 4.03125ZM0 14.25L11.0625 3.1875L14.8125 6.9375L3.75 18H0V14.25Z" fill={(!start && (connect_flag || modo_demo)) ? '#4585FF' : '#e0e0e0'} />
						</svg>
					</p>
					<small>{current_language?.execucao_force_min}</small>
				</div>

				<div className="legenda">
					<h2>{parseFloat(step).toFixed(2)} Kg</h2>
				</div>

				<div className="legendaPonta" >
					<p style={{color:'#000000'}}>{parseFloat(force_max).toFixed(2)} Kg</p>
					<small style={{color:'#000000'}}>{current_language?.execucao_force_max}</small>
				</div>
			</div>
			<Barra>
				<div className="lineMin" style={{ left: `${porcentMin}%` }}/>
				<div className="lineMax" style={{ left: `${valorMaior == 0 ? 0 : valorMaior}%`}}/>
				<Preenchimento style={{ width: `${porcent}%` }}></Preenchimento>
			</Barra>
		</div>
	);
};

export default Progress;
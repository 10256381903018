import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 72%;
	margin-right: 2.6%;
	height: 100%;

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-right: 2.6%;
		height: 100%;
	}

	padding-top: 2.3rem;
	padding-bottom: 2.3rem;
	padding-left: 3.1rem;
	padding-right: 3.1rem;

	background-color: white;
	border-radius: 1.25rem;

	.cabecalho {
		margin-bottom: 2.3rem;
		width: 100%;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		color: #5c5b5b;
	}

	.salve {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;
		margin-top: 2rem;

		background-color: ${colors.primary_blue_k_main};
		color: white;

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.cancel {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;

		background-color: transparent;
		color: ${colors.primary_blue_k_main};

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.cancel:hover {
		color: ${colors.primary_blue_k_dark_2};
	}

	.divInput {
		display: flex;
		align-items: flex-end;
		width: 100%;
		margin-bottom: 0.56rem;
	}

	.input {
		width: 100%;
		margin-left: 1rem;
		border: none;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #5c5b5b;
	}

	.hr {
		background-color: #e0e0e0;
		border: none;
		height: 3px;
		width: 100%;
		margin-bottom: 2.9rem;
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 90%;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.lista {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		height: 6.5rem;
		width: 100%;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;

		.left {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 5rem;
			height: 100%;
			margin-right: 1.25rem;
		}

		.mid {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;

			.midSup {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				height: 50%;

				.exercicio {
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
					color: #5c5b5b;
					margin-left: 0.375rem;
				}
			}

			.midInf {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				width: 100%;
				height: 50%;

				.sagitalSelected {
					background-color: #001574;
					padding-top: 0.313rem;
					padding-bottom: 0.3rem;
					padding-left: 0.75rem;
					padding-right: 0.75rem;
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #ffffff;
					border-radius: 32px;
					margin-right: 0.87rem;
					border: solid 2px #001574;
				}

				.sagital {
					background-color: transparent;
					padding-top: 0.313rem;
					padding-bottom: 0.3rem;
					padding-left: 0.75rem;
					padding-right: 0.75rem;
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #8d8d8d;
					border-radius: 32px;
					margin-right: 0.87rem;
					border: solid 2px #8d8d8d;
				}
			}
		}

		.right {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 11rem;
			height: 100%;

			.tutorial {
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				color: #001574;
				margin-right: 0.75rem;
			}
		}
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}
`;

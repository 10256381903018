import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AnotacaoProps } from "../../../interfaces/anotacoes.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { getPermission } from "../../../utils/funcs";
import { TypesMenuInfoProps, TypesPermission } from "../../../utils/types";
import { AmplitudeLog } from "../../../utils/amplitude";
import { colors } from "../../../styles/colors";

const Anotacoes: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	function permissionAddAnnotation(){
		const permissionAnotation: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_ANOTACOES,
		)
		if(permissionAnotation){
			dispatch(AppActions.set_modal_adicionar_anotacao(true));
		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
		}
	};

	const handleOpenApagarAnotacao = (item: any) => {
		dispatch(AppActions.set_modal_apagar_anotacao(item));
	};

	const handleOpenEditarAnotacao = (item: any) => {
		dispatch(AppActions.set_modal_editar_anotacao(item));
	};

	const todasAnotacoes: any = useSelector(
		(state: any) => state.dashboard.anotacoes
	);

	useEffect(() => {
		dispatch(DashBoardActions.anotacoes_request());
	}, []);

	const data = todasAnotacoes.filter(
		(filterAnotacoes: AnotacaoProps) =>
			filterAnotacoes.ID_USUARIO == paciente.ID_USUARIO
	);

	const setSelectMenu = (info: string) => {
		dispatch(DashBoardActions.set_select_info(info));
	};
	
	const lineBreak = (item: any)=>{
		const line_break = item.split('\\n')
		return line_break
	};
	
	return (
		<Container>
			<svg
				onClick={() => setSelectMenu(TypesMenuInfoProps.BTN_MENU)}
				className="btnVoltar"
				width="28"
				height="28"
				viewBox="0 0 28 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M27.3594 12.3594V15.6406H7.04688L16.3438 25.0156L14 27.3594L0.640625 14L14 0.640625L16.3438 2.98438L7.04688 12.3594H27.3594Z"
					fill="#000E4B"
				/>
			</svg>
			<Content>
				<div className="header">
					<p className="title">{current_language?.todas_anotacoes}</p>

					<div className="divBtnAfterDrop">
						<button 
							className="btnShare" 
							onClick={()=> {
								permissionAddAnnotation()
								AmplitudeLog('adicionar_anotacoes')
							}}
						>
							<svg
								className="share"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2Z"
									fill="#00A377"
								/>
								<path
									d="M8.99805 4.82422V13.2121"
									stroke="#00A377"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.53516 9.01758H13.4632"
									stroke="#00A377"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							{current_language?.adicionar_anotacao}
						</button>
					</div>
				</div>

				<div className="divAnotacoes">
					{data.map((item: AnotacaoProps) => (
						<div className="divAnotacao" key={item.ID_ANOTACAO}>
							<div className="divTitle">
								<p className="title">
									{current_language?.anotacao} {item.ANOTACAO_CRIADA}
								</p>

								<div className="dropdown">
									<img className="pontos" src={Pontos} alt="" />
									<div className="dropdown-content">
										<button
											className="op"
											onClick={()=> {
												handleOpenEditarAnotacao(item)
												AmplitudeLog('editar_anotacao')
											}}
										>
											<img src={Editar} className="img" alt="" />
											{current_language?.editar}
										</button>
										<br />
										<button
											className="op2"
											onClick={()=>{ 
												handleOpenApagarAnotacao(item) 
												AmplitudeLog('excluir_anotacao')
											}}
										>
											<img src={Excluir} className="img" alt="" />
											{current_language?.excluir}
										</button>
									</div>
								</div>
							</div>
							{lineBreak(item.ANOTACAO_TXT).map((e:any)=>(
								<>
									<p>{e}</p>
								</>
							))}
						</div>
					))}
					{data.length == 0 && (
						<div className="body">
							<svg
								width="161"
								height="161"
								viewBox="0 0 161 161"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="80.5" cy="80.5" r="80.5" fill="#EBEBEB" />
								<rect x="50" y="64" width="60" height="60" fill="white" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M104.777 65.1219H55.5683C53.3036 65.1219 51.4676 66.9579 51.4676 69.2226V118.431C51.4676 120.696 53.3036 122.532 55.5683 122.532H104.777C107.042 122.532 108.878 120.696 108.878 118.431V69.2226C108.878 66.9579 107.042 65.1219 104.777 65.1219ZM55.5683 59.6543C50.2839 59.6543 46 63.9382 46 69.2226V118.431C46 123.716 50.2839 128 55.5683 128H104.777C110.061 128 114.345 123.716 114.345 118.431V69.2226C114.345 63.9382 110.061 59.6543 104.777 59.6543H55.5683Z"
									fill="#000E4B"
								/>
								<path
									d="M64.1113 85.9672C64.1113 84.4574 65.3352 83.2334 66.8451 83.2334C68.3549 83.2334 69.5789 84.4574 69.5789 85.9672V92.8017C69.5789 94.3116 68.3549 95.5356 66.8451 95.5356C65.3352 95.5356 64.1113 94.3116 64.1113 92.8017V85.9672Z"
									fill="#000E4B"
								/>
								<path
									d="M91.7916 85.9672C91.7916 84.4574 93.0156 83.2334 94.5254 83.2334C96.0353 83.2334 97.2593 84.4574 97.2593 85.9672V92.8017C97.2593 94.3116 96.0353 95.5356 94.5254 95.5356C93.0156 95.5356 91.7916 94.3116 91.7916 92.8017V85.9672Z"
									fill="#000E4B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M77.238 91.8659C80.1049 92.9476 83.2976 92.3099 84.4743 91.8659C85.1807 91.5994 85.9693 91.9559 86.2358 92.6623C86.5023 93.3686 86.1457 94.1572 85.4394 94.4237C83.8823 95.0112 79.9671 95.8176 76.2729 94.4237C75.5666 94.1572 75.21 93.3686 75.4765 92.6623C75.743 91.9559 76.5317 91.5994 77.238 91.8659Z"
									fill="#000E4B"
								/>
								<path
									d="M75.3885 53.1623C75.3885 51.0863 77.0714 49.4033 79.1475 49.4033C81.2235 49.4033 82.9065 51.0863 82.9065 53.1623V61.3638C82.9065 63.4398 81.2235 65.1227 79.1475 65.1227C77.0714 65.1227 75.3885 63.4398 75.3885 61.3638V53.1623Z"
									fill="#000E4B"
								/>
								<path
									d="M85.6403 54.8701C86.3952 54.8701 87.0072 55.4821 87.0072 56.237C87.0072 56.9919 86.3952 57.6039 85.6403 57.6039L72.6547 57.6039C71.8998 57.6039 71.2878 56.9919 71.2878 56.237C71.2878 55.4821 71.8998 54.8701 72.6547 54.8701L85.6403 54.8701Z"
									fill="#000E4B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8057 38.4676C75.786 38.4676 73.3381 40.9156 73.3381 43.9353V45.6409C73.3381 47.5434 74.0939 49.368 75.4391 50.7132L77.6633 52.9374L73.7971 56.8036L71.5729 54.5794C69.2023 52.2088 67.8705 48.9935 67.8705 45.6409V43.9353C67.8705 37.8959 72.7664 33 78.8057 33V38.4676Z"
									fill="#000E4B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M78.8058 38.4676C81.8255 38.4676 84.2734 40.9156 84.2734 43.9353V45.6409C84.2734 47.5434 83.5176 49.368 82.1724 50.7132L79.9482 52.9374L83.8144 56.8036L86.0386 54.5794C88.4092 52.2088 89.741 48.9935 89.741 45.6409V43.9353C89.741 37.8959 84.8451 33 78.8058 33V38.4676Z"
									fill="#000E4B"
								/>
								<path
									d="M107.169 116.381V102.028C90.3268 106.295 72.7087 106.475 55.7828 102.553L53.518 102.028V116.381L55.8488 116.011C72.8597 113.314 90.1988 113.439 107.169 116.381Z"
									fill="#00D084"
								/>
							</svg>
							<p style={{ marginTop: "20px" }}>
								{current_language?.nenhuma_anotacao_paciente}
							</p>
						</div>
					)}
				</div>
			</Content>
		</Container>
	);
};

export default Anotacoes;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 2.6%;
	height: 45vh;
	padding-bottom: 2.5rem;
	background-color: white;

	position: relative;

	.btnVoltar {
		position: absolute;
		top: 1rem;
		left: 0;
		cursor: pointer;
	}

	overflow-x: hidden;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const Content = styled.div`
	padding-top: 2.5rem;

	margin-top: 1rem;

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 3rem;
		margin-top: 1rem;

		.title {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 23px;
			color: #5c5b5b;
		}

		.divBtnAfterDrop {
			display: flex;
			flex-direction: row;

			.btnShare {
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				display: flex;
				align-items: center;
				text-align: center;
				color: ${colors.secondary_green_k_medium_1};
				background-color: transparent;
				border: none;
				margin-right: 1.8rem;

				.share {
					margin-right: 5px;
				}
			}

			.btnCale {
				display: flex;
				padding: 0.37rem;
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: #5c5b5b;
				align-items: flex-end;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 4px;
				background-color: transparent;

				.calen {
					margin-right: 0.5rem;
				}
			}
		}
	}

	.divAnotacoes {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;

		.divAnotacao {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-bottom: 3.75rem;

			.divTitle {
				display: flex;
				align-items: center;
				width: 100%;
				flex-direction: row;

				.title {
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
					color: #5c5b5b;
					margin-right: 2.87rem;
				}

				.dropdown {
					position: relative;
					display: inline-block;
					cursor: pointer;
				}

				.dropdown-content {
					display: none;
					flex-direction: row;
					position: absolute;
					background-color: #f9f9f9;
					min-width: 9.75rem;
					min-height: 7.6rem;
					box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
					padding-left: 1.6rem;
					padding-top: 1.6rem;
					padding-right: 1.6rem;
					z-index: 1;
					border-radius: 1.12rem;
					right: -5px;
					top: -5px;
				}

				.img {
					width: 1.18rem;
					height: 1.18rem;
					margin-right: 1rem;
				}

				.imgForInput {
					width: 4.8rem;
					height: 4.8rem;
					border-radius: 50%;
					object-fit: cover;
				}

				.op {
					font-size: 1.25rem;
					width: 100%;
					margin-bottom: 1.4rem;
					font-weight: 500;
					color: #5c5b5b;
					background-color: transparent;
					border: none;
				}

				.op2 {
					font-size: 1.25rem;
					width: 100%;
					color: #D12600;
					font-weight: 500;
					background-color: transparent;
					border: none;
				}

				.dropdown:hover .dropdown-content {
					display: block;
				}

				.pontos {
					background-color: transparent;
					border: none;
					display: flex;
				}
			}

			.note {
				margin-top: 1.37rem;
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				color: #8c8e86;
			}
		}

		.body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
`;

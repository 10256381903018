import React, { useEffect, useState } from 'react';
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { Container, ContainerOptions } from './styles';
import ArrowDropdown from "../../../assets/icons/arrow_drop_down_circle.svg";
import Check from "../../../assets/icons/checkFilterProtocol.svg";

import buttonClose from "../../../assets/icons/iconClose.svg";
import AddCircle from "../../../assets/icons/addCircle.svg";
import AddCircleDisable from "../../../assets/icons/addCircleDisable.svg";
import { PacientesProps } from '../../../interfaces/pacientes.interface';
import { ProtocolosProps } from '../../../interfaces/protocolo.interface';
import { ManageProtocolActions } from '../../../store/ducks/manage_protocols';
import { TypesLanguage } from '../../../utils/types';
import { PropsCategoriasProtocolos } from '../../../interfaces/categoriasProtocolos.interface';
import { DashBoardActions } from '../../../store/ducks/dashboard';
import moment from 'moment';
import { TextsProps } from '../../../database/txt.interface';
import { colors } from '../../../styles/colors';

enum CategoriaType {
    ARTICULACAO = 'ARTICULACAO',
    GLOBAL = 'GLOBAL',
};

const ModalFilterProcol:React.FC = ()=> {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            modal: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            paper: {
                backgroundColor: theme.palette.background.paper,
                border: "0",
                boxShadow: theme.shadows[5],
            },
        })
    );
    const dispatch = useDispatch();
    const classes = useStyles();

    const [ idCheck, setIdCheck ] = useState<number[]>([])
    const [ dropDown, setDropDown] = useState<number[]>([])
    const [ categoriaArticulacao, setCategoriaArticulacao]= useState<any[]>([]);
    const [ categoriaGlobal, setCategoriaGlobal]= useState<any[]>([]);
    const [ lengthProtocol, setLengthprotocol] = useState<any>()
    const [current_patient, set_current_patient]: any = useState(null);


    const pacientes = useSelector((state: any) => state.dashboard.pacientes);
    const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
    const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

    const language = useSelector((state: any) => state.configs.language);
    const open_modal_filter_protcol = useSelector((state: any)=>  state.manage_protocol.is_open_modal_filter_protocol)
    
    const CategoriasProtocolos:any[] = useSelector((state:any)=> state.dashboard.categorias_protocolos.categorias_protocolo)
    const protocolos: ProtocolosProps[] = useSelector(
		(state: any) => state.manage_protocol.protocolos
	);
    const categorias_atribuidas: number[] = useSelector((state:any)=> state.dashboard.categorias_atribuidas);
    const searchProtocolInStorage = useSelector((state:any)=> state.dashboard.ids_storage_protocol)

    useEffect(()=>{
        if(open_modal_filter_protcol){
            if(categorias_atribuidas?.length == 0 ){
                setIdCheck([])
            }
        }

    },[!!open_modal_filter_protcol])

    useEffect(()=>{
        dispatch(DashBoardActions.search_protocol_in_storage(idCheck))
    },[idCheck])

    useEffect(()=>{
        if( categorias_atribuidas?.length > 0){
            setIdCheck( categorias_atribuidas )
            setDropDown( categorias_atribuidas)
        }
    },[!!open_modal_filter_protcol])

    useEffect(()=>{
        const ProtocolosPorArticulacao = CategoriasProtocolos?.filter(
            (item:any)=> item?.CATEGORIA_TIPO == CategoriaType.ARTICULACAO
        );
        setCategoriaArticulacao(ProtocolosPorArticulacao)

        const ProtocolosGlobais = CategoriasProtocolos?.filter(
            (item:any)=> item?.CATEGORIA_TIPO == CategoriaType.GLOBAL
        );
        setCategoriaGlobal(ProtocolosGlobais)

    },[CategoriasProtocolos])

    const handleCheckBox = (id:number) => {
        if (idCheck?.includes(id)) {
          setIdCheck(idCheck?.filter((item) => item !== id));
          setDropDown(dropDown?.filter((item) => item !== id));
        } else {
          setIdCheck([...idCheck, id]);
          setDropDown([...dropDown, id]);
        }
    };

    const handleDropDown= (id:number) => {
        if (dropDown?.includes(id)) {
          setDropDown(dropDown?.filter((item) => item !== id));
        } else {
          setDropDown([...dropDown, id]);
        }
    };

    useEffect(()=>{
        const searchLengthProtocols =  protocolos?.filter(
            (item:any)=>  searchProtocolInStorage.includes(item?.ID_CATEGORIA_PROTOCOLO)
        )
        setLengthprotocol(searchLengthProtocols?.length)

    },[searchProtocolInStorage])

    
	const SendInformation = ()=>{
        const defaultCategori:number[] = []
        categoriaGlobal?.forEach(
            (item:PropsCategoriasProtocolos)=> defaultCategori?.push(item?.ID_CATEGORIA_PROTOCOLO)
        );
        if(idCheck?.length > 0 ){
            dispatch(DashBoardActions.set_atribuir_categorias_protocolos(
                moment(new Date()).format('DD/MM/YYYY HH:MM:SS'),
                paciente?.ID_USUARIO,
                idCheck
            ))
        }
        if(idCheck?.length == 0 ){
            dispatch(DashBoardActions.set_atribuir_categorias_protocolos(
                moment(new Date()).format('DD/MM/YYYY HH:MM:SS'),
                paciente?.ID_USUARIO,
                defaultCategori
            ))
        }
        dispatch(ManageProtocolActions.set_modal_filter_protocol(false))

    };
  

    const handleVisible = (item: any)=> {
        const dataAtualizacao = item

        const isDataValida = moment(dataAtualizacao, 'YYYY-MM-DD HH:mm:ss', true).isValid();

        const diasParaExibir = 15;

        let isVisible = true; 

        if (!isDataValida) {
            isVisible = false;
        } else {
            const dataInicial = moment(dataAtualizacao);
            const dataFinal = dataInicial.clone().add(diasParaExibir, 'days');
            const hoje = moment();
        
            if (hoje.isAfter(dataFinal)) {
              isVisible = false;
            }
        }

        return isVisible 
            ?
                <div className='TagNovo'>
                    <text className='tagText'>Novo</text>
                </div> 
            : <div></div>
    }

   

   

    const HandleOptions = (item:PropsCategoriasProtocolos, index: number)=> {
       

        return (
            <ContainerOptions key={index}>
                <div className='ContainerLabels'>
                    <div 
                        onClick={()=> handleCheckBox(item?.ID_CATEGORIA_PROTOCOLO)}
                        style={{display: 'flex', flexDirection: 'row', cursor: 'pointer'}}
                    >
                        <div 
                            className='checkBox'
                            style={{
                                border:  idCheck.includes(item?.ID_CATEGORIA_PROTOCOLO) 
                                    ? `1px solid ${colors.secondary_green_k_medium_1}`
                                    : `1px solid ${colors.neutral_grey_k_10}` ,
                                backgroundColor:  idCheck.includes(item?.ID_CATEGORIA_PROTOCOLO) 
                                    ? colors.secondary_green_k_medium_1 
                                    : '#ffffff', 
                            }}
                        >
                            <img src={Check} style={{width: 20, height:20}} />
                        </div>

                        <text className='Textlabel'>
                            {language == TypesLanguage.PT
                                ? item?.CATEGORIA_TITULO_PT
                                : language == TypesLanguage.EN
                                    ? item?.CATEGORIA_TITULO_EN
                                    : item?.CATEGORIA_TITULO_ES
                            }
                        </text>
                        {
                            handleVisible(item?.CATEGORIA_ATUALIZADO)
                        }
                    </div>
                   
                    <div 
                        className={`ContainerDropdown ${
                            dropDown.includes(item?.ID_CATEGORIA_PROTOCOLO)
                                ? 'rotate180'
                                : 'rotate0'
                        }`}
                        onClick={()=>  handleDropDown(item?.ID_CATEGORIA_PROTOCOLO)}
                    >
                        <img src={ArrowDropdown} alt='arrowDropdown' style={{width: 20, height:20}}/>
                    </div>

                </div>
                <div className='description'>
                    { dropDown.includes(item?.ID_CATEGORIA_PROTOCOLO)
                        ? language == TypesLanguage.PT
                            ? item?.CATEGORIA_DESCRICAO_PT
                            : language == TypesLanguage.EN
                                ? item?.CATEGORIA_DESCRICAO_EN
                                : item?.CATEGORIA_DESCRICAO_ES
                        : ''
                    }
                </div>
             
            </ContainerOptions>
        )
    }

    return (
        <div>
            <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open_modal_filter_protcol}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
                    <Fade in={open_modal_filter_protcol}>
                        <Container>
                            <div  className='Header'>
                                <div className='phrase'>
                                    
                                    <text className='text'>
                                        {current_language?.o_que_deseja_avaliar}
                                    </text>
                                    <img
                                        className="close"
                                        src={buttonClose}

                                        onClick={()=> SendInformation()}
                                        alt="Close"
                                    /> 
                                    <div className='ContentName'>

                                        <text className='namepaci'>
                                          {paciente?.USUARIO_NOME} ?
                                        </text>
                                    </div>

                                </div>

                                <div   
                                    className='LenghProtocol' 
                                    style={{
                                        backgroundColor:  lengthProtocol > 0 
                                            ?colors.primary_blue_k_light_2
                                            :colors.neutral_grey_k_10
                                    }}
                                 >
                                    <img 
                                        src={lengthProtocol > 0 
                                            ? AddCircle
                                            : AddCircleDisable
                                        }
                                        alt="" 
                                        className="addCircle" 
                                    />
                                    <text 
                                        className='Length'
                                        style={{
                                            color: lengthProtocol > 0 
                                             ? colors.primary_blue_k_dark_2
                                             :colors.neutral_grey_k_40
                                        }}
                                    >
                                            {lengthProtocol}
                                    </text>
                                    <text 
                                        className='Disponiveis'
                                        style={{
                                            color: lengthProtocol > 0 
                                             ? colors.primary_blue_k_dark_2
                                             :colors.neutral_grey_k_40
                                        }}
                                    > 
                                        { lengthProtocol > 0 
                                            ? current_language?.protocolos_disponiveis_para
                                            : current_language?.nenhum_protocolo_selecionado_no_momento
                                        } 
                                    </text>
                                </div>  
                            </div>  


                            <div className='ContentChecks'>

                                <div className='ContainerTitleProtocolosPorArticulacao'>
                                    <text className='TitleProtocolosPorArticulacao'>
                                        {current_language?.protocolos_tipo_categorias}
                                    </text>
                                </div>

                                <div className='ProtocolosPorArticulacao'>
                                   { categoriaArticulacao?.map((item: any, index)=> 
                                        HandleOptions(item, index)
                                   )}
                                </div>

                                <div className='ContainerTitleProtocolosGlobais'>
                                    <text className='TitleProtocolosGlobais'>
                                       {current_language?.protocolos_tipo_globais}
                                    </text>
                                </div>

                                <div className='ProtocolosGlobais'>
                                    { categoriaGlobal?.map((item: any, index)=> 
                                     HandleOptions(item, index)
                                    )}
                                </div>

                                <div className='footer'>

                                    <div
                                     className='save'
                                     onClick={()=> SendInformation()}
                                    >
                                        <div className='textSave'>
                                           {current_language?.menu_lateral_botao_salvar}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Fade>
				
			</Modal>
        </div>
        
    )
}
export default ModalFilterProcol;
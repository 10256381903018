import React,{useEffect, useState,} from "react";
import { BallIndicator } from "./styles";

interface PropsNovelyIndicator {
    top?:number;
    right?: number;
    startDate?: string;
    expirationDate?: string;
};
const NoveltyIndicatorBall: React.FC<PropsNovelyIndicator> = ({
    top ,
    right ,
    startDate = '00-00-0000',  
    expirationDate = '00-00-0000',
})=>{
   

    const [ visible, setVisible] = useState(false);
    

    useEffect(()=>{
        if(startDate < expirationDate ){
            setVisible(true)
        }else{
            setVisible(false)
        }
    },[startDate, expirationDate])





    return visible 
    ? 
        (
            <BallIndicator
                style={{top:top, right: right}}
            >
                
            </BallIndicator>
        )
    : null
}

export default NoveltyIndicatorBall;
import {
	createStyles,
	makeStyles,
	MenuItem,
	Select,
	Theme,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { getGroupingSide, getPermission } from "../../../utils/funcs";
import { TypesLanguage, TypesPermission } from "../../../utils/types";
import { ExerAfterListProps } from "../Evolucao";
import NenhumRelatorio from "../NenhumRelatorio";
import RenderGrafic from "./_renderGrafic";
import { Content, Exercice } from "./styles";
import { ConfigsActions } from "../../../store/ducks/configs";

const ForcaTempo: React.FC = () => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			button: {
				display: "block",
				marginTop: theme.spacing(2),
				border: "none",
				fontSize: 25,
			},
			formControl: {
				margin: theme.spacing(1),
				border: "none",
				fontSize: 25,
			},
			select: {
				border: 0,
				fontSize: 20,
				fontWeight: "bold",
				fontStyle: "normal",
				color: "#5C5B5B",
			},
		})
	);

	const dispatch = useDispatch();
	const [age, setAge] = React.useState<string>();
	const [executionsFilter, setExecutions] = React.useState<any>([]);
	const [open, setOpen] = React.useState(false);

	const language = useSelector((state: any) => state.configs.language);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const [finalExers, setFinalExers] = useState<Exercicios2Props[]>([]);
	const [finalExersRelatorios, setFinalExersRelatorios] = useState<
		ExerAfterListProps[]
	>([]);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);

	const opAvalFunci = useSelector((state: any) => state.relatorios.opAvalFunci);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		const filter: any = finalExersRelatorios.find(
			(e) => e.ID_EXERCICIO + e.MODO == age
		);
		const execfiltered = execucoes.filter((exe) => {
			return (
				exe.ID_USUARIO == paciente?.ID_USUARIO &&
				exe.ID_EXERCICIO == filter?.ID_EXERCICIO &&
				exe.EXECUCAO_TIPO == opAvalFunci.split("")[0].toLowerCase() &&
				exe.EXECUCAO_MODO == filter?.MODO &&
				exe.EXECUCAO_EXCLUIDA == false
			);
		});

		setExecutions(execfiltered);
	}, [age, opAvalFunci, paciente, is_open_toast]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setAge(event.target.value as string);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		!getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_EVOLUCAO,
			dispatch
		)
			? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
			: setOpen(true);
	};

	const classes = useStyles();

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	dispatch(AppActions.set_number_grafics(executionsFilter.length));
	useEffect(() => {
		const ids: any = [];
		const modosForExer: any = {};
		execucoes.forEach((e) => {
			if (e.ID_USUARIO == paciente.ID_USUARIO) {
				ids.push(e.ID_EXERCICIO);
				modosForExer[`${e.ID_EXERCICIO}-${e.EXECUCAO_MODO}`] = e.EXECUCAO_MODO;
			}
		});

		const filter = exercicios.filter((e) => {
			return ids.includes(e.ID_EXERCICIO);
		});

		let exers: any = [];
		filter.forEach((exer) => {
			let pro: any;
			for (pro in modosForExer) {
				if (exer.ID_EXERCICIO == pro.split("-")[0]) {
					const filt: any = exers.find(
						(ex: any) => ex.ID_EXERCICIO == exer.ID_EXERCICIO
					);
					if (filt) {
						const add = {
							...exer,
							modo: [...filt.modo, modosForExer[pro]],
						};
						exers = exers.filter(
							(ex: any) => ex.ID_EXERCICIO != exer.ID_EXERCICIO
						);
						exers = exers.filter(
							(ex: any) => ex.ID_EXERCICIO != exer.ID_EXERCICIO
						);
						exers.push(add);
					} else {
						exers.push({
							...exer,
							modo: [modosForExer[pro]],
						});
					}
				}
			}
		});
		setFinalExers(exers);
	}, []);

	useEffect(() => {
		const finalExers2: any = [];

		function ChangeType() {
			if (opAvalFunci == "AVALIACAO") {
				return "a";
			} else {
				return "f";
			}
		}

		finalExers.forEach((exer: any, index) => {
			if (exer.EXERCICIO_EXCLUIDO == false) {
				if (
					execucoes.find(
						(item) =>
							item.ID_EXERCICIO == exer.ID_EXERCICIO &&
							item.EXECUCAO_EXCLUIDA == false &&
							item.EXECUCAO_TIPO == ChangeType()
					)
				) {
					exer.modo.forEach((e: string) => {
						finalExers2.push({
							EXERCICIO_NOME:
								language == TypesLanguage.PT
									? finalExers[index]?.EXERCICIO_TITULO_PT
									: language == TypesLanguage.EN
									? finalExers[index]?.EXERCICIO_TITULO_EN
									: finalExers[index]?.EXERCICIO_TITULO_ES,
							ID_EXERCICIO: exer.ID_EXERCICIO,
							MODO: e[0],
						});
					});
				}
			}
		});
		setAge(finalExers2[0]?.ID_EXERCICIO + finalExers2[0]?.MODO);
		setFinalExersRelatorios(finalExers2);
	}, [finalExers, is_open_toast, opAvalFunci]);

	const [ permission, setPermission ] = useState(true)

	useEffect(()=>{
		const permissionTimeForce: any =  getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_FORCA_TEMPO
		)
		if(!permissionTimeForce){
			setPermission(false)
			dispatch(ConfigsActions.set_title_modal_upgrade(true))
		}else{
			dispatch(ConfigsActions.set_title_modal_upgrade(false))
		}
	
	},[])

	return (
		<Content>
			<Exercice>
				{
					 permission ? 
						<>
							<div className="Divtitle">
								<FormControl>
									{ finalExersRelatorios.length > 0 ?
										<Select
											className={"selectTitle"}
											labelId="demo-controlled-open-select-label"
											id="demo-controlled-open-select"
											open={open}
											value={age ? age : ""}
											onClose={handleClose}
											onOpen={handleOpen}
											onChange={handleChange}
										>
											{finalExersRelatorios.map((item) => (
												<MenuItem
													key={item.ID_EXERCICIO}
													value={item.ID_EXERCICIO + item.MODO}
												>
													{`${item.EXERCICIO_NOME} ${getGroupingSide(
														item.MODO,
														current_language
													)?.toLowerCase()}`}
												</MenuItem>
											))}
										</Select>
										: <p></p>
									}
								</FormControl>
							</div>
							<div className="row">
								{executionsFilter.length == 0 && <NenhumRelatorio />}
								{executionsFilter.map((exe: any) => {
									return <RenderGrafic key={exe.ID_EXECUCAO} execucao={exe} />;
								})}
							</div>
						</>
					: 	<NenhumRelatorio />

				}
			</Exercice>
		</Content>
	);
};

export default ForcaTempo;

import { push } from "connected-react-router";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastAlert from "../../components/toast";

import { TextsProps } from "../../database/txt.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { ProtocolosProps } from "../../interfaces/protocolo.interface";
import { AppActions } from "../../store/ducks/app";
import { AvaliationActions } from "../../store/ducks/avaliation";
import { ManageProtocolActions } from "../../store/ducks/manage_protocols";
import {
	TypesAvalOrFunci,
	TypesErrorModal,
	TypesErrorRequest,
	TypesProLivre,
} from "../../utils/types";
import Descanso from "./descanso";
import GraficResult from "./graficResult";
import { Body } from "./styles";
import { AmplitudeLog } from "../../utils/amplitude";
import { loadStorage, persistStorage } from "../../utils/base_async_storage";
import { getEvaluatedPatients } from "../../utils/funcs";

export interface ExerProtoPros {
	title: string;
	value: string;
	grupo?: any;
	exercice_id: any;
	id_protocolo_exer: any;
	name: any;
}

const ResultadoExecucao: React.FC = () => {
	const dispatch = useDispatch();

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const paciente = useSelector((state: any) => state.app.is_open_paciente);

	const current_exercise: ExerProtoPros = useSelector(
		(state: any) => state.avaliation.current_exercise
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const exercicio = useSelector((state: any) => state.app.exercicio);

	const intervalo_descanso_flag = useSelector(
		(state: any) => state.configs.intervalo_descanso_flag
	);

	const pico_result = useSelector((state: any) => state.avaliation.pico_result);

	const [picoResult, setPicoResult] = React.useState<any>(0);

	const current_repeticoes = useSelector(
		(state: any) => state.avaliation.current_repeticoes
	);

	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);

	const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);

	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);

	const max_force = useSelector((state: any) => state.avaliation.max_force);

	const slider_execucao = useSelector(
		(state: any) => state.app.slider_execucao
	);

	const values_result = useSelector(
		(state: any) => state.avaliation.values_result
	);
	const result_repe = useSelector(
		(state: any) => state.avaliation.result
	);

	const is_selected_type_pro_livre = useSelector(
		(state: any) => state.app.is_selected_type_pro_livre
	);

	const lado_exercicio = useSelector((state: any) => state.app.lado_exercicio);

	const time_final = useSelector((state: any) => state.avaliation.time_final);
	
	const questionarioSatisfacaoExameRespondido = useSelector((state: any)=> state.dashboard.resposta_questionario_satisfacao);

	const valorContador = tempo_contracao;
	const [contador, setContador] = React.useState<any>(valorContador);

	let resultMediaPico = 0;
	pico_result.map((i: any) => {
		if (i.valor > picoResult) {
			setPicoResult(i.valor);
		}
		resultMediaPico += i.valor;
	});
	resultMediaPico = resultMediaPico / pico_result.length;

	const handleOpenDuvidaExecucaoResultado = () => {
		dispatch(AppActions.set_open_duvida_execucao_resultado(true));
	};
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const dinamo_mac_address: string = useSelector ((state: any)=>state.configs.mac_address);
	const dinamo_uuid: string = useSelector ((state: any)=>state.configs.dinamo_uuid);

	const enviar = (e: any) => {
		const dat_request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ID_USUARIO: paciente?.ID_USUARIO,
			ID_PROTOCOLO:
				is_selected_type_pro_livre == TypesProLivre.LIVRE
					? 0
					: protocolo_list?.ID_PROTOCOLO,
			ID_EXERCICIO:
				is_selected_type_pro_livre == TypesProLivre.LIVRE
					? exercicio?.ID_EXERCICIO
					: current_exercise?.exercice_id,
			EXECUCAO_MODO:
				is_selected_type_pro_livre == TypesProLivre.LIVRE
					? lado_exercicio == "Sem Definicao"
						? "S"
						: lado_exercicio.substr(0, 1)
					: current_exercise?.value,
			EXECUCAO_TIPO: is_selected_avaliacao == "AVALIACAO" ? "a" : "f",
			EXECUCAO_PESOMIN: parseFloat(force_option_min).toFixed(1).toString(),
			EXECUCAO_PESOMAX: max_force
				? parseFloat(force_option_max).toFixed(1).toString()
				: 0,
			EXECUCAO_RM: slider_execucao,
			EXECUCAO_DADOS: values_result, 
			EXECUCAO_ATRIBUIR_REPS: result_repe,
			EXECUCAO_TEMPO: time_final,
			EXECUCAO_DINAMO_UUID_APPLE: "0",
			//EXECUCAO_DINAMO_MAC: dinamo_mac_address ? dinamo_mac_address : " ",
			EXECUCAO_DINAMO_UUID: dinamo_uuid ? dinamo_uuid : " ",
			EXECUCAO_EXECUTADA: moment(new Date())
				.format("YYYY-MM-DD HH:mm:ss")
				.toString(),
			EXECUCAO_EXCLUIDA: e,
		};
		dispatch(AvaliationActions.add_execucao_request(dat_request));
	};
	const online = navigator.onLine
	const set_toast_after_completing: boolean = useSelector(
		(state: any) => state.avaliation.set_toast_after_completing
	);
	const CompleteExercises = (id: any) => {
		if(is_selected_type_pro_livre == TypesProLivre.LIVRE){
			AmplitudeLog('concluir_exercicio', {body:id?.ID_EXERCICIO})
			getEvaluatedPatients(paciente)
		}
		if(is_selected_type_pro_livre == TypesProLivre.PROTOCOLO){
			AmplitudeLog('concluir_protocolo', {body:id})
		}
		is_selected_type_pro_livre == TypesProLivre.LIVRE
			? dispatch(AvaliationActions.set_progress_exers_proto(id?.ID_EXERCICIO))
			: current_repeticoes > 0 &&
			  dispatch(AvaliationActions.set_progress_exers_proto(id));
		dispatch(AppActions.set_resultado_execucao(false));
		setContador(valorContador);
		if(is_selected_type_pro_livre == TypesProLivre.PROTOCOLO ){
			dispatch(ManageProtocolActions.set_button_goback(true));
		}else{
			dispatch(ManageProtocolActions.set_button_goback(false));
		}
		if(online){
			dispatch(AvaliationActions.set_toast_after_completing(true))
		}
		current_repeticoes > 0
			? enviar("N")
			: toogle(current_language?.zero_repeticoes);
		dispatch(AvaliationActions.reset2());
		dispatch(AvaliationActions.reset());
		handleNavigation()
		is_selected_type_pro_livre == TypesProLivre.LIVRE &&
			dispatch(push("/paciente"));
	};

	
	const handleNavigation = ()=>{
			if(online){
				dispatch(AppActions.set_checklist_execucao(true));
			}else {
				dispatch(AppActions.set_resultado_execucao(true));
			}
	};

	const avaliationInit = (exerc: any) => {
		dispatch(AvaliationActions.set_current_exercicise(exerc));
		if (is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO) {
			dispatch(AvaliationActions.set_in_execution_force_max_status(false));
		}
		dispatch(AppActions.set_checklist_execucao(false));
		dispatch(AppActions.set_resultado_execucao(false));
		setContador(valorContador);
		current_repeticoes > 0 && enviar("S");
		dispatch(AvaliationActions.reset2());
		dispatch(AvaliationActions.reset());
	};

	const toogle = (msg: string) => {
		if(online){
			dispatch(
				AppActions.set_modal_visible({
					type: TypesErrorModal.FAIL,
					message: msg,
				})
			);
		}else{
			console.log(current_language?.erro_video)
		}
	};


	return (
		<>
			<div className="header">
			{is_open_toast && !online && <ToastAlert />}
				<div className="perfil">
					<svg
						className="fotoPerfil"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="10" cy="10" r="9.5" fill="#EBEBEB" stroke="#E5E5E5" />
						<path
							d="M10.2747 10.2864C11.7319 10.2864 12.9121 9.10355 12.9121 7.64319C12.9121 6.18283 11.7319 5 10.2747 5C8.81758 5 7.63736 6.18283 7.63736 7.64319C7.63736 9.10355 8.81758 10.2864 10.2747 10.2864ZM10.2747 11.608C8.51429 11.608 5 12.4934 5 14.2512V15.5728H15.5495V14.2512C15.5495 12.4934 12.0352 11.608 10.2747 11.608Z"
							fill="#B3B3B3"
						/>
					</svg>
					<div className="info">
						<p>{user_logado.USUARIO_NOME}</p>
						<div>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 12C15.315 12 18 9.315 18 6C18 2.685 15.315 0 12 0C8.685 0 6 2.685 6 6C6 9.315 8.685 12 12 12ZM12 15C7.995 15 0 17.01 0 21V24H24V21C24 17.01 16.005 15 12 15Z"
									fill="#001574"
								/>
							</svg>
							<small>{paciente?.USUARIO_NOME}</small>
						</div>
					</div>
				</div>
			</div>
			<Body>
				{is_selected_type_pro_livre == TypesProLivre.LIVRE ? (
					<h2 className="titleH2">
						{current_language?.resultado} -{" "}
						{current_language?.idioma === "Português"
							? exercicio?.EXERCICIO_TITULO_PT
							: current_language?.idioma === "English"
							? exercicio?.EXERCICIO_TITULO_EN
							: exercicio?.EXERCICIO_TITULO_ES}
					</h2>
				) : (
					<h2 className="titleH2">
						{current_language?.resultado} -{" "}
						{current_language?.idioma === "Português"
							? exercicio?.EXERCICIO_TITULO_PT
							: current_language?.idioma === "English"
							? exercicio?.EXERCICIO_TITULO_EN
							: exercicio?.EXERCICIO_TITULO_ES}{" "}
						({current_exercise?.title})
					</h2>
				)}
				{intervalo_descanso_flag && <Descanso />}

				<GraficResult />

				<div className="legendaResultado">
					<div className="legendaInfo">
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18 0L18 3L-1.31134e-07 3L0 -7.86805e-07L18 0ZM9 5.25L-7.86805e-07 18L18 18L9 5.25Z"
								fill="#5C5B5B"
							/>
						</svg>
						<p>
							{current_language?.execucao_force_max}{" "}
							<b>{parseFloat(picoResult).toFixed(2)} Kg</b>
						</p>
					</div>

					<div className="legendaInfo">
						<svg
							width="20"
							height="23"
							viewBox="0 0 20 23"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.44444 5.55556H15.5556V8.88889L20 4.44444L15.5556 0V3.33333H2.22222V10H4.44444V5.55556ZM15.5556 16.6667H4.44444V13.3333L0 17.7778L4.44444 22.2222V18.8889H17.7778V12.2222H15.5556V16.6667Z"
								fill="#5C5B5B"
							/>
							<path
								d="M10.3236 10.5664L11.3294 8.82812H12.6478L11.0316 11.4307L12.7015 14.1113H11.3929L10.3382 12.3047L9.28841 14.1113H7.97005L9.63997 11.4307L8.02865 8.82812H9.33724L10.3236 10.5664Z"
								fill="#5C5B5B"
							/>
						</svg>
						<p>
							{current_language?.config_aval_repeticoes}{" "}
							<b>{current_repeticoes}</b>
						</p>
					</div>

					<div className="legendaInfo">
						<svg
							width="20"
							height="18"
							viewBox="0 0 20 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 15.3387C0 15.3387 1.11087 4.08967 4.4435 0L9.99787 0.999918L8.88699 4.08967H6.66525V11.2491H7.77612C9.99787 8.17933 14.5969 7.05942 17.3741 8.17933C21.04 9.70921 20.7067 14.3188 17.3741 16.3587C14.708 17.9985 6.66525 19.4284 0 15.3387Z"
								fill="#5C5B5B"
							/>
						</svg>

						<p>
							{current_language?.execucao_force_media}{" "}
							<b>
								{isNaN(resultMediaPico) ? "0.00" : resultMediaPico.toFixed(2)}{" "}
								Kg
							</b>
						</p>
					</div>

					<div
						className="legendaInfo duvida"
						onClick={handleOpenDuvidaExecucaoResultado}
					>
						<svg
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.6666 22H15.3333V19.3333H12.6666V22ZM14 0.666641C12.249 0.666641 10.5152 1.01152 8.89751 1.68158C7.27984 2.35164 5.80998 3.33377 4.57187 4.57188C2.07138 7.07237 0.666626 10.4638 0.666626 14C0.666626 17.5362 2.07138 20.9276 4.57187 23.4281C5.80998 24.6662 7.27984 25.6483 8.89751 26.3184C10.5152 26.9884 12.249 27.3333 14 27.3333C17.5362 27.3333 20.9276 25.9285 23.428 23.4281C25.9285 20.9276 27.3333 17.5362 27.3333 14C27.3333 12.249 26.9884 10.5152 26.3184 8.89753C25.6483 7.27985 24.6662 5.81 23.428 4.57188C22.1899 3.33377 20.7201 2.35164 19.1024 1.68158C17.4847 1.01152 15.7509 0.666641 14 0.666641ZM14 24.6666C8.11996 24.6666 3.33329 19.88 3.33329 14C3.33329 8.11997 8.11996 3.33331 14 3.33331C19.88 3.33331 24.6666 8.11997 24.6666 14C24.6666 19.88 19.88 24.6666 14 24.6666ZM14 5.99997C12.5855 5.99997 11.2289 6.56188 10.2287 7.56207C9.22853 8.56226 8.66663 9.91882 8.66663 11.3333H11.3333C11.3333 10.6261 11.6142 9.94779 12.1143 9.44769C12.6144 8.94759 13.2927 8.66664 14 8.66664C14.7072 8.66664 15.3855 8.94759 15.8856 9.44769C16.3857 9.94779 16.6666 10.6261 16.6666 11.3333C16.6666 14 12.6666 13.6666 12.6666 18H15.3333C15.3333 15 19.3333 14.6666 19.3333 11.3333C19.3333 9.91882 18.7714 8.56226 17.7712 7.56207C16.771 6.56188 15.4144 5.99997 14 5.99997Z"
								fill="#001574"
							/>
						</svg>
					</div>
				</div>
				{is_selected_type_pro_livre == TypesProLivre.LIVRE ? (
					<button
						className="botaoConcluir"
						onClick={() => CompleteExercises(exercicio)}
					>
						{current_language?.concluir}
					</button>
				) : (
					<button
						className="botaoConcluir"
						onClick={() => CompleteExercises(current_exercise)}
					>
						{current_language?.concluir}
					</button>
				)}
				<button
					className="botaoRefazer"
					onClick={() => {
						avaliationInit(current_exercise)
						AmplitudeLog(
							is_selected_type_pro_livre == TypesProLivre.LIVRE 
							?'descartar_execucao_exercicio'
							:'descartar_execucao_protocolo'
						)
					}}
				>
					{current_language?.delete_execucao}
				</button>
			</Body>
		</>
	);
};

export default ResultadoExecucao;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 45vh;
	overflow-x: hidden;
	padding-right:1rem;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 6rem;
		padding-top: 1.87rem;
		padding-bottom: 1.87rem;
		border-bottom: 1px solid #e0e0e0;
		padding: 1.7rem;
		margin-top: 1rem;
		border-radius:8px;
		margin-left:2px;
		box-shadow: 1px 1px 4px #5c5b5b50;
	}

	.c {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;
		height: 100%;
	}

	.ca {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		width: 100%;
		height: 50%;
	}

	.cb {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50%;
		background-color: white;
	}

	.cc {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
	}

	.d {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		background-color: snow;
	}

	.condicao {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 1.3rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 50%;
		height: 100%;
	}

	.b {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
	}

	.p {
		color: #5c5b5b;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.botao {
		display: flex;
		flex-direction: row;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: ${colors.primary_blue_k_dark_2};
		background-color: transparent;
		border: none;
		margin-left: 2.1rem;
		margin-right: 1rem;
	}

	.boxSearch {
		width: 100%;
		margin-top: 1rem;
	}


	.input {
		display: flex;
		align-items: center;
		width: 100%;
		height: 2.3rem;
		background-color: #f2f2f2;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		border-radius: 10px;
		margin-bottom: 1rem;
	}
	.reset {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}
	.inputPesquisa {
		height: 90%;
		width: 100%;
		margin-left: 1rem;
		margin-right: 1rem;
		background-color: transparent;
		border: none;
	}

`;

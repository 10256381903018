import React from "react";
import { Provider } from "react-redux";

import ModalAdicionarAnotacao from "./components/Modais/ModalAdicionarAnotacao";
import ModalAdicionarAvaliador from "./components/Modais/ModalAdicionarAvaliador";
import ModalAdicionarEtiqueta from "./components/Modais/ModalAdicionarEtiqueta";
import ModalAdicionarPaciente from "./components/Modais/ModalAdicionarPaciente";
import ModalAlert from "./components/Modais/ModalAlert";
import ModalApagarAnotacao from "./components/Modais/ModalApagarAnotacao";
import ModalAtribuirAvaliador from "./components/Modais/ModalAtribuirAvaliador";
import ModalAtribuirEtiqueta from "./components/Modais/ModalAtribuirEtiqueta";
import ModalAtribuirFisica from "./components/Modais/ModalAtribuirFisica";
import ModalCadastroSuccess from "./components/Modais/ModalCadastroSuccess";
import ModalCentraAjuda from "./components/Modais/ModalCentraAjuda";
import ModalCofiguracoesExecucao from './components/Modais/ModalCofiguracoesExecucao';
import ModalCofiguracoesExecucaoForcaMin from './components/Modais/ModalCofiguracoesExecucaoForcaMin';
import ModalCompartilhamento from "./components/Modais/ModalCompartilhamento";
import ModalConecta from "./components/Modais/ModalConecta";
import ModalConecta2 from "./components/Modais/ModalConecta2";
import ModalConecta3 from "./components/Modais/ModalConecta3";
import ModalConecta4 from "./components/Modais/ModalConecta4";
import ModalConexoes from "./components/Modais/ModalConexoes";
import ModalConta from "./components/Modais/ModalConta";
import ModalContagemExercicio from "./components/Modais/ModalContagemExercicio";
import ModalDuvidaExecucao from "./components/Modais/ModalDuvidaExecucao";
import ModalDuvidaExecucaoResultado from "./components/Modais/ModalDuvidaExecucaoResultado";
import ModalEditarAnotacao from "./components/Modais/ModalEditarAnotacao";
import EditarAvaliador from "./components/Modais/ModalEditarAvaliador";
import ModalEditarEtiqueta from "./components/Modais/ModalEditarEtiqueta";
import EditarPaciente from "./components/Modais/ModalEditarPaciente";
import ModalExcluir from "./components/Modais/ModalExcluir";
import ModalExcluirEtiqueta from "./components/Modais/ModalExcluirEtiqueta";
import ModalFiltroEtiquetas from "./components/Modais/ModalFiltroEtiquetas";
import ModalFotoPerfil from "./components/Modais/ModalFotoPerfil";
import ModalInadimplente from "./components/Modais/ModalInadimplente";
import ModalLinguagem from "./components/Modais/ModalLinguagem";
import ModalMigracao from "./components/Modais/ModalMigracao";
import ModalModoExercicio from "./components/Modais/ModalModoExercicio";
import ModalNotificacoes from "./components/Modais/ModalNotificacoes";
import ModalRecuperar from "./components/Modais/ModalRecuperar";
import ModalSelectAvaliation from "./components/Modais/ModalSelectAvaliation";
import ModalTutorial from "./components/Modais/ModalTutorial";
import ModalUpgrade from "./components/Modais/ModalUpgrade";
import ModalUpgradeFuncionalidade from "./components/Modais/ModalUpgradeFuncionalidade";
import ModalUpgradeFuncionalidadeIndisponivel from "./components/Modais/ModalUpgradeFuncionalidadeIndisponivel";
import ModalUpgradeRelatorios from './components/Modais/ModalUpgradeRelatorios';
import ModalContinuarSF36 from "./components/Modais/ModalContinuarSF_36"
import Routes from "./routes";
import store from "./store";
import ModalContinuarAnamnese from "./components/Modais/ModalContinuarAnamnese";
import ModalBloqueioDino from "./components/Modais/ModalBloqueioDino";
import ModalFilterProcol from "./components/Modais/ModalFiltroProtocolo";
import ModalSatisfacao from "./components/Modais/ModalSatisfacao";
import ModalSatisfacaoExame from "./components/Modais/ModalSatisfacaoExame";
import ModalCadastroCartao from "./components/Modais/ModalAvisoCartao";

// test build

const App = function () {
	return (
		<Provider store={store}>
			<Routes />
			<ModalAlert />
			<ModalCadastroSuccess />
			<ModalConecta />
			<ModalConecta2 />
			<ModalConecta3 />
			<ModalConecta4 />
			<ModalConta />
			<ModalLinguagem />
			<ModalFiltroEtiquetas />
			<ModalNotificacoes />
			<ModalExcluir />
			<ModalConexoes />
			<ModalUpgrade />
			<ModalAtribuirAvaliador />
			<ModalAdicionarPaciente />
			<ModalAdicionarAvaliador />
			<ModalAtribuirEtiqueta />
			<ModalAtribuirFisica />
			<ModalFotoPerfil />
			<ModalAdicionarEtiqueta />
			<ModalContagemExercicio />
			<ModalSelectAvaliation />
			<ModalCofiguracoesExecucao />
			<ModalCofiguracoesExecucaoForcaMin />
			<ModalDuvidaExecucao />
			<ModalDuvidaExecucaoResultado />
			<EditarPaciente />
			<ModalAdicionarAnotacao />
			<ModalApagarAnotacao />
			<ModalEditarAnotacao />
			<ModalCompartilhamento />
			<EditarAvaliador />
			<ModalModoExercicio />
			<ModalRecuperar />
			<ModalEditarEtiqueta />
			<ModalExcluirEtiqueta />
			<ModalTutorial />
			<ModalCentraAjuda />
			<ModalUpgradeFuncionalidade />
			<ModalUpgradeFuncionalidadeIndisponivel />
			<ModalMigracao />
			<ModalUpgradeRelatorios />
			<ModalInadimplente />
			<ModalContinuarSF36/>
			<ModalContinuarAnamnese/>
			<ModalBloqueioDino/>
			<ModalFilterProcol/>
			<ModalSatisfacao />
			<ModalSatisfacaoExame />
			<ModalCadastroCartao />
		</Provider>
	);
};

export default App;

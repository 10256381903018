import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";

//abdominal
import INI_ABDOMINAL_LATERAL from '../../../assets/tutoriais/INI_ABDOMINAL_LATERAL.png';
import FIN_ABDOMINAL_LATERAL from '../../../assets/tutoriais/FIN_ABDOMINAL_LATERAL.png';
//abdução de ombro
import INI_ABDUCAO_OMBRO_60_FRONTAL from '../../../assets/tutoriais/INI_ABDUCAO_OMBRO_60_FRONTAL.png';
import FIN_ABDUCAO_OMBRO_60_FRONTAL from '../../../assets/tutoriais/FIN_ABDUCAO_OMBRO_60_FRONTAL.png';
//Abdução de Quadril
import INI_ABDUCAO_QUADRIL_FRONTAL from '../../../assets/tutoriais/INI_ABDUCAO_QUADRIL_FRONTAL.png';
import FIN_ABDUCAO_QUADRIL_FRONTAL from '../../../assets/tutoriais/FIN_ABDUCAO_QUADRIL_FRONTAL.png';
import INI_ABDUCAO_QUADRIL_LATERAL from '../../../assets/tutoriais/INI_ABDUCAO_QUADRIL_LATERAL.png';
import FIN_ABDUCAO_QUADRIL_LATERAL from '../../../assets/tutoriais/FIN_ABDUCAO_QUADRIL_LATERAL.png';
//Adução de Ombro
import INI_ADUCAO_OMBRO from '../../../assets/tutoriais/INI_ADUCAO_OMBRO.png';
import FIN_ADUCAO_OMBRO from '../../../assets/tutoriais/FIN_ADUCAO_OMBRO.png';
//Adução de Quadril
import INI_ADUCAO_QUADRIL_DIAGONAL from '../../../assets/tutoriais/INI_ADUCAO_QUADRIL_DIAGONAL.png';
import FIN_ADUCAO_QUADRIL_DIAGONAL from '../../../assets/tutoriais/FIN_ADUCAO_QUADRIL_DIAGONAL.png';
import INI_ADUCAO_QUADRIL_FRONTAL from '../../../assets/tutoriais/INI_ADUCAO_QUADRIL_FRONTAL.png';
import FIN_ADUCAO_QUADRIL_FRONTAL from '../../../assets/tutoriais/FIN_ADUCAO_QUADRIL_FRONTAL.png';
//Afundo
import INI_AFUNDO_LATERAL from '../../../assets/tutoriais/INI_AFUNDO_LATERAL.png';
import FIN_AFUNDO_LATERAL from '../../../assets/tutoriais/FIN_AFUNDO_LATERAL.png';
import INI_AFUNDO_DIAGONAL from '../../../assets/tutoriais/INI_AFUNDO_DIAGONAL.png';
import FIN_AFUNDO_DIAGONAL from '../../../assets/tutoriais/FIN_AFUNDO_DIAGONAL.png';
//Agachamento
import INI_AGACHAMENTO_DIAGONAL from '../../../assets/tutoriais/INI_AGACHAMENTO_DIAGONAL.png';
import FIN_AGACHAMENTO_DIAGONAL from '../../../assets/tutoriais/FIN_AGACHAMENTO_DIAGONAL.png';
//Crucifixo
import INI_CRUCIFIXO_FRONTAL from '../../../assets/tutoriais/INI_CRUCIFIXO_FRONTAL.png';
import FIN_CRUCIFIXO_FRONTAL from '../../../assets/tutoriais/FIN_CRUCIFIXO_FRONTAL.png';
import INI_CRUCIFIXO_LATERAL from '../../../assets/tutoriais/INI_CRUCIFIXO_LATERAL.png';
import FIN_CRUCIFIXO_LATERAL from '../../../assets/tutoriais/FIN_CRUCIFIXO_LATERAL.png';
//Desenvolvimento de Ombro 
import INI_DESENVOLVIMENTO_OMBRO_BILATERAL_DIAGONAL from '../../../assets/tutoriais/INI_DESENVOLVIMENTO_OMBRO_BILATERAL_DIAGONAL.png';
import FIN_DESENVOLVIMENTO_OMBRO_BILATERAL_DIAGONAL from '../../../assets/tutoriais/FIN_DESENVOLVIMENTO_OMBRO_BILATERAL_DIAGONAL.png';

//Dorsiflexão
import INI_DORSIFLEXAO_LATERAL from '../../../assets/tutoriais/INI_DORSIFLEXAO_LATERAL.png';
import FIN_DORSIFLEXAO_LATERAL from '../../../assets/tutoriais/FIN_DORSIFLEXAO_LATERAL.png';
//Eversão
import INI_EVERSAO_DIAGONAL from '../../../assets/tutoriais/INI_EVERSAO_DIAGONAL.png';
import FIN_EVERSAO_DIAGONAL from '../../../assets/tutoriais/FIN_EVERSAO_DIAGONAL.png';
import INI_EVERSAO_SUPERIOR from '../../../assets/tutoriais/INI_EVERSAO_SUPERIOR.png';
import FIN_EVERSAO_SUPERIOR from '../../../assets/tutoriais/FIN_EVERSAO_SUPERIOR.png';
//Extensão de Cotovelo
import INI_EXTENSAO_COTOVELO_LATERAL from '../../../assets/tutoriais/INI_EXTENSAO_COTOVELO_LATERAL.png';
import FIN_EXTENSAO_COTOVELO_LATERAL from '../../../assets/tutoriais/FIN_EXTENSAO_COTOVELO_LATERAL.png';
//Extensão de Cotovelo Francês bilateral
import INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_SUPERIOR from '../../../assets/tutoriais/INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_SUPERIOR.png';
import FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_SUPERIOR from '../../../assets/tutoriais/FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_SUPERIOR.png';
import INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO_2_LATERAL from '../../../assets/tutoriais/INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO_2_LATERAL.png';
import FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO_2_LATERAL from '../../../assets/tutoriais/FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO_2_LATERAL.png';
//Extensão de Cotovelo Testa bilateral
import INI_EXTENSAO_COTOVELO_TESTA_BILATERAL_LATERAL from '../../../assets/tutoriais/INI_EXTENSAO_COTOVELO_TESTA_BILATERAL_LATERAL.png';
import FIN_EXTENSAO_COTOVELO_TESTA_BILATERAL_LATERAL from '../../../assets/tutoriais/FIN_EXTENSAO_COTOVELO_TESTA_BILATERAL_LATERAL.png';
import INI_EXTENSAO_COTOVELO_TESTA_BILATERAL_FRONTAL from '../../../assets/tutoriais/INI_EXTENSAO_COTOVELO_TESTA_BILATERAL_FRONTAL.png';
import FIN_EXTENSAO_COTOVELO_TESTA_BILATERAL_FRONTAL from '../../../assets/tutoriais/FIN_EXTENSAO_COTOVELO_TESTA_BILATERAL_FRONTAL.png';
//Extensão de Joelho
import INI_EXTENSAO_JOELHO_LATERAL from '../../../assets/tutoriais/INI_EXTENSAO_JOELHO_LATERAL.png';
import FIN_EXTENSAO_JOELHO_LATERAL from '../../../assets/tutoriais/FIN_EXTENSAO_JOELHO_LATERAL.png';
//Extensão de Ombro
import INI_EXTENSAO_OMBRO_LATERAL from '../../../assets/tutoriais/INI_EXTENSAO_OMBRO_LATERAL.png';
import FIN_EXTENSAO_OMBRO_LATERAL from '../../../assets/tutoriais/FIN_EXTENSAO_OMBRO_LATERAL.png';
//Extensão de Quadril
import INI_EXTENSAO_QUADRIL_LATERAL from '../../../assets/tutoriais/INI_EXTENSAO_QUADRIL_LATERAL.png';
import FIN_EXTENSAO_QUADRIL_LATERAL from '../../../assets/tutoriais/FIN_EXTENSAO_QUADRIL_LATERAL.png';
//Flexão de Cotovelo
import INI_FLEXAO_COTOVELO_LATERAL from '../../../assets/tutoriais/INI_FLEXAO_COTOVELO_LATERAL.png';
import FIN_FLEXAO_COTOVELO_LATERAL from '../../../assets/tutoriais/FIN_FLEXAO_COTOVELO_LATERAL.png';
//Flexão de Joelho em dv
import INI_FLEXAO_JOELHO_DV_LATERAL from '../../../assets/tutoriais/INI_FLEXAO_JOELHO_DV_LATERAL.png';
import FIN_FLEXAO_JOELHO_DV_LATERAL from '../../../assets/tutoriais/FIN_FLEXAO_JOELHO_DV_LATERAL.png';
import INI_FLEXAO_JOELHO_DV_LATERAL_ZOOM from '../../../assets/tutoriais/INI_FLEXAO_JOELHO_DV_LATERAL_ZOOM.png';
import FIN_FLEXAO_JOELHO_DV_LATERAL_ZOOM from '../../../assets/tutoriais/FIN_FLEXAO_JOELHO_DV_LATERAL_ZOOM.png';
//Flexão de Ombro 
import INI_FLEXAO_OMBRO_LATERAL from '../../../assets/tutoriais/INI_FLEXAO_OMBRO_LATERAL.png';
import FIN_FLEXAO_OMBRO_LATERAL from '../../../assets/tutoriais/FIN_FLEXAO_OMBRO_LATERAL.png';
//Flexão do Pescoço
import INI_FLEXAO_PESCOCO_LATERAL from '../../../assets/tutoriais/INI_FLEXAO_PESCOCO_LATERAL.png';
import FIN_FLEXAO_PESCOCO_LATERAL from '../../../assets/tutoriais/FIN_FLEXAO_PESCOCO_LATERAL.png';
//Flexão de punho
import INI_FLEXAO_PUNHO_DIAGONAL from '../../../assets/tutoriais/INI_FLEXAO_PUNHO_DIAGONAL.png';
import FIN_FLEXAO_PUNHO_DIAGONAL from '../../../assets/tutoriais/FIN_FLEXAO_PUNHO_DIAGONAL.png';
//Flexão de quadril
import INI_FLEXAO_QUADRIL_LATERAL from '../../../assets/tutoriais/INI_FLEXAO_QUADRIL_LATERAL.png';
import FIN_FLEXAO_QUADRIL_LATERAL from '../../../assets/tutoriais/FIN_FLEXAO_QUADRIL_LATERAL.png';
//Flexão lateral de pescoço
import INI_FLEXAO_LATERAL_PESCOCO_FRONTAL from '../../../assets/tutoriais/INI_FLEXAO_LATERAL_PESCOCO_FRONTAL.png';
import FIN_FLEXAO_LATERAL_PESCOCO_FRONTAL from '../../../assets/tutoriais/FIN_FLEXAO_LATERAL_PESCOCO_FRONTAL.png';
//Flexão lateral de tronco
import INI_FLEXAO_LATERAL_TRONCO_FRONTAL from '../../../assets/tutoriais/INI_FLEXAO_LATERAL_TRONCO_FRONTAL.png';
import FIN_FLEXAO_LATERAL_TRONCO_FRONTAL from '../../../assets/tutoriais/FIN_FLEXAO_LATERAL_TRONCO_FRONTAL.png';
//Inversão
import INI_INVERSAO_SUPERIOR from '../../../assets/tutoriais/INI_INVERSAO_SUPERIOR.png';
import FIN_INVERSAO_SUPERIOR from '../../../assets/tutoriais/FIN_INVERSAO_SUPERIOR.png';
//Remada Aberta Bilateral
import INI_REMADA_ABERTA_UNILATERAL_LATERAL from '../../../assets/tutoriais/INI_REMADA_ABERTA_UNILATERAL_LATERAL.png';
import FIN_REMADA_ABERTA_UNILATERAL_LATERAL from '../../../assets/tutoriais/FIN_REMADA_ABERTA_UNILATERAL_LATERAL.png';
//Remada Alta Bilateral
import INI_REMADA_ALTA_BILATERAL_VISTA_LATERAL from '../../../assets/tutoriais/INI_REMADA_ALTA_BILATERAL_VISTA_LATERAL.png';
import FIN_REMADA_ALTA_BILATERAL_VISTA_LATERAL from '../../../assets/tutoriais/FIN_REMADA_ALTA_BILATERAL_VISTA_LATERAL.png';
//Remada bilateral
import INI_REMADA_UNILATERAL_LATERAL from '../../../assets/tutoriais/INI_REMADA_UNILATERAL_LATERAL.png';
import FIN_REMADA_UNILATERAL_LATERAL from '../../../assets/tutoriais/FIN_REMADA_UNILATERAL_LATERAL.png';
//Rotação Externa de Ombro
import INI_ROTACAO_EXTERNA_OMBRO_FRONTAL from '../../../assets/tutoriais/INI_ROTACAO_EXTERNA_OMBRO_FRONTAL.png';
import FIN_ROTACAO_EXTERNA_OMBRO_FRONTAL from '../../../assets/tutoriais/FIN_ROTACAO_EXTERNA_OMBRO_FRONTAL.png';
//Rotação Externa de Quadril
import INI_ROTACAO_EXTERNA_QUADRIL_SUPERIOR from '../../../assets/tutoriais/INI_ROTACAO_EXTERNA_QUADRIL_SUPERIOR.png';
import FIN_ROTACAO_EXTERNA_QUADRIL_SUPERIOR from '../../../assets/tutoriais/FIN_ROTACAO_EXTERNA_QUADRIL_SUPERIOR.png';
//Rotação Interna de Ombro
import INI_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL from '../../../assets/tutoriais/INI_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL.png';
import FIN_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL from '../../../assets/tutoriais/FIN_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL.png';
import INI_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL from '../../../assets/tutoriais/INI_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL.png';
import FIN_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL from '../../../assets/tutoriais/FIN_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL.png';
//Rotação Interna de Quadril
import INI_ROTACAO_INTERNA_QUADRIL_SUPERIOR from '../../../assets/tutoriais/INI_ROTACAO_INTERNA_QUADRIL_SUPERIOR.png';
import FIN_ROTACAO_INTERNA_QUADRIL_SUPERIOR from '../../../assets/tutoriais/FIN_ROTACAO_INTERNA_QUADRIL_SUPERIOR.png';
//Supino Bilateral
import INI_SUPINO_BILATERAL_VISTA_LATERAL from '../../../assets/tutoriais/INI_SUPINO_BILATERAL_VISTA_LATERAL.png';
import FIN_SUPINO_BILATERAL_VISTA_LATERAL from '../../../assets/tutoriais/FIN_SUPINO_BILATERAL_VISTA_LATERAL.png';
import INI_SUPINO_BILATERAL_VISTA_FRONTAL from '../../../assets/tutoriais/INI_SUPINO_BILATERAL_VISTA_FRONTAL.png';
import FIN_SUPINO_BILATERAL_VISTA_FRONTAL from '../../../assets/tutoriais/FIN_SUPINO_BILATERAL_VISTA_FRONTAL.png';

import { TextsProps } from "../../../database/txt.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { AppActions } from "../../../store/ducks/app";
import { TypesLanguage } from "../../../utils/types";
import { Container } from "./styles";
import { linksForVideo } from "../../../database/tutoriais";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

enum TypeView {
	VIDEO = 'Video',
	IMAGE = 'Image',
};
interface PropsLink  {
	id: number, 
	name: string, 
	link: string
};

const ModalTutorial: React.FC = () => {
	// comunica com redux
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const language = useSelector((state: any) => state.configs.language);

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const exercicio_tutorial = useSelector(
		(state: any) => state.app.exercicio_tutorial
	);

	const idExercicio: any = exercicios.find(
		(e: any) => exercicio_tutorial == e.ID_EXERCICIO
	);


	const classes = useStyles();
	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const is_open_tutorial = useSelector(
		(state: any) => state.app.is_open_tutorial
	);

	const [position, setPosition] = useState(1);
	const [widthImage, setWidthImage] = useState(0);
	const [heightImage, setHeightImage] = useState(0);
	const [ OptionView, setOptionView] = useState(TypeView.VIDEO)
	const [loading, setLoading] = useState<boolean>(true);
	const [infoLink, setInfoLink] = useState<PropsLink>()

	// função para fechar modal
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_tutorial(false));
		setOptionView(TypeView.VIDEO)
		setPosition(1);
		setTimeout(()=>{setLoading(true)}, 500)
	};

	const ReplacePosition = (way: string) => {
		if(way === 'foward') setPosition(position + 1);
		if(way === 'foward' && position === tutorialImages[exercicio_tutorial].length) setPosition(1);
		if(way === 'back') setPosition(position - 1);
		if(way === 'back' && position === 1) setPosition(tutorialImages[exercicio_tutorial].length);
	};

	useEffect(()=> {

		const GetInfoLink = ()=> {
			var dinamicLink
			dinamicLink = linksForVideo?.find((e:PropsLink)=> exercicio_tutorial ==  e.name  )
			if(!!dinamicLink){
				setInfoLink(dinamicLink)
			}else{
			setInfoLink(undefined)
			}
		};
		
		GetInfoLink()

	},[exercicio_tutorial, idExercicio, loading])

	const tutorialImages: any = {
		Agachamento: [INI_AGACHAMENTO_DIAGONAL, FIN_AGACHAMENTO_DIAGONAL],
		'Flexão de cotovelo': [INI_FLEXAO_COTOVELO_LATERAL, FIN_FLEXAO_COTOVELO_LATERAL],
		'Extensão de cotovelo': [INI_EXTENSAO_COTOVELO_LATERAL, FIN_EXTENSAO_COTOVELO_LATERAL],
		'Extensão de cotovelo francês': [
			INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_SUPERIOR, FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_SUPERIOR,
			INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO_2_LATERAL, FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO_2_LATERAL
		],
		'Extensão de cotovelo testa': [
			INI_EXTENSAO_COTOVELO_TESTA_BILATERAL_LATERAL, FIN_EXTENSAO_COTOVELO_TESTA_BILATERAL_LATERAL,
			INI_EXTENSAO_COTOVELO_TESTA_BILATERAL_FRONTAL, FIN_EXTENSAO_COTOVELO_TESTA_BILATERAL_FRONTAL
		],
		'Remada alta': [INI_REMADA_ALTA_BILATERAL_VISTA_LATERAL, FIN_REMADA_ALTA_BILATERAL_VISTA_LATERAL],
		Remada: [
			INI_REMADA_UNILATERAL_LATERAL, FIN_REMADA_UNILATERAL_LATERAL,
		],
		'Remada aberta': [
			INI_REMADA_ABERTA_UNILATERAL_LATERAL, FIN_REMADA_ABERTA_UNILATERAL_LATERAL
		],
		'Supino reto': [
			INI_SUPINO_BILATERAL_VISTA_LATERAL, FIN_SUPINO_BILATERAL_VISTA_LATERAL,
			INI_SUPINO_BILATERAL_VISTA_FRONTAL, FIN_SUPINO_BILATERAL_VISTA_FRONTAL
		],
		Crucifixo: [
			INI_CRUCIFIXO_FRONTAL, FIN_CRUCIFIXO_FRONTAL,
			INI_CRUCIFIXO_LATERAL, FIN_CRUCIFIXO_LATERAL
		],
		'Flexão lateral de tronco': [INI_FLEXAO_LATERAL_TRONCO_FRONTAL, FIN_FLEXAO_LATERAL_TRONCO_FRONTAL],
		Abdominal: [INI_ABDOMINAL_LATERAL, FIN_ABDOMINAL_LATERAL],
		'Rotação interna': [
			INI_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL, FIN_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL,
			INI_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL, FIN_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL
		],
		'Rotação externa': [INI_ROTACAO_EXTERNA_OMBRO_FRONTAL, FIN_ROTACAO_EXTERNA_OMBRO_FRONTAL],
		'Flexão de ombro': [INI_FLEXAO_OMBRO_LATERAL, FIN_FLEXAO_OMBRO_LATERAL],
		'Extensão de ombro': [INI_EXTENSAO_OMBRO_LATERAL, FIN_EXTENSAO_OMBRO_LATERAL],
		'Abdução de ombro': [INI_ABDUCAO_OMBRO_60_FRONTAL, FIN_ABDUCAO_OMBRO_60_FRONTAL],
		'Adução de ombro': [INI_ADUCAO_OMBRO, FIN_ADUCAO_OMBRO],
		'Desenvolvimento de ombro': [INI_DESENVOLVIMENTO_OMBRO_BILATERAL_DIAGONAL, FIN_DESENVOLVIMENTO_OMBRO_BILATERAL_DIAGONAL],
		'Flexão plantar': [],
		Dorsiflexão: [INI_DORSIFLEXAO_LATERAL, FIN_DORSIFLEXAO_LATERAL],
		'Abdução de quadril': [
			INI_ABDUCAO_QUADRIL_FRONTAL, FIN_ABDUCAO_QUADRIL_FRONTAL,
			INI_ABDUCAO_QUADRIL_LATERAL, FIN_ABDUCAO_QUADRIL_LATERAL
		],
		'Adução de quadril': [
			INI_ADUCAO_QUADRIL_DIAGONAL, FIN_ADUCAO_QUADRIL_DIAGONAL,
			INI_ADUCAO_QUADRIL_FRONTAL, FIN_ADUCAO_QUADRIL_FRONTAL
		],
		Afundo: [
			INI_AFUNDO_LATERAL, FIN_AFUNDO_LATERAL,
			INI_AFUNDO_DIAGONAL,FIN_AFUNDO_DIAGONAL
		],
		'Flexão de joelho': [
			INI_FLEXAO_JOELHO_DV_LATERAL, FIN_FLEXAO_JOELHO_DV_LATERAL,
			INI_FLEXAO_JOELHO_DV_LATERAL_ZOOM, FIN_FLEXAO_JOELHO_DV_LATERAL_ZOOM
		],
		'Extensão de joelho': [INI_EXTENSAO_JOELHO_LATERAL, FIN_EXTENSAO_JOELHO_LATERAL],
		'Flexão de quadril': [INI_FLEXAO_QUADRIL_LATERAL, FIN_FLEXAO_QUADRIL_LATERAL],
		'Extensão de quadril': [INI_EXTENSAO_QUADRIL_LATERAL, FIN_EXTENSAO_QUADRIL_LATERAL],
		'Flexão do pescoço': [INI_FLEXAO_PESCOCO_LATERAL, FIN_FLEXAO_PESCOCO_LATERAL],
		'Extensão do pescoço': [],
		'Flexão lateral do pescoço': [INI_FLEXAO_LATERAL_PESCOCO_FRONTAL, FIN_FLEXAO_LATERAL_PESCOCO_FRONTAL],
		'Rotação do pescoço': [],
		'Pronação do antebraço': [],
		'Flexão de punho': [INI_FLEXAO_PUNHO_DIAGONAL, FIN_FLEXAO_PUNHO_DIAGONAL],
		'Desvio Ulnar do punho': [],
		'Desvio Radial do punho': [],
		'Supinação do antebraço': [],
		Plantiflexão: [],
		Inversão: [INI_INVERSAO_SUPERIOR, FIN_INVERSAO_SUPERIOR],
		Eversão: [
			INI_EVERSAO_DIAGONAL, FIN_EVERSAO_DIAGONAL,
			INI_EVERSAO_SUPERIOR, FIN_EVERSAO_SUPERIOR
		],
		'Extensão de punho': [],
		'Inversão resistida': [],
		'Eversão resistida': [],
	};

	const onLoad = (imageUrl: any) => {
		console.log(imageUrl);
		const img = new Image();
		img.src = imageUrl;
		img.onload = () => {
			setHeightImage(img.height);
			setWidthImage(img.width);
		};
	};

	return (
		<div>
			{/* librarie para abrir o modal */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_tutorial}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_tutorial}>
					{/* modal com as infos, feito html e css */}
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						{
							infoLink ?
								<div className="contentButtonOptions">
									<div 
										onClick={()=> setOptionView(TypeView.VIDEO)}
										style={{borderColor: OptionView == TypeView.VIDEO ? '#001574': '#999898'}}
										className="tutorialVideo"
									>
										<p 
											className="TextVideos"
											style={{color: OptionView == TypeView.VIDEO ? '#001574': '#999898'}}
										>
											{current_language?.tutorial_video}
										</p>
									</div>
									<div 
										onClick={()=> {setOptionView(TypeView.IMAGE), setLoading(true)}}
										style={{borderColor: OptionView == TypeView.IMAGE ? '#001574': '#999898'}}
										className="tutorialImagens"
									>
										<p 
											className="TextImagens"
											style={{color: OptionView == TypeView.IMAGE ? '#001574': '#999898'}}
										>
											{current_language?.tutorial_imagens}
										</p>
									</div>
								</div>
							: <div className="empty"/>
						}
							
						<div className="divText" >
								{OptionView == TypeView?.VIDEO  && infoLink
									?  
										<>
										<div className="bodyVideo" >
											{
												loading  &&
												<div className="activityIndicator"/>
											}
											<div className="ContainerTitleAndVideo"> 
											
												{exercicio_tutorial}
												<iframe
													className="iframe"
													onLoadCapture={(synteticEvent)=> 
														synteticEvent.target && setLoading(false) 
													}
													src={infoLink?.link} 
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												/>
											</div>
											</div>
										</>
									:
										<>
										<div className="bodyImage" >
										
											<div className="title">
												<p>
														{language === TypesLanguage.PT
															? idExercicio?.EXERCICIO_TITULO_PT
															: language === TypesLanguage.EN
																? idExercicio?.EXERCICIO_TITULO_EN
																: idExercicio?.EXERCICIO_TITULO_ES}
													</p>
												<p className="posicao">
													{position % 2 && (
															current_language?.posicionamento_inicial
														) || (
															current_language?.posicionamento_final
													)}
												</p>
											</div>
											<div className="carousel">
												<div>
													<button className="btnArrow" onClick={() => ReplacePosition('back')}>
														<svg
															width="8"
															height="14"
															viewBox="0 0 8 14"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7 1L1 7L7 13"
																stroke="#000E4B"
																strokeWidth="2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</button>
												</div>
											<div className={
												widthImage <= 1045 && "img1" ||
												widthImage > 1045 && widthImage <= 1275 && "img2" ||
												widthImage > 1275 && widthImage <= 1550 && "img3" ||
												"img4"}>
												{exercicio_tutorial && position && tutorialImages[exercicio_tutorial]?.map((image: any, index: any) => {
													if (index + 1 === position) {
														return(
															<img
																onLoad={() => onLoad(image)}
																className="imgExer"
																src={image}
															/>
														)
													}
												})}
											</div>
											<div>
												<button className="btnArrow" onClick={() => ReplacePosition('foward')}>
													<svg
														width="8"
														height="14"
														viewBox="0 0 8 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M0.999999 13L7 7L1 0.999999"
															stroke="#000E4B"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</button>
											</div>
											</div>
										</div>
									</>
								}
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalTutorial);

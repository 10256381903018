import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	margin-right: 2.6%;
	height: 100%;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	border-radius: 1.25rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	overflow: hidden;
`;

export const BodyEdite = styled.div`
	margin-top: 1rem;
	width: 100%;
	height: 100%;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.tamanhoInput {
		height: 5rem;
		/* background-color: red; */
	}

	.margem {
		margin-top: 0.5rem;
	}

	.margemNome {
		margin-top: -0.1rem;
	}

	.number {
		width: 4rem;
		display: flex;
		justify-content: flex-end;

		font-weight: 400;
	}

	.capacidade {
		color: #b3b3b3;
	}

	.left {
		display: flex;
	}

	.item {
		border: 1px solid #e0e0e0;
		border-radius: 1rem;

		padding: 2rem;

		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			margin-left: 1rem;
		}
	}

	.titulo {
		font-weight: 500;
		font-size: 1.5rem;

		color: #5c5b5b;

		margin-bottom: 2rem;
	}

	.global {
		width: 100%;
	}

	.globalInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.input {
		width: 100%;
		margin-left: 1rem;
		background-color: white;
		color: #8c8e86;
		font-weight: 500;
		border: none;
	}

	.icon {
		margin-bottom: 5px;
	}

	.divhr {
		width: 100%;
		margin-bottom: 3rem;
		margin-top: 0.5rem;
	}

	.globo {
		margin-top: -5px;
	}

	.hr {
		width: 100%;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${colors.tertiary_red_1_dark};
		color: ${colors.tertiary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${colors.tertiary_red_1_dark};
	}

	.mesAno {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.dadosCartao {
		width: 47%;
	}

	h5 {
		font-weight: 400;
		font-size: 0.75rem;
		position: absolute;
		left: 4rem;
		top: -0.2rem;
		width: 20rem;
		display: none;
	}

	.duvida {
		cursor: pointer;
		position: relative;
	}

	.duvida:hover h5 {
		display: block;
	}

	.botao {
	}
`;

export const Footer = styled.div`
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.botao {
		width: 23rem;
		height: 3.625rem;

		border: 0;
		border-radius: 0.5rem;

		background: ${colors.primary_blue_k_main};
		color: #ffffff;

		font-weight: 500;
	}

	.cancelar {
		width: 23rem;
		height: 3.625rem;

		border: 0;
		border-radius: 0.5rem;

		background: transparent;
		color: ${colors.primary_blue_k_main};

		font-weight: 500;
	}
`;

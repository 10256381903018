import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { AtribuirActions } from "../../../store/ducks/atribuir";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import Checkbox from "../../checkbox";
import { Container, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAtribuirAvaliador: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);

	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);
	const selected_aval: PacientesProps = useSelector(
		(state: any) => state.pac_aval.selected_aval
	);

	const classes = useStyles();
	const is_open_atribuir_avaliador = useSelector(
		(state: any) => state.app.is_open_atribuir_avaliador
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_atribuir_avaliador(false));
	};

	const ModalCadastroCloseSave = () => {
		atribuir();
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_atribuir_avaliador(false));
	};
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [aprovado, setAprovado] = useState(false);

	const atribuir = () => {
		setAprovado(true);
		selected_patients.map((e: PacientesProps) => {
			const request = {
				USUARIO_AVALIADOR: selected_aval?.ID_USUARIO,
				ID_USUARIO: e?.ID_USUARIO,
				USUARIO_NOME: e?.USUARIO_NOME,
			};
			dispatch(AtribuirActions.edit_atribuir(request));
		});
		dispatch(PacAvalActions.reset_patient());
		dispatch(DashBoardActions.paciente_request());
	};

	function isSelectedAval(avaliador: PacientesProps) {
		if (selected_aval?.ID_USUARIO === avaliador?.ID_USUARIO) {
			return true;
		}
		return false;
	}

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_atribuir_avaliador}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_atribuir_avaliador}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<Content>
							<div className="divText">
								<p className="text">{current_language?.tag_selecionados}:</p>
							</div>
							<div className="selecionados">
								{selected_patients?.map((pacientes: PacientesProps) => {
									return (
										<div key={pacientes?.ID_USUARIO} className="item">
											<span className="paciente">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
														fill="#001574"
													/>
												</svg>
												{pacientes?.USUARIO_NOME}
											</span>
										</div>
									);
								})}
							</div>
							<div className="divText">
								<p className="text">
									{current_language?.modal_atribuir_avaliadores}
								</p>
							</div>
							<div className="etiquetas">
								{avaliadores.map((avaliador: PacientesProps) => {
									return (
										<div key={avaliador?.ID_USUARIO} className="item">
											<span className="etiqueta">
												{avaliador?.USUARIO_NOME}
											</span>
											<Checkbox
												isAround={true}
												isChecked={isSelectedAval(avaliador)}
												avaliador={avaliador}
												classe={avaliador?.ID_USUARIO.toString()}
											/>
										</div>
									);
								})}
							</div>
						</Content>
						<button 
							className="button" 
							onClick={()=> {
								ModalCadastroCloseSave() 
								AmplitudeLog('avaliador_atribuido')
							}}
						>
							{aprovado == true ? (
								<CircularProgress color="inherit" />
							) : (
								current_language?.btn_atribuir
							)}
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalAtribuirAvaliador;

import styled from "styled-components";
import { colors } from "../../../../styles/colors";

export const AccordionStyled = styled.div`
	.accordion {
		list-style: none;
		padding: 5px;
	}
	.accordion li {
		margin-bottom: -20px;
	}

	.accordion_item {
		// border-top: 1px solid #9f9f9f;
	}

	.card {
		width: 23.063rem;
		height: 5.75rem;

		margin-top: 2rem;

		border-radius: 1rem;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);

		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.button {
		font-size: 16px;
		background-color: #fcfcfc;
		color: #5c5b5b;
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 18px 8px;
		cursor: pointer;
		border-radius: 0.5rem;
		border: none;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);
	}

	.control {
		font-size: 20px;
	}

	.options {
		background-color: #f7f7f7;
		border-radius: 0.5rem 0.5rem;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);
		color: #5c5b5b;
		padding: 15px;
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
		flex-direction: row;
	}
	.options.active {
		color: ${colors.secondary_green_k_medium_1};
		border: 1px solid ${colors.secondary_green_k_medium_1};
	}
	.item {
		display: flex;
		flex-direction: column;
		width: fit-content;
		font-size: 14px;
		margin-left: 0.5rem;
	}
	.radio {
		display: flex;
		background-color: #f7f7f7;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		border: 3px solid #e2e2e2;
	}
	.radio.active {
		background-color: ${colors.secondary_green_k_medium_1};
	}

	/* activate toggle */
	.accordion_item.active .button {
		color: #fff;
		background-color: ${colors.primary_blue_k_dark_2};
	}
	.options_wrapper {
		height: fit-content;
		overflow: scroll;
		transition: height ease 0.2s;
	}
	.disabled {
		display: none;
	}
`;


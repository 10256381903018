import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CorpoHumando from "../../../assets/corpoHumano.png";
import CorpoHumandoPeB from "../../../assets/corpoHumanoP&B.png";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import {
	AgrupamentosProps,
	MusculoProps,
} from "../../../interfaces/agrupamento.interface";
import history from "../../../routes/history";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { TypesLanguage } from "../../../utils/types";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { colors } from "../../../styles/colors";

interface Musculo {
	nome: string;
	idMuscolo: number;
	idGrupamento: number;
}

const NovoExercicio: React.FC = () => {
	const setaDown = (
		<svg
			width="12"
			height="8"
			viewBox="0 0 12 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5938 0.578125L12 1.98438L6 7.98438L0 1.98438L1.40625 0.578125L6 5.17188L10.5938 0.578125Z"
				fill="#00A377"
			/>
		</svg>
	);

	const agrupamentos = useSelector(
		(state: any) => state.dashboard.agrupamentos
	);
	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const language = useSelector((state: any) => state.configs.language);

	//Set divisão
	const [divisao, setDivisao] = useState(0);
	const handleDivisao = (val: any) => () => {
		setDivisao(val);
	};

	//Set nome do exercicio
	const [valueInput, setValueInput] = useState("false");
	const isWriting = (e: any) => {
		setValueInput(e.target.value);
	};

	//Abre e fecha os select e pega o id do grupamento
	const [open, setOpen]: any = useState(false);
	const selectGruop = (idGrupamento: any) => {
		if (open != idGrupamento) {
			setOpen(idGrupamento);
		} else {
			setOpen(false);
		}
	};

	//Seta o valor do musculo
	const musculoInicial: Musculo = {
		nome: "null",
		idGrupamento: 0,
		idMuscolo: 0,
	};
	const [option, setOption] = useState(musculoInicial);
	const SelectOption = (item: any) => {
		setOption(item);
	};

	//Verificacões de campos e submit
	const [error, setError] = useState(false);

	//Label para avisos
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data: any) => {
		const NovoExercicio = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ID_AGRUPAMENTO: option.idGrupamento,
			MUSCULO_TITULO: data.nome_exercicio,
			ID_MUSCULO: option.idMuscolo,
			EXERCICIO_TITULO: valueInput,
			EXERCICIO_TIPO: divisao,
		};

		if (
			data.nome_exercicio !== "null" &&
			data.nome_exercicio !== "" &&
			data.nome_exercicio !== null &&
			data.nome_exercicio !== undefined &&
			option.nome !== "null"
		) {
			dispatch(ManageProtocolActions.add_exer_request(NovoExercicio));
			AmplitudeLog('exercicio_adicionado',{body: NovoExercicio})
			setError(false);
			history.push("/protocolos");
		} else {
			setError(true);
		}
	};

	//UI
	return (
		<Conteiner id="DivPrincipal">
			{is_open_toast && <ToastAlert />}
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="content">
					<div className="superior">
						<div className="header">
							<p className="title">
								{current_language?.escolher_nome_exercicio}
							</p>

							<div className="divInput">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M22.284 15.432L24 13.716L22.284 12L18 16.284L7.716 6L12 1.716L10.284 0L8.568 1.716L6.852 0L4.284 2.568L2.568 0.852L0.852 2.568L2.568 4.284L0 6.852L1.716 8.568L0 10.284L1.716 12L6 7.716L16.284 18L12 22.284L13.716 24L15.432 22.284L17.148 24L19.716 21.432L21.432 23.148L23.148 21.432L21.432 19.716L24 17.148L22.284 15.432Z"
										fill="#8C8E86"
									/>
								</svg>

								<input
									type="text"
									id="nome_exercicio"
									className="input"
									placeholder={current_language?.nome_do_exercicio}
									onKeyUp={(e) => isWriting(e)}
									{...register("nome_exercicio", {
										required: current_language?.login_input_senha,
									})}
								/>
							</div>

							<hr className={errors.nome_exercicio ? "hr hrcor" : "hr"} />
							{errors.nome_exercicio && (
								<div className="error">{errors.nome_exercicio.message}</div>
							)}
						</div>
						<p className="titleMid">
							{current_language?.exercise_group_muscular}
						</p>

						<div className="divTags">
							{agrupamentos.map((item: AgrupamentosProps) => (
								<div className="divSelecao" key={item.ID_AGRUPAMENTO}>
									<button
										className="buttonGrouping"
										onClick={() => {
											selectGruop(item.ID_AGRUPAMENTO);
										}}
									>
										<p style={{ marginRight: "7px" }}>
											{item.AGRUPAMENTO_DESCRICAO_PT}
										</p>
										{option.idGrupamento === item.ID_AGRUPAMENTO && (
											<p
												style={{
													marginRight: "7px",
													fontSize: "14px",
													color: colors.primary_blue_k_dark_2,
												}}
											>
												{option.nome}
											</p>
										)}
										{setaDown}
									</button>

									{open === item.ID_AGRUPAMENTO && (
										<div className="select" onClick={() => setOpen(false)}>
											{item.AGRUPAMENTO_MUSCULOS.map(
												(musculo: MusculoProps) => (
													<p
														key={musculo.ID_MUSCULO}
														className="option"
														onClick={() => {
															SelectOption({
																nome: musculo.MUSCULO_TITULO_PT,
																idMuscolo: musculo.ID_MUSCULO,
																idGrupamento: item.ID_AGRUPAMENTO,
															});
														}}
													>
														{language === TypesLanguage.PT
															? musculo.MUSCULO_TITULO_PT
															: language === TypesLanguage.EN
															? musculo.MUSCULO_TITULO_EN
															: musculo.MUSCULO_TITULO_ES}
													</p>
												)
											)}
										</div>
									)}
								</div>
							))}
						</div>
						{error && (
							<div className="error" style={{ marginBottom: "4rem" }}>
								{current_language?.escolha_opcao}
							</div>
						)}
						<p className="titleMid">{current_language?.exercise_looks}</p>
						<div className="divButtons" style={{justifyContent: option.nome.includes('Abdomen') ? 'center' : 'space-around'}}>
							{ option.nome == agrupamentos[3]?.AGRUPAMENTO_MUSCULOS[1]?.MUSCULO_TITULO_PT  ? <div/> :
								<button
									type="button"
									className={divisao === 0 ? "btnSelected" : "btn"}
									onClick={handleDivisao(0)}
								>
									<img src={CorpoHumando} alt="" />
									<p className="titleBtn">
										{current_language?.exercise_possui_divisao}
									</p>
								</button>
							}
							<button
								type="button"
								className={divisao === 1 ? "btnSelected" : "btn"}
								onClick={handleDivisao(1)}
							>
								<img src={CorpoHumandoPeB} alt="" />
								<p className="titleBtn">
									{current_language?.exercise_nao_possui_divisao}
								</p>
							</button>
						</div>
					</div>

					<div className="inf">
						<div style={{ textDecoration: "none" }}>
							<button
								type="submit"
								id="enviar"
								value="ENVIAR"
								className="salve"
							>
								{current_language?.menu_lateral_botao_salvar}
							</button>
						</div>
						<Link to="/protocolos" style={{ textDecoration: "none" }}>
							<button 
								onClick={()=> AmplitudeLog('cancelar_add_exercicio')}
								id="cancel" 
								type="button" 
								className="cancel"
							>
								{current_language?.botao_cancelar}
							</button>
						</Link>
					</div>
				</div>
			</form>
		</Conteiner>
	);
};

export default NovoExercicio;

import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AnotacaoProps } from "../../../interfaces/anotacoes.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AnotationActions } from "../../../store/ducks/anotacoes";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorRequest } from "../../../utils/types";
import ToastAlert from "../../toast";
import { Container, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalApagarAnotacao: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const is_open_apagar_anotacao: AnotacaoProps = useSelector(
		(state: any) => state.app.is_open_apagar_anotacao
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_apagar_anotacao(false));
	};
	const [aprovado, setAprovado] = useState(false);

	const HandleHide = () => {
		setAprovado(true);
		const data = {
			ID_ANOTACAO: is_open_apagar_anotacao.ID_ANOTACAO,
		};
		AmplitudeLog('anotacao_excluida', {body: data}) 
		ModalCadastroClose();
		dispatch(AnotationActions.delete_anotation_request(data));
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		if (type_toast == TypesErrorRequest.SUCCESS) {
			ModalCadastroClose();
		}
		if (is_open_toast) {
			setAprovado(false);
			ModalCadastroClose();
		}
	}, [is_open_toast]);

	const windowWidth = window.innerWidth;

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={!!is_open_apagar_anotacao}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={!!is_open_apagar_anotacao}>
					<Container>
						{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
							<ToastAlert />
						) : (
							""
						)}

						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<Content>
							<div className="sup">
								<div className="divAvaliador">
									<svg
										style={{ marginRight: 11 }}
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
											fill="#001574"
										/>
									</svg>
									<p className="avaliador">{user_logado?.USUARIO_NOME}</p>
								</div>

								<p className="anotacao">
									{current_language?.apagar_anotacao} <br />
									{is_open_apagar_anotacao.ANOTACAO_CRIADA}
								</p>

								<textarea
									name=""
									className="textArea"
									value={is_open_apagar_anotacao.ANOTACAO_TXT ? is_open_apagar_anotacao.ANOTACAO_TXT.replace(/\\n/gi,`${'\n'}`): ''}
									id=""
									cols={30}
									rows={windowWidth < 1440 ? 5 : 10}
								></textarea>
							</div>

							<div className="inf">
								<button
									type="button"
									id="enviar"
									value="ENVIAR"
									className="salve"
									onClick={()=> HandleHide()}
								>
									APAGAR
								</button>
								<button
									id="cancel"
									type="button"
									className="cancel"
									onClick={()=> {
										ModalCadastroClose() 
										AmplitudeLog('cancelar_exclusao_anotacao')
									}}
								>
									{current_language?.voltar}
								</button>
							</div>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalApagarAnotacao;

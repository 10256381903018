import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import cartaoCredito from "../../../assets/icons/cartaoCredito.svg";
import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import mastercard from "../../../assets/icons/mastercard.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import { TextsProps } from "../../../database/txt.interface";
import {
	CartaoAlterarProps,
	FaturaProps,
} from "../../../interfaces/cartao.interface";
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from "../../../store/ducks/financial";
import { Conteiner, BodyEdite, ButtonLogoutInadimplente } from "./styles";
import { AuthActions } from "../../../store/ducks/auth";

enum TypeFatura {
	BOLETO = 'Boleto',
	PENDING = 'pending',
};

const Fatura: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	useEffect(() => {
		dispatch(FinancialActions.financial_request(
			config_servicos?.SERVICO_IUGU,
			config_servicos?.SERVICO_SUPERLOGICA
		));
	}, []);

	const faturas = useSelector((state: any) => state.financial.faturas);
	
	const faturaFiltro = faturas?.filter(
		(fatura: FaturaProps) => fatura?.FATURA_TIPO === TypeFatura?.BOLETO || fatura?.FATURA_TIPO === TypeFatura?.PENDING
	);
	const numberFaturas = faturaFiltro?.length;

	const handleConta = () => {
		dispatch(AppActions.set_open_editar_perfil(true));
	};

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("faturas"));
	}, []);

	const ver_fatura = (url: string) => {
		window.open(url, "_blank");
	};
	const is_inadimplente = useSelector(
		(state: any) => state.app.is_inadimplente
	);

	const language: any = useSelector((state: any) => state.configs.language);

	const formatDate = (date: any) => {
		if(config_servicos?.SERVICO_SUPERLOGICA) {
			const splitDate = date.split('/');
			const formatted = language == 'EN' && date || `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
			return formatted;	
		}
		if(config_servicos?.SERVICO_IUGU && !config_servicos?.SERVICO_SUPERLOGICA) {
			const splitDate = date.split('-');
			const formatted = language == 'EN' && date || `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
			return formatted;
		}
	}
	const formatPrice = (price: any) => (price / 100)?.toFixed(2).replace(/\./g, ',');

	//UI
	return (
		<Conteiner>
			<BodyEdite>
				{numberFaturas <= 0 || numberFaturas == undefined ? (
					<div className="semFatura">
						<div className="FundoRedondo">
							<svg
								width="69"
								height="95"
								viewBox="0 0 69 95"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="4" y="31" width="60" height="60" fill="white" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M58.777 32.1224H9.56834C7.30358 32.1224 5.46763 33.9584 5.46763 36.2231V85.4318C5.46763 87.6965 7.30358 89.5325 9.56834 89.5325H58.777C61.0417 89.5325 62.8777 87.6965 62.8777 85.4318V36.2231C62.8777 33.9584 61.0417 32.1224 58.777 32.1224ZM9.56834 26.6548C4.28389 26.6548 0 30.9387 0 36.2231V85.4318C0 90.7162 4.28389 95.0001 9.56834 95.0001H58.777C64.0614 95.0001 68.3453 90.7162 68.3453 85.4318V36.2231C68.3453 30.9387 64.0614 26.6548 58.777 26.6548H9.56834Z"
									fill="#5C5B5B"
								/>
								<path
									d="M18.1113 52.9677C18.1113 51.4579 19.3353 50.2339 20.8451 50.2339V50.2339C22.355 50.2339 23.579 51.4579 23.579 52.9677V59.8022C23.579 61.3121 22.355 62.536 20.8451 62.536V62.536C19.3353 62.536 18.1113 61.3121 18.1113 59.8022V52.9677Z"
									fill="#5C5B5B"
								/>
								<path
									d="M45.7915 52.9677C45.7915 51.4579 47.0155 50.2339 48.5253 50.2339V50.2339C50.0352 50.2339 51.2591 51.4579 51.2591 52.9677V59.8022C51.2591 61.3121 50.0352 62.536 48.5253 62.536V62.536C47.0155 62.536 45.7915 61.3121 45.7915 59.8022V52.9677Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M31.238 58.8657C34.105 59.9474 37.2977 59.3096 38.4744 58.8657C39.1807 58.5991 39.9693 58.9557 40.2358 59.662C40.5023 60.3683 40.1458 61.1569 39.4395 61.4235C37.8824 62.011 33.9672 62.8173 30.273 61.4235C29.5666 61.1569 29.2101 60.3683 29.4766 59.662C29.7431 58.9557 30.5317 58.5991 31.238 58.8657Z"
									fill="#5C5B5B"
								/>
								<path
									d="M29.3887 20.1618C29.3887 18.0858 31.0716 16.4028 33.1477 16.4028V16.4028C35.2237 16.4028 36.9067 18.0858 36.9067 20.1618V28.3633C36.9067 30.4393 35.2237 32.1223 33.1477 32.1223V32.1223C31.0716 32.1223 29.3887 30.4393 29.3887 28.3633V20.1618Z"
									fill="#5C5B5B"
								/>
								<path
									d="M39.6404 21.8706C40.3953 21.8706 41.0073 22.4826 41.0073 23.2375V23.2375C41.0073 23.9924 40.3953 24.6044 39.6404 24.6044L26.6548 24.6044C25.8999 24.6044 25.2879 23.9924 25.2879 23.2375V23.2375C25.2879 22.4826 25.8999 21.8706 26.6548 21.8706L39.6404 21.8706Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M32.8059 5.46763C29.7862 5.46763 27.3382 7.91557 27.3382 10.9353V12.6409C27.3382 14.5434 28.094 16.368 29.4392 17.7132L31.6634 19.9374L27.7972 23.8036L25.5731 21.5794C23.2024 19.2088 21.8706 15.9935 21.8706 12.6409V10.9353C21.8706 4.89588 26.7665 0 32.8059 0V5.46763Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M32.806 5.46763C35.8256 5.46763 38.2736 7.91557 38.2736 10.9353V12.6409C38.2736 14.5434 37.5178 16.368 36.1726 17.7132L33.9484 19.9374L37.8146 23.8036L40.0388 21.5794C42.4094 19.2088 43.7412 15.9935 43.7412 12.6409V10.9353C43.7412 4.89588 38.8453 0 32.806 0V5.46763Z"
									fill="#5C5B5B"
								/>
								<path
									d="M61.1691 83.3813V69.0288C44.3269 73.2955 26.7088 73.4754 9.7829 69.5536L7.51807 69.0288V83.3813L9.84884 83.0118C26.8598 80.315 44.1988 80.4398 61.1691 83.3813Z"
									fill="#000E4B"
								/>
							</svg>
						</div>
						<h2 className="informacao">
							{current_language?.menu_lateral_faturas_pendentes}
						</h2>
					</div>
				) : (
					faturaFiltro?.map((fatura: any) => {

						return (
							<div
								key={`${fatura?.FATURA_VALIDADE}`}
								className="lineItens"
								onClick={() => ver_fatura(fatura?.FATURA_URL)}
							>
								<div className="item">
									<div className="left">
										{current_language?.menu_lateral_fatura_pendente}
										<div className="info">R$ {formatPrice(fatura?.FATURA_VALOR)} </div>
									</div>
									<div className="botaoSelecao">
										<div className="data">
											<small>{current_language?.pagamento_vencimento}</small>
											{formatDate(fatura?.FATURA_VALIDADE)}
										</div>
										<svg
											width="8"
											height="17"
											viewBox="0 0 8 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1 16L7 8.5L1 1"
												stroke="#000E4B"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
							</div>
						);
					})
				)}
				{ !!is_inadimplente && (
						<div style={{display: 'flex', justifyContent: 'center'}}>
							<ButtonLogoutInadimplente
								onClick={()=> dispatch(AuthActions.auth_logout())}
							>
								{current_language?.sair_modal}
							</ButtonLogoutInadimplente>
						</div>
					)
				}
			</BodyEdite>
		</Conteiner>
	);
};

export default memo(Fatura);

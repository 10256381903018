import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import background from "../../assets/background_question_async.svg";
import { TextsProps } from "../../database/txt.interface";
import {
	TypeButtonsQuestionnaire,
	TypesQuestionnaire,
} from "../../utils/types";
import { Conteiner, Content, Header, Container } from "./styles";
import Alerts from "./Components/_renderAlerts";
import Questionario_progress from "../Paciente/Questionario_progress";
import FlagLang from "./Components/_renderSetLang";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";

const QuestionarioAsync: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const questionario: any = useSelector(
		(state: any) => state.pac_aval.questionario
	);
	const questionario_array = useSelector(
		(state: any) => state.pac_aval.questionario_array
	);
	const questionario_resposta_concluido: any = useSelector(
		(state: any) => state.pac_aval.questionario_resposta_concluido
	);

	const [error, setError] = useState<boolean>(false);
	const [typeAlert, setTypeAlert] = useState<string>("error"); // success | error
	const [title, setTitle] = useState<string>("");
	const [subTitle, setSubTitle] = useState<string>("");

	function useQuery() {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	}
	const queryParams = useQuery();
	const token: string | any = queryParams.get("t") || false;

	useEffect(() => {
		if (token) {
			dispatch(PacAvalActions.set_questionario_async(true));
			dispatch(PacAvalActions.set_questionario_async_token(token));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_TIPO)
			);
			dispatch(PacAvalActions.set_questionario_request(true));
		} else {
			setTitle(current_language?.link_nao_encontrado);
			setSubTitle(current_language?.question_description_async);
			setError(true);
		}
	}, [token, language]);

	useEffect(() => {
		if (questionario_array && questionario_array.status === "fail") {
			if (questionario_array.code === 1) {
				setTitle(current_language?.link_nao_encontrado);
				setSubTitle(current_language?.question_description_async);
			} else if (questionario_array.code === 2) {
				setTypeAlert("success");
				setTitle(current_language?.question_ja_respon);
				setSubTitle(current_language?.anamnese_resp_novo_link);
			}
			setError(true);
		}
		if (!questionario_array) {
			setTitle(current_language?.link_nao_encontrado);
			setSubTitle(current_language?.question_description_async);
			setError(true);
		}
		if (
			questionario_resposta_concluido &&
			questionario_resposta_concluido.CONCLUIDO === "S"
		) {
			if (
				questionario_resposta_concluido.TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_TIPO
			) {
				setTypeAlert("success");
				setTitle("Anamnese respondida !");
				setSubTitle(current_language?.anamnese_resp_sair_pag);
				setError(true);
			} else if (
				questionario_resposta_concluido.TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_SF_36
			) {
				setTypeAlert("success");
				setTitle(current_language?.qualidade_de_vida_resp);
				setSubTitle(current_language?.anamnese_resp_sair_pag);
				setError(true);
			}
		}
	}, [questionario_array, questionario_resposta_concluido, language]);

	return (
		<Conteiner background={background}>
			<Content>
				<Header>
					<svg
						className="svg"
						width="40"
						height="45"
						viewBox="0 0 19 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.109 4.15223C13.0005 3.69704 12.8321 3.57458 12.3569 3.57458C11.9532 3.57227 11.5495 3.57227 11.1435 3.57227H8.29442C7.8538 3.57227 7.41548 3.57227 6.97255 3.57458C6.74878 3.57227 6.51809 3.57458 6.29432 3.57458C5.81679 3.57458 5.653 3.69704 5.54688 4.15223C5.40385 4.74375 5.26313 5.21049 5.13394 5.80894C5.09242 5.99841 5.19853 6.3242 5.39001 6.3242C5.92752 6.32189 6.44888 6.32189 6.97025 6.32189C7.74076 6.32189 8.50204 6.3242 9.29793 6.3242H9.35099C9.96232 6.3242 10.5529 6.3242 11.1458 6.32189C11.8425 6.32189 12.5391 6.31958 13.2635 6.3242C13.4527 6.3242 13.5634 5.99841 13.5219 5.80894C13.3927 5.21049 13.2474 4.74375 13.109 4.15223Z"
							fill="#000E4B"
						></path>
						<path
							d="M18.5937 3.47285V6.36574H17.7217C17.115 6.36343 16.5844 6.36343 15.8716 6.36574C15.5002 6.36805 15.3456 6.10695 15.3018 5.91286C15.0872 4.94702 14.9673 4.40634 14.6604 3.58376C14.4597 3.03614 14.0814 2.6572 13.5093 2.48622C13.2094 2.39842 12.9556 2.34527 12.5911 2.34296C10.4549 2.3291 8.29336 2.3291 6.14562 2.34065C5.90801 2.34065 5.67271 2.35682 5.43971 2.39842C4.73379 2.52088 4.12246 2.81433 3.89638 3.54679C3.58495 4.53573 3.33119 5.54778 3.27582 6.58987C3.20661 7.92771 3.5042 9.21472 3.89177 10.4856C4.04172 10.98 4.38545 11.2943 4.88605 11.4537C5.35896 11.6062 5.84342 11.6617 6.3371 11.6617C8.45946 11.6617 10.5841 11.6617 12.7065 11.6593C13.0871 11.6593 13.4632 11.59 13.823 11.4583C14.2867 11.2873 14.5474 10.9893 14.7389 10.4856C14.8658 10.1436 14.965 9.81779 15.0434 9.43654C15.0849 9.24245 15.1657 9.1454 14.9834 9.14771C14.5705 9.15002 14.116 9.13616 13.6777 9.15927C13.4032 9.17313 13.3271 9.35336 13.3271 9.35336C12.9233 10.1829 12.9141 10.4324 12.455 10.4393H11.2577C9.45605 10.4393 8.50098 10.437 6.69467 10.4393C6.49627 10.4393 6.4063 10.444 6.06488 10.4393C5.84111 10.4347 5.72807 10.37 5.62887 10.1459C5.46739 9.47813 5.29898 9.04836 5.19056 8.36904C5.15596 8.14491 5.23208 7.85377 5.47431 7.85377C13.071 7.84684 18.2985 7.85377 18.5799 7.85377L18.5776 7.85608H18.596V10.5295C18.596 12.445 17.0527 14 15.1495 14H3.44653C1.54333 14 0 12.445 0 10.5295V3.47285C0 1.55504 1.54333 0 3.44653 0H15.1472C17.0504 0 18.5937 1.55504 18.5937 3.47285Z"
							fill="#000E4B"
						></path>
					</svg>
					<FlagLang />
				</Header>
				<Container>
					{error ? (
						<Alerts type={typeAlert} title={title} subTitle={subTitle} />
					) : questionario === TypesQuestionnaire.ANAMNESE_TIPO ? (
						<Questionario_progress
							key={TypesQuestionnaire.ANAMNESE}
							Type={TypesQuestionnaire.ANAMNESE}
						/>
					) : questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO ? (
						<Questionario_progress
							key={TypesQuestionnaire.ANAMNESE_AVALIACAO}
							Type={TypesQuestionnaire.ANAMNESE_AVALIACAO}
						/>
					) : questionario === TypesQuestionnaire.SF_36 ? (
						<Questionario_progress
							key={TypesQuestionnaire.SF_36}
							Type={TypesQuestionnaire.SF_36}
						/>
					) : (
						<></>
					)}
				</Container>
			</Content>
		</Conteiner>
	);
};

export default QuestionarioAsync;

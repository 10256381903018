import { push } from "connected-react-router";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { TextsProps } from "../../database/txt.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { DashBoardActions } from "../ducks/dashboard";
import { ManageProtocolActions, Types } from "../ducks/manage_protocols";
import * as Sentry from "@sentry/react";

function* addProtocolo({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);

	async function apiCall() {
		const data_request = {
			TOKEN: user_logado.token,
			...payload,
		};
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}protocolos/protocolos_adicionar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: protocolos_adicionar.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);

		if (data.status == TypesErrorRequest.SUCCESS) {
			if (data.code == 0) {
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: current_language?.adicao_concluida,
					})
				);
			}
			yield put(DashBoardActions.protocolos_request());
			yield put(push("/protocolos"));
		} else {
			let message: any = "";
			if (data.code == -1) {
				message = current_language?.adicao_falhou;
			}
			if (data.code == 1) {
				message = current_language?.titulo_protocolo_existe;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
			yield put(push("/protocolos"));
		}
	} catch (e) {
		console.error(e);
		yield put(ManageProtocolActions.cancel_protocolo_request());
	} finally {
		yield put(ManageProtocolActions.cancel_protocolo_request());
	}
}

function* editProtocolo({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);

	async function apiCall() {
		const data_request = {
			TOKEN: user_logado.token,
			...payload,
		};
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}protocolos/protocolos_editar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: protocolos_editar.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.SUCCESS) {
			if (data.code == 0) {
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: current_language?.edicao_completa,
					})
				);
			}
			yield put(DashBoardActions.protocolos_request());
			yield put(push("/protocolos"));
		} else {
			let message: any = "";
			if (data.code == -1) {
				message = current_language?.edicao_falhou;
			}
			if (data.code == 1) {
				message = current_language?.titulo_protocolo_existe;
			}
			if (data.code == 2) {
				message = current_language?.protocol_not_found;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		console.error(e);
		yield put(ManageProtocolActions.edit_cancel_protocolo_request());
	} finally {
		yield put(ManageProtocolActions.edit_cancel_protocolo_request());
	}
}

function* deleteProtocolo({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);



	async function apiCall() {
		try {
			const dat_request = {
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat_request),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}protocolos/protocolos_desativar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: protocolos_desativar.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == -1) {
				message = current_language?.desativacao_falhou;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			if (response.code == 0) {
				message = current_language?.desativacao_concluida;
				yield put(DashBoardActions.protocolos_request());
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
	} finally {
		yield put(ManageProtocolActions.cancel_protocolo_request());
	}
}

function* addExercise({ payload }: any): any {


	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);

	async function apiCall() {
		try {
			const dataRequest = {
				...payload,
				TOKEN: user_logado.token,
			};
			//console.log(dataRequest)
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}exercicios/exercicios_adicionar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		//console.log(data)
		if (data.status == TypesErrorRequest.SUCCESS) {
			yield put(DashBoardActions.exercices_request());
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: current_language?.exercicio_sucesso,
				})
			);
		} else {
			let message = "";
			if (data.code == -1) {
				message = current_language?.falha_exercicio;
			}
			if (data.code == 1) {
				message = current_language?.exercicio_nome_existe;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
			yield put(ManageProtocolActions.cancel_exer_request());
		}
	} catch (e: any) {
		let message = "";
		if (e.toString() == "Error: Request failed with status code 400") {
			message = current_language?.erro_processar_solicitacao;
		} else if (e.toString() == "Error: Request failed with status code 404") {
			message = current_language?.erro_servidor;
		} else if (e.toString() == "Error: Request failed with status code 401") {
			message = current_language?.nao_autorizado;
		} else {
			message = current_language?.erro_interno;
			//console.log(e);
		}
		yield put(
			AppActions.set_toast_visible({
				status_toast: true,
				type_toast: TypesErrorRequest.FAIL,
				message_toast: message,
			})
		);
		yield put(ManageProtocolActions.cancel_exer_request());
		Sentry.captureMessage("CAIU NO CATCH: exercicios_adicionar.php", "error");
	} finally {
		yield put(ManageProtocolActions.cancel_exer_request());
	}
}

function* deleteExercise({ payload }: any): any {

	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);

	async function apiCall() {
		try {
			const dataRequest = {
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}exercicios/exercicios_desativar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: exercicios_desativar.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		//console.log(data)
		if (data.status == TypesErrorRequest.SUCCESS) {
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: current_language?.desativacao_concluida,
				})
			);
			yield put(ManageProtocolActions.delete_exer_request_cancel());
		} else {
			let message = "";
			if (data.code == -1) {
				//console.log(true)
				message = current_language?.desativacao_falhou;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
			yield put(ManageProtocolActions.delete_exer_request_cancel());
		}
	} catch (e: any) {
		let message = "";
		if (e?.toString() == "Error: Request failed with status code 400") {
			message = current_language?.erro_processar_solicitacao;
		} else if (e?.toString() == "Error: Request failed with status code 404") {
			message = current_language?.erro_servidor;
		} else if (e?.toString() == "Error: Request failed with status code 401") {
			message = current_language?.nao_autorizado;
		} else {
			message = current_language?.erro_interno;
			//console.log(e);
		}
		yield put(
			AppActions.set_toast_visible({
				status_toast: true,
				type_toast: TypesErrorRequest.FAIL,
				message_toast: message,
			})
		);
		yield put(ManageProtocolActions.delete_exer_request_cancel());
	} finally {
		yield put(ManageProtocolActions.delete_exer_request_cancel());
		yield put(DashBoardActions.exercices_request());
	}
}

export function* ProtocoloSaga(): any {
	return yield all([
		takeLatest(Types.ADD_PROTOCOLO_REQUEST, addProtocolo),
		takeLatest(Types.EDIT_PROTOCOLO_REQUEST, editProtocolo),
		takeLatest(Types.DELETE_PROTO_REQUEST, deleteProtocolo),
		takeLatest(Types.DELETE_EXER_REQUEST, deleteExercise),
		takeLatest(Types.ADD_EXER_REQUEST, addExercise),
	]);
}

import React from "react";
import { useSelector } from "react-redux";

import Listagem from "../Listagem";
import ListagemAval from "../ListagemAval";
import Loading from "../Loading";
import NenhumPaciente from "../NenhumPaciente";

const Listagens: React.FC = () => {
	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const is_open_listagem = useSelector(
		(state: any) => state.app.is_open_listagem
	);

	const is_list = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	return !is_open_listagem ? (
		<ListagemAval />
	) : (
		<Listagem key={1} />
	);
};

export default Listagens;

import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { TextsProps } from "../../database/txt.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { DashBoardActions } from "../ducks/dashboard";
import { TagActions, Types } from "../ducks/tags";
import * as Sentry from "@sentry/react";

function* adicionarTags({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dataRequest = {
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}etiquetas/etiquetas_adicionar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == -1) {
				message = current_language?.adicao_falhou;
			}
			if (response.code == 1) {
				message = current_language?.etiqueta_existe;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			if (response.code == 0) {
				message = current_language?.adicao_concluida;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
			yield put(DashBoardActions.etiquetas_request());
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: etiquetas_adicionar.php", "error");
	} finally {
		yield put(TagActions.add_tag_cancel());
	}
}

function* excluirTags({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dataRequest: any = {};
			dataRequest["0"] = { TOKEN: user_logado.token };
			dataRequest["1"] = { ...payload };
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}etiquetas/etiquetas_desativar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: etiquetas_desativar.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.SUCCESS) {
			//msg de sucesso
			if (response.code == 0) {
				message = current_language?.desativacao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(DashBoardActions.etiquetas_request());
			}
		}
	} catch (e) {
	} finally {
		yield put(TagActions.set_delete_tags_canceL());
	}
}

function* editarTags({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dataRequest: any = {
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}etiquetas/etiquetas_editar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: etiquetas_editar.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.SUCCESS) {
			//msg de sucesso
			if (response.code == 0) {
				yield put(DashBoardActions.etiquetas_request());
				message = current_language?.desativacao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
	} finally {
		yield put(TagActions.set_delete_tags_canceL());
	}
}

function* atribuirTags({ payload }: any): any {
	const current_language: TextsProps = yield select(
		(state) => state.configs.current_language
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dataRequest: any = {};
			dataRequest["0"] = { TOKEN: user_logado.token };
			dataRequest["1"] = { ...payload };

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			//console.log(dataRequest, 'array')
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}etiquetas/etiquetas_atribuir.php`,
				requestOptions
			);
			const response: any = await res.json();
			//console.log(response, 'response1')
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: etiquetas_atribuir.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.SUCCESS) {
			//msg de sucesso
			if (response.code == 0) {
				yield put(DashBoardActions.etiquetas_request());
				message = current_language?.atribuicao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		}
		if (response.status == TypesErrorRequest.FAIL) {
			//msg de falha
			if (response.code == -1) {
				message = current_language?.atribuicao_falhou;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
	} finally {
		yield put(TagActions.set_atribuir_tags_cancel());
	}
}

export function* TagsSaga(): any {
	return yield all([
		takeLatest(Types.ADD_TAG_REQUEST, adicionarTags),
		takeLatest(Types.SET_DELETE_TAGS, excluirTags),
		takeLatest(Types.SET_EDITAR_TAGS, editarTags),
		takeLatest(Types.SET_ATRIBUIR_TAG, atribuirTags),
	]);
}

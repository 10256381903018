import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding: 3.6rem;
	border-radius: 1.25rem;

	@media (max-width: 1366px) {
		padding: 2rem;
	}

	.itensExcluidos {
		background-color: transparent;
		color: #8d8d8d;
		font-size: medium;
	}

	.vermelho {
		color: ${colors.tertiary_red_1_dark};
	}

	.semExcluido {
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		color: #8d8d8d;
		font-size: 20px;
		font-weight: 500;
	}

	.conteudo {
		width: 100%;
	}

	.body {
		width: 100%;
		height: 80%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.left {
		display: flex;
	}

	.mid {
		margin-left: 2.4%;
		width: 75%;
		cursor: pointer;
	}

	.usuario {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}
	.usuario2 {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}

	.usuarioAzul {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}

	.avaliador {
		font-weight: 400;
	}

	.nome {
		font-size: 1.25rem;
		margin-bottom: 1.5%;
		cursor: pointer;
	}

	.tags {
		width: 100%;
		margin-top: 2%;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.right {
		display: flex;
		width: 25%;
		flex-direction: column;
	}

	.hr {
		margin: 0.1rem 0;
		border: none;
		height: 0.063rem;
		background-color: #e5e5e5;
	}

	.PacienteEdata {
		width: 90%;
		height: 2rem;
	}

	.Divpaciente {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.paciente {
		font-size: 1rem;
		color: #5c5b5b;
		font-weight: 400;
	}

	.divOpcoes {
		width: 10%;
		height: 50%;
		display: flex;
		flex-direction: column;
		float: inline-end;
	}

	.botaoPontos {
		margin-top: 2rem;
		background-color: transparent;
		border: none;
	}

	.pontos {
		width: 1.25rem;
		height: 1.25rem;
		margin-top: 5%;
		float: right;
	}

	.user {
		width: 1rem;
		height: 1rem;
		margin-right: 0.5rem;
	}

	.dia {
		margin-top: 5%;
	}

	.btnAdicionar {
		margin-top: 4.8rem;
		display: flex;
		flex-direction: row;
		width: 18.5rem;
		height: 5.6rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);

		@media (max-height: 900px) {
			margin-top: 1.5rem;
		}
	}

	.textoBotao {
		margin-left: 1rem;
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${colors.secondary_green_k_medium_1};
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.dropdown:active .dropdown-content {
		display: block;
	}

	.img {
		width: 1.18rem;
		height: 1.18rem;
		margin-right: 1rem;
	}

	.op {
		font-size: 1.25rem;
		width: 100%;
		margin-bottom: 1.4rem;
		font-weight: 500;
		color: #5c5b5b;
		background-color: transparent;
		border: none;
	}

	.op2 {
		font-size: 1.25rem;
		width: 100%;
		color: ${colors.tertiary_red_1_dark};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}
	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.r {
		display: flex;
		justify-content: flex-end;
	}

	.divRecuperarConta {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 50%;
	}

	.recuperar {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: ${colors.primary_blue_k_medium_1};
		cursor: pointer;
	}

	.divBotao {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.btnRecuperar {
		width: 26.87rem;
		height: 3.6rem;
		background: ${colors.primary_blue_k_main};
		border-radius: 8px;
		border: none;
		color: white;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		margin-top: 2rem;

		@media (min-width: 1366px) {
			margin-top: 1rem;
		}
	}

	.divCabecalho {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.divTitle {
		display: flex;
		flex-direction: row;
		height: 2rem;
		background-color: white;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 28px;
		color: #5c5b5b;
	}

	.divNav {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 6rem;
		align-items: center;
		background-color: white;
	}

	.selecao {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #001574;
		font-weight: 500;
		background-color: transparent;
		border-bottom: 3px solid #001574;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #4d4d4d;
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.fichaExercicio {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 1.3rem;
		margin-top: 1.3rem;
		padding-right: 1rem;
	}

	.c {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;
		height: 100%;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;
	}

	.ca {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		width: 100%;
		height: 50%;
	}

	.cb {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50%;
		background-color: white;
	}

	.cc {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
	}

	.d {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		background-color: snow;
	}

	.condicao {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 1.3rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 80%;
		height: 100%;
	}

	.b {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
	}

	.p {
		color: #5c5b5b;
		margin-bottom: 0.8rem;
	}

	.opo {
		display: flex;
		flex-direction: column;
		width: 20%;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.botao {
		color: ${colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		font-weight: 500;
		margin-left: 2.1rem;
	}

	.divExercicio {
		display: flex;
		flex-direction: column;
		width: 100%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}
`;

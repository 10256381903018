import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TextsProps } from "../../../database/txt.interface";
import { ExerProtoProps } from "../../../interfaces/exercicio_protocolo";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import RenderItemGroup from "./_renderItemGroup";
import { Conteiner, Content } from "./styles";
import { AppActions } from "../../../store/ducks/app";
import Protocol from "../../../assets/icons/protocol.svg";
import Tutorial from "../../../assets/icons/tutorial.svg";
import { AmplitudeLog } from "../../../utils/amplitude";
import { exercicesWithNoTutorial } from "../../../database/exercicicesNoTutorial";

const AdicionarProtocolo: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	useEffect(() => {
		dispatch(ManageProtocolActions.set_selected_exercises_reset());
	}, []);

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);
	const selected_protocols = useSelector(
		(state: any) => state.manage_protocol.selected_protocols
	);

	const is_open_protocolo = useSelector(
		(state: any) => state.app.is_open_protocolo
	);

	const seletec_hemisferios = useSelector(
		(state: any) => state.manage_protocol.seletec_hemisferios
	);

	const berofe_add_protocol: any = useSelector(
		(state: any) => state.manage_protocol.berofe_add_protocol
	);

	const before = berofe_add_protocol?.PROTOCOLO_EXERCICIO;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const onSubmit = (data: any) => {
		const arrayExercicios: any = [];
		selected_protocols.map((e: ExerProtoProps) => {
			let tipo = "";
			const D = e.PROTOCOLO_EXERCICIO_TIPO.find(
				(i: any) => i.substr(0, 1) == "D"
			);
			const E = e.PROTOCOLO_EXERCICIO_TIPO.find(
				(i: any) => i.substr(0, 1) == "E"
			);
			const B = e.PROTOCOLO_EXERCICIO_TIPO.find(
				(i: any) => i.substr(0, 1) == "B"
			);
			D != undefined ? (tipo += "D") : (tipo += "");
			E != undefined ? (tipo += "E") : (tipo += "");
			B != undefined ? (tipo += "B") : (tipo += "");

			arrayExercicios.push({
				ID_EXERCICIO: e.ID_EXERCICIO,
				EXERCICIO_TITULO_PT: e.EXERCICIO_TITULO_PT,
				EXERCICIO_TITULO_EN: e.EXERCICIO_TITULO_EN,
				EXERCICIO_TITULO_ES: e.EXERCICIO_TITULO_ES,
				PROTOCOLO_EXERCICIO_TIPO: tipo,
			});
		});

		const dat_request = {
			PROTOCOLO_TITULO: data.nomeProtocolo,
			PROTOCOLO_EXERCICIO: arrayExercicios,
		};
		dispatch(ManageProtocolActions.set_before_add_protocol(dat_request));

		if (before != undefined && before.length != 0) {
			dispatch(push("/drag_protocolo"));
		}
	};

	useEffect(() => {
		if (is_open_protocolo?.edit) {
			setValue("nomeProtocolo", is_open_protocolo?.status?.PROTOCOLO_TITULO_PT);
		}
	}, [is_open_protocolo.status]);

	const tutorial = (e: any) => {
		dispatch(AppActions.set_exercicio_tutorial(e));
		dispatch(AppActions.set_modal_tutorial(true));
	};

	const exerciciosCurrent = [];

	const exerciciosCriados = exercicios.filter((e: any) => e.ID_USUARIO != 0);
	exerciciosCurrent.push(...exerciciosCriados.reverse());

	const exerciciosElastic = exercicios.filter((e: any) => e.ID_USUARIO == 0);
	exerciciosCurrent.push(...exerciciosElastic);

	return (
		<Conteiner onSubmit={handleSubmit(onSubmit)}>
			<div className="cabecalho">
				<p className="title">
					{is_open_protocolo?.edit
						? current_language?.info_edit_protocolo
						: current_language?.info_novo_protocolo}
				</p>
			</div>
			<div className="divInput">
				<img src={Protocol} alt="protocol icon" />
				<input
					type="text"
					id="nomeProtocolo"
					className="input"
					placeholder={current_language?.criacao_proto}
					{...register("nomeProtocolo", {
						required: `${current_language?.login_input_senha}`,
					})}
				/>
			</div>
			<div className="divhr">
				<hr className={errors.nomeProtocolo ? "hrErro hrcor" : "hrErro"} />
				{errors.nomeProtocolo && (
					<div className="error">{errors.nomeProtocolo.message}</div>
				)}
			</div>

			<Content>
				<div>
					{exerciciosCurrent.map((row: any, index) => {
						return (
							<div className="ficha" key={index}>
								<RenderItemGroup key={index} row={row} index={index} />
								{row.ID_USUARIO === 0 && !exercicesWithNoTutorial.includes(row.EXERCICIO_TITULO_PT) && (
									<button
										type="button"
										className="right botao"
										onClick={() => tutorial(row.EXERCICIO_TITULO_PT)}
									>
										<p className="tutorial">{current_language?.tutorial}</p>
										<img src={Tutorial} alt="" />
									</button>
								)}
							</div>
						);
					})}
				</div>
			</Content>
			<button
				onClick={()=> AmplitudeLog(is_open_protocolo?.edit ? 'avancar_editar_protocolo' : 'adicionado_info_protocolo')}
				id="salvar"
				type={seletec_hemisferios.length >= 1 ? "submit" : "button"}
				className={seletec_hemisferios.length >= 1 ? "salve" : "salveDisabled"}
			>
				{current_language?.menu_lateral_botao_avancar}
			</button>
			<Link to="/protocolos" style={{ textDecoration: "none" }}>
				<button
					id="cancel"
					type="button"
					className="cancel"
					onClick={() =>{
						dispatch(ManageProtocolActions.set_selected_exercises_reset())
						AmplitudeLog(is_open_protocolo?.edit ? 'cancelar_editar_protocolo' : 'cancelar_add_protocolo')
					}}
				>
					{current_language?.botao_cancelar}
				</button>
			</Link>
		</Conteiner>
	);
};

export default AdicionarProtocolo;

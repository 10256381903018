import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Menu from "../../components/Menu";
import ToastAlert from "../../components/toast";
import { AppActions } from "../../store/ducks/app";
import { FinancialActions } from "../../store/ducks/financial";
import BarraSuperiorControladora from "./BarrasSuperiores/BarraSuperiorControladora";
import Listagens from "./Listagens";
import { Container, Content, Body } from "./styles";
import { handleInstanceAmplitude } from "../../utils/amplitude";
import { UserProps } from "../../interfaces/pacientes.interface";
import * as Sentry from "@sentry/react";
import { loadStorage } from "../../utils/base_async_storage";
import moment from "moment";

const Dashboard: React.FC = () => {
	const dispatch = useDispatch();

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const cartaoFaltando = useSelector((state: any)=>state.financial.cartao_faltando)
	const firstLoad = useSelector((state:any)=> state.app.isFirstLoad)
	const is_list = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);
	const propaganda = false;
	const Group = styled.div`
		display: flex;
		width: 100%;
		${propaganda ? "height: 82%;" : "height: 100%;"}
	`;

	const ListagemEcards = styled.div`
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 90vh;
		padding-left: 4rem;
		padding-right: 4rem;
		padding-top: 4rem;
		padding-bottom: 4rem;
		background-color: #f5fbfc;

		@media only screen and (min-width: 1280px) {
			padding: 2rem;
			padding-right: 0;
		}

		@media only screen and (max-width: 1300px) {
			padding: 2rem;
			padding-right: 0;
		}

		@media only screen and (min-width: 1440px) {
			padding-left: 3rem;
			padding-right: 0;
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

		@media only screen and (max-width: 1280px) {
			padding: 1rem;
			padding-right: 0;
		}
	`;

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const is_open_inadimplente = useSelector(
		(state: any) => state.app.is_open_modal_inadimplente
	);
	const is_open_modal_add_patient = useSelector(
		(state: any) => state.app.is_open_adicionar_paciente
	);
	const history = useHistory();
	useEffect(() => {
		if (is_open_inadimplente == "faturas") {
			history.push("/faturas");
			dispatch(AppActions.set_modal_inadimplente(false, true));
		}
	}, [is_open_inadimplente]);

	useEffect(() => {
		if (
			(!process.env.NODE_ENV || process.env.NODE_ENV !== "development") &&
			window.location.hostname === "web.elastic.fit"
		)
			Sentry.setUser({ email: user_logado?.USUARIO_EMAIL });
		handleInstanceAmplitude(
			user_logado?.ID_USUARIO,
			config_servicos?.SERVICO_PLANO_NOME
		);
	}, [user_logado?.ID_USUARIO]);
	
	useEffect(()=>{
		async function handleModalCadastroCartao () {
			const doNotShowAgain: boolean | null =  await loadStorage('set_hide_modal_credit_card');
			const AsyncToday: string | null =  await loadStorage('set_modal_card_worning_today')
			const Now = moment(new Date()).format('YYYY-MM-DD').toString()
			const timesTempCloseModal =  AsyncToday ? new Date(AsyncToday).getTime() : new Date(Now).getTime()
			const  timesTempNow = new Date(Now).getTime();
			const verifiyPlan = ['Básico 2024', 'Free 2024'].indexOf(config_servicos?.SERVICO_PLANO_NOME) > -1;

			if(firstLoad){
				setTimeout(()=>{
					dispatch(AppActions.set_is_first_load(false))
				},3000)
			}

			if(!!cartaoFaltando 
				&& !is_list 
				&& !firstLoad 
				&& !!config_servicos?.SERVICO_SUPERLOGICA  
				&& !verifiyPlan
				&& !doNotShowAgain
			){
				if(!AsyncToday){
					dispatch(AppActions.set_modal_aviso_cadastro_cartao(true))
				}
				if(timesTempNow > timesTempCloseModal){
					dispatch(AppActions.set_modal_aviso_cadastro_cartao(true))
				}
			}
		};
		handleModalCadastroCartao()
	},[cartaoFaltando, is_list, firstLoad, config_servicos])

	return (
		<Body>
			{is_open_toast && !is_open_modal_add_patient ? <ToastAlert /> : ""}
			<Menu setMenu="home"></Menu>
			<Container>
				<BarraSuperiorControladora />
				<ListagemEcards>
					<Group>
						<Content>
							<Listagens />
						</Content>
					</Group>
				</ListagemEcards>
			</Container>
		</Body>
	);
};

export default memo(Dashboard);

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TextsProps } from "../../../database/txt.interface";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { Conteiner, Header, BodyEdite } from "./styles";
import Evaluator from "../../../assets/icons/evaluator.svg";
import exit_to_app from "../../../assets/icons/exit_to_app.svg";
import person from "../../../assets/icons/person.svg";
import { ServicosProps } from "../../../interfaces/config.interface";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { AmplitudeLog } from "../../../utils/amplitude";
import { AuthActions } from "../../../store/ducks/auth";

const MinhaConta: React.FC = () => {
	const dispatch = useDispatch();

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const dados = config_servicos;

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("minhaConta"));
	}, []);

	const pacientes = useSelector((state: any) => state.dashboard.total_pacientes);
	const avaliadores = useSelector((state: any) => state.dashboard.total_avaliadores);

	const customizeReportPermission = () => {
		const accessCustomizeReport: any = getPermission(
			config_servicos as ServicosProps[],
			TypesPermission.SERVICO_LOGOMARCA,
			dispatch
		);
		if (accessCustomizeReport) {
			history.push("/personalizar_relatorio");
		} else
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
	};

	function permissionExportCSV() {
		const exportCSv: any = getPermission(
			config_servicos as ServicosProps[],
			TypesPermission.SERVICO_CSV
		);
		if (exportCSv) {
			dispatch(AppActions.set_modal_migracao(true, null));
			dispatch(AppActions.set_title_export_data(true));
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		}
	}


	return (
		<Conteiner>
			<Header>
				<div className="bodyHeader">
					<p className="nome">Kinology Brasil</p>
					<div>
						<div className="footerBody">
							<h5 className="textHeader text">
								{current_language?.pag_inicial_pacientes}
							</h5>
							<h5 className="textHeader number">{`${pacientes}/${
								dados.SERVICO_PACIENTES >= 999999999
									? current_language?.ilimitado
									: dados.SERVICO_PACIENTES
							}`}</h5>
						</div>
						<div className="hr"></div>
						<div className="footerBody">
							<h5 className="textHeader text">
								{current_language?.pag_inicial_avaliador}
							</h5>
							<h5 className="textHeader number">{`${avaliadores}/${
								dados.SERVICO_AVALIADORES >= 999999999
									? current_language?.ilimitado
									: dados.SERVICO_AVALIADORES
							}`}</h5>
						</div>
					</div>
				</div>
			</Header>
			<BodyEdite>
				<div className="lineItens">
					<Link
						to="/perfil"
						className="item"
						onClick={() => {
							dispatch(AppActions.set_open_editar_perfil(true));
							AmplitudeLog("editar_perfil");
						}}
					>
						<div className="left">
						<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.52734 15.4487C6.98568 14.4487 9.33984 13.9487 11.5898 13.9487C13.8398 13.9487 16.1732 14.4487 18.5898 15.4487C21.0482 16.4071 22.2773 17.6779 22.2773 19.2612V21.9487H0.902344V19.2612C0.902344 17.6779 2.11068 16.4071 4.52734 15.4487ZM15.3398 9.69873C14.2982 10.7404 13.0482 11.2612 11.5898 11.2612C10.1315 11.2612 8.88151 10.7404 7.83984 9.69873C6.79818 8.65706 6.27734 7.40706 6.27734 5.94873C6.27734 4.4904 6.79818 3.2404 7.83984 2.19873C8.88151 1.1154 10.1315 0.57373 11.5898 0.57373C13.0482 0.57373 14.2982 1.1154 15.3398 2.19873C16.3815 3.2404 16.9023 4.4904 16.9023 5.94873C16.9023 7.40706 16.3815 8.65706 15.3398 9.69873Z" fill="#001574"/>
						</svg>
							<p>Editar perfil</p>
						</div>
						<svg
							width="8"
							height="17"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</Link>
					<Link
						to="/meu_plano"
						className="item"
						onClick={() => AmplitudeLog("plano_usuario")}
					>
						<div className="left">
							<svg
								width="26"
								height="17"
								viewBox="0 0 26 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.8571 4.85714H0V7.28571H14.8571V4.85714ZM14.8571 0H0V2.42857H14.8571V0ZM0 12.1429H9.90476V9.71429H0V12.1429ZM24.1429 6.67857L26 8.5L17.3457 17L11.7619 11.5357L13.619 9.71429L17.3457 13.3571L24.1429 6.67857Z"
									fill="#001574"
								/>
							</svg>

							<p>{current_language?.menu_lateral_meu_plano}</p>
						</div>
						<svg
							width="8"
							height="17"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</Link>

					<Link
						to="/evaluator"
						onClick={() => {
							 AmplitudeLog("avaliadores");
						}}
						className="item"
					>
						<div className="left">
							<img src={Evaluator} style={{ marginLeft: 10 }} />
							<p>{current_language?.menu_lateral_avaliadores}</p>
						</div>
						<svg
							width="8"
							height="17"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</Link>

					{dados.SERVICO_PLANO_NOME !== "Free" &&
						dados.SERVICO_PLANO_NOME !== "Básico" && (
							<Link to="/pagamentos" className="item">
								<div className="left">
									<svg
										width="23"
										height="18"
										viewBox="0 0 23 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M20.7 0H2.3C1.0235 0 0.0115 1.00125 0.0115 2.25L0 15.75C0 16.9988 1.0235 18 2.3 18H20.7C21.9765 18 23 16.9988 23 15.75V2.25C23 1.00125 21.9765 0 20.7 0ZM20.7 15.75H2.3V9H20.7V15.75ZM20.7 4.5H2.3V2.25H20.7V4.5Z"
											fill="#001574"
										/>
									</svg>

									<p>{current_language?.menu_lateral_pagamento}</p>
								</div>
								<svg
									width="8"
									height="17"
									viewBox="0 0 8 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 16L7 8.5L1 1"
										stroke="#000E4B"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Link>
						)}

					<div
						className="item"
						onClick={() => {
							customizeReportPermission(),
								AmplitudeLog("personalizar_relatorio");
						}}
					>
						<div className="left">
							<svg
								width="22"
								height="22"
								viewBox="0 0 22 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19.5556 0H2.44444C1.1 0 0 1.1 0 2.44444V19.5556C0 20.9 1.1 22 2.44444 22H19.5556C20.9 22 22 20.9 22 19.5556V2.44444C22 1.1 20.9 0 19.5556 0ZM7.33333 17.1111H4.88889V8.55556H7.33333V17.1111ZM12.2222 17.1111H9.77778V4.88889H12.2222V17.1111ZM17.1111 17.1111H14.6667V12.2222H17.1111V17.1111Z"
									fill="#001574"
								/>
							</svg>
							<p>{current_language?.menu_lateral_personalizar_relatorio}</p>
						</div>
						<svg
							width="8"
							height="17"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>

					<div
						className="item"
						onClick={() => {
							dispatch(AuthActions.auth_logout());
							AmplitudeLog("sair_conta");
						}}
					>
						<div className="left" style={{display: "flex", flexDirection: "row", alignItems: "center" }}>		
							<img
								style={{ marginLeft: "12px", width: "28px", height: "28px" }}
								src={exit_to_app}
								alt=""
							/>
							<p>Sair da conta</p>
						</div>
						<svg
							width="8"
							height="17"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>

					<div
						className="item2"
						onClick={() => {
							permissionExportCSV(), AmplitudeLog("exportar_dados");
						}}
					>
						<div className="left">
							<svg
								width="22"
								height="22"
								viewBox="0 0 22 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M22 22L22 19.25L-1.66694e-06 19.25L-1.90735e-06 22L22 22ZM22 8.25L15.7143 8.25L15.7143 1.35783e-06L6.28572 5.33562e-07L6.28571 8.25L-7.05286e-07 8.25L11 17.875L22 8.25Z"
									fill="#001574"
								/>
							</svg>

							<p>{current_language?.menu_lateral_exportar}</p>
						</div>
						<svg
							width="8"
							height="17"
							viewBox="0 0 8 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				</div>
			</BodyEdite>
		</Conteiner>
	);
};

export default MinhaConta;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Perfil from "../../../assets/icons/perfilCompleto.svg";
import { TextsProps } from "../../../database/txt.interface";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";
import { AppActions } from "../../../store/ducks/app";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { TypesAvalOrFunci, TypesLanguage, TypesProLivre } from "../../../utils/types";
import { BoxSearch, Content, LoadingDiv } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import LupaFilterProtocol from "../../../assets/icons/lupaFilterProtocol.svg";
import FilterList from "../../../assets/icons/filterList.svg";
import logoKinology from "../../../assets/kinologyIcons/svg/logo.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import NoveltyIndicatorBall from "../../../components/ComponentNoveltyIndicatorBall";
import Loading from "../../Dashboard/Loading";
import { PropsCategoriasProtocolos } from '../../../interfaces/categoriasProtocolos.interface';
import { push } from "connected-react-router";


const Protocolos: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	
	const protocolos: ProtocolosProps[] = useSelector(
		(state: any) => state.manage_protocol.protocolos
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);
	const config_funcional_habilitado = useSelector(
		(state: any) => state.configs.config_funcional,
	);

	const atualizacao_proto: any = [];
	const { categorias_protocolo } = useSelector((state:any)=> state.dashboard.categorias_protocolos)
	const [inputValue, setInputValue] = useState("");

	useEffect(()=>{
		dispatch(DashBoardActions.search_categorias_atribuidas(paciente?.ID_USUARIO))
	},[paciente?.ID_USUARIO, ])

	const categorias_atribuidas: number[] = useSelector((state:any)=> state.dashboard.categorias_atribuidas);

	execucoes.map((e: any) => {
		atualizacao_proto.push({
			ID_PROTOCOLO: e.ID_PROTOCOLO,
			EXECUCAO_ATUALIZADA: e.EXECUCAO_ATUALIZADA,
			EXECUCAO_EXECUTADA: e.EXECUCAO_EXECUTADA,
		});
	});

	const data_protocolo = (number: any) => {
		const data: any = atualizacao_proto?.find(
			(e: any) => e?.ID_PROTOCOLO == number
		);
		return data?.EXECUCAO_ATUALIZADA;
	};

	const nextPage = () => {
		dispatch(AppActions.set_selected_type_pro_livre(TypesProLivre.PROTOCOLO));
		dispatch(AppActions.set_modal_select_avaliacao(true));
	};

	const handleProtocolExecutionEvaluationMode = ()=> {
			dispatch(AppActions.set_selected_avaliacao(TypesAvalOrFunci.AVALIACAO)); 
			dispatch(AppActions.set_selected_type_pro_livre(TypesProLivre.PROTOCOLO));
			dispatch(AppActions.set_checklist_execucao(true));
			dispatch(push("/protocolo"));
			dispatch(AppActions.set_modal_select_avaliacao(false));
			dispatch(ManageProtocolActions.set_remove_play(false))
			dispatch(ManageProtocolActions.set_button_goback(true));
	};

	const handleProtocoloList = (protocolo: ProtocolosProps) => {
		dispatch(ExerciciosActions.set_id_proto(protocolo.ID_PROTOCOLO));
		if(!!config_funcional_habilitado){
			nextPage();
		}else{
			handleProtocolExecutionEvaluationMode()
		}
		dispatch(AppActions.set_protocolo_list(protocolo));
		dispatch(ManageProtocolActions.set_disable_modal_conexao_bluetooth(false));
		AmplitudeLog("escolha_protocolo",{
			body: protocolo
		});
	};

	const resetInput = () => {
		setInputValue("");
	};

	const [protocolos_pesquisa, setProtocolosPesquisa] = useState<
	ProtocolosProps[]
	>([]);
	const [listerFilteredProtocols, setListerFilteredProtocols] = useState<ProtocolosProps[]>([]);
	const [categories, setCategories] = useState<PropsCategoriasProtocolos[]>([]);

	useEffect(()=>{
		const protocolosFiltrados = protocolos.filter(
			(objeto:any) => categorias_atribuidas.includes(objeto?.ID_CATEGORIA_PROTOCOLO) 
		);
		setListerFilteredProtocols(protocolosFiltrados)
		setCategories(categorias_protocolo || [])
	},[protocolos, categorias_atribuidas, categorias_protocolo])

	const handleInputChange = (event: any) => {
		setInputValue(event.target.value);
		if (inputValue != "") {
			const searchOptionsProtocols = [
				listerFilteredProtocols,
				protocolos
			];
			
			const matchedProtocolos = config_servicos?.SERVICO_NOVOS_PROTOCOLOS && searchOptionsProtocols[config_servicos?.SERVICO_NOVOS_PROTOCOLOS ? 0 : 1].filter(
				(protocolo: ProtocolosProps) => {
				return (
					protocolo.PROTOCOLO_TITULO_PT.toLowerCase().indexOf(
						event.target.value.toLowerCase()
					) !== -1
				);
			})
				
			setProtocolosPesquisa(matchedProtocolos);
		}
	};

	const handleCategoriaTitulo = (protocolo:any)=> {
		const categoria = categories?.filter((item: any)=> item?.ID_CATEGORIA_PROTOCOLO == protocolo?.ID_CATEGORIA_PROTOCOLO)
		return language == TypesLanguage.PT 
			? categoria[0]?.CATEGORIA_TITULO_PT
			: language == TypesLanguage.EN
				?  categoria[0]?.CATEGORIA_TITULO_EN
				:  categoria[0]?.CATEGORIA_TITULO_ES
	};

	const _RenderProtocolList = (protocol_list: Array<ProtocolosProps>) => {
		return protocol_list.map((row) => (
			<div className="protocolo" key={row.ID_PROTOCOLO}>
				<div className="protocoloInfo">
					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>	
						<img  
							src={row.ID_USUARIO === 0 
								? logoKinology
								: Perfil
						}/>
						<p className="p">
							{language === TypesLanguage.PT
								? row.PROTOCOLO_TITULO_PT
								: language === TypesLanguage.EN
								? row.PROTOCOLO_TITULO_EN
								: row.PROTOCOLO_TITULO_ES}
						</p>
					</div>
						
					<div style={{flexDirection: 'row', display: 'flex'}}>

						<div className="divProtoSugerido">
								{row?.ID_USUARIO === 0 ? (
										<p className="pa" >{handleCategoriaTitulo(row)}</p>
								) : (
										<p className="pa">{current_language?.Criado_por_voce}</p>
								)}
						</div>
						{  row?.ID_USUARIO === 0 &&
							<p className="pipe">|</p>

						}
						{  row?.ID_USUARIO === 0 &&
							<div className="tempoEstimado">
								<p className="Time">{current_language?.tempo_estimado}: {row?.PROTOCOLO_TEMPO_ESTIMADO}</p>
							</div>
						}
					</div>
				</div>
				<div className="d">
					<div 
						className="botao"
						onClick={() => handleProtocoloList(row)}
					>
						<svg
							className="play"
							width="8"
							height="17"
							viewBox="0 0 8 17"
							style={{marginTop:0, paddingBottom:0}}
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 16L7 8.5L1 1"
								stroke="#000E4B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				</div>
			</div>
		));
	};

	return (
		<>
			{!protocolos.length && !categories.length && (
				<LoadingDiv>
					<Loading />
				</LoadingDiv>
			) || (
				<>
					<BoxSearch>
						<div className="input">
						    <button type="button" className={"reset"}>
								<img className="lupa" src={LupaFilterProtocol} alt="" />
							</button>
							<input
								value={inputValue}
								onChange={handleInputChange}
								className="inputPesquisa"
								type="text"
								placeholder="Pesquisar protocolos"
							/>
						</div>
						{ config_servicos?.SERVICO_NOVOS_PROTOCOLOS &&
							<div 
								className="ContentIconFilterList"
							>
								<div className="ContainerIcons">	
									
									<img 
										onClick={()=> dispatch(ManageProtocolActions.set_modal_filter_protocol(true))}
										style={{cursor: 'pointer'}} 
										className="lupa" 
										width={34} 
										height={34} 
										src={FilterList} alt=""  
									/>
									<NoveltyIndicatorBall 
										startDate={'17-11-2023'}
										expirationDate={'30-11-2023'}
									/>
									
								</div>
							</div>
						}
					</BoxSearch>
					<Content>
					{!protocolos.length && !categories.length && <Loading /> || (
						_RenderProtocolList(
							config_servicos?.SERVICO_NOVOS_PROTOCOLOS
								? inputValue == ""
									? listerFilteredProtocols
									: protocolos_pesquisa
								:  inputValue == ""
									? protocolos
									: protocolos_pesquisa								
						))}
					</Content>
				</>
			)}
		</>
	);
};

export default Protocolos;

import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import Etiqueta from "../../../assets/icons/etiqueta.svg";
import Limpar from "../../../assets/icons/limpar.svg";
import { TextsProps } from "../../../database/txt.interface";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TagActions } from "../../../store/ducks/tags";
import { Container, Content } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

interface TagsProps {
	ID_ETIQUETA: number;
	ETIQUETA_NOME: string;
	ID_USUARIOS: [];
}

const ModalAtribuirEtiqueta: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const etiquetas = useSelector((state: any) => state.dashboard.etiquetas);
	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);

	const classes = useStyles();
	const is_open_atribuir_etiqueta = useSelector(
		(state: any) => state.app.is_open_atribuir_etiqueta
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_atribuir_etiqueta(false));
		setAprovado(false);
		setIdentificador([]);
	};

	const [identificador, setIdentificador] = useState<number[]>([]);

	const handleActiveTag = (id: number) => () => {
		let newIdentificador: any = [];
		if (identificador?.includes(id)) {
			const filter = identificador.filter((e: any) => e !== id);
			newIdentificador = filter;
		} else {
			newIdentificador = [...identificador, id];
		}
		setIdentificador(newIdentificador);
	};

	const isPresent = (id: number) => {
		const present = identificador.find((e: any) => e === id);
		if (present) {
			return true;
		}
		return false;
	};

	const [value, setvalue] = useState<any>();
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const isWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalue(e.target.value);
	};
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [aprovado, setAprovado] = useState(false);
	const [adicionar, setAdicionar] = useState(false);

	const atribuir = () => {
		setAprovado(true);
		identificador?.map((tag: any) => {
			selected_patients?.map((e: PacientesProps) => {
				const request = {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_ETIQUETA: tag,
					ID_USUARIO: e?.ID_USUARIO,
				};
				dispatch(TagActions.set_atribuir_tags(request));
			});
		});
		ModalCadastroClose();
	};

	const AdicionarTag = () => {
		setAdicionar(true);
		const request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ETIQUETA_NOME: value,
		};
		dispatch(TagActions.add_tag_request(request));
	};

	const resetInput = () => {
		setvalue("");
		setIsSearch(false);
	};

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
			setAdicionar(false);
			// dispatch(DashBoardActions.paciente_request());
		}
	}, [is_open_toast]);

	const [palavra, setPalavra] = useState<any>();

	useEffect(() => {
		setPalavra(
			etiquetas?.filter((tag: TagsProps) =>
				tag?.ETIQUETA_NOME?.toLowerCase()?.includes(value?.toLowerCase())
			)
		);
	}, [value]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_atribuir_etiqueta}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_atribuir_etiqueta}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<Content>
							<form action="">
								<div className="mid">
									<div className="input">
										<button type="button" className="reset">
											<img className="lupa" src={Etiqueta} alt="" />
										</button>
										<input
											className="inputPesquisa"
											type="text"
											placeholder={current_language?.modal_atribuir_etiquetas2}
											onKeyUp={(e) => isWriting(e)}
										/>
										<button type="reset" className="reset" onClick={resetInput}>
											<img className="x" src={Limpar} alt="" />
										</button>
									</div>
								</div>
							</form>

							{!isSearch && (
								<div className="body">
									<div className="divText">
										<p className="text">
											{current_language?.tag_selecionados}:
										</p>
									</div>
									<div className="selecionados">
										{selected_patients?.map((paciente: PacientesProps) => {
											return (
												<span className="paciente" key={paciente?.ID_USUARIO}>
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
															fill="#001574"
														/>
													</svg>
													<p>{paciente?.USUARIO_NOME}</p>
												</span>
											);
										})}
									</div>
									<div className="divText">
										<p className="text">
											{current_language?.modal_atribuir_etiquetas}:
										</p>
									</div>
									<div className="divTags">
										{etiquetas?.map((row: TagsProps) => (
											<span
												key={row.ID_ETIQUETA}
												className={
													isPresent(row.ID_ETIQUETA) ? "tagVermelha" : "tag"
												}
												id={row.ID_ETIQUETA.toString()}
												onClick={handleActiveTag(row.ID_ETIQUETA)}
											>
												{row.ETIQUETA_NOME}
											</span>
										))}
									</div>
									<div className="divButton">
										<button className="button" onClick={atribuir}>
											{aprovado == true ? (
												<CircularProgress color="inherit" />
											) : (
												current_language?.btn_atribuir
											)}
										</button>
									</div>
								</div>
							)}

							{isSearch && (
								<div className="divPesquisarTag">
									<div>
										{palavra?.map((row: TagsProps) => (
											<span
												key={row.ID_ETIQUETA}
												className={
													isPresent(row.ID_ETIQUETA) ? "tagVermelha" : "tag"
												}
												id={row.ID_ETIQUETA.toString()}
												onClick={handleActiveTag(row.ID_ETIQUETA)}
											>
												{row.ETIQUETA_NOME}
											</span>
										))}
									</div>
									<div className="divAdicionarEtiqueta" onClick={AdicionarTag}>
										{adicionar == true ? (
											<CircularProgress
												color="inherit"
												style={{ color: "#000E4B" }}
											/>
										) : (
											<svg
												width="30"
												height="30"
												viewBox="0 0 27 27"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M13.5002 24.3C19.4649 24.3 24.3002 19.4646 24.3002 13.5C24.3002 7.53528 19.4649 2.69995 13.5002 2.69995C7.53552 2.69995 2.7002 7.53528 2.7002 13.5C2.7002 19.4646 7.53552 24.3 13.5002 24.3Z"
													stroke="#000E4B"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M13.5 9.17993V17.8199"
													stroke="#000E4B"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M9.18066 13.4998H17.8207"
													stroke="#000E4B"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										)}
										<p className="p">
											{current_language?.tags_criar_nova_etiqueta}
										</p>
										<p className="value">"{value}"</p>
									</div>
								</div>
							)}
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalAtribuirEtiqueta);

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import ListagemProtocolos from "..";

import { checkLanguage } from "../../../../utils/texts";
import BarraSuperiorControladora from "../../../Dashboard/BarrasSuperiores/BarraSuperiorControladora";

const ExercicioController: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	useEffect(() => {
		checkLanguage(language, dispatch);
	}, [language]);
	return (
		<ViewPort>
			<BarraSuperiorControladora />
			<ListagemProtocolos />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	color: rgba(140, 142, 134, 0, 8);

	display: flex;
	flex-direction: column;

	overflow: hidden;

	background-color: red;
`;

export default ExercicioController;

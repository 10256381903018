import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../database/txt.interface";
import { RelatorioActions } from "../../store/ducks/relatorios";
import { Container } from "./styles";
import { AmplitudeLog } from "../../utils/amplitude";

const AdicionarLogo: React.FC = () => {
	const dispatch = useDispatch();

	const [img, setImg] = useState<any>();
	const [nomeImg, setNomeImg] = useState<any>();

	function getBase64(event: any) {
		const file = event.target.files[0];
		if (file !== undefined) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				setImg(reader.result);
				setNomeImg(event.target.files[0].name);
				dispatch(RelatorioActions.set_imagem_relatorio(reader.result));
			};
			reader.onerror = function (error) {
				console.error("Error: ", error);
			};
		}
	}

	const removePhoto = () => {
		setImg(undefined);
		dispatch(RelatorioActions.set_imagem_relatorio(""));
	};

	const image_relatorio = useSelector(
		(state: any) => state.relatorios.image_relatorio
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	if (img == undefined) {
		setImg("");
	}

	useEffect(() => {
		setImg(image_relatorio);
	}, [image_relatorio]);

	useEffect(() => {
		if (img === undefined) {
			setImg(undefined);
			dispatch(RelatorioActions.set_imagem_relatorio(""));
		}
	}, [img]);

	return (
		<Container className="DivInput">
			<div className="conteinerImg">
				<div>
					<div className="Adicionar">
						{img !== undefined ? (
							<p className="addLogo">
								{nomeImg?.length > 30
									? `${nomeImg.substring(0, 25)}...`
									: nomeImg}
							</p>
						) : (
							<p className="addLogo">Adicionar sua logo</p>
						)}
						{img !== undefined ? (
							<label 
								onClick={()=> AmplitudeLog('logo_relatorio')}
								htmlFor="img" 
								className="p"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									height="24px"
									viewBox="0 0 24 24"
									width="24px"
									fill="#0023BD"
								>
									<path d="M0 0h24v24H0V0z" fill="none" />
									<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z" />
								</svg>
								<input
									id="img"
									type="file"
									onChange={getBase64}
									style={{ display: "none" }}
									accept="image/*"
								/>
								{current_language?.alterar_arquivo}
							</label>
						) : (
							<label htmlFor="img" className="p">
								<svg
									width="14"
									height="17"
									viewBox="0 0 14 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0 17H14V15H0V17ZM0 7H4V13H10V7H14L7 0L0 7Z"
										fill="#000E4B"
									/>
								</svg>
								<input
									id="img"
									type="file"
									onChange={getBase64}
									style={{ display: "none" }}
									accept="image/*"
								/>
								Enviar arquivo
							</label>
						)}
					</div>
					{img !== undefined && (
						<div className="divFoto">
							<div className="xisFoto" onClick={removePhoto}>
								<svg
									width="16"
									height="16"
									viewBox="0 0 8 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.00009 0L3.99965 3L0.999911 0L0 1L2.99973 4L0 7L0.999911 8L3.99965 5L7.00009 8L8 7L5.00026 4L8 1L7.00009 0Z"
										fill="#5C5B5B"
									/>
								</svg>
							</div>

							<img src={img} alt="" className="imgForInput" />
						</div>
					)}
				</div>
			</div>
		</Container>
	);
};

export default AdicionarLogo;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	position: relative;
`;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	color: #5c5b5b;
	h3 {
		width: 100%;

		text-align: center;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 25.4356px;
		line-height: 34px;

		color: ${colors.primary_blue_k_dark_2};
		margin-bottom: 2rem;
	}
	.contentData {
		display: flex;
		flex-direction: column;
	}
	.dataRow {
		margin-top: 1rem;
	}
	.dataColumn {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 1rem;
		.dualListRow {
			display: flex;
			flex-direction: row;
		}
		.dataList {
			margin-top: 0.5rem;
			margin-left: 2rem;
		}
	}
`;

import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TextsProps } from "../../database/txt.interface";

const Descanso: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const intervalo_descanso = useSelector(
		(state: any) => state.configs.intervalo_descanso
	);

	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [tempo, setTempo] = useState(intervalo_descanso);
	const resultado_execucao = useSelector(
		(state: any) => state.app.resultado_execucao
	);

	useEffect(() => {
		let tempoExec = false;
		if (resultado_execucao) {
			if (tempo >= 0) {
				tempoExec = true;
			}
			setTimeout(() => {
				if (tempoExec) {
					setTempo(tempo - 1);
					setMinutes(Math.trunc(tempo / 60));
					setSeconds(tempo % 60);
				}
			}, 1000);
		} else if (resultado_execucao === false) {
			return;
		}
	}, [resultado_execucao, tempo]);
	return (
		<p className="descanso">
			<i>
				{current_language?.descanso}:{minutes <= 9 ? ` 0${minutes}` : minutes}:
				{seconds <= 9 ? `0${seconds}` : seconds}
			</i>
		</p>
	);
};

export default memo(Descanso);

import styled from "styled-components";
import { colors } from "../../../../../styles/colors";

export const Container = styled.div`
	display: flex;
	align-items: flex-start;
`;

export const Cards = styled.div`
	width: 50%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	border-right: 1px solid #B3B3B3;

	padding-bottom: 3rem;

	.cardsList {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		button {
			margin: .5rem;
		}
	}
`;

export const Title = styled.h5`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1.375rem;
	line-height: 120%;

	margin-bottom: 1.367rem;

	color: ${colors.primary_blue_k_main};

	text-align: center;
`;

export const Historico = styled.div`
	width: 50%;
	height: 400px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	padding-left: 3.848rem;

	h5 {
		padding-right: 3.848rem;
	}
`;

export const List = styled.div`
	display: flex;
	flex-direction: column;

	overflow-x: hidden;

	padding-right: 3.848rem;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 4px;
	}
`;

export const ItemList = styled.p`
	padding: 1.438rem 0;

	border-top: 1px solid #B3B3B3;
`;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TextsProps } from "../../../database/txt.interface";
import { FinancialActions } from "../../../store/ducks/financial";
import { AppActions } from "../../../store/ducks/app";
import { TypesLanguage } from "../../../utils/types";
import { Conteiner, Content, Selection } from "./styles";
import ToastAlert from "../../../components/toast";
import Loading from '../../Dashboard/Loading';
import history from "../../../routes/history";
import { AmplitudeLog } from "../../../utils/amplitude";

export interface PlanosProps {
	PLANO_DESCRICAO_EN: string;
	PLANO_DESCRICAO_ES: string;
	PLANO_DESCRICAO_PT: string;
	PLANO_IUGU: string;
	PLANO_TITULO_EN: string;
	PLANO_TITULO_ES: string;
	PLANO_TITULO_PT: string;
	PLANO_VALOR: string;
}

export interface ItensProps {
	ITEM_ASSINATURA_DESCRICAO_EN: string;
	ITEM_ASSINATURA_DESCRICAO_ES: string;
	ITEM_ASSINATURA_DESCRICAO_PT: string;
	ITEM_ASSINATURA_TITULO_EN: string;
	ITEM_ASSINATURA_TITULO_ES: string;
	ITEM_ASSINATURA_TITULO_PT: string;
	ITEM_ASSINATURA_VALOR: string;
	ITEM_ASSINATURA_QUANTIDADE: string;
	ITEM_ASSINATURA_RECORRENCIA: string;
	ITEM_ASSINATURA_TIPO: string;
}

export const TypesSelectButton = {
	PLANOS: "PLANOS",
	PACOTES: "PACOTES",
};

const Upgrade: React.FC = () => {
	const [planos, setPlanos] = useState<any>([]);
	const [itens, setItens] = useState<any>([]);
	const [new_planos, setNewPlanos] = React.useState([]);
	const [contrato, setContrato] = useState<any>([]);
	const [contratoItens, setContratoItens] = useState<any>([]);
	const [item, setItem] = useState("")
	const language = useSelector((state: any) => state.configs.language);

	const [active, setActive] = useState(TypesSelectButton.PLANOS)

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const planos_upsell = useSelector(
		(state: any) => state.financial.planos_upsell
	);
	const itens_upsell = useSelector(
		(state: any) => state.financial.itens_upsell
	);
	const loading_upsell = useSelector(
		(state: any) => state.financial.loading_upsell
	);
	const select_item = useSelector(
		(state: any) => state.financial.select_item
	);
	const filterPlanos = planos_upsell?.planos?.filter((item: any)=> item?.PLANO_PESO > config_servicos?.SERVICO_PLANO_PESO)

	const dispatch = useDispatch();

	useEffect(() => {
		if (loading_upsell && filterPlanos.length === 0) {
			window.open(`https://wa.me/556133479291?text=Ol%C3%A1%2C%20gostaria%20de%20mudar%20o%20meu%20plano%20`, '_blank');
			history.push('/dashboard')
		}

		if (!loading_upsell && !!planos_upsell) {
			setContrato(planos_upsell.link_contrato);
			setPlanos(planos_upsell.planos);
			setItens(itens_upsell.itens_assinatura);
			setContratoItens(itens_upsell.link_contrato);
		}
	}, [loading_upsell]);


	function SelectContrato(plano: any) {
		AmplitudeLog('plano_'+` ${plano?.PLANO_TITULO_PT}`)
		window.open(`https://wa.me/556133479291?text=Ol%C3%A1%2C%20gostaria%20de%20mudar%20o%20meu%20plano%20para%20${plano.PLANO_TITULO_PT}`, '_blank');
	}

	useEffect(() => {
		dispatch(FinancialActions.set_planos_itens());
		dispatch(AppActions.set_open_barraSuperior("Upgrade"));
	}, []);

	
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const formatInformation=(item:any)=>{
		
		const result = language == TypesLanguage.PT
			? item.PLANO_DESCRICAO_PT.split(',')
			: language == TypesLanguage.EN
			? item.PLANO_DESCRICAO_EN.split(',')
			: language == TypesLanguage.ES && item.PLANO_DESCRICAO_ES.split(',')
		return result
	};

	return (
		<>
			{is_open_toast && <ToastAlert />}
			{loading_upsell ?
				<Loading />
				:
				<Conteiner>
					<h1 className="title">{current_language?.saia_sub}</h1>
					<Content>
						{filterPlanos?.map((item: PlanosProps) => (
							<div key={item.PLANO_DESCRICAO_PT} className="divPlano">
								<div className="divPlanoDetail">
									<div className="titleAndPrice">
										<h3 className="titlePlan">
											{current_language?.plano}{' '}
											{language == TypesLanguage.PT
												? item.PLANO_TITULO_PT
												: language == TypesLanguage.EN
													? item.PLANO_TITULO_ES
													: language == TypesLanguage.ES && item.PLANO_TITULO_ES}
										</h3>
									</div>

									{
										formatInformation(item).map((e:PlanosProps)=>(
											<>
												<li style={{marginTop:10}}>{e}</li>
											</>
										))
									}
								</div>
								<div className="divAssinar">
									<button 
										className="button"
										onClick={() => SelectContrato(item)}
									>
										{current_language?.falar_com_especialista}
									</button>
									<p className="termos">{current_language?.sujeito_termos_condicoes}</p>
								</div>
							</div>
						))
						}
					</Content>
				</Conteiner>
			}
		</>
	);
};

export default Upgrade;

import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

import Mais from "../../../assets/icons/+.svg";
import DinoFeliz from "../../../assets/icons/dinoFeliz.svg";
import { TextsProps } from "../../../database/txt.interface";
import {  Conteiner } from "./styles";
import DinoHappy from '../../../assets/icons/dinoFeliz.svg'

const Loading: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	//UI
	return (
		<Conteiner id="DivPrincipal">
			<div className="divLoading">
				<div className="circulo">
					<img src={DinoHappy} style={{height:60, width:60}} />
				</div>
				<div className="loader-container">
					<div className="loader"></div>
				</div>
				<h1 className="texto">{current_language?.loading}...</h1>
			</div>
		</Conteiner>
	);
};

export default Loading;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 21px;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 2rem;
		width: 12%;
	}

	.divText {
		width: 65%;
	}

	.text {
		color: #b3b3b3;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}

	.textButton {
		color: #b3b3b3;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
		cursor: pointer;
	}

	.textButtonSeleted {
		color: ${colors.secondary_green_k_medium_1};
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
		cursor: pointer;
	}

	.button {
		width: 60%;

		text-align: center;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 0.7rem 3.3rem;

		border: 4px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}

	.lenguages {
		width: 60%;

		margin: 5rem 0;

		display: flex;
		justify-content: center;
	}

	.lenguage {
		position: relative;

		display: flex;
		flex-direction: column;
		align-items: center;

		margin: 0 1.5rem;
	}

	.select {
		width: 1.5rem;
		margin-bottom: 2rem;

		fill: #001574;
	}

	.groupRadio {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.groupRadio input {
		opacity: 0;
		cursor: pointer;
		z-index: 999;
		height: 25px;
	}

	.checkmark {
		position: absolute;
		top: 0;
		height: 25px;
		width: 25px;
		background-color: #c4c4c4;
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}

	.checkmarkSeleted {
		position: absolute;
		top: 0;
		height: 25px;
		width: 25px;
		background-color: ${colors.secondary_green_k_medium_1};
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}
`;

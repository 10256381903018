import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Mais from "../../../assets/icons/+.svg";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

interface iBotao {
	patch: string;
}

const BotaoAdicionar: React.FC<iBotao> = (props: any) => {
	const dispatch = useDispatch();

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);
	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);

	const permissionHandleOpenAdicionar =(config_servicos: ServicosProps[], dispatch: any,)=>{
		const permissionAdicionarPatient: any = getPermission(
			config_servicos,
			TypesPermission?.SERVICO_PACIENTES,
		)

		const permissionAdicionarEvaluator: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_AVALIADORES,
		)
		if (props.patch === "addPc") {
			AmplitudeLog('adicionar_paciente')
			total_pacientes < permissionAdicionarPatient
				? dispatch(AppActions.set_modal_adicionar_paciente(true))
				: dispatch(AppActions.set_modal_upgrade(true));
		}
		dispatch(AppActions.set_open_protocolo(false, false));

		if (props.patch === "addAval") {
			AmplitudeLog('adicionar_avaliador')
			avaliadores.length < permissionAdicionarEvaluator
				? dispatch(AppActions.set_modal_adicionar_avaliador(true))
				: dispatch(AppActions.set_modal_upgrade(true));
		}
		dispatch(AppActions.set_open_protocolo(false, false));
	};

	
	return (
		<Conteiner>
			<div>
				<button
					className="btnAdicionar btn-white btn-animate"
					onClick={()=> {
						permissionHandleOpenAdicionar(config_servicos, dispatch)
						dispatch(AppActions.set_open_paciente(false))
					}}
				>
					<svg
						width="21"
						height="21"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.3174 6.54492V17.6345"
							stroke="#000E4B"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M6.5459 12.0898H18.0892"
							stroke="#000E4B"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<circle cx="12" cy="12" r="11" stroke="#000E4B" strokeWidth="2" />
					</svg>
					<p className="textoBotao">{props.children}</p>
				</button>
			</div>
		</Conteiner>
	);
};

export default BotaoAdicionar;

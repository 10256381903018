import styled from "styled-components";
import { colors } from "../../../../styles/colors";

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.btns {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100% !important;

		.typeReport {
			font-family: Roboto;
			padding-left: 1.37rem;
			padding-right: 1.37rem;
			padding-top: 0.43rem;
			padding-bottom: 0.43rem;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #b3b3b3;
			border: 0.4px solid #b3b3b3;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 0.5rem;
			margin-right: 0.75rem;
			background-color: transparent;
		}

		.typeReportActive {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #001574;
			border: 0.4px solid #001574;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 0.5rem;
			margin-right: 0.75rem;
			background-color: transparent;
		}
	}

	.cards {
		display: grid;
		grid-template-columns: auto auto;
		/* flex-direction: row;
      justify-content: space-between; */
		width: 100%;
		margin-top: 2.18rem;
		margin-bottom: 1rem;

		.card {
			margin: 5px;
			width: 97%;
			padding: 1rem;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
			box-sizing: border-box;
			border-radius: 10px;

			.title {
				font-family: Roboto;
				font-style: italic;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: #5c5b5b;
				margin-bottom: 1.44rem;
			}

			.chart {
				height: 6.25rem;
				width: 100%;
			}

			.table {
				width: 100%;
				border: 1px solid #e5e5e5;
				box-sizing: border-box;
				padding: 0.813rem;
				border-radius: 8px;
				margin-top: 0.5rem;

				.parameter {
					font-family: Roboto;
					font-style: nl;
					font-weight: bold;
					font-size: 12px;
					line-height: 14px;
					text-align: justify;
					color: #5c5b5b;
					padding-bottom: 1.25rem;
				}

				.assimetria {
					font-family: Roboto;
					font-style: normal;
					font-weight: bold;
					font-size: 12px;
					line-height: 14px;
					text-align: justify;
					border-bottom: 0.5px solid #e5e5e5;
				}

				.verde {
					color: #39e664;
				}

				.amarelo {
					color: #ffc530;
				}

				.vermelho {
					color: ${colors.tertiary_red_1_dark};
				}

				.value {
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 14px;
					text-align: justify;
					color: #5c5b5b;
					border-bottom: 0.5px solid #e5e5e5;
					padding-bottom: 0.3rem;
					padding-top: 0.3rem;
				}
			}
		}
	}
`;

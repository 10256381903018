import { CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import calendar from "../../../assets/icons/calendar.svg";
import celular from "../../../assets/icons/celular.svg";
import emailGray from "../../../assets/icons/email.svg";
import globo from "../../../assets/icons/globoComLocalidade.svg";
import loja from "../../../assets/icons/loja.svg";
import user from "../../../assets/icons/peopleCartao.svg";
import AdicionarLogo from "../../../components/AdicionarLogo";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { RelatorioPersonal } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { RegisterActions } from "../../../store/ducks/register";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { Conteiner, BodyEdite, Footer } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

interface Usuario {
	celular: string;
	cpf: string;
}

const PersonalizarRelatorio: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
	} = useForm();

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("personalizarRelatorio"));
	}, []);

	const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
	const hendleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUsuario({
				...usuario,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[usuario]
	);

	const image_relatorio = useSelector(
		(state: any) => state.relatorios.image_relatorio
	);

	const config_relatorio: RelatorioPersonal = useSelector(
		(state: any) => state.configs.config_relatorio
	);

	const handleKeyUpCEP = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (current_language.idioma === "Português") {
			// 12345-678
			e.currentTarget.maxLength = 9;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/(\d{5})(\d)/, "$1-$2");

			e.currentTarget.value = ajustado;
		} else {
			e.currentTarget.maxLength = 14;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			e.currentTarget.value = ajustado;
		}
	}, []);

	const handleContato = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (current_language.idioma === "Português") {
			// (99)99999-9999
			e.currentTarget.maxLength = 15;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			if (ajustado.length > 10) {
				ajustado = ajustado.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 5) {
				ajustado = ajustado.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 2) {
				ajustado = ajustado.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
			} else {
				ajustado = ajustado.replace(/^(\d*)/, "($1");
			}
			e.currentTarget.value = ajustado;
		} else {
			// + 456 544 344 454
			e.currentTarget.maxLength = 17;
			const value = e.currentTarget.value;
			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			ajustado = ajustado.replace(/^(\d{3})/, "+ $1");

			e.currentTarget.value = ajustado;
		}
	}, []);

	if (image_relatorio == undefined) {
		dispatch(
			RelatorioActions.set_imagem_relatorio(
				config_relatorio?.CONFIG_LOGOMARCA_BASE64
			)
		);
	}
	useEffect(() => {
		setValue("celular", config_relatorio?.CONFIG_EMPRESA_TELEFONE);
		setValue("email", config_relatorio?.CONFIG_EMPRESA_EMAIL);
		setValue("empresa", config_relatorio?.CONFIG_EMPRESA_NOME);
		setValue("lagradouro", config_relatorio?.CONFIG_ENDERECO_LOGRADOURO);
		setValue("bairro", config_relatorio?.CONFIG_ENDERECO_BAIRRO);
		setValue("cidade", config_relatorio?.CONFIG_ENDERECO_CIDADE_UF);
		setValue("cep", config_relatorio?.CONFIG_ENDERECO_CEP_ZIP);
		dispatch(
			RelatorioActions.set_imagem_relatorio(
				config_relatorio?.CONFIG_LOGOMARCA_BASE64
			)
		);
	}, [config_relatorio]);

	const [aprovado, setAprovado] = useState(false);

	const onSubmit = (data: any) => {
		setAprovado(true);
		const dat_request = {
			CONFIG_LOGOMARCA_BASE64: image_relatorio,
			CONFIG_EMPRESA_TELEFONE: data.celular,
			CONFIG_EMPRESA_EMAIL: data.email,
			CONFIG_EMPRESA_NOME: data.empresa,
			CONFIG_ENDERECO_LOGRADOURO: data.lagradouro,
			CONFIG_ENDERECO_BAIRRO: data.bairro,
			CONFIG_ENDERECO_CIDADE_UF: data.cidade,
			CONFIG_ENDERECO_CEP_ZIP: data.cep,
		};
		AmplitudeLog('relatorio_personalizado_sucesso',{
			body: dat_request
		});
		dispatch(RelatorioActions.personalizar_relatorio_request(dat_request));
	};

	const voltar = () => {
		window.history.back();
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			<BodyEdite>
				<h2 className="titulo">
					{current_language?.menu_lateral_personalizar_relatorio}
				</h2>
				<form onSubmit={handleSubmit(onSubmit)} className="form">
					<AdicionarLogo />
					<div className="global">
						<div className="tamanhoInput">
							<div className="globalInput">
								<img src={celular} alt="key" className="icon" />
								<input
									onKeyPress={handleContato}
									type="text"
									id="celular"
									placeholder={current_language?.numero_telefone}
									className="input"
									{...register("celular")}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.celular ? "hr hrcor" : "hr"} />
								{errors.celular && (
									<div className="error">{errors.celular.message}</div>
								)}
							</div>
						</div>

						<div className="tamanhoInput margem">
							<div className="globalInput">
								<img src={emailGray} alt="key" className="icon" />
								<input
									type="text"
									id="email"
									placeholder={current_language?.login_email}
									className="input"
									{...register("email", {
										pattern: {
											value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: `${current_language?.email_invalido}`,
										},
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.email ? "hr hrcor" : "hr"} />
								{errors.email && (
									<div className="error">{errors.email.message}</div>
								)}
							</div>
						</div>

						<div className="tamanhoInput margemNome">
							<div className="globalInput">
								<img src={loja} alt="key" className="icon" />
								<input
									type="text"
									id="empresa"
									placeholder={current_language?.nome_empresa}
									className="input"
									{...register("empresa")}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.empresa ? "hr hrcor" : "hr"} />
								{errors.empresa && (
									<div className="error">{errors.empresa.message}</div>
								)}
							</div>
						</div>

						<div className="tamanhoInput">
							<div className="globalInput">
								<img src={globo} alt="key" className="globo" />
								<input
									type="text"
									id="lagradouro"
									placeholder={current_language?.menu_lateral_logradouro}
									className="input"
									{...register("lagradouro")}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.lagradouro ? "hr hrcor" : "hr"} />
								{errors.lagradouro && (
									<div className="error">{errors.lagradouro.message}</div>
								)}
							</div>
						</div>

						<div className="tamanhoInput">
							<div className="globalInput">
								<img src={globo} alt="key" className="globo" />
								<input
									type="text"
									id="bairro"
									placeholder={current_language?.menu_lateral_bairro}
									className="input"
									{...register("bairro")}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.bairro ? "hr hrcor" : "hr"} />
								{errors.bairro && (
									<div className="error">{errors.bairro.message}</div>
								)}
							</div>
						</div>

						<div className="tamanhoInput">
							<div className="globalInput">
								<img src={globo} alt="key" className="globo" />
								<input
									type="text"
									id="endercidadeeco"
									placeholder={current_language?.menu_lateral_cidade_uf}
									className="input"
									{...register("cidade")}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.cidade ? "hr hrcor" : "hr"} />
								{errors.cidade && (
									<div className="error">{errors.cidade.message}</div>
								)}
							</div>
						</div>

						<div className="tamanhoInput">
							<div className="globalInput">
								<img src={globo} alt="key" className="globo" />
								<input
									onKeyPress={handleKeyUpCEP}
									type="text"
									id="cep"
									placeholder={`CEP`}
									className="input"
									{...register("cep")}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.cep ? "hr hrcor" : "hr"} />
								{errors.cep && (
									<div className="error">{errors.cep.message}</div>
								)}
							</div>
						</div>
					</div>

					<Footer>
						<button 
							type="submit" 
							id="botao" 
							className="botao"
						>
							{aprovado == true ? (
								<CircularProgress color="inherit" />
							) : (
								current_language?.pagamento_salvar
							)}
						</button>
						<button
							type="button"
							id="botao"
							className="cancelar"
							onClick={()=>{voltar(), AmplitudeLog('cancelar_personalizacao_relatorio')}}
						>
							{current_language?.botao_cancelar}
						</button>
					</Footer>
				</form>
			</BodyEdite>
		</Conteiner>
	);
};

export default PersonalizarRelatorio;

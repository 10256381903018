import React, { memo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { UserProps } from "../../../interfaces/pacientes.interface";
import AlterarSenha from "../../MenueConta/AlterarSenha";
import CentralDeAjuda from "../../MenueConta/CentralDeAjuda";
import CentralDeAjudaItem from "../../MenueConta/CentralDeAjudaItem";
import Configuracoes from "../../MenueConta/Configuracoes";
import EditarMetodoPagamento from "../../MenueConta/EditarMetodoPagamento";
import EditarPerfil from "../../MenueConta/EditarPerfil";
import Etiquetas from "../../MenueConta/Etiquetas";
import Fatura from "../../MenueConta/Faturas";
import GerenciadorExcluidos from "../../MenueConta/GerenciadorExcluidos";
import MetodoPagamento from "../../MenueConta/MetodoPagamento";
import MeuPlano from "../../MenueConta/MeuPlano";
import MinhaConta from "../../MenueConta/MinhaConta";
import Pagamentos from "../../MenueConta/Pagamentos";
import Perfil from "../../MenueConta/Perfil";
import PersonalizarRelatorio from "../../MenueConta/PersonalizarRelatorio";
import Upgrade from "../../MenueConta/Upgrade";
import Confirmacao from "../../MenueConta/Upgrade/Confirmacao";
import Paciente from "../../Paciente";
import AdicionarProtocolo from "../../Protocolos/AdicionarProtocolo";
import ListagemProtocolos from "../../Protocolos/ListagemProtocolo";
import NovoExercicio from "../../Protocolos/NovoExercicio";
import BarraSuperiorControladora from "../BarrasSuperiores/BarraSuperiorControladora";
import Listagens from "../Listagens";
import Assinatura from "../../MenueConta/Assinatura";
import { Cards, ListagemEcards, Content } from "./styles";
import Evaluator from "../../../pages/MenueConta/MenuEvaluator";
import DetailProtocolo from "../../Protocolos/DetailProtocolo";

interface iListagem {
	listagem: string;
}

const ContentDash: React.FC<iListagem> = (props: any) => {
	const propaganda = false;
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const Group = styled.div`
		width: 100%;
		${propaganda ? "height: 82%;" : "height: 100%;"}
	`;

	const Container = styled.div`
		display: flex;
		flex-direction: column;
		width: 100vw;
		height: 100vh;

		@media only screen and (min-width: 1280px) {
			${user_logado !== null && "width: calc(100vw - 7rem);"}
		}

		@media only screen and (max-width: 1280px) {
			${user_logado !== null && "width: 100%;"}
		}

		@media only screen and (min-width: 1440px) {
			${user_logado !== null && "width: calc(100vw - 7rem);"}
		}

		@media only screen and (min-width: 1680px) {
			${user_logado !== null && "width: calc(100vw - 15.93rem);"}
		}
	`;

	return (
		<Container>
			<BarraSuperiorControladora />
			<ListagemEcards>
				<Group>
					<Content>
						{props.listagem === "home" && <Listagens />}
						{props.listagem === "paciente" && <Paciente />}
						{props.listagem === "alterarSenha" && <AlterarSenha />}
						{props.listagem === "assinatura" && <Assinatura />}
						{props.listagem === "editarPerfil" && <EditarPerfil />}
						{props.listagem === "perfil" && <Perfil />}
						{props.listagem === "minhaConta" && <MinhaConta />}
						{props.listagem === "evaluator" && <Evaluator />}
						{props.listagem === "meuPlano" && <MeuPlano />}
						{props.listagem === "pagamentos" && <Pagamentos />}
						{props.listagem === "editarMetodoPagamento" && (
							<EditarMetodoPagamento />
						)}
						{props.listagem === "metodoPagamento" && <MetodoPagamento />}
						{props.listagem === "fatura" && <Fatura />}
						{props.listagem === "personalizarRelatorio" && (
							<PersonalizarRelatorio />
						)}
						{props.listagem === "etiquetas" && <Etiquetas />}
						{props.listagem === "adicionarProtocolo" && <AdicionarProtocolo />}
						{props.listagem === "novoExercicio" && <NovoExercicio />}
						{props.listagem === "listagemProtocolo" && <ListagemProtocolos />}
						{props.listagem === "configuracoes" && <Configuracoes />}
						{props.listagem === "gerenciadorExcluidos" && (
							<GerenciadorExcluidos />
						)}
						{props.listagem === "centralAjuda" && <CentralDeAjuda />}
						{props.listagem === "centralAjudaItem" && <CentralDeAjudaItem />}
						{props.listagem === "upgrade" && <Upgrade />}
						{props.listagem === "confirmacao" && <Confirmacao />}
						{props.listagem === "detalhe_protocolo" && <DetailProtocolo />}
					</Content>
					{propaganda && <Cards />}
				</Group>
			</ListagemEcards>
		</Container>
	);
};

export default memo(ContentDash);

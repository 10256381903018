import { Fade } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Celular from "../../../assets/icons/celular.svg";
import Datanasc from "../../../assets/icons/datanasc.svg";
import emailGray from "../../../assets/icons/emailGray.svg";
import Fechar from "../../../assets/icons/fechar.svg";
import cpfUser from "../../../assets/icons/cpfAvaliador.svg";
import user from "../../../assets/icons/user.svg";
import Cpf from "../../../assets/icons/user.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorRequest } from "../../../utils/types";
import FotoPerfilMenor from "../../FotoPerfilMenor";
import ToastAlert from "../../toast";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import handleMaskCPF from "../../MascaraCPF";
import handleMaskPhone from "../../MascaraTel";


interface Usuario {
	celular: string;
	cpf: string;
}

const EditarAvaliador = () => {
	//ESTILO DA MODAL
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			modal: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				backgroundColor: theme.palette.background.paper,
				border: "0",
				boxShadow: theme.shadows[5],
			},
		})
	);

	const classes = useStyles();
	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_avaliador
	);

	//Traducão
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	//ESTADO CPF MASK
	const [us, setUs] = useState<Usuario>({} as Usuario);
	const hendleChange2 = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUs({
				...us,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[us]
	);

	//Toogle para tornar o usuário adm
	const [adm, setAdm] = useState(paciente.USUARIO_NIVEL == 1 ? true : false);
	const HandleCondition = () => {
		if (adm == true) {
			setAdm(false);
		} else {
			setAdm(true);
		}
	};

	//Abrir modal
	const is_open = useSelector(
		(state: any) => state.app.is_open_editar_avaliador
	);
	const ModalEditarPacienteClose = () => {
		dispatch(AppActions.set_modal_editar_avaliador(false));
	};

	//Submit do formulario
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const [aprovado, setAprovado] = useState(false);

	const onSubmit = (data: any) => {
		setAprovado(true);
		const dat_request = {
			USUARIO_NIVEL: adm ? 1 : 2,
			USUARIO_NOME: data.nome,
			USUARIO_EMAIL: data.email,
			USUARIO_CONTATO: data.celular,
			USUARIO_CPF: data.cpf,
			USUARIO_ESTATURA: data.altura.replace("m", ""),
			USUARIO_MASSA: data.massa.replace("kg", ""),
			USUARIO_NASCIMENTO:
				current_language?.idioma === "Português"
					? data?.data?.split("/").reverse().join("-")
					: current_language?.idioma === "English"
					? `${data?.data?.substr(6, 10)}-${data?.data
							?.substr(0, 5)
							.split("/")
							.reverse()
							.join("-")}`
					: data?.data.split("-").reverse().join("-"),
			USUARIO_SEXO: data?.sexo,
			USUARIO_CONDICIONAMENTO: data?.condicionamento,
		};
		AmplitudeLog('edicao_concluida', {
			body: dat_request
		})
		dispatch(AddPacAvalActions.set_edit_paci(paciente));
		dispatch(AddPacAvalActions.request_add_evaluetor(dat_request));
	};
	//console.log(errors);

	//Valores dos inputs
	useEffect(() => {
		setValue("nome", paciente?.USUARIO_NOME);
		setValue("email", paciente?.USUARIO_EMAIL);
		setValue(
			"celular",
			paciente?.USUARIO_CONTATO?.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
		);
		setValue(
			"cpf",
			paciente?.USUARIO_CPF?.replace(
				/(\d{3})(\d{3})(\d{3})(\d{2})/,
				"$1.$2.$3-$4"
			)
		);
		setValue(
			"data",
			paciente?.USUARIO_NASCIMENTO == "0000-00-00"
				? ""
				: current_language?.idioma === "Português"
				? `${paciente.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("/")}`
				: current_language?.idioma === "English"
				? `${paciente.USUARIO_NASCIMENTO?.substr(5, 10)
						.split("-")
						.join("/")}/${paciente?.USUARIO_NASCIMENTO?.substr(0, 4)}`
				: `${paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("-")}`
		);
		setValue(
			"altura",
			`${
				paciente?.USUARIO_ESTATURA != null && paciente?.USUARIO_ESTATURA != ""
					? paciente?.USUARIO_ESTATURA?.split(".").join(",")
					: ""
			}`
		);
		setValue(
			"massa",
			`${
				paciente?.USUARIO_MASSA != null && paciente?.USUARIO_MASSA != ""
					? paciente?.USUARIO_MASSA?.split(".").join(",")
					: ""
			}`
		);
	}, [paciente]);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		if (type_toast == TypesErrorRequest.SUCCESS) {
			ModalEditarPacienteClose();
		}
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				onClose={ModalEditarPacienteClose}
				open={is_open}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open}>
					<Conteiner>
						{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
							<ToastAlert />
						) : (
							""
						)}
						<form onSubmit={handleSubmit(onSubmit)} className="form">
							<div className="sup">
								<div className="NomeEperfil">
									<h1 className="NovoPc">
										{current_language?.editar_avaliador}
									</h1>
									<FotoPerfilMenor></FotoPerfilMenor>
								</div>
								<div className="fechar">
									<button type="button" className="botaofechar">
										<img
											src={Fechar}
											alt=""
											onClick={()=>{
												ModalEditarPacienteClose(), 
												AmplitudeLog('edicao_cancelada')
											}}
										/>
									</button>
								</div>
							</div>
							<div className="mid">
								<div className="formP1">
									<div className="globalInput">
										<img src={user} alt="key" className="icon" />
										<input
											type="text"
											id="nome"
											placeholder={current_language?.primeiro_acesso_nome}
											className="input"
											{...register("nome", {
												required: `${current_language?.login_input_senha}`,
												pattern: {
													value:
														/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,35}/i,
													message: `${current_language?.pagamento_nome_invalido}`,
												},
											})}
										/>
									</div>
									<div className="divhr">
										<hr className={errors.nome ? "hr hrcor" : "hr"} />
										{errors.nome && (
											<div className="error">{errors.nome.message}</div>
										)}
									</div>
									
									<div className="globalInput">
										<img src={cpfUser} alt="cpf" className="icon" />
										<input
											type="text"
											id="cpf"
											onKeyDown={handleMaskCPF}
											placeholder={current_language?.primeiro_acesso_CPF}
											className="input"
											{...register("cpf", {
												// required: `${current_language?.login_input_senha}`,
												pattern: {
													value:
														/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i,
													message: `${current_language?.cpf_invalido}`,
												},
											})}
											{...hendleChange2}
										/>
									</div>
									<div className="divhrCPF">
										<hr className={errors.cpf ? "hr hrcor" : "hr"} />
										{errors.cpf && (
											<div className="error">{errors.cpf.message}</div>
										)}
									</div>
									<div className="grupo">
											<div className="adm">
												<p className="input">
													{current_language?.add_avaliador}
												</p>
												<label className="switch">
													<input
														type="checkbox"
														checked={adm}
														onChange={HandleCondition}
													/>
													<span className="slider round"></span>
												</label>
											</div>
										</div>
								</div>
								<div className="formP2">
									<div className="formP21">
										<div className="globalInput">
											<img 
												src={emailGray}
												alt="key" 
												className="icon" 
												style={{marginTop: 5}}
											/>
											<input
												type="text"
												id="email"
												placeholder={current_language?.login_email}
												className="input"
												{...register("email", {
													required: `${current_language?.login_input_senha}`,
													pattern: {
														value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
														message: `${current_language?.forgot_request_error_email}`,
													},
												})}
											/>
										</div>
										<div className="divhr">
											<hr className={errors.email ? "hr hrcor" : "hr"} />
											{errors.email && (
												<div className="error">{errors.email.message}</div>
											)}
										</div>
										<div className="globalInput">
											<img 
												src={Celular} 
												alt="key" 
												className="icon" 
												style={{marginTop: 4}}
											/>
											<input
												type="text"
												id="celular"
												onKeyDown={handleMaskPhone}
												placeholder={current_language?.menu_lateral_person_numero}
												className="input"
												{...register("celular", {
													required: `${current_language?.login_input_senha}`,
												})}
											/>
										</div>
										<div className="divhr">
											<hr className={errors.celular ? "hr hrcor" : "hr"} />
											{errors.celular && (
												<div className="error">{errors.celular.message}</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="inf">
								<button 
									id="concluir" 
									className="concluir"
								>
									{aprovado == true ? (
										<CircularProgress size="2rem" color="inherit" />
									) : (
										current_language?.pagamento_salvar
									)}
								</button>
							</div>
						</form>
					</Conteiner>
				</Fade>
			</Modal>
		</div>
	);
};

export default EditarAvaliador;

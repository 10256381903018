import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	overflow: hidden;
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 50vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 50vh;
		width: 40vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 50vh;
		width: 40vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 45vh;
		width: 35vw;
	}
	.close {
		width: 1.738rem;

		position: absolute;
		right: 1.8rem;
		top: 1.8rem;

		cursor: pointer;
	}

	.text {
		color: #b3b3b3;
		font-weight: 500;
		font-family: "Roboto";
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}

	.title {
		width: 70%;
		color: #5c5b5b;
	}

	.textButton {
		color: #b3b3b3;
		font-weight: 500;
		font-family: "Roboto";
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
		cursor: pointer;
	}

	.footer {
		width: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.button {
		width: 60%;
		height: 3.625rem;

		text-align: center;

		font-family: "Roboto";
		font-weight: 700;
		font-size: 1.125rem;

		color: #fff;
		background: ${colors.primary_blue_k_main};

		line-height: 1.758rem;
		padding: 0.7rem 3.3rem;
		border: 0;
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_dark_2};
		color: #ffffff;
	}

	.numberSelect {
		font-weight: 500;
		font-size: 1.25rem;

		border: 1px solid #c4c4c4;
		border-radius: 0.3rem;
		padding: 0.5rem 0 0.5rem 1rem;

		display: flex;
		align-items: center;

		span {
			margin-right: 0.8rem;
		}

		svg {
			cursor: pointer;
		}
	}

	.transparent {
		visibility: hidden;
	}

	.buttons {
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;

		height: 1rem;

		border: 0;

		background: transparent;
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: left;
		cursor: pointer;
		margin-right: 1rem;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.maxkg {
		width: 15rem;
	}

	.opacidade {
		opacity: 0.5;
	}
`;

export const Content = styled.div`
	width: 90%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.divText {
		color: #5c5b5b;
		width: 83%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			margin-right: 0.8rem;
		}

		span {
			font-weight: 500;
			font-size: 1.125rem;
		}
	}


	.avisos {
		width: 100%;
	}

	.aviso {
		font-size: 0.78rem;

		span {
			font-size: 0.78rem;
			color: ${colors.tertiary_red_1_dark};
		}
	}

	h4 {
		color: #b3b3b3;
	}

	.etiquetas {
		height: 100%;
		width: 100%;

		font-weight: 500;
		font-size: 1.25rem;

		color: #8c8e86;

		margin: 1rem;
		padding: 0 8%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.item {
		width: 100%;
		height: 5rem;

		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.checkbox {
	}
`;

export const SliderDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 93%;
	margin-left: 0.7rem;
`;

export const SliderContent = styled.div`
	position: relative;

	.Nminimo {
		margin-left: 0.4rem;
		font-size: 0.8rem;
	}

	.forcaMax {
		font-size: 0.6rem;
		font-weight: 500;

		color: #5c5b5b;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
`;

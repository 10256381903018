import { CircularProgress } from "@material-ui/core";
import { push } from "connected-react-router";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import background from "../../../assets/kinologyIcons/png/backgroundLogin.png";
import Ajuda from "../../../assets/icons/ajuda.svg";
import email from "../../../assets/icons/email.svg";
import Idioma from "../../../assets/icons/idioma.svg";
import key from "../../../assets/icons/key.svg";
import NotVisible from "../../../assets/icons/notvisible.svg";
import Visible from "../../../assets/icons/visible.svg";
import Logo_login from "../../../assets/kinologyIcons/svg/Logo_login.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";
import { AuthActions } from "../../../store/ducks/auth";
import { ConfigsActions } from "../../../store/ducks/configs";
import { checkLanguage } from "../../../utils/texts";
import { TypesPermission } from "../../../utils/types";
import { Background, Conteiner, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { colors } from "../../../styles/colors";

const Login: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const [passwordShown, setPasswordShown] = useState(false);
	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};
	const [aprovado, setAprovado] = useState(false);
	const config_servicos: ServicosProps = useSelector(
		(state: any) => state.configs.config_servicos,
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data: any) => {
		AmplitudeLog('login_usuario', {
			usuario:{
				USUARIO_EMAIL: data.email,
				txIdioma: "pt",
				txLoginTipo: 2,
				DEVICE_UUID: "",
			}
		})
		setAprovado(true);
		const authData = {
			USUARIO_EMAIL: data.email,
			USUARIO_SENHA: data.password,
			DEVICE_UUID: "24c6482b64839e8384cJ",
			txIdioma: "pt",
			txLoginTipo: 2,
		};
		dispatch(AuthActions.auth_request(authData, true));
	};


	const handleOpenLinguagem = () => {
		dispatch(AppActions.set_modal_linguagem(true));
	};

	const helpElastic = () => {
		history.push("/central_ajuda");
	};

	useEffect(() => {
		checkLanguage(language, dispatch);
	}, [language]);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const is_open = useSelector((state: any) => state.app.is_open);

	useEffect(() => {
		if (is_open_toast === true || is_open === true) {
			setAprovado(false);
		}
	}, [is_open_toast, is_open]);

	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}

			<Content>
				<img src={Logo_login} alt="logo" className="logo" />
				<div className="interativos">
					<div className="groupForm">
						<div className="Textos">
							<h1 className="h1">Login</h1>
							<p className="p">{current_language?.inicie_os_trabalhos}</p>
						</div>
						<form action="" onSubmit={handleSubmit(onSubmit)}>
							<div className="globalInput">
								<img src={email} alt="email" className="icon" />
								<input
									className="input"
									type="text"
									id="email"
									placeholder={current_language?.login_email}
									{...register("email", {
										required: `${current_language?.login_input_senha}`,
										pattern: {
											value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: `${current_language?.register_email_valido}`,
										},
									})}
								/>
							</div>
							<div className="divhr">
								<hr className={errors.email ? "hr hrcor" : "hr"} />
								{errors.email && (
									<div className="error">{errors.email.message}</div>
								)}
							</div>
							<div className="globalInput">
								<img src={key} alt="key" className="icon" />
								<input
									className="input"
									type={passwordShown ? "text" : "password"}
									id="password"
									placeholder={current_language?.login_senha}
									{...register("password", {
										required: `${current_language?.login_input_senha}`,
										minLength: {
											value: 8,
											message: current_language?.register_aviso_min_6,
										},
									})}
								/>
								<button
									type="button"
									id="eye"
									className="eyeButton"
									onClick={togglePasswordVisiblity}
								>
									<img src={passwordShown ? NotVisible : Visible} alt="eye" />
								</button>
							</div>
							<div className="divhr2">
								<hr className={errors.password ? "hr2 hr2cor" : "hr2"} />
								{errors.password && (
									<div className="error">{errors.password.message}</div>
								)}
							</div>
							<div className="LoginCadastro">
								<>
									{aprovado === true ? (
										<button
											type="submit"
											id="enviar"
											value="ENVIAR"
											className="enviar"
										>
											<CircularProgress color="inherit" style={{color: colors?.neutral_grey_k_5}} />
										</button>
									) : (
										<button
											type="submit"
											id="enviar"
											value="ENVIAR"
											className="enviar"
										>
											{current_language?.login_entrar_botao}
										</button>
									)}
									<Link to="/cadastro" className="forget"
										onClick={()=> AmplitudeLog('criar_conta')}
									>
										{current_language?.criar_minha_conta}
									</Link>
									<Link
										onClick={()=> AmplitudeLog('esqueceu_senha')}
										to="/recuperar_senha"
										type="button"
										id="Forget"
										className="forget"
									>
										{current_language?.login_esqueci_senha}
									</Link>
								</>
							</div>
						</form>
					</div>
					<div className="global">
						<button
							type="button"
							value="Idioma"
							onClick={handleOpenLinguagem}
							className="inputIA idiomaAjuda"
						>
							<img src={Idioma} alt="logo" className="imgIA" />
							{current_language?.login_idioma}
						</button>
						<button
							type="button"
							value="Ajuda"
							onClick={helpElastic}
							className="inputIA idiomaAjuda"
						>
							<img src={Ajuda} alt="logo" className="imgIA" />
							{current_language?.login_ajuda}
						</button>
					</div>
				</div>
			</Content>
			<Background>
				<img src={background} alt="" />
			</Background>
		</Conteiner>
	);
};

export default memo(Login);

import styled from "styled-components";

export const Conteiner = styled.div`
	.btns {
		display: flex;
		flex-direction: row;
	}

	.btnAvaliador {
		margin-left: 1.3rem;
		display: flex;
		flex-direction: row;
		width: 9rem;
		height: 3.731rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		transition: all 0.3s linear;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);

		p {
			margin-left: 0.5rem;
			font-weight: 500;
			font-size: 16px;
		}

		svg {
			margin-top: -0.1rem;
			width: 20px;
		}
	}

	.btnAvaliador:hover {
		transform: translateY(-3px);
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btnAvaliador:active {
		transform: translateY(-1px);
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btn-white {
		background-color: white;
		color: #777;
	}

	.btnAvaliador::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 100px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}

	.btn-white::after {
		background-color: white;
		transition: all 0.2s linear;
	}

	.btnAvaliador-animated {
		animation: moveInBottom 5s ease-out;
		animation-fill-mode: backwards;
	}

	@keyframes moveInBottom {
		0% {
			opacity: 0;
			transform: translateY(30px);
		}

		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	.btnfísica {
		margin-left: 1.3rem;
		display: flex;
		flex-direction: row;
		width: 12rem;
		height: 3.731rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		transition: all 0.3s linear;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);

		p {
			margin-left: 0.5rem;
			font-weight: 500;
			font-size: 16px;
		}

		svg {
			width: 20px;
		}
	}

	.btnfísica:hover {
		transform: translateY(-3px);
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btnfísica:active {
		transform: translateY(-1px);
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btn-white {
		background-color: white;
		color: #777;
	}

	.btnfísica::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 100px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}

	.btn-white::after {
		background-color: white;
		transition: all 0.2s linear;
	}

	.btnfísica-animated {
		animation: moveInBottom 5s ease-out;
		animation-fill-mode: backwards;
	}

	@keyframes moveInBottom {
		0% {
			opacity: 0;
			transform: translateY(30px);
		}

		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	.btnEtiqueta {
		margin-left: 1.3rem;
		display: flex;
		flex-direction: row;
		width: 8.5rem;
		height: 3.731rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		transition: all 0.3s linear;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);

		p {
			margin-left: 0.5rem;
			font-weight: 500;
			font-size: 16px;
		}

		svg {
			width: 25px;
		}
	}

	.texto {
		margin-left: 1rem;
	}

	.btnEtiqueta:hover {
		transform: translateY(-3px);
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btnEtiqueta:active {
		transform: translateY(-1px);
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btn-white {
		background-color: white;
		color: #777;
	}

	.btnEtiqueta::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 100px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}

	.btn-white::after {
		background-color: white;
		transition: all 0.2s linear;
	}

	.btnEtiqueta-animated {
		animation: moveInBottom 5s ease-out;
		animation-fill-mode: backwards;
	}

	.transicaoCond {
		animation: go2-back 0.5s;
	}

	@keyframes go2-back {
		from {
			transform: translateX(150px);
		}
		to {
			transform: translateX(0);
		}
	}

	.transicaoAva {
		animation: go-back 0.5s;
	}

	@keyframes go-back {
		from {
			transform: translateX(350px);
		}
		to {
			transform: translateX(0);
		}
	}

	@keyframes moveInBottom {
		0% {
			opacity: 0;
			transform: translateY(30px);
		}

		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}
`;

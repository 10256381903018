import { push } from "connected-react-router";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { RegisterActions, Types } from "../ducks/register";
import { AmplitudeLog } from "../../utils/amplitude";
import * as Sentry from "@sentry/react";

function* registerUser({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const language: any = yield select((state) => state.configs.language);
	async function apiCall() {
		try {
			const dat = {
				...payload,
				txDataHora: new Date(),
				txIdioma: language?.toLowerCase(),
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}acesso/acesso_cadastro.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == 1) {
				message = current_language?.register_request_error_email;
			}
			if (response.code == -1) {
				message = current_language?.register_request_error;
			}
			yield put(push("/login"));
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			yield put(push("/login"));
			if (response.code == 0) {
				message = current_language?.cadastro_concluido;
				AmplitudeLog('conta_criada', {
					usuario: {
						...payload,
						txDataHora: new Date(),
						txIdioma: language?.toLowerCase(),
					}
				});
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: acesso_cadastro.php", "error");
	} finally {
		yield put(RegisterActions.register_finally());
	}
}

export function* RegisterSaga(): any {
	return yield all([takeLatest(Types.REGISTER_REQUEST, registerUser)]);
}

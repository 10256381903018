import React, { useEffect } from "react";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import BarraSuperiorControladora from "../../../Dashboard/BarrasSuperiores/BarraSuperiorControladora";
import {
	Cards,
	Container,
	Group,
	ListagemEcards,
} from "../../../Dashboard/ContentDash/styles";
import { checkLanguage } from "../../../../utils/texts";

import { useDispatch, useSelector } from "react-redux";

import AdicionarProtocolo from "..";

const EditarProtocoloController: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	useEffect(() => {
		checkLanguage(language, dispatch);
	}, [language]);
	return (
		<ViewPort>
			<Menu setMenu="protocolos"></Menu>
			<Container>
				<BarraSuperiorControladora />
				<ListagemEcards>
					<Group>
						<AdicionarProtocolo />
					</Group>
				</ListagemEcards>
			</Container>
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	height: 100vh;
	min-height: 797px;
	width: 100vw;
	display: flex;
	color: rgba(140, 142, 134, 0, 8);
	min-height: 48.125rem;
`;

export default EditarProtocoloController;

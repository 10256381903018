import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalUpgradeFuncionalidade: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_upgrade_funcionalidade = useSelector(
		(state: any) => state.app.is_open_upgrade_funcionalidade
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_upgrade_funcionalidade(false));
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_upgrade_funcionalidade}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_upgrade_funcionalidade}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="divText">
							<svg
								width="72"
								height="92"
								viewBox="0 0 52 72"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="3.03125"
									y="23.4946"
									width="45.4737"
									height="45.4737"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M44.5468 24.3453H7.2518C5.53534 24.3453 4.14388 25.7368 4.14388 27.4532V64.7482C4.14388 66.4646 5.53535 67.8561 7.2518 67.8561H44.5468C46.2632 67.8561 47.6547 66.4646 47.6547 64.7482V27.4532C47.6547 25.7368 46.2632 24.3453 44.5468 24.3453ZM7.2518 20.2014C3.24674 20.2014 0 23.4482 0 27.4532V64.7482C0 68.7532 3.24674 72 7.2518 72H44.5468C48.5518 72 51.7986 68.7532 51.7986 64.7482V27.4532C51.7986 23.4482 48.5518 20.2014 44.5468 20.2014H7.2518Z"
									fill="#5C5B5B"
								/>
								<path
									d="M13.7266 40.144C13.7266 38.9997 14.6542 38.072 15.7985 38.072C16.9428 38.072 17.8704 38.9997 17.8704 40.144V45.3238C17.8704 46.4681 16.9428 47.3958 15.7985 47.3958C14.6542 47.3958 13.7266 46.4681 13.7266 45.3238V40.144Z"
									fill="#5C5B5B"
								/>
								<path
									d="M34.7051 40.144C34.7051 38.9997 35.6327 38.072 36.777 38.072C37.9213 38.072 38.849 38.9997 38.849 40.144V45.3238C38.849 46.4681 37.9213 47.3958 36.777 47.3958C35.6327 47.3958 34.7051 46.4681 34.7051 45.3238V40.144Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M23.6742 44.6139C25.847 45.4337 28.2667 44.9504 29.1586 44.6139C29.6939 44.4119 30.2916 44.6821 30.4936 45.2174C30.6955 45.7527 30.4253 46.3504 29.89 46.5524C28.7099 46.9977 25.7426 47.6088 22.9427 46.5524C22.4074 46.3504 22.1372 45.7527 22.3392 45.2174C22.5412 44.6821 23.1389 44.4119 23.6742 44.6139Z"
									fill="#5C5B5B"
								/>
								<path
									d="M22.2734 15.2806C22.2734 13.7071 23.5489 12.4316 25.1224 12.4316C26.6958 12.4316 27.9713 13.7071 27.9713 15.2806V21.4964C27.9713 23.0698 26.6958 24.3453 25.1224 24.3453C23.5489 24.3453 22.2734 23.0698 22.2734 21.4964V15.2806Z"
									fill="#5C5B5B"
								/>
								<path
									d="M30.0441 16.5754C30.6163 16.5754 31.0801 17.0393 31.0801 17.6114C31.0801 18.1836 30.6163 18.6474 30.0441 18.6474L20.2024 18.6474C19.6302 18.6474 19.1664 18.1836 19.1664 17.6114C19.1664 17.0393 19.6302 16.5754 20.2024 16.5754L30.0441 16.5754Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M24.8639 4.14388C22.5753 4.14388 20.7201 5.99917 20.7201 8.28777V9.5805C20.7201 11.0224 21.2928 12.4052 22.3124 13.4248L23.9981 15.1105L21.0679 18.0406L19.3822 16.3549C17.5855 14.5582 16.5762 12.1214 16.5762 9.5805V8.28777C16.5762 3.71056 20.2867 0 24.8639 0V4.14388Z"
									fill="#5C5B5B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M24.8626 4.14388C27.1512 4.14388 29.0065 5.99917 29.0065 8.28777V9.5805C29.0065 11.0224 28.4337 12.4052 27.4142 13.4248L25.7285 15.1105L28.6586 18.0406L30.3443 16.3549C32.141 14.5582 33.1504 12.1214 33.1504 9.5805V8.28777C33.1504 3.71056 29.4398 0 24.8626 0V4.14388Z"
									fill="#5C5B5B"
								/>
								<path
									d="M46.3591 63.1943V52.3167C33.5945 55.5504 20.2418 55.6867 7.41377 52.7144L5.69727 52.3167V63.1943L7.46375 62.9143C20.3562 60.8704 33.4974 60.965 46.3591 63.1943Z"
									fill="#000E4B"
								/>
							</svg>

							<p className="titulo">
								<b>{current_language?.modal_upgrade_funcionalidade}</b>
							</p>

							<p className="text">
								{current_language?.modal_upgrade_funcionalidade2}
							</p>

							<p className="chamada">
								{current_language?.modal_upgrade_funcionalidade3}
							</p>
						</div>
						<button
							className="button upgrade"
							onClick={() =>
								window.open("https://wa.me/556185953414", "_blank")
							}
						>
							{current_language?.menu_lateral_saiba_mais}
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default  memo(ModalUpgradeFuncionalidade);

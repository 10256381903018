import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnRelatorio from "../../components/BtnRelatorio";
import { TextsProps } from "../../database/txt.interface";
import { ServicosProps } from "../../interfaces/config.interface";
import { AppActions } from "../../store/ducks/app";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { RelatorioActions } from "../../store/ducks/relatorios";
import { getPermission } from "../../utils/funcs";
import {
	EXECUCAO_TIPO,
	TypesBtnRelatorio,
	TypesMenuInfoProps,
	TypesPermission,
} from "../../utils/types";
import Assimetria from "./Assimetria";
import HistoricoAssimetria from "./Assimetria/HistoricoAssimetria";
import Evolucao from "./Evolucao";
import ForcaTempo from "./ForcaTempo";
import Multifatorial from "./Multifatorial";
import Fisioterapeutico from "./Fisioterapeutico";

import Loading from "../Dashboard/Loading";
import ModalBloqueio from "./ModalBloqueio";
import {
	BtnAux,
	Conteiner,
	Content,
	MenuBtnRelatorios,
	MenuTopRelatorios,
} from "./styles";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import { ExecucaoProps } from "../../interfaces/execucao.interface";
import { AssimetriaPros } from "../../interfaces/assimetria.interface";
import { AmplitudeLog } from "../../utils/amplitude";

const Relatorios: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const dispatch = useDispatch();
	
	

	const config_servicos: ServicosProps = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const config_servicos_array: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const execucoes_loading: boolean = useSelector(
		(state: any) => state.dashboard.execucoes_loading
	);
	const anamneses_loading: boolean = useSelector(
		(state: any) => state.dashboard.anamneses_loading
	);
	const assimetria_loading: boolean = useSelector(
		(state: any) => state.dashboard.assimetria_loading
	);

	const set_quantidade_emissoes_relatorios = useSelector(
		(state: any) => state.configs.set_quantidade_emissoes_relatorios
	);

	const age: number = useSelector((state: any) => state.relatorios.age);

	const setAge = (age: number) => {
		dispatch(RelatorioActions.set_age(age));
	};
	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);
	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);

	const anamneses: any = useSelector((state: any) => state.dashboard.anamneses);

	const [dataAnamnese, setDataAnamnese] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [isShare, setIsShare] = useState(false);

	const handleOpenCompartilhamento = () => {
		if (
			isShare &&
			getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_COMPARTILHAMENTO_EXTERNO,
			)
		) {
			dispatch(AppActions.set_modal_compartilhamento(true, age));
			dispatch(RelatorioActions.set_relatorio_mult_type(false));
			dispatch(RelatorioActions.set_relatorio_fisio_type(false));
			dispatch(RelatorioActions.set_relatorio_qv_type(false));
			dispatch(RelatorioActions.set_relatorio_avaliacao_type(false));
			dispatch(RelatorioActions.set_relatorio_multifatorial(false));
			dispatch(RelatorioActions.set_relatorio_fisioterapeutico(false));
			dispatch(RelatorioActions.set_relatorio_forca_tempo(false));
			dispatch(RelatorioActions.set_relatorio_evolucao(false));
			dispatch(RelatorioActions.set_relatorio_assimetria(false));
			dispatch(AppActions.set_continue_compartilhamento("EscolhaData"));
		}else{
			if(!getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_COMPARTILHAMENTO_EXTERNO,
			)){
				dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
			}
		}
	};

	const [reportType, setReportType] = useState(EXECUCAO_TIPO.AVALIACAO);

	const Historico = () => {
		!getPermission(
			config_servicos_array,
			TypesPermission.SERVICO_REL_ASSIMETRIA,
			dispatch
		)
			? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
			: setAge(4);
	};

	const grupoMuscular = () => {
		!getPermission(
			config_servicos_array,
			TypesPermission.SERVICO_REL_ASSIMETRIA,
			dispatch
		)
			? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
			: setAge(3);
	};

	const HandleReportType = (value: any) => () => {
		if (
			age === 1 &&
			!getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_REL_EVOLUCAO,
				dispatch
			)
		) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade(true));
		} else if (
			age === 2 &&
			!getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_REL_FORCA_TEMPO,
				dispatch
			)
		) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade(true));
		} else if (
			age === 3 &&
			!getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_REL_ASSIMETRIA,
				dispatch
			)
		) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade(true));
		} else if (
			age >= 4 &&
			!getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_REL_ASSIMETRIA,
				dispatch
			)
		) {
			dispatch(AppActions.set_modal_upgrade_funcionalidade(true));
		} else {
			setReportType(value);
			dispatch(RelatorioActions.set_op_aval_funci(value));
		}
	};

	useEffect(() => {
		// if (execucoes.length === 0) {
		if (execucoes_loading || anamneses_loading || assimetria_loading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [execucoes_loading, anamneses_loading]);

	const setSelectMenu = (info: string) => {
		dispatch(DashBoardActions.set_select_info(info));
	};

	useEffect(() => {
		setDataAnamnese(
			anamneses.filter(
				(item: any) =>
					item.ID_USUARIO == paciente.ID_USUARIO &&
					(item.relatorio_multifatorial?.POSSIVEL_GERAR === "S" ||
						item.relatorio_fisioterapeutico?.POSSIVEL_GERAR === "S")
			)
		);
	}, [paciente, anamneses]);

	useEffect(() => {
		if (
			(
				(
					config_servicos?.SERVICO_REL_AVALIACAO ||
					config_servicos?.SERVICO_REL_ASSIMETRIA ||
					config_servicos?.SERVICO_REL_FORCA
				) &&
				(
					execucoes.length > 0 ||
					assimetrias.length > 0
				)
			) ||
			(
				(
					config_servicos.SERVICO_ANAMNESE ||
					(
						10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
							set_quantidade_emissoes_relatorios.multifatorial) > 0
					)
				) &&
				dataAnamnese.length > 0
			)
		) {
			setIsShare(true);
		} else {
			setIsShare(false)
		};
	}, [execucoes, assimetrias, dataAnamnese]);


	useEffect(()=>{
		if(
			reportType === EXECUCAO_TIPO.AVALIACAO
		){
			AmplitudeLog('relat_tipo_avaliacao')
		}
		if(
			reportType === EXECUCAO_TIPO.FUNCIONAL
		){
			AmplitudeLog('relat_tipo_funcional')
		}
	},[reportType, age])

	
	return (
		<Conteiner>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className="header">
						<div className="divDropdown">
							<MenuTopRelatorios>
								<div className="svg">
									<svg
										onClick={() => {setSelectMenu(TypesMenuInfoProps.BTN_MENU), dispatch(RelatorioActions.set_age(3))}}
										width="28"
										height="28"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M27.3594 12.3594V15.6406H7.04688L16.3438 25.0156L14 27.3594L0.640625 14L14 0.640625L16.3438 2.98438L7.04688 12.3594H27.3594Z"
											fill="#000E4B"
										/>
									</svg>
								</div>
								<h3>{current_language?.relatorios}</h3>
								<BtnAux>
									{age === 4 && (
										<button className="btnShare" onClick={grupoMuscular}>
											<svg
												style={{ marginRight: 5 }}
												width="19"
												height="16"
												viewBox="0 0 19 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M10.8571 0C6.36048 0 2.71429 3.58222 2.71429 8H0L3.51952 11.4578L3.58286 11.5822L7.2381 8H4.52381C4.52381 4.56 7.35571 1.77778 10.8571 1.77778C14.3586 1.77778 17.1905 4.56 17.1905 8C17.1905 11.44 14.3586 14.2222 10.8571 14.2222C9.11095 14.2222 7.52762 13.52 6.38762 12.3911L5.10286 13.6533C6.57762 15.1022 8.60429 16 10.8571 16C15.3538 16 19 12.4178 19 8C19 3.58222 15.3538 0 10.8571 0ZM9.95238 4.44444V8.88889L13.8248 11.1467L14.4762 10.0711L11.3095 8.22222V4.44444H9.95238Z"
													fill="#000E4B"
												/>
											</svg>
											{current_language?.grupos_musculares}
										</button>
									)}
									<button
										className={`btnShare ${!isShare && "btnShareDisable"}`}
										onClick={()=> {
											handleOpenCompartilhamento()
											 AmplitudeLog('compartilhar_relatorio')
										}}
									>
										<svg
											className="share"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M11.0001 2.49996C11 1.91348 11.2062 1.34564 11.5825 0.8958C11.9589 0.445958 12.4815 0.142758 13.0588 0.0392456C13.6362 -0.0642662 14.2315 0.038502 14.7408 0.32957C15.25 0.620638 15.6406 1.08147 15.8444 1.63144C16.0481 2.18142 16.0519 2.7855 15.8552 3.33802C15.6585 3.89053 15.2737 4.35629 14.7682 4.6538C14.2627 4.95132 13.6687 5.06164 13.0901 4.96547C12.5115 4.8693 11.9851 4.57276 11.6031 4.12774L4.88518 7.2473C5.03962 7.73667 5.03962 8.26172 4.88518 8.75109L11.6031 11.8706C12.0069 11.401 12.5707 11.0981 13.1852 11.0204C13.7998 10.9428 14.4212 11.096 14.9292 11.4504C15.4372 11.8048 15.7955 12.3351 15.9347 12.9386C16.0739 13.5421 15.984 14.1758 15.6826 14.7168C15.3812 15.2579 14.8896 15.6678 14.3031 15.867C13.7166 16.0663 13.0771 16.0408 12.5083 15.7955C11.9396 15.5502 11.4822 15.1025 11.2248 14.5391C10.9674 13.9758 10.9283 13.337 11.1151 12.7465L4.39719 9.62696C4.06463 10.0145 3.62136 10.2909 3.12699 10.4189C2.63263 10.547 2.1109 10.5206 1.63198 10.3433C1.15306 10.1661 0.739929 9.84636 0.448165 9.42728C0.156402 9.00819 0 8.50982 0 7.99919C0 7.48857 0.156402 6.99019 0.448165 6.57111C0.739929 6.15202 1.15306 5.83233 1.63198 5.65504C2.1109 5.47775 2.63263 5.45137 3.12699 5.57945C3.62136 5.70752 4.06463 5.9839 4.39719 6.37142L11.1151 3.25186C11.0386 3.00856 10.9998 2.75499 11.0001 2.49996Z"
												fill={isShare ? "#000E4B" : "#b3b3b3"}
											/>
										</svg>
										{current_language?.compartilhar}
									</button>
								</BtnAux>
							</MenuTopRelatorios>
							<MenuBtnRelatorios>
								<BtnRelatorio
									Icon={TypesBtnRelatorio.BTN_ASSIMETRIA}
									Texto={current_language?.btn_assimetria}
									set_age={3}
									style={{
										borderBottom:
											age === 3 || age === 4 ? "3px solid #00A377" : "",
									}}
								/>
								<BtnRelatorio
									Icon={TypesBtnRelatorio.BTN_EVOLUCAO}
									Texto={current_language?.btn_evolucao}
									style={{
										borderRadius: "0.688rem 0 0 0",
										borderBottom: age === 1 && "3px solid #00A377",
									}}
									set_age={1}
								/>
								<BtnRelatorio
									Icon={TypesBtnRelatorio.BTN_FORCA_TEMPO}
									Texto={current_language?.btn_forca_tempo}
									set_age={2}
									style={{ borderBottom: age === 2 && "3px solid #00A377" }}
								/>
								
								<BtnRelatorio
									Icon={TypesBtnRelatorio.BTN_MULTIFATORIAL}
									Texto={current_language?.btn_multifatorial}
									set_age={5}
									style={{
										borderBottom: age === 5 ? "3px solid #00A377" : "",
									}}
								/>
								<BtnRelatorio
									Icon={TypesBtnRelatorio.BTN_FISIOTERAPEUTICO}
									Texto={current_language?.btn_fisioterapeutico}
									set_age={6}
									style={{
										borderRadius: "0 0.688rem 0 0",
										borderBottom: age === 6 ? "3px solid #00A377" : "",
									}}
								/>
							</MenuBtnRelatorios>

							<div className="divBtnAfterDrop"></div>
						</div>
					</div>

					<Content>
						{age !== 3 && age !== 4 && age !== 5 && age !== 6 && (
							<div className="btns">
								<button
									autoFocus
									className={
										reportType === EXECUCAO_TIPO.AVALIACAO
											? "typeReportActive"
											: "typeReport"
									}
									onClick={HandleReportType(EXECUCAO_TIPO.AVALIACAO)}
								>
									{current_language?.config_avaliacao}
								</button>
								<button
									className={
										reportType === EXECUCAO_TIPO.FUNCIONAL
											? "typeReportActive"
											: "typeReport"
									}
									onClick={HandleReportType(EXECUCAO_TIPO.FUNCIONAL)}
								>
									{current_language?.config_funcional}
								</button>
							</div>
						)}
						{age === 1 ? (
							<>
								<Evolucao />
								{config_servicos.SERVICO_REL_AVALIACAO === false && (
									<ModalBloqueio />
								)}
							</>
						) : age === 2 ? (
							<>
								<ForcaTempo />
								{config_servicos.SERVICO_REL_FORCA === false && (
									<ModalBloqueio />
								)}
							</>
						) : age === 3 ? (
							<>
								<Assimetria />
								{config_servicos.SERVICO_REL_ASSIMETRIA === false && (
									<ModalBloqueio />
								)}
							</>
						) : age === 5 ? (
							<>
								<Multifatorial />
								{(config_servicos.SERVICO_ANAMNESE === false && (10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
									set_quantidade_emissoes_relatorios.multifatorial) <= 0)) && (
										<ModalBloqueio />
									)}
							</>
						) : age === 6 ? (
							<>
								<Fisioterapeutico />
								{(config_servicos.SERVICO_ANAMNESE === false && (10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
									set_quantidade_emissoes_relatorios.multifatorial) <= 0)) && (
										<ModalBloqueio />
									)}
							</>
						) : (
							<>
								<HistoricoAssimetria />
								{!getPermission(
									config_servicos_array,
									TypesPermission.SERVICO_ANAMNESE,
								) && <ModalBloqueio />}
							</>
						)}
					</Content>
				</>
			)}
		</Conteiner>
	);
};

export default Relatorios;

import { Tooltip } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { withStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { SliderDiv, SliderContent } from "./styles";

interface Props {
	children: React.ReactElement;
	open: boolean;
	value: number;
}

const iOSBoxShadow =
	"0 3px 1px rgba(0,0,0,0.1),0 4px 2px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
	{
		value: 30,
	},
	{
		value: 35,
	},
	{
		value: 40,
	},
	{
		value: 45,
	},
	{
		value: 50,
	},
	{
		value: 55,
	},
	{
		value: 60,
	},
	{
		value: 65,
	},
	{
		value: 70,
	},
	{
		value: 75,
	},
	{
		value: 80,
	},
];

const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: "#001574",
		boxShadow: theme.shadows[2],
		fontSize: 13,
		margin: 5,
		padding: 1,
		borderRadius: 2,
	},
}))(Tooltip);

function valuetext(value: number) {
	return `${value}%`;
}

function ValueLabelComponent(props: Props) {
	const { children, open, value } = props;

	return (
		<>
			<LightTooltip
				open={open}
				enterTouchDelay={0}
				placement="top"
				title={value}
			>
				{children}
			</LightTooltip>
		</>
	);
}

const IOSSlider = withStyles({
	root: {
		color: "#001574",
		height: 2,
		padding: "15px 0",
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		boxShadow: iOSBoxShadow,
		marginTop: -12,
		marginLeft: -13,
		"&:focus, &:hover, &$active": {
			boxShadow:
				"0 2px 2px rgba(0,0,0,0.1),0 2px 4px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
			// Reset on touch devices, it doesn't add specificity
			"@media (hover: none)": {
				boxShadow: iOSBoxShadow,
			},
		},
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 8px)",
		top: -22,
		"& *": {
			background: "transparent",
			color: "#001574",
		},
	},
	track: {
		height: 2,
	},
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: "#bfbfbf",
	},
	mark: {
		backgroundColor: "#bfbfbf",
		height: 10,
		width: 10,
		marginTop: -4,
		borderRadius: 50,
	},
	markActive: {
		opacity: 1,
		backgroundColor: "currentColor",
	},
})(Slider);

export default function CustomizedSlider() {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	// VALOR DA ULTIMA AVALIAÇÃO FEITA
	const slider = useSelector((state: any) => state.app.slider_execucao);
	const id_proto: any = useSelector((state: any) => state.exercicios.id_proto);
	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);

	const ultima_avaliacao = execucoes?.find(
		(e: any) => e?.ID_EXERCICIO == id_proto
	)?.EXECUCAO_EXECMAX;
	const [value, setValue] = React.useState<number | number[]>(1);

	const handleChange = (event: any, newValue: number | number[]) => {
		if (
			getPermission(
				config_servicos,
				TypesPermission.SERVICO_PERCENTUAL_RM,
				dispatch
			)
		) {
			setValue(newValue);
			dispatch(AppActions.set_slider_execucao(newValue));
			if (ultima_avaliacao != 0) {
				const calc = (slider * ultima_avaliacao) / 100;
				dispatch(AvaliationActions.set_force_option_min(calc));

				let newValueMin = calc.toFixed(2).toString();
				newValueMin = newValueMin.replace(".", " . ");

				dispatch(AvaliationActions.set_force_option_min_str(newValueMin));
			}
		} else {
			dispatch(AppActions.set_open_configuracoes_execucao(false));
			dispatch(AppActions.set_modal_upgrade_funcionalidade(true));
		}
	};

	return (
		<SliderContent className="slider">
			<small className="Nminimo">30%</small>
			<SliderDiv>
				<IOSSlider
					onChange={handleChange}
					value={value}
					defaultValue={30}
					getAriaValueText={valuetext}
					valueLabelFormat={valuetext}
					aria-labelledby="discrete-slider-always"
					ValueLabelComponent={ValueLabelComponent}
					marks={marks}
					valueLabelDisplay="on"
					step={5}
					min={30}
					max={80}
				/>
			</SliderDiv>
			<span className="forcaMax">{current_language?.forca_max}</span>
		</SliderContent>
	);
}

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 100%;
	height: 27rem;
	background-color: white;
	overflow-x: hidden;
	height: 100%;

	overflow-y: hidden;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		width: 100%;
		height: 4rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.fichaActive {
		display: flex;
		flex-direction: row;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		width: 100%;
		height: 4rem;
	}

	.fichaOpen {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 98.5%;
		margin-top: 1.87rem;
	}

	.dados {
		display: flex;
		flex-direction: row !important;
		width: 100%;
	}

	.strong {
		margin-right: 5.75rem;
	}

	.values {
	}

	.textStrong {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #5c5b5b;
		margin-bottom: 2rem;
	}

	.textNormal {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #b3b3b3;
		margin-bottom: 2rem;
	}

	.relatorios {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 4rem;
	}

	.avaliador {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.c {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		height: 100%;
		background-color: white;
	}

	.ca {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		width: 100%;
		height: 50%;
		margin-bottom: 1.25rem;
	}

	.cb {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50%;
		background-color: white;
	}

	.cc {
		display: inline-block;
		width: 100%;
	}

	.ccAvaliador {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
	}

	.d {
		display: flex;
		width: 10%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		background-color: white;
		padding-right: 1rem;
	}

	.condicao {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 1.3rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 50%;
		height: 100%;
	}

	.b {
		display: flex;
		width: 50%;
		height: 100%;
		justify-content: flex-end;
		align-items: center;
		padding-right: 1rem;
	}

	.p {
		color: #5c5b5b;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	.botao {
		color: ${colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		font-size: 0.87rem;
	}

	.divBtn {
		margin-top: 2rem;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.btnVoltar {
		position: absolute;
		left: 0;
		cursor: pointer;
	}
`;

export const BtnDiv = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;

	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	width: 100%;

	button {
		margin: .5rem .5%;
	}
`;


export const Item = styled.div`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 0.75rem;
	line-height: 150%;

	padding: .5rem;

	display: flex;
	align-items: flex-start;

	border-bottom: 1px solid #B3B3B380;

	p {
		color: ${colors.secondary_green_k_dark_2};
	}

	span {
		margin-left: .5rem;
		color: #404040;
	}

	.imgs {
		overflow: hidden;
		width:100px;
		height:100px;

		display: initial;

		margin: .5rem;

		z-index: 6;

		img {
			width: 100px;
			height: 100px;
		}
	}
`;

export const InformacoesContent = styled.div`
	display: flex;
	justify-content: space-around;
`;

export const ListItens = styled.div`
	width: 40%;
`;

export const Title = styled.h2`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 120%;

	color: #404040;

	width: 100%;
	margin: 2rem 0;
	padding-left: 20%;
`;

export const BtnEmitir = styled.button`
	width: 14.438rem;
	height: 3.125rem;
	left: 392px;
	top: 267px;

	border: 0;

	color: #FCFCFC;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 1.375rem;
	line-height: 120%;

	background: ${colors.primary_blue_k_main};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

export const BodyQuest = styled.div`
	width: 100%;
	height: 500px;

	position: relative;

	padding-top: 2rem;

	.btnVoltarAnamnese {
		position: absolute;
		top: 2rem;
		left: 0;
		cursor: pointer;
	}
`;

export const FichaTech = styled.div`
	overflow-x: hidden;
	height: 100%;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 5px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 5px;
	}
`;
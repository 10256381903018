import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 40rem;
	width: 38vw;

	@media (max-height: 655px) {
		height: 35rem;
		
	}
	@media (max-height: 565px) {
		height: 23rem;
		
	}

	.contentButtonOptions {
		display: flex;
		height: 100px;
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		margin-top: 10%;
	}
	.tutorialVideo {
		display: flex;
		height: 35px;
		width: 30%;
		justify-content: center;
		align-items: center;
		border: solid;
		border-width: 1.5px;
		border-color: #5c5b5b;
		border-radius: 10px;
		cursor: pointer;
	}
	.TextVideos {
		font-weight: bold;
		@media (max-width: 1473px) {
			font-size:14px;
			
		}
	}
	.tutorialImagens {
		display: flex;
		height: 35px;
		width: 30%;
		justify-content: center;
		align-items: center;
		border: solid;
		border-width: 1.5px;
		border-color: #5c5b5b;
		border-radius: 10px;
		cursor: pointer;
	}
	.TextImagens {
		font-weight: bold;
		@media (max-width: 1473px) {
			font-size:14px;
			
		}
	}
	.containerVideo {
		display: flex;
		width: 100%;
		height: 50%;
		justify-content: center;
		align-items:  flex-end ;
		justify-content: center;
		align-items: center;
	}
	.iframe {
		height: 380px;
		width: 100%;
		margin-top: 30px;
		border-radius: 20px;
		border-width: 0px;

		@media (max-width: 900px) {
			height: 240px;
			width: 100%;
			margin-bottom: 20px;
		}
		@media (max-height: 655px) {
			height: 350px;
			
		}
		@media (max-height: 565px) {
			height: 200px;
			width: 90%;
			margin-bottom: 20px;
			
		}

	}

	.bodyVideo {
		height: 25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		.img {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			font-size: x-large;
			font-weight: 500;
			color: #5c5b5b;
		}

		.posicao {
			font-size: large;
			font-weight: 400;
			color: #5c5b5b;
		}
	}

	.bodyImage {
		height: 25rem;
		display: block;
		justify-content: center;
		align-items: center;

		.title {
			display: flex;
			justify-content: center;
			margin-bottom: 1rem;
		}

		.imageContent {
			height: 22rem;
			width: 20rem;
			overflow: hidden;
		}

		.img1 {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 12rem;

			@media (max-height: 655px) {
				width: 10rem;
			}
		}

		.img2 {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 14rem;

			@media (max-height: 655px) {
				width: 12rem;
			}
		}

		.img3 {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 14.5rem;

			@media (max-height: 655px) {
				width: 12.5rem;
			}
		}

		.img4 {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 18.2rem;

			@media (max-height: 655px) {
				width: 16.5rem;
			}
		}

		img {
			display: none;
		}

		.imgExer {
			display: block;
			max-width: 100%;
			object-fit: contain;
		}
		.carousel {
			justify-content: center;
			align-items: center;
			display: flex;
		}

		.posicao {
			font-size: large;
			font-weight: 400;
			color: #5c5b5b;
		}
	}

	.activityIndicator {
		border: 4px solid rgba(0, 0, 0, 0.1);
		border-left-color: ${colors.primary_blue_k_main};
		border-radius: 50%;
		width: 50px;
		height: 50px;
		position: absolute;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}

	.btnArrow {
		height: 3.43rem;
		width: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		border: 0.4px solid #e5e5e5;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin: 1rem;
	}

	.close {
		width: 1.738rem;

		position: absolute;
		right: 2rem;
		top: 2rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 3rem;
		width: 12%;
	}

	.divText {
		width: 90%;
		height: 80%;
	}
	.ContainerTitleAndVideo {
		display: flex; 
		width: 100%;
		flex-direction: column;
		align-items:center;
	}
	.empty {
		height: 100px;
	}

	.text {
		color: #b3b3b3;
		font-weight: 400;
		font-family: Roboto;
		font-size: 1.625rem;
		line-height: 30.47px;
		text-align: center;
		margin-bottom: 2rem;

		b {
			color: #5c5b5b;
			font-weight: 500;
		}
	}

	@media (max-width: 1150px) {
		height: 680px;
		width: 900px;
	}

	@media (max-width: 900px) {
		height: 400px;
		width: 700px;

		.img {
			width: 70%;
		}
	}

	@media (max-width: 770px) {
		height: 500px;
		width: 500px;
	}

	@media (max-width: 430px) {
		height: 400px;
		width: 400px;
	}
`;

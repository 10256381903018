import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Content = styled.div`
	position: relative;
`;

export const Exercice = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	
`;

export const Update = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 80rem;
	height: 15rem;
	
  .buttonUpgrade {
	background: ${colors.primary_blue_k_main};
	width: 15rem;
	height: 3rem;
	margin-top: 1.5rem;
	border-radius: 1rem;
	align-items: center;
	color: white;
	font-weight: bold;
	border: none;
	transition: all 0.3s ease;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
  }
`



import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import BaixarApp from "../components/BaixarApp";
import ModalAdicionarPaciente from "../components/Modais/ModalAdicionarPaciente";
import ModalLinguagem from "../components/Modais/ModalLinguagem";
import { UserProps } from "../interfaces/pacientes.interface";
import AvisoMobile from "../pages/Acesso/AvisoMobile";
import Cadastro from "../pages/Acesso/Cadastro";
import Loading from "../pages/Acesso/Loading";
import Login from "../pages/Acesso/Login";
import RecuperarSenha from "../pages/Acesso/RecuperarSenha";
import TesteModal from "../pages/Acesso/TesteModal";
import Dashboard from "../pages/Dashboard";
import FuncionalController from "../pages/Execucao/Exercicio/Controller";
import AvaliacaoController from "../pages/Execucao/Protocolo/Controller";
import AlterarSenhaController from "../pages/MenueConta/AlterarSenha/Controller";
import EditarMetodoPagamentoController from "../pages/MenueConta/EditarMetodoPagamento/Controller";
import CentralDeAjuda from "../pages/MenueConta/CentralDeAjuda/Controller";
import CentralDeAjudaItem from "../pages/MenueConta/CentralDeAjudaItem/Controller";
import QuestionarioAsync from "../pages/QuestionarioAsync";
import ConfiguracoesController from "../pages/MenueConta/Configuracoes/Controller";
import EditarPerfilController from "../pages/MenueConta/EditarPerfil/Controller";
import EtiquetasController from "../pages/MenueConta/Etiquetas/Controller";
import FaturasController from "../pages/MenueConta/Faturas/Controller";
import GerenciadorController from "../pages/MenueConta/GerenciadorExcluidos/Controller";
import MetodoPagamentoController from "../pages/MenueConta/MetodoPagamento/Controller";
import MeuPlanoController from "../pages/MenueConta/MeuPlano/Controller";
import MinhaContaController from "../pages/MenueConta/MinhaConta/Controller";
import PagamentosController from "../pages/MenueConta/Pagamentos/Controller";
import PerfilController from "../pages/MenueConta/Perfil/Controller";
import PersonalizarController from "../pages/MenueConta/PersonalizarRelatorio/Controller";
import PacienteController from "../pages/Paciente/Controller";
import AdicionarProtocoloController from "../pages/Protocolos/AdicionarProtocolo/Controller";
import ListagemExerciciosController from "../pages/Protocolos/AdicionarProtocolo/Controller";
import DragProtoExerController from "../pages/Protocolos/AdicionarProtocolo/Drag/Controller";
import EditarProtocoloController from "../pages/Protocolos/EditarProtocolo/Controller";
import ListagemProtocolosController from "../pages/Protocolos/ListagemProtocolo/Controller";
import NovoExercicioController from "../pages/Protocolos/NovoExercicio/Controller";
import TutorialController from "../pages/Protocolos/Tutorial/Controller";
import history from "./history";
import UpgradeController from "../pages/MenueConta/Upgrade/Controller";
import ConfirmacaoController from "../pages/MenueConta/Upgrade/Confirmacao/Controller";
import AssinaturaController from "../pages/MenueConta/Assinatura/Controller";
import Evaluator from "../pages/MenueConta/MenuEvaluator/Controller";
import DetailProtocoloController from "../pages/Protocolos/DetailProtocolo/Controller";

export default function Routes() {
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const PrivateRouter = ({ component: Component, ...rest }: any) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					!!user_logado ? (
						<Component {...props} />
					) : (
						<Redirect to={{ pathname: "/", state: { from: props.location } }} />
					)
				}
			/>
		);
	};

	const url_atual = window.location.href;
	console.log(window.location.pathname);
	let environment = "";
	url_atual.includes("https://web.elastic.fit/") ||
	url_atual.includes("http://web.elastic.fit/")
		? (environment = "production")
		: (environment = "development");

	const mobileTrue = window.screen.height > window.screen.width;

	const mobileTeste =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
			navigator.userAgent
		);

	return (
		<ConnectedRouter history={history}>
			{window.location.pathname !== "/questionario" &&
			(mobileTeste || mobileTrue) ? (
				<Switch>
					<Route path="/aviso" component={AvisoMobile} />
					<Redirect to="/aviso" />
				</Switch>
			) : (
				<Switch>
					<Route path="/aviso" component={AvisoMobile} />
					<Route path="/" exact component={Loading} />
					<Route path="/app" component={BaixarApp} />
					<Route path="/login" component={Login} />
					<Route path="/cadastro" component={Cadastro} />
					<Route path="/idioma" component={ModalLinguagem} />
					<Route path="/recuperar_senha" component={RecuperarSenha} />
					<Route path="/TesteModal" component={TesteModal} />
					<Route path="/central_ajuda" component={CentralDeAjuda} />
					<Route path="/central_ajuda_item" component={CentralDeAjudaItem} />
					<Route path="/questionario" component={QuestionarioAsync} />
					<PrivateRouter path="/dashboard" component={Dashboard} />
					<PrivateRouter path="/evaluator" component={Evaluator} />
					<PrivateRouter
						path="/adicionar_paciente"
						component={ModalAdicionarPaciente}
					/>
					<PrivateRouter
						path="/gerenciador_excluidos"
						component={GerenciadorController}
					/>
					<PrivateRouter
						path="/configuracoes"
						component={ConfiguracoesController}
					/>
					<PrivateRouter path="/etiquetas" component={EtiquetasController} />
					<PrivateRouter
						path="/personalizar_relatorio"
						component={PersonalizarController}
					/>
					<PrivateRouter path="/perfil" component={PerfilController} />
					<PrivateRouter
						path="/editar_perfil"
						component={EditarPerfilController}
					/>
					<PrivateRouter
						path="/alterar_senha"
						component={AlterarSenhaController}
					/>
					<PrivateRouter path="/assinatura" component={AssinaturaController} />
					<PrivateRouter path="/minha_conta" component={MinhaContaController} />
					<PrivateRouter path="/meu_plano" component={MeuPlanoController} />
					<PrivateRouter path="/pagamentos" component={PagamentosController} />
					<PrivateRouter path="/faturas" component={FaturasController} />
					<PrivateRouter
						path="/metodo_pagamento"
						component={MetodoPagamentoController}
					/>
					<PrivateRouter
						path="/editar_metodo_pagamento"
						component={EditarMetodoPagamentoController}
					/>
					<PrivateRouter path="/paciente" component={PacienteController} />
					<PrivateRouter
						path="/protocolos"
						component={ListagemProtocolosController}
					/>
					<PrivateRouter path="/protocolo" component={AvaliacaoController} />
					<PrivateRouter path="/exercicio" component={FuncionalController} />
					<PrivateRouter
						path="/adicionar_protocolo"
						component={AdicionarProtocoloController}
					/>
					<PrivateRouter
						path="/drag_protocolo"
						component={DragProtoExerController}
					/>
					<PrivateRouter
						path="/adicionar_exercicio"
						component={NovoExercicioController}
					/>
					<PrivateRouter
						path="/exercicios"
						component={ListagemExerciciosController}
					/>
					<PrivateRouter path="/tutorial" component={TutorialController} />
					<PrivateRouter
						path="/editar_protocolo"
						component={EditarProtocoloController}
					/>
					<PrivateRouter path="/upgrade" component={UpgradeController} />
					<PrivateRouter
						path="/confirmacao"
						component={ConfirmacaoController}
					/>
					<PrivateRouter
						path="/detalhes_protocolo"
						component={DetailProtocoloController}
					/>
					<Redirect from="*" to="/" />
				</Switch>
			)}
		</ConnectedRouter>
	);
}

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 80vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 85vh;
		width: 42vw;
	}
	.close {
		width: 1.738rem;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.text {
		color: #b3b3b3;
		font-weight: 500;
		font-family: "Roboto";
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}

	.textButton {
		color: #b3b3b3;
		font-weight: 500;
		font-family: "Roboto";
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
		cursor: pointer;
	}

	.button {
		width: 50%;

		text-align: center;

		font-family: "Roboto";
		font-weight: 700;
		font-size: 1.5rem;

		color: ${colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 0.7rem 3.3rem;

		border: 4px solid ${colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${colors.primary_blue_k_main};
		color: #ffffff;
	}
`;

export const Content = styled.div`
	width: 90%;
	height: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.divText {
		color: #5c5b5b;
		width: 83%;
		display: flex;
		justify-content: left;
	}

	.selecionados {
		width: 100%;
		min-height: 8rem;
		display: flex;
		justify-content: left;

		margin: 2rem;
		padding: 0 8%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}

		display: grid;
		grid-template-columns: repeat(3, auto);
		grid-auto-rows: minmax(2rem, auto);
		grid-row-gap: 1.5rem;

		@media only screen and (max-width: 1440px) {
			min-height: 4rem;
		}
	}

	.paciente {
		font-size: 1.25rem;
		font-weight: 500;
		color: #8c8e86;

		height: 3rem;

		margin-right: 1.5rem;

		display: flex;
		flex-direction: row;
		align-items: baseline;

		svg {
			margin-right: 0.6rem;
		}
	}

	h4 {
		color: #b3b3b3;
	}

	.etiquetas {
		height: 100%;
		width: 100%;

		font-weight: 500;
		font-size: 1.25rem;

		color: #8c8e86;

		margin: 1rem;
		padding: 0 8%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.item {
		width: 100%;
		height: 5rem;

		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	label {
		cursor: pointer;
	}

	.custom-checkbox input + .label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}

	.custom-checkbox input:checked + .label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.custom-checkbox input + .label2:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}

	.custom-checkbox input:checked + .label2:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}
`;

const messages = {
	es: {
		translations: {
			login: {
				email: "Email",
				senha: "Contraseña",
				forget: "Olvido la contraseña?",
				enviar: "ENVIAR",
				cadastrar: "REGISTRAR",
				idioma: "Idioma",
				ajuda: "Ayudar",
			},
			cadastro: {
				nome: "Nombre",
				email: "Email",
				celular: "Célula",
				senha: "Contraseña",
				confirmarsenha: "Confirme la contraseña",
				cpf: "CPF",
				nacionalidade: "Nacionalidad",
				concluir: "CONCLUIR",
				voltar: "Vuelve",
			},
			recuperar_senha: {
				inserir:
					"Ingrese el correo electrónico de registro para restablecer la contraseña",
				exemplo: "ejemplo@gmail.com",
				enviar: "ENVIAR",
				voltar: "Vuelve",
			},
		},
	},
};

export { messages };

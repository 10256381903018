import styled from "styled-components";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 10vh;
	border-bottom: 1px solid #e0e0e0;

	.buttonGoback {
		display: flex;
		margin-left: 100px;
		width: 10rem;
		height: 30px;
		align-items: center;
		justify-content: center;
	}
	.setaButton {
		background-color: transparent;
		border: none;
		margin-right: 2rem;
	}
`;

import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const ContainerOptions = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 65px;
    margin-top: 10px;

    .ContainerLabels {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 21px;
        width: 90%;

        .checkBox {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            height: 16px;
            width: 16px;

        }
        .Textlabel {
            margin-left: 12px;
            font-size: 14px;
            color: #09101D;
            font-weight: 600;

            @media screen and (max-width: 660px) {
                font-size: 11px;
            }
        }

        
        .label {
            font-size: 14px;
            margin-right: 12px;
            height: 21px;
            color: #09101D;
            font-weight: 600;
        }
        .TagNovo {
            display: flex;
            margin-left: 8px;
            background-color: ${colors.tertiary_yellow_1_dark};
            align-items: center;
            padding-left: 7px;
            padding-right: 7px;
            border-radius: 8px;
            width: 42px;
            height: 16px;

            .tagText {
                font-size: 12px;
                align-items: center;
                font-family: Roboto;
                font-weight: 400;
            }
        }
        .ContainerDropdown {
            display: flex;
            height: 16px;
            width: 16px;
            align-items: center;
            justify-content: center;
            margin-left: 16px;
        }
        .rotate180 {
            transform: rotate(180deg)
        }
        .rotate0 {
            transform: rotate(0deg)
        }


    }
    .description {
        margin-left: 28px;
        width: 80%;
        font-size: 12px;
        font-weight: 400;

        @media screen and (max-width: 660px) {
            font-size: 10px;
        }
    }

   

`;

export const Container = styled.div`
    background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 100%;
	width: 805px;

    .close {
		width: 1.738rem;
		position: absolute;
		right: 3rem;
		top: 1.8rem;
		cursor: pointer;
	}

    .Header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 10rem;

        .phrase{
            display: flex;
            width: 100%;
            height: 4rem;
            padding-top: 18px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .text {
                font-size: 16px;
                font-weight: 400;
                color: #2C3A4B;
                font-family: Roboto;
                text-align: center;
            }
            .ContentName {
                display: flex;
                flex-direction: row;
                .namepaci{
                    font-size: 16px;
                    font-weight: 700;
                    color: #2C3A4B;
                    font-family: Roboto;
                    text-align: center;
                }

            }

        }
        .LenghProtocol{
            display: flex;
            height: 33px;
            width: 80%;
            margin-top: 10px;
            border-radius: 100px;
            flex-direction: row;
            align-items: center;
            padding: 6px 24px 6px 24px;
            

            .addCircle{
                width: 25px;
                height: 25px;
            }

            .Length{
                margin-left: 5px;
                font-size: 16px;
                font-weight: bold;
              
            }
            .Disponiveis{
                margin-left: 5px;
                font-size: 16px;
                color: #2E5AAC;
            }

        }
    }

    .ContentChecks{
        display: flex;
        width: 80%;
        height: 80%;
        flex-direction: column;
      

        .ContainerTitleProtocolosPorArticulacao {
            display: flex;
            margin-top: 20px;

            .TitleProtocolosPorArticulacao {
                font-size: 14px;
                font-weight: 600;
                color: #858C94;
            }
        }
        .ContainerTitleProtocolosGlobais {
            display: flex;
            margin-top: 10px;

            .TitleProtocolosGlobais {
                font-size: 14px;
                font-weight: 600;
                color: #858C94;
            }
        }

        .ProtocolosPorArticulacao {
            display: flex;
            width: 100%;
            height: 18.75rem;
            flex-flow: wrap;
            

            @media only screen and (max-height: 815px) {
                height: 15.281rem;
            }
        }
        .ProtocolosGlobais {
            display: flex;
            width: 100%;
            height: 12rem;
            flex-flow: wrap;
        }




        .footer{
            display: flex;
            height: 12%;
            width: 80%;
            align-items: center;
            justify-content: center;
            bottom: 0;
            position: absolute;

            .save {
                display: flex;
                height: 39.91px;
                width: 120.54px;
                border-radius: 24px;
                align-items: center;
                justify-content: center;
                background-color: ${colors.primary_blue_k_main};
                cursor: pointer;
                transition: background-color 0.3s ease;
                
                .textSave {
                    font-size: 16px;;
                    font-weight: 600;
                    color: #ffffff;
                   
                }
            }
            .save:hover {
                background-color:  ${colors.primary_blue_k_dark_2}
            }

        } 
    }

`;
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";

interface ContainerProps {
	questionAsync: boolean;
}

export const Container = styled.div<ContainerProps>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: ${({ questionAsync }) =>
		questionAsync ? "center" : "flex-end"};
	flex-direction: column;
	${({ questionAsync }) => questionAsync && "padding: 1.2rem 0; "}
`;

export const ProgressNumber = styled.div`
	width: 13.175rem;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 23px;
	text-align: center;

	color: ${colors.primary_blue_k_main};

	margin-bottom: 0.475rem;
`;

export const ProgressBar = styled.div`
	height: 0.5rem;
	width: 13.175rem;

	background: ${colors.neutral_grey_k_10};
	border-radius: 4px;

	overflow: hidden;
`;

export const Preenchimento = styled.div`
	height: 100%;

	background: ${colors.secondary_green_k_medium_1};
	border-radius: 4px;

	transition: all 0.2s linear;
`;

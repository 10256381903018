import moment from "moment";
import React from "react";

import { TypesCores } from "../../../utils/types";
import { DropDownContent } from "./styles";

interface RenderDrop {
	currentElement: any;
	side: string;
	top: any;
	left: any;
	maior: any;
}

const DropDown: React.FC<RenderDrop> = ({
	currentElement,
	side,
	top,
	left,
	maior,
}: any) => {
	function getPorcent(valor: any) {
		const x = (valor * 100) / maior;
		return `${x.toFixed(0)}%`;
	}

	const corLegenda: any = currentElement?.EXECUCOES[0].LEGENDA_ASSIMETRIA;

	const baseStyleD = {
		width: getPorcent(currentElement?.EXECUCOES[0]?.MAXEXEC_D),
		backgroundColor:
			corLegenda == TypesCores.POSITIVO
				? "#39E664"
				: corLegenda == TypesCores.INTERMEDIARIO
				? "#FFC530"
				: corLegenda == TypesCores.NEGATIVO
				? "#FF0000"
				: "#8D8D8D",
	};
	const baseStyleE = {
		width: getPorcent(currentElement?.EXECUCOES[0]?.MAXEXEC_E),
		backgroundColor:
			corLegenda == TypesCores.POSITIVO
				? "#39E664"
				: corLegenda == TypesCores.INTERMEDIARIO
				? "#FFC530"
				: corLegenda == TypesCores.NEGATIVO
				? "#FF0000"
				: "#8D8D8D",
	};

	const styleD = {
		width: getPorcent(currentElement?.EXECUCOES[0]?.MAXEXEC_D),
		backgroundColor:
			currentElement?.EXECUCOES[0]?.MAXEXEC_E < maior
				? "#8D8D8D"
				: corLegenda == TypesCores.POSITIVO
				? "#39E664"
				: corLegenda == TypesCores.INTERMEDIARIO
				? "#FFC530"
				: corLegenda == TypesCores.NEGATIVO
				? "#FF0000"
				: "#8D8D8D",
	};
	const styleE = {
		width: getPorcent(currentElement?.EXECUCOES[0]?.MAXEXEC_E),
		backgroundColor:
			currentElement?.EXECUCOES[0]?.MAXEXEC_D < maior
				? "#8D8D8D"
				: corLegenda == TypesCores.POSITIVO
				? "#39E664"
				: corLegenda == TypesCores.INTERMEDIARIO
				? "#FFC530"
				: corLegenda == TypesCores.NEGATIVO
				? "#FF0000"
				: "#8D8D8D",
	};
	const styleIgualE = {
		width: getPorcent(currentElement?.EXECUCOES[0]?.MAXEXEC_E),
		backgroundColor:
			currentElement?.EXECUCOES[0]?.ASSIMETRIA_VALOR == "0%"
				? "#39E664"
				: currentElement?.EXECUCOES[0]?.MAXEXEC_D < maior
				? "#8D8D8D"
				: corLegenda == TypesCores.POSITIVO
				? "#39E664"
				: corLegenda == TypesCores.INTERMEDIARIO
				? "#FFC530"
				: corLegenda == TypesCores.NEGATIVO
				? "#FF0000"
				: "#8D8D8D",
	};
	const styleIgualD = {
		width: getPorcent(currentElement?.EXECUCOES[0]?.MAXEXEC_D),
		backgroundColor:
			currentElement?.EXECUCOES[0]?.ASSIMETRIA_VALOR == "0%"
				? "#39E664"
				: currentElement?.EXECUCOES[0]?.MAXEXEC_E < maior
				? "#8D8D8D"
				: corLegenda == TypesCores.POSITIVO
				? "#39E664"
				: corLegenda == TypesCores.INTERMEDIARIO
				? "#FFC530"
				: corLegenda == TypesCores.NEGATIVO
				? "#FF0000"
				: "#8D8D8D",
	};

	return (
		<aside style={{ display: "block", left: left, top: top }}>
			<DropDownContent>
				{side == "left" ? (
					<div className="exerciceEnumber">
						<section>{currentElement?.EXECUCOES[0].LEGENDA_ASSIMETRIA}</section>
						<small className="exerciceName">
							{currentElement?.EXERCICIO_TITULO_PT}
						</small>
					</div>
				) : (
					<div className="exerciceEnumber">
						<small className="exerciceName">
							{currentElement?.EXERCICIO_TITULO_PT}
						</small>
						<section>{currentElement?.LABEL_EXERCICIO}</section>
					</div>
				)}

				<small className={side == "left" ? "date" : "dateRight"}>
					{moment(currentElement?.EXECUCOES[0].DATA).format("DD/MM/YY")}
				</small>

				<div className="grafic">
					<div className="barras">
						<div className="barra1">
							<div className="divLado">
								<small className="lado">D</small>
							</div>
							<div
								className="barra"
								style={{
									...baseStyleD,
									...styleD,
									...styleIgualD,
								}}
							></div>
						</div>
						<div className="barra2">
							<div className="divLado">
								<small className="lado">E</small>
							</div>
							<div
								className="barra"
								style={{
									...baseStyleE,
									...styleE,
									...styleIgualE,
								}}
							></div>
						</div>
					</div>
					<div className="divValues">
						<div className="values">
							<small className="value">
								{currentElement?.EXECUCOES[0]?.MAXEXEC_D} Kg
							</small>
							<small className="value">
								{currentElement?.EXECUCOES[0]?.MAXEXEC_E} Kg
							</small>
						</div>

						<svg
							width="7"
							height="27"
							viewBox="0 0 7 27"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 1H6V26H0"
								stroke="#5C5B5B"
								strokeWidth="0.7"
								strokeLinejoin="round"
							/>
						</svg>
						<p
							style={{
								marginLeft: "5px",
								color:
									currentElement?.EXECUCOES[0]?.LEGENDA_ASSIMETRIA ==
									TypesCores.POSITIVO
										? "#39E664"
										: currentElement?.EXECUCOES[0]?.LEGENDA_ASSIMETRIA ==
										  TypesCores.INTERMEDIARIO
										? "#EBB909"
										: "#E50000",
							}}
						>
							{currentElement?.EXECUCOES[0]?.ASSIMETRIA_VALOR}
						</p>
					</div>
				</div>
			</DropDownContent>
		</aside>
	);
};

export default DropDown;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as V from "victory";

import { TextsProps } from "../../../../database/txt.interface";
import {
	ExecucaoProp,
	ExerCucaoProp,
} from "../../../../interfaces/assimetria.interface";
import { PacAvalActions } from "../../../../store/ducks/manage_pac_aval";
import { RelatorioActions } from "../../../../store/ducks/relatorios";
import { TypesCores } from "../../../../utils/types";
import { Content } from "./styles";

const HistoricoAssimetria: React.FC = () => {
	const dispatch = useDispatch();

	const dataD = useSelector((state: any) => state.relatorios.dataD);
	const dataE = useSelector((state: any) => state.relatorios.dataE);

	const execucoes: ExecucaoProp[] = useSelector(
		(state: any) => state.relatorios.history_execucoes
	);

	const assimetria = useSelector((state: any) => state.dashboard.assimetria);

	const [reportType, setReportType] = useState();
	const HandleReportType = (value: any) => (event: any) => {
		setReportType(value);
	};

	const exercicios_historicos = useSelector(
		(state: any) => state.relatorios.exercicios_historicos
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const exer_historico: any = [];

	exercicios_historicos.map((e: ExerCucaoProp) => {
		const ladoDireito = [];
		const ladoEsquerdo = [];
		const legendas = [];
		if (e?.EXECUCOES[2]) {
			ladoDireito.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[2].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[2].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[2].MAXEXEC_D,
				position: "D",
				color: e.EXECUCOES[2].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[2].MAXEXEC_E > e.EXECUCOES[2].MAXEXEC_D
						? e.EXECUCOES[2].MAXEXEC_E
						: e.EXECUCOES[2].MAXEXEC_D,
			});
			ladoEsquerdo.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[2].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[2].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[2].MAXEXEC_E,
				position: "E",
				color: e.EXECUCOES[2].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[2].MAXEXEC_E > e.EXECUCOES[2].MAXEXEC_D
						? e.EXECUCOES[2].MAXEXEC_E
						: e.EXECUCOES[2].MAXEXEC_D,
			});
			legendas.push({
				data:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[2].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[2].DATA?.substr(0, 10).split("/").join("-")}`,
				direito: e?.EXECUCOES[2].MAXEXEC_D,
				esquerdo: e?.EXECUCOES[2].MAXEXEC_E,
				assimetria: e?.EXECUCOES[2].ASSIMETRIA_VALOR,
			});
		}
		if (e?.EXECUCOES[1]) {
			ladoDireito.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[1].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[1].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[1].MAXEXEC_D,
				position: "D",
				color: e.EXECUCOES[1].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[1].MAXEXEC_E > e.EXECUCOES[1].MAXEXEC_D
						? e.EXECUCOES[1].MAXEXEC_E
						: e.EXECUCOES[1].MAXEXEC_D,
			});
			ladoEsquerdo.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[1].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[1].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[1].MAXEXEC_E,
				position: "E",
				color: e.EXECUCOES[1].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[1].MAXEXEC_E > e.EXECUCOES[1].MAXEXEC_D
						? e.EXECUCOES[1].MAXEXEC_E
						: e.EXECUCOES[1].MAXEXEC_D,
			});
			legendas.push({
				data:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[1].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[1].DATA?.substr(0, 10).split("/").join("-")}`,
				direito: e?.EXECUCOES[1].MAXEXEC_D,
				esquerdo: e?.EXECUCOES[1].MAXEXEC_E,
				assimetria: e?.EXECUCOES[1].ASSIMETRIA_VALOR,
			});
		}
		if (e?.EXECUCOES[0]) {
			ladoDireito.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[0].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[0].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[0].MAXEXEC_D,
				position: "D",
				color: e.EXECUCOES[0].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[0].MAXEXEC_E > e.EXECUCOES[0].MAXEXEC_D
						? e.EXECUCOES[0].MAXEXEC_E
						: e.EXECUCOES[0].MAXEXEC_D,
			});
			ladoEsquerdo.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[0].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[0].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[0].MAXEXEC_E,
				position: "E",
				color: e.EXECUCOES[0].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[0].MAXEXEC_E > e.EXECUCOES[0].MAXEXEC_D
						? e.EXECUCOES[0].MAXEXEC_E
						: e.EXECUCOES[0].MAXEXEC_D,
			});
			legendas.push({
				data:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[0].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[0].DATA?.substr(0, 10).split("/").join("-")}`,
				direito: e?.EXECUCOES[0].MAXEXEC_D,
				esquerdo: e?.EXECUCOES[0].MAXEXEC_E,
				assimetria: e?.EXECUCOES[0].ASSIMETRIA_VALOR,
			});
		}
		exer_historico.push({
			EXERCICIO_TITULO_EN: e.EXERCICIO_TITULO_EN,
			EXERCICIO_TITULO_ES: e.EXERCICIO_TITULO_ES,
			EXERCICIO_TITULO_PT: e.EXERCICIO_TITULO_PT,
			DADOS_DIREITA: ladoDireito,
			DADOS_ESQUERDA: ladoEsquerdo,
			LEGENDAS: legendas,
		});
	});

	// //console.log(exercicios_historicos, 'exercicios_historicos')
	// //console.log(exer_historico)

	return (
		<Content>
			<div className="cards">
				{exer_historico.map((e: any, index: any) => {
					return (
						<div className="card" key={index}>
							<p className="title">
								{current_language?.idioma === "Português" && (
									<p>{e?.EXERCICIO_TITULO_PT}</p>
								)}
								{current_language?.idioma === "English" && (
									<p>{e?.EXERCICIO_TITULO_EN}</p>
								)}
								{current_language?.idioma === "Español" && (
									<p>{e?.EXERCICIO_TITULO_ES}</p>
								)}
							</p>
							<div className="chart">
								<V.VictoryChart width={1300}>
									<V.VictoryGroup offset={135}>
										<V.VictoryBar
											data={e?.DADOS_ESQUERDA}
											labels={({ datum }) => [datum._y, datum.position]}
											style={{
												data: {
													fill: ({ datum }) => {
														let cor: any = null;
														if (datum.color == TypesCores.POSITIVO) {
															cor = "#39E664";
														} else if (datum.color == TypesCores.NEGATIVO) {
															cor = "#FF0000";
														} else if (
															datum.color == TypesCores.INTERMEDIARIO
														) {
															cor = "#FFC530";
														} else {
															cor = "#7F7F7F";
														}
														if (datum.y < datum.maior) {
														} else if (
															datum.color == TypesCores.POSITIVO &&
															datum.y === datum.maior
														) {
															cor = "#39E664";
														} else {
															cor = "#7F7F7F";
														}
														return cor;
													},
													width: "120",
												},
												labels: {
													fill: "white",
													fontSize: 32,
													fontFamily: "roboto",
												},
											}}
											labelComponent={
												<V.VictoryLabel
													dy={70}
													lineHeight={[3, 1]}
													style={[
														{ fill: "#000", fontSize: "40px" },
														{ fill: "white", fontSize: "40px" },
													]}
												/>
											}
										/>
										<V.VictoryBar
											data={e?.DADOS_DIREITA}
											labels={({ datum }) => [datum._y, datum.position]}
											style={{
												data: {
													fill: ({ datum }) => {
														let cor: any = null;
														if (datum.color == TypesCores.POSITIVO) {
															cor = "#39E664";
														} else if (datum.color == TypesCores.NEGATIVO) {
															cor = "#FF0000";
														} else if (
															datum.color == TypesCores.INTERMEDIARIO
														) {
															cor = "#FFC530";
														} else {
															cor = "#7F7F7F";
														}
														if (datum.y < datum.maior) {
														} else if (
															datum.color == TypesCores.POSITIVO &&
															datum.y === datum.maior
														) {
															cor = "#39E664";
														} else {
															cor = "#7F7F7F";
														}

														return cor;
													},
													width: "120",
												},
												labels: {
													fill: "white",
													fontSize: 32,
													fontFamily: "roboto",
												},
											}}
											labelComponent={
												<V.VictoryLabel
													dy={70}
													lineHeight={[3, 1]}
													style={[
														{ fill: "#000", fontSize: "40px" },
														{ fill: "white", fontSize: "40px" },
													]}
												/>
											}
										/>
									</V.VictoryGroup>

									<V.VictoryAxis
										dependentAxis
										label={current_language?.forca_kg}
										style={{
											axisLabel: {
												fontSize: 30,
												fontFamily: "roboto",
											},

											tickLabels: {
												fontSize: 20,
												fill: "transparent",
											},
											axis: {
												color: "transparent",
											},
										}}
									/>

									<V.VictoryAxis
										style={{
											tickLabels: {
												fontSize: 35,
												fill: "black",
												fontFamily: "roboto",
											},
										}}
									/>
								</V.VictoryChart>
							</div>
							<table className="table">
								<tr>
									<td className="parameter">{current_language?.data}</td>
									<td className="parameter">
										{current_language?.hemisferio_direito} (D)
									</td>
									<td className="parameter">
										{current_language?.hemisferio_esquerdo} (E)
									</td>
									<td className="parameter">{current_language?.assimetria}</td>
								</tr>

								{e?.LEGENDAS.map((i: any, index: any) => {
									return (
										<tr key={index}>
											<td className="value">{i?.data}</td>
											<td className="value">{i?.direito} Kg</td>
											<td className="value">{i?.esquerdo} Kg</td>
											<td
												className={`assimetria ${
													i?.assimetria.slice(0, -1) >= 20
														? `vermelho`
														: i?.assimetria.slice(0, -1) > 10 &&
														  i?.assimetria.slice(0, -1) < 20
														? `amarelo`
														: i?.assimetria.slice(0, -1) <= 10 && `verde`
												}`}
											>
												{i?.assimetria}
											</td>
										</tr>
									);
								})}
							</table>
						</div>
					);
				})}
			</div>
		</Content>
	);
};

export default HistoricoAssimetria;

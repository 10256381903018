import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: 100%;
`;

export const QualityLifeContent = styled.div`
	padding-vertical: 3px;
`;

export const Row = styled.div`
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 4px;
`;

export const InfoText = styled.p`
		margin-top: 5px;
`;

export const Spectrum = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Dot = styled.div`
	width: 40px;
	height: 12px;
	margin-right: 0.09em;
`;

import { messages as englishMessages } from "./en";
import { messages as espanholMessages } from "./es";
import { messages as portuguesMessages } from "./pt";

const messages = {
	...portuguesMessages,
	...englishMessages,
	...espanholMessages,
};

export { messages };
